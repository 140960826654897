////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component, Fragment , Profiler, createContext} from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { Route, Link } from 'react-router-dom';
import _ from 'lodash';
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/edit.scss"


// 컴포넌트 연결

// 서비스 연결
import * as Event from 'service/event/Actions' ; 
import * as Utility from'service/other/Utility.js' ; 
import * as viewAPI from 'service/api/view' ; 
import * as spaceAPI from 'service/api/space' ; 
import * as pageAPI from 'service/api/page' ; 
import { token } from 'service/event/Account'; 
import GlobalContext from 'service/context/GlobalContext';
import { widget } from 'service/value/Model';
import { object } from 'service/event/template';

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

class GlobalProvider extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        captureStream : [],
        getScreenCapture : this.getScreenCapture,
        stopCapture : this.stopCapture,
        CaptrueState : this.CaptrueState,
        select : this.space,
    };
      this.spaceSocket = spaceAPI.start();

    }

    async componentDidMount()  {
        console.log('글로벌 프로바이더 작동')
        
        this.spaceSocket.on('space', (token) => {
          console.log(`📡 소켓 : View 소켓이 연결되었습니다, 연결된 뷰 : ${this.props.uuid} `);
          // this.joinView(this.props.uuid)
      });

    }

    


    componentDidUpdate(prevProps, prevState) {

      }

    ////////////////////////////////////////////////////////
    // 소켓 연결 부분
    ////////////////////////////////////////////////////////

    /** getScreen | View 소켓에 접속합니다.
     * @param {string} uuid 접속할 뷰의 아이디
     * @returns 
     *  */ 


    // function stopCapture() {
    //   if (mediaStream) {
    //     const tracks = mediaStream.getTracks();
    //     tracks.forEach(track => track.stop());
    //     mediaStream = null;
    //   }
    // }
    
    CaptrueState = () =>{
      console.log('navigator.mediaDevices',navigator);
  
    }

    

    stopCapture = (id) => {
      const index = this.state.captureStream.findIndex(item => item.id === id);
      const captureStream = this.state.captureStream.filter(obj => obj.id !== id);
      let mediaStream = this.state.captureStream[index]


      if (mediaStream) {
        mediaStream.getTracks().forEach(track => track.stop());
        mediaStream = null;
      }
      

      this.setState({
        captureStream
      })
    }
    

    getScreenCapture = async(hook,displayMediaOptions)  => {

        let captureStream;
        try {
           const stream = await navigator.mediaDevices.getDisplayMedia(displayMediaOptions);
            stream.getTracks().forEach(track => {
              track.onended = () => {
                const captureStream = this.state.captureStream.filter(obj => obj.id !== stream.id);
                this.setState({
                  captureStream
                })
              };
            });
            captureStream = [...this.state.captureStream];
            captureStream.push(stream);
            this.setState({
              captureStream
            })
          } catch (err) {
            console.error(`Error: ${err}`);
          }
      
       
    }
  
    componentWillUnmount() {
        // 방을 나갑니다.
        console.log('소켓을 종료합니다.')
    }
  
    render() {

      return (
        <GlobalContext.Provider value={this.state}> 
            {this.props.children}
        </GlobalContext.Provider>
      );
    }
  }

////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////

    // export default Login;
    export default GlobalProvider;