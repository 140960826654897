////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, {useState, useEffect, useRef } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { Route, Link } from 'react-router-dom';
import { useHistory } from "react-router";
// 라이브러리 
import { useGoogleLogin } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google'// 구글 로그인
// 설정 파일
import * as config from 'config/OverlapConfig'

import { withRouter } from 'react-router-dom';

import * as apiAccount from 'service/api/apiAccount'

// 컴포넌트 연결
import ViewInfo from "components/Lobby/space/module/ViewInfo"
import OverlapInfo from "components/Lobby/space/module/OverlapInfo"
import GuideBalloon from 'components/common/form/GuideBalloon'; // 말풍선 가이드
import SwitchModule from 'components/Edit/editModule/SwitchModule'; // 체크박스 모듈
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import 'sass/login.scss'

// 이미지 연결
import logoW from 'assets/logoW.svg';

// 서비스 연결
import * as Editor from 'service/edit/EditService'; 
import * as overlap from 'service/api/overlap';
import * as Utility from 'service/other/Utility' ; 
import * as view from 'service/api/view';
import { utils } from 'sortablejs';
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

function OverlapCreate({spaceId,setMode}) {
    // const todos = useSelector(state => state.todos)
    // const dispatch = useDispatch()
    // const addTodo = text => dispatch(addTodo(text))


    let defaultOverlapData = {
      title : '',
      resolution : {width : 1920, height : 1080},
      private : false,
    }

    let defaultChannelData = {
      name : '',
      resolution : {width : 1920, height : 1080},
      private : false,
    }

    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////
    // state
    const [textValue, setTextValue] = useState('');
    const [resolutionValue, setResolutionValue] = useState('HD');
    const [overlapData, setOverlapData] = useState(defaultOverlapData);

    const [Resolution, setResolution] = useState({...defaultOverlapData.resolution});


    // 2단계 채널 정보 관련
    const [ChannelData, setChannelData] = useState(defaultChannelData);
    const [ChannelOption, setChannelOption] = useState('select');
    const [ChannelList, setChannelList] = useState([]);
    const [CreateStep, setCreateStep] = useState(1);
    const [ChannelSelect, setChannelSelect] = useState(null); // 선택한 채널의 uuid


    const [Flag_AutoMake, setFlag_AutoMake] = useState(true); // 채널 자동 생성


    // 선택한 채널의 uuid


    const [Direction, setDirection] = useState('horizontal'); //horizontal,vertical

    // rel 
    // const SpaceRef = useRef(null);
    ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////
      useEffect(() => {

        getChannel();

      }, []);

      useEffect(() => {
        console.log('width',Resolution.width,'height',Resolution.height)
        resolutionControl(Resolution.width,Resolution.height);
      }, [Resolution,Direction]);
    ////////////////////////////////////////////////////////
    // Fuction
    ////////////////////////////////////////////////////////


      const changeOverlap = ({key,subKey,value}) => {
        console.log('key,subKey,value',key,subKey,value)
        const Data =  Utility.deepCopy(overlapData)
        if(subKey){
          Data[key][subKey] = value;
        }else{
          Data[key] = value;
        }

        setOverlapData(Data)
      }
      
      const changeChannel = ({key,subKey,value}) => {
        console.log('key,subKey,value',key,subKey,value)
        const Data =  Utility.deepCopy(ChannelData)
        if(subKey){
          Data[key][subKey] = value;
        }else{
          Data[key] = value;
        }
        setChannelData(Data)
      }
      

      const getChannel = async() => {
        const viewList = await view.list(spaceId);
        if(viewList.length == 0){
          setChannelOption('make')
        }
        setChannelList(viewList);
      }

      const valueChange = (key,value) =>  {
        let newObject = Utility.deepCopy(overlapData);
        newObject[key] = value;
        setOverlapData(newObject);
        console.log('newObject',newObject);
      }
      function handleTextChange(event) {
        setTextValue(event.target.value);
        valueChange('title',event.target.value)
      }
      console.log(resolutionValue)


      /**
       * 오버랩 생성 버튼을 누르면 오버랩을 생성합니다.
       */
      const createOverlap = async () => {

        let createData = Utility.deepCopy(overlapData);
        console.log('메이크',ChannelOption);
        switch (ChannelOption) {
          
          case 'select':

            createData['default_channel'] = ChannelSelect;
            const res1 = await overlap.create(spaceId,createData)
            console.log('res',res1)
            if(res1){
              setMode(null);
            }

            return;
          case 'make':
              let Channel = {}
              createData['default_channel'] = ChannelSelect;

              if(Flag_AutoMake){ // 채널 자동생성 기능 사용시
                Channel = Utility.deepCopy(overlapData);

                Channel['name'] = ChannelData.name
                if(Channel['name']==''){
                  Channel['name'] = overlapData.title?overlapData.title+'의 채널':'새로운 프로젝트의 채널'
                }

              }else{
                Channel = Utility.deepCopy(ChannelData);
              }

              const CreateViewID = await view.create(spaceId,Channel)

              if(!CreateViewID){
                break;
              }
              createData['default_channel'] = CreateViewID.uuid;
              const res2 = await overlap.create(spaceId,createData)
              if(res2){
                setMode(null);
              }
            return;
          default:
            const res3 = await overlap.create(spaceId,createData)
            console.log('res',res3)
            if(res3){
              setMode(null);
            }
            return;
        }
        

      }

      /**
       * 해상도를 조절합니다.
       */
      const resolutionControl = (width,height) => {
        switch (Direction) {
          case 'horizontal':
            changeOverlap({key:'resolution',value:{width : Number(width), height : Number(height)}})
            break;
          case 'vertical':
            changeOverlap({key:'resolution',value:{width : Number(height), height : Number(width)}})

          default:
            break;
        }
      }

      const channelControl = () => {
        switch (ChannelOption) {

          case 'select':

            const ChannelComponent = ChannelList.map((view,index) => (
              <div className={ChannelSelect==view.uuid?'ChannelInfo select':'ChannelInfo'}>
                <ViewInfo 
                  key={view.uuid} 
                  index={index} 
                  setSelect={(index,uuid,data)=>setChannelSelect(uuid)} 
                  spaceId={spaceId}
                  data={view}/>
              </div>
             ));   

             
            return (
              <div className='selectContainer'>
                  <p>연결할 채널을 선택해주세요</p>
                  <div className='channelList'>
                    {ChannelList.length != 0 ?ChannelComponent:<div>스페이스에 채널이 없습니다.<br/>새로운 채널을 생성해주세요</div>}
                  </div>
     
              </div>
              )
          case 'make':
            return (
              <div className='selectContainer'>
                <p>새로운 채널을 생성합니다.</p>
                <div id='OverlapCreateField'>

                  <p>채널 이름</p>
                  <input className='overlapTitleInput' placeholder={overlapData.title?overlapData.title+'의 채널':'새로운 프로젝트의 채널'} value={ChannelData.title} onChange={(e)=>changeChannel({key:'title',value:e.target.value})}/>
           
                  <div className='OverlapCreateOption'>
                  <GuideBalloon guideText='오버랩에서 설정한 해상도와 동일한 채널을 만듭니다.' direction='top'>
                    <p>생성한 오버랩의 설정에 맞춰 채널을 만듭니다.</p>
                  </GuideBalloon>
                    <span>
                    <label className="switch">
                      <input className="switch" type="checkbox" name="input1" value={1}  checked={('checked')} onChange={(e) => console.log(e)}  />
                      <span className="slider"/>
                    </label>  
     
                    </span>
     
                  </div>
                
                </div>
      
              </div>
              )
          case 'none':
              return (
                <div className='selectContainer'>
                  연결된 채널이 없는 상태로 생성합니다.<br/><small>오버랩 프로젝트 내에서 수정이 가능합니다.</small>
                </div>
                )
          default:
            break;
        }
      }


    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////
    return (
        <div id="overlapCreateScreen" className='overlapCreate SpaceWrap'>
            <style jsx>{`${SpaceModuleStyle}${ContainerStyle}`}</style>


            {/* <div className='overlapSetWrap'>
              <section className='overlapTitle'>
                <h3>
                  오버랩 라이브 프로젝트 시작하기
                </h3>
                <div>
                  <br/>
                <p>
                  온라인 방송 송출을 위해 사용되는 프로젝트 입니다. OBS, XSplit의 웹 브라우저 캡쳐 기능으로 사용할 수 있습니다.
                </p>
                </div>
              </section>
            </div> */}

            <div id="MainContent">
              {CreateStep==1 ? 
            <div className='overlapSetWrap'>
              <section className='overlapBasicField'>
                <h3>
                  프로젝트 생성
                </h3>
                <div  className= 'bothField'>
                <div className='overlapPreViewField'>
                  <OverlapInfo key={'preView'} setSelect={()=>(console.log('1'))} index={1} windowSize={null} data={{title:overlapData['title']==''?'새로운 프로젝트':overlapData['title'],resolution_width:overlapData.resolution.width,resolution_height:overlapData.resolution.height}} spaceId={'ex'}/>
                  <span className='preViewInfo' >{Editor.icon({icon:'resize',size:20,lineHeight:25})} <p>{overlapData.resolution.width}*{overlapData.resolution.height}</p></span>
                  </div>

                  <div className='overlapInputField'>
                    <p>프로젝트 이름</p>
                    <input className='overlapTitleInput' placeholder="새로운 프로젝트" value={overlapData.title} onChange={(e)=>handleTextChange(e)}/>
                    <p>프로젝트 방향</p>
                    <div className='buttonList'>

                      <button className={Direction == 'horizontal'?'select':'unselect'} onClick={()=>{setDirection('horizontal'); }}>
                      {Editor.icon({icon:'crop_landscape',size:25,lineHeight:30})}
                        가로
                      </button>
                      <button className={Direction == 'vertical'?'select':'unselect'} onClick={()=>{setDirection('vertical'); }}>
                      {Editor.icon({icon:'crop_portrait',size:25,lineHeight:30})}
                        세로
                      </button>
                      <button className={Direction == 'custom'?'select':'unselect'}  onClick={()=>{setDirection('custom'); }}>
                      {Editor.icon({icon:'crop_rotate',size:25,lineHeight:30})}
                        커스텀
                      </button>

                    </div>

                    <p>프로젝트 해상도</p>
                    {Direction == 'horizontal' || Direction == 'vertical' ? 

                    <div className='buttonList'>

                    <button className={Resolution.width == 1920 && Resolution.height == 1080 ?'select':'unselect' }  onClick={()=>{setResolution({width : 1920, height : 1080}); }}>
                    {Editor.icon({icon:'HD',size:25,lineHeight:30})}
                      HD 해상도
                    </button>
                    <button  className={Resolution.width == 3840 && Resolution.height == 2160 ?'select':'unselect' } onClick={()=>{setResolution({width : 3840, height : 2160}); }}>
                    {Editor.icon({icon:'4K',size:25,lineHeight:30})}
                      4K 해상도
                    </button>

                    </div>
                    
                    :null}
                    {Direction == 'custom' ? 
                    
                    <div className='customResolution'>
                        <input className='resolutionInput' type='number' value={overlapData.resolution.width} onChange={(e)=>changeOverlap({key:'resolution',subKey:'width',value:Number(e.target.value)})}/> 
                        {Editor.icon({icon:'close',size:20,lineHeight:30})}
                        <input className='resolutionInput' type='number' value={overlapData.resolution.height} onChange={(e)=>changeOverlap({key:'resolution',subKey:'height',value:Number(e.target.value)})}/> 
                    </div>
                    :null }
                    {/* <textarea  className='overlapSummaryInput' rows="4" maxlength="200" placeholder="오버랩에 대한 소개 (옵션) ">
                      이 오버랩 프로젝트에 대해 설명해주세요
                    </textarea> */}
                  </div>
                  
                </div>
              </section>
              </div>
              :null}
            {CreateStep==2 ? 
             <div className='overlapSetWrap'>
              <section className='ChannelBasicField'>
  
                <h3>
                채널 연결하기
                </h3>
                <small>
                  오버랩 프로젝트를 송출할 기본 채널을 설정합니다. 채널을 지정해야만 프로젝트의 송출된 화면이 보이는 인터넷 주소를 얻을 수 있습니다.
                </small>

                <div className='ChannelSetField'>
                  <div className='ChannelOptionSelectField'>
                    <div className={ChannelOption =='select' ? 'ChannelRadioButton select':'ChannelRadioButton'} onClick={()=>setChannelOption('select')}>
                        <p>기존 채널과 연결하기</p>
                      </div>

                      <div className={ChannelOption =='make' ? 'ChannelRadioButton select':'ChannelRadioButton'} onClick={()=>setChannelOption('make')}>
                        <p>새로운 채널 만들고 연결하기</p>
                      </div>

                      <div className={ChannelOption =='none' ? 'ChannelRadioButton select':'ChannelRadioButton'} onClick={()=>setChannelOption('none')}>
                          <p>연결하지 않기</p>
                      </div>
                  </div>
                <div className='ChannelSelectField'>
                  {channelControl()}
                </div>
                </div>

              </section>
            </div> :null}
            </div>

            <section id='CreateControlField'> 
            <div className='PrevButton'> 
            {CreateStep == 2 ? 
                <button onClick={()=>setCreateStep(1)}>
                  이전
                </button>
              :null}
            </div>
            <div className='CreateState'> 

            </div>
            <div className='NextButton'> 
            {CreateStep == 1 ? 
                <button onClick={()=>setCreateStep(2)}>
                  다음
                </button>
              :null}
            {CreateStep == 2 ? 
                <button onClick={()=>createOverlap()}>
                  프로젝트 생성
                </button>
              :null}
            </div>
        
            </section>  

        </div>
    );
  }

  export default OverlapCreate;

  
  const SpaceModuleStyle = `
  .overlapCreate {

  }
  
  `;
  
  const ContainerStyle = `
  @keyframes start {
    0% {
      opacity: 0;

    }
    100% {
      opacity: 1;

    }
  }

  .overlapCreateTest {
    animation: start 0.6s forwards;
      display: flex;
      justify-content: center;
      width: 100%;
  }

  .overlapSetWrap {
    width: calc(100% - 60px);
    margin : 20px;
    padding : 10px;
    border-radius: 10px;
    background: #2D2D2D;
  }
  

  .overlapSetWrap  .overlapTitle{
    width: calc(100% - 40px);
    margin : 20px;
    display: flex;
    flex-direction: column;
  }

  #overlapCreateScreen {
    display: flex;
    width: 100%;
    padding-right: 8px; /* 스크롤바의 너비 */
    height: calc( 100vh - 40px );
    overflow: hidden; /* 스크롤바를 기본적으로 숨깁니다. */
    flex-direction: column;
  }

  #overlapCreateScreen:hover {
    padding-right: 0px; /* 스크롤바의 너비 */
    overflow: auto; /* 마우스 오버 시 스크롤바를 표시합니다. */
  }

  /* 크로스 브라우저 호환성을 위한 스크롤바 스타일링 */
  .overlapCreateScreen::-webkit-scrollbar {
      width: 8px; /* 스크롤바의 너비 */
  }

  .overlapCreateScreen::-webkit-scrollbar-thumb {
      background-color: #888; /* 스크롤바의 색상 */
      border-radius: 4px; /* 스크롤바의 모서리 */
  }

  .overlapCreateScreen::-webkit-scrollbar-track {
      background: #1c1c1c; /* 스크롤 트랙의 색상 */
  }

  

  #overlapCreateScreen  .overlapTitleInput{
    width: 500px;
    height: 50px;
    margin : 10px 0px;
    background-color: #444;
    border: none;
    color : #ffffff;
    font-size : 30px;
    margin-top : 10px;
    line-height: 50px;
    border-radius: 5px;
    box-shadow: none;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-family: "프리텐다드SemiBold", sans-serif;

  }

  #overlapCreateScreen .bothField{
    display :flex;
    justify-content: center;
  }
  #overlapCreateScreen .overlapBasicField {
    margin: 20px;
  }

  #overlapCreateScreen .overlapPreViewField{
    display :flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 300px;

  }
  #overlapCreateScreen .overlapPreViewField .preViewInfo{
    display :flex;
    justify-content: center;
    align-items: center;
  }
  #overlapCreateScreen .overlapPreViewField .preViewInfo p{
    margin-left : 10px;
  }

  #overlapCreateScreen .overlapInputField .customResolution{
    display : flex;
    justify-content: start;
    align-items: center;
  }

  #overlapCreateScreen .overlapInputField .customResolution .resolutionInput{
    background-color: #444;
    border : none;
    color : #fff;
    height: 40px;
    border-radius: 5px ;
    margin: 10px 0px;
    font-size : 20px;
    width: 100px;
    text-align: center;
  }

  /* Chrome, Safari, Edge, Opera */
  #overlapCreateScreen .overlapInputField .customResolution input::-webkit-outer-spin-button,
  #overlapCreateScreen .overlapInputField .customResolution input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  /* Firefox */
  #overlapCreateScreen .overlapInputField .customResolution input[type=number] {
    -moz-appearance: textfield;
  }




  

  #overlapCreateScreen .overlapFileExample{
    position: relative;
    width: 192px;
    height: 108px;
    background-color: rgb(28, 28, 28);    
  }

  #overlapCreateScreen .overlapInputField{
    display: flex;
    flex-direction: column;
    flex : 1; 
    margin : 0px 20px;
  }

  #overlapCreateScreen .overlapBasicField button {
    width: 100px;
    height: 70px;
    color : #fff;
    background-color: #363636;
    margin : 10px 10px 10px 0px;
    border-radius: 5px ;
  }

  #overlapCreateScreen .select {
    background-color: rgb(28, 28, 28);    
  }

  

  #overlapCreateScreen .preView{
    position: absolute;
    width: 192px;
    height: 108px;
    overflow: hidden;
    border-radius: 5px ;
    transition: all 0.5s;
}

 #overlapCreateScreen.readyPreView{
      background-color: rgb(28, 28, 28);
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto;
  }


  #overlapCreateScreen .ChannelBasicField {
    margin: 10px;
  }

  #overlapCreateScreen .ChannelSetField {
    display : flex;
    background-color: #222;
    border-radius: 5px ;
    margin : 10px 0px;
    padding : 0px 10px;
    
  }

  #overlapCreateScreen .ChannelSetField .ChannelRadioButton{
    display : flex;
    width: 300px;
    height: 50px;
    margin : 10px 0px;
    align-items: center;
    background-color: #363636;
    border-radius: 5px ;
    padding-left : 10px;
  }

  #overlapCreateScreen .buttonList .select{
    background-color: rgb(28, 28, 28);
  }
  #overlapCreateScreen .ChannelSetField .ChannelRadioButton.select{
    background-color: rgb(28, 28, 28);
  }



  #overlapCreateScreen .ChannelSetField  .ChannelSelectField{
    width: 100%;
    max-height: 500px;
    margin : 10px 0px;
    margin-left : 10px;
    border-radius: 5px ;
    background-color: #363636;
    overflow : auto;
  }
  #overlapCreateScreen .selectContainer{
    margin : 10px;
    
  }


  #overlapCreateScreen .ChannelInfo{
    padding :20px;
  }
  #overlapCreateScreen .ChannelInfo.select{
    border-radius: 10px;
  }

  


  #overlapCreateScreen .selectContainer .channelList{
    width: 100%;
    padding-top: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    grid-auto-rows: 170px;
  }



  #overlapCreateScreen  #CreateControlField  {
    display: flex;
    width: calc(100% - 40px) ;
    padding : 0px 20px;
    justify-content: space-between;
    height: 100px;
    margin-top: 20px;
  }


  #overlapCreateScreen  #CreateControlField .PrevButton{
    color: white;
    width: 150px;
  }
  #overlapCreateScreen  #CreateControlField .PrevButton button{
    color: white;
    width: 150px;
    height: 50px;
    background-color: #363636;
    border-radius: 10px;
  }

  #overlapCreateScreen  #CreateControlField .CreateState{
    color: white;
    flex : 1;
    text-align: center;
  }

  #overlapCreateScreen  #CreateControlField .NextButton{
    color: white;
    width: 150px;
  }
  #overlapCreateScreen  #CreateControlField .NextButton button{
    color: white;
    width: 150px;
    height: 50px;
    background-color: #363636;
    border-radius: 10px ;
  }


  /* 채널 필드 */

  #overlapCreateScreen #OverlapCreateField{
    margin : 20px 0px;

  }
  
  #overlapCreateScreen #OverlapCreateField .OverlapCreateOption{
    display: flex;
    width: 100% ;
    justify-content: space-between;
    height: 50px;

    align-items: center;
    max-width: 500px ;

  }

  #overlapCreateScreen .switch{
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
    
  }

  #overlapCreateScreen .switch input {
      width: 0;
      height: 0;
      opacity: 0;

    }
  #overlapCreateScreen .switch .slider {

        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $EditModuleButton;
        -webkit-transition: .4s;
        transition: .4s;
        border-radius: 34px;
    }
    #overlapCreateScreen .switch .slider:before {
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.45), 0 3px 3px rgba(0, 0, 0, 0.15);
        position: absolute;
        content: "";
        height: 16px;
        width: 16px;
        left: 2px;
        bottom: 2px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
        border-radius: 50%;
        
      }
    #overlapCreateScreen .switch input:checked + .slider {
        background-color: orange;
      }s
      
      #overlapCreateScreen .switch input:focus + .slider {
        box-shadow: 0 0 1px orange;
      }
      
      #overlapCreateScreen .switch input:checked + .slider:before {
        -webkit-transform: translateX(20px);
        -ms-transform: translateX(20px);
        transform: translateX(20px);
      }
  
}




  `;