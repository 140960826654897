////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component, Fragment , Profiler, createContext} from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { Route, Link } from 'react-router-dom';
import _ from 'lodash';
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/edit.scss"


// 컴포넌트 연결

// 서비스 연결

import * as Event from 'service/event/Actions' ; 
import * as Utility from'service/other/Utility.js' ; 
import * as viewAPI from 'service/api/view' ; 
import * as spaceAPI from 'service/api/space' ; 
import * as pageAPI from 'service/api/page' ; 
import { token } from 'service/event/Account'; 
import AssistContext from 'service/context/AssistContext';
import { widget } from 'service/value/Model';
import { object } from 'service/event/template';


////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////


function AssistProvider(props) {

    const [ZoomLevel, setZoomLevel] = React.useState(1);
    const [WidgetCache, setWidgetCache] = React.useState({});
    const [EidtMode, setEidtMode] = React.useState('normal');
    // const [mousePoint, setMousePoint] = React.useState({});
    // const [mousePoint, setMousePoint] = React.useState({});


    /**
     * 편집화면에서 저장하지 않고 일시적으로 표시해야 하는 위젯의 상태를 기록하는 곳 입니다.
     * @param {uuid} uuid 
     * @param {*} widgetState 
     */
    const editWidgetCache = (uuid,widgetState) =>{
        console.log('editWidgetCache',uuid,widgetState);
        const modifyCache = {...WidgetCache};
        modifyCache['uuid'] =  Object.assign( widgetState, modifyCache['uuid']);
        setWidgetCache(modifyCache)
    } 

    const zoomControl = (e,increase) =>{
        setZoomLevel(ZoomLevel + increase) 
    }

    const delWidgetCache = (uuid) =>{

    }

    const contextMenu = (target,x,y,uuid=null) =>{

    }





    // Providing both state and function to change it
    return (
        <AssistContext.Provider value={{ WidgetCache, editWidgetCache, zoomControl }}>
            {props.children}
        </AssistContext.Provider>
    );
}

export default AssistProvider;

////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
