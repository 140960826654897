////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [프로바이더] 연결
import EditContext from 'service/context/EditContext' ; 

// SASS&CSS 연결
import "sass/edit.scss"


// 컴포넌트 연결
import TextEdit from '../../../../editForm/TextEdit'; // 메뉴 헤더
import PositionEdit from '../../../../editForm/PositionEdit'; // 메뉴 헤더
import InteractivityGuide from '../../../../editController/InteractivityGuide'; // 가이드

// 서비스 연결
import * as EnCoder from 'service/engine/EnCoder' ; 
import * as DeCoder from 'service/engine/DeCoder' ; 
import * as Utility from 'service/other/Utility' ; 
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class TapEdit extends Component {
    static contextType = EditContext;

    // state = {
    //     editObj : this.props.screenData[this.props.selectTemplate],
    //     editType : this.props.screenData[this.props.selectTemplate].type,
    //     keyList : Object.keys(this.props.screenData[this.props.selectTemplate].data),
    // }


    constructor(props) {
        super(props);
        this.myRef = React.createRef();
      }

    ////////////////////////////////////////////////////////
    // 디스패치 함수, 다중디스패치 포함,
    ////////////////////////////////////////////////////////
    dispatch = (property,arg1=null,arg2=null) => {

        let modifyWidgetList = this.context?.nowPage().page_widget;           
        const SelectWidget = this.context.selectWidget;
        const widgetIndex = modifyWidgetList.findIndex(item => item.uuid === SelectWidget);

        // let temp = Utility.deepCopy(this.props.screenData)
        if(arg2==null&&typeof(arg1)=='object'){
            let nameList = Object.keys(arg1);
            for (let index = 0; index < nameList.length; index++) {
                let name = nameList[index]
                let value = arg1[nameList[index]]
                modifyWidgetList[widgetIndex].data[property][name] = value
            }
            this.context.modifyPage({'page_widget':modifyWidgetList},'modifyWidget')
        } else if(typeof(arg1)=='object'){
            console.log('property : ',property,arg1,arg2);
            console.error('심각한 오류 : 위젯편집기를 통해 다중값을 전달 받았으나 arg2 인자가 존재함')
            return 
        } else {
            // 하나의 값을 변경하는경우
            let name = arg1
            let value = arg2

            if(property=='preset'){
                modifyWidgetList[widgetIndex].data['preset'] = arg2
                this.context.modifyPage({'page_widget':modifyWidgetList},'modifyWidget')
                return
            }
            modifyWidgetList[widgetIndex].data[property][name] = value
            this.context.modifyPage({'page_widget':modifyWidgetList},'modifyWidget')
            return
        }
    }

    
    multiDispatch = (request) => {
        let history = ''
        console.log('modifyWidgetList',request);
        let modifyWidgetList = this.context?.nowPage().page_widget;           
        const SelectWidget = this.context.selectWidget;
        const widgetIndex = modifyWidgetList.findIndex(item => item.uuid === SelectWidget);
            for (const req of request) {
                const { name, property, value } = req;
                try {
                    modifyWidgetList[widgetIndex].data[property][name] = value;
                } catch (error) {
                    console.error('사이드바에서 값 변경중 에러발생 :',error);
                }
            }
            this.context.modifyPage({'page_widget':modifyWidgetList},'modifyWidget')
            return
    }
    ////////////////////////////////////////////////////////
    // 인터렉티브 가이드 컨트롤
    ////////////////////////////////////////////////////////

    render() { 
        
        // EDITTYPE 사이드바의 종류를 정의합니다. TapEdit 컴포넌트에서는 'edit' 관련 컴포넌트를 호출합니다.
        const EDITTYPE = 'edit';
        const NOWPAGE = this.context?.nowPage();
        const SELECTWIDGET = this.context?.selectWidget;
        const TargetIndex = NOWPAGE.page_widget.findIndex(item => item.uuid === SELECTWIDGET);
        
        let sidebarComponent = [];

        if(NOWPAGE!=null&&TargetIndex!=null){
            sidebarComponent = DeCoder.sidebar(this,NOWPAGE.page_widget[TargetIndex],EDITTYPE)
        }else{
            // 선택된 객체가 없거나 선택된 페이지가 없는경우 에러처리
        }
        
        return (
            <div id = "WidgetSet" ref={this.myRef}>
                {/* {this.props.selectTemplate} */}
                {sidebarComponent}
                {/* <InteractivityGuide left={200} top={top} type={type} data={null}/> */}
            </div>
        );
    }
}
////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    selectTemplate : state.selectTemplate,
    screenData : state.screenData,
    editGuide : state.editGuide,
})

function mapDispatchToProps(dispatch){
    return {
        // screenDataDispatch : (screenData) => dispatch({type:'screenData',data : screenData}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(TapEdit);
  