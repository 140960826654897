////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { useState, useEffect, useContext, Fragment } from 'react';

// SASS&CSS 연결
// import "sass/edit.scss"

// 이미지 연결
import logoW from 'assets/logoW.svg';

// [컴포넌트] 위젯 
import TextBalloon from 'components/common/form/TextBalloon'

// 서비스 연결
import * as Editor from 'service/edit/EditService'; 
import * as widgetModel from 'service/model/widgetModel'
import { dragTransparent } from 'service/event/common' ; 
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
function ListOrder({uuid,widget,select,setSelect,context,aliasChange,aliasCheck}) {

    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////
    //set

    // state
    // const [WidgetList, setWidgetList] = useState([]);
    

    /* EditContext 가 비동기 로딩으로 인해서 로딩중일때 오류를 막기 위한 State */
    const [ContextReady, setContextReady] = useState(false);
    const [Alias, setAlias] = useState(widget.alias);
    const [Duplicate, setDuplicate] = useState(false);

    // rel 

   ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////
    useEffect(() => {
        // 여기에 컴포넌트가 마운트되었을 때 실행할 코드를 작성합니다.
        duplicateCheck(widget.alias)
        return () => {
        // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
        };
      }, []);
    

    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////
  
    /**
     * 편집 프로바이더에서 위젯의 목록을 가저옵니다. 
     * 이후 위젯 리스트를 만들기 위해 데이터를 가공합니다.
     */
    // const getWidgetList = () =>{
    //     if(ContextReady){
    //         const widgetList = context?.nowPage()?.page_widget;
    //         return widgetList?.slice()?.reverse()??[];
    //     }else{
    //         return []; 
    //     }
    // }

    /**
     * 편집 프로바이더에서 오버랩의 위젯을 수정합니다.
     */
    // const update = (modifyWidgetList) => {
    //     if(ContextReady){
    //         const widgetList = modifyWidgetList.slice().reverse();
    //         context?.modifyPage({'page_widget': widgetList}, 'modifyOrder');
    //     }
    // };

    const duplicateCheck = (alias) => {
        setAlias(alias);
        const result = aliasCheck(uuid,alias);
        setDuplicate(result)
    }

    const aliasControl = (alias) => {
        setAlias(alias);
        const result = aliasChange(uuid,alias);
        setDuplicate(result)
    }


    let Reference =  widgetModel?.getReference()?.[widget?.type]
    if(!Reference){
      Reference =  widgetModel?.getReference().Unknown
    }

    const TargetReference  = Reference?.version[widget?.version??Reference?.default]

    return (
        <Fragment>
            
        <div className={select? 'selectListOrder ListOrder': 'ListOrder'} onDragStart={dragTransparent} onClick={()=>setSelect(uuid)} onContextMenu={(e)=>{setSelect(uuid);context(e)}}>   
            <div className='ListInfo'>  
            <div className='ListState'>  
                    {Editor.icon({icon:'texture',size:15,lineHeight:20})}
                    {Editor.icon({icon:'animation',size:15,lineHeight:20})}
            </div>
            <input className='ListTitle' style={{color:(Duplicate&&Alias!=null?'red':'white')}} placeholder={TargetReference?.alias??widget.type} value={Alias} onChange={(e)=>duplicateCheck(e.target.value)} onBlur={(e)=>aliasControl(e.target.value)}/>
            </div>
            <div className='handle'>  
                {Editor.icon({icon:'drag_indicator',size:20,lineHeight:30})}
            </div>
        </div>
        </Fragment>
    );

}

////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    // mainScreen : state.mainScreen,
})

function mapDispatchToProps(dispatch){
    return {
        // initOverlap : (overlapData) => dispatch({type:'initOverlap',overlap : overlapData}),
    }
}

  // export default EditPage;
export default ListOrder;
  
  const EditPageStyle = `




  `;