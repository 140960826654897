////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component, Fragment , useState, useEffect, useRef} from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { useHistory,useLocation } from 'react-router-dom';
// 라이브러리 
import { useResizeDetector } from 'react-resize-detector';// 컴포넌트의 사이즈 감지
// [리덕스]스토어 연결
import store from "store";
// [프로바이더]
import EditProvider from 'service/provider/EditProvider';

// SASS&CSS 연결
// import "sass/edit.scss"



// 이미지 연결
import logoW from 'assets/logoW.svg';

// 컴포넌트 연결
import PreviewDisplay from 'components/display/PreviewDisplay' // 오버랩 디스플레이
import StaticDisplay from 'components/display/StaticDisplay'; // 오버랩 디스플레이
import EditContainer from 'components/Edit/editScreen/EditContainer'; // 사용자가 보는 편집화면
import LeftPanelArea from 'components/Edit/editPanel/preferences/LeftPanelArea'; // 왼쪽 편집 패널
import BottomPanelArea from 'components/Edit/editPanel/append/BottomPanelArea'; // 오른쪽 편집 패널
import ExtendPanelArea from 'components/Edit/editPanel/preferences/ExtendPanelArea'; // 왼쪽 편집 패널


import ContextMenu from 'components/Edit/contextMenu/ContextMenu'; // 컨텍스트 메뉴


// 서비스 연결
import * as Editor from 'service/edit/EditService' ; 

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
function UploadPage() {


    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////


    //set

    const location = useLocation();
  
    const uuID = location?.state?.uuid ?? null;
    const overlapID = location?.state?.id ?? null;
    const spaceID = location?.state?.spaceId ?? null;

 
    
    // state
    const [SizeDetector, setSizeDetector] = useState({});
  

    // rel 
    const PageRef = useRef(null); // 업로드 페이지 가장 최상단의 Ref (기본 이벤트 차단)

   ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////
    useEffect(() => {
 
      }, []);

    const objectDrop = (e) =>{
      e.preventDefault();
      e.stopPropagation();
    }


    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////
    return (
        <Fragment>
                <style jsx>{`${EditPageStyle}`}</style>
                {/* <EditProvider uuid={uuID} overlapID={overlapID} spaceId={spaceID} > */}
                  
                <div id = "UploadPage" >
                  <div id = "TemplateInfoSetting" >

                    <div id="TemplateProfileImage">
                    {/* <PreviewDisplay pageList={[]} referenceWidth={MYOVERLAP.meta.resolution.width} referenceHeight={MYOVERLAP.meta.resolution.height}/>                */}
                    </div>
                    <div id="TemplateProfileInfo">
                      <div className='nameField'>
                      <p>템플릿 이름</p>
                      {/* <input placeholder={MYOVERLAP.title}/> */}
                      </div>
                      <div className='summaryField'>
                      <p>템플릿 설명</p>
                      <textarea wrap="soft" />
                      </div>
                      <div className='tagField'>
                      <p>태그</p>
                      
                    </div>
                    </div>
                  </div>
     
                  <div id = "TemplatePagesSetting" >
                    <div className = "overlapNav" >
                    <p className = "NavButtons" >
                    {Editor.icon({icon:'stack',size:25,lineHeight:30})}
                    {/* 페이지 */}
                    </p>
                    <p className = "NavButtons" >
                    {Editor.icon({icon:'function',size:25,lineHeight:30})}
                    </p>
                    </div>
                    <div className = "overlapPages" >
                      {/* {List} */}
                    </div>
 
                  </div>


                </div>
       
            {/* </EditProvider> */}
        </Fragment>
    );

}

////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    mainScreen : state.mainScreen,
})

function mapDispatchToProps(dispatch){
    return {
        initOverlap : (overlapData) => dispatch({type:'initOverlap',overlap : overlapData}),
    }
}

  // export default EditPage;
export default connect(mapStateToProps,mapDispatchToProps)(UploadPage);
  


  const EditPageStyle = `


  #TemplateUpload {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding : 2

  }
  #TemplateUpload .contents{
    padding : 20px;
  }

  #TemplateUpload #TemplateInfoSetting{
    width: 100%;
    height: 30%;
    background : #3F3F3F;
    display: flex;
    justify-content : center;
    align-items : center;
  }
  #TemplateUpload #TemplatePagesSetting{
    flex:1;


  }
  #TemplateProfileImage{
    width: 50%;
    height: 80%;
  }
  #TemplateProfileInfo{
    width: 30%;
    height: 80%;
  }

  #TemplateProfileInfo .nameField{
    width: 100%;
    display:flex;
    flex-direction: column;
  }

  #TemplateProfileInfo .nameField p{
  }

  #TemplateProfileInfo .nameField input{
    background-color : #444;
    border : none;
    height: 50px;
    color : #ffffff;
    font-size : 30px;
    margin-top : 10px;
    line-height: 50px;
    border-radius: 5px;
    box-shadow: none;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 300px;

    font-family: "프리텐다드SemiBold", sans-serif;
  }

  #TemplateProfileInfo .summaryField{
    width: 100%;
    display:flex;
    flex-direction: column;
  }

  #TemplateProfileInfo .summaryField p{
    margin-top : 10px;
  }

  #TemplateProfileInfo .summaryField textarea{
    background-color : #444;
    border : none;
    resize: none;
    height: 100px;
    color : #ffffff;
    font-size : 15px;
    margin-top : 10px;
    line-height: 15px;
    border-radius: 5px;
    box-shadow: none;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 300px;
    
    font-family: "프리텐다드SemiBold", sans-serif;
  }


  #TemplatePagesSetting .overlapNav{
    width: calc(100% -40px);
    margin : 0px 20px;
    height: 30px;
    border-bottom : 1px solid #fff;
    display: flex;
  }

  .NavButtons{
    width: 30px;
    height: 30px;
    padding : 0px 10px;
  }
  #TemplatePagesSetting .Pages{
    width: 220px;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content : center;
    align-items : center;
  }
  #TemplatePagesSetting .Pages .display{
    width: 192px;
    height: 108px;
  }
  #TemplatePagesSetting .Pages .title{
    width: 192px;
    margin :10px;
    height: 30px;
    background-color : #444;
    border : none;
    border-radius : 5px;
    color : #fff;
  }
  #TemplatePagesSetting .Pages .title::placeholder{
    color : #aaa;
  }
  .overlapPages{
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap:wrap;
    justify-content : center;
    align-items : center;
  }
  `;