// 리엑트 연결

import { widget } from "service/value/Model";

// 서비스 연결
import * as Utility from 'service/other/Utility';
import * as Model from 'service/value/Model' ;  
////////////////////////////////////////////////////////
// store 연결
////////////////////////////////////////////////////////
import store from "store";

////////////////////////////////////////////////////////

// 테스트 구문
export function connectTest() {
    console.log('Eecoder : 연결테스트')
  }

function getPageIndex (context,screenUUID) {
    const orderArray = context.overlap?.content?.order??[];
    const index = orderArray.indexOf(screenUUID);
    if (index !== -1) {
        return index;
    } else {
        console.error(`${screenUUID}은(는) 배열에 없습니다.`);
        return null;
    }
}

export function copy(context,screenUUID) {
    //TODO : screenUUID 에서 더 정확하게 값을 받아야함
    const targetScreen = context.overlap.content.page.filter((screen, index) => screen.uuid === screenUUID);
    console.log('스크린 사이즈',targetScreen)
    if(targetScreen.length == 0){
      console.error(`선택한 스크린을 찾을 수 없습니다. 선택한 스크린 의 ID : ${screenUUID} `)
      return
    }
    const copyScreen = Utility.deepCopy(targetScreen[0]);
    context.appendClipBoard('screen',copyScreen);
}

/**
 * 페이지를 복사하고 붙여 넣습니다.
 * 
 */
export function duplicate(context,screenUUID) {

    const TargetIndex = getPageIndex(context,screenUUID)
    //TODO : screenUUID 에서 더 정확하게 값을 받아야함
    const targetScreen = context.overlap.content.page.filter((screen, index) => screen.uuid === screenUUID);

    if(targetScreen.length == 0){
      console.error(`선택한 스크린을 찾을 수 없습니다. 선택한 스크린 의 ID : ${screenUUID} `)
      return
    }
    const copyScreen = Utility.deepCopy(targetScreen[0]);
    // const OverlapOrder = context.overlap?.content?.order.filter(item => item = uuid);
    context.addPage(TargetIndex,copyScreen)
   
}

/**
 * 페이지를 삭제합니다.
 */
export function del(context,screenUUID) {
    context.deletePage(screenUUID)
}


