////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, {useState, useEffect, useRef } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { Route, Link } from 'react-router-dom';
import { useHistory } from "react-router";
// 라이브러리 
import { useGoogleLogin } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google'// 구글 로그인
// 설정 파일
import * as config from 'config/OverlapConfig'

import { withRouter } from 'react-router-dom';

import * as apiAccount from 'service/api/apiAccount'

// [컴포넌트] 위젯 
import PreviewDisplay from 'components/display/PreviewDisplay' // 오버랩 디스플레이
import StaticDisplay from 'components/display/StaticDisplay'; // 오버랩 디스플레이
import OverlapInfo from "components/Lobby/space/module/OverlapInfo"
import GuideBalloon from "components/common/form/GuideBalloon"
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import 'sass/login.scss'

// 이미지 연결


// 서비스 연결
import * as templateAPI from 'service/api/template' ; 
import * as Editor from 'service/edit/EditService'; 
import * as overlap from 'service/api/overlap';
import * as Template from 'service/edit/TemplateService'; 
import * as Utility from 'service/other/Utility';
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

function SelectPage({spaceID,targetID,uploadTemplate}) {

    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////
    // state

    const [TargetTemplate, setTargetTemplate] = useState(null);

    const [DisablePage, setDisablePage] = useState([]);

    const [Loading, setLoading] = useState(false);
    // rel 
    ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////

    useEffect(() => {
        console.log('Component did mount or update');
        getOverlap()
        // Cleanup function (optional)
        return () => {
          console.log('Component will unmount');
        };
      },[]);


      
    ////////////////////////////////////////////////////////
    // Fuction
    ////////////////////////////////////////////////////////
    
    const getOverlap = async()=>{
      const Overlap = await overlap.get(targetID,spaceID);
      setTargetTemplate(Template.convert(Overlap));
    }


    const handleChildClick = (id,uuid,data) => {
        console.log("Child clicked with data:", id,uuid,data );
    };
  

    const setTitle = (value) => {
      const ModifyTemplate = Utility.deepCopy(TargetTemplate);
      ModifyTemplate.title = value
      setTargetTemplate(ModifyTemplate);

    }
    const setSummary = (value) => {
      const ModifyTemplate = Utility.deepCopy(TargetTemplate);
      ModifyTemplate.summary = value
      setTargetTemplate(ModifyTemplate);
    }

    const setPage = (value,index) => {
      const ModifyTemplate = Utility.deepCopy(TargetTemplate);

      if(value?.page_title!=undefined){
        ModifyTemplate.content.page[index].page_title = value['page_title'];
      }
      if(value?.page_summary!=undefined){
        ModifyTemplate.content.page[index].page_summary = value['page_summary'];
      }

      console.log('체커22',  ModifyTemplate.content.page[index],'value?.page_title',value?.page_title,'value?.page_summary',value?.page_summary);

      setTargetTemplate(ModifyTemplate);
  };

    const upload = () => {
      templateAPI.create(spaceID,TargetTemplate)
      uploadTemplate();
    }
  
    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////

    const List = TargetTemplate?.content?.page.map((page,index)=>{return(
      <div className = "Pages" key={page.uuid} >
      <div className = "PagesDisplay">
        <StaticDisplay page={page} previewWidth={192} previewHeight={108} referenceWidth={TargetTemplate.meta.resolution.width} referenceHeight={TargetTemplate.meta.resolution.height} option={{align:'center'}} />
          {/* 
          <div className='PagesOption'>
          <button className='PagesButton'>
            {Editor.icon({icon:'cloud_off',size:20,lineHeight:25})}
          </button>
          </div> */}
      </div>
      <input className = "PagesTitle" value={page.page_title}  placeholder={'페이지 이름'} onChange={(e)=>setPage({'page_title':e.target.value},index)}/>
      <textarea className = "PagesSummary" value={page.page_summary}  placeholder={'페이지에 대한 설명'}  wrap="soft" onChange={(e)=>setPage({'page_summary':e.target.value},index)}/>

    </div>
   )})


    return (


            <div id = "UploadSelectPage" >
            <style jsx>{`${SpaceModuleStyle}${ContainerStyle}`}</style>
                  <div id = "TemplateInfoSetting" >

                    <div id="TemplateProfileImage">
                    <PreviewDisplay pageList={[]} referenceWidth={TargetTemplate?.meta?.resolution?.width??0} referenceHeight={TargetTemplate?.meta?.resolution?.height??0}/>               
                    </div>
                    <div id="TemplateProfileInfo">
                      <div className='nameField'>
                      <p>템플릿 이름</p>
                      <input value={TargetTemplate?.title} onChange={(e)=>setTitle()}/>
                      </div>
                      <div className='summaryField'>
                      <p>템플릿 설명</p>
                      <textarea wrap="soft" value={TargetTemplate?.summary} onChange={(e)=>setSummary(e.target.value)}/>
                      </div>
                    </div>
                  </div>
     
                  <div id = "TemplatePagesSetting" >
                    <div className = "overlapNav" >
                      <div className='leftNav'>
                        <p className = "NavButtons" >
                        {Editor.icon({icon:'stack',size:25,lineHeight:30})}
                        {/* 페이지 */}
                        </p>
                        <p className = "NavButtons" >
                        {Editor.icon({icon:'function',size:25,lineHeight:30})}
                        </p>
                      </div>
                      <div className='rightNav'>
                      <p className = "uploadButtons" onClick={()=>upload()} >
                        {Editor.icon({icon:'upload',size:25,lineHeight:30})}
                        업로드
                        </p>
                      </div>
                    
          
                    </div>
                    <div className = "overlapPages" >
                      {List}
                    </div>
 
                  </div>
            </div>
    );
  }

  export default SelectPage;
////////////////////////////////////////////////////////
// css 부분
////////////////////////////////////////////////////////
const SpaceModuleStyle = `


`;


const ContainerStyle = `
  

#UploadSelectPage {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding : 2
  
  }
  #UploadSelectPage .contents{
    padding : 20px;
  }
  
  #UploadSelectPage #TemplateInfoSetting{
    width: 100%;
    height: 300px;
    background : #3F3F3F;
    display: flex;
    justify-content : center;
    align-items : center;
    padding-top : 80px;
  }
  #UploadSelectPage #TemplatePagesSetting{
    height: calc(100% - 420px);
  
  }
  #TemplateProfileImage{
    width: 50%;
    height: 80%;
  }
  #TemplateProfileInfo{
    width: 30%;
    height: 80%;
    display:flex;
    flex-direction: column;
    justify-content : center;
    align-items : space-between;
  }
  
  #TemplateProfileInfo .nameField{
    width: 100%;
    display:flex;
    flex-direction: column;
  }
  
  #TemplateProfileInfo .nameField p{
  }
  
  #TemplateProfileInfo .nameField input{
    background-color : #444;
    border : none;
    height: 50px;
    color : #ffffff;
    font-size : 30px;
    margin-top : 10px;
    line-height: 50px;
    border-radius: 5px;
    box-shadow: none;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 300px;
  
    font-family: "프리텐다드SemiBold", sans-serif;
  }
  
  #TemplateProfileInfo .summaryField{
    width: 100%;
    display:flex;
    flex-direction: column;
  }
  
  #TemplateProfileInfo .summaryField p{
    margin-top : 10px;
  }
  
  #TemplateProfileInfo .summaryField textarea{
    background-color : #444;
    border : none;
    resize: none;
    height: 100px;
    color : #ffffff;
    font-size : 15px;
    margin-top : 10px;
    line-height: 15px;
    border-radius: 5px;
    box-shadow: none;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 300px;
    
    font-family: "프리텐다드SemiBold", sans-serif;
  }
  
  
  #TemplatePagesSetting .overlapNav{
    width: calc(100% - 40px);
    padding : 0px 20px 10px;
    height: 30px;
    display: flex;
    background : #3F3F3F;
    justify-content: space-between;

  }

  #TemplatePagesSetting .overlapNav .leftNav{
    display: flex;
  }
  
  #TemplatePagesSetting .overlapNav .rightNav{
    display: flex;
  }
  #TemplatePagesSetting .overlapNav .uploadButtons{
    height: 30px;
    padding : 0px 10px;
    display: flex;
    background-color : orange;
    justify-content : center;
    align-items : center;
    border-radius : 5px;
    
  }

  #TemplatePagesSetting .overlapNav .NavButtons{
    width: 30px;
    height: 30px;
    padding : 0px 10px;
  }
  #TemplatePagesSetting .Pages{
    width: 220px;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content : center;
    align-items : center;
  }
  #TemplatePagesSetting .Pages .PagesDisplay{
    width: 192px;
    height: 108px;
    position: relative;
  }
  #TemplatePagesSetting .Pages .PagesOption{
    width: 192px;
    height: 108px;
    position: absolute;
    background-color : #2D2D2Dcc;
    top : 0;
  }
  #TemplatePagesSetting .Pages .PagesTitle{
    width: 192px;
    margin-top :10px;
    height: 30px;
    background-color : #2D2D2D;
    border : none;
    border-bottom : 1px solid gray;
    color : #fff;
  }
  #TemplatePagesSetting .Pages .PagesTitle::placeholder{
    color : #aaa;
  }

  #TemplatePagesSetting .Pages .PagesSummary{
    width: 192px;
    margin-top :5px;
    height: 60px;
    resize: none;
    color : #fff;
    background-color : #2D2D2D;
    border:none;
  }

  #TemplatePagesSetting .Pages .PagesButton{
    background-color : #2D2D2D;
    padding : 2.5px;
    height: 30px;
    width: 30px;
    border-radius: 5px;
    margin : 2.5px;
    display: flex;
    flex-direction: column;
    justify-content : center;
    align-items : center;
  }


  .overlapPages{
    height: calc(100%);
    overflow : auto;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    grid-auto-rows: 250px;
  }
`;