////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widgetEdit.scss"


// 컴포넌트 연결
// import Show from '../show/Show'; // 메뉴 헤더

// 서비스 연결

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class RangeModule extends Component {

    render() { 
   
        let step = 1
        if(this.props.step){
            step = this.props.step
        }
         
        return (
            <label className="EditModule RangeModule" htmlfor="fname"> 
                <p className="editNav" >{this.props.title?this.props.title:'색상'}</p>
                <div className="valueView">
                    
                {(this.props.startImage?<span className='startImage'><img src={this.props.startImage}/></span>:'')}
         
                <span>
                    {(this.props.valueView?this.props.value+''+this.props.valueView+' ':'')}
                </span>
                <input type="range" className="rangeInput" name="volume" min={this.props.min} step={step} max={this.props.max} value={this.props.value} onChange={(e) => this.props.change(this.props.type,e.target.value)} />

                {(this.props.endImage?<span className='endImage'><img src={this.props.endImage}/></span>:'')}
                </div>

            </label>
        );
    }
}

////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    // screenData : state.screenData,
})

function mapDispatchToProps(dispatch){
    return {
    //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(RangeModule);
  