////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widgetEdit.scss"


// 컴포넌트 연결
import TextModule from '../editModule/TextModule'; // 텍스트 모듈
import TextSizeModule from '../editModule/TextSizeModule'; // 텍스트 크기 모듈
import FontModule from '../editModule/FontModule'; // 텍스트 크기 모듈
import ColorModule from '../editModule/ColorModule'; // 색상 모듈
import SwitchModule from '../editModule/SwitchModule'; // 숫자 모듈
import RangeModule from '../editModule/RangeModule';

// 서비스 연결
import * as Editor from 'service/edit/EditService'; 
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class TextStyleEdit extends Component {
    
    state = {
        editorToggle : true
    }


    change = (name,value) =>{
        this.props.callBack('style',name,value)
    }

    render() { 

        const Style = {
            label: {
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
            },
            textarea : {
                width: '100%',
                resize: 'none'
            }

        }
        return (
            <section className='TextStyleEdit'>
            {Editor.WidgetEditTitle(this,{title:'텍스트 스타일',toggle:false,icon:'title'})}
            {Editor.WidgetEditSubhead({text:'텍스트 설정',icon:"edit_note"})}
            <div className={this.state.editorToggle?'editContainer containerOpen':'editContainer containerClose'}>
            <article className="EditPart MediaSourceModule" >
                <ColorModule value={this.props.data.style.color} type ="color" change={this.change}/>
                <TextSizeModule value={this.props.data.style.fontSize} type ="fontSize" change={this.change}/>
                <FontModule  value={this.props.data.style.fontFamily} type ="fontFamily" change={this.change}/>
            </article>
            {Editor.WidgetEditSubhead({text:'외곽선',icon:"border_style"})}
            <article className="EditPart MediaSourceModule" >
                <RangeModule value={this.props.data.style.textOutline} type ="textOutline"valueView="%" min="0" max="150" title="선두께" step="10" change={this.change} />
                <ColorModule value={this.props.data.style.outlineColor} type ="outlineColor" title="선색상" change={this.change}/>
            </article>
            </div>
        </section>

            // <Fragment>
            // <fieldset className="EditModuleField" style={Style.fieldset} >
            //     <legend style={Style.legend} > 텍스트 </legend>
            //     <ColorModule value={this.props.data.style.color} type ="color" change={this.change}/>
            //     <TextSizeModule value={this.props.data.style.fontSize} type ="fontSize" change={this.change}/>
            //     <FontModule  value={this.props.data.style.fontFamily} type ="fontFamily" change={this.change}/>
            // </fieldset>

            // <details>
            // <summary>
            //     외곽선
            // </summary>
            // <fieldset className="EditModuleField" style={Style.fieldset} >
            // <legend >외곽선</legend>
            // <RangeModule value={this.props.data.style.textOutline} type ="textOutline"valueView="%" min="0" max="150" title="두께" step="10" change={this.change} />
            // <ColorModule value={this.props.data.style.outlineColor} type ="outlineColor" title="색상" change={this.change}/>
            // </fieldset>

            // </details>
            // </Fragment>
        );
    }
}

////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    // screenData : state.screenData,
})

function mapDispatchToProps(dispatch){
    return {
    //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(TextStyleEdit);
  