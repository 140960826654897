////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, {useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
// 라이브러리 

// 설정 파일

// 컴포넌트 연결
import GuideBalloon from 'components/common/form/GuideBalloon'// 가이드 벌룬

// [리덕스]스토어 연결

// SASS&CSS 연결

// 이미지 연결



// 서비스 연결
import * as page from 'service/api/page';
import * as overlap from 'service/api/overlap';
import * as Editor from 'service/edit/EditService' ; 
import PreView from 'components/viewer/PreView';
import PreviewDisplay from 'components/display/PreviewDisplay';
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

function SpaceModuleOverlapPreview(props) {

    const history = useHistory();

    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////
    // state
    const [getPreview, setPreview] = useState([]);
    const [PreviewState, setPreviewState] = useState(null);
    // rel 
    ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////

    //   useEffect(() => {
    //     console.log('Component did mount or update');
    //     return () => {
    //       console.log('Component will unmount');
    //     };
    //   },[]);

    ////////////////////////////////////////////////////////
    // Fuction
    ////////////////////////////////////////////////////////

    useEffect(() => {
        getPagePreview()
    }, [props.overlap]);

    async function getPagePreview(){
        const NewPreview = [];
        if(props.overlap?.page_order?.[0]){
            const overLapPreview = await page.getPageForUUID(props.overlap.page_order[0]);
            NewPreview.push(overLapPreview[0])
            setPreview(NewPreview)
        }else{
            setPreview([])
        }
    }
   //     pathname: `/view/${uuid}`,
        //     state: {id: props.data.id , uuid: props.data.uuid},
    function timeAgo(dateString) {
        const then = new Date(dateString);
        const now = new Date();
        const diffInSeconds = Math.floor((now - then) / 1000);
      
        if (diffInSeconds < 180) {
          return `지금`;
        }
        if (diffInSeconds < 3600) {
          return `${Math.floor(diffInSeconds / 60)}분 전`;
        }
        if (diffInSeconds < 86400) {
          return `${Math.floor(diffInSeconds / 3600)}시간 전`;
        }
        return `${Math.floor(diffInSeconds / 86400)}일 전`;
      }

    const goEditOverlap = () => {
        history.push({
          pathname: '/edit',
          state: {id: props.overlap.id, uuid: props.overlap.uuid, spaceId: props.spaceId},
        });
      }

      const deleteOverlap = async () => {
        const Del =  await overlap.del(props.overlap.id,props.spaceId)
        if(Del){
            setPreviewState('deletePreview')
            props.deletePreview(props.overlap.uuid);
        }else{
            setPreviewState('errorPreview')
        }
      }

    const goUploadOverlap = () => {
        history.push({
          pathname: '/upload',
          state: {id: props.overlap.id, uuid: props.overlap.uuid, spaceId: props.spaceId},
        });
      }

    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////
    return (
        <div className={'SpaceModuleContainer OverlapPreview '+PreviewState}>
            <style jsx>{`${SpaceModuleStyle}${ContainerStyle}`}</style>
            <div className="previewArea">
                <div className='previewLeft'>
                
                </div>
                <div className='previewWarp'>
                    <div className='centerScreen'>
                        <PreviewDisplay pageList={getPreview} referenceWidth={props.overlap.resolution_width??0} referenceHeight={props.overlap?.resolution_height??0}/>
                    </div>
                </div>
                <div className='previewRight'>
                    {/* <button className='RightButton'>
                    {Editor.icon({icon:'more_horiz',size:35,lineHeight:40})}
                    </button> */}
                    {/* <GuideBalloon guideText={<p>프로젝트를 삭제합니다. </p>} width={240} direction='left'> */}
                            <button className='delBackground utilityButton' onClick={()=>deleteOverlap()} >
                                    <p> {Editor.icon({icon:'delete',size:25,lineHeight:50})} </p>
                            </button>
                    {/* </GuideBalloon> */}
                </div>
            </div>
           
            {/* <div className='previewWarp'>
                <div className='centerScreen'>
                    <PreviewDisplay pageList={getPreview} referenceWidth={props.overlap.resolution_width??0} referenceHeight={props.overlap?.resolution_height??0}/>
                </div>
            </div> */}
            <div className='overlapInfo'>
                <div className='overlapFileTitle'>
                    {props?.overlap?.title}
                </div>

                <div className='centerInfo'>
               
                <div className='rightInfo'>
                    <div className='overlapSummary'>
                            {/* <h3>{props.overlap?.title}</h3> */}
                           

                            {/* <p className='overlapSummaryData'>{props.overlap?.summary}</p> */}
                        </div>
                </div>
                    <div className='overlapDetail'>
                        <div className='overlapDataList'>
                            <div className='overlapData'>
                            <span style={{fontSize:'20px !important',lineHeight:'25px !important'}} class="material-symbols-outlined iconfont infoIcon">update</span>
                            <p>{timeAgo(props.overlap?.updated_at)}</p>
                            </div>
                        
                            <div className='overlapData'>
                            <span style={{fontSize:'20px !important',lineHeight:'25px !important'}} class="material-symbols-outlined iconfont infoIcon">resize</span>
                            <p>{props.overlap?.resolution_width}*{props.overlap?.resolution_height}</p>
                            </div>

                            <div className='overlapData'>
                            <span style={{fontSize:'20px !important',lineHeight:'25px !important'}} class="material-symbols-outlined iconfont infoIcon">note_stack</span>
                            <p>{props.overlap?.page_order.length}</p>
                            </div>
                        </div>
                        <div className='overlapButtons'>
                            {/* <div className='dummy'/> */}
                            <button className='infoBackground overlapButton' onClick={()=>goEditOverlap()} >
                                    <p> 편집하기 </p>
                            </button>
  

                        </div>
                    </div>
                    <div className='leftControl'>
              
                    </div>

                </div>
                
              
            
            </div>
    </div>
    );
  }

  export default SpaceModuleOverlapPreview;

////////////////////////////////////////////////////////
// css 부분
////////////////////////////////////////////////////////
const SpaceModuleStyle = `
    .OverlapPreview {
        animation: slideUp 0.7s forwards ease-out;
        margin: 32px 25px 17px;
        display :flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;

        width: calc(100% - 50px);       
    }
    @keyframes slideUp {
        0% {
            transform: translateY(120%); /* 시작 위치: 아래 */

        }
        100% {
          transform: translateY(0); /* 끝 위치: 원래 위치 */

        }
      }
`;

const ContainerStyle = `

    .OverlapPreview.deletePreview .centerScreen{
        animation: delete_file 0.7s ease-in forwards;
    }
    .OverlapPreview.deletePreview .overlapInfo{
        animation: delete_file 0.7s ease-in forwards;
    }

    .OverlapPreview .previewArea{
        display :flex;
        width: 100%;
        max-height: 30vw; 
    }
    .OverlapPreview .previewArea .previewLeft{
        display :flex;
        width: 20%;
        max-height: 30vw; 
    }
    .OverlapPreview .previewArea .previewRight .RightButton{
        width: 40px;
        height: 40px; 
        border-radius : 40px;
        background-color : #444;
        color: #fff;
    }
    .OverlapPreview .previewArea .previewRight{
        display :flex;
        justify-content: end;
        width: 20%;
        max-height: 30vw; 
    }
    .OverlapPreview .previewWarp{
        display :flex;
        width: 60%;
        max-height: 30vw; 
    }

    .OverlapPreview .centerScreen{
        display :flex;
        width: 100%;
        height: 100%;
        max-height: 30vh; 
        aspect-ratio: 16 / 9;
    }

    .OverlapPreview .overlapInfo{
        width: 100%;
        overflow: hidden;
    }

    .OverlapPreview .overlapFileTitle{
        text-align: center;
        font-size: 20px;
        margin: 10px;
        font-family: '프리텐다드ExtraBold';
    }


    .OverlapPreview .centerInfo{
        width: 100%;  
        display :flex;
        justify-content: center;
    }
    .OverlapPreview .leftControl{
        display :flex;
        flex : 1;
        align-items: end;
        justify-content: start;
    }
    .OverlapPreview .overlapDetail{
        display :flex;
        flex-direction: column;
        align-items: center;
        max-width: 30vw; 
        width: 60%;  
        
    }
    .OverlapPreview .rightInfo{
        display :flex;
        flex : 1;
    }

    .OverlapPreview .overlapData{
        display :flex;
        flex-wrap: wrap;
        margin-right :15px;
        line-height : 24px;
    }
    .OverlapPreview .overlapDataList{
        display :flex;
        justify-content: center;
    }
    .OverlapPreview .overlapButtons{
        display :flex;
        justify-content: center;
    }

    .OverlapPreview .infoBackground{
        background-color: #444;
        padding: 5px 10px;
        border-radius: 10px;
        display : flex;
        min-height: 40px;
    }
    
    .OverlapPreview .dummy{
        width: 50px;
        height: 50px;
    }
    .OverlapPreview .overlapButton{
        height: 50px;
        padding: 5px 10px;
        border-radius: 10px;
        color : #fff;
        width: 300px;
        margin : 10px
    }
    .OverlapPreview .overlapButton p{
        font-family: '프리텐다드SemiBold';
        width: 100%;
        height: 40px;   
        line-height: 40px;
        text-align: center;
        justify-content: center;
        align-items: center;
        display: flex;
    }

 
    .OverlapPreview .delBackground{
        background-color: #444;
        padding: 5px 10px;
        border-radius: 10px;
        display : flex;
        min-height: 40px;
        animation: left_delBackground 0.3s 0.7s both ease-out 1;
    }
    .OverlapPreview .utilityButton{
        height: 50px;
        padding: 5px 10px;
        border-radius: 10px;
        color : #fff;
        width: 50px;
        margin : 0px 10px 10px 10px;
    }
    .OverlapPreview .utilityButton p{
        font-family: '프리텐다드SemiBold';
        width: 100%;
        height: 40px;   
        line-height: 40px;
        text-align: center;
        justify-content: center;
        align-items: center;
        display: flex;
    }
    
    @keyframes left_delBackground {
        0% { transform: translateX(100px); opacity: 0; }
        100% { transform: translateX(0); opacity: 1;}
    }

    @keyframes delete_file {
        0% { transform: translateY(0px) rotateZ(0deg);}
        80% { opacity: 1}
        100% { transform: translateY(500px) rotateZ(30deg); opacity: 0}
    }


`;

