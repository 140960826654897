////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { Route, Link } from 'react-router-dom';
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/tutorial.scss"

// 이미지 연결

import onAirFull_1 from 'assets/guide/onAirFull_1.png';
import sidebarFull_1 from 'assets/guide/sidebarFull_1.png';
import widgetListFull_1 from 'assets/guide/widgetListFull_1.png';
import widgetSelectFull_1 from 'assets/guide/widgetSelectFull_1.png';
import Rmove from 'assets/guide/rmove.png';
import Lmove from 'assets/guide/lmove.png';
// 컴포넌트 연결
// import Text from './template/Text'; // 메뉴 헤더

// 서비스 연결
import * as DeCoder from 'service/engine/DeCoder' ; 
import * as Rest from 'service/api/rest' ; 
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class EditGuideVeiw extends Component {
    
    state = {
        page : 0
    }

    move =(value) =>{    
        let page = this.state.page + value
        console.log(page,'테스트')
        if (page == -1 || page == 4){
            return
        }
        this.setState({page})
    }

    render() { 


        return (
            <div id = "EditGuideVeiw" >
                <div  id = "EditGuideVeiwList" style={{left:this.state.page*100*-1 +'vw'}} >
                    <div className="GuideImgOBJ" >
                    <img className="GuideImg" src={sidebarFull_1}/>
                    </div>
                    <div className="GuideImgOBJ" >
                    <img className="GuideImg" src={widgetListFull_1}/>
                    </div>
                    <div className="GuideImgOBJ" >
                    <img className="GuideImg" src={widgetSelectFull_1}/>
                    </div>
   
                    <div className="GuideImgOBJ" >
                    <img className="GuideImg" src={onAirFull_1}/>
                    </div>
                </div>
                <div  className = "overlayBnt" >
                    <button>
                    <img src={Lmove} onClick={()=>this.move(-1)}/>
                    </button>
                    <button>
                    <img src={Rmove} onClick={()=>this.move(1)}/>
                    </button>
                </div>
                
                <div  className = "editStartOverlayBnt" >
                <Link to="/edit">
                    <button>
                     편집 시작하기
                    </button>
                </Link>
                </div>
                
                <div  className = "editStartText" >
                    <p>편집 퀵 스타트 가이드</p>
                </div>
            </div>
        );
    }
}
////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    passToken : state.passToken,
})

function mapDispatchToProps(dispatch){
    return {
    //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(EditGuideVeiw);
  