////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, {useState, useEffect, Fragment, useContext } from 'react';
import { useHistory,useLocation } from 'react-router-dom';
// 라이브러리 

import { useResizeDetector } from 'react-resize-detector';// 컴포넌트의 사이즈 감지

// 설정 파일
import * as config from 'config/OverlapConfig'

// [컴포넌트] 위젯 
import BroadcastDisplay from 'components/display/BroadcastDisplay'; // 신형 디스플레이
import GuideBalloon from 'components/common/form/GuideBalloon'// 가이드 벌룬
// [리덕스]스토어 연결
// import store from "store";
import ViewContext from 'service/context/EditContext'; //[에러] 뷰로 하면 안되고 에딧으로 해야함 뷰 컨텍스트 가져오기
// SASS&CSS 연결
// import 'sass/login.scss'

// 이미지 연결
// import logoW from 'assets/logoW.svg';



// 서비스 연결
import * as DeCoder from '../../service/engine/DeCoder' ; 
import * as Editor from 'service/edit/EditService' ; 
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

/** [채널페이지 필드] 
 * 채널 페이지내 데이터 교류, 상태 관리를 위한 컴포넌트 입니다.
 */
function ChannelField({}) {
    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////
    // state
    const [IsControl, setControl] = useState(false);
    // ref
    const { width, height, ref } = useResizeDetector();

    const context = useContext(ViewContext);
    const history = useHistory();
    ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////

    //   useEffect(() => {
    //     return () => {
    //     };
    //   },[]);

    //   useEffect(() => {
    //   },[context]);

    ////////////////////////////////////////////////////////
    // Fuction
    ////////////////////////////////////////////////////////


    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////

    if(context.view==null){
        return(
            null
        )
    }
    const goLobby = () => {
        history.push({
          pathname: '/main',
        });
      }

      
    const Page = context.pageData
    const ChannelHeight = context.view?.resolution_height;
    const ChannelWidth = context.view?.resolution_width;

    const DisplayControl = {
        transform:  IsControl?`scale(0.7)`:`scale(1)`,
    };

    const FieldStyle = {
        backgroundColor:  IsControl?`#1c1c1c`:`#1c1c1c00`,
    }
    return (
        <Fragment>
        <style jsx>{`${ChannelFieldStyle}`}</style>
        <main id='ChannelField' style={FieldStyle} ref={ref} onMouseOver={()=>setControl(true)} onMouseLeave={()=>setControl(false)}>
            <div className='ChannelControlLayer' >


                <div className='Display' style={DisplayControl}>
                    <BroadcastDisplay status={context.view?.view_status?.status} background={IsControl?"rgba(255, 255, 255, 1)":"rgba(255, 255, 255, 0)"} referenceWidth={ChannelWidth} referenceHeight={ChannelHeight} pageList={Page}/>
                </div>


                <div className={IsControl?'Control':'Control disable'} >

                    <div className='ControlContainer'>

                        <div className='ControlBox'>
                            <GuideBalloon guideText={<p> 채널의 링크를 복사합니다. </p>} direction='right'>
                            <div className='infoIcon'>
                                {Editor.icon({icon:'link',size:25,lineHeight:30})}
                            </div>
                            </GuideBalloon>
                          
                            <GuideBalloon guideText={<p> 로비 페이지로 이동합니다. </p>} direction='right'>
                            <div className='infoIcon' onClick={()=>goLobby()}>
                                {Editor.icon({icon:'door_open',size:25,lineHeight:30})}
                            </div>
                            </GuideBalloon>
                            <GuideBalloon guideText={<p> 메뉴가 항상 보이도록 고정합니다. </p>} direction='right'>
                            <div className='infoIcon'>
                                {Editor.icon({icon:'lock',size:25,lineHeight:30})}
                            </div>
                            </GuideBalloon>
                        </div>

                        <div className='ControlBox'>

                            <GuideBalloon guideText={<p> 전체화면을 종료합니다. </p>} direction='right'>
                            <div className='infoIcon'>
                                {Editor.icon({icon:'fullscreen_exit',size:25,lineHeight:30})}
                            </div>
                            </GuideBalloon>
                            <GuideBalloon guideText={<p> 전체화면으로 고정합니다. </p>} direction='right'>
                            <div className='infoIcon'>
                                {Editor.icon({icon:'fullscreen',size:25,lineHeight:30})}
                            </div>
                            </GuideBalloon>

                        </div>
                    </div>

                </div>

                <div className={IsControl?'Info':'Info disable'} >
                    <div className='InfoContainer'>
               
                        <div className='ChannelInfoBox'>
                            <div className='infoIcon'>
                                {Editor.icon({icon:'title',size:25,lineHeight:30})}
                            </div>
                            
                            
                            <div className='infoText'>
                                {context.view?.view_name} 
                            </div>
               
         
                            <div className='infoIcon'>
                                {Editor.icon({icon:'resize',size:25,lineHeight:30})}
                            </div>
                            <div className='infoText'>
                                {context.view?.resolution_width} * {context.view?.resolution_height} 
                            </div>

                            <div className='infoIcon'>
                                {Editor.icon({icon:'layers',size:25,lineHeight:30})}
                            </div>
                            <div className='infoText'>
                            {context.view?.view_pointer?.length??0}
                            </div>
                        </div>
                    </div>

                    <div className='CacheBox'>
                        <textarea value={JSON.stringify(context.channelCache)}/>

                    </div>

                </div>
            </div>
        </main>
        </Fragment>
    );
  }

  export default ChannelField;
////////////////////////////////////////////////////////
// css 부분
////////////////////////////////////////////////////////
const ChannelFieldStyle = `
    #ChannelField {
        display: flex;
        width: 100vw;
        height: 100vh;
        transition: background 0.5s;  
    }

    #ChannelField ChannelControlLayer {
        position: relative;
        display: flex;
        width: 100vw;
        height: 100vh;
    }
   
    #ChannelField .Display{
        position: absolute;
        display: flex;
        width: 100vw;
        height: 100vh;
        z-index: 0;
        transition: transform 0.5s;  
    }

    #ChannelField #test{
        width: 100%;
        height: 100%;
        display:flex;
        justify-content: center;
        align-items: center;

    }
    #ChannelField .Control{
        position: absolute;
        display: flex;
        width: 100vw;
        height: 100vh;
        // background-color: #323232;
        z-index: 2;
        transition: opacity 0.5s; 
        opacity : 1;
    }

    #ChannelField .Info{
        position: absolute;
        display: flex;
        width: 100vw;
        height: 100vh;
        // background-color: #323232;
        z-index: 1;
        transition: opacity 0.5s; 
        opacity : 1;
    }

    #ChannelField .InfoContainer{
        width: 100vw;
        height: 10vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }
 
    #ChannelField .ChannelInfoBox{
        display: flex;
        justify-content: center;
        align-items: center;
        width: auto;
        height: 50px;
        background-color: #444;
        color : #fff;
        font-family: '프리텐다드SemiBold';
        padding : 5px;
        border-radius: 5px;
    }
    #ChannelField .infoIcon{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30px;
        width: 30px;
        background-color: #323232;
        padding : 5px;
        margin : 10px;
        cursor: pointer;
    }
    #ChannelField .infoText{
        margin-right : 15px;
    }
    #ChannelField .CacheBox{
        position: absolute;
    }
    #ChannelField .CacheBox textarea{
        background-color: #323232;
        font-family: '프리텐다드SemiBold';
        color : #fff;
    }

    #ChannelField .ControlContainer{
        position: absolute;
        bottom : 0px;
        display: flex;
        align-items: end;
    }
    #ChannelField .disable{
        opacity : 0;
    }
`;


