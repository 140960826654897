////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, {useState, useEffect, useRef } from 'react';

// 라이브러리 

import { useResizeDetector } from 'react-resize-detector';// 컴포넌트의 사이즈 감지

// 설정 파일
import * as config from 'config/OverlapConfig'

// [컴포넌트] 위젯 
// import MiniView from "components/viewer/MiniView"
// [리덕스]스토어 연결
// import store from "store";

// SASS&CSS 연결
// import 'sass/login.scss'

// 이미지 연결
// import logoW from 'assets/logoW.svg';



// 서비스 연결
import * as DeCoder from '../../service/engine/DeCoder' ; 
import * as Utility from 'service/other/Utility' ; 

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

/** [디스플레이] 
 * 송출용도로 제작된 디스플레이 입니다.
 * @param {*} pageList 오버랩의 페이지 객체
 * @param {number} referenceWidth 디스플레이의 해상도
 * @param {number} referenceHeight 디스플레이의 해상도
 * @param {*} view 오버랩의 뷰 객체
 * @param {*} option 디스플레이에 적용할 옵션
 * @returns 
 */
function BroadcastDisplay({ status, pageList, referenceWidth, referenceHeight, background, view = null, option }) {
    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////
    // state
    const [Component, setComponent] = useState(null);

    const [LastPageID, setLastPageID] = useState([]); // 직전 페이지의 UUID 리스트를 넣습니다. 변화가 있으면 페이지가 변경된것으로 간주합니다.
    const [LastPage, setLastPage] = useState([]); // 직전 페이지의 UUID 리스트를 넣습니다. 변화가 있으면 페이지가 변경된것으로 간주합니다.
    // ref
    const { width, height, ref } = useResizeDetector();
    // const displayRef = useRef(null);

    ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////

      // useEffect(() => {
      //   return () => {
      //   };
      // },[]);

      useEffect(() => {

        const PageID = pageList.map((item)=>{return item.uuid})
        if(!Utility.arrayEqual(PageID,LastPageID)){
          // 페이지 변경시에 해당 구문이 실행됩니다.
          setLastPage(pageList)
          setLastPageID(PageID)

          for (let index = 0; index < pageList.length; index++) {
            preDeCoding(pageList?.[index],LastPage?.[index],pageList?.[index]?.global);
          }
          setTimeout(() => {
            for (let index = 0; index < pageList.length; index++) {
              displayDeCoding(pageList?.[index],pageList?.[index]?.global);
            }
          }, 10);
        }else{
         
          for (let index = 0; index < pageList.length; index++) {
            displayDeCoding(pageList?.[index],pageList?.[index]?.global);
          }

          setLastPageID(PageID)
        }

        return () => {

        };
      },[pageList]);

    ////////////////////////////////////////////////////////
    // Fuction
    ////////////////////////////////////////////////////////


    const displayDeCoding = (source,global) =>{

      if(zoom){
   
        const Widget = DeCoder.run(source,'broadcast',global,{animation:true})
        setComponent(Widget)
      }
    }

    const preDeCoding = (source,preSource,global) =>{

      // let identifier = widget?.alias? widget?.type+'_'+widget?.alias : widget?.type+'_'+widget?.uuid;
      if(preSource?.page_widget){
        const preSource2 = Utility.deepCopy(preSource?.page_widget)
        const NextList = source?.page_widget.map((widget)=>{return widget?.alias? widget?.type+'_'+widget?.alias : widget?.type+'_'+widget?.uuid;})
        // const Next = source?.page_widget.map((widget)=>{return widget?.uuid})
        const PreData = sortByIDOrder(preSource2,NextList)
        const Widget = DeCoder.run({'page_widget':PreData,},'broadcast',global,{animation:"mountStart"})
        setComponent(Widget)
      }else{
        // preSource 가 없는 경우
        if(zoom){
          const Widget = DeCoder.run({source},'broadcast',global,{animation:true})
          setComponent(Widget)
        }
      }
    }

    const DisplayCore = () => {
        if(zoom){
            return DeCoder.run({'page_widget':pageList?.[0]?.page_widget},'broadcast')
        }
    }
    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////
    const zoom = (width / referenceWidth)??0;

    const Ratio = referenceHeight/referenceWidth;

    const displaySizeControl = {
        backgroundColor: background,
        height: width * (referenceHeight/referenceWidth),
        aspectRatio:  `${referenceWidth} / ${referenceHeight}`,
      };

    const zoomControl = {
        transform:  `scale(${zoom})`,
    };
      
    return (
        <div className={'BroadcastDisplayContainer ' + (status=='pause'? 'broadcastPause ':'broadcastPlay')}ref={ref} >
        {/* <style jsx>{`${DisplayStyle}${SizeStyle}`}</style> */}
        <style jsx>{`${DisplayStyle}`}</style>
            <div style={displaySizeControl} className='BroadcastDisplay' >
            {/* Width: {width}, Height: {height} */}
                <div  style={zoomControl} className='DisplayCore' >
                    {/* {DisplayCore()} */}
                    {Component}
                </div>
                <div className='DisplayGuide' >

                </div>

            </div>
        </div>
    );
  }

  export default BroadcastDisplay;

  function sortByUuidOrder(items, uuidOrder) {
    // uuidOrder 배열에 기반하여 각 UUID의 인덱스를 매핑
    const uuidIndexMap = new Map(uuidOrder.map((uuid, index) => [uuid, index]));

    // 필터링: uuidOrder에 포함된 uuid만을 가진 items 요소를 유지
    const filteredItems = items.filter(item => uuidIndexMap.has(item.uuid));
  
    // 정렬: uuidOrder에 따라 filteredItems 배열을 정렬
    return filteredItems.sort((a, b) => {
      const indexA = uuidIndexMap.get(a.uuid);
      const indexB = uuidIndexMap.get(b.uuid);
      return indexA - indexB;
    });
  }

  function sortByIDOrder(items, idOrder) {
    // idOrder 배열에 기반하여 각 식별자 인덱스를 매핑
    const uuidIndexMap = new Map(idOrder.map((id, index) => [id, index]));

    // 필터링: uuidOrder에 포함된 uuid만을 가진 items 요소를 유지
    const filteredItems = items.filter(widget => uuidIndexMap.has(widget?.alias? widget?.type+'_'+widget?.alias : widget?.type+'_'+widget?.uuid));
  
    // 정렬: uuidOrder에 따라 filteredItems 배열을 정렬
    return filteredItems.sort((a, b) => {
      const indexA = uuidIndexMap.get(a.uuid);
      const indexB = uuidIndexMap.get(b.uuid);
      return indexA - indexB;
    });
  }
////////////////////////////////////////////////////////
// css 부분
////////////////////////////////////////////////////////
const DisplayStyle = `
    .BroadcastDisplayContainer {
        width: 100%;
        height: 100%;
        display:flex;
        justify-content: center;
        align-items: center;
    }
    .broadcastPause{
        animation: broadcastPauseAnimation 1s 0.5s forwards;
    }
    .broadcastPlay{
      animation: broadcastStartAnimation 1s 0.5s both;
    }
    .BroadcastDisplayContainer .BroadcastDisplay{
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        box-shadow: 0 6px 16px rgba(0,0,0,.15);
        overflow: hidden;
        transition: aspect-ratio 0.5s, background 0.5s;  
    }

    .BroadcastDisplayContainer .DisplayGuide{
      position: absolute;
    }

    
    .BroadcastDisplayContainer .DisplayCore{
        width: 100%;
        height: 100%;
        transform-origin: 0% 0%;
    }
    
    @keyframes broadcastStartAnimation {
      from {
        opacity : 0;
        transform: scale(2);
      }
      to {
        opacity : 1;
        transform: scale(1);
      }
    }

    @keyframes broadcastPauseAnimation {
      0% {
        transform: scale(1);
        opacity : 1;
      }
      99% {
        opacity : 0;
        transform: scale(2);
      }
      100% {
        display : none;
        opacity : 0;
        transform: scale(2);
      }
    }
`;


