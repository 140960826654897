////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widgetEdit.scss"


// 컴포넌트 연결
import SizeModule from '../editModule/SizeModule'; //위치 모듈
import ColorModule from '../editModule/ColorModule'; // 색상 모듈
import RangeModule from '../editModule/RangeModule'; // 색상 모듈
import ImageButtonModule from '../editModule/ImageButtonModule';

// 서비스 연결
import * as Editor from 'service/edit/EditService'; 

// 이미지
import round_0 from 'assets/sidebar/other/round_0.png';
import round_1 from 'assets/sidebar/other/round_1.png';
import round_2 from 'assets/sidebar/other/round_2.png';
import round_3 from 'assets/sidebar/other/round_3.png';
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class RadiusEdit extends Component {
    
    state = {
        editorToggle : false
    }

    change = (name,value) =>{
        this.props.callBack('style',name,value)
    }

    sizeChange = (name,value) =>{
        this.props.callBack('size',name,value)
    }


    render() { 

        let legend = '모서리'
        if (this.props.type){
            legend = '모서리'
        }
        
        let max =Math.min(this.props.data.size.width,this.props.data.size.height)/2


        return (
            <section className='RadiusEdit'>
                {Editor.WidgetEditTitle(this,{title:'모서리',toggle:true,icon:'rounded_corner'})}
                <div className={this.state.editorToggle?'editContainer containerOpen':'editContainer containerClose'}>
                    <article className="EditPart" >
                    <ImageButtonModule value={this.props.data.style.borderRadius} type ="borderRadius" data={[{val:max,image:round_3,text:'text',title:"원형모서리"},{val:25,image:round_2,title:"둥근모서리"},{val:10,image:round_1,title:"약간 둥근모서리"},{val:0,image:round_0,title:"각진모서리"}]} title="둥글게"  change={this.change}/>
                    {/* <RangeModule value={this.props.data.style.borderRadius} type ="borderRadius" valueView="px" min="0" max={max} title="둥글게" change={this.change} /> */}
                    </article>
                </div>

            </section>
     
        );
    }
}

////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    // screenData : state.screenData,
})

function mapDispatchToProps(dispatch){
    return {
    //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(RadiusEdit);
  