////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widgetEdit.scss"

// 컴포넌트 연결
import TextModule from '../editModule/TextModule'; // 텍스트 모듈
import TextSizeModule from '../editModule/TextSizeModule'; // 텍스트 크기 모듈
import FontModule from '../editModule/FontModule'; // 폰트 종류 모듈
import ColorModule from '../editModule/ColorModule'; // 색상 모듈
import RangeModule from '../editModule/RangeModule'; //범위 모듈 
import SentenceModule from '../editModule/SentenceModule'; // 선택리스트 모듈
import RadioButtonModule from '../editModule/RadioButtonModule';
import DateModule from '../editModule/DateModule';
import del from 'assets/edit/delete.png';

// 서비스 연결

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class DdayEdit extends Component {
    
    state = {
        value : ''

    }

    change = (name,value) =>{
        this.props.callBack('style',name,value)
    }
    valueChange = (name,value) =>{

        switch (name) {
            case 'fontSize':
                // px 때문에 비교 불가 
                if(value>this.props.data.size.height){
                }
                break;
        
            default:
                break;
        }
        this.props.callBack('value',name,value)

    }

    
    render() { 

        const Style = {
            label: {
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
            },
            textarea : {
                width: '100%',
                resize: 'none',
                boxSizing:'border-box',
            },
            mediaView : {
                width: '100%',
                MaxHeight : '500px',
            }

        }
        return (
            <Fragment>

            <fieldset className="EditModuleField" style={Style.fieldset} >
                <legend style={Style.legend} > 본문 </legend>
                <FontModule  value={this.props.data.style.fontFamily} type ="fontFamily" change={this.change}/>
                <TextSizeModule value={this.props.data.style.fontSize} type ="fontSize" change={this.change}/>
                <SentenceModule value={this.props.data.value.info} fontFamily={this.props.data.style.fontFamily} type ="info" title="일정제목 " placeholder="일정을 입력하세요"  change={this.valueChange}/>
                <DateModule value={this.props.data.value.date} title="날짜" type ="date" change={this.valueChange}/>
            </fieldset>

            <fieldset className="EditModuleField" style={Style.fieldset} >
                <legend style={Style.legend} > 색상 </legend>
                <ColorModule value={this.props.data.style.main_fill} type ="main_fill" title="주배경색" change={this.change}/>
                <ColorModule value={this.props.data.style.sub_fill} type ="sub_fill" title="보조배경색" change={this.change}/>
                <ColorModule value={this.props.data.style.main_color} type ="main_color" title="주글자색" change={this.change}/>
                <ColorModule value={this.props.data.style.sub_color} type ="sub_color" title="보조글자색" change={this.change}/>


                <RangeModule value={this.props.data.style.fillOpacity} type ="fillOpacity" title="배경투명도" valueView="%" min="0" max="100" change={this.change} />
            </fieldset>


            </Fragment>
        );
    }
}

////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    // screenData : state.screenData,
})

function mapDispatchToProps(dispatch){
    return {
    //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(DdayEdit);
  