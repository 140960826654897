import store from "store";
import * as EnCoder from 'service/engine/EnCoder' ; 
import * as Event from 'service/event/Actions'; 
import * as Model from 'service/value/Model'; 
import * as Utility from 'service/other/Utility'; 



////////////////////////////////////////////////////////
// control (해당객체번호)
// 해당 객체를 삭제합니다.
////////////////////////////////////////////////////////

// ==> 위젯 오버레이 컴포넌트로 기능이동

// function overRayTransform (property,subName=null) {
//     if (subName){
//       subName =subName+'_'
//     }else{
//       subName = ''
//     }
//     let keys = Object.keys(property)
//     let result =''
  
//     // 필터
//     if(keys.includes(subName+'rotateZ')){
//       result += 'rotateZ('+subName+property['rotateZ']+'deg) '
//     }
//     if(keys.includes(subName+'scale')){
//       result += 'scale('+subName+property['scale']/100+','+subName+property['scale']/100+') '
//     }
//     return {transform : result}
//   }


// export function overRay(type=null,style=null) {
//     let transform = overRayTransform(type.data.style)

//     return ( 
//         <div className='overRay' style={transform} >
//             <div style={{top:'-5px',left:'-5px'}} className='overRayRact'/>
//             <div style={{top:'-5px',right:'-5px'}} className='overRayRact'/>
//             <div style={{bottom:'-5px',left:'-5px'}} className='overRayRact'/>
//             <div style={{bottom:'-5px',right:'-5px'}} className='overRayRact'/>
//         </div>
//     )
//   }
////////////////////////////////////////////////////////
// WidgetDefaultSetting
// 위젯 기본 설정 
////////////////////////////////////////////////////////
export function DefaultState(self) {
    let widgetName = self.props.widgetName
    /*
    이곳에서 정의된 state 는 모든 widget Object 에 적용됩니다.
    만약 각 widget Object 에서 재 정의 되었다면 재 정의된 값이 적용됩니다.
    */
    let state = {
        dragLock : false, // dragLock : 파이어폭스, 사파리등 특정 브라우저에서 드레그를 위해 사용 됩니다 기본값 false
        focus : self.props.focus, // focus : 어떤 함수가 클릭되었는지 체크 하기 위해 사용됩니다.
    }
    return state
}
export function DefaultMethod(self,type,value) {

    switch (type) {
        ////////////////////////////////////////////////////////
        // dragLock : 파이어폭스, 사파리등 특정 브라우저에서 드레그를 위해 사용 됩니다
        ////////////////////////////////////////////////////////
        case 'dragLock':
            self.setState({
                dragLock : value, 
            })
            return ;
        default:
            return ;
    }
}
////////////////////////////////////////////////////////
// objectMove
// 편집화면에서 위젯을 움직입니다.
////////////////////////////////////////////////////////
export function objectMove(context,selectWidgets,x=0,y=0,w=0,h=0) {

    const resolution = context.overlap.meta.resolution;
    const widgetList = context.nowPage()?.page_widget;

    let screen = document.getElementById('EditScreen')
    let zoom = resolution.width/(resolution.width*context.editor.zoom)

    // 맵의 위치 보정을 합니다.
    const absoluteTop = window.pageYOffset + screen.getBoundingClientRect().top;
    const absoluteLeft = window.pageYOffset + screen.getBoundingClientRect().left;

    // 클릭한 좌표를 e.client 로 받아오고 위치를 보정해줍니다. 또 이를 % 로 나눕니다.
    let xPoint = ((x-w)-absoluteLeft)
    let yPoint = ((y-h)-absoluteTop)
    xPoint = xPoint * zoom
    yPoint = yPoint * zoom

    widgetList[selectWidgets].data.position['x'] = xPoint.toFixed(0)
    widgetList[selectWidgets].data.position['y'] = yPoint.toFixed(0)
    context.modifyPage({'page_widget':widgetList},'modifyWidget')
}

////////////////////////////////////////////////////////
// objectDisplay
// 위젯을 보이거나 숨깁니다.
////////////////////////////////////////////////////////

/** show | 위젯을 보여줍니다.
 * @param {Provider} context 편집 프로바이더
 * @param {string|Array} uuid 보이게 할 위젯의 uuid 배열로 uuid을 주면 여러개 위젯을 보이게합니다.
 * @returns 프로바이더 내 state contextMenu 에 파라미터를 등록합니다
 *  */ 

// export function show(context,uuid) {
//     console.log('========show========')
//     if (!Array.isArray(uuid)){
//         let uuidList = [uuid] ;
//         uuid = uuidList
//     }

//     const widgetList = context.findwidget(uuid);

//     let modifyPage = widgetList.map(function(widget) {
//         widget.data.display.show = true;
//         return widget;
//       });
    
//     // context.modifyWidget(modifyPage);
//     // context.modifyPage({'page_widget':widgetList},'modifyWidget')
//   }


// export function hide(context,uuid) {
//     console.log('========hide========')
//     if (!Array.isArray(uuid)){
//         let uuidList = [uuid] ;
//         uuid = uuidList
//     }

//     const widgetList = context.findwidget(uuid);

//     let modifyPage = widgetList.map(function(widget) {
//         widget.data.display.show = false;
//         return widget;
//       });
    
//     console.log('modifyPage',modifyPage);


//     // 여기에 
//     context.modifyWidget(modifyPage);
//     // context.modifyPage({'page_widget':widgetList},'modifyWidget')
//   }
  

////////////////////////////////////////////////////////
// event control
// 해당 객체에서 마우스 포커싱을 통해 일어나는 일을 체크합니다.
////////////////////////////////////////////////////////

export function widgetSizeChange(context,type,pageX,pageY,endX=0,endY=0,shiftKey) {


    
    const SELECTWIDGET = context?.selectWidget;
    const widgetList = Utility.deepCopy(context.nowPage()?.page_widget);
    const selectWidgets = widgetList.findIndex(item => item.uuid === SELECTWIDGET);
   
    const resolution = context.overlap.meta.resolution;

    let zoom = resolution.width/(resolution.width*context.editor.zoom)

    // edit스크린의 사이즈에 맞춰서 %보정을 진행합니다
    let startX = pageX * zoom
    let startY = pageY * zoom
    endX = endX * zoom
    endY = endY * zoom
    

    // 드레그 시작 위지부터 드레그 종료위치까지 

    let xPoint = Number(startX-endX)
    let yPoint = Number(startY-endY)

    let width = null
    let height = null
    let xPosition =null
    let yPosition =null
    
    if(!widgetList[selectWidgets].data.position['x']){
        // console.error('값없음',Model.safety(widgetList[id]).position.x)
        widgetList[selectWidgets].data.position['x'] = Model.safety(widgetList[selectWidgets]).position.x
    }
    if(!widgetList[selectWidgets].data.position['y']){
        // console.error('값없음',Model.safety(widgetList[id]).position.y)
        widgetList[selectWidgets].data.position['y'] = Model.safety(widgetList[selectWidgets]).position.y
    }
 
    if(!widgetList[selectWidgets].data.size['width']){
        widgetList[selectWidgets].data.size['width'] = Model.safety(widgetList[selectWidgets]).size.width
        if(String(widgetList[selectWidgets].data.size['width']).indexOf('%')!=-1 ){
            widgetList[selectWidgets].data.size['width'] = EnCoder.percentValue(widgetList[selectWidgets].data.size['width'],'width')  
        } 
    }
    if(!widgetList[selectWidgets].data.size['height']){
        widgetList[selectWidgets].data.size['height'] = Model.safety(widgetList[selectWidgets]).size.height
        if(String(widgetList[selectWidgets].data.size['height']).indexOf('%')!=-1 ){
            widgetList[selectWidgets].data.size['height'] = EnCoder.percentValue(widgetList[selectWidgets].data.size['height'],'height') 
        } 
    }

    let widthRatio = Number(widgetList[selectWidgets].data.size['width'])/Number(widgetList[selectWidgets].data.size['height'])
    let heightRatio = Number(widgetList[selectWidgets].data.size['height'])/Number(widgetList[selectWidgets].data.size['width'])

    let xRatio
    let yRatio
    // X좌표 부터
    switch (type) {
        ////////////////////////////////////////////////////////
        //  위 포인트를 드레그 
        ////////////////////////////////////////////////////////
        case 'Top':
            yPosition = Number(widgetList[selectWidgets].data.position['y']) + yPoint
            height = Number(widgetList[selectWidgets].data.size['height']) - yPoint
            break;
        ////////////////////////////////////////////////////////
        // 아래 포인트를 드레그 
        ////////////////////////////////////////////////////////
        case 'Bottom':
            height = Number(widgetList[selectWidgets].data.size['height']) + yPoint
            break;
        ////////////////////////////////////////////////////////
        //  왼쪽 포인트를 드레그
        ////////////////////////////////////////////////////////
        case 'Left':
            xPosition = Number(widgetList[selectWidgets].data.position['x']) + xPoint

            width = Number(widgetList[selectWidgets].data.size['width']) - xPoint
            break;
        ////////////////////////////////////////////////////////
        //  오른쪽 포인트를 드레그
        ////////////////////////////////////////////////////////
        case 'Right':
            width = Number(widgetList[selectWidgets].data.size['width']) + xPoint

            break;
        ////////////////////////////////////////////////////////
        //  왼쪽 상단 포인트 드래그
        ////////////////////////////////////////////////////////
        case 'RatioLeftTop':   
            if(xPoint/widthRatio>yPoint){
                xRatio = yPoint * widthRatio
                yRatio = yPoint 
            }
            else{
                xRatio = xPoint 
                yRatio = xPoint * heightRatio
            }
            xPosition = Number(widgetList[selectWidgets].data.position['x']) + xRatio
            yPosition = Number(widgetList[selectWidgets].data.position['y']) + yRatio
            width = Number(widgetList[selectWidgets].data.size['width']) - xRatio
            height = Number(widgetList[selectWidgets].data.size['height']) - yRatio
        break;

        case 'LeftTop':
            ////////////////////////////////////////////////////////
            //  쉬프트를 누른상태
            ////////////////////////////////////////////////////////
            if(shiftKey){
                if(xPoint/widthRatio>yPoint){
                    xRatio = yPoint * widthRatio
                    yRatio = yPoint 
                }
                else{
                    xRatio = xPoint 
                    yRatio = xPoint * heightRatio
                }
                xPosition = Number(widgetList[selectWidgets].data.position['x']) + xRatio
                yPosition = Number(widgetList[selectWidgets].data.position['y']) + yRatio
                width = Number(widgetList[selectWidgets].data.size['width']) - xRatio
                height = Number(widgetList[selectWidgets].data.size['height']) - yRatio
            }
            ////////////////////////////////////////////////////////
            // 기본상태
            ////////////////////////////////////////////////////////
            else{
                console.log(xPoint,widgetList[selectWidgets].data.position['x'])
                xPosition = Number(widgetList[selectWidgets].data.position['x']) + xPoint
                yPosition = Number(widgetList[selectWidgets].data.position['y']) + yPoint
    
                width = Number(widgetList[selectWidgets].data.size['width']) - xPoint
                height = Number(widgetList[selectWidgets].data.size['height']) - yPoint
            }
            break;

        ////////////////////////////////////////////////////////
        //  오른쪽 상단 포인트 드래그
        ////////////////////////////////////////////////////////
        case 'RatioRightTop':
            if(xPoint*-1/widthRatio>yPoint){
                xRatio = yPoint * widthRatio *-1
                yRatio = yPoint 
            }
            else{
                xRatio = xPoint 
                yRatio = xPoint * heightRatio *-1 
            }
            yPosition = Number(widgetList[selectWidgets].data.position['y']) + yRatio

            width = Number(widgetList[selectWidgets].data.size['width']) + xRatio
            height = Number(widgetList[selectWidgets].data.size['height']) - yRatio
        break;

        case 'RightTop':
            ////////////////////////////////////////////////////////
            //  쉬프트를 누른상태
            ////////////////////////////////////////////////////////
            if(shiftKey){
                if(xPoint*-1/widthRatio>yPoint){
                    xRatio = yPoint * widthRatio *-1
                    yRatio = yPoint 
                }
                else{
                    xRatio = xPoint 
                    yRatio = xPoint * heightRatio *-1 
                }
                yPosition = Number(widgetList[selectWidgets].data.position['y']) + yRatio
    
                width = Number(widgetList[selectWidgets].data.size['width']) + xRatio
                height = Number(widgetList[selectWidgets].data.size['height']) - yRatio
            }
            ////////////////////////////////////////////////////////
            //  기본상태
            ////////////////////////////////////////////////////////
            else{
                xRatio = xPoint 
                yRatio = xPoint * heightRatio.toFixed(3)
                yPosition = Number(widgetList[selectWidgets].data.position['y']) + yPoint
    
                width = Number(widgetList[selectWidgets].data.size['width']) + xPoint
                height = Number(widgetList[selectWidgets].data.size['height']) - yPoint
            }
            break;
        ////////////////////////////////////////////////////////
        //  왼쪽 하단 포인트 드래그
        ////////////////////////////////////////////////////////
        case 'RatioLeftBottom':
            if(xPoint*-1/widthRatio<yPoint){
                xRatio = (yPoint * widthRatio)*-1 
                yRatio = yPoint 
            }
            else{
                xRatio = xPoint 
                yRatio = (xPoint * heightRatio) *-1
            }
       
            xPosition = Number(widgetList[selectWidgets].data.position['x']) + xRatio

            width = Number(widgetList[selectWidgets].data.size['width']) - xRatio
            height = Number(widgetList[selectWidgets].data.size['height']) + yRatio
        break;

        case 'LeftBottom':
            ////////////////////////////////////////////////////////
            //  쉬프트를 누른상태
            ////////////////////////////////////////////////////////
            if(shiftKey){
                if(xPoint*-1/widthRatio<yPoint){
                    xRatio = (yPoint * widthRatio)*-1 
                    yRatio = yPoint 
                }
                else{
                    xRatio = xPoint 
                    yRatio = (xPoint * heightRatio) *-1
                }
           
                xPosition = Number(widgetList[selectWidgets].data.position['x']) + xRatio
    
                width = Number(widgetList[selectWidgets].data.size['width']) - xRatio
                height = Number(widgetList[selectWidgets].data.size['height']) + yRatio
            }
            ////////////////////////////////////////////////////////
            //  기본상태
            ////////////////////////////////////////////////////////
            else{
                xPosition = Number(widgetList[selectWidgets].data.position['x']) + xPoint

                width = Number(widgetList[selectWidgets].data.size['width']) - xPoint
                height = Number(widgetList[selectWidgets].data.size['height']) + yPoint
            }
            break;
        ////////////////////////////////////////////////////////
        //  오른쪽 하단 포인트 드래그
        ////////////////////////////////////////////////////////
        case 'RatioRightBottom':
            if(xPoint/widthRatio<yPoint){
                xRatio = (yPoint * widthRatio)
                yRatio = yPoint 
            }
            else{
                xRatio = xPoint 
                yRatio = (xPoint * heightRatio)
            }
            width = Number(widgetList[selectWidgets].data.size['width']) + xRatio
            height = Number(widgetList[selectWidgets].data.size['height']) + yRatio
        break;

        case 'RightBottom':
            if(shiftKey){
                if(xPoint/widthRatio<yPoint){
                    xRatio = (yPoint * widthRatio)
                    yRatio = yPoint 
                }
                else{
                    xRatio = xPoint 
                    yRatio = (xPoint * heightRatio)
                }
                width = Number(widgetList[selectWidgets].data.size['width']) + xRatio
                height = Number(widgetList[selectWidgets].data.size['height']) + yRatio
            }
            ////////////////////////////////////////////////////////
            //  기본상태
            ////////////////////////////////////////////////////////
            else{
            width = Number(widgetList[selectWidgets].data.size['width']) + xPoint
            height = Number(widgetList[selectWidgets].data.size['height']) + yPoint
            }
            break;
        default:
            break;
    }
    if(width<0){width = 0}
    if(height<0){height = 0}
    if(xPosition!=null){
        widgetList[selectWidgets].data.position['x'] = xPosition.toFixed(0)
    }
    if(yPosition!=null){
        widgetList[selectWidgets].data.position['y'] = yPosition.toFixed(0)
    }
    if(width!=null){
        widgetList[selectWidgets].data.size['width'] = width.toFixed(0)
    }
    if(height!=null){
        widgetList[selectWidgets].data.size['height'] = height.toFixed(0)
    }

    // console.log('상대좌표',xPoint,yPoint,'startX : ',startX,'startY : ',startY,'endX : ',endX,'endY : ',endY)
    // console.log(width,height)

    // EnCoder.screenDataUpdate(widgetList,'dragMove') 

    context.modifyPage({'page_widget':widgetList},'modifyWidget')
    return(widgetList[selectWidgets].data)
  }


////////////////////////////////////////////////////////
// coordinateSystem
// 위젯의 좌표계 
////////////////////////////////////////////////////////

export function coordinateSystem(context,type,e,endX=0,endY=0) {


    const SELECTWIDGET = context.selectWidget;
    
    const widgetList = Utility.deepCopy(context.nowPage()?.page_widget);
    const selectWidgets = widgetList.findIndex(item => item.uuid === SELECTWIDGET);

    const resolution = context.overlap.meta.resolution;

    // let screen = document.getElementById('EditScreen')
    let zoom = resolution.width/(resolution.width*context.editor.zoom)

    // 딥 카피 안하면 문제 발생
    let widget = Utility.deepCopy(widgetList[selectWidgets])
    // edit스크린의 사이즈에 맞춰서 %보정을 진행합니다
    let startX = e.pageX * zoom
    let startY = e.pageY * zoom
    endX = endX * zoom
    endY = endY * zoom
    
    // 드레그 시작 위지부터 드레그 종료위치까지 
    let xPoint = Number(startX-endX)
    let yPoint = Number(startY-endY)


    let width = null
    let height = null
    let xPosition =0
    let yPosition =0
    
    
    if(!widget.data.size['width']){
        widget.data.size['width'] = Model.safety(widget).size.width
    }
    if(!widget.data.size['height']){
        widget.data.size['height'] = Model.safety(widget).size.height
    }

    let widthRatio = Number(widgetList[selectWidgets].data.size['width'])/Number(widgetList[selectWidgets].data.size['height'])
    let heightRatio = Number(widgetList[selectWidgets].data.size['height'])/Number(widgetList[selectWidgets].data.size['width'])
    let xMove,yMove

    // X좌표 부터
    switch (type) {
        case 'Top':
            yPosition = yPoint

            height = Number(widget.data.size['height']) - yPoint
            break;
        case 'Bottom':
            height = Number(widget.data.size['height']) + yPoint
            break;
        case 'Left':
            xPosition = xPoint

            width = Number(widget.data.size['width']) - xPoint
            break;
        case 'Right':
            console.log(` xPoint : ${xPoint} now : ${widgetList[selectWidgets].data.size['width']} now2 : ${widget.data.size['width']}`)
            width = Number(widget.data.size['width']) + xPoint
            break;
        ////////////////////////////////////////////////////////
        //  왼쪽 상단으로 드레그시
        ////////////////////////////////////////////////////////
        case 'RatioLeftTop':
            if(xPoint/widthRatio>yPoint){
                xMove = yPoint * widthRatio
                yMove = yPoint
            }else{
                xMove = xPoint 
                yMove = xPoint * heightRatio
            }
            xPosition =xMove
            yPosition =yMove
            width = Number(widget.data.size['width']) - xMove
            height = Number(widget.data.size['height']) - yMove
        break;

        case 'LeftTop':
            ////////////////////////////////////////////////////
            //  쉬프트 비율유지 변형 사용시
            ////////////////////////////////////////////////////
            if(e.shiftKey){
                if(xPoint/widthRatio>yPoint){
                    xMove = yPoint * widthRatio
                    yMove = yPoint
                }else{
                    xMove = xPoint 
                    yMove = xPoint * heightRatio
                }
                xPosition =xMove
                yPosition =yMove
                width = Number(widget.data.size['width']) - xMove
                height = Number(widget.data.size['height']) - yMove

            }else{
                xPosition = xPoint
                yPosition = yPoint
    
                width = Number(widget.data.size['width']) - xPoint
                height = Number(widget.data.size['height']) - yPoint
            }
            break;
        ////////////////////////////////////////////////////////
        //  오른쪽 상단으로 드레그시
        ////////////////////////////////////////////////////////
        case 'RatioRightTop':
            if(xPoint*-1/widthRatio>yPoint){
                xMove = yPoint * widthRatio *-1
                yMove = yPoint
            }else{
                xMove = xPoint 
                yMove = xPoint * heightRatio *-1 
            }
            yPosition = yMove
            width = Number(widget.data.size['width']) + xMove
            height = Number(widget.data.size['height']) - yMove
        break;
        
        case 'RightTop':
            if(e.shiftKey){
                if(xPoint*-1/widthRatio>yPoint){
                    xMove = yPoint * widthRatio *-1
                    yMove = yPoint
                }else{
                    xMove = xPoint 
                    yMove = xPoint * heightRatio *-1 
                }
                yPosition = yMove
                width = Number(widget.data.size['width']) + xMove
                height = Number(widget.data.size['height']) - yMove
            }else{
                yPosition = yPoint
                width = Number(widget.data.size['width']) + xPoint
                height = Number(widget.data.size['height']) - yPoint
            }

            break;

        ////////////////////////////////////////////////////////
        //  왼쪽 하단으로 드레그시
        ////////////////////////////////////////////////////////
        case 'RatioLeftBottom':
            if(xPoint*-1/widthRatio<yPoint){
                xMove = (yPoint * widthRatio)*-1 
                yMove = yPoint
            }else{
                xMove = xPoint 
                yMove = (xPoint * heightRatio)*-1 
            }
            xPosition = xMove
            width = Number(widget.data.size['width']) - xMove
            height = Number(widget.data.size['height']) + yMove
        break;
        
        case 'LeftBottom':
            if(e.shiftKey){
                if(xPoint*-1/widthRatio<yPoint){
                    xMove = (yPoint * widthRatio)*-1 
                    yMove = yPoint
                }else{
                    xMove = xPoint 
                    yMove = (xPoint * heightRatio)*-1 
                }
                xPosition = xMove
                width = Number(widget.data.size['width']) - xMove
                height = Number(widget.data.size['height']) + yMove
            }else{
            xPosition = xPoint

            width = Number(widget.data.size['width']) - xPoint
            height = Number(widget.data.size['height']) + yPoint
            }
            break;
        ////////////////////////////////////////////////////////
        //  오른쪽 하단으로 드레그시
        ////////////////////////////////////////////////////////
        case 'RatioRightBottom':
            if(xPoint/widthRatio<yPoint){
                xMove = (yPoint * widthRatio)
                yMove = yPoint
            }else{
                xMove = xPoint 
                yMove = (xPoint * heightRatio)  
            }
            width = Number(widget.data.size['width']) + xMove
            height = Number(widget.data.size['height']) + yMove
        break;
        
        case 'RightBottom':
            if(e.shiftKey){
                if(xPoint/widthRatio<yPoint){
                    xMove = (yPoint * widthRatio)
                    yMove = yPoint
                }else{
                    xMove = xPoint 
                    yMove = (xPoint * heightRatio)  
                }
                width = Number(widget.data.size['width']) + xMove
                height = Number(widget.data.size['height']) + yMove
            }else{
            width = Number(widget.data.size['width']) + xPoint
            height = Number(widget.data.size['height']) + yPoint
            }
            break;
        default:
            break;
    }

    if(width<0){width = 0}
    if(height<0){height = 0}
    if(xPosition!=null){
        widget.data.position['x'] = xPosition.toFixed(0)
    }
    if(yPosition!=null){
        widget.data.position['y'] = yPosition.toFixed(0)
    }
    if(width!=null){
        widget.data.size['width'] = width.toFixed(0)
    }
    if(height!=null){
        widget.data.size['height'] = height.toFixed(0)
    }
    return(widget.data)
  }

////////////////////////////////////////////////////////
// event control
// 해당 객체에서 마우스 포커싱을 통해 일어나는 일을 체크합니다.
////////////////////////////////////////////////////////
/** isfocus | 포커싱하는 위젯을 변경합니다.
 * @param {number} e 해당 객체의 이벤트
 * @param {number} self 해당 객체의 this
 * @returns void
 *  */ 
export function isfocus (e,self) {
    console.log('isfocus',self.props.uuid);
    self.props.control.modifySelectWidget(self.props.uuid)
    e.stopPropagation();
}
export function keyDown (e,self) {
    console.log('포커스 된 위젯',self.props.widgetName)
    objectKeyEvent(e,self)
}
////////////////////////////////////////////////////////
// drag
// 드레그 이벤트  (크로뮴 엔진 전용 타 브라우저에서 onDrag와 onDragEnd에서 e.page 같은 좌표값을 알수없어 오류발생)
////////////////////////////////////////////////////////
/** drag | 위젯 드레그 중에 연속적으로 일어나는 이벤트를 정의합니다.
 * @param {number} e 해당 객체의 이벤트
 * @param {number} self 해당 객체의 this
 * @returns 위젯의 dragMoveX, dragMoveY 가 변경됩니다.
 *  */ 
export function drag (e,self) {

    // 만약 이동하지 않도록 설정된 위젯인경우 이동하지 않기
    if (self.props.data?.value?.option?.move == false){
        noneEvent(e,self)
        return
    }

    const resolution = self.context.overlap.meta.resolution
    let zoom = resolution.width/(resolution.width*self.context.editor.zoom)
    let moveStartX = e.clientX-self.state.startX
    let moveStartY = e.clientY-self.state.startY
    if(e.clientX!=0||e.clientY!=0){
        self.setState({
            dragMoveX : moveStartX*zoom,
            dragMoveY : moveStartY*zoom
        })
    }
}

/** dragEnd | 위젯 드레그가 끝날때 위젯에 일어나는 이벤트를 정의합니다.
 * @param {number} e 해당 객체의 이벤트
 * @param {number} self 해당 객체의 this
 * @returns 위젯의 위치가 드레그 한 곳으로 변경됩니다.
 *  */ 
export function dragEnd (e,self) {

    // 만약 이동하지 않도록 설정된 위젯인경우 이동하지 않기
    if (self.props.data?.value?.option?.move == false){
        noneEvent(e,self)
        return
    }

    const WidgetList = self.context?.nowPage().page_widget;           
    const SelectWidget = self.context.selectWidget;
    const widgetIndex = WidgetList.findIndex(item => item.uuid === SelectWidget);

    objectMove(self.context,widgetIndex,e.clientX,e.clientY,self.state.moveStartX,self.state.moveStartY);
    Event.deleteDragImage();
    self.setState({
        dragMoveX :0,
        dragMoveY :0,
    })
}
/** dragStart | 위젯 드레그가 시작할때 위젯에 일어나는 이벤트를 정의합니다.
 * @param {number} e 해당 객체의 이벤트
 * @param {number} self 해당 객체의 this
 * @returns 드레그 시작 지점을 state 에 기록합니다. (moveStartX,Y, startX,Y)
 *  */ 
export function dragStart (e,self) {

    // 만약 이동하지 않도록 설정된 위젯인경우 이동하지 않기
    if (self.props.data?.value?.option?.move == false){
        noneEvent(e,self)
        return
    }
    // store.dispatch(selectTemplate(self.props.id));  
    Event.setDragNoneImage(self,e)
    self.setState({
        moveStartX : e.pageX - self.object.getBoundingClientRect().left,
        moveStartY : e.pageY -self.object.getBoundingClientRect().top,
        startX:e.pageX,
        startY:e.pageY,
        dragMoveX :0,
        dragMoveY :0,
    })
    
}

export function noneEvent (e,self) {
    e.preventDefault();
    // e.stopPropagation();
}


export function drop (e,self) {
  
}

export function dragOver (e,self) {

}

export function dragLeave (e,self) {

}

export function dragEnter (e,self) {

}




////////////////////////////////////////////////////////
// Alt drag
// 드레그 이벤트  (크로뮴 엔진 전용 타 브라우저에서 onDrag와 onDragEnd에서 e.page 같은 좌표값을 알수없어 오류발생)
////////////////////////////////////////////////////////

export function alt_drag (e,self) {


    // 만약 이동하지 않도록 설정된 위젯인경우 이동하지 않기
    if (self.props.data?.value?.option?.move == false){
        noneEvent(e,self)
        return
    }

    const resolution = self.context.overlap.meta.resolution
    let zoom = resolution.width/(resolution.width*self.context.editor.zoom)
    let moveStartX = e.pageX-self.state.startX
    let moveStartY = e.pageY-self.state.startY

    if(e.pageX!=0||e.page!=0){
        self.setState({
            dragMoveX : moveStartX*zoom,
            dragMoveY : moveStartY*zoom,
            pageX : e.pageX,
            pageY : e.pageY,
        })
    } 
}

export function alt_dragEnd (e,self) {
    if(self.state.dragLock){
        return 
    }
    objectMove(self.context,self.context.selectWidget,e.clientX,e.clientY,self.state.moveStartX,self.state.moveStartY)
    Event.deleteDragImage()
    
    self.setState({
        dragMoveX :0,
        dragMoveY :0,
    })
    
}
export function alt_dragStart(e,self) {
    if(self.state.dragLock){
        return 
    }
    Event.setDragNoneImage(self,e)
    self.setState({
        moveStartX : e.pageX - self.object.getBoundingClientRect().left,
        moveStartY : e.pageY -self.object.getBoundingClientRect().top,
        startX:e.pageX,
        startY:e.pageY,
        dragMoveX :0,
        dragMoveY :0,
        
    })
    
}

export function alt_dragOver(e,self){
    e.preventDefault()
}

export function dragStopPropagation(e,self) {
    e.stopPropagation()
}




const templateClick = value => ({type:'templateClick',click : value})
////////////////////////////////////////////////////////
// objectKeyEvent (해당객체,이밴트)
// 특정키가 눌리는경우 위젯의 위치를 변경해줍니다.
////////////////////////////////////////////////////////

export function objectKeyEvent(event,self) {

    const id = self.props.id;

    // modifyPage = self.context.modifyPage;

    console.log('self :',self);

    let key = event.key
    let ctrl = event.ctrlKey
    let alt = event.altKey
    let shift = event.shiftKey

    switch (key) {

        // ['C'또는'c'] 복사
        case 'C':
            console.log(ctrl&&shift)
            if(ctrl&&shift){
                console.log('서식복사')
            }
            else if(ctrl){
                copy(id)
                console.log('복사')
            }
            break;
        case 'c':
            console.log(ctrl&&shift)
            if(ctrl&&shift){
                console.log('서식복사')
            }
            else if(ctrl){
                copy(id)
                console.log('복사')
            }
            break;
        case 'ㅊ':
            console.log(ctrl&&shift)
            if(ctrl&&shift){
                console.log('서식복사')
            }
            else if(ctrl){
                copy(id)
                console.log('복사')
            }
            break;
        // [DELETEorBACKSPACE] widget 삭제
        case 'Backspace':
            console.log('self',self.context)
            delect(self.context)

            // this.context.modifyPage({'page_widget':},'modifyWidget')
            break;
        case 'Delete':
            delect(self.context)

            // this.context.modifyPage({'page_widget':delect(id)},'modifyWidget')
            break;
        // [ESC] 포커싱 해제
        case 'Escape':
            store.dispatch(selectTemplate(null));  
            break;
        // 방향키 컨트롤
        case 'ArrowLeft':
            keyMove(self.context,id,'left',shift)
            // keyMove(id,'left',shift)
            // if(shift){

            //     temp[id].data.position['x'] = Number(temp[id].data.position.x) - 1
            //     store.dispatch(screenDataDispatch(temp));
            // }else{
            //     temp[id].data.position['x'] = Number(temp[id].data.position.x) - 5
            //     store.dispatch(screenDataDispatch(temp));
            // }
            break;
        case 'ArrowRight':
            keyMove(self.context,id,'right',shift)
            // keyMove(id,'right',shift)
            // if(shift){
            //     temp[id].data.position['x'] = Number(temp[id].data.position.x) + 1
            //     store.dispatch(screenDataDispatch(temp));
            // }else{
            //     temp[id].data.position['x'] = Number(temp[id].data.position.x) + 5
            //     store.dispatch(screenDataDispatch(temp));
            // }
            break;   
        case 'ArrowUp':
            keyMove(self.context,id,'up',shift)
            // keyMove(id,'up',shift)
            // if(shift){
            //     temp[id].data.position['y'] = Number(temp[id].data.position.y) - 1
            //     store.dispatch(screenDataDispatch(temp));
            // }else{
            //     temp[id].data.position['y'] = Number(temp[id].data.position.y) - 5
            //     store.dispatch(screenDataDispatch(temp));
            // }
            break;   
        case 'ArrowDown':
            keyMove(self.context,id,'down',shift)
            // keyMove(id,'down',shift)
            // if(shift){
            //     temp[id].data.position['y'] = Number(temp[id].data.position.y) + 1
            //     store.dispatch(screenDataDispatch(temp)); 
            // }else{
            //     temp[id].data.position['y'] = Number(temp[id].data.position.y) + 5
            //     store.dispatch(screenDataDispatch(temp)); 
            // }
            break;   
        default:
            store.dispatch(templateClick(true));     
            break;
    }
  }

  
//////////////////////////////////////////////////////
//mouseControl (마우스 이벤트)
//마우스의 눌린 버튼을 보고 
//////////////////////////////////////////////////////
export function mouseControl (e,self) {
    switch (e.buttons){
        // 왼쪽 마우스클릭
        case 1 :
            isfocus(e,self)
            self.context.hideContext();
            break;
        // 오른쪽 마우스클릭
        case 2 :
            // self.context.showContext(this.props.screenUUID,'screen',e.clientX,e.clientY)
            self.context.showContext(self,'widget',e.clientX,e.clientY,self.props.uuid)
            Event.objectContextMenu(self.props.id,e.clientX,e.clientY,self)
            e.stopPropagation()
            break;
        // 왼쪽,오른쪽 동시 마우스클릭
        case 3 :
            self.context.hideContext();
            break;
        // 휠버튼
        case 4 :
            self.context.hideContext();
            break;

        default :
    }
}
////////////////////////////////////////////////////////
// display (해당겍체데이터)
// 해당 객체가 보이기, 숨기기 모드인지 확인하고 맞는 클래스명을 반환합니다.
////////////////////////////////////////////////////////

// 클래스 컴포넌트용
export function display(self) {
    if (self.data.display.show){
      return ' widgetShow '
    }else{
      return ' widgetHidden '
    }
  }
 
  // ==> 펑션 컴포넌트 용은 widgetService (activate) 로 이전됨 


////////////////////////////////////////////////////////
// move (해당객체번호)
// 해당 객체를 복사합니다.
////////////////////////////////////////////////////////
export function keyMove(context,selectWidgets,position,shift) {

    if (Array.isArray(selectWidgets)) {
        console.error('keyMove : 다중 편집을 아직 지원하지 않습니다.')
        return;
    }else{

    }

    const widgetList = context.nowPage()?.page_widget;

    // // 만약 이동하지 않도록 설정된 위젯인경우 이동하지 않기
    if (Model.safety(widgetList[selectWidgets]).value?.option?.move == false ){
        return
    }

    // 이동할 방향
    let direction = 0
        // 수정할 방향
    let value = 0

    switch (position) {
        case 'up':
            direction = 'y'
            value = -1
            break;
        case 'down':
            direction = 'y'
            value = 1    
            break;   
        case 'left':
            direction = 'x'
            value = -1
            break;
        case 'right':
            direction = 'x'
            value = 1  
            break;
        default:
            console.error('위젯 이동 에러 방향이 정해지지 않음')
            return;
        }
        if(shift){
            // "shift" 버튼과 함께 방향키를 누르는 경우 value 값 만큼 위젯을 이동시킵니다.
            widgetList[selectWidgets].data.position[direction] =  Number(Model.safety(widgetList[selectWidgets]).position[direction]) + (value);
        }else{
            // "shift" 버튼을 누르지 않는경우 value 값의 다섯배 만큼 위젯을 이동시킵니다.
            widgetList[selectWidgets].data.position[direction] =  Number(Model.safety(widgetList[selectWidgets]).position[direction]) + (value*5);
        }
        // 해당 페이지의 위젯 정보를 에딧 프로바이더를 통해 수정시킵니다.
        context.modifyPage({'page_widget':widgetList},'modifyWidget')
}

 
////////////////////////////////////////////////////////
// delect (해당객체번호)
// 해당 객체를 삭제합니다.
////////////////////////////////////////////////////////
const screenDataDispatch = screenData => ({type:'screenData',data : screenData})
const selectTemplate = id => ({type:'selectTemplate',select : id})

/** delect | 선택한 오버랩 위젯(위젯들)을 삭제합니다. 
 * @param {state} select 선택돈 위젯 | list 로 보내면 모든 위젯에 적용, Number 로 보내면 해당 위젯에 적용
 * @param {state} overlapPage 해당 위젯의 객체
 * @returns 오버랩을 생성합니다. 생성 성공시 생성한 오버랩의 아이디를 반환 합니다.
 *  */ 

export function delect(context) {
    const widgetList = context.nowPage()?.page_widget;
    const SelectWidget = context.selectWidget;
    if (Array.isArray(SelectWidget)) {
        console.error('keyMove : 다중 편집을 아직 지원하지 않습니다.')
        return;

    }else{

    }
    const newWidgetLi2st = widgetList.filter((item, index) => item.uuid !== SelectWidget);
    context.modifyPage({'page_widget':newWidgetLi2st},'modifyWidget')  
}



////////////////////////////////////////////////////////
// copy (해당객체번호)
// 해당 객체를 복사합니다.
////////////////////////////////////////////////////////
const clipBoardObject = object => ({type:'clipBoardObject',object})

export function copy(context,type) {
    context.appendClipBoard(type,context.findwidget(context.contextMenu.uuid).shift());
    // const state = store.getState(); // 현재 상태를 가져옵니다.
    // const { screenData } = state; // 편의상 비구조화 할당
    // store.dispatch(clipBoardObject(screenData[widgetID]));  
}

////////////////////////////////////////////////////////
// paste (붙여넣을 위치)
// 해당 객체를 붙여넣기 합니다.
////////////////////////////////////////////////////////

export function paste(context,type,position) {
    


    // Todo : 이하 구문 만들기


    if (!context.clipBoard.hasOwnProperty(type)) {
        console.error(`clipBoard : \n ⛔️ 클립보드에 해당 타입이 존재하지 않습니다 ${type} `);
        return
    }

    if(!context.clipBoard.length == 0){
        console.error(`clipBoard : \n ⛔️ ${type} 클립보드에 복사할 대상이 하나도 없습니다.`);
        return
    }
    const WidgetClipBoard = context.clipBoard[type];
    let targetWidget =  Utility.deepCopy(WidgetClipBoard[WidgetClipBoard.length- 1]);
    let targetPage = context.nowPage()

    ////////////////////////////////////////////////////////
    // 복사 세이프티, alias 또는 UUID를 통해 KEY가 중복되는것을 막음
    ////////////////////////////////////////////////////////

    // 같은 페이지내 복사를 통한 UUID 중복 방지구문
    for (let index = 0; index < targetPage.page_widget.length; index++) {
        if (targetPage.page_widget[index].uuid == targetWidget.uuid)
        {   
            console.error('uuid 중복')
            targetWidget.uuid = uuidMake()
            break
        }
    }
     // 같은 페이지내 복사를 통한 UUID 중복 방지구문
     for (let index = 0; index < targetPage.page_widget.length; index++) {
        if (targetPage.page_widget[index].alias == targetWidget.alias)
        {   
            console.error('uuid 중복')
            targetWidget.alias = null
            break
        }
    }

    if(position!=null){
        targetWidget.data.position.x = position.x
        targetWidget.data.position.y = position.y
    }

    context.modifyWidget(targetPage.page_widget.push(targetWidget));
}

function uuidMake() {
    function s4() {
      return ((1 + Math.random()) * 0x10000 | 0).toString(16).substring(1);
    }
    return s4() + s4() + s4() + s4() + s4() + s4() + s4() + s4();
  }

////////////////////////////////////////////////////////
// index (해당객체번호)
// 해당 객체를 복사합니다.
////////////////////////////////////////////////////////
export function checkSwap(context,widgetUUID,value) {
}

export function indexSwap(context,widgetUUID,value) {
    // 보고 있는 페이지의 위젯 리스트를 가저옵니다.

    const PageWidgets = context.nowPage().page_widget ;
    // 선택한 위젯을 가저옵니다. 
    const TargetWidget = context.findwidget(widgetUUID)[0];
    const TargetIndex = PageWidgets.findIndex(item => item.uuid === widgetUUID);
    // 위젯 리스트에서 선택한 위젯을 제거합니다.
    let newTargetWidgets = PageWidgets.filter(item => item.uuid !== widgetUUID);


    
    
    if(TargetIndex===-1){
        console.error('[error] ⛔️ widget > indexSwap : 현제 페이지에서 선택한 위젯을 찾을 수 없습니다. ');
        return
    }

    const moveIndex = TargetIndex + value;
    if(moveIndex > PageWidgets.length ){
        console.log('[error] ⛔️ widget > indexSwap : 위젯이 더 뒤로 갈 수 없음')
        return;
    }
    if(moveIndex < 0 ){
        console.log('[error] ⛔️ widget > indexSwap : 위젯이 더 앞으로 갈 수 없음')
        return;
    }

    newTargetWidgets.splice(moveIndex, 0, TargetWidget);
    context.modifyPage({'page_widget':newTargetWidgets},'modifyWidget')  
}

/** indexFront | 특정 위젯을 페이지의 가징 최상단으로 이동시킵니다.
 * @param {EditProvider} context 전역 데이터 접근을 위해 context 객체가 필요합니다.
 * @param {string} widgetUUID 페이지의 최상단으로 이동 시킬 위젯의 uuid
 * @returns void 
 *  */ 
export function indexFront(context,widgetUUID) {
    // 보고 있는 페이지의 위젯 리스트를 가저옵니다.
    const PageWidgets = context.nowPage().page_widget ;
    // 선택한 위젯을 가저옵니다. 
    const TargetWidget = context.findwidget(widgetUUID)[0];
    // 위젯 리스트에서 선택한 위젯을 제거합니다.
    let NewTargetWidgets = PageWidgets.filter(item => item.uuid !== widgetUUID);
    // 위젯 리스트에서 맨 앞에 추가해줍니다.
    NewTargetWidgets.unshift(TargetWidget)
    // 수정 사항을 반영합니다.
    context.modifyPage({'page_widget':NewTargetWidgets},'modifyWidget')  
}
export function indexEnd(context,widgetUUID) {
    // 보고 있는 페이지의 위젯 리스트를 가저옵니다.
    const PageWidgets = context.nowPage().page_widget ;
    // 선택한 위젯을 가저옵니다. 
    const TargetWidget = context.findwidget(widgetUUID)[0];
    // 위젯 리스트에서 선택한 위젯을 제거합니다.
    let NewTargetWidgets = PageWidgets.filter(item => item.uuid !== widgetUUID);
    // 위젯 리스트에서 맨 뒤에 추가해줍니다.
    NewTargetWidgets.push(TargetWidget)
    // 수정 사항을 반영합니다.
    context.modifyPage({'page_widget':NewTargetWidgets},'modifyWidget') 
}



