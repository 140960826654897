////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결

// 위젯 
import SpaceModuleViewGuideList from 'pages/lobby/space/sm_view/SpaceModuleViewGuideList';

// 이미지 연결
import logoW from 'assets/logoW.svg';
import logo from 'assets/logo.svg';
import twitchLogo from 'assets/main/twitchlogo.png';
import edit from 'assets/main/edit.png';
import bot from 'assets/main/bot.png';
import control from 'assets/main/control.png';
import banner1 from 'assets/main/banner1.png';

// 컴포넌트 연결
import SpaceGuideList from "components/Lobby/space/module/SpaceGuideList"
import SpaceModuleViewList from './sm_view/SpaceModuleViewList';
import SpaceModuleChannelList from './sm_channel/SpaceModuleChannelList';
import SpaceModuleViewPreview from './sm_view/SpaceModuleViewPreview';
import SpaceModuleViewDummy from './sm_view/SpaceModuleViewDummy';
import ViewCreate from "components/Lobby/space/module/ViewCreate"
import ChannelCreate from "components/Lobby/space/module/ChannelCreate"

// 서비스 연결

import * as Space from 'service/api/space' ; 
import * as guide from 'service/event/guide';
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class SpaceView extends Component {
    
    state = {
        mode : null,
        select : null,
        view : null,
        delete : null,
    }

    async componentDidMount () {
        
    }

    constructor(props) {
        super(props);
        this.spaceRef = React.createRef();
      }


      
    componentDidUpdate(prevProps){
        
    }
    setSelect = (id,uuid,view)=>{
        if(id == null || uuid == null || uuid == this.state.delete){
            return;
        }
        const select = {id,uuid};
        this.setState({
            select,
            view,
            mode : null
        })
        
    }
    setMode = (mode) => { 
        this.setState({
            mode : mode,
            select : null,
            view : null,
        })
    }

    deletePreview = (target) => { 
        this.setState({
            delete : target,
        })

        setTimeout(() => {
            this.setState({
                mode : null,
                select : null,
                overlap : null,
            })
        }, 1000);
    }
    



    render() { 
        switch (this.state.mode) {
            case 'append':
                return (
                    <div className = "Lobby_innerPage" >
                        <div className = "Lobby_common Lobby_SpaceOverlap" ref={this.spaceRef} >
                            <ChannelCreate spaceId={this.props.space?.id} setMode={this.setMode} />
                        </div>
                    </div>
                )
        
            default:
                return (
                    <div className = "Lobby_innerPage" >
                        <div className = "Lobby_common Lobby_SpaceOverlap" ref={this.spaceRef} >
            
                        <div className={this.state.select != null ?'upperSide abled':'upperSide disabled'}>
                
                    <SpaceGuideList guideList={guide.viewGuide}  lobbyRoutes={this.props.lobbyRoutes}/>
                    
                    {this.state.select != null ?
                    <SpaceModuleViewPreview view={this.state.view} spaceId={this.props.space?.id} deletePreview={this.deletePreview}/>

                    :
                    <SpaceModuleViewDummy/>
                    }     
                    </div>      
                   

                    <SpaceModuleChannelList spaceId={this.props.space?.id} setSelect={this.setSelect} delete={this.state.delete} mode={this.state.mode} setMode={this.setMode}/>
                    {/* <SpaceModuleViewList spaceId={this.props.space?.id} setSelect={this.setSelect} mode={this.state.mode} setMode={this.setMode}/> */}
                        
                        </div>
                    </div>
                )
        }
    }
}
////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////


  // export default Login;
  export default SpaceView;
  