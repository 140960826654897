////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { useState, useCallback, useContext, useEffect, useRef, memo } from 'react';
import ContentEditable from 'react-contenteditable';
import "sass/widgetEdit.scss";
import { icon } from 'service/edit/EditService';
import * as TextEditor from 'service/edit/TextEditorService';
import * as ED from 'service/engine/decoder/EditorDecoder';

// 서비스 연결
import * as Editor from 'service/edit/EditService'; 
import * as Utility from 'service/other/Utility'; 
import DropdownModuleV2 from '../editModule/DropdownModuleV2';
import DropdownButtonV2 from '../editModule/DropdownButtonV2';
import DropdownFontModuleV2 from '../editModule/DropdownFontModuleV2';

import _ from 'lodash'; // lodash import

import { style } from 'service/model/widget/UnknownModel';
import { CodeSharp, Tune } from '@material-ui/icons';
import { coordinateSystem } from 'service/event/widget';
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

const TextEditV2Form = memo(({ target,  modify, keyName, title, option }) => {

    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////

    // 이전 텍스트 데이터를 저장하는 Ref
    const PrevValueRef = useRef(null);
    const PrevText_JSON = useRef(null);
    // 최근에 스타일이 변경되었는지 확인하는 Ref
    const ModifyRef = useRef(false);
    // 현재 HTML 콘텐츠를 저장하는 Ref
    const HTMLRef = useRef(null);
    // 커서 위치와 선택 영역을 저장하는 Ref
    const corserRef = useRef(null);
    
    // 에디터에서 가지고 오는 위젯의 속성
    const { text, textAlign, lineHeight } = keyName;

    // 폼의 열림/닫힘 상태를 관리하는 State
    const [Toggle, setToggle] = useState(option?.openToggle === undefined ? true : option?.openToggle);
    const [OpenForm, setOpenForm] = useState(option?.openForm === undefined ? true : option?.openForm);

    // 캐쉬
    const [Cache,setCache] = useState(null);

  console.log('ED.getValue(target,text)',ED.getValue(target,text))


    const Last_word = ED.getValue(target,text)?.[0]

    const getLastElement = (arr) => arr?.[arr.length - 1]?.style ?? false;

    // 기본으로 정의된 텍스트 스타일을 설정하는 State
    const DefaultStyle = {
      'color': '#000000',
      'fontfamily': '프리텐다드SemiBold',
      'fontSize': 50,
      'fontWeight': 'normal',
      'fontStyle': 'normal',
      'textDecoration': 'none',
    }

    DefaultStyle['color'] = getLastElement(ED.getValue(target,text))?.color ? getLastElement(ED.getValue(target,text))?.color : DefaultStyle.color ;
    DefaultStyle['fontfamily'] = getLastElement(ED.getValue(target,text))?.fontfamily ? getLastElement(ED.getValue(target,text))?.fontfamily : DefaultStyle.fontfamily ;
    DefaultStyle['fontSize'] = getLastElement(ED.getValue(target,text))?.fontSize ? getLastElement(ED.getValue(target,text))?.fontSize : DefaultStyle.fontSize ;
    DefaultStyle['fontStyle'] = getLastElement(ED.getValue(target,text))?.fontStyle ? getLastElement(ED.getValue(target,text))?.fontStyle : DefaultStyle.fontStyle ;
    DefaultStyle['fontWeight'] = getLastElement(ED.getValue(target,text))?.fontWeight ? getLastElement(ED.getValue(target,text))?.fontWeight : DefaultStyle.fontWeight ; 
    DefaultStyle['textDecoration'] = getLastElement(ED.getValue(target,text))?.textDecoration ? getLastElement(ED.getValue(target,text))?.textDecoration : DefaultStyle.textDecoration ;

    // 기본 스타일을 정하는 Ref
    const DefaultStyleRef = useRef(DefaultStyle);


    // 커서 상태를 저장하는 State
    const [CorserState, setCorserState] = useState({ start: null, end: null, area: null });
    
    const [StyleTextAlign, setStyleTextAlign] = useState(ED.getValue(target,textAlign));
    const [StyleLineHeight, setStyleLineHeight] = useState(ED.getValue(target,lineHeight));

    // 에디터에 표시될 HTML 콘텐츠를 저장하는 State
    const [html, refresh_Html] = useState('');

    const [flag_R, flag_SetR] = useState(0);

    // ContentEditable 컴포넌트에 대한 Ref
    const contentEditable = useRef(null);


    /* 텍스트 스타일 수정용 REF */
    // textColorRef : debounce 상황에서 최신 텍스트 값을 전달하기 위해 사용합니다.
    const textColorRef = useRef(null);

    // keyPressRef : 키를 꾹 눌러 반복적으로 실행하는 클릭 이벤트에서 interval 제어를 위해 사용합니다.
    const keyPressRef = useRef(null);

    
    ///////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////

    useEffect(() => {
      initForm();
      return () => {
        clearInterval(keyPressRef.current);
      };
    }, []);


    useEffect(() => {
      console.log('⭕️리렌더 HTML StyleUpdateFlag')
      restoreSelection();
    }, [html,flag_R]);

    useEffect(() => {
      console.log(' CorserState 체크')
      restoreSelection();
    }, [CorserState]);


  


    ////////////////////////////////////////////////////////
    // function
    ////////////////////////////////////////////////////////

    /** 오버랩 데이터를 가지고 텍스트 폼을 초기화 합니다. */
    const initForm = () => {
      const WidgetText_JSON = ED.getValue(target, text);

      // 위젯의 텍스트 데이터 값을 복사해 이전 데이터 값을 담는 [PrevText_JSON] rel에 넣습니다.
      PrevText_JSON.current = Utility.deepCopy(WidgetText_JSON);
      
      PrevValueRef.current = Utility.deepCopy(WidgetText_JSON);
      // 위젯의 텍스트 데이터 값으로 HTML을 만듭니다.
      const Text_HTML = TextEditor.decoder(WidgetText_JSON);
      // 에디터가 준비 되었는지 체크합니다. 만약 준비된 경우 에디터를 포커싱 합니다.
      if (contentEditable.current) {
        contentEditable.current.focus();
      }
      // 에디터를 업데이트 합니다.

      HTMLRef.current = Text_HTML;
      console.log('refresh_Html : initForm',Text_HTML)
      refresh_Html(Text_HTML);
    }


    ////////////////////////////////////////////////////////
    // Handlers
    ////////////////////////////////////////////////////////

    // 새로운 글자를 입력시 실행되는 구문
    const textOnChange = (evt) => {
        console.log('textOnChange : 변경 ')
        console.log('이벤트',evt.target.value)
        // ContentEditable 의 HTML 값을 가지고 옵니다.
        HTMLRef.current = evt.target.value;
        
        // HTML을 JSON 형식으로 인코딩합니다.
        console.log('evt.type',ModifyRef.current);

        const EditableEventType = evt.type

        const Value_JSON = TextEditor.encoder(HTMLRef.current,PrevValueRef.current,DefaultStyleRef.current,{styleModify:ModifyRef.current&&evt.type=='input'});

        console.log('HTMLRef.Value_JSON',Value_JSON);
        switch (EditableEventType) {
          case 'input':
            if(ModifyRef.current){
              const LastValue = PrevValueRef.current[PrevValueRef.current.length - 1];
              let targetIndex = Value_JSON.findIndex(obj => obj.styleKey === LastValue.styleKey);

              if(Value_JSON.length - targetIndex > 0){
                ModifyRef.current= false
              }
            }
            break;
          case 'blur':

            break;
        
          default:
            break;
        }

        const Value_HTML = TextEditor.decoder(Value_JSON);
        PrevValueRef.current = Value_JSON
        HTMLRef.current = Value_HTML
        console.log('HTMLRef.current',HTMLRef.current);
        if(Value_JSON.length == 0){
          console.log('refresh_Html : textOnChange',Value_HTML)
          refresh_Html(Value_HTML)
        }


 

        modify(text.category, text.key, Value_JSON, target.uuid,{log:'textOnChange'});

    };

    const pushEnter = () => {
  
      const Value_JSON = TextEditor.encoder(HTMLRef.current,PrevValueRef.current,DefaultStyleRef.current,{enter : corserRef.current?.start});
      // 이전 스타일 가지고 오기
      const prevText = Value_JSON[corserRef.current?.start - 1]?? Value_JSON[corserRef.current?.start];
      // 공백추가
      Value_JSON.splice(corserRef.current?.start, 0, { char: '\n', style: prevText?.style });
      corserRef.current.start = corserRef.current.start + 1;
      corserRef.current.end = corserRef.current.end + 1;
      const Value_HTML = TextEditor.decoder(Value_JSON);
      PrevValueRef.current = Value_JSON
      HTMLRef.current = Value_HTML;
      console.log('refresh_Html : pushEnter',Value_HTML)
      refresh_Html(Value_HTML);

      modify(text.category, text.key, Value_JSON, target.uuid,{log:'pushEnter'});
    } 

    const endEdit = () => { 
      // 문제 요인 여기서 HTML을 가지고 최신 스타일 반영불가
      const Value_JSON = TextEditor.encoder(HTMLRef.current,PrevValueRef.current,DefaultStyleRef.current);
      const Value_HTML = TextEditor.decoder(Value_JSON);
      PrevValueRef.current = Value_JSON
      HTMLRef.current = Value_HTML;
      refresh_Html(Value_HTML);
   
      modify(text.category, text.key, Value_JSON, target.uuid,{log:'endEdit'});
      
    } 



    /**
     * HTMLRef의 값으로 HTMLState를 최신화 합니다.
     */
    const syncHtml = () => {
      // HTMLRef(최신화된 HTML) 과 html State 가 다르면 최신으로 갱신해줍니다.

      if(HTMLRef.current !== html){
        console.log('refresh_Html : syncHtml',HTMLRef.current,html)
        refresh_Html(HTMLRef.current);
      }
    }

    const cursorChange = (option) => {
      
    }


    const handlePaste = (event) => {
      // 기본 붙여넣기 동작을 막음
      event.preventDefault();
  
      // 붙여넣기 데이터에서 텍스트를 가져옴
      const pasteText = event.clipboardData.getData('text/plain');
  
      // 현재 커서 위치에 텍스트 삽입
      document.execCommand('insertText', false, pasteText);
    };

    /**
     * 마우스 이벤트 발생시 선택 영억을 삭제합니다.
     * 선택 영역이 있어야만 작동합니다.
     * @param {e} event 
     */
    const removeRanges = (event) => {

      // 마우스 클릭 위치 좌표를 가져옴
      const mouseX = event.clientX; // 클릭된 위치의 X 좌표를 가져옴
      const mouseY = event.clientY; // 클릭된 위치의 Y 좌표를 가져옴
    
      // 해당 좌표에서 가장 가까운 엘리먼트를 찾음
      const closestElement = document.elementFromPoint(mouseX, mouseY); // 클릭된 좌표에 있는 가장 가까운 엘리먼트를 찾음
    
      // 현재 선택된 텍스트 정보를 가져옴
      const selection = window.getSelection();
    
      console.log('removeRanges',closestElement, selection.rangeCount , selection.isCollapsed)
      // 가까운 엘리먼트가 존재하고, 선택된 텍스트 범위가 있을 때
      if (closestElement && selection.rangeCount > 0) {
        selection.removeAllRanges(); // 모든 선택된 텍스트 범위를 제거함 (텍스트 선택 해제)
      }
    };


    
    const handleCursorChange = (option) => {

      const selection = window.getSelection();

      console.log('체크 포인트')
      if (selection.rangeCount != 1) {
        // 선택된 영역의 숫자가 1
        console.error('선택 영역 없음' )
        return ;
      }

      const range = selection.getRangeAt(0);
      // 현재 선택(selection)된 영역의 첫 번째 Range 객체를 가져옵니다. 대부분의 브라우저는 선택영역이 하나라서 무조건 0 으로 고정
      const preCaretRange = range.cloneRange();
      // 기존 range 객체를 복제하여 새로운 Range 객체인 preCaretRange를 생성합니다.
      preCaretRange.selectNodeContents(contentEditable.current);
      // 기능: preCaretRange의 범위를 contentEditable 요소의 전체 내용으로 설정합니다.
      preCaretRange.setEnd(range.startContainer, range.startOffset);
      // 기능: preCaretRange의 끝 지점을 원래 선택된 range의 시작 지점으로 설정합니다.

      /**
       * 선택영역의 HTML 에서 <BR> 태그의 숫자를 구합니다.
       * @param {*} range 
       * @returns 
       */
      const getLineBreak = (range) => {
        const div = document.createElement('div');
        div.appendChild(range.cloneContents());
        return (div.innerHTML.match(/<br\s*\/?>/gi) || []).length;
      };

      // 영역내 BR 태그 숫자
      const startLineBreak = getLineBreak(preCaretRange);
      const start = preCaretRange.toString().length + startLineBreak;

      preCaretRange.setEnd(range.endContainer, range.endOffset);
      const endLineBreak = getLineBreak(preCaretRange);
      const end = preCaretRange.toString().length + endLineBreak;
      // 총 텍스트 숫자 계산
      const last = preCaretRange.toString().length;

      console.log('[위치] : 시작점 : ',start,' 종료점 : ', end,' 범위 영역 :',!range.collapsed, '전채 :',preCaretRange.toString().length)

      const hasSelection = !range.collapsed
      corserRef.current = { start, end ,area: hasSelection};

      // 컴포넌트 리렌더를 하지 않는경우
      if(!option?.refresh) {
        return 
      }

      // 선택영역을 제거합니다.
      contentEditable.current.blur(); 

      // 선택 영역이 없는 경우
      if(hasSelection == false){
        const Value_JSON = TextEditor.encoder(HTMLRef.current,PrevValueRef.current,DefaultStyleRef.current);
        switch (option?.use) {
          case 'keyboard':
            if(end&&ModifyRef.current==false){
              const Value_JSON = TextEditor.encoder(HTMLRef.current,PrevValueRef.current,DefaultStyleRef.current);
      
              DefaultStyleRef.current = Value_JSON[end-1]?.style??DefaultStyleRef.current;
            }

            if(end == 0&&ModifyRef.current==false){
              // 맨 앞인 경우
              console.log('[커서] : 텍스트2 (',Value_JSON[0]?.char,') \n 순서:',end,' 스타일 : ', Value_JSON[0]?.style);
              DefaultStyleRef.current = Value_JSON[0]?.style??DefaultStyleRef.current;
            }else if(end > 0&&ModifyRef.current==false){
              console.log('[커서] : 텍스트 (',Value_JSON[end-1]?.char,') \n 순서:',end-1,' 스타일 : ', Value_JSON[end-1]?.style);
              DefaultStyleRef.current = Value_JSON[end-1]?.style??DefaultStyleRef.current;
            }
            setCorserState(corserRef.current);
            refresh_Html(HTMLRef.current);
            break;
        
          default:

            if(end == 0&&ModifyRef.current==false){
              // 맨 앞인 경우
              console.log('[커서] : 텍스트2 (',Value_JSON[0]?.char,') \n 순서:',end,' 스타일 : ', Value_JSON[0]?.style);
              DefaultStyleRef.current = Value_JSON[0]?.style??DefaultStyleRef.current;
            }else if(end > 0&&ModifyRef.current==false){
              console.log('[커서] : 텍스트 (',Value_JSON[end-1]?.char,') \n 순서:',end-1,' 스타일 : ', Value_JSON[end-1]?.style);
              DefaultStyleRef.current = Value_JSON[end-1]?.style??DefaultStyleRef.current;
            }
            setCorserState(corserRef.current);
            refresh_Html(HTMLRef.current);
            break;
        }
      }

    }


    // 제어 문자를 확인하는 함수
  const isControlCharacter = (char) => {
    const code = char.charCodeAt(0);
    // 제어 문자 범위: 0x00 ~ 0x1F, 0x7F
  return (code >= 0x00 && code <= 0x1F) || code === 0x7F;
  }

  // 첫 번째 유효한 문자 찾기
  const getFirstValidChar = (dataArray,startIndex = 0) => {
  // 입력 검증
  if (!Array.isArray(dataArray)) {
    throw new TypeError('dataArray는 배열이어야 합니다.');
    }
    if (typeof startIndex !== 'number' || startIndex < 0 || startIndex >= dataArray.length) {
      throw new RangeError('startIndex는 0 이상 배열의 길이 미만의 정수여야 합니다.');
    }

    for (let i = startIndex; 0 < startIndex; i--) {
      const obj = dataArray[i];
      const char = obj.char;

      // 'char' 필드가 문자열인지, 빈 문자열이 아닌지, 제어 문자가 아닌지 확인
      if (typeof char === 'string' && char.trim() !== '' && !isControlCharacter(char)) {
          return obj;
      }
    }

    // 보안 필요 첫 텍스트 인 경우

    return null; // 유효한 문자가 없는 경우
  }

    


    // const handleCursorChange = (option) => {


    //   const selection = window.getSelection();


    //   console.log('체크 포인트')
    //   if (selection.rangeCount > 0) {

    //     const range = selection.getRangeAt(0);
    //     const preCaretRange = range.cloneRange();
    //     preCaretRange.selectNodeContents(contentEditable.current);

    //     preCaretRange.setEnd(range.startContainer, range.startOffset);
        
    //     const getLineBreak = (range) => {
    //       const div = document.createElement('div');
    //       div.appendChild(range.cloneContents());
    //       return (div.innerHTML.match(/<br\s*\/?>/gi) || []).length;
    //     };

    //     const startLineBreak = getLineBreak(preCaretRange);
    //     const start = preCaretRange.toString().length + startLineBreak;
    //     preCaretRange.setEnd(range.endContainer, range.endOffset);
    //     const endLineBreak = getLineBreak(preCaretRange);
    //     const end = preCaretRange.toString().length + endLineBreak;

        

    //     console.log('[커서] : 시작점 : ',start,' 종료점 : ', end,' 범위 영역 :',!range.collapsed, '전채 :',preCaretRange.toString().length , '테스트',range.endContainer, range.endOffset )
    //     const isCollapsed = range.collapsed
    //     corserRef.current = { start, end ,area: !isCollapsed};

    //     if(option?.refresh) {
    //       contentEditable.current.blur();
    //     // 범위가 아닌 경우

    //     if(isCollapsed&&option?.use != 'keyboard'){
    //       console.log('[커서] :  순서:',end-1,end,ModifyRef.current==false);
    //       const Value_JSON = TextEditor.encoder(HTMLRef.current,PrevValueRef.current,DefaultStyleRef.current);
    //       if(typeof end === 'number' &&ModifyRef.current==false){

    //         if(end == 0){
    //           // 맨 앞인 경우
    //           console.log('[커서] : 텍스트2 (',Value_JSON[0]?.char,') \n 순서:',end,' 스타일 : ', Value_JSON[0]?.style,getFirstValidChar(Value_JSON,0));
    //           DefaultStyleRef.current = Value_JSON[0]?.style??{'color':'#000000','fontfamily':'프리텐다드SemiBold','fontSize':50,'fontWeight':'normal'}
    //         }else{
    //           // 맨 앞인 경우
    //           console.log('[커서] : 텍스트 (',Value_JSON[end-1]?.char,') \n 순서:',end-1,' 스타일 : ', Value_JSON[end-1]?.style,getFirstValidChar(Value_JSON,end-1));
    //           DefaultStyleRef.current = Value_JSON[end-1]?.style??{'color':'#000000','fontfamily':'프리텐다드SemiBold','fontSize':50,'fontWeight':'normal'}
    //         }
    //       }
    //       setCorserState(corserRef.current);
    //       console.log('refresh_Html : handleCursorChange 1',HTMLRef.current)
    //       refresh_Html(HTMLRef.current);
    //     }else{
    //       // 사용자가 방금 수정하지 않았다면

    //       if(end&&ModifyRef.current==false){
    //         const Value_JSON = TextEditor.encoder(HTMLRef.current,PrevValueRef.current,DefaultStyleRef.current);
 
    //         DefaultStyleRef.current = Value_JSON[end-1]?.style??{'color':'#000000','fontfamily':'프리텐다드SemiBold','fontSize':50,'fontWeight':'normal'}
    //       }
    //       setCorserState(corserRef.current);

    //       refresh_Html(HTMLRef.current);
    //     }
        
    //     // const Value_JSON = TextEditor.encoder(HTMLRef.current,PrevValueRef.current,DefaultStyleRef.current);
    //     // const Value_HTML = TextEditor.decoder(Value_JSON);
  

    //     }else{

    //     }
    //   }


    // };



    /**
     * 반복적으로 컬러 변경시 작동하는 
     */
    const modifyColor = useCallback((e) => {
      if(textColorRef.current != null) {
        modifyStyle('color',textColorRef.current.toUpperCase())
        textColorRef.current = null;
      }
    }, []);

    const modifyFontSizeUP = () => {
      keyPressRef.current = setInterval(() => {
        flag_SetR((prevFlagR) => prevFlagR + 1); // 이전 값을 참조하여 업데이트
        modifyStyle('fontSize',Number(DefaultStyleRef.current?.fontSize) + 1)
      }, 100); // 100ms 간격으로 카운트 증가
    };

    const modifyFontSizeDown = () => {
      keyPressRef.current = setInterval(() => {
        flag_SetR((prevFlagR) => prevFlagR + 1); // 이전 값을 참조하여 업데이트
        modifyStyle('fontSize',Number(DefaultStyleRef.current?.fontSize) - 1)
      }, 100); // 100ms 간격으로 카운트 증가
    };
  
    // 마우스를 놓을 때 실행할 함수 (반복 중지)
    const handleMouseUp = () => {
      clearInterval(keyPressRef.current);
      flag_SetR(flag_R+1)
    };
    


    const throttledHandleChange = useCallback(
      _.debounce(modifyColor, 100, { leading: true, trailing: true }),
      [modifyColor]
    );

    useEffect(() => {
      // 클린업: 컴포넌트 언마운트 시 스로틀 취소
      return () => {
        throttledHandleChange.cancel();
      };
    }, [throttledHandleChange]);


    
    
    const restoreSelection = () => {

      console.log('restoreSelection : 변경',HTMLRef.current)
      // contentEditable 요소와 커서 참조가 존재하는지 확인합니다.
      if (contentEditable.current && corserRef.current?.start != null && corserRef.current?.end != null) {
        try {
          // 새 Range 객체를 생성합니다.
          const range = document.createRange();
    
          // Range의 시작 위치와 끝 위치를 설정합니다.
          range.setStart(contentEditable.current.childNodes[corserRef.current.start], 0);
          range.setEnd(contentEditable.current.childNodes[corserRef.current.end], 0);
    
          // 현재 선택된 범위를 초기화합니다.
          const selection = window.getSelection();
          selection.removeAllRanges();
    
          // 새로 만든 Range를 선택합니다.
          selection.addRange(range);
    
          // contentEditable 요소를 블러 처리한 후 포커스를 다시 맞춥니다.
          contentEditable.current.blur();
          contentEditable.current.focus();
          
        } catch (error) {
          // 에러가 발생하면 콘솔에 에러를 출력합니다.
          console.error(error);
        }
      }
    };

    const handleKeyDown = (e) => {

      switch (e.key) {
        case 'Enter':
          if(!e.nativeEvent.isComposing){
            e.preventDefault();
            pushEnter();
          }
          break;
        case 'ArrowLeft':
        case 'ArrowUp':
        case 'ArrowRight':
        case 'ArrowDown':

          // pushEnter();
          break;      
        default:
          break;
      }

    };

    const handleKeyUp = (e) => {
      switch (e.key) {
        case 'Enter':
          if(!e.nativeEvent.isComposing){
  
          }else{
            handleCursorChange();
          }
          break;
        case 'ArrowLeft':
        case 'ArrowUp':
          handleCursorChange({refresh:true,dragDirection:'left',use:'keyboard'});
          break;      
        case 'ArrowRight':
        case 'ArrowDown':
          handleCursorChange({refresh:true,dragDirection:'right',use:'keyboard'});
          break;      
        default:
          handleCursorChange();
          break;
      }

      if (e.key === 'Enter' && !e.nativeEvent.isComposing) {
        console.log('검사 패스', corserRef.current);
      } else {
  
      }
    };

    const modifyStyle = (key,value) => {
    
      handleCursorChange();

      // 선택영역안의 텍스트를 변경 하는 경우 (커서로 블록 지정이 되어 있는 경우)
      if(corserRef.current?.area == true){
        console.log('modifyStyle : ',key,value)
          // 텍스트 필드 내 커서의 위치를 고정합니다.
        restoreSelection(); 

        // 기본 스타일에 변경된 스타일을 반영합니다. (스타일 로딩 확인)
        if (DefaultStyleRef.current) {
          console.log('[컬러변경] 컬러 : ',value);
          DefaultStyleRef.current[key] =  Utility.deepCopy(value);
        }

        // 텍스트 필드의 상태를 가지고 옵니다.
        const ValueJSON = TextEditor.encoder(HTMLRef.current,PrevValueRef.current,DefaultStyleRef.current);

        // 영역을 찾습니다 *커서의 두개의 좌표중 작은수가 Start 큰 수가 End 에 담깁니다.
        const CorserStart = corserRef.current.start > corserRef.current.end ? corserRef.current.end : corserRef.current.start;
        const CorserEnd = corserRef.current.start > corserRef.current.end ? corserRef.current.start : corserRef.current.end;
        
        
        modifyArea(ValueJSON,key,value,CorserStart,CorserEnd)
      }else{
        // 모든 텍스트에 적용하기
        if (DefaultStyleRef.current) {
          DefaultStyleRef.current[key] =  Utility.deepCopy(value);
        }
        // 텍스트 필드의 상태를 가지고 옵니다.
        const ValueJSON = TextEditor.encoder(HTMLRef.current,PrevValueRef.current,DefaultStyleRef.current);
        modifyArea(ValueJSON,key,value,0,ValueJSON.length)
      }
      // 새로운 택스트에 적용할 스타일을 지정하는 경우
      // else if(corserRef.current?.area == false && CorserStart == CorserEnd){
      //   modifyCursor(ValueJSON,CorserStart)
      // }

    }


     /**
     * 선택된 영역의 스타일을 변경합니다.
     * @param {*} ValueJSON 
     * @param {*} widgetKey 변경할 스타일의 종류
     * @param {*} widgetValue 변경할 스타일의 값
     * @param {*} start 선택된 영역의 시작점
     * @param {*} end 선택된 영역의 종료점
     */
     const modifyArea = (ValueJSON,widgetKey,widgetValue,start,end) => {

      // 깊은 복사를 통해서 새로운 배열을 만들어줍니다. *이상하게 DEEPCOPY 안먹음
      ValueJSON =  JSON.parse(JSON.stringify(ValueJSON));

      // 텍스트 필드를 순회합니다.
      for (let index = start; index < end; index++) {
        // 문자의 정의된 스타일을 불러옵니다. 없는경우 빈 배열을 가지고 옵니다.
        const CharStyle = ValueJSON[index]?.style??{}
        // 새로운 스타일을 문자에 적용시킵니다.
        CharStyle[widgetKey] = widgetValue;
        ValueJSON[index]['style'] = CharStyle;
      }
      
      // 새로운 스타일을 문자에 적용시킵니다.
      const ValueHTML = TextEditor.decoder(ValueJSON);

      // 이전 JSON 값에 JSON 을 적용 해줍니다.
      PrevValueRef.current = ValueJSON;

      // HTML 값에 HTML 을 적용 해줍니다.
      HTMLRef.current = ValueHTML;

      // 변경된 텍스트를 서버로 전송합니다.
      modify(text.category, text.key, ValueJSON, target.uuid,{log:'CharStyleModify - 영역 스타일 변경'});

      // HTML 을 다시 랜더합니다.
      refresh_Html(JSON.parse(JSON.stringify(ValueHTML)));
      flag_SetR(flag_R+1)
    }



    const TextHandleNav = (targetStyle) => {
      const fontSizes = [10,20,30,40,50,60,80,100,130,150,180,250,300]

      return (
        <nav className='TextEditNav'>
        {/* 폰트 만들기 */}
        {/* <button className='TextEditNavButton OptipnButton'>
          <div className='SingleButton'> 
            {Editor.icon({icon:'format_bold',size:20,lineHeight:25})}
          </div>
        </button>

        <button className='TextEditNavButton OptipnButton'>
          <div className='SingleButton'> 
          {Editor.icon({icon:'format_italic',size:20,lineHeight:25})}
          </div>
        </button>

        <button className='TextEditNavButton OptpnButton'>
          <div className='SingleButton'> 
          {Editor.icon({icon:'format_underlined',size:20,lineHeight:25})}
          </div>
        </button> */}


        <DropdownFontModuleV2 style={{width : 120, listWidth : 200, fontSize : 12,}} value={DefaultStyleRef.current?.fontfamily} change={(value)=>{modifyStyle('fontfamily',value)}} list ={FONTLIST}/>
        <DropdownModuleV2 style={{width : 50}} value={DefaultStyleRef.current?.fontSize} change={(value)=>{modifyStyle('fontSize',Number(value))}} list ={fontSizes}/>
        {/* <DropdownButtonV2 style={{width : 50}} value={{value:DefaultStyleRef.current?.fontSize}} direction={'vertical'} change={(value)=>{modifyStyle('fontSize',Number(value))}} list = {[{'value' : 10},{'value' : 20},{'value' : 30},{'value' : 40},{'value' : 50},{'value' : 60},{'value' : 80},{'value' : 100},{'value' : 120},{'value' : 150},{'value' : 200},{'value' : 300},]}/> */}
        <button className='TextEditNavButton optionButton fontSizeButton'>
          <div className='SingleButton' onMouseDown={(e) => modifyFontSizeUP()}  onMouseUp={(e)=>handleMouseUp()} onMouseLeave={(e)=>handleMouseUp()}> 
          {Editor.icon({icon:'arrow_drop_up',size:20,lineHeight:25})}
          </div>
        </button>

        <button className='TextEditNavButton optionButton fontSizeButton'>
        <div className='SingleButton' onMouseDown={(e) => modifyFontSizeDown()} onMouseUp={(e)=>handleMouseUp()} onMouseLeave={(e)=>handleMouseUp()}> 
          {Editor.icon({icon:'arrow_drop_down',size:20,lineHeight:25})}
          </div>
        </button>

        <button className='TextEditNavButton optionButton ColorForm'>
          <input type="color" value={DefaultStyleRef.current?.color??'#000000'} onChange={(e) => {textColorRef.current = e.target.value;throttledHandleChange(e);console.log('추가 로직을 여기에 작성 2',e.target.value)}} />
        </button>

        <button className='TextEditNavButton optionButton' style={(DefaultStyleRef.current?.fontWeight == 'bold'?{backgroundColor:'#303030'} :{})}>
          <div className='SingleButton' onClick={(e) => modifyStyle('fontWeight',DefaultStyleRef.current?.fontWeight == 'bold'?'normal' :'bold')}> 
            {Editor.icon({icon:'format_bold',size:20,lineHeight:25})}
          </div>
        </button>

        <button className='TextEditNavButton optionButton' style={(DefaultStyleRef.current?.fontStyle == 'italic'?{backgroundColor:'#303030'} :{})}>
          <div className='SingleButton' onClick={(e) => modifyStyle('fontStyle',DefaultStyleRef.current?.fontStyle == 'italic'? 'normal' :'italic')}> 
          {Editor.icon({icon:'format_italic',size:20,lineHeight:25})}
          </div>
        </button>

        <button className='TextEditNavButton optionButton' style={(DefaultStyleRef.current?.textDecoration == 'underline'?{backgroundColor:'#303030'} :{})}>
          <div className='SingleButton' onClick={(e) => modifyStyle('textDecoration',DefaultStyleRef.current?.textDecoration == 'underline'? 'none' :'underline')}> 
          {Editor.icon({icon:'format_underlined',size:20,lineHeight:25})}
          </div>
        </button>

        <DropdownButtonV2 value={StyleTextAlign} direction={'horizontal'} change={(value)=>{ setStyleTextAlign(value); modify(textAlign.category, textAlign.key, value, target.uuid,{log:'textAlignChange'})}} list = {[{'value' : 'left','icon':'format_align_left'},{'value' : 'center','icon':'format_align_center'},{'value' : 'right','icon':'format_align_right'}]}/>

        <DropdownButtonV2 value={StyleLineHeight} direction={'vertical'} icon={'format_line_spacing'} change={(value)=>{ setStyleLineHeight(value); modify(lineHeight.category, lineHeight.key, value, target.uuid,{log:'lineHeightChange'})}} list = {[{'value' : 1.0,'text':'1.0'},{'value' : 1.2,'text':'1.2'},{'value' : 1.4,'text':'1.4'},{'value' : 1.6,'text':'1.6'},{'value' : 1.8,'text':'1.8'},{'value' : 2.0,'text':'2.0'},]}/>


        {/* <button className='TextEditNavButton OptpnButton'>
        <div className='SingleButton' onClick={(e) => modifyStyle('fontSize',Number(DefaultStyleRef.current?.fontSize) - 1)}> 
          {Editor.icon({icon:'arrow_drop_down',size:20,lineHeight:25})}
          </div>
        </button>


        <button className='TextEditNavButton OptpnButton'>
        <div className='SingleButton' onClick={(e) => modifyStyle('fontSize',Number(DefaultStyleRef.current?.fontSize) - 1)}> 
          {Editor.icon({icon:'arrow_drop_down',size:20,lineHeight:25})}
          </div>
        </button> */}


        </nav>
      )
      
    }

    // const TextWidgetNav = () => {

    //   return (
    //     <nav className='TextWidgetNav'>
    //     <button className='TextEditNavButton OptpnButton'>
    //     <div className='SingleButton' onClick={(e) => modifyStyle('fontSize',Number(DefaultStyleRef.current?.fontSize) - 1)}> 
    //       {Editor.icon({icon:'arrow_drop_down',size:20,lineHeight:25})}
    //       </div>
    //     </button>
    //     </nav>
    //   )
      
    // }

    const TargetStart = CorserState?.start??null
    const TargetEnd = CorserState?.end??null
    const TargetArea = CorserState?.area??false

    const TargetChar = TargetStart? PrevValueRef?.current?.[TargetStart]?.char : null;
    const TargetStyle = TargetStart? PrevValueRef?.current?.[TargetStart]?.style : null;
    return (
      <section className='TextEditV2Form'>
        <style jsx>{`${FormStyle}`}</style>
        <div className={OpenForm ? 'editContainer containerOpen' : 'editContainer containerClose'}>
          <article className="EditPart TextEditor">
            {TextHandleNav(TargetStyle)}

            <ContentEditable
              className='ContentEditable'
              innerRef={contentEditable}
              html={html + `<BR data-key="null"/>`}
              disabled={false}
              onChange={textOnChange}
              onPaste={handlePaste}
              onMouseLeave={()=>syncHtml()}
              onKeyUp={handleKeyUp}
              onKeyDown={handleKeyDown}
              onMouseDown={(e)=>removeRanges(e)}
              onMouseUp={()=>handleCursorChange({refresh:true})}
              tagName="div"
              style={{padding: '10px', minHeight: '100px' ,...DefaultStyleRef.current,textDecoration: 'none',fontSize:15,textAlign:StyleTextAlign,lineHeight:StyleLineHeight}}
            />
            {/* {TextWidgetNav()} */}
          </article>
        </div>

        {/* <div>
          컨트롤 박스
          {!TargetArea?
            <p>단어 : {TargetChar} 	&#91;{TargetStart}&#93;   </p>
            :
            <p>단어 : {TargetChar} 	&#91;{TargetStart}&#93; ~ {TargetChar} 	&#91;{TargetEnd}&#93;   </p>
          }          {Test2}

          <p>KEY : {JSON.stringify(TargetStyle)} </p>
          <p>KEY : {JSON.stringify(DefaultStyleRef.current)} </p>

          
        </div>
        <div>
          <p>선택 상태:{corserRef?.current?.area?'네':'아니요'}</p>
          <p>Start:  {CorserState.start}</p>
          <p>End: {corserRef?.current?.end??'null'}</p>
        </div>
          <textarea>{html}</textarea>
        <div dangerouslySetInnerHTML={{ __html: html }} /> */}
      </section>
    );

}, () => true);

export default TextEditV2Form;

const FormStyle = `
.TextEditV2Form #EditControl {
  width : 100%;
  height: 100%;
  position: absolute;
  z-index : 2;
  outline: none;
}

.TextEditV2Form .ContentEditable {
  background : #666;
  border-radius : 5px;
  outline: none;
  border: none;
  margin : 10px 0px;
}

.TextEditV2Form .TextEditNav{
  margin : 10px 0px;
  display: flex;
  flex-wrap: wrap;
  background : #222;
  border-radius : 5px;
}

.TextEditV2Form .TextWidgetNav{
  margin : 10px 0px;
  display: flex;
  flex-wrap: wrap;
  background : #333;
  border-radius : 5px;
}
.TextEditV2Form .optionButton{
    height: 25px;
    margin: 5px;
    background-color: #3F3F3F;
    border-radius: 2.5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2), 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease, background 0.3s ease-out;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.TextEditV2Form .optionButton:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  border-radius: 10px;
  z-index: -1;
}




.TextEditNavButton:active {
  background-color: red;
}

.TextEditV2Form .optionButton:active{
  transform: scale(0.80);
  background-color: #1C1C1C;
}

.TextEditV2Form .optionButton:before {
   transform: scale(1.25);
}

.TextEditV2Form .SizeFrom{
    height: 25px;
    width : 50px;
}
.TextEditV2Form .SizeFrom input{
    height: 25px;
    width : 50px;
    font-family: "프리텐다드SemiBold", sans-serif;
    text-align: center;
}

.TextEditV2Form .SingleButton{
    height: 25px;
    width : 25px;

}

.TextEditV2Form .TextEditNavButton input{
    background-color: transparent;
    border: none; 
    height: 25px;
    font-family: "프리텐다드SemiBold", sans-serif;

}

.TextEditV2Form .ColorFrom{
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.TextEditV2Form .ColorForm .SelectColorCode{
  height: 20px;
  width: 70px;
  padding : 0px;
  margin : 0px; 
  font-family: "프리텐다드SemiBold", sans-serif;
  font-size : 14px;
  line-height : 20px;
  text-align : center;    
  outline: none;
}




.TextEditV2Form .ColorForm input[type="color"] {
  -webkit-appearance: none;
  border: none;
  width: 20px;
  height: 20px;
  margin : 2.5px;
  border-radius: 2px;
  cursor: pointer;
  padding: 0;
}

/* Remove default internal padding and appearance for webkit browsers */
.TextEditV2Form .ColorForm input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
  border-radius: 5px;
}
.TextEditV2Form .ColorForm input[type="color"]::-webkit-color-swatch {
  border: none;
  border-radius: 5px;
}



`;



const FONTLIST=['나눔스퀘어','나눔고딕','나눔바른고딕','나눔바른펜','한나체','한나체Air','에스코어드림Thin','에스코어드림Light','에스코어드림Medium','에스코어드림Bold','에스코어드림Heavy','프리텐다드Light','프리텐다드Regular','프리텐다드Medium','프리텐다드SemiBold','프리텐다드Black','어그로체Light','어그로체Medium','어그로체Bold','망고빙수','주아체','기랑해랑체','도현체','연성체','을지로체','을지로10년후체','메이플스토리체','삼립호빵체','이사만루체','마포꽃섬','조선궁서체','DOS명조','타자기체','당당해체','교보손글씨','잘난체']