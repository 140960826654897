////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/edit.scss"

// 이미지 연결
import textIcon from 'assets/widget/text.png';
import imageIcon from 'assets/widget/image.png';
import videoIcon from 'assets/widget/video.png';
import timerIcon from 'assets/widget/timer.png';
import particlesIcon from 'assets/widget/Particles.png';
import backgroundIcon from 'assets/widget/Background.png';
import CameraIcon from 'assets/widget/camera.png';
import lineIcon from 'assets/widget/line.png';
import triangleIcon from 'assets/widget/triangle.png';
import rightTriangleIcon from 'assets/widget/rightTriangle.png';
import quadrangleIcon from 'assets/widget/quadrangle.png';
import hexagonIcon from 'assets/widget/hexagon.png';
import circleIcon from 'assets/widget/circle.png';
import noticeIcon from 'assets/widget/notice.png';
import ADalert from 'assets/widget/adalert.png';
import BroadcastAlertIcon from 'assets/widget/BroadcastAlertIcon.png';
import scrollCaptionIcon from 'assets/widget/scrollCaption.png';
import newsCaption from 'assets/widget/NewsCaption.png';
import liveIconIcon from 'assets/widget/liveIconIcon.png';
import ddayIcon from 'assets/widget/dday.png';


import CryptoCurrencyIcon from 'assets/widget/cryptoCurrency.png';

import heroImage from 'assets/widget/heroImage.png';
import GameMaps from 'assets/widget/GameMaps.png';
import OverwatchProfile from 'assets/widget/OverwatchProfile.png';
import OverwatchRank from 'assets/widget/OverwatchRank.png';
import teamPoint from 'assets/widget/teamPoint.png';
import DraftPickIcon from 'assets/widget/DraftPick.png';

import test1 from 'assets/widget/test1.png';
import test2 from 'assets/widget/test2.png';
import test3 from 'assets/widget/test3.png';

// 컴포넌트 연결
import WidgetBox from 'components/Edit/WidgetSelectBox'; // 메뉴 헤더

// 서비스 연결
// import * as DeCoder from '../..s/service/transform/DeCoder' ; 
import * as EeCoder from 'service/engine/EnCoder';
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class ApeendWidget extends Component {
    
    state = {
        WidgetList : "기본",
    }
    
    templateContent = (e) => { 
        this.props.viewMode(false)
        e.stopPropagation();
        this.screenDataAdd()
    }
    screenDataAdd = () =>{
        this.props.screenDataDispatch(this.props.screenData.concat(EeCoder.add('Text')))        
    }

    WidgetList = () =>{
        let list =''
         switch (this.props.list) {
            case 'test':
            return (
                <Fragment>
                    <WidgetBox viewMode={this.props.viewMode} name="T1 테스트" type="ExWidget" info ={null} img={DraftPickIcon}/>
                    <WidgetBox viewMode={this.props.viewMode} name="패턴디자인배경" type="BackgroundPatternDesign" info ={null} img={backgroundIcon}/>

                    <WidgetBox viewMode={this.props.viewMode} name="현제시간" type="Clock" info ={null} img={timerIcon}/>
                    <WidgetBox viewMode={this.props.viewMode} name="카운트다운" type="Countdown" info ={null} img={timerIcon}/>
                    <WidgetBox viewMode={this.props.viewMode} name="타이머" type="Timer" info ={null} img={timerIcon}/>
                
                    {/* <WidgetBox viewMode={this.props.viewMode} name="T2 테스트" type="NewsCaption" info ={null} img={backgroundIcon}/>
                    <WidgetBox viewMode={this.props.viewMode} name="T3 테스트" type="particles" info ={null} img={particlesIcon}/>  */}
                </Fragment>
            )
            case 'basicWidget':
                return (
                    <Fragment>
                        <WidgetBox viewMode={this.props.viewMode} name="텍스트" type="Text" info ={null} img={textIcon}/>
                        <WidgetBox viewMode={this.props.viewMode} name="이미지" type="Image" info ={null} img={imageIcon}/>
                        <WidgetBox viewMode={this.props.viewMode} name="동영상" type="Video" info ={null} img={videoIcon}/>

        
                        <WidgetBox viewMode={this.props.viewMode} name="공지" type="Notice" info ={null} img={noticeIcon}/>
                        <WidgetBox viewMode={this.props.viewMode} name="타이머" type="Timer" info ={null} img={timerIcon}/>
                        <WidgetBox viewMode={this.props.viewMode} name="현제시간" type="Clock" info ={null} img={timerIcon}/>
                        <WidgetBox viewMode={this.props.viewMode} name="웹캠" type="Camera" info ={null} img={CameraIcon}/>
        
                        <WidgetBox viewMode={this.props.viewMode} name="선" type="Line" info ={null} img={lineIcon}/>
                        <WidgetBox viewMode={this.props.viewMode} name="사각형" type="Shape" preset={'quadrangle'} info ={null} img={quadrangleIcon}/>
                        <WidgetBox viewMode={this.props.viewMode} name="삼각형" type="Shape" preset={'triangle'} info ={null} img={triangleIcon}/>
                        <WidgetBox viewMode={this.props.viewMode} name="직각삼각형" type="Shape" preset={'rightTriangle'} info ={null} img={rightTriangleIcon}/>
                        <WidgetBox viewMode={this.props.viewMode} name="육각형" type="Shape" preset={'hexagon'} info ={null} img={hexagonIcon}/>
                        <WidgetBox viewMode={this.props.viewMode} name="원" type="Shape" preset={'circle'} info ={null} img={circleIcon}/>

                        <WidgetBox viewMode={this.props.viewMode} name="패턴디자인" type="BackgroundPatternDesign" info ={null} img={backgroundIcon}/>
                    </Fragment>
                )
            case 'broadcastWidget':
                return (
                    <Fragment>
                        <WidgetBox viewMode={this.props.viewMode} name="공지" type="Notice" info ={null} img={noticeIcon}/>
                        <WidgetBox viewMode={this.props.viewMode} name="타이머" type="Timer" info ={null} img={timerIcon}/>
                        <WidgetBox viewMode={this.props.viewMode} name="웹캠" type="Camera" info ={null} img={CameraIcon}/>
                        <WidgetBox viewMode={this.props.viewMode} name="가상화폐" type="CryptoCurrency" info ={null} img={CryptoCurrencyIcon}/>
                        <WidgetBox viewMode={this.props.viewMode} name="광고알람" type="ADalert" info ={null} img={ADalert}/>
                        <WidgetBox viewMode={this.props.viewMode} name="방송알람" type="BroadcastAlert" info ={null} img={BroadcastAlertIcon}/>
                        <WidgetBox viewMode={this.props.viewMode} name="스크롤자막" type="ScrollCaption" info ={null} img={scrollCaptionIcon}/>
                        <WidgetBox viewMode={this.props.viewMode} name="뉴스자막" type="NewsCaption" info ={null} img={newsCaption}/>
                        <WidgetBox viewMode={this.props.viewMode} name="라이브아이콘" type="LiveIcon" info ={null} img={liveIconIcon}/>
                        <WidgetBox viewMode={this.props.viewMode} name="디데이" type="Dday" info ={null} img={ddayIcon}/>
                        <WidgetBox viewMode={this.props.viewMode} name="파티클" type="Particles" info ={null} img={particlesIcon}/> 
                    </Fragment>
                )
            case 'gameWidget':
                return (
                    <Fragment>
                        <WidgetBox viewMode={this.props.viewMode} name="스코어보드" x='0' y='0' type="Scoreboard" info ={null} img={teamPoint}/>
                        <WidgetBox viewMode={this.props.viewMode} name="드레프트 픽" type="DraftPick" info ={null} img={DraftPickIcon}/>
                        {/* <WidgetBox viewMode={this.props.viewMode} name="영웅초상화" type="OverwatchHeroes" info ={null}  img={heroImage}/> */}
                        <WidgetBox viewMode={this.props.viewMode} name="오버워치 프로필" type="OverwatchProfile" info ={null}  img={OverwatchProfile}/>
                        <WidgetBox viewMode={this.props.viewMode} name="게임캐릭터" type="GameCharacters" info ={null}  img={heroImage}/>
                        <WidgetBox viewMode={this.props.viewMode} name="게임맵" type="GameMaps" info ={null}  img={GameMaps}/>
                        <WidgetBox viewMode={this.props.viewMode} name="오버워치 경쟁전" type="OverwatchRank" info ={null}  img={OverwatchRank}/>
                    </Fragment>
                )
            case 'allWidget':
                return (
                    <Fragment>
                        <WidgetBox viewMode={this.props.viewMode} name="텍스트" type="Text" info ={null} img={textIcon}/>
                        <WidgetBox viewMode={this.props.viewMode} name="이미지" type="Image" info ={null} img={imageIcon}/>
                        <WidgetBox viewMode={this.props.viewMode} name="동영상" type="Video" info ={null} img={videoIcon}/>

                        <WidgetBox viewMode={this.props.viewMode} name="캡쳐" type="CaptureStream" info ={null} img={test1}/>
        
                        <WidgetBox viewMode={this.props.viewMode} name="공지" type="Notice" info ={null} img={noticeIcon}/>
                        <WidgetBox viewMode={this.props.viewMode} name="타이머" type="Timer" info ={null} img={timerIcon}/>
                        <WidgetBox viewMode={this.props.viewMode} name="현제시간" type="Clock" info ={null} img={timerIcon}/>
                        <WidgetBox viewMode={this.props.viewMode} name="웹캠" type="Camera" info ={null} img={CameraIcon}/>

                        {/* <WidgetBox viewMode={this.props.viewMode} name="배경" type="Background" info ={null} img={backgroundIcon}/>
                        <WidgetBox viewMode={this.props.viewMode} name="파티클" type="Particles" info ={null} img={particlesIcon}/>  */}
        
                        <WidgetBox viewMode={this.props.viewMode} name="선" type="Line" info ={null} img={lineIcon}/>
                        <WidgetBox viewMode={this.props.viewMode} name="사각형" type="Shape" preset={'quadrangle'} info ={null} img={quadrangleIcon}/>
                        <WidgetBox viewMode={this.props.viewMode} name="삼각형" type="Shape" preset={'triangle'} info ={null} img={triangleIcon}/>
                        <WidgetBox viewMode={this.props.viewMode} name="직각삼각형" type="Shape" preset={'rightTriangle'} info ={null} img={rightTriangleIcon}/>
                        <WidgetBox viewMode={this.props.viewMode} name="육각형" type="Shape" preset={'hexagon'} info ={null} img={hexagonIcon}/>
                        <WidgetBox viewMode={this.props.viewMode} name="원" type="Shape" preset={'circle'} info ={null} img={circleIcon}/>

                        <WidgetBox viewMode={this.props.viewMode} name="패턴디자인" type="BackgroundPatternDesign" info ={null} img={backgroundIcon}/>
        
                        <WidgetBox viewMode={this.props.viewMode} name="가상화폐" type="CryptoCurrency" info ={null} img={CryptoCurrencyIcon}/>
                        <WidgetBox viewMode={this.props.viewMode} name="드레프트 픽" type="DraftPick" info ={null} img={DraftPickIcon}/>
                        <WidgetBox viewMode={this.props.viewMode} name="스코어보드" x='0' y='0' type="Scoreboard" info ={null} img={teamPoint}/>
                        {/* <WidgetBox viewMode={this.props.viewMode} name="영웅초상화" type="OverwatchHeroes" info ={null}  img={heroImage}/> */}
                        <WidgetBox viewMode={this.props.viewMode} name="게임캐릭터" type="GameCharacters" info ={null}  img={heroImage}/>
                        <WidgetBox viewMode={this.props.viewMode} name="게임맵" type="GameMaps" info ={null}  img={GameMaps}/>
                        <WidgetBox viewMode={this.props.viewMode} name="광고알람" type="ADalert" info ={null} img={ADalert}/>
                        
                        <WidgetBox viewMode={this.props.viewMode} name="방송알람" type="BroadcastAlert" info ={null} img={BroadcastAlertIcon}/>
                        <WidgetBox viewMode={this.props.viewMode} name="스크롤자막" type="ScrollCaption" info ={null} img={scrollCaptionIcon}/>
                        <WidgetBox viewMode={this.props.viewMode} name="뉴스자막" type="NewsCaption" info ={null} img={newsCaption}/>

                        <WidgetBox viewMode={this.props.viewMode} name="라이브아이콘" type="LiveIcon" info ={null}   img={liveIconIcon}/>
                        <WidgetBox viewMode={this.props.viewMode} name="디데이" type="Dday" info ={null} img={ddayIcon}/>

                        <WidgetBox viewMode={this.props.viewMode} name="파티클" type="Particles" info ={null} img={particlesIcon}/> 
                        
                        <WidgetBox viewMode={this.props.viewMode} name="오버워치 프로필" type="OverwatchProfile" info ={null}  img={OverwatchProfile}/>
                        <WidgetBox viewMode={this.props.viewMode} name="오버워치 경쟁전" type="OverwatchRank" info ={null}  img={OverwatchRank}/>
                    </Fragment>
                )
             default:
                 break;
         }
    }
    render() { 
        return (
            <div id = "WidgetSelectField" onClick={()=>this.props.viewMode()}>

           
            <div id = "WidgetList" >
                {this.WidgetList()}
            </div>
            </div>
        );
    }
}
////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    screenData : state.screenData,
})

function mapDispatchToProps(dispatch){
    return {
        
      screenDataDispatch : (screenData) => dispatch({type:'screenData',data : screenData}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(ApeendWidget);
  