
////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결

// 라이브러리 
import io from 'socket.io-client';
// 설정 파일
import * as config from 'config/OverlapConfig'
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결

// 이미지 연결

// 서비스 연결
import * as Rest from 'service/api/rest' ;
import { token } from 'service/event/Account' ; 

////////////////////////////////////////////////////////
// store 연결
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
/** templateCreate
 * @param spaceId 오버랩을 생성할 스페이스의 ID를 적습니다.
 * @param data 오버랩 생성에 필요한 정보를 적습니다. {title,resolution,private,private,summary}
 * @returns 오버랩 IO 객체
 */
////////////////////////////////////////////////////////

export async function create(space_id,data) {
    if(data.title == ''){
        data['title'] = '새로운 프로젝트';
    }
    console.log(' 새 템플릿 data :',data);
    const createData = Object.assign(data, {space_id});

    let response =  await Rest.request('/template/create','Post',createData,token('account'));
    if(response?.status == 200){
        console.log('response',response)
        return true;
    }
    return false;
}


////////////////////////////////////////////////////////
/** templateGetList
 * @param spaceId 오버랩을 생성할 스페이스의 ID를 적습니다.
 * @param data 오버랩 생성에 필요한 정보를 적습니다. {title,resolution,private,private,summary}
 * @returns 오버랩 IO 객체
 */
////////////////////////////////////////////////////////

export async function getList(tag) {

    let response =  await Rest.request('/template/get/list','get',{tag:tag},token('account'));
    console.log('체크',response?.data?.status)
    if(response?.status == 200){
        return response.data.data;
    }
    return false;
}


////////////////////////////////////////////////////////
/** templateGet
 * @param spaceId 오버랩을 생성할 스페이스의 ID를 적습니다.
 * @param data 오버랩 생성에 필요한 정보를 적습니다. {title,resolution,private,private,summary}
 * @returns 오버랩 IO 객체
 */
////////////////////////////////////////////////////////

export async function get(tag) {

    let response =  await Rest.request('/template/get','get',{tag:tag},token('account'));
    console.log('체크',response?.data?.status)
    if(response?.status == 200){
        return response.data.data;
    }
    return false;
}

////////////////////////////////////////////////////////
/** templateGetPageForUUID
 * @param uuid 가지고 올 템플릿의 UUID 를 가져옵니다.
 * @returns 오버랩 IO 객체
 */
////////////////////////////////////////////////////////

export async function getPageForUUID(uuid) {

    let response =  await Rest.request('/template/page/uuid','get',{"uuid":uuid},token('account'));
    console.log('/template/page/uuid response',response)
    if(response?.status == 200){
        console.log('response',response)

        return response?.data?.data;
    }
    return [];
}

////////////////////////////////////////////////////////
/** templateGetPageForUUID
 * @param uuid 가지고 올 템플릿의 UUID 를 가져옵니다.
 * @returns 오버랩 IO 객체
 */
////////////////////////////////////////////////////////

export async function getTemplateForUUID(uuid) {

    let response =  await Rest.request('/template/get/uuid','get',{"uuid":uuid},token('account'));
    console.log('/template/page/uuid response',response)
    if(response?.status == 200){
        console.log('response',response)

        return response?.data?.data;
    }
    return [];
}


export async function getTemplateConcatForUUID(uuid) {

    let response =  await Rest.request('/template/get/concat/uuid','get',{"uuid":uuid},token('account'));
    console.log('/template/page/uuid response',response)
    if(response?.status == 200){
        console.log('response',response)

        return response?.data?.data;
    }
    return [];
}
