////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { useState, useEffect, useContext, Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widgetEdit.scss"


// 컴포넌트 연결
import GuideBalloon from 'components/common/form/GuideBalloon'// 가이드 벌룬
import ImageButtonModule from 'components/Edit/editModule/ImageButtonModule'; // 이미지 버튼을 눌러 여러 옵션중 값을 정합니다.
// import Show from '../show/Show'; // 메뉴 헤더

// 이미지 연결
import logoW from 'assets/logoW.svg';

// 서비스 연결
import * as Editor from 'service/edit/EditService'; 
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
function VarTeamPlayer({player,index,modify,del,update}) {
    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////

    // set
    const [Expand, setExpand] = useState(false);
    // state

    // rel 

   ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////
    useEffect(() => {
        // 여기에 컴포넌트가 마운트되었을 때 실행할 코드를 작성합니다.
        return () => {
        // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
        };
    }, []);
    
    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////

    return (
        <Fragment>
           <div className='PlayerData'>

                <div className='PlayerHeader'>
                    <div className='PlayerLeft'>
                        <div  className='PlayerImage'>
                            <img src={player?.image?player?.image:logoW}/>
                        </div>

                    <div  className='PlayerRight'>
                        <div className='PlayerField'>
                            <input className='PlayerNameInput' placeholder='플레이어 이름' value={player?.name??''} onChange={(e)=>modify(index,'name',e.target.value)} onBlur={()=>update()} />
                            <button className='PlayerDel' onClick={()=>del(index)}>
                            {Editor.icon({icon:'remove',size:30,lineHeight:50})}

                            </button>
                        </div>
                        <div className='PlayerField'>
                            <textarea className='PlayerImageInput' placeholder='프로필 URL' value={player?.image??''} onChange={(e)=>modify(index,'image',e.target.value)} onBlur={()=>update()}/>
                        </div>


                    </div>

                    </div>
          
                </div>
                {/* <div className='PlayerContent'>

                </div> */}
            </div>
        </Fragment>
    )
}
export default VarTeamPlayer;

