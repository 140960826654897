////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, {useState, useEffect, useContext, useRef } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { Route, Link } from 'react-router-dom';
import { useHistory } from "react-router";
// 라이브러리 

// 설정 파일
import * as config from 'config/OverlapConfig'

// [컴포넌트] 위젯 

// [프로바이더]
import EditContext from 'service/context/EditContext' ; 
// import store from "store";

// SASS&CSS 연결
// import 'sass/login.scss'

// 이미지 연결
// import logoW from 'assets/logoW.svg';


// 서비스 연결
import * as Editor from 'service/edit/EditService'; 
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

/** [컨텍스트 메뉴 리스트] 
 * 컨텍스트의 메뉴 하나를 표시하는 컴포넌트 입니다.
 * @param {string} icon 메뉴을 알아보기 쉽게 하는 아이콘 (머터리얼아이콘)
 * @param {string} text 어떤 메뉴인지 설명하는 텍스트
 * @param {function} action 메뉴가 눌렸을때 일어나는 이벤트
 * @param {function} hover 마우스가 대상 메뉴 위에 있을때
 * @param {boolena} activate 해당 메뉴가 활성화 되었는지 여부 (비 활성화시 이벤트 미작동 및 스타일 변화)
 * @returns 
 */


const MenuList =  React.memo(function MenuList({icon,text,hover,action,activate=true}) {
    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////

    //set



    ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////

      // useEffect(() => {

      //   return () => {

      //   };
      // },[]);

    ////////////////////////////////////////////////////////
    // Fuction
    ////////////////////////////////////////////////////////

    const hoverAction = () => {
      if(hover){
        hover();
      }

    }
    
    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////
      let MenuIcon = null 
      if(typeof icon === 'string'){
        MenuIcon = Editor.icon({icon:icon,size:15,lineHeight:20,color:(activate? "#ffffff":"#adadad" )});
      }
      if(React.isValidElement(icon)){
        MenuIcon = icon;
      }

      
      return(
        <li className='ContextMenuList' onClick={()=>action()} onMouseOver={()=>hoverAction()} >
          <div className='MenuIcon'>
            {MenuIcon}
          </div>
          <div className='MenuText' style={{color:(activate? "#ffffff":"#adadad" )}}>
            {text}
          </div>
        </li>
      )



  });

  export default MenuList;
////////////////////////////////////////////////////////
// css 부분
////////////////////////////////////////////////////////

