////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widgetEdit.scss"

// 컴포넌트 연결
// import Show from '../show/Show'; // 메뉴 헤더
// 서비스 연결

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

class ImageButtonModule extends Component {

    state = {
        select : null,
    }

    buttonClick = (e,val)=>{
        console.log('값 전달 과 체크',this.props.type,val);
        this.props.change(this.props.type,val)
        // this.props.change(this.props.type,e.target.val)
        // e.stoppropagation()
        
    }


    buttonType = (data) => {

        if(data.image!=undefined){
            return(
                <div title={data.title?data.title:null} key={this.props.type+'_'+data.val} className={'optionButton '+(data.val==this.props.value?'buttonSelect ':'')} onClick={(e) => this.buttonClick(e,data.val)} >
                <img className="ButtonImage" src={data.image}/>
            </div>
            )

        }
        if(data.icon!=undefined){
            return(
                <div title={data.title?data.title:null} style={{width:this.props.option?.buttonSize??'26px',height:this.props.option?.buttonSize??'26px'}} key={this.props.type+'_'+data.val} className={'optionButton '+(data.val==this.props.value?'buttonSelect ':'')} onClick={(e) => this.buttonClick(e,data.val)} >
                    <span style={{fontSize:this.props.option?.fontSize??'20px',lineHeight:this.props.option?.lineHeight??'26px'}} className="material-symbols-outlined iconfont">{data.icon}</span>
                </div>
            ) 
        }
        if(data.text!=undefined){
            return(
                <div title={data.title?data.title:null} style={{width:this.props.option?.buttonSize??'26px',height:this.props.option?.buttonSize??'26px'}} key={this.props.type+'_'+data.val} className={'optionButton '+(data.val==this.props.value?'buttonSelect ':'')} onClick={(e) => this.buttonClick(e,data.val)} >
                    <span style={{fontSize:this.props.option?.fontSize??'20px',lineHeight:this.props.option?.lineHeight??'26px',fontFamily:data?.fontFamily??'프리텐다드Black'}} >{data.text}</span>
                </div>
            )     
        }

        
    }

    buttonList = () =>{
        if(this.props.data){
            let list = this.props.data.map(
                (data,index) => (this.buttonType(data))
                );    
            return list
         }
    }

    render() { 
        return (
            <div className={"ButtonModule ImageButtonModule " + this.props.appendClass} > 
                {this.props.title?
                <p>{this.props.title}</p>
                :""}
                <span className="ButtonList">
                    {this.buttonList()}
        
                </span>

            </div>
        );
    }
}

////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    // screenData : state.screenData,
})

function mapDispatchToProps(dispatch){
    return {
    //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(ImageButtonModule);
  