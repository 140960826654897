// -- 위젯의 정의파일 Model 입니다. 해당 파일을 widgetModel 파일에 import 시켜야 사용 가능 합니다. -- 

////////////////////////////////////////////////////////
// Notice | 공지 
// 시청자에게 전달할 공지를 작성하는 위젯
////////////////////////////////////////////////////////

// -- 컴포넌트 불러오기 -- ('components/widget/[위젯이름]') 위치로 가저올 수 있습니다.
import Notice from 'components/widget/Notice'; // 컴포넌트
import { data } from 'components/widget/preset/NoticePreset';

// -- 스타일 불러오기 -- ('service/model/widget/[스타일이름]') 위치로 가저올 수 있습니다.
import * as common from 'service/model/widget/commonStyle'; // 자주 사용되는 스타일 메이커 가저오기
import * as common_v2 from 'service/model/widget/commonStyle_V2'; // 자주 사용되는 스타일 메이커 가저오기
import * as keyframe from 'service/model/widget/keyframe'; // 자주 사용되는 스타일 메이커 가저오기

// -- 기타 함수 불러오기 -- ('service/model/widget/[스타일이름]') 위치로 가저올 수 있습니다.
// import * as [이름] from 'service/[경로]'; 


////////////////////////////////////////////////////////
// 컴포넌트 정의
// 해당 위젯 모댈과 연결할 리엑트 컴포넌트를 정의 합니다.
////////////////////////////////////////////////////////
// export const Component = [가저올 컴포넌트] ; 
export const Component = Notice; 

////////////////////////////////////////////////////////
// 레퍼런스 정의
// 해당 위젯의 기본 설정값을 넣습니다.
////////////////////////////////////////////////////////
export const Reference = {
  position: {x: 20, y: 20, show : true},
  size: {width: 500, height: 300},
  style : {
    fontSize : 50,
    fontFamily : '나눔바른펜',
    shadowValue: "0", 
    shadowDirection : "soft",
    shadowColor: "#000000",
    fill : "#f8f861",
    direction:"horizontal",
    useFill : false,
    color : "#000000",
    textAlign: 'left',
    verticalAlign : 'flex-start',
    fontWeight : 'normal',
    outlineColor : '#000000',
    textOutline : 0,
    ...common.filterDefault,
    ...common.transformDefault,
  },
  value: {
    text:'',
    option: null
  },
  display:{
    show : true
  },
  animation:{
    mountStart:{keyFrame:null,delay:null,timing:null},
    mount:{keyFrame:null,delay:null,timing:null},
    mountEnd:{keyFrame:null,delay:null,timing:null},
    morphing:true
  },
  preset : 'Modern'
}


////////////////////////////////////////////////////////
// 스타일 정의
// 해당 위젯의 CSS 스타일을 정의합니다.
////////////////////////////////////////////////////////
export function style(data) {
  let layout = {}
  let object = {}
  let property = Object.keys(data)
  if(property.includes('position')){
    layout = Object.assign(layout,common.position({widget:data.position}))
  }
  if(property.includes('size')){
    object = Object.assign(object,minSize(data.size,200,'auto'))
  }
  if(property.includes('style')){
    // 외곽선 
    object = Object.assign(object,common.border({widget:data.style}))
    // 폰트
    object = Object.assign(object,common.font({widget:data.style}))
    // 필터 + 그림자 + 투명도
    object = Object.assign(object,common.filter({widget:data.style}))
    // 모핑
    layout = Object.assign(layout,common.morphing({widget:data.animation}))
    object = Object.assign(object,common.morphing({widget:data.animation}))
    //배경색
    /* 이 위젯은 프리셋에 따라 css 수정을 통해 직접 배경색을 채웁니다 */
    // 변형
    object = Object.assign(object,common.transform({widget:data.style}))
  }
  return {layout,object}
}
  
////////////////////////////////////////////////////////
// 프리셋 정의
// 해당 위젯의 CSS  프리셋을 정의합니다.
////////////////////////////////////////////////////////
// 프리셋을 사용하지 않는 위젯의 경우
// export const Preset = null
// 프리셋을 사용하는 위젯의 경우
// export const Preset = [
//   {name:'Postit', nameKo:'노란색 포스트잇 가로형', ratio:1.666},
//   {name:'yellowPostit_vertical', nameKo:'노란색 포스트잇 세로형', ratio:1.2},
//   {name:'pinkPostit_Horizontal', nameKo:'분홍색 포스트잇 가로형', ratio:1.666},
//   {name:'pinkPostit_vertical', nameKo:'분홍색 포스트잇 세로형', ratio:1.2},
//   {name:'twitchNotice', nameKo:'트위치 공지', ratio:7},
//   {name:'normal', nameKo:'기본형 모든포지션', ratio:null},
// ]



export const Preset = [

  {name:'Postit', nameKo:'메모지',summary:'붙이는 메모지 스타일의 메모 입니다.',
    data:{style:{shadowDirection : "soft",shadowColor: "#000000",shadowValue: 0,}}
  },
  {name:'Modern', nameKo:'모던',summary:'깔끔한 스타일의 메모입니다.',
  data:{style:{shadowDirection : "soft",shadowColor: "#000000",shadowValue: 1,}},
  },
  {name:'Apps', nameKo:'메모장',summary:'메모장 프로그램 스타일의 메모입니다.',
  data:{style:{shadowDirection : "soft",shadowColor: "#000000",shadowValue: 1,}},
  },
  {name:'Receipt', nameKo:'영수증',summary:'영수증 스타일의 메모입니다.',
    data:{size:{width: 500, height: 300},style:{shadowDirection : "soft",shadowColor: "#000000",shadowValue: 1,}},
  },

  
  // {name:'yellowPostit_vertical', nameKo:'메모장',summary:'기본형태의 스코어보드 입니다.'},
  // {name:'Postit_vertical', nameKo:'포스트잇',summary:'기본형태의 스코어보드 입니다.',
  // info : <p>{Editor.icon({icon:'group',size:15,lineHeight:20})}2</p>,
  // data:{size:{width: 1920, height: 50},position:{x: 0, y: 10,},data:{maxTeam : 2, minTeam : 2}}},

  // {name:'default_gradation', nameKo:'그라데이션 스코어보드',summary:'기본형태의 스코어보드 입니다.',
  // info : <p>{Editor.icon({icon:'group',size:15,lineHeight:20})}2</p>,
  // data:{size:{width: 1920, height: 50},position:{x: 0, y: 10,},data:{maxTeam : 2, minTeam : 2}}},

  // {name:'box', nameKo:'박스 스코어보드',summary:'기본형태의 스코어보드 입니다.',
  // info : <p>{Editor.icon({icon:'group',size:15,lineHeight:20})}2</p>,
  // data:{size:{width: 300, height: 200},position:{x: 100, y: 100,},data:{maxTeam : 2, minTeam : 2}}},

]

////////////////////////////////////////////////////////
// 애니메이션 정의
// 해당 위젯의 기본 설정값을 넣습니다.
////////////////////////////////////////////////////////
/**
 * 사용 가능한 애니메이션 효과를 정의합니다.
 * @param {*} state // 애니메이션 효과 종류 mountStart&mountEnd
 * @param {*} preset // 위젯의 프리셋
 * @returns 
 */
export function availableAnimation (state,preset=null) {

  const List = keyframe.basic(state)

  let customList = {}

  switch (preset) {
    case 'presetName':
      if(state == 'mountStart'){
        customList = {
          'custom' :  {
            info : {name:'fade in',nameKo:'페이드인',icon:'blur_on'},
            timing : {max:5000,min:500,default:1000},
            target :{layout:{keyframe:'widget_animation_fadeIn'}},
          },
        }
      }

      else if(state == 'mountEnd'){
        customList = {
          'custom' :  {
            info : {name:'fade in',nameKo:'페이드인',icon:'blur_on'},
            timing : {max:5000,min:500,default:1000},
            target :{layout:{keyframe:'widget_animation_fadeIn'}},
          },
        }
      }
      break;
    default:
      break;
  }

  const MergedList = Object.assign( customList, List );
  return MergedList;
}
/**
 * animation 애니메이션 효과를 정의합니다.
 * @param {*} state // 애니메이션 효과 종류 mountStart&mountEnd
 * @param {*} data  // 위젯의 데이터
 * @param {*} preset // 해당 프리셋에서 유효한 애니메이션인지 검증합니다. 
 * @returns 
 */
export function animation (state,data,preset=null) {
  let output = {}
  switch (state) {
    case 'mountStart':

    const List = availableAnimation(state,preset)
    output = keyframe.css({widgetAnimation:data.animation[state],availableAnimation:List})
    break;

    default:
      break;
  }
  return output
}

////////////////////////////////////////////////////////
// 커스텀 펑션 정의
// 해당 위젯 및 위젯 모델에서 사용하는 평선을 정의합니다.
////////////////////////////////////////////////////////
/*
minSize 최대 사이즈를 정의합니다.
 */
function minSize (property,width='200px',height='200px') {
  let keys = Object.keys(property)
  let result = {
    width,
    height,
  }

  if(keys.includes('width')){
    if (property.width=='auto'){
      result['minWidth'] = 'auto'
    }else {
      result['minWidth'] = property.width +'px'
    }
  }

  if(keys.includes('height')){
    if (property.result=='auto'){
      result['minHeight'] = 'auto'
    }else {
      result['minHeight'] = property.height +'px'
    }
  }
  return result
}

