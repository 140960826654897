////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widget.scss"

// [프로바이더] 연결
import EditContext from 'service/context/EditContext' ; 

// 컴포넌트 연결
import WidgetOverRay from '../Edit/editController/WidgetOverRay'; // 메뉴 헤더

// 서비스 연결
import * as Event from '../../service/event/Actions' ; 
import * as Widget from 'service/edit/WidgetService' ; 
import * as Model from '../../service/value/Model' ; 
import * as EnCoder from '../../service/engine/EnCoder' 
import * as Editor from 'service/edit/EditService'; ; 
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class UnKnownV1 extends Component {
    
    static defaultProps = {
        widgetName: 'UnKnown',
      }


    //[임시] 컨텍스트 연결
    static contextType = EditContext;

    state = {
    }

    constructor(props) {
        super(props);
        this.widget = React.createRef();
    }
    
    render() {

        const Style = this.props.getStyle(this.props.data)
        const Animation = this.props.getAnimation(this.props.animation,this.props.data)
        const MergeStyle = Widget.styleMerge(Style,Animation);

        const Height =this.props.data?.size?.height;
        const Width = this.props.data?.size?.width;
        const MinSize = Width>Height?Width:Height;
        
        // 드레그를 통한 위젯 이동
        switch (this.props.parents) {

            case 'edit':
                return (
                    <div className = {this.props.parents + " widgetObject "+this.props.widgetName+Widget.activate(this.props.data)} style={MergeStyle.layout} >
                        <div className={"object unknown"} style={{...MergeStyle.object,'overflow':'hidden'}}>
                        {MinSize < 200 ?
                        <Fragment>
                        {Editor.icon({icon:'indeterminate_question_box',size:MinSize/2,lineHeight:MinSize/2})}
                        </Fragment>
                       :
                       <Fragment>
                       {Editor.icon({icon:'indeterminate_question_box',size:MinSize/5,lineHeight:MinSize/5})}
                       <p style={{'fontSize': MinSize/20,'margin': MinSize/30,}}>
                           호환되지 않는 위젯입니다.
                       </p>
                       </Fragment>
                       }
                        </div>
                  
                    </div>
                );
            case 'broadcast':
                return null;
          
            case 'view':
                return null;
        }

    }
}
////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    userState : state.userState,
})

function mapDispatchToProps(dispatch){
    return {
        //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}


  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(UnKnownV1);
  