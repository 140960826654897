////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widgetEdit.scss"


// 컴포넌트 연결
import TimeModule from 'components/Edit/editModule/TimeModule'; // 타이머 모듈
import RadioButtonModule from 'components/Edit/editModule/RadioButtonModule'; //범위 모듈 
import ImageButtonModule from 'components/Edit/editModule/ImageButtonModule';

// 서비스 연결
import * as Editor from 'service/edit/EditService'; 
// 이미지 연결

import leftButton from 'assets/sidebar/text/left.png';
import centerButton from 'assets/sidebar/text/center.png';
import rightButton from 'assets/sidebar/text/right.png';
import justifyButton from 'assets/sidebar/text/justify.png';


////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class ClockEdit extends Component {
    
    state = {
        editorToggle : true
    }

    change = (name,value) =>{
        this.props.callBack('style',name,value)
    }
    valueChange = (name,value) =>{
        this.props.callBack('value',name,value)
    }

    radioChange = (e) => {
        this.setState({
            raido : e.target.value
        })
    }

    render() { 

        const Style = {
            label: {
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
            },
            textarea : {
                width: '100%',
                resize: 'none'
            }
        }
        return (
            <section className='ClockEdit'>
            {Editor.WidgetEditTitle(this,{title:'시간제 설정',toggle:false,icon:'more_time'})}
            <div className={this.state.editorToggle?'editContainer containerOpen':'editContainer containerClose'}>
            <article className="EditPart MediaSourceModule" >
            <RadioButtonModule value={this.props.data.value.use24hour} list={[{name:'24H',value:true},{name:'12H',value:false}]} type ="use24hour" title="시간설정"  change={this.valueChange} />
                {!this.props.data.value.use24hour?
                <RadioButtonModule value={this.props.data.value.meridiemText} list={[{name:'사용',value:true},{name:'사용안함',value:false}]} type ="meridiemText" title="오전/오후"  change={this.valueChange} />
                :''}
                <RadioButtonModule value={this.props.data.value.outputText} list={[{name:'한글',value:'krText'},{name:'영어',value:'enText'},{name:':',value:'colon'}]} type ="outputText" title="시간구분"  change={this.valueChange} />
            
                <ImageButtonModule value={this.props.data.style.textAlign} type ="textAlign" data={[{val:'left',image:leftButton,text:'text'},{val:'center',image:centerButton},{val:'right',image:rightButton},{val:'justify',image:justifyButton}]} title="좌우 정렬"  change={this.change}/>
            </article>
            </div>
        </section>
        );
    }
}

////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    // screenData : state.screenData,
})

function mapDispatchToProps(dispatch){
    return {
    //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(ClockEdit);
  