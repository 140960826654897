import React, { Component , Fragment } from 'react';
// 서비스 연결
import * as Model from '../value/Model' ; 
import * as Editor from 'service/edit/EditService'; 
import * as Utility from 'service/other/Utility' ; 
// 위젯 목록
import "sass/widget.scss"


// 전용 에딧
import LegacyTextEditForm from 'components/Edit/editorField/LegacyTextEditForm';

import WidgetEditWrap from 'components/Edit/editController/WidgetEditWrap'
import * as widgetModel from 'service/model/widgetModel'
import * as EditorDecoder from 'service/engine/decoder/EditorDecoder'
import * as WidgetDecoder from 'service/engine/decoder/WidgetDecoder'



////////////////////////////////////////////////////////
// 오류처리&테스트를 위한 위젯
// 컴포넌트
import ExWidget from 'components/widget/ExWidget'
import Undefined from 'components/widget/Undefined'
////////////////////////////////////////////////////////

////////////////////////////////////////////////////////
// 텍스트 위젯
// 컴포넌트
import Text from 'components/widget/Text';
// 전용 에딧
import TextEdit from 'components/Edit/editForm/TextEdit';
// 전용 에딧
import TextStyleEdit from 'components/Edit/editForm/TextStyleEdit';
////////////////////////////////////////////////////////

////////////////////////////////////////////////////////
// 이미지 위젯
// 컴포넌트
import Image from 'components/widget/ImageV2'; // 컴포넌트
// 이미지 소스 에딧
import ImageEdit from 'components/Edit/editForm/ImageEdit'; // 
////////////////////////////////////////////////////////

////////////////////////////////////////////////////////
// 비디오 위젯
// 컴포넌트
import Video from 'components/widget/Video'; // 컴포넌트
// 이미지 소스 에딧
import VideoEdit from 'components/Edit/editForm/VideoEdit'; // 
////////////////////////////////////////////////////////

////////////////////////////////////////////////////////
// 이미지 위젯
// 컴포넌트
import CaptureStream from 'components/widget/CaptureStream'; // 컴포넌트
// 이미지 소스 에딧
import CaptureStreamEdit from 'components/Edit/editForm/CaptureStreamEdit'; // 
////////////////////////////////////////////////////////

////////////////////////////////////////////////////////
// 웹캠 위젯
// 컴포넌트
import Camera from 'components/widget/Camera'; // 컴포넌트
// 웹캠 에딧
import CameraEdit from 'components/Edit/editForm/CameraEdit'; // 
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// 도형 위젯
// 컴포넌트
import Shape from 'components/widget/Shape'; // 컴포넌트
import Line from 'components/widget/Line'; // 컴포넌트
import LineEdit from 'components/Edit/editForm/LineEdit'; // 컴포넌트
// 이미지 소스 에딧

////////////////////////////////////////////////////////

////////////////////////////////////////////////////////
// 공지 위젯
// 컴포넌트
import Notice from 'components/widget/Notice';
import ADalert from 'components/widget/ADalert';
// 
import ADalertEdit from 'components/Edit/editForm/ADalertEdit'; 
////////////////////////////////////////////////////////

////////////////////////////////////////////////////////
// 일정 위젯
// 컴포넌트
import BroadcastAlert from 'components/widget/BroadcastAlert';
import NextSchedule from 'components/widget/NextSchedule';
// 
import BroadcastAlertEdit from 'components/Edit/editForm/BroadcastAlertEdit'; 
////////////////////////////////////////////////////////

////////////////////////////////////////////////////////
// 디데이 위젯
// 컴포넌트
import Dday from 'components/widget/Dday';
//
import DdayEdit from 'components/Edit/editForm/DdayEdit'; 
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// 자막 위젯
// 컴포넌트
import ScrollCaption from 'components/widget/ScrollCaption';
import NewsCaption from 'components/widget/NewsCaption';
// 

import NewsCaptionEdit from 'components/Edit/editForm/NewsCaptionEdit';
import ScrollCaptionEdit from 'components/Edit/editForm/ScrollCaptionEdit'; 
////////////////////////////////////////////////////////

////////////////////////////////////////////////////////
// 아이콘 위젯
// 컴포넌트
import LiveIcon from 'components/widget/LiveIcon';
// 
////////////////////////////////////////////////////////

////////////////////////////////////////////////////////
// 타이머 위젯 [좌표,타이머,텍스트]
// 컴포넌트
import Timer from 'components/widget/Timer';
import Clock from 'components/widget/Clock';
import Countdown from 'components/widget/Countdown';

// 타이머 에딧
import TimerEdit from 'components/Edit/editForm/TimerEdit'; // 
// 타이머 에딧
import ClockEdit from 'components/Edit/editForm/ClockEdit'; // 
// 타이머 에딧
import CountdownEdit from 'components/Edit/editForm/CountdownEdit'; // 
////////////////////////////////////////////////////////

////////////////////////////////////////////////////////
// 배경 위젯 
// 컴포넌트
import BackgroundPatternDesign from 'components/widget/BackgroundPatternDesign';
// 전용 에디터
import BackgroundPatternDesignEdit from 'components/Edit/editForm/BackgroundPatternDesignEdit'; // 
////////////////////////////////////////////////////////

////////////////////////////////////////////////////////
// 파티클 위젯
// 컴포넌트
import Particles from 'components/widget/Particles';
// 타이머 에딧
import ParticlesEdit from 'components/Edit/editForm/ParticlesEdit'; // 
////////////////////////////////////////////////////////

////////////////////////////////////////////////////////
// 오버워치 위젯
// 컴포넌트
import Scoreboard from 'components/widget/Scoreboard'; // 경기바
import DraftPick from 'components/widget/DraftPick'; // 드레프트 픽 

import GameCharacters from 'components/widget/GameCharacters'; // 히어로 사진
import GameMaps from 'components/widget/GameMaps'; // 히어로 사진

import OverwatchHeroes from 'components/widget/OverwatchHeroes'; // 히어로 사진
import OverwatchRank from 'components/widget/OverwatchRank'; // 히어로 사진
// import OverwatchRank from '../../component/widget/OverwatchRank'; // 히어로 사진
import OverwatchProfile from 'components/widget/OverwatchProfile'; // 히어로 사진

// 이미지 소스 에딧
import ScoreboardEdit from 'components/Edit/editForm/ScoreboardEdit'; // 


import GameCharactersEdit from 'components/Edit/editForm/GameCharactersEdit'; // 
import GameMapsEdit from 'components/Edit/editForm/GameMapsEdit'; // 
import OverwatchHeroesEdit from 'components/Edit/editForm/OverwatchHeroesEdit'; // 
import OverwatchRankEdit from 'components/Edit/editForm/OverwatchRankEdit'; // 
import OverwatchProfileEdit from 'components/Edit/editForm/OverwatchProfileEdit'; // 
import DraftPickEdit from 'components/Edit/editForm/DraftPickEdit'; // 
////////////////////////////////////////////////////////

////////////////////////////////////////////////////////
// 가상화폐 위젯
// 컴포넌트
import CryptoCurrency from 'components/widget/CryptoCurrency';
// 타이머 에딧
import CryptoCurrencyEdit from  'components/Edit/editForm/CryptoCurrencyEdit'; //
////////////////////////////////////////////////////////

////////////////////////////////////////////////////////
// 공통 에디터
// 좌표 에딧
import PositionEdit from 'components/Edit/editForm/PositionEdit'; // x,y좌표
import SizeEdit from 'components/Edit/editForm/SizeEdit'; // w,h 크기
import FilterEdit from 'components/Edit/editForm/FilterEdit'; // 필터 효과를 조절하는 에디터
import ShadowEdit from 'components/Edit/editForm/ShadowEdit'; // 그림자를 조절하는 에디터
import TextShadowEdit from 'components/Edit/editForm/TextShadowEdit'; // 텍스트 그림자를 조절하는 에디터

import StrokeEdit from 'components/Edit/editForm/StrokeEdit'; // 선을 조절하는 에디터
import OpacityEdit from 'components/Edit/editForm/OpacityEdit'; // 투명도를 조절하는 에디터
import BrightnessEdit from 'components/Edit/editForm/BrightnessEdit'; // 밝기&명도 조절하는 에디터
import AliasEdit from 'components/Edit/editForm/AliasEdit'; // 별칭을 만드는 에디터
import RadiusEdit from 'components/Edit/editForm/RadiusEdit'; // 별칭을 만드는 에디터

import PresetEdit from 'components/Edit/editForm/PresetEdit'; // 별칭을 만드는 에디터
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// 변형 에디터
// 좌표 에딧
import MorphingEdit from 'components/Edit/editForm/MorphingEdit'; // x,y좌표
import TransformEdit from 'components/Edit/editForm/TransformEdit'; // x,y좌표
import ClippathEdit from 'components/Edit/editForm/ClippathEdit'; // x,y좌표
////////////////////////////////////////////////////////


import ColorEdit from 'components/Edit/editForm/ColorEdit';


export function connectTest() {
    console.log('Decoder : 연결테스트')
  }

export function getSidebarList(type) {
   

    return widget2(type,null).sidebarList
}

////////////////////////////////////////////////////////
// 위젯 인코더 버전 2 
// 함수형으로 교체됨
////////////////////////////////////////////////////////
function widget2(type,data) {

  ////////////////////////////////////////////////////////
  // 향후 나중을 위한 코드 (프리셋 확인)
  ////////////////////////////////////////////////////////
  // let preset =null
  // if (data){
  //   let dataList = Object.keys(data)
  //   if (dataList.includes('preset')){
  //     preset = data['preset']
  //   } 
  // }

  ////////////////////////////////////////////////////////
  // 위젯 디코더
  ////////////////////////////////////////////////////////
  let sidebar
  let component
  switch (type) {
    case 'Text':
      component = Text
      sidebar = {
        edit : [PositionEdit,SizeEdit,TextEdit,TextShadowEdit,OpacityEdit],
        transform : [MorphingEdit,AliasEdit,TransformEdit],
      }
      break;
    case 'Image':
      component = Image
      sidebar = {
        edit : [PositionEdit,SizeEdit,ImageEdit,RadiusEdit,ShadowEdit,OpacityEdit,BrightnessEdit,FilterEdit],
        transform : [MorphingEdit,AliasEdit,TransformEdit,ClippathEdit]
      } 
      break;
    case 'CaptureStream':
        component = CaptureStream
        sidebar = {
          edit : [PositionEdit,SizeEdit,CaptureStreamEdit,RadiusEdit,ShadowEdit,OpacityEdit,BrightnessEdit,FilterEdit],
          transform : [MorphingEdit,AliasEdit,TransformEdit,ClippathEdit]
        } 
      break;
    case 'Camera':
      component = Camera
      sidebar = {
        edit : [PositionEdit,SizeEdit,CameraEdit,ShadowEdit,RadiusEdit,OpacityEdit,BrightnessEdit,FilterEdit],
        transform : [MorphingEdit,AliasEdit,TransformEdit]
      }
      break;
    case 'Video':
      component = Video
      sidebar = {
        edit : [PositionEdit,SizeEdit,VideoEdit,ShadowEdit,OpacityEdit,BrightnessEdit,FilterEdit],
        transform : [MorphingEdit,AliasEdit,TransformEdit]
      } 
      break;     
    case 'Shape':
      component = Shape
      sidebar = {
        edit : [PositionEdit,SizeEdit,ColorEdit,StrokeEdit,RadiusEdit,ShadowEdit,OpacityEdit,BrightnessEdit,FilterEdit],
        transform : [MorphingEdit,AliasEdit,TransformEdit,ClippathEdit]
      } 
      break;
    case 'Line':
      component = Line
      sidebar = {
        edit : [PositionEdit,LineEdit,StrokeEdit,ColorEdit,FilterEdit],
        transform : [MorphingEdit,AliasEdit,TransformEdit]
      } 
      break;
    case 'Notice':
      component = Notice
      sidebar = {
        edit : [PositionEdit,SizeEdit,TextEdit,ShadowEdit,OpacityEdit],
        transform : [MorphingEdit,AliasEdit,TransformEdit],
        preset : [PresetEdit]
      } 
      break;     
    case 'Timer':
      component = Timer
      sidebar = {
        edit : [PositionEdit,TimerEdit,TextStyleEdit,ShadowEdit,OpacityEdit],
        transform : [MorphingEdit,AliasEdit,TransformEdit],
        preset : [PresetEdit]
      }
      break;
    case 'Clock':
      component = Clock
      sidebar = {
        edit : [PositionEdit,SizeEdit,ClockEdit,TextStyleEdit,ShadowEdit,OpacityEdit],
        transform : [MorphingEdit,AliasEdit,TransformEdit],
        preset : [PresetEdit]
      }
      break;
    case 'Countdown':
      component = Countdown
      sidebar = {
        edit : [PositionEdit,TimerEdit,TextStyleEdit,ShadowEdit,OpacityEdit],
        transform : [MorphingEdit,AliasEdit,TransformEdit],
        preset : [PresetEdit]
      }
      break;
    case 'BackgroundPatternDesign':
      component = BackgroundPatternDesign
      sidebar = {
        edit : [PositionEdit,SizeEdit,BackgroundPatternDesignEdit,ShadowEdit,OpacityEdit],
        transform : [MorphingEdit,AliasEdit,TransformEdit,ClippathEdit],
        preset : [PresetEdit]
      }
      break;
    case 'Particles':
      component = Particles
      sidebar = {
        edit : [PositionEdit,SizeEdit,ParticlesEdit],
        transform : [MorphingEdit,AliasEdit,FilterEdit,TransformEdit],
        preset : [PresetEdit]
      }
      break;
    case 'Scoreboard':
      component = Scoreboard
      sidebar = {
        edit : [PositionEdit,ScoreboardEdit],
        transform : [AliasEdit],
        preset : [PresetEdit]
      }
      break;
    case 'DraftPick':
      component = DraftPick
      sidebar = {
        edit : [PositionEdit,SizeEdit,DraftPickEdit,ShadowEdit,OpacityEdit,BrightnessEdit,FilterEdit],
        transform : [MorphingEdit,AliasEdit,TransformEdit],
        preset : [PresetEdit]
      }
      break;
    case 'OverwatchHeroes':
      component = OverwatchHeroes
      sidebar = {
        edit : [PositionEdit,SizeEdit,OverwatchHeroesEdit,RadiusEdit,ShadowEdit,OpacityEdit,BrightnessEdit,FilterEdit],
        transform : [MorphingEdit,AliasEdit,TransformEdit]
      }
      break;
    case 'GameCharacters':
      component = GameCharacters
      sidebar = {
        edit : [PositionEdit,SizeEdit,GameCharactersEdit,RadiusEdit,ShadowEdit,OpacityEdit,BrightnessEdit,FilterEdit],
        transform : [MorphingEdit,AliasEdit,TransformEdit]
      }
      break;
    case 'GameMaps':
      component = GameMaps
      sidebar = {
        edit : [PositionEdit,SizeEdit,GameMapsEdit,RadiusEdit,ShadowEdit,OpacityEdit,BrightnessEdit,FilterEdit],
        transform : [MorphingEdit,AliasEdit,TransformEdit]
      }
      break;  
    case 'OverwatchRank':
      component = OverwatchRank
      sidebar = {
        edit : [PositionEdit,SizeEdit,OverwatchRankEdit,ShadowEdit,OpacityEdit,BrightnessEdit,FilterEdit],
        transform : [MorphingEdit,AliasEdit,TransformEdit],
        preset : [PresetEdit],
      }
      break;     
    case 'OverwatchProfile':
      component = OverwatchProfile
      sidebar = {
        edit : [PositionEdit,SizeEdit,OverwatchProfileEdit,RadiusEdit,ShadowEdit,OpacityEdit,BrightnessEdit,FilterEdit],
        transform : [MorphingEdit,AliasEdit,TransformEdit],
        preset : [PresetEdit],
      }
      break;
    case 'ExWidget':
      component = ExWidget
      sidebar = {
        edit : [PositionEdit,SizeEdit,ColorEdit,ShadowEdit,OpacityEdit,BrightnessEdit,FilterEdit],
        transform : [MorphingEdit,AliasEdit,]
      }
      break;
    case 'CryptoCurrency':
      component = CryptoCurrency
      sidebar = {
        edit : [PositionEdit,SizeEdit,CryptoCurrencyEdit,ShadowEdit,OpacityEdit,BrightnessEdit,FilterEdit],
        transform : [MorphingEdit,AliasEdit,TransformEdit],
        preset : [PresetEdit],
      }
      break;
    case 'ADalert':
      component = ADalert
      sidebar = {
        edit : [PositionEdit,SizeEdit,ADalertEdit,ShadowEdit,OpacityEdit],
        transform : [MorphingEdit,AliasEdit,TransformEdit],
      } 
      break;
    case 'BroadcastAlert':
      component = BroadcastAlert
      sidebar = {
        edit : [PositionEdit,SizeEdit,BroadcastAlertEdit,ShadowEdit,OpacityEdit],
        transform : [MorphingEdit,AliasEdit,TransformEdit],
        preset : [PresetEdit],
      } 
      break;
    case 'NextSchedule':
      component = NextSchedule
      sidebar = {
        edit : [PositionEdit,SizeEdit,ShadowEdit,OpacityEdit],
        transform : [MorphingEdit,AliasEdit,TransformEdit],
        preset : [PresetEdit],
      } 
      break;
    case 'ScrollCaption':
      component = ScrollCaption
      sidebar = {
        edit : [PositionEdit,SizeEdit,ScrollCaptionEdit,ShadowEdit,OpacityEdit],
        transform : [MorphingEdit,AliasEdit,TransformEdit],
      } 
      break;
    case 'NewsCaption':
      component = NewsCaption
      sidebar = {
        edit : [PositionEdit,SizeEdit,NewsCaptionEdit,ShadowEdit,RadiusEdit,OpacityEdit],
        transform : [MorphingEdit,AliasEdit,TransformEdit],
      } 
      break;
    case 'LiveIcon':
      component = LiveIcon
      sidebar = {
        edit : [PositionEdit,SizeEdit,ShadowEdit,OpacityEdit],
        transform : [MorphingEdit,AliasEdit,TransformEdit],
        preset : [PresetEdit],
      } 
      break;
    case 'Dday':
      component = Dday
      sidebar = {
        edit : [PositionEdit,SizeEdit,DdayEdit,ShadowEdit,OpacityEdit],
        transform : [MorphingEdit,AliasEdit,TransformEdit],
        preset : [PresetEdit],
      } 
      break;      
    default:   
      component = Undefined
      sidebar = {
        edit :[],
      } 
      break;     
  }

  let res = {
    component,
    sidebar,
    sidebarList : Object.keys(sidebar)
  }
  return res
}

  /** run | EditScreen 컴포넌트에서 오버랩 위젯들의 JSON을 EditProvider를 통해 받아온 후 JSON 상태의 오버랩위젯을 컴포넌트로 변환합니다.
   * @returns 오버랩을 생성합니다. 생성 성공시 생성한 오버랩의 아이디를 반환 합니다.
   *  */ 
export function run(page,parents,global={},option) {
  // 테스트중 
  const data = getWidgetComponent(page,parents,global,option);
  return data;
}



////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// 위젯 생성
////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * 위젯 데이터를 받아서 위젯을 생성합니다.
 * @param {*} page 오버랩의 페이지 객체 
 * @param {*} mode 생성 타입 
 * @param {*} setChannel 
 */
export const getWidgetComponent = (page,mode,global,option) => {
  // const widgetList = pageData.page_widget;

  const widgetList =  Utility.deepCopy(page?.page_widget);

  const Animation = option?.animation == true ? true: false;

  const Component = []

  if(widgetList == null){
    console.error('[DeCoder] Widget : 해당 오버렙에 위젯이 없습니다. ')
    return [] // 가능하다면 에러 엘리먼트 생성
  }

  if(widgetList){

    const IdList = []; 

    // -- 오버랩 위젯 데이터를 순회하며 위젯 컴포넌트로 변경합니다.  --

    const Component = widgetList.map((widget,index)=>{
      
      const widgetType = widget?.type; 

      if(!widgetType){ // 만약 위젯의 종류가 null 인 경우 에러를 처리해줍니다.
        console.error('[DeCoder] Widget : 잘못된 타입의 위젯 [',widgetType,']')
 
      }

      // -- 위젯의 식별자가 겹치지 않도록 합니다. --
      // 위젯의 식별자는 유저가 선택한 위젯 이름(alias) 을 기반으로 생성되며 유저 이름이 없는경우 위젯의 UUID 를 기반으로 정합니다.

      let identifier = widget?.alias? widget?.type+'_'+widget?.alias : widget?.type+'_'+widget?.uuid;


      const PageID = page?.uuid ?? Utility.uuidMake();

      const identifier_key = widget?.alias? widget?.type+'_'+widget?.alias : widget?.type+'_'+widget?.uuid+'_'+PageID;

      if(IdList.includes(identifier)){
        identifier = 'aliastemp_'+identifier;
      }

      IdList.push(identifier);

      // -- 위젯의 데이터를 기반으로 위젯 컴포넌트를 생성합니다. --

      GlobalMergeWidget(widget,global);

      const MergeData = getMergeWidget(widget)

      
      const Reference = getWidgetModel(widget)

      let result = React.createElement(Reference.component,Object.assign({'keyValue':identifier,'pageKey':identifier_key,'alias':widget?.alias,'type':widget?.type,'key':identifier_key,'id':index,'uuid':widget?.uuid,'getStyle':Reference.style,'getAnimation':Reference.animation??null,'animation':option?.animation?'mountStart':null,'parents':mode,'update':null,'control':null},{data:MergeData}))        

      return result
     
    });
    
    const FilteredComponent = Component.filter(element => element !== null);

    return FilteredComponent

  }

}

/**
 * 
 * @param { } widget 
 * @param {*} global 
 * @return widget 에 수정됨
 */
export const GlobalMergeWidget = (widget,global) => {

  const GlobalVariable = global?.variable??{}
  // console.log('GlobalVariable',GlobalVariable)
  function findAllGlobalTeams(obj, results = []) {

    // 객체의 모든 키를 순회
    Object.keys(obj).forEach(key => {
        const value = obj[key];

        // 값이 문자열인 경우, 'global_team_'으로 시작하는지 확인
        if (typeof value === 'string' && value.startsWith('global_')) {
          const Variable = Utility.replaceGlobal(value,'all')
        
            // id가 'xxx'인 객체 찾기
            const item = GlobalVariable?.[Variable.key]?.find(item => item.id === Variable.value);
            if(item){
              obj[key] = item
            }else {
              obj[key] = undefined
            }         
        }
        // 값이 객체 또는 배열인 경우, 재귀적으로 함수 호출
        else if (typeof value === 'object' && value !== null) {
            findAllGlobalTeams(value, results);
        }
    });
    return results;
}
  findAllGlobalTeams(widget);
}


export const getMergeWidget = (widget) => {

  const WidgetModel = getWidgetModel(widget)
  if(WidgetModel==null){
    return null;
  }

  const Reference = WidgetModel?.reference
  const Style = WidgetModel?.style

  if(!Reference&&!Style){
    return null 
  }

  const WidgetData =  Utility.deepCopy(widget.data);
  return widgetModel.referenceMerge(Reference,WidgetData)
}

export const getWidgetModel = (widget) => {

  const Reference = widgetModel.getReference();
  

  if(widget == undefined || widget == null) {
    console.error('[DeCoder] Widget : 존재하지 않는 위젯입니다.')
    return Reference['Unknown'].version[Reference['Unknown'].default]
  }
  try {
    const widgetVersion = widget?.version??Reference[widget.type].default;


  if(Reference[widget.type] == undefined) {
    console.error('[DeCoder] Widget : 존재하지 않는 위젯입니다.')
    return Reference['Unknown'].version[Reference['Unknown'].default]
  }

  if( Reference[widget.type].version?.[widgetVersion] == undefined) {
    console.error('[DeCoder] Widget : 맞지 않는 버전의 위젯입니다.')
    return Reference['Unknown'].version[Reference['Unknown'].default]
  }

  return Reference[widget.type].version[widgetVersion]

  } catch (error) {

    return Reference['Unknown'].version[Reference['Unknown'].default]
  }


}


////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// 위젯에 맞는 에디터를 가저옵니다. 
////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * getEditor | 
 * @param {*} modify 수정함수
 * @param {*} target 편집 대상 위젯의 UUID
 * @param {*} global 전역 변수
 * @param {*} global 내장 함수
 * @param {*} tab 편집기에서 보여줄 종류 (edit,animation)등
 * @returns 
 */
export const getEditor = ({modify,target,global,func,tab}) => {
  if(!target){
    return (
    <div id='tapInfo'>
      {Editor.icon({icon:'edit_square',size:100,lineHeight:150,color:"#adadad"})}
      <span>
        설정 가능한 요소가 없습니다.
      </span>
    </div>
    )
  }
  //TODO 뷰 연결
  const widgetType = target.type
  const version = target.version
  let Reference =  widgetModel?.getReference()?.[widgetType]

    if(!Reference){
      Reference =  widgetModel?.getReference().Unknown
    }
  
    // 레퍼런스가 있는 위젯의 경우

      // 위젯의 버전에 따라 수정되는 위젯이 다릅니다.
      let vReference =  Reference?.version?.[version]
      if(!vReference){
        vReference = Reference?.version?.[Reference.default]
      }

      // 레퍼런스 데이터와 실제 객체의 데이터를 병합합니다.
      const targetData = Utility.mergeDeep(target.data,vReference.reference);
      // 병합된 데이터로 대상 위젯의 데이터를 갱신해줍니다.

      const ModifyTarget = Utility.deepCopy(target)

      ModifyTarget['data'] = targetData;

      
      const WidgetEditor = vReference.editor(target?.data?.preset??null,tab);

      const Component = getEditorComponent(ModifyTarget,modify,WidgetEditor,func,global)
      return Component
}


/**
 * 에디터 컴포넌트를 생성합니다.
 * @param {*} targetWidget 대상 위젯 객체
 * @param {*} modifyFunction 
 * @param {*} reference 위젯의 레퍼런스
 * @param {*} channel 
 * @returns 
 */
const getEditorComponent = (targetWidget,modifyFunction,reference,func,global) => {
  
  
  // 에디터 컴포넌트 목록
  const Component=[];

  // 정의된 에디터 컴포넌틀를 생성합니다.
  for (const editor of reference) {
    Component.push(React.createElement(editor.form,{key:`${editor.form?.name}_${targetWidget.uuid}`,keyTest:'`${editor.form?.name}_${targetWidget.uuid}`','target':targetWidget,'modify':modifyFunction,'keyName':editor.keyName,'title':editor.title,'option':editor.option,'func':func,'global':global} ) )
  }

  return Component ; 
}


/**
 * 
 * @param {*} targetWidget 
 * @param {*} modifyFunction 
 */
export const getControllerComponent = (target,modify,cache,modifyCache) => {

  if(!target){
    return null
  }
  const widgetType = target.type
  const version = target.version
  let Reference =  widgetModel?.getReference()?.[widgetType]
  if(!Reference){
    Reference =  widgetModel?.getReference().Unknown
  }
  // 위젯의 버전에 따라 수정되는 위젯이 다릅니다.
  let vReference =  Reference?.version?.[version]
  if(!vReference){
    vReference = Reference?.version?.[Reference.default]
  }

  if(vReference?.controller){
    return React.createElement(vReference?.controller,{key:target.uuid,target,modify,cache,modifyCache})
  }
  
  return vReference?.controller??null;

}


export const getPreset = ({target})=>{

  if(!target){
    return []
  }
  const widgetType = target.type
  const version = target.version
  let Reference =  widgetModel?.getReference()?.[widgetType]
  if(!Reference){
    Reference =  widgetModel?.getReference().Unknown
  }

  // 레퍼런스가 있는 위젯의 경우

    // 위젯의 버전에 따라 수정되는 위젯이 다릅니다.
    let vReference =  Reference?.version?.[version]
    if(!vReference){
      vReference = Reference?.version?.[Reference.default]
    }

  return vReference?.preset??[];
}

export const getAsset = ({target})=>{

  if(!target){
    return []
  }
  const widgetType = target.type
  const version = target.version
  let Reference =  widgetModel?.getReference()?.[widgetType]
  if(!Reference){
    Reference =  widgetModel?.getReference().Unknown
  }

  // 레퍼런스가 있는 위젯의 경우

    // 위젯의 버전에 따라 수정되는 위젯이 다릅니다.
    let vReference =  Reference?.version?.[version]
    if(!vReference){
      vReference = Reference?.version?.[Reference.default]
    }

  return vReference?.asset??[];

}




export function sidebar(self,props,mode) {

  if (props){
   let result = []

   let sideComponent = widget2(props.type).sidebar[mode]
    for (let index = 0; index < sideComponent.length; index++) {
      let data = Object.assign({type:props.type},Model.safety(props))
      result.push(React.createElement(sideComponent[index],{key:props.uuid+'_'+index,'type':props.type,'alias':props.alias,'uuid':props.uuid,'data':data,'callBack':self.dispatch,'multiCallBack':self.multiDispatch,'getCache':1,'setCache':1}))
    }
    
   return result;
   
  }
}




