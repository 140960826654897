

////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component, Fragment , useState, useEffect, useRef} from 'react';

import { Route, Link } from 'react-router-dom';
// [리덕스]스토어 연결

// 이미지 연결
import logoW from 'assets/logoW.svg';
import PromotionVideo from 'assets/Promotion/overlapPromotion1.mp4';

// 컴포넌트 연결
import HeroSection from 'components/Promotion/HeroSection'; // 오버랩 인트로 색션
import OverviewSection from 'components/Promotion/OverviewSection'; // 중요한 내용 색션
import BottomSection from 'components/Promotion/BottomSection'; // 바텀 부분 색션
import FeatureSection from 'components/Promotion/FeatureSection'; // 오버랩의 특징 설명 색션

import AccountBanner from 'components/Promotion/AccountBanner'; // 어카운트 관련 배너
// SASS&CSS 연결
import "sass/frontPage.scss"

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////


function NoticeDetailPage () {
    
    const [name, setName] = useState(''); // 입력된 이름을 저장할 상태
    const [FrontPageState, setFrontPageState] = useState(''); // 입력된 이름을 저장할 상태
    const [submittedName, setSubmittedName] = useState(''); // 제출된 이름을 저장할 상태
  

    const getPost = ({title = '공지사항',date = '2020-10-10'} = {}) => {

        let post = (
            <Link  to="/notice/1">
           <div className='NoticeContent'>
                <h3 className='NoticeTitle'>
                    {title}
                </h3>
                <div className='NoticeDate'>
                    {date}
                </div>
            </div>
      
            </Link>
    
        )

        return post;
    }



    return  (
    <Fragment>
    <style jsx>{`${PageStyle}`}</style>
      <div id = "NoticePage" className='' >
       

        <div id = "NoticePageHeader" >
        <h2>공지사항</h2>
            </div>
        <div id = "PromotionContent" >
          {getPost()}
          {getPost()}
          {getPost()}
            
        </div>

      </div>
      </Fragment>
    );

}

export default NoticeDetailPage;



const PageStyle = `

#NoticePage{
    display :flex;
    flex-direction: column;
    width : 100%;
    min-height : 100vh;
    color : #fff;
    background-color : #202020;
    font-family: '프리텐다드ExtraBold';
    align-items: center;

}

#NoticePageHeader {
    max-width : 1200px;
    width : 100%;
    height: 200px;
    margin-top : 50px;
    background-color : #fff;
    color : #202020;
    display :flex;
    flex-direction: column;
    justify-content: end;
}

#NoticePageHeader h2 {
    font-size : 40px;
    margin-left : 30px;
}

#NoticePage a {
    text-decoration: none;
}


#PromotionContent{
    max-width : 1200px;
    width : 100%;
}
#PromotionContent .NoticeContent{
    max-width : 1200px;
    width : 100%;
    height: 100px;
    margin : 20px 0px;
    display :flex;
    border-bottom : solid 1px #fff;
    justify-content: space-between;
    color : #C0C0C0;
    text-decoration: none;
}

#PromotionContent .NoticeContent:hover{
    color : #fff;
}

#PromotionContent .NoticeTitle{
    flex : 3;
    height: 80px;
    display :flex;
    flex-direction: column;
    justify-content: end;
    font-size : 30px;
    padding : 0px 10px;
    box-sizing: border-box;
}
#PromotionContent .NoticeDate{
    flex : 1;
    height: 80px;
    display :flex;
    flex-direction: column;
    justify-content: end;
    font-size : 20px;
    padding : 0px 10px;
    align-items: end;
    box-sizing: border-box;
}

#PromotionPage #PromotionContent{
    position: relative;
    display :flex;
    flex-direction: column;
    width : 100%;

}




`;