////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { useState, useEffect, useContext, Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// [프로바이더]
import EditContext from 'service/context/EditContext'; 

// SASS&CSS 연결
import "sass/widgetEdit.scss"

// 컴포넌트 연결
import WidgetDisplay from 'components/display/WidgetDisplay' // 위젯 전용 디스플레이
import PositionModule from '../editModule/PositionModule'; // 위젯의 사이즈를 조정 하는 모듈입니다.
import ErrorModule from '../editModule/ErrorModule'; // 잘못된 위젯의 값이 있을때 편집기를 대체합니다.

// 서비스 연결
import * as Editor from 'service/edit/EditService'; 
import * as ED from 'service/engine/decoder/EditorDecoder'; 
import * as DeCoder from 'service/engine/DeCoder'; 
// 이미지 연결

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////


/** [에디터] 드롭다운 모듈입니다.
* @param {widget} value : 선택된 값
* @param {widget} List : 드롭 다운 리스트
* @param {function} change : 드롭 다운이 적용되었을때
*/


function DropdownModuleV2({value,list=[],change,className,style}) {

    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////

    // set


    // state
    const [OpenList, setOpenList] = useState(false);

    // rel 

    ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////
    useEffect(() => {

        // 여기에 컴포넌트가 마운트되었을 때 실행할 코드를 작성합니다.

        return () => {
        // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
        };
      }, []);

    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////

    const DropdownList = () => {

        return list.map((item)=>(<div className='item' onClick={()=>(change(item))}>
            {item}
        </div>))
        
    }  


    return (
    <Fragment>
        <style jsx>{`${ModuleStyle}`}</style>
        <section className={'DropdownModuleV2 ' + className} style={style} onClick={()=>setOpenList(!OpenList)}>
            <div className='value'>
                {value}
            </div>
            {OpenList?
            <Fragment>
            <div className='list'>
                {DropdownList()}
            </div>
            <div className='cover' onMouseDown={()=>setOpenList(false)}>

            </div>
            </Fragment>
            :null}
        </section>
    </Fragment>
    );

}

export default DropdownModuleV2;

const ModuleStyle = `
.DropdownModuleV2{
    min-width : 50px;
    margin: 5px;
    background-color: #3F3F3F;
    border-radius: 2.5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2), 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    height : 25px;
    justify-content: center;
    align-items: center;
    position: relative;
}

.DropdownModuleV2 .value{

}

.DropdownModuleV2 .list{
    width : 100%;
    max-height: 150px;
    overflow: auto;
    position: absolute;
    z-index : 5;
    top : calc( 100% + 10px);
    background-color: #3F3F3F;
    border-radius: 2.5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2), 0 1px 3px rgba(0, 0, 0, 0.1);
    width: calc(100%);

    overflow-y: hidden;
    overflow-x: hidden;
}

.DropdownModuleV2 .list .item {
    width : clac(100% - 16px);
    height: 20px;
    margin: 8px;
    border-bottom: 1px solid #666;
    text-align: center;
    overflow: hidden;
}

.DropdownModuleV2 ..list:hover .item {
    width : clac(100% - 0px);
 
    margin-right: 0px;
}

.DropdownModuleV2 .list:hover {

    overflow-y: scroll; /* 마우스 오버 시 스크롤바를 표시합니다. */
}

/* 크로스 브라우저 호환성을 위한 스크롤바 스타일링 */
.DropdownModuleV2 .list:hover::-webkit-scrollbar {
    width: 0px; /* 스크롤바의 너비 */
}

.DropdownModuleV2 .list::-webkit-scrollbar-thumb {
    background-color: #000000aa; /* 스크롤바의 색상 */
    border-radius: 0px; /* 스크롤바의 모서리 */
}

.DropdownModuleV2 .list::-webkit-scrollbar-track {
    background: transparent; /* 스크롤 트랙의 색상 */
    width: 0px; /* 스크롤바의 너비 */
}

.DropdownModuleV2 .cover {
    width : 100vw;
    height: 100vh;
    background-color : transparent;
    position: absolute;
    z-index : 2;
}


`;