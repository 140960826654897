////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";
// [프로바이더] 연결
import EditContext from 'service/context/EditContext' ; 

// SASS&CSS 연결
import "sass/widgetEdit.scss"


// 컴포넌트 연결
import ImageButtonModule from '../editModule/ImageButtonModule'; // 이미지 버튼을 눌러 여러 옵션중 값을 정합니다.
import PositionModule from '../editModule/PositionModule';//위치 모듈

// 서비스 연결 
import * as Editor from 'service/edit/EditService'; 

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class PositionEdit extends Component {
    static contextType = EditContext;
    
    state = {
        editorToggle : true
    }

    change = (name,value) =>{
        this.props.callBack('position',name,value)
    }

    sortChange = (name,value) =>{
        this.props.callBack('position',name,value)
    }

    guide = (e,type='test') =>{
        e.stopPropagation()
    }
    
    render() { 

        const Style = {
            label: {
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
            },
            textarea : {
                width: '100%',
                resize: 'none'
            }

        }
        let xValue = this.props.data.position.x
        let yValue = this.props.data.position.y

        return (
            <section className='PositionEdit'>
                {Editor.WidgetEditTitle(this,{title:'위치',toggle:false,icon:'open_with'})}
                {/* {this.state.editorToggle? */}
                <div className={this.state.editorToggle?'editContainer containerOpen':'editContainer containerClose'}>
                    <article className="EditPart MediaSourceModule">
                    {/* <div onMouseEnter={(e)=>this.guide(e)}> */}
                    <PositionModule value={xValue} max={this.context.overlap.meta.resolution.width} title ="x 좌표" type="x" change={this.change}/>
                    {/* </div> */}
                    {/* <div onMouseEnter={(e)=>this.guide(e)}> */}
                    <PositionModule value={yValue} max={this.context.overlap.meta.resolution.height} title ="y 좌표" type="y" change={this.change}/>
                    {/* </div> */}
                    {/* <ImageButtonModule value={null} type ="mediaState"  data={[{val:"play",icon:"play_arrow"},{val:"stop",icon:"device_reset"},]} change={(type,value)=>this.sortChange(value)}/> */}
                    </article>
                </div>
                {/* :''} */}
            </section>
        );
    }
}

////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////

const mapStateToProps = (state) => ({
    // exemple : state.exemple,
})

function mapDispatchToProps(dispatch){
    return {
    //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}
  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(PositionEdit);
  