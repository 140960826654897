////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widgetEdit.scss"


// 컴포넌트 연결
import TextModule from '../editModule/TextModule'; // 텍스트 모듈
import SentenceModule from '../editModule/SentenceModule'; // 텍스트 모듈

import TextSizeModule from '../editModule/TextSizeModule'; // 텍스트 크기 모듈
import FontModule from '../editModule/FontModule'; // 폰트 모듈
import ColorModule from '../editModule/ColorModule'; // 색상 모듈
import SwitchModule from '../editModule/SwitchModule'; // 체크박스 모듈
import NumberModule from '../editModule/NumberModule'; // 숫자 모듈

// 서비스 연결
import * as Editor from 'service/edit/EditService'; 
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class ScoreboardEdit extends Component {
    
    state = {
        editorToggle : true
    }
    
    change = (name,value) =>{
        this.props.callBack('style',name,value)
    }
    valueChange = (name,value) =>{
        this.props.callBack('value',name,value)
    }

    render() { 
        const Style = {
            label: {
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
            },
            textarea : {
                width: '100%',
                resize: 'none'
            }

        }
        return (
            <Fragment>
            <section className='SizeEdit'>
                {Editor.WidgetEditTitle(this,{title:'스코어보드',toggle:false,icon:'scoreboard'})}
                <div className={this.state.editorToggle?'editContainer containerOpen':'editContainer containerClose'}>
               
                <article className="EditPart MediaSourceModule" >
                    <FontModule  value={this.props.data.style.team_fontFamily} type ="team_fontFamily" change={this.change}/>
                    <SwitchModule value={this.props.data.value.reverse} title="팀반전" type ="reverse" change={this.valueChange}/>
                </article>

                <article className="EditPart MediaSourceModule" >
                    <SentenceModule value={this.props.data.value.leftTeamName} title="팀이름" fontFamily={this.props.data.style.teamFont} type ="leftTeamName" change={this.valueChange}/>
                    <ColorModule value={this.props.data.style.leftTeam_fill} title="팀컬러" type ="leftTeam_fill" change={this.change}/>
                    <NumberModule value={this.props.data.value.leftTeamScore} min='0' max='99' title="메인 스코어" type ="leftTeamScore" change={this.valueChange}/>
                    <NumberModule value={this.props.data.value.leftTeamSubScore} min='0' max='99' title="서브 스코어" type ="leftTeamSubScore" change={this.valueChange}/>
                </article>

                <article className="EditPart MediaSourceModule" >
                    <SentenceModule value={this.props.data.value.rightTeamName} title="팀이름" fontFamily={this.props.data.style.teamFont} type ="rightTeamName" change={this.valueChange}/>
                    <ColorModule value={this.props.data.style.rightTeam_fill} title="팀컬러" type ="rightTeam_fill" change={this.change}/>
                    <NumberModule value={this.props.data.value.rightTeamScore} min='0' max='99' title="메인 스코어" type ="rightTeamScore" change={this.valueChange}/>
                    <NumberModule value={this.props.data.value.rightTeamSubScore} min='0' max='99' title="서브 스코어" type ="rightTeamSubScore" change={this.valueChange}/>
                </article>

                <article className="EditPart MediaSourceModule" >
                <TextModule value={this.props.data.value.matchInfo} fontFamily={this.props.data.style.infoFont} title="대회정보" type ="matchInfo" change={this.valueChange}/>
                    {/* <TextSizeModule value={this.props.data.style.info_fontSize} type ="info_fontSize" change={this.change}/> */}
                    <FontModule  value={this.props.data.style.info_fontFamily} type ="info_fontFamily" change={this.change}/>
                </article>

                </div>
            </section>

            </Fragment>
 
            // <Fragment>
            //     <fieldset className="EditModuleField" style={Style.fieldset} >
            //         <legend style={Style.legend} > 팀설정  </legend>
            //         {/* <TextSizeModule value={this.props.data.style.team_fontSize} type ="team_fontSize" change={this.change}/> */}
            //         <FontModule  value={this.props.data.style.team_fontFamily} type ="team_fontFamily" change={this.change}/>
            //         <SwitchModule value={this.props.data.value.reverse} title="팀반전" type ="reverse" change={this.valueChange}/>
            //     </fieldset>

            //     <fieldset className="EditModuleField" style={Style.fieldset} >
            //         <legend style={Style.legend} > 왼쪽팀 </legend>
            //         <SentenceModule value={this.props.data.value.leftTeamName} title="팀이름" fontFamily={this.props.data.style.teamFont} type ="leftTeamName" change={this.valueChange}/>
            //         <ColorModule value={this.props.data.style.leftTeam_fill} title="팀컬러" type ="leftTeam_fill" change={this.change}/>
            //         <NumberModule value={this.props.data.value.leftTeamScore} min='0' max='99' title="점수" type ="leftTeamScore" change={this.valueChange}/>
            //     </fieldset>

            //     <fieldset className="EditModuleField" style={Style.fieldset} >
            //         <legend style={Style.legend} > 오른쪽팀 </legend>
            //         <SentenceModule value={this.props.data.value.rightTeamName} title="팀이름" fontFamily={this.props.data.style.teamFont} type ="rightTeamName" change={this.valueChange}/>
            //         <ColorModule value={this.props.data.style.rightTeam_fill} title="팀컬러" type ="rightTeam_fill" change={this.change}/>
            //         <NumberModule value={this.props.data.value.rightTeamScore} min='0' max='99' title="점수" type ="rightTeamScore" change={this.valueChange}/>
            //     </fieldset>

            //     <fieldset className="EditModuleField" style={Style.fieldset} >
            //         <legend style={Style.legend} > 경기정보 </legend>
            //         <TextModule value={this.props.data.value.matchInfo} fontFamily={this.props.data.style.infoFont} type ="matchInfo" change={this.valueChange}/>
            //         {/* <TextSizeModule value={this.props.data.style.info_fontSize} type ="info_fontSize" change={this.change}/> */}
            //         <FontModule  value={this.props.data.style.info_fontFamily} type ="info_fontFamily" change={this.change}/>
            //     </fieldset>


            // </Fragment>

        );
    }
}

////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    // screenData : state.screenData,
})

function mapDispatchToProps(dispatch){
    return {
    //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(ScoreboardEdit);
  