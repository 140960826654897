// -- 위젯의 정의파일 Model 입니다. 해당 파일을 widgetModel 파일에 import 시켜야 사용 가능 합니다. -- 

////////////////////////////////////////////////////////
// Scoreboard | 스코어보드 
// 경기의 점수판을 실시간으로 기록합니다.
////////////////////////////////////////////////////////

// -- 컴포넌트 불러오기 -- ('components/widget/[위젯이름]') 위치로 가저올 수 있습니다.
import Scoreboard from 'components/widget/Scoreboard'; // 컴포넌트
import { forEach } from 'lodash';

// -- 스타일 불러오기 -- ('service/model/widget/[스타일이름]') 위치로 가저올 수 있습니다.
import * as common from 'service/model/widget/commonStyle'; // 자주 사용되는 스타일 메이커 가저오기
import * as common_v2 from 'service/model/widget/commonStyle_V2'; // 자주 사용되는 스타일 메이커 가저오기
import * as keyframe from 'service/model/widget/keyframe'; // 자주 사용되는 스타일 메이커 가저오기

// -- 기타 함수 불러오기 -- ('service/model/widget/[스타일이름]') 위치로 가저올 수 있습니다.
// import * as [이름] from 'service/[경로]'; 
import * as Editor from 'service/edit/EditService'; 

////////////////////////////////////////////////////////
// 컴포넌트 정의
// 해당 위젯 모댈과 연결할 리엑트 컴포넌트를 정의 합니다.
////////////////////////////////////////////////////////
// export const Component = [가저올 컴포넌트] ; 
export const Component = Scoreboard; 

////////////////////////////////////////////////////////
// 레퍼런스 정의
// 해당 위젯의 기본 설정값을 넣습니다.
////////////////////////////////////////////////////////

export const Reference = {
  position: {x: 0, y: 0, show : true},
  size: {width: 1920, height: 50},
  style : {
    infoFont: "프리텐다드Regular",
    infoSize: 30,
    infoColor : '#FFFFFF',
    teamFont : '프리텐다드Black',
    teamSize: 30,
    teamColor : ["#59CBE8","#F77397"],
    shadowValue: "0", 
    shadowColor: "#000000",
    shadowDirection : "RightBottom",
    ...common.filterDefault,
    ...common.transformDefault,
  },
  value: {
    reverse : false,
    matchInfo: "",
    useTeam : 2,
    teamData : [],
    option: {move:false},
  },
  display:{
    show : true
  },
  animation:{
    mountStart:{keyFrame:null,delay:null,timing:null},
    mount:{keyFrame:null,delay:null,timing:null},
    mountEnd:{keyFrame:null,delay:null,timing:null},
    morphing:true
  },
  preset : 'default'
}

export const ReferenceTeam = {
  teamName : '',
  teamColor : '',
  teamFontColor : '',
  teamLogo : '',
  teamInfo : '',
  teamScore : 0,
  teamSubScore : 0,
  teamSpecialScore : {},
}

////////////////////////////////////////////////////////
// 스타일 정의
// 해당 위젯의 CSS 스타일을 정의합니다.
////////////////////////////////////////////////////////
export function style(data) {
  let left = {}
  let right = {}
  let info = {}
  let team = {}
  let teamStyle = []
  let layout ={}
  let object = {}
  let property = Object.keys(data)
  if(property.includes('position')){
    layout = Object.assign(layout,common.position({widget:data.position}))

  }
  if(property.includes('size')){
    object = Object.assign(object,common.size({widget:data.size}))
  }
  if(property.includes('style')){
    // 폰트
    info = Object.assign(info,common_v2.font({style:data.style,target:{fontFamily:'infoFont',fontSize:'infoSize',color:'infoColor'}}))
    // 폰트
    team = Object.assign(team,common_v2.font({style:data.style,target:{fontFamily:'teamFont'}}))
    // 필터 + 그림자 + 투명도
    object = Object.assign(object,common.filter({widget:data.style}))
  }

  if(property.includes('value')){
    // 폰트
    const teamData =  data?.value?.teamData;
    teamStyle = teamData.map( (team) => ({
      background:team?.teamColor,
      color:team?.teamFontColor,
    }));

  }
  return {layout,object,info,team,teamStyle,left,right}
}
  
////////////////////////////////////////////////////////
// 프리셋 정의
// 해당 위젯의 CSS  프리셋을 정의합니다.
////////////////////////////////////////////////////////
// 프리셋을 사용하지 않는 위젯의 경우
// export const Preset = null
// 프리셋을 사용하는 위젯의 경우
export const Preset = [

  {name:'default', nameKo:'기본 스코어보드',summary:'기본형태의 스코어보드 입니다.',
  info : <p>{Editor.icon({icon:'group',size:15,lineHeight:20})}2</p>,
  data:{size:{width: 1920, height: 50},position:{x: 0, y: 10,},data:{maxTeam : 2, minTeam : 2},style:{infoColor : '#FFFFFF'}}},

  {name:'tilted', nameKo:'틸트 스코어보드',summary:'기본형태의 스코어보드 입니다.',
  info : <p>{Editor.icon({icon:'group',size:15,lineHeight:20})}2</p>,
  data:{size:{width: 1920, height: 50},position:{x: 0, y: 10,},data:{maxTeam : 2, minTeam : 2},style:{infoColor : '#FFFFFF'}}},

  {name:'default_gradation', nameKo:'그라데이션 스코어보드',summary:'기본형태의 스코어보드 입니다.',
  info : <p>{Editor.icon({icon:'group',size:15,lineHeight:20})}2</p>,
  data:{size:{width: 1920, height: 50},position:{x: 0, y: 10,},data:{maxTeam : 2, minTeam : 2},style:{infoColor : '#FFFFFF'}}},

  {name:'boxStyleN', nameKo:'박스형 스코어보드',summary:'기본형태의 스코어보드 입니다.',
  info : <p>{Editor.icon({icon:'group',size:15,lineHeight:20})}2</p>,
  data:{size:{width: 300, height: 200},position:{x: 100, y: 100,},data:{maxTeam : 2, minTeam : 2},style:{infoColor : '#000000'}}},

  {name:'sports', nameKo:'스포츠 스코어보드',summary:'기본형태의 스코어보드 입니다.',
  info : <p>{Editor.icon({icon:'group',size:15,lineHeight:20})}2</p>,
  data:{size:{width: 500, height: 50},data:{maxTeam  : 2, minTeam : 2},style:{infoColor : '#000000'}}},
  
  // {name:'sportsWithInfo', nameKo:'스포츠 스코어보드',summary:'기본형태의 스코어보드 입니다.',
  // info : <p>{Editor.icon({icon:'group',size:15,lineHeight:20})}2</p>,
  // data:{size:{width: 500, height: 50},data:{maxTeam : 2, minTeam : 2},style:{infoColor : '#000000'}}},
  
  // {name:'overwatch_normal', nameKo:'오버워치 기본형 스코어보드', size:{width: 1920, height: 108},position:{x: 0, y: 0,}},
  // // {name:'overwatch2_normal', nameKo:'오버워치 심플 스코어보드', size:{width: '100%', height: '10%'}},
  // {name:'overwatch_underbar', nameKo:'오버워치 언더바 스코어보드', size:{width: 1920, height: 108},position:{x: 0, y: 972,}},
  // {name:'overwatch2_normal', nameKo:'오버워치2 기본형 스코어보드', size:{width: 1920, height: 108},position:{x: 0, y: 0,}},
  // {name:'overwatch2_simple', nameKo:'오버워치2 심플 스코어보드', size:{width: 1920, height: 108},position:{x: 0, y: 0,}},
  // {name:'overwatch2_header', nameKo:'오버워치2 상단바 스코어보드', size:{width: 1920, height: 108},position:{x: 0, y: 0,}},
  // {name:'leagueofLegends_normal', nameKo:'롤 기본형 스코어보드', size:{width: 1920, height: 108},position:{x: 0, y: 0,}},
  // {name:'leagueofLegends_GameStyle', nameKo:'롤 게임스타일', size:{width: 1920, height: 108},position:{x: 0, y: 0,}},
  // {name:'valorant_normal', nameKo:'발로란트 게임스타일', size:{width: 1920, height: 108}},
  // {name:'valorant_double', nameKo:'전투형제 스코어보드 *테스트용', size:{width: 1920, height: 108}},
]


////////////////////////////////////////////////////////
// 애니메이션 정의
// 해당 위젯의 기본 설정값을 넣습니다.
////////////////////////////////////////////////////////
/**
 * 사용 가능한 애니메이션 효과를 정의합니다.
 * @param {*} state // 애니메이션 효과 종류 mountStart&mountEnd
 * @param {*} preset // 위젯의 프리셋
 * @returns 
 */
export function availableAnimation (state,preset=null) {
  const List = keyframe.basic(state)

  let customList = {}

  switch (preset) {
    case 'default':
      if(state == 'mountStart'){
        customList = {
          'custom' :  {
            info : {name:'fade in',nameKo:'페이드인',icon:'blur_on'},
            timing : {max:5000,min:500,default:1000},
            target :{layout:{keyframe:'widget_animation_fadeIn'}},
          },
        }
      }

      else if(state == 'mountEnd'){
        customList = {
          'custom' :  {
            info : {name:'fade in',nameKo:'페이드인',icon:'blur_on'},
            timing : {max:5000,min:500,default:1000},
            target :{layout:{keyframe:'widget_animation_fadeIn'}},
          },
        }
      }
      break;
    default:
      break;
  }

  console.log('state,preset',List, customList)
  const MergedList = Object.assign( customList, List );
  return MergedList;
}
/**
 * animation 애니메이션 효과를 정의합니다.
 * @param {*} state // 애니메이션 효과 종류 mountStart&mountEnd
 * @param {*} data  // 위젯의 데이터
 * @param {*} preset // 해당 프리셋에서 유효한 애니메이션인지 검증합니다. 
 * @returns 
 */
export function animation (state,data,preset=null) {
  
  let output = {}
  
  switch (state) {
    case 'mountStart':

    const List = availableAnimation(state,preset)
    output = keyframe.css({widgetAnimation:data.animation[state],availableAnimation:List})
    break;

    default:
      break;
  }

  return output
}
////////////////////////////////////////////////////////
// 커스텀 펑션 정의
// 해당 위젯 및 위젯 모델에서 사용하는 평선을 정의합니다.
////////////////////////////////////////////////////////
