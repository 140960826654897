import store from "store";
// 이미지
import quick from 'assets/guide/quick.png';
import live from 'assets/guide/live.png';
import template from 'assets/guide/template.png';
import menu from 'assets/guide/menu.png';
import onAirFull_1 from 'assets/guide/onAirFull_1.png';
import onAir_1 from 'assets/guide/onAir_1.png';
import sidebarFull_1 from 'assets/guide/sidebarFull_1.png';
import widgetListFull_1 from 'assets/guide/widgetListFull_1.png';
import widgetList_1 from 'assets/guide/widgetList_1.png';
import widgetSelectFull_1 from 'assets/guide/widgetSelectFull_1.png';
import screenIcon from 'assets/screen.png';
import templateIcon from 'assets/template.png';

import widgetControlIcon from 'assets/widgetControl.png';

import information from 'assets/edit/information.png';

////////////////////////////////////////////////////////
// object (해당객체번호)
// 해당 객체를 삭제합니다.
////////////////////////////////////////////////////////

export function list(name,self) {
    const DICTIONARY = {
        front :{
            img : '',
            title : '',
            info : <div>
                        <div className="title">
                            <p>편집화면에 <br/> 오신것을 환영합니다</p>
                        </div>
                        <div className="info">
                            <div className="notice">
                                오버렙은 알파버전으로<br/>
                                크롬에서만 편집을 지원하며 오류가 있을수 있습니다.
                            </div>
                            <div className="dictionaryLink" onClick={()=>self.change('guide')}>
                                <div className="listIMG">
                                    <img src={quick}/>
                                </div>
                                <p className="listTitel">
                                    편집화면<br/> 간단 가이드
                                </p>
                            </div>
                            <div className="dictionaryLink" onClick={()=>self.change('onair')}>
                                <div className="listIMG">
                                    <img src={live}/>
                                </div>
                                <p className="listTitel">
                                송출화면<br/> 변경하는 방법
                                </p>
                            </div>
                            <div className="dictionaryLink" onClick={()=>self.change('template')}>
                                <div className="listIMG">
                                    <img src={template}/>
                                </div>
                                <p className="listTitel">
                                템플릿을 통해<br/> 바로 사용하기
                                </p>
                            </div>
                            <hr/>
                            <p>
                                하단의 <img className="informationbnt"  src={information}/> 버튼을 눌러 언제든 다시 보실 수 있습니다.
                            </p>
                            {/* <img className="img50" src={menu}/>
                            <div>
                                <p>
                                왼쪽 상단의 메뉴를 클릭해서
                                다시 팁을 보실 수 있습니다.
                                </p>
                              
                            </div> */}
                        </div>
                    </div>,
        },
        guide :{
            img : '',
            title : '',
            info : <div>
                        <div className="title">
                            <p>편집화면을 소개해 드릴게요</p>
                        </div>
                        <div className="info">
                            <b>편집화면</b>은 왼쪽 사이드바, 편집화면, 위젯스토어로 나뉩니다.
                        </div>
                        <div className="center">
                        <img  className="img90"  src={sidebarFull_1}/>
                        </div>
                        
                        <div className="info">
                            <b>사이드바</b>에서는 다음과 같은 일을 할 수 있습니다.
                            <hr/>
                            <h3>화면</h3>
                            <ul className="textAlign_left">
                                <li> <img className="img20px" src={screenIcon}/> : 새로운 스크린을 추가합니다.</li>
                                <li> <img className="img20px" src={templateIcon}/>  : 이미 만들어저 있는 스크린을 추가합니다.</li>
                                <li> <img className="img20px" src={widgetControlIcon}/> 위젯리스트 : 위젯의 순서를 조정하는곳 입니다. </li>
                            </ul>
                            <hr/>
                            <h3>편집</h3>
                            <ul className="textAlign_left">
                                <li> 위치 : 스크린에서의 위젯 좌표를 설정합니다. </li>
                                <li> 크기 : 위젯의 크기를 설정합니다.  </li>
                            </ul>
                        </div>
                        
                        <div className="center">
                        <img  className="img90"  src={widgetListFull_1}/>
                        </div>
                        
                        <div className="info">
                           <b>위젯 스토어</b>에서 필요한 위젯을 선택해 사용 할 수 있습니다.
                        </div>
                        <hr/>
                        <div className="center">
                        <img  className="img90"  src={widgetList_1}/>
                        </div>
                        
                        
            </div>,
        },
        onair :{
            img : '',
            title : '',
            info : <div>
                        <div className="title">
                            <p>화면을 송출하는법</p>
                        </div>
                        <div className="info">
                            <b>사이드바</b>에서 <b>스크린</b>을 오른쪽 클릭하면 화면을 송출 할 수 있습니다.
                        </div>
                        <div className="center">
                        <img  className="img90"  src={onAirFull_1}/>
                        </div>
                        
                        <div className="info">
                            
                            <hr/>
                            <h3>화면을 송출하는법</h3>
                            <ul className="textAlign_left">
                                <ol>1. 송출하고 싶은 화면을 오른쪽 클릭합니다.</ol>
                                <ol>2. 컨텍스트 메뉴가 나오면 <b>이 화면 송출하기</b>를 클릭합니다</ol>
                                <ol>3. 선택한 화면의 테두리가 붉게 변하며 화면이 송출됩니다.</ol>
                            </ul>
                            <hr/>
                         
                        </div>  
                        <div className="center">
                            <img  className="img90"  src={onAir_1}/>
                        </div>
                        <div className="info">
                            <p>
                                송출중인 화면은 테두리가 붉습니다.<br/>
                                송출중인 화면이 없으면 아무것도 송출하지 않습니다.
                            </p>
                         
                        </div>  
                    </div>,
        },
        template :{
            img : '',
            title : '',
            info : <div>
                    <div className="title">
                        <p>템플릿을 선택해 사용하기</p>
                    </div>
                    <div className="info">
                        <p>
                        오버렙에서는 용도에 맞게 제작된 템플릿이 있습니다.<br>
                        </br>
                        사이드바에서 <img className="img20px" src={templateIcon}/>  버튼을 눌러 템플릿을 선택 할 수 있습니다.
                        </p>
                        
                    </div>
                    
                </div>,
        },
        hotkey :{
            img : '',
            title : '',
            info : <div>
                    <div className="title">
                        <p>단축키 소개</p>
                    </div>
                    <div className="info">
                        <p>
                        오버랩에서 사용할 수 있는 단축키 입니다.<br/>
                        </p> 
                        <table border="1px">
                            <tr>
                                <th>이름</th>
                                <th>단축키</th>
                                <th>설명</th>
                            </tr>
                            <tr>
                                <td>복사하기</td>
                                <td>Control + C</td>
                                <td>위젯을 복사합니다.</td>
                            </tr>
                            <tr>
                                <td>붙여넣기</td>
                                <td>Control + V</td>
                                <td>위젯을 붙여넣습니다.</td>
                            </tr>
                            <tr>
                                <td>되돌리기</td>
                                <td>Control + Z</td>
                                <td>이전상태로 편집화면을 되돌립니다.</td>
                            </tr>
                        </table>
                    </div>
                </div>,
        },
    }
    
    
    return DICTIONARY[name]
  }

