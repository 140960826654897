import Particles from 'components/widget/Particles';
import React, { Component , Fragment } from 'react';


export function connectTest() {
    console.log('WidgetData : 연결테스트')
}

export function get(name) {
    switch (name) {
        case 'GameMaps':
            return GAMEMAPS
        case 'GameCharacters':
            return GAMECHARACTERS
        case 'Particles':
            return PARTICLES
        default:
            return null
    }
}

const IMGSERVERURL = 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget'

const GAMEMAPS = {
    "overwatch": {
        'Nepal' : {
            filter : '',
            translate : {ko:'네팔',en:'Nepal'},
            type : 'Control',
            url : {기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameMaps/overwatch/nepal.webp'},
        },
        'LijiangTower' : {
            filter : '',
            translate : {ko:'리장타워',en:'Lijiang Tower'},
            type : 'Control',
            url : {기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameMaps/overwatch/lijiangtower.webp'},
        },
        'Busan' : {
            filter : '',
            translate : {ko:'부산',en:'Busan'},
            type : 'Control',
            url : {기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameMaps/overwatch/busan.webp'},
        },
        'Oasis' : {
            filter : '',
            translate : {ko:'오아시스',en:'Oasis'},
            type : 'Control',
            url : {기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameMaps/overwatch/oasis.webp'},
        },
        'Ilios' : {
            filter : '',
            translate : {ko:'일리오스',en:'Ilios'},
            type : 'Control',
            url : {기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameMaps/overwatch/ilios.webp'},
        },
        // 'VolskayaIndustries' : {
        //     filter : '',
        //     translate : {ko:'볼스카야 인더스트리',en:'Volskaya Industries'},
        //     type : 'Assault',
        //     url : {기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameMaps/overwatch/volskaya.webp'},
        // },
        // 'TempleOfAnubis' : {
        //     filter : '',
        //     translate : {ko:'아누비스 신전',en:'Temple of Anubis'},
        //     type : 'Assault',
        //     url : {기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameMaps/overwatch/anubis.webp'},
        // },
        // 'Paris' : {
        //     filter : '',
        //     translate : {ko:'파리',en:'Paris'},
        //     type : 'Assault',
        //     url : {기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameMaps/overwatch/paris.webp'},
        // },
        // 'Hanamura' : {
        //     filter : '',
        //     translate : {ko:'하나무라',en:'Hanamura'},
        //     type : 'Assault',
        //     url : {기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameMaps/overwatch/hanamura.webp'},
        // },
        // 'HorizonLunarColony' : {
        //     filter : '',
        //     translate : {ko:'호라이즌 달 기지',en:'Horizon Lunar Colony'},
        //     type : 'Assault',
        //     url : {기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameMaps/overwatch/horizon.webp'},
        // },
        'Watchpoint:Gibraltar' : {
            filter : '',
            translate : {ko:'감시기지:지블로터',en:'Watchpoint:Gibraltar'},
            type : 'Escort',
            url : {기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameMaps/overwatch/gibraltar.webp'},
        },
        'Route66' : {
            filter : '',
            translate : {ko:'66번국도',en:'Route66'},
            type : 'Escort',
            url : {기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameMaps/overwatch/route66.webp'},
        },
        'Dorado' : {
            filter : '',
            translate : {ko:'도라도',en:'Dorado'},
            type : 'Escort',
            url : {기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameMaps/overwatch/dorado.webp'},
        },
        'Rialto' : {
            filter : '',
            translate : {ko:'리알토',en:'Rialto'},
            type : 'Escort',
            url : {기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameMaps/overwatch/rialto.webp'},
        },
        'Junkertown' : {
            filter : '',
            translate : {ko:'쓰레기촌',en:'Junkertown'},
            type : 'Escort',
            url : {기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameMaps/overwatch/junkertown.webp'},
        },
        'Havana' : {
            filter : '',
            translate : {ko:'하바나',en:'Havana'},
            type : 'Escort',
            url : {기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameMaps/overwatch/havana.webp'},
        },
        'CircuitRoyal' : {
            filter : '',
            translate : {ko:'서킷로얄',en:'CircuitRoyal'},
            type : 'Escort',
            url : {기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameMaps/overwatch/circuitroyal.webp'},
        },
        // 'Numbani' : {
        //     filter : '',
        //     translate : {ko:'눔바니',en:'Numbani'},
        //     type : 'Hybrid',
        //     url : {기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameMaps/overwatch/numbani.webp'},
        // },
        'BlizzardWorld' : {
            filter : '',
            translate : {ko:'블리자드월드',en:'Blizzard World'},
            type : 'Hybrid',
            url : {기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameMaps/overwatch/blizzardworld.webp'},
        },
        'Eichenwalde' : {
            filter : '',
            translate : {ko:'아이헨발데',en:'Eichenwalde'},
            type : 'Hybrid',
            url : {기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameMaps/overwatch/eichenwalde.webp'},
        },
        'KingsRow' : {
            filter : '',
            translate : {ko:'왕의길',en:'Kings Row'},
            type : 'Hybrid',
            url : {기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameMaps/overwatch/kingsrow.webp'},
        },
        'Hollywood' : {
            filter : '',
            translate : {ko:'할리우드',en:'Hollywood'},
            type : 'Hybrid',
            url : {기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameMaps/overwatch/hollywood.webp'},
        },
        'Paraiso' : {
            filter : '',
            translate : {ko:'파라이수',en:'Paraíso'},
            type : 'Hybrid',
            url : {기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameMaps/overwatch/paraiso.webp'},
        },
        'Midtown' : {
            filter : '',
            translate : {ko:'미드타운',en:'Midtown'},
            type : 'Hybrid',
            url : {기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameMaps/overwatch/midtown.webp'},
        },
        'NewQueenStreet' : {
            filter : '',
            translate : {ko:'뉴퀸스트리트',en:'NewQueenStreet'},
            type : 'Push',
            url : {기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameMaps/overwatch/newqueenstreet.webp'},
        },
        'Colosseo' : {
            filter : '',
            translate : {ko:'콜로세오',en:'Colosseo'},
            type : 'Push',
            url : {기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameMaps/overwatch/midtown.webp'},
        },
        'Esperanca' : {
            filter : '',
            translate : {ko:'이스페란사',en:'Esperança'},
            type : 'Push',
            url : {기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameMaps/overwatch/Esperanca.webp'},
        },
        'ChateauGuillard' : {
            filter : '',
            translate : {ko:'샤토기야르',en:'ChateauGuillard'},
            type : 'Deathmatch',
            url : {기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameMaps/overwatch/chateauguillard.webp'},
        },
        'Petra' : {
            filter : '',
            translate : {ko:'페트라',en:'Petra'},
            type : 'Deathmatch',
            url : {기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameMaps/overwatch/petra.webp'},
        },
        'Kanezaka' : {
            filter : '',
            translate : {ko:'카네자카',en:'Kanezaka'},
            type : 'Deathmatch',
            url : {기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameMaps/overwatch/kanezaka.webp'},
        },
        'Malevento' : {
            filter : '',
            translate : {ko:'말레벤토',en:'Malevento'},
            type : 'Deathmatch',
            url : {기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameMaps/overwatch/malevento.webp'},
        },
        'Ayutthaya' : {
            filter : '',
            translate : {ko:'아유타야',en:'Ayutthaya'},
            type : 'Flag',
            url : {기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameMaps/overwatch/ayutthaya.webp'},
        },
        'BlackForest' : {
            filter : '',
            translate : {ko:'검은숲',en:'BlackForest'},
            type : 'Arena',
            url : {기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameMaps/overwatch/blackforest.webp'},
        },
        'Necropolis' : {
            filter : '',
            translate : {ko:'네크로폴리스',en:'Necropolis'},
            type : 'Arena',
            url : {기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameMaps/overwatch/necropolis.webp'},
        },
        'Castillo' : {
            filter : '',
            translate : {ko:'카스티요',en:'Castillo'},
            type : 'Arena',
            url : {기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameMaps/overwatch/castillo.webp'},
        },
        'Ecopoint:Antarctica' : {
            filter : '',
            translate : {ko:'탐사기지:남극',en:'Ecopoint:Antarctica'},
            type : 'Arena',
            url : {기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameMaps/overwatch/antarctica.webp'},
        },
    },
    'valorant':{
        'Fracture' : {
            filter : '',
            translate : {ko:'프랙처',en:'Fracture'},
            type : 'normal',
            url : {기본스타일:'https://images.contentstack.io/v3/assets/bltb6530b271fddd0b1/bltf4485163c8c5873c/6131b23e9db95e7ff74b6393/Valorant_FRACTURE_Minimap_Alpha_web.png'},
        },
        'Breeze' : {
            filter : '',
            translate : {ko:'브리즈',en:'Breeze'},
            type : 'normal',
            url : {기본스타일:'https://images.contentstack.io/v3/assets/bltb6530b271fddd0b1/bltb03d2e4867f2e324/607f995892f0063e5c0711bd/breeze-featured_v1.png'},
        },
        'Icebox' : {
            filter : '',
            translate : {ko:'아이스박스',en:'Icebox'},
            type : 'normal',
            url : {기본스타일:'https://images.contentstack.io/v3/assets/bltb6530b271fddd0b1/bltde02911a015d7ef9/5f80d2851f5f6d4173b4e49d/Icebox_transparentbg_for_Web.png'},
        },
        'Bind' : {
            filter : '',
            translate : {ko:'바인드',en:'Bind'},
            type : 'normal',
            url : {기본스타일:'https://images.contentstack.io/v3/assets/bltb6530b271fddd0b1/blt8538036a309525ae/5ebc470bfd85ad7411ce6b50/bind-featured.png'},
        },
        'Haven' : {
            filter : '',
            translate : {ko:'헤이븐',en:'Haven'},
            type : 'normal',
            url : {기본스타일:'https://images.contentstack.io/v3/assets/bltb6530b271fddd0b1/blt8afb5b8145f5e9b2/5ebc46f7b8c49976b71c0bc5/haven-featured.png'},
        },
        'Split' : {
            filter : '',
            translate : {ko:'스플릿',en:'Split'},
            type : 'normal',
            url : {기본스타일:'https://images.contentstack.io/v3/assets/bltb6530b271fddd0b1/bltd188c023f88f7d91/5ebc46db20f7727335261fcd/split-featured.png'},
        },
        'Ascent' : {
            filter : '',
            translate : {ko:'어센트',en:'Ascent'},
            type : 'normal',
            url : {기본스타일:'https://images.contentstack.io/v3/assets/bltb6530b271fddd0b1/blta9b912e1a1b59aa4/5ebc471cfa550001f72bcb13/ascent-featured.png'},
        },
    },
    'LeagueOfLegends':{
        'SummonersRift' : {
            filter : '',
            translate : {ko:'소환사의 협곡',en:'Summoner&lsquo;s Rift'},
            type : 'normal',
            url : {기본스타일:'https://www.leagueoflegends.com/static/base-2x-1f52ee2c1820c7cfe9031a6f7524c7b5.png'},
        },
    },
    
}

const GAMECHARACTERS = {
    "overwatch": {
        'Null': {
            filter : '',
            translate : {ko:'없음',en:'null'},
            type : 'null',
            url : {
                원화스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/concept/null.png',
                옵1스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/default/null.png',
                기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/default/null.png',
                연블리스타일 : 'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/yeonvely/null.png',
            },        
        },
        'Ana': {
            filter : '',
            translate : {ko:'아나',en:'ana'},
            type : 'Support',
            url : {
                원화스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/concept/ana.png',
                옵1스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/default/ana.png',
                기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/ow2default/ana.png',
                연블리스타일 : 'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/yeonvely/ana.png',
            },        
        },
        'Ashe' : {
            filter : '',
            translate : {ko:'애쉬',en:'ashe'},
            type : 'DPS',
            url : {
                원화스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/concept/ashe.png',
                옵1스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/default/ashe.png',
                기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/ow2default/ashe.png',
                연블리스타일 : 'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/yeonvely/ashe.png',
            },        
        },
        'Baptiste' : {
            filter : '',
            translate : {ko:'바티스트',en:'baptiste'},
            type : 'Support',
            url : {
                원화스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/concept/baptiste.png',
                옵1스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/default/baptiste.png',
                기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/ow2default/baptiste.png',
                연블리스타일 : 'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/yeonvely/baptiste.png',
            },        
        },
        'Bastion' : {
            filter : '',
            translate : {ko:'바스티온',en:'bastion'},
            type : 'DPS',
            url : {
                원화스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/concept/bastion.png',
                옵1스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/default/bastion.png',
                기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/ow2default/bastion.png',
                연블리스타일 : 'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/yeonvely/bastion.png',
            },        
        },
        'Brigitte' : {
            filter : '',
            translate : {ko:'브리기테',en:'brigitte'},
            type : 'Support',
            url : {
                원화스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/concept/brigitte.png',
                옵1스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/default/brigitte.png',
                기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/ow2default/brigitte.png',
                연블리스타일 : 'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/yeonvely/brigitte.png',
            },
        },
        'Doomfist' : {
            filter : '',
            translate : {ko:'둠피스트',en:'doomfist'},
            type : 'Tank',
            url : {
                원화스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/concept/doomfist.png',
                옵1스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/default/doomfist.png',
                기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/ow2default/doomfist.png',
                연블리스타일 : 'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/yeonvely/doomfist.png',
            },        
        },
        'Dva' : {
            filter : '',
            translate : {ko:'디바',en:'D.va'},
            type : 'Tank',
            url : {
                원화스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/concept/dva.png',
                옵1스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/default/dva.png',
                기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/ow2default/dva.png',
                연블리스타일 : 'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/yeonvely/dva.png',
            },
        },
        'Echo' : {
            filter : '',
            translate : {ko:'에코',en:'echo'},
            type : 'DPS',
            url : {
                원화스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/concept/echo.png',
                옵1스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/default/echo.png',
                기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/ow2default/echo.png',
                연블리스타일 : 'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/yeonvely/echo.png',
            }, 
        },
        'Genji' : {
            filter : '',
            translate : {ko:'겐지',en:'genji'},
            type : 'DPS',
            url : {
                원화스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/concept/genji.png',
                옵1스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/default/genji.png',
                기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/ow2default/genji.png',
                연블리스타일 : 'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/yeonvely/genji.png',
            },        
        },
        'Hanzo' : {
            filter : '',
            translate : {ko:'한조',en:'hanzo'},
            type : 'DPS',
            url : {
                원화스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/concept/hanzo.png',
                옵1스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/default/hanzo.png',
                기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/ow2default/hanzo.png',
                연블리스타일 : 'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/yeonvely/hanzo.png',
            },        
        },
        'Junkrat' : {
            filter : '',
            translate : {ko:'정크렛',en:'junkrat'},
            type : 'DPS',
            url : {
                원화스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/concept/junkrat.png',
                옵1스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/default/junkrat.png',
                기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/ow2default/junkrat.png',
                연블리스타일 : 'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/yeonvely/junkrat.png',
            },        
        },
        'JunkerQueen' : {
            filter : '',
            translate : {ko:'정커퀸',en:'junkerqueen'},
            type : 'Tank',
            url : {
                원화스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/default/null.png',
                옵1스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/default/null.png',
                기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/ow2default/junkerqueen.png',
                연블리스타일 : 'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/default/null.png',
            },        
        },
        'Kiriko' : {
            filter : '',
            translate : {ko:'키리코',en:'kiriko'},
            type : 'Support',
            url : {
                원화스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/default/null.png',
                옵1스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/default/null.png',
                기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/ow2default/kiriko.png',
                연블리스타일 : 'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/default/null.png',
            },        
        },
        'Lucio' : {
            filter : '',
            translate : {ko:'루시우',en:'lucio'},
            type : 'Support',
            url : {
                원화스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/concept/lucio.png',
                옵1스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/default/lucio.png',
                기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/ow2default/lucio.png',
                연블리스타일 : 'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/yeonvely/lucio.png',
            },        
        },
        'Cassidy' : {
            filter : '',
            translate : {ko:'캐서디',en:'cassidy'},
            type : 'DPS',
            url : {
                원화스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/concept/cassidy.png',
                옵1스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/default/mccree.png',
                기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/ow2default/cassidy.png',
                연블리스타일 : 'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/yeonvely/mccree.png',
            },        
        },
        'Mei' : {
            filter : '',
            translate : {ko:'메이',en:'mei'},
            type : 'DPS',
            url : {
                원화스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/concept/mei.png',
                옵1스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/default/mei.png',
                기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/ow2default/mei.png',
                연블리스타일 : 'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/yeonvely/mei.png',
            },        
        },
        'Mercy' : {
            filter : '',
            translate : {ko:'메르시',en:'mercy'},
            type : 'Support',
            url : {
                원화스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/concept/mercy.png',
                옵1스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/default/mercy.png',
                기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/ow2default/mercy.png',
                연블리스타일 : 'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/yeonvely/mercy.png',
            },        
        },
        'Moira' : {
            filter : '',
            translate : {ko:'모이라',en:'moira'},
            type : 'Support',
            url : {
                원화스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/concept/moira.png',
                옵1스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/default/moira.png',
                기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/ow2default/moira.png',
                연블리스타일 : 'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/yeonvely/moira.png',
            },        
        },
        'Orisa' : {
            filter : '',
            translate : {ko:'오리사',en:'orisa'},
            type : 'Tank',
            url : {
                원화스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/concept/orisa.png',
                옵1스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/default/orisa.png',
                기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/ow2default/orisa.png',
                연블리스타일 : 'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/yeonvely/orisa.png',
            },        
        },
        'Pharah' : {
            filter : '',
            translate : {ko:'파라',en:'pharah'},
            type : 'DPS',
            url : {
                원화스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/concept/pharah.png',
                옵1스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/default/pharah.png',
                기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/ow2default/pharah.png',
                연블리스타일 : 'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/yeonvely/pharah.png',
            },        
        },
        'Reaper' : {
            filter : '',
            translate : {ko:'리퍼',en:'reaper'},
            type : 'DPS',
            url : {
                원화스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/concept/reaper.png',
                옵1스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/default/reaper.png',
                기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/ow2default/reaper.png',
                연블리스타일 : 'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/yeonvely/reaper.png',
            },        
        },
        'Reinhardt' : {
            filter : '',
            translate : {ko:'라인하르트',en:'reinhardt'},
            type : 'Tank',
            url : {
                원화스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/concept/reinhardt.png',
                옵1스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/default/reinhardt.png',
                기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/ow2default/reinhardt.png',
                연블리스타일 : 'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/yeonvely/reinhardt.png',
            },        
        },
        'Roadhog' : {
            filter : '',
            translate : {ko:'로드호그',en:'roadhog'},
            type : 'Tank',
            url : {
                원화스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/concept/roadhog.png',
                옵1스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/default/roadhog.png',
                기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/ow2default/roadhog.png',
                연블리스타일 : 'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/yeonvely/roadhog.png',
            },        
        },
        'Sigma' : {
            filter : '',
            translate : {ko:'시그마',en:'sigma'},
            type : 'Tank',
            url : {
                원화스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/concept/sigma.png',
                옵1스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/default/sigma.png',
                기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/ow2default/sigma.png',
                연블리스타일 : 'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/yeonvely/sigma.png',
            },        
        },
        'Sojourn' : {
            filter : '',
            translate : {ko:'소전',en:'sojourn'},
            type : 'DPS',
            url : {
                원화스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/concept/sojourn.png',
                옵1스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/default/sojourn.png',
                기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/ow2default/sojourn.png',
                연블리스타일 : 'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/yeonvely/sojourn.png',
            },        
        },
        'Soldier-76' : {
            filter : '',
            translate : {ko:'솔저:76',en:'soldier:76'},
            type : 'DPS',
            url : {
                원화스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/concept/soldier-76.png',
                옵1스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/default/soldier-76.png',
                기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/ow2default/soldier-76.png',
                연블리스타일 : 'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/yeonvely/soldier-76.png',
            },        
        },
        'Sombra' : {
            filter : '',
            translate : {ko:'솜브라',en:'sombra'},
            type : 'DPS',
            url : {
                원화스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/concept/sombra.png',
                옵1스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/default/sombra.png',
                기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/ow2default/sombra.png',
                연블리스타일 : 'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/yeonvely/sombra.png',
            },        
        },
        'Symmetra' : {
            filter : '',
            translate : {ko:'시메트라',en:'symmetra'},
            type : 'DPS',
            url : {
                원화스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/concept/symmetra.png',
                옵1스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/default/symmetra.png',
                기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/ow2default/symmetra.png',
                연블리스타일 : 'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/yeonvely/symmetra.png',
            },        
        },
        'Mercy' : {
            filter : '',
            translate : {ko:'메르시',en:'mercy'},
            type : 'Support',
            url : {
                원화스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/concept/mercy.png',
                옵1스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/default/mercy.png',
                기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/ow2default/mercy.png',
                연블리스타일 : 'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/yeonvely/mercy.png',
            },        
        },
        'Torbjorn' : {
            filter : '',
            translate : {ko:'토르비욘',en:'torbjorn'},
            type : 'DPS',
            url : {
                원화스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/concept/torbjorn.png',
                옵1스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/default/torbjorn.png',
                기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/ow2default/torbjorn.png',
                연블리스타일 : 'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/yeonvely/torbjorn.png',
            },        
        },
        'Tracer' : {
            filter : '',
            translate : {ko:'트레이서',en:'tracer'},
            type : 'DPS',
            url : {
                원화스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/concept/tracer.png',
                옵1스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/default/tracer.png',
                기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/ow2default/tracer.png',
                연블리스타일 : 'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/yeonvely/tracer.png',
            },        
        },
        'Widowmaker' : {
            filter : '',
            translate : {ko:'위도우메이커',en:'widowmaker'},
            type : 'DPS',
            url : {
                원화스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/concept/widowmaker.png',
                옵1스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/default/widowmaker.png',
                기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/ow2default/widowmaker.png',
                연블리스타일 : 'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/yeonvely/widowmaker.png',
            },        
        },
        'Winston' : {
            filter : '',
            translate : {ko:'윈스턴',en:'winston'},
            type : 'Tank',
            url : {
                원화스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/concept/winston.png',
                옵1스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/default/winston.png',
                기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/ow2default/winston.png',
                연블리스타일 : 'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/yeonvely/winston.png',
            },        
        },
        'Wrecking-ball' : {
            filter : '',
            translate : {ko:'레킹볼',en:'wrecking ball'},
            type : 'Tank',
            url : {
                원화스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/concept/wrecking-ball.png',
                옵1스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/default/wrecking-ball.png',
                기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/ow2default/wrecking-ball.png',
                연블리스타일 : 'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/yeonvely/wrecking-ball.png',
            },        
        },
        'Zarya' : {
            filter : '',
            translate : {ko:'자리야',en:'zarya'},
            type : 'Tank',
            url : {
                원화스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/concept/zarya.png',
                옵1스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/default/zarya.png',
                기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/ow2default/zarya.png',
                연블리스타일 : 'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/yeonvely/zarya.png',
            },        
        },
        'Zenyatta' : {
            filter : '',
            translate : {ko:'젠야타',en:'zenyatta'},
            type : 'Support',
            url : {
                원화스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/concept/zenyatta.png',
                옵1스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/default/zenyatta.png',
                기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/ow2default/zenyatta.png',
                연블리스타일 : 'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/yeonvely/zenyatta.png',
            },        
        },
    },
    'valorant':{
        'Null' : {
            filter : '',
            translate : {ko:'없음',en:'null'},
            type : 'null',
            url : {
                바스트샷이미지:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/valorant/null.png',
                기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/valorant/null.png',
            },        
        },
        'Jett' : {
            filter : '',
            translate : {ko:'제트',en:'JETT'},
            type : 'Duelist',
            url : {
                바스트샷이미지:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/valorant/valorant_jett_min.png',
                기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/valorant/valorant_jett_normal.png',
            },        
        },
        'Raze' : {
            filter : '',
            translate : {ko:'레이즈',en:'RAZE'},
            type : 'Duelist',
            url : {
                바스트샷이미지:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/valorant/valorant_raze_min.png',
                기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/valorant/valorant_raze_normal.png',
            },        
        },
        'Phoenix' : {
            filter : '',
            translate : {ko:'피닉스',en:'PHOENIX'},
            type : 'Duelist',
            url : {
                바스트샷이미지:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/valorant/valorant_phoenix_min.png',
                기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/valorant/valorant_phoenix_normal.png',
            },        
        },
        'Reyna' : {
            filter : '',
            translate : {ko:'레이나',en:'REYNA'},
            type : 'Duelist',
            url : {
                바스트샷이미지:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/valorant/valorant_reyna_min.png',
                기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/valorant/valorant_reyna_normal.png',
            },        
        },
        'Yoru' : {
            filter : '',
            translate : {ko:'요루',en:'YORU'},
            type : 'Duelist',
            url : {
                바스트샷이미지:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/valorant/valorant_yoru_min.png',
                기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/valorant/valorant_yoru_normal.png',
            },        
        },
        'Neon' : {
            filter : '',
            translate : {ko:'네온',en:'NEON'},
            type : 'Duelist',
            url : {
                바스트샷이미지:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/valorant/valorant_neon_min.png',
                기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/valorant/valorant_neon_normal.png',
            },        
        },
        'Breach' : {
            filter : '',
            translate : {ko:'브리치',en:'BREACH'},
            type : 'Initiator',
            url : {
                바스트샷이미지:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/valorant/valorant_breach_min.png',
                기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/valorant/valorant_breach_normal.png',
            },        
        },
        'Sova' : {
            filter : '',
            translate : {ko:'소바',en:'SOVA'},
            type : 'Initiator',
            url : {
                바스트샷이미지:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/valorant/valorant_sova_min.png',
                기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/valorant/valorant_sova_normal.png',
            },        
        },
        'Skye' : {
            filter : '',
            translate : {ko:'스카이',en:'SKYE'},
            type : 'Initiator',
            url : {
                바스트샷이미지:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/valorant/valorant_skye_min.png',
                기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/valorant/valorant_skye_normal.png',
            },        
        },
        'Kayo' : {
            filter : '',
            translate : {ko:'케이/오',en:'KAY/O'},
            type : 'Initiator',
            url : {
                바스트샷이미지:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/valorant/valorant_kayo_min.png',
                기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/valorant/valorant_kayo_normal.png',
            },        
        },
        'Fade' : {
            filter : '',
            translate : {ko:'페이드',en:'FADE'},
            type : 'Initiator',
            url : {
                바스트샷이미지:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/valorant/valorant_fade_min.png',
                기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/valorant/valorant_fade_normal.png',
            },        
        },
        'Sage' : {
            filter : '',
            translate : {ko:'세이지',en:'SAGE'},
            type : 'Sentinel',
            url : {
                바스트샷이미지:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/valorant/valorant_sage_min.png',
                기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/valorant/valorant_sage_normal.png',
            },        
        },
        'Cypher' : {
            filter : '',
            translate : {ko:'사이퍼',en:'CYPHER'},
            type : 'Sentinel',
            url : {
                바스트샷이미지:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/valorant/valorant_cypher_min.png',
                기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/valorant/valorant_cypher_normal.png',
            },        
        },
        'KillJoy' : {
            filter : '',
            translate : {ko:'킬조이',en:'KILLJOY'},
            type : 'Sentinel',
            url : {
                바스트샷이미지:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/valorant/valorant_killjoy_min.png',
                기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/valorant/valorant_killjoy_normal.png',
            },        
        },
        'Chamber' : {
            filter : '',
            translate : {ko:'체임버',en:'CHAMBER'},
            type : 'Sentinel',
            url : {
                바스트샷이미지:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/valorant/valorant_chamber_min.png',
                기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/valorant/valorant_chamber_normal.png',
            },        
        },
        'BrimStone' : {
            filter : '',
            translate : {ko:'브림스톤',en:'BRIMSTONE'},
            type : 'Controller',
            url : {
                바스트샷이미지:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/valorant/valorant_brimstone_min.png',
                기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/valorant/valorant_brimstone_normal.png',
            },        
        },
        'Omen' : {
            filter : '',
            translate : {ko:'오멘',en:'OMEN'},
            type : 'Controller',
            url : {
                바스트샷이미지:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/valorant/valorant_omen_min.png',
                기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/valorant/valorant_omen_normal.png',
            },        
        },
        'Viper' : {
            filter : '',
            translate : {ko:'바이퍼',en:'VIPER'},
            type : 'Controller',
            url : {
                바스트샷이미지:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/valorant/valorant_viper_min.png',
                기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/valorant/valorant_viper_normal.png',
            },        
        },
        'Astra' : {
            filter : '',
            translate : {ko:'아스트라',en:'ASTRA'},
            type : 'Controller',
            url : {
                바스트샷이미지:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/valorant/valorant_astra_min.png',
                기본스타일:'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/valorant/valorant_astra_normal.png',
            },        
        },
        
    },

    'LeagueOfLegends':{

    },
    
}


const PARTICLES = {
    // 'snow' : {
    //     images : ["https://www.svgrepo.com/show/222592/snowflake-snow.svg","https://www.svgrepo.com/show/222586/snowflake-snow.svg","https://www.svgrepo.com/show/222585/snowflake-snow.svg"]
    // },
    // 'game' : {
    //     images : [
    //         "https://www.blog.jonnycornwell.com/wp-content/uploads/2012/07/Smoke10.png",
    //         "https://upload.wikimedia.org/wikipedia/commons/thumb/5/55/Overwatch_circle_logo.svg/600px-Overwatch_circle_logo.svg.png",
    //         "https://images.emojiterra.com/google/noto-emoji/v2.034/512px/1f3f5.png",
    //         "https://media.discordapp.net/attachments/938456783746367538/974257404126560266/pngwing.com.png"]
    // },
    'valorant' : {
        images : [
            "https://media.discordapp.net/attachments/938456783746367538/976508794811064350/1.png"]
    },
    'game2' : {
        images : [
            "https://upload.wikimedia.org/wikipedia/commons/thumb/5/55/Overwatch_circle_logo.svg/600px-Overwatch_circle_logo.svg.png"]
    },
    // 'flare' : {
    //     images : [
    //         'https://blogger.googleusercontent.com/img/a/AVvXsEgRIZTplPmJHHo9JjgFBh80q8l4f-WmRebdm_jzJV8lJcWChYkTem4eGXJIwEkATvj6vFZYVcUgXWPq9zVWPbEIoqLc0lbqhbaEEQPJL14BlCOrVx8M_VajGirB65co61WLxAOeq3iJcU83vk0pj9KpL-5743Xoo3BClq1iKp2NN1YuYg6HeV623_Im=w0',
    //         'https://blogger.googleusercontent.com/img/a/AVvXsEiTjcS36n65tyrnmp7mzVrNPpxDWnhZxyR4x25HGqrcMH84epPKKAy-GCgonbzM13rXRlGuzZI9KkyHdPAGOxrizFe0edxJ2olk_Cf_AdggL0eX8ZH1flI6yHFtNbTiSnuNgtwjwykiEAKKu4nYkIDbrRVVJqiB9y_9HfasKulGtIX6Tj2DNdf8Jnl5=s1080',
    //     ]
    // },
    'overwatch' : {
        images : [
        'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/yeonvely/ana.png',
        'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/yeonvely/ashe.png',
        'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/yeonvely/baptiste.png',
        'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/yeonvely/bastion.png',
        'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/yeonvely/brigitte.png',
        'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/yeonvely/doomfist.png',
        'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/yeonvely/dva.png',
        'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/yeonvely/echo.png',
        'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/yeonvely/genji.png',
        'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/yeonvely/hanzo.png',
        'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/yeonvely/junkrat.png',
        'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/yeonvely/lucio.png',
        'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/yeonvely/mccree.png',
        'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/yeonvely/mei.png',
        'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/yeonvely/mercy.png',
        'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/yeonvely/moira.png',
        'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/yeonvely/orisa.png',
        'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/yeonvely/pharah.png',
        'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/yeonvely/reaper.png',
        'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/yeonvely/reinhardt.png',
        'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/yeonvely/roadhog.png',
        'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/yeonvely/sigma.png',
        'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/yeonvely/soldier-76.png',
        'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/yeonvely/sombra.png',
        'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/yeonvely/symmetra.png',
        'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/yeonvely/mercy.png',
        'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/yeonvely/torbjorn.png',
        'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/yeonvely/tracer.png',
        'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/yeonvely/widowmaker.png',
        'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/yeonvely/winston.png',
        'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/yeonvely/wrecking-ball.png',
        'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/yeonvely/zarya.png',
        'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/GameCharacters/overwatch/yeonvely/zenyatta.png',
        ]
    },
    'dot' : {
        images : [
        'https://media.discordapp.net/attachments/938456783746367538/974844451845468190/unknown.png',
        ]
    },
   
    'flower' : {
        images : [
            'https://media.discordapp.net/attachments/938456783746367538/974837094621020160/unknown.png',
            'https://media.discordapp.net/attachments/938456783746367538/974837094948143164/unknown.png',
            'https://media.discordapp.net/attachments/938456783746367538/974837095422115890/unknown.png',
            'https://media.discordapp.net/attachments/938456783746367538/974837095732502528/unknown.png',
        ]
    },


            // // images.src = "https://www.blog.jonnycornwell.com/wp-content/uploads/2012/07/Smoke10.png"
            // images.src = "https://upload.wikimedia.org/wikipedia/commons/thumb/5/55/Overwatch_circle_logo.svg/600px-Overwatch_circle_logo.svg.png"
            // // images.src = "https://images.emojiterra.com/google/noto-emoji/v2.034/512px/1f3f5.png"
            // // images.src = "https://media.discordapp.net/attachments/938456783746367538/974257404126560266/pngwing.com.png"
    
}