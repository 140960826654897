// 리엑트 연결
import React, { Component , Fragment } from 'react';

// 서비스 연결
import * as Utility from 'service/other/Utility'; 
////////////////////////////////////////////////////////
// store 연결
////////////////////////////////////////////////////////
import store from "store";

const templateClick = value => ({type:'templateClick',click : value})
const selectTemplate = id => ({type:'selectTemplate',select : id})
////////////////////////////////////////////////////////

// 테스트 구문
export function connectTest() {
    console.log('Eecoder : 연결테스트')
  }

/**
 * 위젯을 추가합니다.
 * @param {widget} type : 위젯의타입
 * @param {*} x : 위젯의 X 좌표 (기본값 20px);
 * @param {*} y : 위젯의 Y 좌표 (기본값 20px);
 * @param {*} set 
 * @returns 
 */
export function add(type,x,y,set,version) {

    // -- 위젯의 기본 값을 설정합니다. -- 

    const uuid = uuidMake()
    const widget = 
    { uuid,
      type,
      alias : null,
      animation :{ mountStart : null, mount :null, mountEnd : null},
      data :{
        position :{x,y},
        size : {},
        style : {},
        value : {},
        display : {},// 만약 보이지 않는 상태로 생성시 show : false
        meta :{},
      },
      version : version,
    }

    set = Utility.deepCopy(set)

    if(set.preset){
      widget.data['preset'] = set.preset    
    }
    
    if(set.value){
      widget.data['value'] = set.value    
    }

    console.log('add 엔코더',set, widget.data['value'])
    
    // -- 위젯의 기본 좌표값을 설정합니다. -- 
    if(set.option){widget.data.value['option'] = set.option}

    return widget
}

  ////////////////////////////////////////////////////////
  // addOption (특수 위젯 생성)
  // 드래그 앤 드롭 등 특별한 위젯 생성 상황일때 해당 방식으로 위젯을 생성합니다.
  ////////////////////////////////////////////////////////
export function addOption(type,option={}) {
  // option에서 모든 값 자동으로 합처주는 로직 만들기
  
  const state = store.getState(); // 현재 상태를 가져옵니다.
  const {widgetShow} = state; // 편의상 비구조화 할당
  let uuid = uuidMake()
  let alias = null
  let preset=option['preset']?option['preset']:null
  let animation = { mountStart : null, mount :null, mountEnd : null}
  let position={show : widgetShow}
  let size=typeof(option['size'])=='object'?option['size']:{}
  let style=typeof(option['style'])=='object'?option['style']:{}
  let value=typeof(option['value'])=='object'?option['value']:{}
  let display=typeof(option['display'])=='object'?option['display']:{}
  let meta=typeof(option['meta'])=='object'?option['meta']:{}
  let version = 1
  let data = {position,style,size,value,preset,display,meta}
  // console.log(type,version,uuid,alias,data,animation)
  return {type,version,uuid,alias,data,animation}
}

////////////////////////////////////////////////////////
// screenDataUpdate (스크린을 업데이트 해줍니다.)
// 위젯이 이동 할때 해당위치를 % 로 계산해서 이동시켜줍니다.
////////////////////////////////////////////////////////

const screenDataDispatch = screenData => ({type:'screenData',data : screenData})
const historyDispatch = history => ({type:'history',history})
let prevMode =null
export function screenDataUpdate(data,mode) {
  ////////////////////////////////////////////////////////
  // 모드가 변경될 경우 히스토리 등록해준다.
  ////////////////////////////////////////////////////////
  switch (mode) {
    // 위젯 추가시
    case 'delect':
      history(data)
      prevMode = mode
      break;
    // 위젯 추가시
    case 'paste':
      history(data)
      prevMode = mode
      break;
    case 'add':
      history(data)
      prevMode = mode
      break;
    // 드레그 이동시
    case 'dragMove':
      history(data)
      prevMode = mode
      break;
    case 'undo':
      prevMode = false
      break; 
    case 'Zindex':
      break;   
    default:
      if (prevMode != mode){
        history(data)
      }
      prevMode = mode
  }
  store.dispatch(screenDataDispatch(data)); 
}

////////////////////////////////////////////////////////
// history(히스토리에 등록할 스크린데이터)
// 히스토리 등록
////////////////////////////////////////////////////////
function history(data) {
  console.error('history')
  // const state = store.getState(); // 현재 상태를 가져옵니다.
  // const {screenData,history} = state; // 편의상 비구조화 할당
  // let newHistory = history.slice()
  // // 히스토리의 제한 갯수
  // if(newHistory.length>=20){
  //   newHistory.shift()
  // }
  // newHistory.push(screenData)

  // store.dispatch(historyDispatch(newHistory)); 
}

////////////////////////////////////////////////////////
// historyReset ()
// 히스토리를 삭제합니다
////////////////////////////////////////////////////////
export function historyReset() {
  let historyReset = []
  store.dispatch(historyDispatch(historyReset)); 
}

////////////////////////////////////////////////////////
// objectMove (해당객체,이밴트)
// 위젯이 이동 할때 해당위치를 % 로 계산해서 이동시켜줍니다.
////////////////////////////////////////////////////////
export function objectMove(id,screenData,x=0,y=0,w=0,h=0) {
  const state = store.getState(); // 현재 상태를 가져옵니다.
  const { editScreen ,broadCast} = state; // 편의상 비구조화 할당

  let temp = Utility.deepCopy(screenData)
  let screen = document.getElementById('EditScreen')
  let zoom = broadCast.size.width/(broadCast.size.width*editScreen.zoom)

  // 맵의 위치 보정을 합니다.
  const absoluteTop = window.pageYOffset + screen.getBoundingClientRect().top;
  const absoluteLeft = window.pageYOffset + screen.getBoundingClientRect().left;

  // 클릭한 좌표를 e.client 로 받아오고 위치를 보정해줍니다. 또 이를 % 로 나눕니다.
  let xPoint = ((x-w)-absoluteLeft)/screen.clientWidth*100
  let yPoint = ((y-h)-absoluteTop)/screen.clientHeight*100


  xPoint = xPoint * zoom
  yPoint = yPoint * zoom

  const agt = navigator.userAgent.toLowerCase(); 
  // if (agt.indexOf("mozilla/5.0") != -1) {
  //   xPoint = xPoint*-1
  //   yPoint = yPoint*-1
  // }
  // 소수점을 절단합니다.
  temp[id].data['x'] = xPoint.toFixed(2)
  temp[id].data['y'] = yPoint.toFixed(2)
  // store.dispatch(screenDataDispatch(temp));  
}

////////////////////////////////////////////////////////
// ParameterChange (해당객체,스크린데이터,변경할파라미터,파라미터의값)
// 위젯의 속성을 변경시켜줍니다.
////////////////////////////////////////////////////////
export function ParameterChange(id,parameterOBJ) {

  const state = store.getState(); // 현재 상태를 가져옵니다.
  const {screenData} = state; // 편의상 비구조화 할당
  // console.log(parameterOBJ,'포인터')


    const keys = Object.keys(parameterOBJ)
    let temp = Utility.deepCopy(screenData)
    if (screenData.length==0){
      return 0
    }
    for (let index = 0; index < keys.length; index++) {
      try {temp[id].data[keys[index]] = parameterOBJ[keys[index]]
      }finally{

      }
    }
    store.dispatch(screenDataDispatch(temp))

}

////////////////////////////////////////////////////////
// objectKeyEvent (해당객체,이밴트)
// 특정키가 눌리는경우 위젯의 위치를 변경해줍니다.
////////////////////////////////////////////////////////

export function objectKeyEvent(key,id,screenData) {
  
  let temp = Utility.deepCopy(screenData)
  // console.log(screenData,temp)
  if (key == 'ArrowLeft'){
      temp[id].data['x'] = Number(temp[id].data.x) - 0.25
      store.dispatch(screenDataDispatch(temp));
  }
  else if (key == 'ArrowRight'){
      temp[id].data['x'] = Number(temp[id].data.x) + 0.25
      store.dispatch(screenDataDispatch(temp));
  }
  else if (key == 'ArrowUp'){
      temp[id].data['y'] = Number(temp[id].data.y) - 0.25
      store.dispatch(screenDataDispatch(temp));
  }
  else if (key == 'ArrowDown'){
      temp[id].data['y'] = Number(temp[id].data.y) + 0.25
      store.dispatch(screenDataDispatch(temp)); 
  }
  else if (key == 'Escape'){
      store.dispatch(selectTemplate(null));  
  }
  else if (key == 'Backspace' || key == 'Delete'){
      store.dispatch(selectTemplate(null));  
      temp.splice(id,1);  
      store.dispatch(screenDataDispatch(temp));      
  }
  else
  {
      store.dispatch(templateClick(true));      
  }
}

////////////////////////////////////////////////////////
// percentValue ()
// 수치가 퍼센트인경우 px 값으로 변경해줍니다.
////////////////////////////////////////////////////////
export function percentValue (value,type){
  const state = store.getState(); // 현재 상태를 가져옵니다.
  const {broadCast} = state; // 편의상 비구조화 할당
  if (String(value).indexOf('%')!=-1){
      const percentValue = value.substring(0,(value).indexOf('%'))
      switch (type) {
          case 'width':
              return percentValue * (broadCast.size.width/100)
          case 'height':
              return percentValue * (broadCast.size.height/100)
          default:
              break;
      }
  }
}

////////////////////////////////////////////////////////
// uuidMake ()
// UUID를 생성해서 리턴합니다.
////////////////////////////////////////////////////////
function uuidMake() {
  function s4() {
    return ((1 + Math.random()) * 0x10000 | 0).toString(16).substring(1);
  }
  return s4() + s4() + s4() + s4() + s4() + s4() + s4() + s4();
}
