////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widgetEdit.scss"


// 컴포넌트 연결
import ColorModule from 'components/Edit/editModule/ColorModule'; // 색상 모듈
import SwitchModule from 'components/Edit/editModule/SwitchModule'; // 색상 모듈
import SelectModule from 'components/Edit/editModule/SelectModule';
import ImageButtonModule from 'components/Edit/editModule/ImageButtonModule'; // 이미지 버튼

// 서비스 연결
import * as WidgetData from 'service/value/WidgetData' ; 

// 이미지 연결
import amongus from 'assets/resource/amongus.png';
import overwatch from 'assets/resource/overwatch.svg';
import overwatch2 from 'assets/resource/overwatch2.svg';
import valorant from 'assets/resource/valorant.png';
import leagueoflegends from 'assets/resource/leagueoflegends.png';

import cover from 'assets/sidebar/image/cover.png';
import fill from 'assets/sidebar/image/fill.png';
import scaleDown from 'assets/sidebar/image/scaleDown.png';

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class GameCharactersEdit extends Component {
    
    state = {
    }

    valueChange = (name,value) =>{
        this.props.callBack('value',name,value)
    }
    presetChange = (name,value) =>{
        this.props.callBack('preset',name,value)
    }
    change = (name,value) =>{
        this.props.callBack('style',name,value)
    }

    GameChange = (name,value) =>{
        this.props.callBack('value',name,value)

        let defaultCharacters = null
        try {
            defaultCharacters = Object.keys(WidgetData.get("GameCharacters")[value])[0]
        } catch (error) {
            
        }
        this.props.multiCallBack([
            {property:'value',name:"game",value:value},
            {property:'value',name:"characters",value:defaultCharacters},
        ])
    }

    heroesList=()=>{
        return Object.keys(OVERWATCHELIST)
    }

    render() { 

        const Style = {
            label: {
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
            },
            textarea : {
                width: '100%',
                resize: 'none'
            }

        }
        let charactersList = ['없음']
        try {
            charactersList = Object.keys(WidgetData.get("GameCharacters")[this.props.data.value.game]) 
        } catch (error) {
            
        }
        let styleList = ['없음']
        try {
            styleList = Object.keys(WidgetData.get("GameCharacters")[this.props.data.value.game][this.props.data.value.characters].url) 
           
        } catch (error) {
            
        }

        return (
            <fieldset className="EditModuleField" style={Style.fieldset} >
                <legend style={Style.legend}> 게임종류 </legend>
                <ImageButtonModule value={this.props.data.style.objectFit} type ="objectFit"  data={[{val:"fill",image:fill},{val:"cover",image:cover},{val:"scale-down",image:scaleDown},]} title="이미지 정렬" change={this.change}/>
                <ImageButtonModule value={this.props.data.value.game} type ="game" appendClass={'roundWhite'} data={[{val:"amongus",image:amongus},{val:"overwatch",image:overwatch},{val:"valorant",image:valorant},{val:"leagueoflegends",image:leagueoflegends},]} title="게임"  change={this.GameChange}/>

                <legend style={Style.legend}> 캐릭터 종류 </legend>
                    <SelectModule value={this.props.data.value.characters} list={charactersList}  title="가로 크기" type ="characters" change={this.valueChange}/>
                <legend style={Style.legend}> 스타일 </legend>
                    <SelectModule value={this.props.data.value.imageStyle} list={styleList}  title="가로 크기" type ="imageStyle" change={this.valueChange}/>
                    <SwitchModule value={this.props.data.style.useFill} title="배경사용" type ="useFill" change={this.change}/>
                    <ColorModule value={this.props.data.style.fill} type ="fill" title="배경색" change={this.change} />
            </fieldset>
        );
    }
}

////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////
const OVERWATCHELIST ={'없음': null,'디바': 'dva','겐지': 'genji','둠피스트': 'doomfist','라인하르트': 'reinhardt','레킹볼': 'wrecking-ball','로드호그': 'roadhog','루시우': 'lucio','리퍼': 'reaper','맥크리': 'mccree','메르시': 'mercy','메이': 'mei','모이라': 'moira','바스티온': 'bastion','바티스트': 'baptiste','브리기테': 'brigitte','솔저:76': 'soldier-76','솜브라': 'sombra','시그마': 'sigma','시메트라': 'symmetra','아나': 'ana','애쉬': 'ashe','에코': 'echo','오리사': 'orisa','위도우메이커': 'widowmaker','윈스턴': 'winston','자리야': 'zarya','정크랫': 'junkrat','젠야타': 'zenyatta','토르비욘': 'torbjorn','트레이서': 'tracer','파라': 'pharah','한조': 'hanzo'}

const OVERWATCHELISTReturn ={'dva': '디바','genji':'겐지','doomfist': '둠피스트','reinhardt' :'라인하르트' ,'wrecking-ball' :'레킹볼','roadhog':'로드호그','lucio':'루시우','reaper':'리퍼','mccree':'맥크리','mercy':'메르시','mei':'메이','moira':'모이라','bastion':'바스티온','baptiste':'바티스트','brigitte':'브리기테','soldier-76':'솔저:76','sombra':'솜브라','sigma':'시그마','symmetra':'시메트라','ana':'아나','ashe':'애쉬','echo':'에코','orisa':'오리사','widowmaker':'위도우메이커','winston':'윈스턴' ,'zarya':'자리야','junkrat':'정크랫','zenyatta':'젠야타','torbjorn':'토르비욘','tracer':'트레이서','pharah':'파라','hanzo':'한조'}

////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////

const mapStateToProps = (state) => ({
    // exemple : state.exemple,
})

function mapDispatchToProps(dispatch){
    return {
    //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(GameCharactersEdit);
  