////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, {useState, useEffect, useRef } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { Route, Link } from 'react-router-dom';
import { useHistory } from "react-router";
// 라이브러리 
import { useGoogleLogin } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google'// 구글 로그인
// 설정 파일
import * as config from 'config/OverlapConfig'

import { withRouter } from 'react-router-dom';

import * as apiAccount from 'service/api/apiAccount'

// [컴포넌트] 위젯 
import GuideModuleHome from "pages/lobby/guide/GuideModuleHome" // 가이드 목록 화면
import GuideModulePage from "pages/lobby/guide/GuideModulePage" // 가이드 페이지
import StoreUpload from "components/store/StoreUpload"
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import 'sass/login.scss'

// 이미지 연결
import logoW from 'assets/logoW.svg';


// 서비스 연결
import * as overlap from 'service/api/overlap';
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

function LobbyGuide({subAddress,lobbyRoutes}) {
      ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////
    // state
    const [Mode, setMode] = useState('list');
    const [Select, setSelect] = useState(null);
    // ref
    // const { width, height, ref } = useResizeDetector();
    // const displayRef = useRef(null);
    
    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////


    let content;


    if(subAddress){
        content = <GuideModulePage subAddress={subAddress} lobbyRoutes={lobbyRoutes}/>
    }else{
        content = <GuideModuleHome lobbyRoutes={lobbyRoutes}/>
    }

    return (
        <div id='LobbyGuide' >
        <style jsx>{`${SpaceModuleStyle}${ContainerStyle}`}</style>
        {content}
    </div>
    );
  }

  export default LobbyGuide;
////////////////////////////////////////////////////////
// css 부분
////////////////////////////////////////////////////////
const SpaceModuleStyle = `


`;

const ContainerStyle = `
    #LobbyGuide {
        display: flex;
        width: 100%; 
        height: 100%;
        position: relative; 
        
    }

   
`;


// .OverlapListContainer .overlapListWrap:hover {
//     padding-right: 0px; /* 스크롤바의 너비 */
//     overflow: auto; /* 마우스 오버 시 스크롤바를 표시합니다. */
//   }

//   /* 크로스 브라우저 호환성을 위한 스크롤바 스타일링 */
//   .OverlapListContainer .overlapListWrap::-webkit-scrollbar {
//       width: 8px; /* 스크롤바의 너비 */
//   }

//   .OverlapListContainer .overlapListWrap::-webkit-scrollbar-thumb {
//       background-color: #888; /* 스크롤바의 색상 */
//       border-radius: 4px; /* 스크롤바의 모서리 */
//   }

//   .OverlapListContainer .overlapListWrap::-webkit-scrollbar-track {
//       background: ##2D2D2D; /* 스크롤 트랙의 색상 */
//   }

//   .OverlapListContainer .overlapListWrap{
//         display: flex;
//         flex : 1;
//         padding-right: 8px; /* 스크롤바의 너비 */
//         overflow: hidden; /* 스크롤바를 기본적으로 숨깁니다. */
//         padding-top: 70px;
//     }