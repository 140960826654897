////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { Route, Link } from 'react-router-dom';
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결

// 이미지 연결
import logoW from 'assets/logoW.svg';
import youtube from 'assets/main/lobby/social/youtube.png';
import twitch from 'assets/main/lobby/social/twitch.png';
import afreeca from 'assets/main/lobby/social/afreeca.png';
import facebook from 'assets/main/lobby/social/facebook.png';
import naver from 'assets/main/lobby/social/naver.png';
// import kakao from 'assets/main/lobby/social/kakao.png';
import edit from 'assets/main/edit.png';
import bot from 'assets/main/bot.png';
import control from 'assets/main/control.png';
import banner1 from 'assets/main/banner1.png';

// 컴포넌트 연결
// import Text from './template/Text'; // 메뉴 헤더
import Tutorial from "../Tutorial"
import MiniView from "components/viewer/MiniView"


// 서비스 연결
import * as Rest from 'service/api/rest' ; 
import * as Screen from 'service/api/screen' ; 
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class Home extends Component {
    
    state = {
        userId : ''
    }


    screenLode = () => {
        let response = Screen.broadcastScreenload()
        response
        .then((result) => {
            let resultData = [JSON.parse(result.data.body)]
            resultData = resultData[0]
            if (this.state.screenData!=resultData){
                this.setState({
                    screenData : resultData
                })
            }
        })
        .catch((error) => {
            console.log(error)
        })
    }


    render() { 
        return (
            <div className = "Lobby_innerPage" >
            <div id = "Lobby_Account" >
               <div className = "myAccount" >
                    <div className="myProfile">
                        <img className="profileImg" src={this.props?.account?.image??logoW}/>
                        <div className="profileData">
                            <h3  className="profileName">
                               {this.props?.account?.nick}
                            </h3>
                            <div>
                                얼리엑세스 테스터
                            </div>
                        </div>
                    </div>
                    <div>

                    </div>
                </div>

                <div className = "accountSync" >
                    <h3 className="title">
                        연동설정
                    </h3>
                    <div className = "socialsync google">
                        <div className = "social">
                            <div className = "socialLogo">
                                <img src={youtube}/>
                            </div>
                            <div className = "socialName">
                                유튜브
                            </div>
                        </div>
                        <div className = "body">

                        </div>
                    </div>
                    <div className = "socialsync twitch">
                        <div className = "social">
                            <div className = "socialLogo">
                                <img src={twitch}/>
                            </div>
                            <div className = "socialName">
                                트위치
                            </div>
                        </div>
                        <div className = "body">

                        </div>
                    </div>
                    <div className = "socialsync afreeca">
                        <div className = "social">
                            <div className = "socialLogo">
                                <img src={afreeca}/> 
                            </div>
                            <div className = "socialName">
                                아프리카
                            </div>
                        </div>
                        <div className = "body">

                        </div>
                    </div>
                    <div className = "socialsync facebook">
                        <div className = "social">
                            <div className = "socialLogo">
                                <img src={facebook}/>  
                            </div>
                            <div className = "socialName">
                                페이스북
                            </div>
                        </div>
                        <div className = "body">

                        </div>
                    </div>
                    <div className = "socialsync naver">
                        <div className = "social">
                            <div className = "socialLogo">
                                <img src={naver}/>  
                            </div>
                            <div className = "socialName">
                                네이버
                            </div>
                        </div>
                        <div className = "body">

                        </div>
                    </div>
                    <div className = "socialsync kakao">
                        <div className = "social">
                            <div className = "socialLogo">
                                
                            </div>
                            <div className = "socialName">
                                카카오
                            </div>
                        </div>
                        <div className = "body">

                        </div>
                    </div>
                </div>
                    <h3 className="title">
                    간단연동
                    </h3>
                <div className = "accountSet" >
                    <h3>
                            계정설정
                    </h3>
                        <div>
                            
                        </div>
                </div>
            </div>
            </div>
        )
    }
}
////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    screenData : state.screenData,
    storeScreenNo : state.screenNo,
    account : state.account,
})

function mapDispatchToProps(dispatch){
    return {
    //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(Home);
  