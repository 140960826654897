////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { useState, useEffect, useContext, Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// [프로바이더]
import EditContext from 'service/context/EditContext'; 

// SASS&CSS 연결
import "sass/widgetEdit.scss"

// 컴포넌트 연결
import WidgetDisplay from 'components/display/WidgetDisplay' // 위젯 전용 디스플레이
import PositionModule from './PositionModule'; // 위젯의 사이즈를 조정 하는 모듈입니다.
import ErrorModule from './ErrorModule'; // 잘못된 위젯의 값이 있을때 편집기를 대체합니다.

// 서비스 연결
import * as Editor from 'service/edit/EditService'; 
import * as ED from 'service/engine/decoder/EditorDecoder'; 
import * as DeCoder from 'service/engine/DeCoder'; 
// 이미지 연결

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////


/** [에디터] 드롭다운 모듈입니다.
* @param {widget} value : 선택된 값
* @param {widget} list : 드롭 다운 리스트
* @param {function} change : 드롭 다운이 적용되었을때
*/



function DropdownButtonV2({value,icon, list=[],change,className,direction,align,style}) {

    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////

    // set


    // state
    const [OpenList, setOpenList] = useState(false);

    // rel 

    ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////
    useEffect(() => {

        // 여기에 컴포넌트가 마운트되었을 때 실행할 코드를 작성합니다.

        return () => {
        // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
        };
      }, []);

    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////

    const DropdownList = () => {

        return list.map((item)=>(<div className={`item ${item?.value == value ? 'select':''}`}  onClick={()=>(change(item?.value))}>
            {getButtonContent(item)}
        </div>))
        
    }  

    const getButtonContent = (item) => {

        if("icon" in item){
            return Editor.icon({icon:item?.icon,size:20,lineHeight:25})
        }
        if("image" in item){
            return null
        }
        if("text" in item){
            return item?.text
        }
        else{
            return null
        }
    }


    const Direction = direction == 'vertical'?'vertical':'horizontal';

    // const Align = direction == 'vertical'?'vertical':'horizontal';
    


    return (
    <Fragment>
        <style jsx>{`${ModuleStyle}`}</style>
        <section className={'DropdownButtonV2 ' + className} style={style} onClick={()=>setOpenList(!OpenList)}>
            <div className='value'>
                {icon ? Editor.icon({icon,size:20,lineHeight:25}) : getButtonContent(list.find(item => item.value === value))}
            </div>
            {OpenList?
            <Fragment>
            <div className={`list ${Direction}`}>
                {DropdownList()}
            </div>
            <div className='cover' onMouseDown={()=>setOpenList(false)}>
            
            </div>
            </Fragment>
            :null}
        </section>
    </Fragment>
    );

}

export default DropdownButtonV2;

const ModuleStyle = `
.DropdownButtonV2{
    min-width : 50px;
    margin: 5px;
    background-color: #3F3F3F;
    border-radius: 2.5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2), 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    height : 25px;
    justify-content: center;
    align-items: center;
    position: relative;
}

.DropdownButtonV2 .value{

}

.DropdownButtonV2 .list{
    
    position: absolute;
    z-index : 3;

    background-color: #3F3F3F;
    border-radius: 2.5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2), 0 1px 3px rgba(0, 0, 0, 0.1);
    width: calc(100% - 16px);
    padding: 0px 8px; /* 스크롤바의 너비 */
    overflow-y: hidden;
    overflow-x: hidden;
}

.DropdownButtonV2 .list.vertical{
    width : 100%;
    max-height: 150px;
    overflow: auto;
    top : calc( 100% + 10px);
}

.DropdownButtonV2 .list.horizontal{
    display : flex;
    width : auto;
    overflow: auto;
    top : calc( 100% + 10px);
}

.DropdownButtonV2 .list .item {
    width : clac(100% - 10px);
    height: 20px;
    padding: 5px;
    margin : 5px 0px; 
    text-align: center;
    overflow: hidden;
    display : flex;
    justify-content: center;
    align-items: center;
    border-radius: 2.5px;
}

.DropdownButtonV2 .list .item:hover {
    background-color: #222;
}

.DropdownButtonV2 .list .select {
    background-color: #222;
}

.DropdownButtonV2 .cover {
    width : 100vw;
    height: 100vh;
    background-color : transparent;
    position: absolute;
    z-index : 2;
}

`;