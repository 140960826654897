////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, {useState, useEffect, useContext, useRef } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { Route, Link } from 'react-router-dom';
import { useHistory } from "react-router";
// 라이브러리 

// 설정 파일
import * as config from 'config/OverlapConfig'

// [컴포넌트] 위젯 
import MenuList from 'components/Edit/contextMenu/MenuList';//오버레이
// [프로바이더]
import EditContext from 'service/context/EditContext' ; 
// import store from "store";

// SASS&CSS 연결
// import 'sass/login.scss'

// 이미지 연결
// import logoW from 'assets/logoW.svg';


// 서비스 연결
import * as Widget from 'service/edit/WidgetService' ; 
import * as PageService from 'service/edit/PageService' ; 
import * as Action from 'service/edit/ActionService'; 

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

/** [위젯선택] 
 * 선택된 위젯을 컨트롤 할 수 있는 컨트롤러 입니다.
 * @param {number} x 위젯의 가로 시작 좌표
 * @param {number} y 위젯의 세로 시작 좌표
 * @param {number} w 위젯의 가로크기
 * @param {number} h 위젯의 세로크기
 * @param {string} uuid 위젯의 고유키
 * @param {number} scale 위젯의 고유키
 * @param {object} getwidget 위젯의 정보를 가저옵니다.
 * @param {function} setWidget 위젯의 정보를 수정합니다.
 * @param {boolean} visible 해당 위젯의 영역이 보이도록 합니다.
 * @param {*} option 디스플레이에 적용할 옵션
 * @returns 
 */


const ContextMenu =  React.memo(function ContextMenu({panelSize}) {
    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////

    //set
    const context = useContext(EditContext);
    // state
    const [SubContext, setSubContext] = useState(null); // 보조 컨텍스트 메뉴의 여부를 결정합니다. null 인경우 표시하지 않음

    ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////

      // useEffect(() => {

      //   return () => {

      //   };
      // },[]);

      useEffect(() => {
        setSubContext(null)
        // getOverlapList() 
        // Cleanup function (optional)
        return () => {

        };
      },[context.contextMenu]);


      
   
    ////////////////////////////////////////////////////////
    // Definition | 펑션및 컴포넌트에 공용으로 필요한 상수를 정의합니다.
    ////////////////////////////////////////////////////////

    // 컨텍스트가 등장할 위치입니다. 
    const ContextPotion = {
      top : context?.contextMenu?.posY??0,
      left : context?.contextMenu?.posX??0,
    }

    // 선택된 위젯의 아이디 입니다. (만약 위젯이 선택되지 않았으면 null 로 표시 됩니다)
    const WidgetID = context.selectWidget ?? null
    // 선택된 페이지의 아이디 입니다. (만약 페이지가 선택되지 않았으면 null 로 표시 됩니다)
    const PageID = context.selectPageUUID ?? null
    const ChannelID = context.view ?? null


    ////////////////////////////////////////////////////////
    // Fuction
    ////////////////////////////////////////////////////////


    /**
     * 클립보드에 위젯이 있는지 체크합니다. 클립보드에 위젯이 있으면 true 아니면 false 를 반환합니다.
     * @param {string} type : 클립보드에 복사된 타입 'widget' 또는 'page' 
     */
    const clipBoardCheck = (type) =>{
      if(context.clipBoard.hasOwnProperty(type)){
          if(context.clipBoard[type].length > 0){
              return true
          }
      }
      return false
    }
    
    ////////////////////////////////////////////////////////
    // Action | 컨텍스트 메뉴를 누를때 일어나는 액션에 대한 정의입니다.
    ////////////////////////////////////////////////////////
    const actionCopy = () =>{
      Widget.copy(context,WidgetID);
      context.hideContext();
    }


    
    const actionPaste = (positionX,positionY) =>{


  
      if(positionX&&positionY){

        Widget.paste(context,'widget',positionX,positionY);
      }else{
        Widget.paste(context,'widget');
      }

      context.hideContext();
    }

    const actionCopyAndPaste = (positionX,positionY) =>{

      Widget.copy(context,WidgetID);

      if(positionX&&positionY){

        Widget.paste(context,'widget',positionX,positionY);
      }else{
        Widget.paste(context,'widget');
      }


      context.hideContext();
    }

    const actionDelete = () =>{
      Widget.del(context);
      context.hideContext();
    }

    const actionFront = () =>{
      context.hideContext();
    }

    const actionBack = () =>{
      context.hideContext();
    }

    const actionShow = () =>{
      let targetWidget = Widget.get(context,context.contextMenu?.target);
  
      const visible = targetWidget?.display?.show;
   
      if(visible == false){
        targetWidget['display']['show'] = true;
        Widget.modify(context,context.contextMenu?.target,targetWidget);
      }
      context.hideContext();
    }

    const actionHide = () =>{
      if(context.contextMenu?.target){
        let targetWidget = Widget.get(context,context.contextMenu?.target);
  
        const visible = targetWidget?.display?.show;
        if(visible == true){
          targetWidget['display']['show'] = false;
          Widget.modify(context,context.contextMenu?.target,targetWidget);
        }
      }
      
      
      context.hideContext();
    }

    

    const pageDuplicate = async () =>{

      PageService.duplicate(context,context.contextMenu.target);
      context.hideContext();
    }

    const pageDelect = async () =>{
      PageService.del(context,context.contextMenu.target);
      context.hideContext();
    }

    const getScreenPoint = () => {

      const editMargin = {x: 20, y:20};
      // 에딧 화면은 ( 전체 사이즈 - 에딧 패널의 양옆 20px 마진 - 오른쪽 끝 편집페이지와 마진 20px = 60px
      const EditAreaWidth = panelSize?.EditContainer?.width - panelSize?.PreferencesPanel?.width - (editMargin.x*2 + 20)??0;
      const EditAreaHeight = panelSize?.EditContainer?.height - panelSize?.UtilityPanel?.height - (editMargin.y*2 + 20)??0;
      
  
      const referenceWidth = context.overlap.meta.resolution.width ;
      const referenceHeight = context.overlap.meta.resolution.height ;
  
      // 자식 컴포넌트 들의 스케일을 결정합니다.
      let scale = ( (panelSize?.EditContainer?.height - editMargin.x) / referenceHeight)??0;
      // 자식 컴포넌트에서 가장 긴 축으로 사용할 기준점을 선택합니다 (가로,세로)
      let dimension = 'height';
      const Ratio = referenceHeight/referenceWidth;
     
      if(EditAreaHeight>=EditAreaWidth*Ratio){
        dimension = 'width';
        scale = (EditAreaWidth / referenceWidth)??0;
      }else{
        dimension = 'height';
        scale = (EditAreaHeight / referenceHeight)??0;
      }
      
      const mouseX = context?.contextMenu?.posX-panelSize?.PreferencesPanel?.width-editMargin.x-20??0
  
      const mouseY = context?.contextMenu?.posY-editMargin.y??0

    }
    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////

    getScreenPoint();


    // 컨텍스트 메뉴가 비활성 되어 있으면 컨텍스트 메뉴를 표시하지 않습니다.
    if(!context.contextMenu){
        return null
    }

    let targetWidget 
    if(context,context.contextMenu?.type == "widget"){
      targetWidget = Widget.get(context,context.contextMenu?.target);
    }
    
    const visible = targetWidget?.display?.show;

    const DefaultContextMenu = [
      <MenuList key={'widget_paste'} icon="content_paste" text="붙여넣기" action={()=>{actionPaste()}} activate={clipBoardCheck('widget')}hover={() => setSubContext('paste')} />,
      <MenuList key={'page_history'} icon="history" text="되돌리기" action={()=>{console.log('준비중인 기능입니다.')}} activate={false} hover={() => setSubContext(null)} />
    ]

    const WidgetActionMenu = [
      visible? <MenuList icon="visibility_off" text="숨기기" action={()=>{actionHide()}} hover={() => setSubContext(null)}/> : <MenuList icon="visibility" text="보이기" action={()=>{actionShow()}}/>,
      <MenuList icon="content_copy" text="복사하기" action={()=>{actionCopy()}} hover={() => setSubContext('copy')}/>,
      <MenuList icon="delete" text="삭제하기" action={()=>{actionDelete()}} hover={() => setSubContext(null)}/>,
    ]  

    const WidgetOptions = [
      <MenuList icon="flip_to_front" text="순서변경" action={()=>{Widget.indexEnd(context,context.contextMenu?.target)}} hover={() => setSubContext('order')}/>,
      <MenuList icon="linked_services" text="위젯 공유" action={()=>{console.log('보이기')}}  activate={false} hover={() => setSubContext(null)}/>,
    ]

    const PasteOptions = [
      <MenuList icon="flip_to_front" text="순서변경" action={()=>{console.log('보이기')}} />,
      <MenuList icon="linked_services" text="위젯 공유" action={()=>{console.log('보이기')}} />
    ]


    const WidgetOrderSubOptions = [
      <MenuList icon="vertical_align_top" text="맨 앞으로" action={()=>{Widget.indexEnd(context,context.contextMenu?.target)}} />,
      <MenuList icon="arrow_upward" text="앞으로" action={()=>{Widget.indexSwap(context,context.contextMenu?.target,1)}} />,
      <MenuList icon="arrow_downward" text="뒤로" action={()=>{Widget.indexSwap(context,context.contextMenu?.target,-1)}}  />,
      <MenuList icon="vertical_align_bottom" text="맨 뒤로" action={()=>{Widget.indexFront(context,context.contextMenu?.target)}} />
    ]
    const Resolution = context?.overlap?.meta?.resolution
    const Size = targetWidget?.size
    
            // modify(positionX.category,positionX?.key,Number(Resolution)/2 - Number(Size)/2,uuid)
    const WidgetCopySubOptions = [
      <MenuList icon="content_paste_go" text="클립보드" action={()=>{actionCopy()}} />,
      <MenuList icon="content_copy" text="복사후 붙이기" action={()=>{actionCopyAndPaste(Number(targetWidget?.position?.x) + 20,Number(targetWidget?.position?.y) + 20)}} />,
      // <MenuList icon="content_copy" text="원위치 붙이기" action={()=>{actionCopyAndPaste()}} />,
    ]


    const WidgetPasteSubOptions = [
      <MenuList icon="content_copy" text="복사후 붙이기" action={()=>{actionCopyAndPaste()}} />,
      <MenuList icon="content_copy" text="원래위치" action={()=>{actionPaste()}} />,
    ]



    const liveIcon = <svg width="20" height="20"  viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><circle cx="10" cy="10" r="8" fill={ChannelID?'red':'gray'} stroke="#fff" stroke-width="3"></circle></svg>

    const PageActionMenu = [
      (context?.view?.view_pointer?.[0]?.uuid == context?.contextMenu?.target && context?.view?.view_status?.status != 'pause'?
      <MenuList icon="pause" text="송출 일시정지" action={()=>{setSubContext(null);Action.broadcastPause(context)}} activate={true} hover={() => setSubContext('broadcastStop')}/>:
      <MenuList icon={liveIcon} text="송출하기" action={()=>{setSubContext(null);Action.broadcast(context,context.contextMenu.target,0)}} activate={true} hover={() => setSubContext('broadcast')}/>
      ),
      <MenuList icon="content_copy" text="복제하기" action={()=>{pageDuplicate()}} activate={true}  hover={() => setSubContext(null)}/>,
      <MenuList icon="remove_selection" text="삭제하기" action={()=>{pageDelect()}} activate={true}  hover={() => setSubContext(null)}/>,
      <MenuList icon="flip_to_front" text="순서변경" action={()=>{console.log('보이기')}} activate={false}  hover={() => setSubContext(null)}/>,
    ]  

    const BroadcastStopSubOptions = [
      <MenuList icon="pause" text="일시정지" action={()=>{Action.broadcastPause(context)}} activate={true} />,
      <MenuList icon="stop" text="송출중지" action={()=>{Action.broadcastStop(context)}} activate={true} />
    ]  

    let ContextMenuList = []
    let subContextMenuList = []

    let ContextColor = '#3F3F3F';

    switch (context.contextMenu?.type) {
      case 'widget':
      
        // 컨텍스트 메뉴가 열린 위젯의 UUID 를 가저옵니다.
        const widgetID = context.contextMenu?.target;
        // 페이지 내 위젯 리스트를 가저옵니다. 
        const Page = context.nowPage()?.page_widget;
        // 선택된 위젯의 객체를 가저옵니다.
        const target = Page.filter(widget => widget.uuid === widgetID);
        // 선택된 위젯의 타입을 가저옵니다.
        const widgetType = target?.[0]?.type??null;
        ContextMenuList = [...DefaultContextMenu,<hr/>,...WidgetActionMenu,<hr/>,...WidgetOptions]
        break;
      case 'EditScreen':
        ContextMenuList = [...DefaultContextMenu,]
        break;
      case 'page':
          ContextMenuList = [...PageActionMenu,]
          ContextColor = "#222"
          break;    
      default:
        break;

    }
    
    switch (SubContext) {

      case 'order':
        subContextMenuList = [...WidgetOrderSubOptions,]
        break;  

      case 'copy':
        subContextMenuList = [...WidgetCopySubOptions,]
        break;  
      case 'paste':
        if(clipBoardCheck('widget')){
          // subContextMenuList = [...WidgetPasteSubOptions,]
        }
      break;  
      case 'broadcast':
        subContextMenuList = [ <MenuList icon={liveIcon} text="페이지 송출" action={()=>{Action.broadcast(context,context.contextMenu.target,0)}} activate={true} />,...BroadcastStopSubOptions,]
        break;  
      case 'broadcastStop':
        subContextMenuList = [...BroadcastStopSubOptions,]
        break;  
      default:
        break;

    }

  return(
   
    <div id="EditContextMenu" style={ContextPotion}>
        <style jsx>{`${ContextMenuStyle}`}</style>
   
        <ul id ="MainContextMenu">
        {/* <div>{Math.round(mouseX/scale)}</div> */}
          {ContextMenuList}

          {/* <MenuList icon="delete" text="삭제하기" action={()=>{console.log('눌림')}} /> */}
        </ul>

        <ul id ="SubContextMenu">
   
        {subContextMenuList}
          {/* <MenuList icon="delete" text="삭제하기" action={()=>{console.log('눌림')}} /> */}
        </ul>
        
    </div>
  )



  });

  export default ContextMenu;
////////////////////////////////////////////////////////
// css 부분
////////////////////////////////////////////////////////
const ContextMenuStyle = `
    #EditContextMenu{
      position: absolute;
      z-index:20;

    }

    #MainContextMenu{
      position: absolute;
      top : 0;
      left : 0;
      background-color: #363636;
      color : #fff;
      border-radius: 10px;
      box-shadow: 0 10px 20px rgba(22, 22, 22, 0.55);
    }
    #SubContextMenu{
      position: absolute;
      top : 0px;
      left : 160px;
      background-color: #363636;
      color : #fff;
      border-radius: 10px;
      box-shadow: 0 10px 20px rgba(64, 64, 64, 0.55);
    }
    
    #EditContextMenu ul, li {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }

    
    #EditContextMenu hr {
      border: none;      /* 경계선 제거 */
      height: 0;         /* 높이 초기화 */
      color: transparent; /* 색상을 투명하게 설정 */
      background-color: transparent; /* 배경 색상을 투명하게 설정 */
      border : 1px solid #2C2C2C;
    }

    #EditContextMenu .ContextMenuList{
      width: 130px;
      height : 20px;
      display : flex;
      margin : 5px;
      padding : 5px;
      border-radius: 10px;
    }

    #EditContextMenu .ContextMenuList:hover{
      background-color: #2C2C2C;
    }

    #EditContextMenu .ContextMenuList .MenuIcon{
      width: 20px;
      height : 20px;
      display : flex;
      overflow :hidden;
      justify-content : center;
      align-items : center;
    }

    #EditContextMenu .ContextMenuList .MenuText{
      width: 90px;
      margin-left : 10px;
      height : 20px;
      display : flex;
      overflow :hidden;
      justify-content : start;
      align-items : center;
    }

`;

////////////////////////////////////////////////////////
// 컨텍스트 메뉴
////////////////////////////////////////////////////////
