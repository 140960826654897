////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component, Fragment , useState, useEffect} from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { useHistory,useLocation } from 'react-router-dom';
import { SortableContainer, SortableElement,ReactSortable } from 'react-sortablejs';
// 라이브러리 



// SASS&CSS 연결
// import "sass/edit.scss"

// 이미지 연결
import chzzkLogo from 'assets/sidebar/chat/chzzk.jpg';

// 컴포넌트 연결
// import VariableTeam from 'components/edit/editVariable/varTeam/VariableTeam';//오버레이
import AppendFile from 'components/Edit/editPanel/append/AppendFile';
import VarTeamPlayer from 'components/Edit/editVariable/varTeam/VarTeamPlayer';

// 서비스 연결
import * as fileAPI from 'service/api/file' ; 
import * as Editor from 'service/edit/EditService'; 
import * as Utility from 'service/other/Utility';
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
function AppendProfileList({setAreaState}) {


    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////
    //set

    const [FileList, setFileList] = useState(false);
    const [TragetWidget, setTragetWidget] = useState(null);

    // state
    const [SelectTeam, setSelectTeam] = useState(null);

    // rel 

   ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////
    useEffect(() => {
        // 여기에 컴포넌트가 마운트되었을 때 실행할 코드를 작성합니다.
        // getFile();
        return () => {
        // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
        };
      }, []);



    return (
        <Fragment>
                <style jsx>{`${EditPageStyle}`}</style>
                <section id = 'AppendProfileList'>
                <div className='createProfile' onClick={setAreaState('create')}>
                  <span>
                  <img src={chzzkLogo} width={50} height={50} style={{margin:'2px 7px 2px 2px',borderRadius:'5px'}}/> 

                  </span>
                  <p>새로운 프로필 추가하기</p>
                </div>
                <div id="ProfileContainer">
                  {/* {list} */}
                  <div>

                      {/* <div>
                        프로필카드
                      </div> */}
                  </div>
                  </div>
           
              
                </section>
        </Fragment>
    );

}

////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    // mainScreen : state.mainScreen,
})

function mapDispatchToProps(dispatch){
    return {
        // initOverlap : (overlapData) => dispatch({type:'initOverlap',overlap : overlapData}),
    }
}

  // export default EditPage;
export default connect(mapStateToProps,mapDispatchToProps)(AppendProfileList);
  


  const EditPageStyle = `

  #AppendProfileList {
    display: flex;
    flex : 1 ;
    flex-direction: column;
  }
  #AppendProfileList #ProfileContainer {
    flex : 1 ;
    display: flex;

  }
  #AppendProfileList .createProfile {
    width: calc( 100% - 30px);
    height: 150px;
    margin : 15px;
    border-radius : 10px;
    background-color: #2c2c2c;
    display : flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  #AppendFileArea #FileListArea{
    flex : 1 ;
    display: grid;
    grid-template-columns: repeat(auto-fill, 150px);
    grid-template-rows: repeat(auto-fill, 150px);
    gap: 10px; /* 아이템 간 여백 */

    overflow-y: auto;

    box-sizing: border-box;
    padding : 10px;
    border-radius: 0px 0px 5px 5px;
  }

  #AppendFileArea #FileContainer .noneFile{
    flex : 1 ;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-aline : center;
    line-height : 30px;
  }

   
  #AppendFileArea #FileListArea .Files{
    width: 150px;
    height: 150px;
    background-color: #2c2c2c;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 24px;
    border-radius: 10px;
    position: relative;
  }
  #AppendFileArea #FileListArea .Files .FilePreview{
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    border-radius: 5px;
  }

  #AppendFileArea #FileListArea .Files .FilePreview .PreviewImage{
      width: 100%;
      height: 100%;
      display: flex;
      object-fit: cover;
      border-radius: 5px;
  }
  #AppendFileArea #FileListArea .Files .FileInfo{
     position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
  }

  #AppendFileArea #FileListArea .Files .FileInfo .FileName{
      font-size : 15px;
      width: calc(100% - 10px) ;
      height: 20px;
      line-height : 20px;
      display: flex;
      margin : 0px 5px ;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
  
  }

    #AppendFileArea #FileListArea .Files .FileInfo .FileType{
      position: absolute;
      right : 5px;
      top : 5px;
      border-radius : 2.5px;
      padding : 2.5px;
      height: 10px;
      line-height : 10px;
      font-size : 10px;
      background : #444444aa;
      font-family : 프리텐다드Regular;
}
      
  #AppendFileArea #FileListArea .Files .FileOption{
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
  }

    
  #AppendFileArea #GetFileArea{
      display: flex;
      justify-content: center;
      align-items: end;
      width: 100%;
      height: 80px;
      position: absolute;
      background:linear-gradient(to bottom, rgba(44,44,44,0), rgba(44,44,44,1));
      border-radius: 0px 0px 5px 5px;
      bottom : 0px;
  }
  #AppendFileArea #GetFileArea #AddFile{
      padding : 0px 10px;
      height: 40px;
      margin: 10px;
      border-radius : 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color : #3F3F3F;
      box-shadow: 
        0px 2px 4px rgba(0, 0, 0, 0.15),
        0px 6px 8px rgba(0, 0, 0, 0.15),
        0px 12px 16px rgba(73, 25, 25, 0.1),
        0px 18px 24px rgba(0, 0, 0, 0.15);
  }
  .fileUploadButton{
      display: flex;
      justify-content: center;
      align-items: center;
  }
  #AppendFileArea #GetFileArea #AddFile input[type="file"] {
    display: none;
  }
  

  `;
  
