////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, {useState, useEffect, useRef, Fragment, Component } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { Route, Link } from 'react-router-dom';
import { useHistory } from "react-router";
// 라이브러리 
import { useResizeDetector } from 'react-resize-detector';// 컴포넌트의 사이즈 감지

// 설정 파일
import * as config from 'config/OverlapConfig'

// [컴포넌트] 위젯 
// import MiniView from "components/viewer/MiniView"
// [리덕스]스토어 연결
// import store from "store";

// SASS&CSS 연결
// import 'sass/login.scss'

// 이미지 연결
// import logoW from 'assets/logoW.svg';


// 서비스 연결
import * as DeCoder from 'service/engine/DeCoder' ; 
import * as Editor from 'service/edit/EditService'; 
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

/** [] 
 * 도움말을 생성합니다.
 * @param {Component} children 설명문이 나올 컴포넌트
 * @param {string} guideText 설명할 텍스트
 * @param {boolean} vertical 세로형태의 말풍선을 사용합니다.
 * @param {boolean} active 세로형태의 말풍선을 사용합니다.
 * @param {number} width 말풍선의 세로 크기를 지정합니다.
 * @returns 
 */


 const GuideBalloon =  React.memo(function GuideBalloon({children, guideText, width=200, zIndex=50, direction = 'top', vertical=false ,test=false, active=false, background='#2C2C2C', margin=null, }) {
    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////
    // state
    const [Display, setDisplay] = useState(false);
    // ref
    // const { width, height, ref } = useResizeDetector();
    // const displayRef = useRef(null);

    ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////

      useEffect(() => {
        // console.log('Component did mount or update');
        // getOverlapList() 
        // Cleanup function (optional)
        return () => {
          // console.log('Component will unmount');
        };
      },[]);

    ////////////////////////////////////////////////////////
    // Fuction
    ////////////////////////////////////////////////////////

    
    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////

    const GuideStyle = `
.balloonTarget{
  position: relative;
  display: flex;
  justify-content: center;
}

.balloon{
  position: absolute;
  padding: 10px;
  background-color: #2C2C2C;
  color : #fff;
  border-radius: 5px;
}

.test{
  background-color: red !important;
}


.balloon.top{
  bottom : calc( 100% + 20px );
  
  left: 50%; 
  transform: translate(-50%,0px); /* 중앙 정렬 */
  text-align: center;
}

.balloon.topleft{
  bottom : calc( 100% + 20px );
  left: -10px;
  transform: translate(0%,0px); /* 중앙 정렬 */
}

.balloon.topright{
  bottom : calc( 100% + 20px );
  right: -30px;
  transform: translate(0%,0px); /* 중앙 정렬 */
  text-align: right;
}

.balloon.right{
  right : -10px;
  transform: translate(100%,0px); /* 중앙 정렬 */
}

.balloon::after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
}

.balloon.top::after {
  bottom : -10px;
  left: 50%; /* 꼬리의 왼쪽 모서리를 풍선의 중앙에 맞춥니다. */
  transform: translateX(-50%); /* 꼬리의 중앙을 풍선의 중앙에 맞춥니다. */

  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #2C2C2C;
}

.balloon.topleft::after {
  bottom : -10px;
  left: 20px ; /* 꼬리의 왼쪽 모서리를 풍선의 중앙에 맞춥니다. */
  transform: translateX(-50%); /* 꼬리의 중앙을 풍선의 중앙에 맞춥니다. */

  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #2C2C2C;
}

.balloon.topright::after {
  bottom : -10px;
  right: 20px ; /* 꼬리의 왼쪽 모서리를 풍선의 중앙에 맞춥니다. */
  transform: translateX(-50%); /* 꼬리의 중앙을 풍선의 중앙에 맞춥니다. */

  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #2C2C2C;
}


.balloon.right::after {
  top: 50%; /* 꼬리의 상단 모서리를 풍선의 중앙에 맞춥니다. */
  left: -10px; /* 삼각형이 풍선 왼쪽 바깥에 위치하도록 합니다. */
  transform: translateY(-50%); /* 꼬리의 중앙을 풍선의 중앙에 맞춥니다. */
  
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #2C2C2C; /* 왼쪽을 향한 삼각형 색상 지정 */
}
.activeBalloon{
  animation: floating 3s ease-in-out infinite; /* 지속시간과 타이밍 조정 가능 */
}
.balloon.horizontal {
  padding: 10px;
  background-color: #2C2C2C;
}


@keyframes floating {
  0%, 100% {
    transform: translateY(10px);
  }
  50% {
    transform: translateY(-10px); /* 높이 조정 가능 */
  }
}


`;


    return(
      <div className={'balloonTarget '} onMouseEnter={()=>setDisplay(true)} onMouseLeave={()=>setDisplay(false)}>
          <style jsx>{`${GuideStyle}`}</style>
        <Fragment>
        {Display||active ? 
          <div className={`balloon horizontal ${direction} ${active?"activeBalloon":''}`} style={{width,zIndex,margin,backgroundColor:background}}>
              <span className ='innerTitle' >
              {guideText}
              </span>
          </div>
        :
          null
        }
        </Fragment>
        {children}
      </div>

    )


  });

  export default GuideBalloon;
////////////////////////////////////////////////////////
// css 부분
////////////////////////////////////////////////////////


// .balloon.horizontal::after {
//   content: '';
//   position: absolute;
//   bottom: -10px;
//   left: 50%; /* 꼬리의 왼쪽 모서리를 풍선의 중앙에 맞춥니다. */
//   transform: translateX(-50%); /* 꼬리의 중앙을 풍선의 중앙에 맞춥니다. */
//   width: 0;
//   height: 0;
//   border-left: 10px solid transparent;
//   border-right: 10px solid transparent;
//   border-top: 10px solid #2C2C2C;
// }

// .balloon.vertical {
//   position: absolute;
//   bottom : 0;
//   z-index : 50; 
//   left: 50%; 
//   padding: 10px;
//   background-color: #2C2C2C;
//   color : #fff;
//   border-radius: 5px;
//   width: 200px;
//   text-align: center;
//   transform: translate(-50%,-50px); /* 중앙 정렬 */
// }

