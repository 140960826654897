////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
// import "/sass/widget.scss"

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class BackgroundWorker extends Component {
    
    static defaultProps = {
        BackgroundWorker: 'true',
      }


    render() {
        return (
            <div>

            </div>
        );

    }
}
////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    // editScreen : state.editScreen,
})

function mapDispatchToProps(dispatch){
    return {
        // templateClick : (value) => dispatch({type:'templateClick',click : value}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(BackgroundWorker);
  