////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/edit.scss"

// [프로바이더] 연결
import EditContext from 'service/context/EditContext' ; 
// 컴포넌트 연결

// 서비스 연결
import * as Rest from '../../../service/api/rest' ; 
import * as Screen from '../../../service/api/screen' ; 
import * as Modal from '../../../service/event/ModalControl';


////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class EditOverlapInfo extends Component {
    static contextType = EditContext;
    state = {
     
    }


    componentDidMount() {

    }

    componentWillUnmount() {

    }

    mouseCoordinate = () => {
       
    }

    render() { 


    const SELECTWIDGET = this.context.selectWidget;
    
    const widgetList = this.context.nowPage()?.page_widget;
    let selectWidgets = 0; 

    try {
        selectWidgets = widgetList.findIndex(item => item.uuid === SELECTWIDGET);
    } catch (error) {
        
    }



        return (
            <div id = "EditOverlapInfo" className="no-drag">
                    <div id = "PageInfo">
                        페이지 정보 : {this.props.screenNo} 
                    </div>
                    <div id = "OverlapTip">
                        {/* 팁 */}
                    </div>
                    <div id = "CoordinateInfo">
                        index : {selectWidgets} uuid : {SELECTWIDGET}
                        위치 : 
                        [{this.context.mouse.x}px ,
                        {this.context.mouse.y}px]
      
      
                        {/* 좌표 */}
                        {this.context.overlap.meta.resolution.width}x{this.context.overlap.meta.resolution.height} ({(this.context.editor.zoom*100).toFixed(2)}%)
                    </div>
                </div>
        );
    }
}
////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    broadCast : state.broadCast,
    editScreen : state.editScreen,
    screenNo : state.screenNo,
    screenData : state.screenData,
    editScreen : state.editScreen,
})

function mapDispatchToProps(dispatch){
    return {
    //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(EditOverlapInfo);
  