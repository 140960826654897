////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component, Fragment , useState, useEffect} from 'react';
import { useHistory } from 'react-router-dom';
// 이미지 연결
import logoW from 'assets/logoW.svg';
// 서비스 연결
import * as Editor from 'service/edit/EditService'; 
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
function EditerLoding() {


    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////

    //set
    const history = useHistory();
    // state

    // rel 

   ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////
    useEffect(() => {
    
        // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
        return () => {
          console.log('Component will unmount');
        };
      }, []);

      const backLobby = () => {
        history.replace('/main');
      };

    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////
    return (
        <Fragment>
                <style jsx>{`${EditPageStyle}`}</style>
                <div id="EditLodingPage">
                  <div id="LoadOption">
                    <button onClick={backLobby}>
                      {Editor.icon({icon:'chevron_left',size:25,lineHeight:30})}
                      <p>뒤로 이동</p>
                    </button>
                  </div>
                    <div  id="EditLodingCenter">
                      <img src={logoW}/>
                
                    </div>
                    <div id="EditLodingMsg">
                        프로젝트를 불러오고 있습니다.
                      </div>
                </div>
        </Fragment>
    );

}

////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////

  // export default EditPage;
export default EditerLoding;
  


  const EditPageStyle = `

  #EditLodingPage {
    background-color: #232323;
    font-family: '프리텐다드SemiBold';
    color: #fff;
    width: 100vw;
    height: 100vh;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  #EditLodingCenter{
    width: 400px;
    height: 400px;
    display:flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    animation: fadeIn ease 1s;
    animation-fill-mode: forwards;
    animation-iteration-count : infinite;
    animation-direction: alternate-reverse;
  }
  #EditLodingMsg{
    width: 400px;
    height: 50px;
    margin-top : 50px;
    border-radius: 15px;
    display:flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    animation: 1s fadeIn ease 1s;
    animation-fill-mode: forwards;
    background-color: #ffffff22;
  }
  #LoadOption{
    width: 50px;
    height: 200px;
    position: absolute;
    top: 20px;
    left: 20px;

  }


  #LoadOption button{
    display:flex;
    justify-content: center;
    align-items: center;
    width: 130px;
    height: 40px;
    background: #2C2C2C;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2), /* 상대적으로 가까운 그림자 */ 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    overflow: hidden;
  }

  #LoadOption button p{
    margin-left :10px;
    color :#fff;
    
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  `;