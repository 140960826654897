////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment ,Profiler} from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { Route, Link } from 'react-router-dom';
import produce from 'immer';
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/edit.scss"

// 컴포넌트 연결
// import TemplateScreenList from './TemplateScreenList'; // 스크린 리스트
import MiniView from 'components/viewer/MiniView'; // 미니뷰어

// 서비스 연결
import * as Modal from '../../../service/event/ModalControl';
import * as Template from '../../../service/event/template';
import * as Screen from '../../../service/api/screen';

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class TemplatePage extends Component {
    
    state = {
        show : true,
    }


    mainEdit = () => {
 
    }
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
      }
    componentDidMount() {
        this.setState({
            zoom : this.myRef.current.clientWidth*1.0/this.props.broadCast.size.width,
        })
    }


    appendScreen = ()=>{
        let response = Screen.add(JSON.stringify(this.props.data),this.props.alias)
        response
        .then((result) => {
            this.props.screenRefreshDispatch(true)
        })
        .catch((error) => {
            console.log(error)
        })
    }

    render() { 

        return (
            <div className='PageList'>
                <div className="PageScreenView" ref={this.myRef}>
                    <MiniView zoom={this.state.zoom} data={this.props.data} />  
                    <div className="PageAppend">
                        <div className="AppendButton" onClick={()=>this.appendScreen()}>
                            <h3>추가하기</h3>
                            {/* <button className='favorite'> 
                            ⭐️
                            </button>
                            <button className='zoom'> 
                            🔎
                            </button> */}
                        </div>
                    </div>
                </div>
      
                <div  className="PageInfo">
                    <h3>{this.props.alias}</h3>
                    <p>{this.props.info}</p>
                </div>
                
            </div>
        );
    }
}
////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    screenRefresh : state.screenRefresh,
    broadCast : state.broadCast,
})

function mapDispatchToProps(dispatch){
    return {
        screenRefreshDispatch : (state) => dispatch({type:'screenRefresh',state : state}),
    //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(TemplatePage);
  