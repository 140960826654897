////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component, Fragment , useState, useEffect, useRef} from 'react';

import { Route, Link } from 'react-router-dom';
// [리덕스]스토어 연결

// 이미지 연결
import logoW from 'assets/logoW.svg';
import PromotionVideo from 'assets/Promotion/overlapPromotion1.mp4';

// 컴포넌트 연결

import OverviewSection from 'components/Promotion/OverviewSection'; // 중요한 내용 색션
import BottomSection from 'components/Promotion/BottomSection'; // 바텀 부분 색션
import ShareNav from 'components/share/ShareNav'; // 오버랩의 특징 설명 색션

import AccountBanner from 'components/Promotion/AccountBanner'; // 어카운트 관련 배너
// SASS&CSS 연결
import "sass/frontPage.scss"
// 서비스 연결
import * as Editor from 'service/edit/EditService' ; 
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////


function ShareObject ({stream,streamID,stopShare}) {

    const [devices, setDevices] = useState([]);
    const [selectedDeviceId, setSelectedDeviceId] = useState('');
    const videoRef = useRef(null);

    
    useEffect(() => {
        videoRef.current.srcObject = stream

        try {  
        const videoTrack = stream.getVideoTracks()[0];
            if (videoTrack) {
       
                const { width, height } = videoTrack.getSettings();
                console.log('width',width,'height',height)
              }
        } catch (error) {


        }

    }, [stream]);
 
    return  (
    <Fragment>
    <style jsx>{`${ComponentStyle}`}</style>
        <div className='shareObject' onClick={()=>{stopShare(streamID)}}>
        <video ref={videoRef} autoPlay/>  
        {}
        </div>
    </Fragment>
    );

}

export default ShareObject;



const ComponentStyle = `

.shareObject{
    width : 960px;
    height : 540px;
    background-color: #222;
    border-radius: 10px;
}

.shareObject video {
    width : 960px;
    height : 540px;
}

`;