// 프로모션 페이지 - 헤더색션 히어로 색션

////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component, Fragment , useState, useEffect, useRef} from 'react';

import { Route, Link } from 'react-router-dom';
// [리덕스]스토어 연결

// 이미지 연결
import logoW from 'assets/logoW.svg';
import PromotionVideo from 'assets/Promotion/overlapPromotion1.mp4';
// SASS&CSS 연결
import "sass/frontPage.scss"
import * as Editor from 'service/edit/EditService'; 
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

function HeroSection () {
    
    return  (
    <Fragment>
    <style jsx>{`${ComponentStyle}`}</style>
        <section id='HeroSection'>
            <div className='HigherLayer' >
                <div className='HeroContainer'>
                <p className='title'>바로 만드는 나만의 방송 화면, 손쉽게 시작하세요!</p>
                <p className='subTitle'>오버랩은 스트리밍을 쉽고 강력하게 관리해주는 서비스입니다.</p>
                </div>
                <Link className="startLink" to="/login">
                    <button  className='loginButton'>
                        지금 시작하기
                    </button>    
                </Link>

                <div className='wheelGuide'>
                {Editor.icon({icon:'keyboard_arrow_down',size:35,lineHeight:50})}
                </div> 
            </div>
            <div className='LowerLayer'>
            <video src={PromotionVideo} width={'100%'} height={'100%'} autoPlay={true} muted loop />
            </div>

        </section>
      </Fragment>
    );

}

export default HeroSection;



const ComponentStyle = `

#HeroSection{
    display :flex;
    flex-direction: column;
    width : 100vw;
    height : 100vh;
    color : #fff;
    background-color : #606060;
    font-family: '프리텐다드ExtraBold';
    position: relative;
}
.LowerLayer{
    position: absolute;
    width : 100vw;
    height : 100vh;
    top : 0;
    left : 0;
    z-index: 0;
}

.LowerLayer video {
    width : 100vw;
    height : 100vh;
    object-fit : cover;
}
.HigherLayer{
    position: absolute;
    background-color : #33333399;
    width : 100vw;
    height : 100vh;
    top : 0;
    left : 0;
    z-index: 1;
    display :flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.HeroContainer{
    width : 100vw;
    display :flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.HeroContainer .title{
    font-size : 40px;
    margin-bottom : 5px;
    text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.7); /* 텍스트 그림자 추가 */
}
.loginButton{
    background-color : #fff;
    width : 180px;
    height : 50px;
    margin : 40px;
        font-size : 20px;
    border-radius : 5px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3); /* 버튼에 그림자 추가 */
}    
.HeroContainer .subTitle{
    font-size : 25px;
    text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.7); /* 텍스트 그림자 추가 */
}

.HigherLayer .wheelGuide{
    position: absolute;
    bottom : 50px;
    animation: moveUpDown 1s infinite alternate;
}


@keyframes moveUpDown {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(-30px);
  }
}



/* 너비가 1200px 이하인 경우 */
@media (max-width: 1200px) {
    .HeroContainer .title{
        font-size : 35px;
        margin-bottom : 5px;
        text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.7); /* 텍스트 그림자 추가 */
    }
    .HeroContainer .subTitle{
        font-size : 20px;
        text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.7); /* 텍스트 그림자 추가 */
    }
}

/* 너비가 1200px 이하인 경우 */
@media (max-width: 768px) {
    .HeroContainer{
        width : calc( 100vw - 80px);
        display :flex;
        height : 50vh;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        margin-bottom : 20px;
    }
    .HeroContainer .title{
        font-size : 30px;
        margin-bottom : 20px;
        text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.7); /* 텍스트 그림자 추가 */
    }
    .HeroContainer .subTitle{
        font-size : 14px;
        text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.7); /* 텍스트 그림자 추가 */
    }
    .HigherLayer .wheelGuide{
        bottom : 40px;
    }
}

`;


