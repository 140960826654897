////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widgetEdit.scss"


// 컴포넌트 연결
import ColorModule from '../editModule/ColorModule'; // 색상 모듈
import SwitchModule from '../editModule/SwitchModule'; // 색상 모듈
import SelectModule from '../editModule/SelectModule';
import SentenceBlurModule from '../editModule/SentenceBlurModule';
import SentenceModule from '../editModule/SentenceModule';
import ListModule from '../editModule/ListModule';
import RadioButtonModule from '../editModule/RadioButtonModule';
// 서비스 연결

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class OverwatchRankEdit extends Component {
  
    static defaultProps = {
        editName: 'OverwatchRankEdit',
    }

    state = {
        heroDataList :this.props.data.value.heroData,
        dataAdd : false,
    }

    valueChange = (name,value) =>{
        this.props.callBack('value',name,value)
    }
    presetChange = (name,value) =>{
        this.props.callBack('preset',name,value)
    }

    change = (name,value) =>{
        this.props.callBack('style',name,value)
    }

    heroesList=()=>{
        // return ['dva','genji','doomfist','reinhardt','wrecking-ball','roadhog','lucio','reaper']
        return Object.keys(OVERWATCHELIST)
    }

    dataAdd = (name,value) => {
        let newDataList =  []
        if(this.state.heroDataList){
            newDataList = this.state.heroDataList.slice()
        }
        newDataList.push({
            hero : value[0],
            type : value[1],
            title : value[2],
            detail : value[3],
            name : value[4],
            })
        this.setState({
            dataAdd : false,
            heroDataList : newDataList,
        },this.dataChange(newDataList))
    }

    dataDelect = (index)=>{
        let newDataList = this.state.heroDataList.slice() 
        newDataList.splice(index,1)
        this.setState({
            heroDataList : newDataList,
        },this.dataChange(newDataList))
    }

    dataChange = (newDataList) =>{
        if(newDataList.length== 0){
            newDataList = null
        }
        this.props.callBack('value','heroData',newDataList)
    }

    dataList = () =>{
        if (this.state.heroDataList){
            let list = this.state.heroDataList.map(
                (info,index) => (
                <div className="myDataList">
                    <div className="upper"><span> 
                        <img src ={'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/'+info.hero+'.png'}/><h3>{info.name}</h3></span><button className="dataDelect" onClick={()=>this.dataDelect(index)}>x</button></div>
                    <div className="lower">
                    </div>
                </div> 
                ) 
            );    
            return list
        }else{
            return (
            <div className="dataAddAlert">
                <button className="newDataAdd" onClick={()=>this.setState({dataAdd : true})} >데이터추가</button>
                <p>버튼을 눌러서 프로필 데이터를 추가해보세요</p>
            </div>
            )
        }
    }

    render() { 
        const Style = {
            label: {
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
            },
            textarea : {
                width: '100%',
                resize: 'none'
            }

        }
        return (


            <Fragment>
            <fieldset className="EditModuleField" style={Style.fieldset} >
                <legend style={Style.legend}> 프로필 설정 </legend>
                    <SentenceBlurModule value={this.props.data.value.battleCode} placeholder={"배틀태그#1234"} title="배틀태그" type ="battleCode" change={this.valueChange} callBackMode="onblur"/>
                    <RadioButtonModule value={this.props.data.value.heroPosition} list={[{name:'돌격',value:'tanker'},{name:'공격',value:'dps'},{name:'지원',value:'suppot'}]} type ="heroPosition" title="포지션"  change={this.valueChange} />
                    <SwitchModule value={this.props.data.value.nameShow} title="아이디 표시" type ="nameShow" change={this.valueChange}/>
                    <SwitchModule value={this.props.data.value.pointShow} title="점수 표시" type ="pointShow" change={this.valueChange}/>
                    <SwitchModule value={this.props.data.value.tierShow} title="티어 표시" type ="tierShow" change={this.valueChange}/>
            </fieldset>
            <details>
                <summary>
                    별명 사용하기
                </summary>
       
                <fieldset className="EditModuleField" style={Style.fieldset} >
                <p className="notice">
                    플레이어를 시청자에게 알려주기<br/> 위해 별명을 사용할 수 있습니다. 
                </p>
                    <legend style={Style.legend} > 별명 </legend>
                    <SentenceModule value={this.props.data.value.userAlias} placeholder={"유저의 별명"} title="별명" type ="userAlias" change={this.valueChange} callBackMode="onblur"/>
                </fieldset>
            </details>
            </Fragment>
        );
    }
}

////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////
const OVERWATCHELIST ={'없음': null,'디바': 'dva','겐지': 'genji','둠피스트': 'doomfist','라인하르트': 'reinhardt','레킹볼': 'wrecking-ball','로드호그': 'roadhog','루시우': 'lucio','리퍼': 'reaper','맥크리': 'mccree','메르시': 'mercy','메이': 'mei','모이라': 'moira','바스티온': 'bastion','바티스트': 'baptiste','브리기테': 'brigitte','솔저:76': 'soldier-76','솜브라': 'sombra','시그마': 'sigma','시메트라': 'symmetra','아나': 'ana','애쉬': 'ashe','에코': 'echo','오리사': 'orisa','위도우메이커': 'widowmaker','윈스턴': 'winston','자리야': 'zarya','정크랫': 'junkrat','젠야타': 'zenyatta','토르비욘': 'torbjorn','트레이서': 'tracer','파라': 'pharah','한조': 'hanzo'}

const OVERWATCHELISTReturn ={'dva': '디바','genji':'겐지','doomfist': '둠피스트','reinhardt' :'라인하르트' ,'wrecking-ball' :'레킹볼','roadhog':'로드호그','lucio':'루시우','reaper':'리퍼','mccree':'맥크리','mercy':'메르시','mei':'메이','moira':'모이라','bastion':'바스티온','baptiste':'바티스트','brigitte':'브리기테','soldier-76':'솔저:76','sombra':'솜브라','sigma':'시그마','symmetra':'시메트라','ana':'아나','ashe':'애쉬','echo':'에코','orisa':'오리사','widowmaker':'위도우메이커','winston':'윈스턴' ,'zarya':'자리야','junkrat':'정크랫','zenyatta':'젠야타','torbjorn':'토르비욘','tracer':'트레이서','pharah':'파라','hanzo':'한조'}

const OVERWATCHDATALIST = [
    {
        name: '[모든영웅] 모스트영웅보기',
        value : ['other','모스트영웅','모스트영웅',null,'[모든영웅] 모스트영웅보기'],
        info : '최다 플레이 영웅',
        img : '',
    },
    {
        name: '[탱커] 모스트보기',
        value : ['other','탱커모스트영웅','탱커모스트영웅',null,'[탱커] 모스트보기'],
        info : '탱커 최다 플레이 영웅',
        img : '',
    },
    {
        name: '[딜러] 모스트보기',
        value : ['other','딜러모스트영웅','딜러모스트영웅',null,'[딜러] 모스트보기'],
        info : '딜러 최다 플레이 영웅',
        img : '',
    },
    {
        name: '[힐러] 모스트보기',
        value : ['other','힐러모스트영웅','힐러모스트영웅',null,'[힐러] 모스트보기'],
        info : '힐러 최다 플레이 영웅',
        img : '',
    },
    {
        name: '[모든영웅] 결정타',
        value : ['all','결정타 - 10분당 평균','결정타','10분당 평균','[모든영웅] 결정타'],
        info : '모든 영웅의 10분당 결정타(막타)',
        img : '',
    },
    {
        name: '[모든영웅] 단독처치',
        value : ['all','단독 처치 - 10분당 평균','단독처치','10분당 평균','[모든영웅] 단독처치'],
        info : '모든 영웅의 10분당 단독처치수',
        img : '',
    },
    {
        name: '[모든영웅] 방벽피해량',
        value : ['all','방벽에 준 피해 - 10분당 평균','방벽피해량','10분당 평균','[모든영웅] 방벽피해량'],
        info : '모든 영웅의 10분당 방벽피해량 ',
        img : '',
    },
    {
        name: '[모든영웅] 영웅피해량',
        value : ['all','영웅에게 준 피해 - 10분당 평균','영웅피해량','10분당 평균','[모든영웅] 영웅피해량'],
        info : '모든 영웅의 10분당 영웅피해량',
        img : '',
    },
    {
        name: '[모든영웅] 모든피해량',
        value : ['all','준 모든 피해 - 10분당 평균','모든피해량','10분당 평균','[모든영웅] 모든피해량'],
        info : '모든 영웅의 10분당 모든피해량',
        img : '',
    },
    {
        name: '[모든영웅] 처치수',
        value : ['all','준 모든 피해 - 10분당 평균','처치수','10분당 평균','[모든영웅] 처치수'],
        info : '모든 영웅의 10분당 처치수 ',
        img : '',
    },
    {
        name: '[모든영웅] 치유량',
        value : ['all','치유량 - 10분당 평균','치유량','10분당 평균','[겐지] 치유량'],
        info : '모든 영웅의 10분당 치유량',
        img : '',
    },
    {
        name: '[모든영웅] 임무 기여 시간',
        value : ['all','임무 기여 시간 - 10분당 평균','치명타','10분당 평균','[겐지] 치명타'],
        info : '모든 영웅의 10분당 평균 임무 기여 시간',
        img : '',
    },
    {
        name: '[모든영웅] 임무 기여 처치',
        value : ['all','임무 기여 처치 - 10분당 평균','치명타','10분당 평균','[겐지] 치명타'],
        info : '모든 영웅의 10분당 평균 임무 기여 처치',
        img : '',
    },
    {
        name: '[모든영웅] 연속처지 최고기록',
        value : ['all','연속 처치 - 최고기록','연속처지수','게임내 최고기록','[모든영웅] 연속처지 최고기록'],
        info : '모든 영웅의 연속처지 최고기록',
        img : '',
    },
    
    // 겐지 
    {
        name: '[겐지] 플레이 시간',
        value : ['genji','플레이 시간','플레이 시간',null,'[겐지] 플레이시간'],
        info : '겐지의 플레이 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/genji.png',
    },
    {
        name: '[겐지] 승률',
        value : ['genji','승률','승률' ,null,'[겐지] 승률'],
        info : '겐지 영웅의 승률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/genji.png',
    },
    {
        name: '[겐지] 목숨당 처치',
        value : ['genji','목숨당 처치','목숨당 처치','게임당 평균','[겐지] 목숨당 처치'],
        info : '겐지의 목숨당 처치횟수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/genji.png',
    },
    {
        name: '[겐지] 명중률',
        value : ['genji','명중률','명중률','게임당 평균','[겐지] 명중률'],
        info : '겐지의 명중률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/genji.png',
    },
    {
        name: '[겐지] 치명타',
        value : ['genji','치명타 - 10분당 평균','치명타','10분당 평균','[겐지] 치명타'],
        info : '겐지의 10분당 평균 치명타 (헤드샷)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/genji.png',
    },
    {
        name: '[겐지] 치명타 명중률',
        value : ['genji','치명타 명중률','치명타 명중률','게임당 평균','[겐지] 치명타명중률'],
        info : '겐지의 치명타 명중률 (헤드샷 명중률)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/genji.png',
    },
    {
        name: '[겐지] 처치수',
        value : ['genji','처치 - 10분당 평균','처치수','10분당 평균','[겐지] 처치수'],
        info : '겐지의 10분당 평균 처지수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/genji.png',
    },
    {
        name: '[겐지] 죽은횟수',
        value : ['genji','죽음 - 10분당 평균','죽은 횟수','10분당 평균','[겐지] 죽은횟수'],
        info : '겐지의 10분당 평균 죽은횟수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/genji.png',
    },
    {
        name: '[겐지] 치유량',
        value : ['genji','치유량 - 10분당 평균','치유량','10분당 평균','[겐지] 치유량'],
        info : '겐지의 10분당 평균 치유량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/genji.png',
    },
    {
        name: '[겐지] 결정타',
        value : ['genji','결정타 - 10분당 평균','결정타 수','10분당 평균','[겐지] 결정타'],
        info : '겐지의 10분당 평균 결정타 (막타)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/genji.png',
    },
    {
        name: '[겐지] 단독처치',
        value : ['genji','단독 처치 - 10분당 평균','단독 처치수','10분당 평균','[겐지] 단독처치'],
        info : '겐지의 10분당 단독 처치수 (솔로킬 수)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/genji.png',
    },
    {
        name: '[겐지] 영웅피해량',
        value : ['genji','영웅에게 준 피해 - 10분당 평균','영웅에게 준 피해량','10분당 평균','[겐지] 영웅피해량'],
        info : '겐지의 10분당 상대 영웅에 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/genji.png',
    },
    {
        name: '[겐지] 방벽피해량',
        value : ['genji','방벽에 준 피해 - 10분당 평균','방벽에게 준 피해량','10분당 평균','[겐지] 방벽피해량'],
        info : '겐지의 10분당 방벽에 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/genji.png',
    },
    {
        name: '[겐지] 모든피해량',
        value : ['genji','준 모든 피해 - 10분당 평균','준 모든 피해량','10분당 평균','[겐지] 모든피해량'],
        info : '겐지의 10분당 방벽과 상대 영웅 에게 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/genji.png',
    },
    {
        name: '[겐지] 임무 기여 시간',
        value : ['genji','임무 기여 시간 - 10분당 평균','임무 기여 시간','10분당 평균','[겐지] 임무기여시간'],
        info : '겐지의 10분당 임무 기여 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/genji.png',
    },
    {
        name: '[겐지] 임무 기여 처치',
        value : ['genji','임무 기여 처치 - 10분당 평균','임무 기여 처치','10분당 평균','[겐지] 임무기여처치'],
        info : '겐지의 10분당 임무 기여 처지수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/genji.png',
    },
    {
        name: '[겐지] 폭주 시간',
        value : ['genji','폭주 시간 - 10분당 평균','폭주 시간','10분당 평균','[겐지] 폭주 시간'],
        info : '겐지의 10분당 폭주 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/genji.png',
    },
    {
        name: '[겐지] 근접 공격 적중률',
        value : ['genji','근접 공격 적중률','근접 공격 적중률',null,'[겐지] 근접 공격 적중률'],
        info : '겐지의 근접 공격 적중률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/genji.png',
    },
    {
        name: '[겐지] 용검으로 처치',
        value : ['genji','용검으로 처치 - 10분당 평균','용검으로 처치','10분당 평균','[겐지] 용검으로 처치'],
        info : '겐지의 10분당 용검으로 처지수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/genji.png',
    },
    {
        name: '[겐지] 튕겨낸 피해',
        value : ['genji','폭주 시간 - 10분당 평균','폭주 시간','10분당 평균','[겐지] 튕겨낸 피해'],
        info : '겐지의 10분당 임무 기여 처지수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/genji.png',
    },
    // 둠피스트 
    {
        name: '[둠피스트] 플레이 시간',
        value : ['doomfist','플레이 시간','플레이 시간',null,'[둠피스트] 플레이시간'],
        info : '둠피스트의 플레이 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/doomfist.png',
    },
    {
        name: '[둠피스트] 승률',
        value : ['doomfist','승률','승률' ,null,'[둠피스트] 승률'],
        info : '둠피스트 영웅의 승률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/doomfist.png',
    },
    {
        name: '[둠피스트] 목숨당 처치',
        value : ['doomfist','목숨당 처치','목숨당 처치','게임당 평균','[둠피스트] 목숨당 처치'],
        info : '둠피스트의 목숨당 처치횟수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/doomfist.png',
    },
    {
        name: '[둠피스트] 명중률',
        value : ['doomfist','명중률','명중률','게임당 평균','[둠피스트] 명중률'],
        info : '둠피스트의 명중률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/doomfist.png',
    },
    {
        name: '[둠피스트] 치명타',
        value : ['doomfist','치명타 - 10분당 평균','치명타','10분당 평균','[둠피스트] 치명타'],
        info : '둠피스트의 10분당 평균 치명타 (헤드샷)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/doomfist.png',
    },
    {
        name: '[둠피스트] 치명타 명중률',
        value : ['doomfist','치명타 명중률','치명타 명중률','게임당 평균','[둠피스트] 치명타명중률'],
        info : '둠피스트의 치명타 명중률 (헤드샷 명중률)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/doomfist.png',
    },
    {
        name: '[둠피스트] 처치수',
        value : ['doomfist','처치 - 10분당 평균','처치수','10분당 평균','[둠피스트] 처치수'],
        info : '둠피스트의 10분당 평균 처지수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/doomfist.png',
    },
    {
        name: '[둠피스트] 죽은횟수',
        value : ['doomfist','죽음 - 10분당 평균','죽은 횟수','10분당 평균','[둠피스트] 죽은횟수'],
        info : '둠피스트의 10분당 평균 죽은횟수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/doomfist.png',
    },
    {
        name: '[둠피스트] 치유량',
        value : ['doomfist','치유량 - 10분당 평균','치유량','10분당 평균','[둠피스트] 치유량'],
        info : '둠피스트의 10분당 평균 치유량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/doomfist.png',
    },
    {
        name: '[둠피스트] 결정타',
        value : ['doomfist','결정타 - 10분당 평균','결정타 수','10분당 평균','[둠피스트] 결정타'],
        info : '둠피스트의 10분당 평균 결정타 (막타)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/doomfist.png',
    },
    {
        name: '[둠피스트] 단독처치',
        value : ['doomfist','단독 처치 - 10분당 평균','단독 처치수','10분당 평균','[둠피스트] 단독처치'],
        info : '둠피스트의 10분당 단독 처치수 (솔로킬 수)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/doomfist.png',
    },
    {
        name: '[둠피스트] 영웅피해량',
        value : ['doomfist','영웅에게 준 피해 - 10분당 평균','영웅에게 준 피해량','10분당 평균','[둠피스트] 영웅피해량'],
        info : '둠피스트의 10분당 상대 영웅에 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/doomfist.png',
    },
    {
        name: '[둠피스트] 방벽피해량',
        value : ['doomfist','방벽에 준 피해 - 10분당 평균','방벽에게 준 피해량','10분당 평균','[둠피스트] 방벽피해량'],
        info : '둠피스트의 10분당 방벽에 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/doomfist.png',
    },
    {
        name: '[둠피스트] 모든피해량',
        value : ['doomfist','준 모든 피해 - 10분당 평균','준 모든 피해량','10분당 평균','[둠피스트] 모든피해량'],
        info : '둠피스트의 10분당 방벽과 상대 영웅 에게 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/doomfist.png',
    },
    {
        name: '[둠피스트] 임무 기여 시간',
        value : ['doomfist','임무 기여 시간 - 10분당 평균','임무 기여 시간','10분당 평균','[둠피스트] 임무기여시간'],
        info : '둠피스트의 10분당 임무 기여 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/doomfist.png',
    },
    {
        name: '[둠피스트] 임무 기여 처치',
        value : ['doomfist','임무 기여 처치 - 10분당 평균','임무 기여 처치','10분당 평균','[둠피스트] 임무기여처치'],
        info : '둠피스트의 10분당 임무 기여 처지수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/doomfist.png',
    },
    {
        name: '[둠피스트] 폭주 시간',
        value : ['doomfist','폭주 시간 - 10분당 평균','폭주 시간','10분당 평균','[둠피스트] 폭주 시간'],
        info : '둠피스트의 폭주 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/doomfist.png',
    },
    {
        name: '[둠피스트] 근접 공격 적중률',
        value : ['doomfist','근접 공격 적중률','근접 공격 적중률',null,'[둠피스트] 근접 공격 적중률'],
        info : '둠피스트의 근접 공격 적중률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/doomfist.png',
    },
    {
        name: '[둠피스트] 기술로 준 피해',
        value : ['doomfist','기술로 준 피해 - 10분당 평균','기술로 준 피해','10분당 평균','[둠피스트] 기술로 준 피해'],
        info : '둠피스트의 10분당 기술로 준 피해',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/doomfist.png',
    },
    {
        name: '[둠피스트] 생성한 보호막',
        value : ['doomfist','생성한 보호막 - 10분당 평균','생성한 보호막','10분당 평균','[둠피스트] 생성한 보호막'],
        info : '둠피스트의 10분당 생성한 보호막',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/doomfist.png',
    },
    {
        name: '[둠피스트] 파멸의 일격으로 처치',
        value : ['doomfist','파멸의 일격으로 처치 - 10분당 평균','파멸의 일격으로 처치','10분당 평균','[둠피스트] 파멸의 일격으로 처치'],
        info : '둠피스트의 10분당 파멸의 일격으로 처치수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/doomfist.png',
    },

    // 디바 
    {
        name: '[디바] 플레이 시간',
        value : ['dva','플레이 시간','플레이 시간',null,'[디바] 플레이시간'],
        info : '디바의 플레이 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/dva.png',
    },
    {
        name: '[디바] 승률',
        value : ['dva','승률','승률' ,null,'[디바] 승률'],
        info : '디바 영웅의 승률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/dva.png',
    },
    {
        name: '[디바] 목숨당 처치',
        value : ['dva','목숨당 처치','목숨당 처치','게임당 평균','[디바] 목숨당 처치'],
        info : '디바의 목숨당 처치횟수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/dva.png',
    },
    {
        name: '[디바] 명중률',
        value : ['dva','명중률','명중률','게임당 평균','[디바] 명중률'],
        info : '디바의 명중률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/dva.png',
    },
    {
        name: '[디바] 치명타',
        value : ['dva','치명타 - 10분당 평균','치명타','10분당 평균','[디바] 치명타'],
        info : '디바의 10분당 평균 치명타 (헤드샷)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/dva.png',
    },
    {
        name: '[디바] 치명타 명중률',
        value : ['dva','치명타 명중률','치명타 명중률','게임당 평균','[디바] 치명타명중률'],
        info : '디바의 치명타 명중률 (헤드샷 명중률)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/dva.png',
    },
    {
        name: '[디바] 처치수',
        value : ['dva','처치 - 10분당 평균','처치수','10분당 평균','[디바] 처치수'],
        info : '디바의 10분당 평균 처지수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/dva.png',
    },
    {
        name: '[디바] 죽은횟수',
        value : ['dva','죽음 - 10분당 평균','죽은 횟수','10분당 평균','[디바] 죽은횟수'],
        info : '디바의 10분당 평균 죽은횟수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/dva.png',
    },
    {
        name: '[디바] 치유량',
        value : ['dva','치유량 - 10분당 평균','치유량','10분당 평균','[디바] 치유량'],
        info : '디바의 10분당 평균 치유량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/dva.png',
    },
    {
        name: '[디바] 결정타',
        value : ['dva','결정타 - 10분당 평균','결정타 수','10분당 평균','[디바] 결정타'],
        info : '디바의 10분당 평균 결정타 (막타)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/dva.png',
    },
    {
        name: '[디바] 단독처치',
        value : ['dva','단독 처치 - 10분당 평균','단독 처치수','10분당 평균','[디바] 단독처치'],
        info : '디바의 10분당 단독 처치수 (솔로킬 수)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/dva.png',
    },
    {
        name: '[디바] 영웅피해량',
        value : ['dva','영웅에게 준 피해 - 10분당 평균','영웅에게 준 피해량','10분당 평균','[디바] 영웅피해량'],
        info : '디바의 10분당 상대 영웅에 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/dva.png',
    },
    {
        name: '[디바] 방벽피해량',
        value : ['dva','방벽에 준 피해 - 10분당 평균','방벽에게 준 피해량','10분당 평균','[디바] 방벽피해량'],
        info : '디바의 10분당 방벽에 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/dva.png',
    },
    {
        name: '[디바] 모든피해량',
        value : ['dva','준 모든 피해 - 10분당 평균','준 모든 피해량','10분당 평균','[디바] 모든피해량'],
        info : '디바의 10분당 방벽과 상대 영웅 에게 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/dva.png',
    },
    {
        name: '[디바] 임무 기여 시간',
        value : ['dva','임무 기여 시간 - 10분당 평균','임무 기여 시간','10분당 평균','[디바] 임무기여시간'],
        info : '디바의 10분당 임무 기여 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/dva.png',
    },
    {
        name: '[디바] 임무 기여 처치',
        value : ['dva','임무 기여 처치 - 10분당 평균','임무 기여 처치','10분당 평균','[디바] 임무기여처치'],
        info : '디바의 10분당 임무 기여 처지수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/dva.png',
    },
    {
        name: '[디바] 폭주 시간',
        value : ['dva','폭주 시간 - 10분당 평균','폭주 시간','10분당 평균','[디바] 폭주 시간'],
        info : '디바의 10분당 폭주 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/dva.png',
    },
    {
        name: '[디바] 근접 공격 적중률',
        value : ['dva','근접 공격 적중률','근접 공격 적중률',null,'[디바] 근접 공격 적중률'],
        info : '디바의 근접 공격 적중률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/dva.png',
    },
    {
        name: '[디바] 매트릭스로 막은 피해',
        value : ['dva','막은 피해 - 10분당 평균','매트릭스로 막은 피해','10분당 평균','[디바] 매트릭스로 막은 피해'],
        info : '디바의 10분당 매트릭스로 막은 피해',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/dva.png',
    },
    {
        name: '[디바] 자폭으로 처치',
        value : ['dva','자폭으로 처치 - 10분당 평균','자폭으로 처치','10분당 평균','[디바] 자폭으로 처치'],
        info : '디바의 10분당 자폭으로 처치수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/dva.png',
    },
    {
        name: '[디바] 호출한 메카',
        value : ['dva','호출한 메카 - 10분당 평균','호출한 메카','10분당 평균','[디바] 호출한 메카'],
        info : '디바의 10분당 호출한 메카수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/dva.png',
    },
    // 라인하르트 
    {
        name: '[라인하르트] 플레이 시간',
        value : ['reinhardt','플레이 시간','플레이 시간',null,'[라인하르트] 플레이시간'],
        info : '라인하르트의 플레이 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/reinhardt.png',
    },
    {
        name: '[라인하르트] 승률',
        value : ['reinhardt','승률','승률' ,null,'[라인하르트] 승률'],
        info : '라인하르트 영웅의 승률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/reinhardt.png',
    },
    {
        name: '[라인하르트] 목숨당 처치',
        value : ['reinhardt','목숨당 처치','목숨당 처치','게임당 평균','[라인하르트] 목숨당 처치'],
        info : '라인하르트의 목숨당 처치횟수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/reinhardt.png',
    },
    {
        name: '[라인하르트] 명중률',
        value : ['reinhardt','명중률','명중률','게임당 평균','[라인하르트] 명중률'],
        info : '라인하르트의 명중률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/reinhardt.png',
    },
    {
        name: '[라인하르트] 치명타',
        value : ['reinhardt','치명타 - 10분당 평균','치명타','10분당 평균','[라인하르트] 치명타'],
        info : '라인하르트의 10분당 평균 치명타 (헤드샷)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/reinhardt.png',
    },
    {
        name: '[라인하르트] 치명타 명중률',
        value : ['reinhardt','치명타 명중률','치명타 명중률','게임당 평균','[라인하르트] 치명타명중률'],
        info : '라인하르트의 치명타 명중률 (헤드샷 명중률)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/reinhardt.png',
    },
    {
        name: '[라인하르트] 처치수',
        value : ['reinhardt','처치 - 10분당 평균','처치수','10분당 평균','[라인하르트] 처치수'],
        info : '라인하르트의 10분당 평균 처지수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/reinhardt.png',
    },
    {
        name: '[라인하르트] 죽은횟수',
        value : ['reinhardt','죽음 - 10분당 평균','죽은 횟수','10분당 평균','[라인하르트] 죽은횟수'],
        info : '라인하르트의 10분당 평균 죽은횟수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/reinhardt.png',
    },
    {
        name: '[라인하르트] 치유량',
        value : ['reinhardt','치유량 - 10분당 평균','치유량','10분당 평균','[라인하르트] 치유량'],
        info : '라인하르트의 10분당 평균 치유량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/reinhardt.png',
    },
    {
        name: '[라인하르트] 결정타',
        value : ['reinhardt','결정타 - 10분당 평균','결정타 수','10분당 평균','[라인하르트] 결정타'],
        info : '라인하르트의 10분당 평균 결정타 (막타)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/reinhardt.png',
    },
    {
        name: '[라인하르트] 단독처치',
        value : ['reinhardt','단독 처치 - 10분당 평균','단독 처치수','10분당 평균','[라인하르트] 단독처치'],
        info : '라인하르트의 10분당 단독 처치수 (솔로킬 수)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/reinhardt.png',
    },
    {
        name: '[라인하르트] 영웅피해량',
        value : ['reinhardt','영웅에게 준 피해 - 10분당 평균','영웅에게 준 피해량','10분당 평균','[라인하르트] 영웅피해량'],
        info : '라인하르트의 10분당 상대 영웅에 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/reinhardt.png',
    },
    {
        name: '[라인하르트] 방벽피해량',
        value : ['reinhardt','방벽에 준 피해 - 10분당 평균','방벽에게 준 피해량','10분당 평균','[라인하르트] 방벽피해량'],
        info : '라인하르트의 10분당 방벽에 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/reinhardt.png',
    },
    {
        name: '[라인하르트] 모든피해량',
        value : ['reinhardt','준 모든 피해 - 10분당 평균','준 모든 피해량','10분당 평균','[라인하르트] 모든피해량'],
        info : '라인하르트의 10분당 방벽과 상대 영웅 에게 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/reinhardt.png',
    },
    {
        name: '[라인하르트] 임무 기여 시간',
        value : ['reinhardt','임무 기여 시간 - 10분당 평균','임무 기여 시간','10분당 평균','[라인하르트] 임무기여시간'],
        info : '라인하르트의 10분당 임무 기여 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/reinhardt.png',
    },
    {
        name: '[라인하르트] 임무 기여 처치',
        value : ['reinhardt','임무 기여 처치 - 10분당 평균','임무 기여 처치','10분당 평균','[라인하르트] 임무기여처치'],
        info : '라인하르트의 10분당 임무 기여 처지수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/reinhardt.png',
    },
    {
        name: '[라인하르트] 폭주 시간',
        value : ['reinhardt','폭주 시간 - 10분당 평균','폭주 시간','10분당 평균','[라인하르트] 폭주 시간'],
        info : '라인하르트의 10분당 폭주 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/reinhardt.png',
    },
    {
        name: '[라인하르트] 대지분쇄로 처치',
        value : ['reinhardt','대지분쇄로 처치 - 10분당 평균','대지분쇄로 처치','10분당 평균','[라인하르트] 대지분쇄로 처치'],
        info : '라인하르트의 10분당 대지분쇄로 처치수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/reinhardt.png',
    },
    {
        name: '[라인하르트] 돌진으로 처치',
        value : ['reinhardt','돌진으로 처치 - 10분당 평균','돌진으로 처치','10분당 평균','[라인하르트] 돌진으로 처치'],
        info : '라인하르트의 10분당 돌진으로 처치수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/reinhardt.png',
    },
    {
        name: '[라인하르트] 로켓 해머 근접 공격 적중률',
        value : ['reinhardt','로켓 해머 근접 공격 적중률','로켓 해머 근접 공격 적중률','게임당 평균','[라인하르트] 로켓 해머 근접 공격 적중률'],
        info : '라인하르트의 로켓 해머 근접 공격 적중률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/reinhardt.png',
    },
    {
        name: '[라인하르트] 방벽으로 막은 피해',
        value : ['reinhardt','막은 피해 - 10분당 평균','막은 피해','10분당 평균','[라인하르트] 방벽으로 막은 피해'],
        info : '라인하르트의 10분당 방벽으로 막은 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/reinhardt.png',
    },
    {
        name: '[라인하르트] 화염 강타로 처치',
        value : ['reinhardt','화염 강타로 처치 - 10분당 평균','화염 강타로 처치','10분당 평균','[라인하르트] 화염 강타로 처치'],
        info : '라인하르트의 10분당 화염 강타로 처치수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/reinhardt.png',
    },
    {
        name: '[라인하르트] 공격형 도움',
        value : ['reinhardt','공격형 도움 - 10분당 평균','공격형 도움','10분당 평균','[라인하르트] 공격형 도움'],
        info : '라인하르트의 10분당 공격형 도움수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/reinhardt.png',
    },
     // 레킹볼 
     {
        name: '[레킹볼] 플레이 시간',
        value : ['wrecking-ball','플레이 시간','플레이 시간',null,'[레킹볼] 플레이시간'],
        info : '레킹볼의 플레이 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/wrecking-ball.png',
    },
    {
        name: '[레킹볼] 승률',
        value : ['wrecking-ball','승률','승률' ,null,'[레킹볼] 승률'],
        info : '레킹볼 영웅의 승률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/wrecking-ball.png',
    },
    {
        name: '[레킹볼] 목숨당 처치',
        value : ['wrecking-ball','목숨당 처치','목숨당 처치','게임당 평균','[레킹볼] 목숨당 처치'],
        info : '레킹볼의 목숨당 처치횟수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/wrecking-ball.png',
    },
    {
        name: '[레킹볼] 명중률',
        value : ['wrecking-ball','명중률','명중률','게임당 평균','[레킹볼] 명중률'],
        info : '레킹볼의 명중률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/wrecking-ball.png',
    },
    {
        name: '[레킹볼] 치명타',
        value : ['wrecking-ball','치명타 - 10분당 평균','치명타','10분당 평균','[레킹볼] 치명타'],
        info : '레킹볼의 10분당 평균 치명타 (헤드샷)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/wrecking-ball.png',
    },
    {
        name: '[레킹볼] 치명타 명중률',
        value : ['wrecking-ball','치명타 명중률','치명타 명중률','게임당 평균','[레킹볼] 치명타명중률'],
        info : '레킹볼의 치명타 명중률 (헤드샷 명중률)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/wrecking-ball.png',
    },
    {
        name: '[레킹볼] 처치수',
        value : ['wrecking-ball','처치 - 10분당 평균','처치수','10분당 평균','[레킹볼] 처치수'],
        info : '레킹볼의 10분당 평균 처지수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/wrecking-ball.png',
    },
    {
        name: '[레킹볼] 죽은횟수',
        value : ['wrecking-ball','죽음 - 10분당 평균','죽은 횟수','10분당 평균','[레킹볼] 죽은횟수'],
        info : '레킹볼의 10분당 평균 죽은횟수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/wrecking-ball.png',
    },
    {
        name: '[레킹볼] 치유량',
        value : ['wrecking-ball','치유량 - 10분당 평균','치유량','10분당 평균','[레킹볼] 치유량'],
        info : '레킹볼의 10분당 평균 치유량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/wrecking-ball.png',
    },
    {
        name: '[레킹볼] 결정타',
        value : ['wrecking-ball','결정타 - 10분당 평균','결정타 수','10분당 평균','[레킹볼] 결정타'],
        info : '레킹볼의 10분당 평균 결정타 (막타)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/wrecking-ball.png',
    },
    {
        name: '[레킹볼] 단독처치',
        value : ['wrecking-ball','단독 처치 - 10분당 평균','단독 처치수','10분당 평균','[레킹볼] 단독처치'],
        info : '레킹볼의 10분당 단독 처치수 (솔로킬 수)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/wrecking-ball.png',
    },
    {
        name: '[레킹볼] 영웅피해량',
        value : ['wrecking-ball','영웅에게 준 피해 - 10분당 평균','영웅에게 준 피해량','10분당 평균','[레킹볼] 영웅피해량'],
        info : '레킹볼의 10분당 상대 영웅에 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/wrecking-ball.png',
    },
    {
        name: '[레킹볼] 방벽피해량',
        value : ['wrecking-ball','방벽에 준 피해 - 10분당 평균','방벽에게 준 피해량','10분당 평균','[레킹볼] 방벽피해량'],
        info : '레킹볼의 10분당 방벽에 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/wrecking-ball.png',
    },
    {
        name: '[레킹볼] 모든피해량',
        value : ['wrecking-ball','준 모든 피해 - 10분당 평균','준 모든 피해량','10분당 평균','[레킹볼] 모든피해량'],
        info : '레킹볼의 10분당 방벽과 상대 영웅 에게 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/wrecking-ball.png',
    },
    {
        name: '[레킹볼] 임무 기여 시간',
        value : ['wrecking-ball','임무 기여 시간 - 10분당 평균','임무 기여 시간','10분당 평균','[레킹볼] 임무기여시간'],
        info : '레킹볼의 10분당 임무 기여 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/wrecking-ball.png',
    },
    {
        name: '[레킹볼] 임무 기여 처치',
        value : ['wrecking-ball','임무 기여 처치 - 10분당 평균','임무 기여 처치','10분당 평균','[레킹볼] 임무기여처치'],
        info : '레킹볼의 10분당 임무 기여 처지수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/wrecking-ball.png',
    },
    {
        name: '[레킹볼] 폭주 시간',
        value : ['wrecking-ball','폭주 시간 - 10분당 평균','폭주 시간','10분당 평균','[레킹볼] 폭주 시간'],
        info : '레킹볼의 10분당 폭주 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/wrecking-ball.png',
    },
    {
        name: '[레킹볼] 근접 공격 적중률',
        value : ['wrecking-ball','근접 공격 적중률','근접 공격 적중률','게임당 평균','[레킹볼] 근접 공격 적중률'],
        info : '레킹볼의 근접 공격 적중률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/wrecking-ball.png',
    },
    {
        name: '[레킹볼] 갈고리 고정으로 처치',
        value : ['wrecking-ball','갈고리 고정으로 처치 - 10분당 평균','갈고리 고정으로 처치','10분당 평균','[레킹볼] 갈고리 고정으로 처치'],
        info : '레킹볼의 10분당 갈고리 고정으로 처치수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/wrecking-ball.png',
    },
    {
        name: '[레킹볼] 밀쳐낸 플레이어',
        value : ['wrecking-ball','밀쳐낸 플레이어 - 10분당 평균','밀쳐낸 플레이어','10분당 평균','[레킹볼] 밀쳐낸 플레이어'],
        info : '레킹볼의 10분당 밀쳐낸 플레이어수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/wrecking-ball.png',
    },
    {
        name: '[레킹볼] 지뢰밭으로 처치',
        value : ['wrecking-ball','지뢰밭으로 처치 - 10분당 평균','지뢰밭으로 처치','10분당 평균','[레킹볼] 지뢰밭으로 처치'],
        info : '레킹볼의 10분당 지뢰밭으로 처치수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/wrecking-ball.png',
    },
    {
        name: '[레킹볼] 파일드라이버로 처치',
        value : ['wrecking-ball','파일드라이버로 처치 - 10분당 평균','파일드라이버로 처치','10분당 평균','[레킹볼] 파일드라이버로 처치'],
        info : '레킹볼의 10분당 파일드라이버로 처치수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/wrecking-ball.png',
    },
     // 로드호그 
     {
        name: '[로드호그] 플레이 시간',
        value : ['roadhog','플레이 시간','플레이 시간',null,'[로드호그] 플레이시간'],
        info : '로드호그의 플레이 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/roadhog.png',
    },
    {
        name: '[로드호그] 승률',
        value : ['roadhog','승률','승률' ,null,'[로드호그] 승률'],
        info : '로드호그 영웅의 승률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/roadhog.png',
    },
    {
        name: '[로드호그] 목숨당 처치',
        value : ['roadhog','목숨당 처치','목숨당 처치','게임당 평균','[로드호그] 목숨당 처치'],
        info : '로드호그의 목숨당 처치횟수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/roadhog.png',
    },
    {
        name: '[로드호그] 명중률',
        value : ['roadhog','명중률','명중률','게임당 평균','[로드호그] 명중률'],
        info : '로드호그의 명중률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/roadhog.png',
    },
    {
        name: '[로드호그] 치명타',
        value : ['roadhog','치명타 - 10분당 평균','치명타','10분당 평균','[로드호그] 치명타'],
        info : '로드호그의 10분당 평균 치명타 (헤드샷)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/roadhog.png',
    },
    {
        name: '[로드호그] 치명타 명중률',
        value : ['roadhog','치명타 명중률','치명타 명중률','게임당 평균','[로드호그] 치명타명중률'],
        info : '로드호그의 치명타 명중률 (헤드샷 명중률)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/roadhog.png',
    },
    {
        name: '[로드호그] 처치수',
        value : ['roadhog','처치 - 10분당 평균','처치수','10분당 평균','[로드호그] 처치수'],
        info : '로드호그의 10분당 평균 처지수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/roadhog.png',
    },
    {
        name: '[로드호그] 죽은횟수',
        value : ['roadhog','죽음 - 10분당 평균','죽은 횟수','10분당 평균','[로드호그] 죽은횟수'],
        info : '로드호그의 10분당 평균 죽은횟수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/roadhog.png',
    },
    {
        name: '[로드호그] 치유량',
        value : ['roadhog','치유량 - 10분당 평균','치유량','10분당 평균','[로드호그] 치유량'],
        info : '로드호그의 10분당 평균 치유량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/roadhog.png',
    },
    {
        name: '[로드호그] 결정타',
        value : ['roadhog','결정타 - 10분당 평균','결정타 수','10분당 평균','[로드호그] 결정타'],
        info : '로드호그의 10분당 평균 결정타 (막타)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/roadhog.png',
    },
    {
        name: '[로드호그] 단독처치',
        value : ['roadhog','단독 처치 - 10분당 평균','단독 처치수','10분당 평균','[로드호그] 단독처치'],
        info : '로드호그의 10분당 단독 처치수 (솔로킬 수)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/roadhog.png',
    },
    {
        name: '[로드호그] 영웅피해량',
        value : ['roadhog','영웅에게 준 피해 - 10분당 평균','영웅에게 준 피해량','10분당 평균','[로드호그] 영웅피해량'],
        info : '로드호그의 10분당 상대 영웅에 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/roadhog.png',
    },
    {
        name: '[로드호그] 방벽피해량',
        value : ['roadhog','방벽에 준 피해 - 10분당 평균','방벽에게 준 피해량','10분당 평균','[로드호그] 방벽피해량'],
        info : '로드호그의 10분당 방벽에 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/roadhog.png',
    },
    {
        name: '[로드호그] 모든피해량',
        value : ['roadhog','준 모든 피해 - 10분당 평균','준 모든 피해량','10분당 평균','[로드호그] 모든피해량'],
        info : '로드호그의 10분당 방벽과 상대 영웅 에게 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/roadhog.png',
    },
    {
        name: '[로드호그] 임무 기여 시간',
        value : ['roadhog','임무 기여 시간 - 10분당 평균','임무 기여 시간','10분당 평균','[로드호그] 임무기여시간'],
        info : '로드호그의 10분당 임무 기여 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/roadhog.png',
    },
    {
        name: '[로드호그] 임무 기여 처치',
        value : ['roadhog','임무 기여 처치 - 10분당 평균','임무 기여 처치','10분당 평균','[로드호그] 임무기여처치'],
        info : '로드호그의 10분당 임무 기여 처지수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/roadhog.png',
    },
    {
        name: '[로드호그] 폭주 시간',
        value : ['roadhog','폭주 시간 - 10분당 평균','폭주 시간','10분당 평균','[로드호그] 폭주 시간'],
        info : '로드호그의 10분당 폭주 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/roadhog.png',
    },
    {
        name: '[로드호그] 근접 공격 적중률',
        value : ['roadhog','근접 공격 적중률','근접 공격 적중률','게임당 평균','[로드호그] 근접 공격 적중률'],
        info : '로드호그의 근접 공격 적중률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/roadhog.png',
    },
    {
        name: '[로드호그] 갈고리 명중률',
        value : ['roadhog','갈고리 명중률','갈고리 명중률','게임당 평균','[로드호그] 갈고리 명중률'],
        info : '로드호그의 갈고리 명중률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/roadhog.png',
    },
    {
        name: '[로드호그] 갈고리로 끌어온 적의 숫자',
        value : ['roadhog','갈고리로 끌어오기 - 10분당 평균','갈고리로 끌어온 적의 숫자','10분당 평균','[로드호그] 갈고리로 끌어온 적의 숫자'],
        info : '로드호그의 10분당 갈고리로 끌어온 적의 숫자',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/roadhog.png',
    },
    {
        name: '[로드호그] 돼재앙으로 처치',
        value : ['roadhog','돼재앙으로 처치 - 10분당 평균','돼재앙으로 처치','10분당 평균','[로드호그] 돼재앙으로 처치'],
        info : '로드호그의 10분당 돼재앙으로 처치수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/roadhog.png',
    },
    {
        name: '[로드호그] 자가 치유량',
        value : ['roadhog','자가 치유 - 10분당 평균','자가 치유량','10분당 평균','[로드호그] 자가 치유량'],
        info : '로드호그의 10분당 자가 치유량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/roadhog.png',
    },
    // 루시우 
    {
        name: '[루시우] 플레이 시간',
        value : ['lucio','플레이 시간','플레이 시간',null,'[루시우] 플레이시간'],
        info : '루시우의 플레이 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/lucio.png',
    },
    {
        name: '[루시우] 승률',
        value : ['lucio','승률','승률' ,null,'[루시우] 승률'],
        info : '루시우 영웅의 승률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/lucio.png',
    },
    {
        name: '[루시우] 목숨당 처치',
        value : ['lucio','목숨당 처치','목숨당 처치','게임당 평균','[루시우] 목숨당 처치'],
        info : '루시우의 목숨당 처치횟수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/lucio.png',
    },
    {
        name: '[루시우] 명중률',
        value : ['lucio','명중률','명중률','게임당 평균','[루시우] 명중률'],
        info : '루시우의 명중률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/lucio.png',
    },
    {
        name: '[루시우] 치명타',
        value : ['lucio','치명타 - 10분당 평균','치명타','10분당 평균','[루시우] 치명타'],
        info : '루시우의 10분당 평균 치명타 (헤드샷)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/lucio.png',
    },
    {
        name: '[루시우] 치명타 명중률',
        value : ['lucio','치명타 명중률','치명타 명중률','게임당 평균','[루시우] 치명타명중률'],
        info : '루시우의 치명타 명중률 (헤드샷 명중률)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/lucio.png',
    },
    {
        name: '[루시우] 처치수',
        value : ['lucio','처치 - 10분당 평균','처치수','10분당 평균','[루시우] 처치수'],
        info : '루시우의 10분당 평균 처지수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/lucio.png',
    },
    {
        name: '[루시우] 죽은횟수',
        value : ['lucio','죽음 - 10분당 평균','죽은 횟수','10분당 평균','[루시우] 죽은횟수'],
        info : '루시우의 10분당 평균 죽은횟수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/lucio.png',
    },
    {
        name: '[루시우] 치유량',
        value : ['lucio','치유량 - 10분당 평균','치유량','10분당 평균','[루시우] 치유량'],
        info : '루시우의 10분당 평균 치유량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/lucio.png',
    },
    {
        name: '[루시우] 결정타',
        value : ['lucio','결정타 - 10분당 평균','결정타 수','10분당 평균','[루시우] 결정타'],
        info : '루시우의 10분당 평균 결정타 (막타)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/lucio.png',
    },
    {
        name: '[루시우] 단독처치',
        value : ['lucio','단독 처치 - 10분당 평균','단독 처치수','10분당 평균','[루시우] 단독처치'],
        info : '루시우의 10분당 단독 처치수 (솔로킬 수)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/lucio.png',
    },
    {
        name: '[루시우] 영웅피해량',
        value : ['lucio','영웅에게 준 피해 - 10분당 평균','영웅에게 준 피해량','10분당 평균','[루시우] 영웅피해량'],
        info : '루시우의 10분당 상대 영웅에 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/lucio.png',
    },
    {
        name: '[루시우] 방벽피해량',
        value : ['lucio','방벽에 준 피해 - 10분당 평균','방벽에게 준 피해량','10분당 평균','[루시우] 방벽피해량'],
        info : '루시우의 10분당 방벽에 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/lucio.png',
    },
    {
        name: '[루시우] 모든피해량',
        value : ['lucio','준 모든 피해 - 10분당 평균','준 모든 피해량','10분당 평균','[루시우] 모든피해량'],
        info : '루시우의 10분당 방벽과 상대 영웅 에게 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/lucio.png',
    },
    {
        name: '[루시우] 임무 기여 시간',
        value : ['lucio','임무 기여 시간 - 10분당 평균','임무 기여 시간','10분당 평균','[루시우] 임무기여시간'],
        info : '루시우의 10분당 임무 기여 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/lucio.png',
    },
    {
        name: '[루시우] 임무 기여 처치',
        value : ['lucio','임무 기여 처치 - 10분당 평균','임무 기여 처치','10분당 평균','[루시우] 임무기여처치'],
        info : '루시우의 10분당 임무 기여 처지수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/lucio.png',
    },
    {
        name: '[루시우] 폭주 시간',
        value : ['lucio','폭주 시간 - 10분당 평균','폭주 시간','10분당 평균','[루시우] 폭주 시간'],
        info : '루시우의 10분당 폭주 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/lucio.png',
    },
    {
        name: '[루시우] 근접 공격 적중률',
        value : ['lucio','근접 공격 적중률 - 10분당 평균','근접 공격 적중률','게임당 평균','[루시우] 근접 공격 적중률'],
        info : '루시우의 10분당 근접 공격 적중률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/lucio.png',
    },
    {
        name: '[루시우] 소리 방벽 제공수',
        value : ['lucio','소리 방벽 제공 - 10분당 평균','소리 방벽 제공수','10분당 평균','[루시우] 소리 방벽 제공수'],
        info : '루시우의 10분당 소리 방벽 제공수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/lucio.png',
    },
    {
        name: '[루시우] 자가 치유량',
        value : ['lucio','자가 치유 - 10분당 평균','자가 치유량','10분당 평균','[루시우] 자가 치유량'],
        info : '루시우의 10분당 자가 치유량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/lucio.png',
    },
    {
        name: '[루시우] 공격형 도움',
        value : ['lucio','공격형 도움 - 10분당 평균','공격형 도움','10분당 평균','[루시우] 공격형 도움'],
        info : '루시우의 10분당 공격형 도움',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/lucio.png',
    },
    {
        name: '[루시우] 방어형 도움',
        value : ['lucio','방어형 도움 - 10분당 평균','방어형 도움','10분당 평균','[루시우] 방어형 도움'],
        info : '루시우의 10분당 방어형 도움',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/lucio.png',
    },

    // 리퍼 
    {
        name: '[리퍼] 플레이 시간',
        value : ['reaper','플레이 시간','플레이 시간',null,'[리퍼] 플레이시간'],
        info : '리퍼의 플레이 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/reaper.png',
    },
    {
        name: '[리퍼] 승률',
        value : ['reaper','승률','승률' ,null,'[리퍼] 승률'],
        info : '리퍼 영웅의 승률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/reaper.png',
    },
    {
        name: '[리퍼] 목숨당 처치',
        value : ['reaper','목숨당 처치','목숨당 처치','게임당 평균','[리퍼] 목숨당 처치'],
        info : '리퍼의 목숨당 처치횟수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/reaper.png',
    },
    {
        name: '[리퍼] 명중률',
        value : ['reaper','명중률','명중률','게임당 평균','[리퍼] 명중률'],
        info : '리퍼의 명중률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/reaper.png',
    },
    {
        name: '[리퍼] 치명타',
        value : ['reaper','치명타 - 10분당 평균','치명타','10분당 평균','[리퍼] 치명타'],
        info : '리퍼의 10분당 평균 치명타 (헤드샷)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/reaper.png',
    },
    {
        name: '[리퍼] 치명타 명중률',
        value : ['reaper','치명타 명중률','치명타 명중률','게임당 평균','[리퍼] 치명타명중률'],
        info : '리퍼의 치명타 명중률 (헤드샷 명중률)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/reaper.png',
    },
    {
        name: '[리퍼] 처치수',
        value : ['reaper','처치 - 10분당 평균','처치수','10분당 평균','[리퍼] 처치수'],
        info : '리퍼의 10분당 평균 처지수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/reaper.png',
    },
    {
        name: '[리퍼] 죽은횟수',
        value : ['reaper','죽음 - 10분당 평균','죽은 횟수','10분당 평균','[리퍼] 죽은횟수'],
        info : '리퍼의 10분당 평균 죽은횟수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/reaper.png',
    },
    {
        name: '[리퍼] 치유량',
        value : ['reaper','치유량 - 10분당 평균','치유량','10분당 평균','[리퍼] 치유량'],
        info : '리퍼의 10분당 평균 치유량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/reaper.png',
    },
    {
        name: '[리퍼] 결정타',
        value : ['reaper','결정타 - 10분당 평균','결정타 수','10분당 평균','[리퍼] 결정타'],
        info : '리퍼의 10분당 평균 결정타 (막타)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/reaper.png',
    },
    {
        name: '[리퍼] 단독처치',
        value : ['reaper','단독 처치 - 10분당 평균','단독 처치수','10분당 평균','[리퍼] 단독처치'],
        info : '리퍼의 10분당 단독 처치수 (솔로킬 수)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/reaper.png',
    },
    {
        name: '[리퍼] 영웅피해량',
        value : ['reaper','영웅에게 준 피해 - 10분당 평균','영웅에게 준 피해량','10분당 평균','[리퍼] 영웅피해량'],
        info : '리퍼의 10분당 상대 영웅에 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/reaper.png',
    },
    {
        name: '[리퍼] 방벽피해량',
        value : ['reaper','방벽에 준 피해 - 10분당 평균','방벽에게 준 피해량','10분당 평균','[리퍼] 방벽피해량'],
        info : '리퍼의 10분당 방벽에 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/reaper.png',
    },
    {
        name: '[리퍼] 모든피해량',
        value : ['reaper','준 모든 피해 - 10분당 평균','준 모든 피해량','10분당 평균','[리퍼] 모든피해량'],
        info : '리퍼의 10분당 방벽과 상대 영웅 에게 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/reaper.png',
    },
    {
        name: '[리퍼] 임무 기여 시간',
        value : ['reaper','임무 기여 시간 - 10분당 평균','임무 기여 시간','10분당 평균','[리퍼] 임무기여시간'],
        info : '리퍼의 10분당 임무 기여 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/reaper.png',
    },
    {
        name: '[리퍼] 임무 기여 처치',
        value : ['reaper','임무 기여 처치 - 10분당 평균','임무 기여 처치','10분당 평균','[리퍼] 임무기여처치'],
        info : '리퍼의 10분당 임무 기여 처지수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/reaper.png',
    },
    {
        name: '[리퍼] 폭주 시간',
        value : ['reaper','폭주 시간 - 10분당 평균','폭주 시간','10분당 평균','[리퍼] 폭주 시간'],
        info : '리퍼의 10분당 폭주 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/reaper.png',
    },
    {
        name: '[리퍼] 근접 공격 적중률',
        value : ['reaper','근접 공격 적중률','근접 공격 적중률','게임당 평균','[리퍼] 근접 공격 적중률'],
        info : '리퍼의 근접 공격 적중률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/reaper.png',
    },
    {
        name: '[리퍼] 자가 치유량',
        value : ['reaper','자가 치유 - 10분당 평균','자가 치유량','10분당 평균','[리퍼] 자가 치유량'],
        info : '리퍼의 10분당 자가 치유량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/reaper.png',
    },
    {
        name: '[리퍼] 죽음의 꽃으로 처치',
        value : ['reaper','죽음의 꽃으로 처치 - 10분당 평균	2.94','죽음의 꽃으로 처치','10분당 평균','[리퍼] 죽음의 꽃으로 처치'],
        info : '리퍼의 10분당 임무 기여 처지수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/reaper.png',
    },
     // 맥크리 
     {
        name: '[맥크리] 플레이 시간',
        value : ['mccree','플레이 시간','플레이 시간',null,'[맥크리] 플레이시간'],
        info : '맥크리의 플레이 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/mccree.png',
    },
    {
        name: '[맥크리] 승률',
        value : ['mccree','승률','승률' ,null,'[맥크리] 승률'],
        info : '맥크리 영웅의 승률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/mccree.png',
    },
    {
        name: '[맥크리] 목숨당 처치',
        value : ['mccree','목숨당 처치','목숨당 처치','게임당 평균','[맥크리] 목숨당 처치'],
        info : '맥크리의 목숨당 처치횟수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/mccree.png',
    },
    {
        name: '[맥크리] 명중률',
        value : ['mccree','명중률','명중률','게임당 평균','[맥크리] 명중률'],
        info : '맥크리의 명중률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/mccree.png',
    },
    {
        name: '[맥크리] 치명타',
        value : ['mccree','치명타 - 10분당 평균','치명타','10분당 평균','[맥크리] 치명타'],
        info : '맥크리의 10분당 평균 치명타 (헤드샷)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/mccree.png',
    },
    {
        name: '[맥크리] 치명타 명중률',
        value : ['mccree','치명타 명중률','치명타 명중률','게임당 평균','[맥크리] 치명타명중률'],
        info : '맥크리의 치명타 명중률 (헤드샷 명중률)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/mccree.png',
    },
    {
        name: '[맥크리] 처치수',
        value : ['mccree','처치 - 10분당 평균','처치수','10분당 평균','[맥크리] 처치수'],
        info : '맥크리의 10분당 평균 처지수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/mccree.png',
    },
    {
        name: '[맥크리] 죽은횟수',
        value : ['mccree','죽음 - 10분당 평균','죽은 횟수','10분당 평균','[맥크리] 죽은횟수'],
        info : '맥크리의 10분당 평균 죽은횟수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/mccree.png',
    },
    {
        name: '[맥크리] 치유량',
        value : ['mccree','치유량 - 10분당 평균','치유량','10분당 평균','[맥크리] 치유량'],
        info : '맥크리의 10분당 평균 치유량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/mccree.png',
    },
    {
        name: '[맥크리] 결정타',
        value : ['mccree','결정타 - 10분당 평균','결정타 수','10분당 평균','[맥크리] 결정타'],
        info : '맥크리의 10분당 평균 결정타 (막타)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/mccree.png',
    },
    {
        name: '[맥크리] 단독처치',
        value : ['mccree','단독 처치 - 10분당 평균','단독 처치수','10분당 평균','[맥크리] 단독처치'],
        info : '맥크리의 10분당 단독 처치수 (솔로킬 수)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/mccree.png',
    },
    {
        name: '[맥크리] 영웅피해량',
        value : ['mccree','영웅에게 준 피해 - 10분당 평균','영웅에게 준 피해량','10분당 평균','[맥크리] 영웅피해량'],
        info : '맥크리의 10분당 상대 영웅에 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/mccree.png',
    },
    {
        name: '[맥크리] 방벽피해량',
        value : ['mccree','방벽에 준 피해 - 10분당 평균','방벽에게 준 피해량','10분당 평균','[맥크리] 방벽피해량'],
        info : '맥크리의 10분당 방벽에 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/mccree.png',
    },
    {
        name: '[맥크리] 모든피해량',
        value : ['mccree','준 모든 피해 - 10분당 평균','준 모든 피해량','10분당 평균','[맥크리] 모든피해량'],
        info : '맥크리의 10분당 방벽과 상대 영웅 에게 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/mccree.png',
    },
    {
        name: '[맥크리] 임무 기여 시간',
        value : ['mccree','임무 기여 시간 - 10분당 평균','임무 기여 시간','10분당 평균','[맥크리] 임무기여시간'],
        info : '맥크리의 10분당 임무 기여 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/mccree.png',
    },
    {
        name: '[맥크리] 임무 기여 처치',
        value : ['mccree','임무 기여 처치 - 10분당 평균','임무 기여 처치','10분당 평균','[맥크리] 임무기여처치'],
        info : '맥크리의 10분당 임무 기여 처지수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/mccree.png',
    },
    {
        name: '[맥크리] 폭주 시간',
        value : ['mccree','폭주 시간 - 10분당 평균','폭주 시간','10분당 평균','[맥크리] 폭주 시간'],
        info : '맥크리의 10분당 폭주 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/mccree.png',
    },
    {
        name: '[맥크리] 근접 공격 적중률',
        value : ['mccree','근접 공격 적중률','근접 공격 적중률','게임당 평균','[맥크리] 근접 공격 적중률'],
        info : '맥크리의 근접 공격 적중률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/mccree.png',
    },
    {
        name: '[맥크리] 피스키퍼 난사로 처치',
        value : ['mccree','피스키퍼 난사로 처치 - 10분당 평균','피스키퍼 난사로 처치','10분당 평균','[맥크리] 피스키퍼 난사로 처치'],
        info : '맥크리의 10분당 피스키퍼 난사로 처치수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/mccree.png',
    },
    {
        name: '[맥크리] 황야의 무법자로 처치',
        value : ['mccree','황야의 무법자로 처치 - 10분당 평균','황야의 무법자로 처치','10분당 평균','[맥크리] 황야의 무법자로 처치'],
        info : '맥크리의 10분당 황야의 무법자로 처치수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/mccree.png',
    },
    // 메르시 
    {
        name: '[메르시] 플레이 시간',
        value : ['mercy','플레이 시간','플레이 시간',null,'[메르시] 플레이시간'],
        info : '메르시의 플레이 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/mercy.png',
    },
    {
        name: '[메르시] 승률',
        value : ['mercy','승률','승률' ,null,'[메르시] 승률'],
        info : '메르시 영웅의 승률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/mercy.png',
    },
    {
        name: '[메르시] 목숨당 처치',
        value : ['mercy','목숨당 처치','목숨당 처치','게임당 평균','[메르시] 목숨당 처치'],
        info : '메르시의 목숨당 처치횟수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/mercy.png',
    },
    {
        name: '[메르시] 명중률',
        value : ['mercy','명중률','명중률','게임당 평균','[메르시] 명중률'],
        info : '메르시의 명중률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/mercy.png',
    },
    {
        name: '[메르시] 치명타',
        value : ['mercy','치명타 - 10분당 평균','치명타','10분당 평균','[메르시] 치명타'],
        info : '메르시의 10분당 평균 치명타 (헤드샷)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/mercy.png',
    },
    {
        name: '[메르시] 치명타 명중률',
        value : ['mercy','치명타 명중률','치명타 명중률','게임당 평균','[메르시] 치명타명중률'],
        info : '메르시의 치명타 명중률 (헤드샷 명중률)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/mercy.png',
    },
    {
        name: '[메르시] 처치수',
        value : ['mercy','처치 - 10분당 평균','처치수','10분당 평균','[메르시] 처치수'],
        info : '메르시의 10분당 평균 처지수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/mercy.png',
    },
    {
        name: '[메르시] 죽은횟수',
        value : ['mercy','죽음 - 10분당 평균','죽은 횟수','10분당 평균','[메르시] 죽은횟수'],
        info : '메르시의 10분당 평균 죽은횟수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/mercy.png',
    },
    {
        name: '[메르시] 치유량',
        value : ['mercy','치유량 - 10분당 평균','치유량','10분당 평균','[메르시] 치유량'],
        info : '메르시의 10분당 평균 치유량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/mercy.png',
    },
    {
        name: '[메르시] 결정타',
        value : ['mercy','결정타 - 10분당 평균','결정타 수','10분당 평균','[메르시] 결정타'],
        info : '메르시의 10분당 평균 결정타 (막타)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/mercy.png',
    },
    {
        name: '[메르시] 단독처치',
        value : ['mercy','단독 처치 - 10분당 평균','단독 처치수','10분당 평균','[메르시] 단독처치'],
        info : '메르시의 10분당 단독 처치수 (솔로킬 수)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/mercy.png',
    },
    {
        name: '[메르시] 영웅피해량',
        value : ['mercy','영웅에게 준 피해 - 10분당 평균','영웅에게 준 피해량','10분당 평균','[메르시] 영웅피해량'],
        info : '메르시의 10분당 상대 영웅에 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/mercy.png',
    },
    {
        name: '[메르시] 방벽피해량',
        value : ['mercy','방벽에 준 피해 - 10분당 평균','방벽에게 준 피해량','10분당 평균','[메르시] 방벽피해량'],
        info : '메르시의 10분당 방벽에 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/mercy.png',
    },
    {
        name: '[메르시] 모든피해량',
        value : ['mercy','준 모든 피해 - 10분당 평균','준 모든 피해량','10분당 평균','[메르시] 모든피해량'],
        info : '메르시의 10분당 방벽과 상대 영웅 에게 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/mercy.png',
    },
    {
        name: '[메르시] 임무 기여 시간',
        value : ['mercy','임무 기여 시간 - 10분당 평균','임무 기여 시간','10분당 평균','[메르시] 임무기여시간'],
        info : '메르시의 10분당 임무 기여 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/mercy.png',
    },
    {
        name: '[메르시] 임무 기여 처치',
        value : ['mercy','임무 기여 처치 - 10분당 평균','임무 기여 처치','10분당 평균','[메르시] 임무기여처치'],
        info : '메르시의 10분당 임무 기여 처지수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/mercy.png',
    },
    {
        name: '[메르시] 폭주 시간',
        value : ['mercy','폭주 시간 - 10분당 평균','폭주 시간','10분당 평균','[메르시] 폭주 시간'],
        info : '메르시의 10분당 폭주 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/mercy.png',
    },
    {
        name: '[메르시] 근접 공격 적중률',
        value : ['mercy','근접 공격 적중률','근접 공격 적중률','게임당 평균','[메르시] 근접 공격 적중률'],
        info : '메르시의 근접 공격 적중률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/mercy.png',
    },
    {
        name: '[메르시] 부활한 플레이어',
        value : ['mercy','부활한 플레이어 - 10분당 평균','부활한 플레이어','10분당 평균','[메르시] 부활한 플레이어'],
        info : '메르시의 10분당 부활한 플레이어수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/mercy.png',
    },
    {
        name: '[메르시] 블라스터로 처치',
        value : ['mercy','블라스터로 처치 - 10분당 평균','블라스터로 처치','10분당 평균','[메르시] 블라스터로 처치'],
        info : '메르시의 10분당 블라스터로 처치수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/mercy.png',
    },
    {
        name: '[메르시] 자가 치유량',
        value : ['mercy','자가 치유 - 10분당 평균','자가 치유량','10분당 평균','[메르시] 자가 치유량'],
        info : '메르시의 10분당 자가 치유량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/mercy.png',
    },
    {
        name: '[메르시] 공격형 도움',
        value : ['mercy','공격형 도움 - 10분당 평균','공격형 도움','10분당 평균','[메르시] 공격형 도움'],
        info : '메르시의 10분당 공격형 도움',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/mercy.png',
    },
    {
        name: '[메르시] 방어형 도움',
        value : ['mercy','방어형 도움 - 10분당 평균','방어형 도움','10분당 평균','[메르시] 방어형 도움'],
        info : '메르시의 10분당 방어형 도움',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/mercy.png',
    },
    // 메이 
    {
        name: '[메이] 플레이 시간',
        value : ['mei','플레이 시간','플레이 시간',null,'[메이] 플레이시간'],
        info : '메이의 플레이 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/mei.png',
    },
    {
        name: '[메이] 승률',
        value : ['mei','승률','승률' ,null,'[메이] 승률'],
        info : '메이 영웅의 승률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/mei.png',
    },
    {
        name: '[메이] 목숨당 처치',
        value : ['mei','목숨당 처치','목숨당 처치','게임당 평균','[메이] 목숨당 처치'],
        info : '메이의 목숨당 처치횟수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/mei.png',
    },
    {
        name: '[메이] 명중률',
        value : ['mei','명중률','명중률','게임당 평균','[메이] 명중률'],
        info : '메이의 명중률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/mei.png',
    },
    {
        name: '[메이] 치명타',
        value : ['mei','치명타 - 10분당 평균','치명타','10분당 평균','[메이] 치명타'],
        info : '메이의 10분당 평균 치명타 (헤드샷)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/mei.png',
    },
    {
        name: '[메이] 치명타 명중률',
        value : ['mei','치명타 명중률','치명타 명중률','게임당 평균','[메이] 치명타명중률'],
        info : '메이의 치명타 명중률 (헤드샷 명중률)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/mei.png',
    },
    {
        name: '[메이] 처치수',
        value : ['mei','처치 - 10분당 평균','처치수','10분당 평균','[메이] 처치수'],
        info : '메이의 10분당 평균 처지수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/mei.png',
    },
    {
        name: '[메이] 죽은횟수',
        value : ['mei','죽음 - 10분당 평균','죽은 횟수','10분당 평균','[메이] 죽은횟수'],
        info : '메이의 10분당 평균 죽은횟수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/mei.png',
    },
    {
        name: '[메이] 치유량',
        value : ['mei','치유량 - 10분당 평균','치유량','10분당 평균','[메이] 치유량'],
        info : '메이의 10분당 평균 치유량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/mei.png',
    },
    {
        name: '[메이] 결정타',
        value : ['mei','결정타 - 10분당 평균','결정타 수','10분당 평균','[메이] 결정타'],
        info : '메이의 10분당 평균 결정타 (막타)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/mei.png',
    },
    {
        name: '[메이] 단독처치',
        value : ['mei','단독 처치 - 10분당 평균','단독 처치수','10분당 평균','[메이] 단독처치'],
        info : '메이의 10분당 단독 처치수 (솔로킬 수)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/mei.png',
    },
    {
        name: '[메이] 영웅피해량',
        value : ['mei','영웅에게 준 피해 - 10분당 평균','영웅에게 준 피해량','10분당 평균','[메이] 영웅피해량'],
        info : '메이의 10분당 상대 영웅에 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/mei.png',
    },
    {
        name: '[메이] 방벽피해량',
        value : ['mei','방벽에 준 피해 - 10분당 평균','방벽에게 준 피해량','10분당 평균','[메이] 방벽피해량'],
        info : '메이의 10분당 방벽에 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/mei.png',
    },
    {
        name: '[메이] 모든피해량',
        value : ['mei','준 모든 피해 - 10분당 평균','준 모든 피해량','10분당 평균','[메이] 모든피해량'],
        info : '메이의 10분당 방벽과 상대 영웅 에게 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/mei.png',
    },
    {
        name: '[메이] 임무 기여 시간',
        value : ['mei','임무 기여 시간 - 10분당 평균','임무 기여 시간','10분당 평균','[메이] 임무기여시간'],
        info : '메이의 10분당 임무 기여 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/mei.png',
    },
    {
        name: '[메이] 임무 기여 처치',
        value : ['mei','임무 기여 처치 - 10분당 평균','임무 기여 처치','10분당 평균','[메이] 임무기여처치'],
        info : '메이의 10분당 임무 기여 처지수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/mei.png',
    },
    {
        name: '[메이] 폭주 시간',
        value : ['mei','폭주 시간 - 10분당 평균','폭주 시간','10분당 평균','[메이] 폭주 시간'],
        info : '메이의 10분당 폭주 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/mei.png',
    },
    {
        name: '[메이] 근접 공격 적중률',
        value : ['mei','근접 공격 적중률','근접 공격 적중률','게임당 평균','[메이] 근접 공격 적중률'],
        info : '메이의 근접 공격 적중률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/mei.png',
    },
    {
        name: '[메이] 눈보라로 처치',
        value : ['mei','눈보라로 처치 - 10분당 평균','눈보라로 처치','10분당 평균','[메이] 눈보라로 처치'],
        info : '메이의 10분당 눈보라로 처치수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/mei.png',
    },
    {
        name: '[메이] 빙벽으로 막은 피해',
        value : ['mei','막은 피해 - 10분당 평균','빙벽으로 막은 피해','10분당 평균','[메이] 빙벽으로 막은 피해'],
        info : '메이의 10분당 빙벽으로 막은 피해',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/mei.png',
    },
    {
        name: '[메이] 얼린 적',
        value : ['mei','얼린 적 - 10분당 평균','얼린 적','10분당 평균','[메이] 얼린 적'],
        info : '메이의 10분당 얼린 적수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/mei.png',
    },
    {
        name: '[메이] 자가 치유량',
        value : ['mei','자가 치유 - 10분당 평균','자가 치유량','10분당 평균','[메이] 자가 치유량'],
        info : '메이의 10분당 자가 치유량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/mei.png',
    },
     // 모이라 
     {
        name: '[모이라] 플레이 시간',
        value : ['moira','플레이 시간','플레이 시간',null,'[모이라] 플레이시간'],
        info : '모이라의 플레이 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/moira.png',
    },
    {
        name: '[모이라] 승률',
        value : ['moira','승률','승률' ,null,'[모이라] 승률'],
        info : '모이라 영웅의 승률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/moira.png',
    },
    {
        name: '[모이라] 목숨당 처치',
        value : ['moira','목숨당 처치','목숨당 처치','게임당 평균','[모이라] 목숨당 처치'],
        info : '모이라의 목숨당 처치횟수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/moira.png',
    },
    {
        name: '[모이라] 명중률',
        value : ['moira','명중률','명중률','게임당 평균','[모이라] 명중률'],
        info : '모이라의 명중률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/moira.png',
    },
    {
        name: '[모이라] 치명타',
        value : ['moira','치명타 - 10분당 평균','치명타','10분당 평균','[모이라] 치명타'],
        info : '모이라의 10분당 평균 치명타 (헤드샷)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/moira.png',
    },
    {
        name: '[모이라] 치명타 명중률',
        value : ['moira','치명타 명중률','치명타 명중률','게임당 평균','[모이라] 치명타명중률'],
        info : '모이라의 치명타 명중률 (헤드샷 명중률)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/moira.png',
    },
    {
        name: '[모이라] 처치수',
        value : ['moira','처치 - 10분당 평균','처치수','10분당 평균','[모이라] 처치수'],
        info : '모이라의 10분당 평균 처지수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/moira.png',
    },
    {
        name: '[모이라] 죽은횟수',
        value : ['moira','죽음 - 10분당 평균','죽은 횟수','10분당 평균','[모이라] 죽은횟수'],
        info : '모이라의 10분당 평균 죽은횟수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/moira.png',
    },
    {
        name: '[모이라] 치유량',
        value : ['moira','치유량 - 10분당 평균','치유량','10분당 평균','[모이라] 치유량'],
        info : '모이라의 10분당 평균 치유량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/moira.png',
    },
    {
        name: '[모이라] 결정타',
        value : ['moira','결정타 - 10분당 평균','결정타 수','10분당 평균','[모이라] 결정타'],
        info : '모이라의 10분당 평균 결정타 (막타)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/moira.png',
    },
    {
        name: '[모이라] 단독처치',
        value : ['moira','단독 처치 - 10분당 평균','단독 처치수','10분당 평균','[모이라] 단독처치'],
        info : '모이라의 10분당 단독 처치수 (솔로킬 수)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/moira.png',
    },
    {
        name: '[모이라] 영웅피해량',
        value : ['moira','영웅에게 준 피해 - 10분당 평균','영웅에게 준 피해량','10분당 평균','[모이라] 영웅피해량'],
        info : '모이라의 10분당 상대 영웅에 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/moira.png',
    },
    {
        name: '[모이라] 방벽피해량',
        value : ['moira','방벽에 준 피해 - 10분당 평균','방벽에게 준 피해량','10분당 평균','[모이라] 방벽피해량'],
        info : '모이라의 10분당 방벽에 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/moira.png',
    },
    {
        name: '[모이라] 모든피해량',
        value : ['moira','준 모든 피해 - 10분당 평균','준 모든 피해량','10분당 평균','[모이라] 모든피해량'],
        info : '모이라의 10분당 방벽과 상대 영웅 에게 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/moira.png',
    },
    {
        name: '[모이라] 임무 기여 시간',
        value : ['moira','임무 기여 시간 - 10분당 평균','임무 기여 시간','10분당 평균','[모이라] 임무기여시간'],
        info : '모이라의 10분당 임무 기여 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/moira.png',
    },
    {
        name: '[모이라] 임무 기여 처치',
        value : ['moira','임무 기여 처치 - 10분당 평균','임무 기여 처치','10분당 평균','[모이라] 임무기여처치'],
        info : '모이라의 10분당 임무 기여 처지수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/moira.png',
    },
    {
        name: '[모이라] 폭주 시간',
        value : ['moira','폭주 시간 - 10분당 평균','폭주 시간','10분당 평균','[모이라] 폭주 시간'],
        info : '모이라의 10분당 폭주 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/moira.png',
    },
    {
        name: '[모이라] 근접 공격 적중률',
        value : ['moira','근접 공격 적중률','근접 공격 적중률','게임당 평균','[모이라] 근접 공격 적중률'],
        info : '모이라의 근접 공격 적중률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/moira.png',
    },
    {
        name: '[모이라] 생체 손아귀 적중률',
        value : ['moira','보조 발사 적중률','생체 손아귀 적중률','10분당 평균','[모이라] 생체 손아귀 적중률'],
        info : '모이라의 생체 손아귀 (우클릭 보조공격) 적중률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/moira.png',
    },
    {
        name: '[모이라] 융화로 처치',
        value : ['moira','융화로 처치 - 10분당 평균','융화로 처치','10분당 평균','[모이라] 융화로 처치'],
        info : '모이라의 10분당 융화로 처치수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/moira.png',
    },
    {
        name: '[모이라] 융화로 치유',
        value : ['moira','융화로 치유 - 10분당 평균','융화로 치유','10분당 평균','[모이라] 융화로 치유'],
        info : '모이라의 10분당 융화로 치유량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/moira.png',
    },
    {
        name: '[모이라] 방어형 도움',
        value : ['moira','방어형 도움 - 10분당 평균','방어형 도움','10분당 평균','[모이라] 방어형 도움'],
        info : '방어형 도움',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/moira.png',
    },
    // 바스티온 
    {
        name: '[바스티온] 플레이 시간',
        value : ['bastion','플레이 시간','플레이 시간',null,'[바스티온] 플레이시간'],
        info : '바스티온의 플레이 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/bastion.png',
    },
    {
        name: '[바스티온] 승률',
        value : ['bastion','승률','승률' ,null,'[바스티온] 승률'],
        info : '바스티온 영웅의 승률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/bastion.png',
    },
    {
        name: '[바스티온] 목숨당 처치',
        value : ['bastion','목숨당 처치','목숨당 처치','게임당 평균','[바스티온] 목숨당 처치'],
        info : '바스티온의 목숨당 처치횟수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/bastion.png',
    },
    {
        name: '[바스티온] 명중률',
        value : ['bastion','명중률','명중률','게임당 평균','[바스티온] 명중률'],
        info : '바스티온의 명중률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/bastion.png',
    },
    {
        name: '[바스티온] 치명타',
        value : ['bastion','치명타 - 10분당 평균','치명타','10분당 평균','[바스티온] 치명타'],
        info : '바스티온의 10분당 평균 치명타 (헤드샷)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/bastion.png',
    },
    {
        name: '[바스티온] 치명타 명중률',
        value : ['bastion','치명타 명중률','치명타 명중률','게임당 평균','[바스티온] 치명타명중률'],
        info : '바스티온의 치명타 명중률 (헤드샷 명중률)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/bastion.png',
    },
    {
        name: '[바스티온] 처치수',
        value : ['bastion','처치 - 10분당 평균','처치수','10분당 평균','[바스티온] 처치수'],
        info : '바스티온의 10분당 평균 처지수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/bastion.png',
    },
    {
        name: '[바스티온] 죽은횟수',
        value : ['bastion','죽음 - 10분당 평균','죽은 횟수','10분당 평균','[바스티온] 죽은횟수'],
        info : '바스티온의 10분당 평균 죽은횟수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/bastion.png',
    },
    {
        name: '[바스티온] 치유량',
        value : ['bastion','치유량 - 10분당 평균','치유량','10분당 평균','[바스티온] 치유량'],
        info : '바스티온의 10분당 평균 치유량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/bastion.png',
    },
    {
        name: '[바스티온] 결정타',
        value : ['bastion','결정타 - 10분당 평균','결정타 수','10분당 평균','[바스티온] 결정타'],
        info : '바스티온의 10분당 평균 결정타 (막타)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/bastion.png',
    },
    {
        name: '[바스티온] 단독처치',
        value : ['bastion','단독 처치 - 10분당 평균','단독 처치수','10분당 평균','[바스티온] 단독처치'],
        info : '바스티온의 10분당 단독 처치수 (솔로킬 수)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/bastion.png',
    },
    {
        name: '[바스티온] 영웅피해량',
        value : ['bastion','영웅에게 준 피해 - 10분당 평균','영웅에게 준 피해량','10분당 평균','[바스티온] 영웅피해량'],
        info : '바스티온의 10분당 상대 영웅에 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/bastion.png',
    },
    {
        name: '[바스티온] 방벽피해량',
        value : ['bastion','방벽에 준 피해 - 10분당 평균','방벽에게 준 피해량','10분당 평균','[바스티온] 방벽피해량'],
        info : '바스티온의 10분당 방벽에 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/bastion.png',
    },
    {
        name: '[바스티온] 모든피해량',
        value : ['bastion','준 모든 피해 - 10분당 평균','준 모든 피해량','10분당 평균','[바스티온] 모든피해량'],
        info : '바스티온의 10분당 방벽과 상대 영웅 에게 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/bastion.png',
    },
    {
        name: '[바스티온] 임무 기여 시간',
        value : ['bastion','임무 기여 시간 - 10분당 평균','임무 기여 시간','10분당 평균','[바스티온] 임무기여시간'],
        info : '바스티온의 10분당 임무 기여 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/bastion.png',
    },
    {
        name: '[바스티온] 임무 기여 처치',
        value : ['bastion','임무 기여 처치 - 10분당 평균','임무 기여 처치','10분당 평균','[바스티온] 임무기여처치'],
        info : '바스티온의 10분당 임무 기여 처지수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/bastion.png',
    },
    {
        name: '[바스티온] 폭주 시간',
        value : ['bastion','폭주 시간 - 10분당 평균','폭주 시간','10분당 평균','[바스티온] 폭주 시간'],
        info : '바스티온의 10분당 폭주 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/bastion.png',
    },
    {
        name: '[바스티온] 근접 공격 적중률',
        value : ['bastion','근접 공격 적중률','근접 공격 적중률','게임당 평균','[바스티온] 근접 공격 적중률'],
        info : '바스티온의 근접 공격 적중률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/bastion.png',
    },
    {
        name: '[바스티온] 경계 모드로 처치',
        value : ['bastion','경계 모드로 처치 - 10분당 평균','경계 모드로 처치','10분당 평균','[바스티온] 경계 모드로 처치'],
        info : '바스티온의 10분당 경계 모드로 처치수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/bastion.png',
    },
    {
        name: '[바스티온] 수색 모드로 처치',
        value : ['bastion','수색 모드로 처치 - 10분당 평균','수색 모드로 처치','10분당 평균','[바스티온] 수색 모드로 처치'],
        info : '바스티온의 10분당 수색 모드로 처치수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/bastion.png',
    },
    {
        name: '[바스티온] 전차 모드로 처치',
        value : ['bastion','전차 모드로 처치 - 10분당 평균','전차 모드로 처치','10분당 평균','[바스티온] 전차 모드로 처치'],
        info : '바스티온의 10분당 전차 모드로 처치수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/bastion.png',
    },
    {
        name: '[바스티온] 자가 치유량',
        value : ['bastion','자가 치유 - 10분당 평균','자가 치유량','10분당 평균','[바스티온] 자가 치유량'],
        info : '바스티온의 10분당 자가 치유량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/bastion.png',
    },
     // 브리기테 
     {
        name: '[브리기테] 플레이 시간',
        value : ['brigitte','플레이 시간','플레이 시간',null,'[브리기테] 플레이시간'],
        info : '브리기테의 플레이 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/brigitte.png',
    },
    {
        name: '[브리기테] 승률',
        value : ['brigitte','승률','승률' ,null,'[브리기테] 승률'],
        info : '브리기테 영웅의 승률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/brigitte.png',
    },
    {
        name: '[브리기테] 목숨당 처치',
        value : ['brigitte','목숨당 처치','목숨당 처치','게임당 평균','[브리기테] 목숨당 처치'],
        info : '브리기테의 목숨당 처치횟수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/brigitte.png',
    },
    {
        name: '[브리기테] 명중률',
        value : ['brigitte','명중률','명중률','게임당 평균','[브리기테] 명중률'],
        info : '브리기테의 명중률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/brigitte.png',
    },
    {
        name: '[브리기테] 치명타',
        value : ['brigitte','치명타 - 10분당 평균','치명타','10분당 평균','[브리기테] 치명타'],
        info : '브리기테의 10분당 평균 치명타 (헤드샷)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/brigitte.png',
    },
    {
        name: '[브리기테] 치명타 명중률',
        value : ['brigitte','치명타 명중률','치명타 명중률','게임당 평균','[브리기테] 치명타명중률'],
        info : '브리기테의 치명타 명중률 (헤드샷 명중률)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/brigitte.png',
    },
    {
        name: '[브리기테] 처치수',
        value : ['brigitte','처치 - 10분당 평균','처치수','10분당 평균','[브리기테] 처치수'],
        info : '브리기테의 10분당 평균 처지수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/brigitte.png',
    },
    {
        name: '[브리기테] 죽은횟수',
        value : ['brigitte','죽음 - 10분당 평균','죽은 횟수','10분당 평균','[브리기테] 죽은횟수'],
        info : '브리기테의 10분당 평균 죽은횟수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/brigitte.png',
    },
    {
        name: '[브리기테] 치유량',
        value : ['brigitte','치유량 - 10분당 평균','치유량','10분당 평균','[브리기테] 치유량'],
        info : '브리기테의 10분당 평균 치유량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/brigitte.png',
    },
    {
        name: '[브리기테] 결정타',
        value : ['brigitte','결정타 - 10분당 평균','결정타 수','10분당 평균','[브리기테] 결정타'],
        info : '브리기테의 10분당 평균 결정타 (막타)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/brigitte.png',
    },
    {
        name: '[브리기테] 단독처치',
        value : ['brigitte','단독 처치 - 10분당 평균','단독 처치수','10분당 평균','[브리기테] 단독처치'],
        info : '브리기테의 10분당 단독 처치수 (솔로킬 수)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/brigitte.png',
    },
    {
        name: '[브리기테] 영웅피해량',
        value : ['brigitte','영웅에게 준 피해 - 10분당 평균','영웅에게 준 피해량','10분당 평균','[브리기테] 영웅피해량'],
        info : '브리기테의 10분당 상대 영웅에 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/brigitte.png',
    },
    {
        name: '[브리기테] 방벽피해량',
        value : ['brigitte','방벽에 준 피해 - 10분당 평균','방벽에게 준 피해량','10분당 평균','[브리기테] 방벽피해량'],
        info : '브리기테의 10분당 방벽에 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/brigitte.png',
    },
    {
        name: '[브리기테] 모든피해량',
        value : ['brigitte','준 모든 피해 - 10분당 평균','준 모든 피해량','10분당 평균','[브리기테] 모든피해량'],
        info : '브리기테의 10분당 방벽과 상대 영웅 에게 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/brigitte.png',
    },
    {
        name: '[브리기테] 임무 기여 시간',
        value : ['brigitte','임무 기여 시간 - 10분당 평균','임무 기여 시간','10분당 평균','[브리기테] 임무기여시간'],
        info : '브리기테의 10분당 임무 기여 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/brigitte.png',
    },
    {
        name: '[브리기테] 임무 기여 처치',
        value : ['brigitte','임무 기여 처치 - 10분당 평균','임무 기여 처치','10분당 평균','[브리기테] 임무기여처치'],
        info : '브리기테의 10분당 임무 기여 처지수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/brigitte.png',
    },
    {
        name: '[브리기테] 폭주 시간',
        value : ['brigitte','폭주 시간 - 10분당 평균','폭주 시간','10분당 평균','[브리기테] 폭주 시간'],
        info : '브리기테의 10분당 임무 기여 처지수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/brigitte.png',
    },
    {
        name: '[브리기테] 격려 활성 시간 비율',
        value : ['brigitte','격려 활성 시간 비율','폭주 시간','게임당 평균','[브리기테] 격려 활성 시간 비율'],
        info : '브리기테의 격려 활성 시간 비율',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/brigitte.png',
    },
    {
        name: '[브리기테] 방벽으로 막은 피해량',
        value : ['brigitte','막은 피해 - 10분당 평균','방벽으로 막은 피해량','10분당 평균','[브리기테] 방벽으로 막은 피해량'],
        info : '브리기테의 10분당 방벽으로 막은 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/brigitte.png',
    },
    {
        name: '[브리기테] 수리팩(쿠키) 방어력 제공량',
        value : ['brigitte','방어력 제공 - 10분당 평균','수리팩(쿠키) 방어력 제공량','10분당 평균','수리팩(쿠키) 방어력 제공량'],
        info : '브리기테의 10분당 수리팩(쿠키) 방어력 제공량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/brigitte.png',
    },
    {
        name: '[브리기테] 자가 치유량',
        value : ['brigitte','자가 치유 - 10분당 평균','자가 치유량','10분당 평균','[브리기테] 자가 치유량'],
        info : '브리기테의 10분당 자가 치유량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/brigitte.png',
    },
    {
        name: '[브리기테] 공격형 도움',
        value : ['brigitte','공격형 도움 - 10분당 평균','공격형 도움','10분당 평균','[브리기테] 공격형 도움'],
        info : '브리기테의 공격형 도움',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/brigitte.png',
    },
    {
        name: '[브리기테] 방어형 도움',
        value : ['brigitte','방어형 도움 - 10분당 평균','방어형 도움','10분당 평균','[브리기테] 방어형 도움'],
        info : '브리기테의 방어형 도움',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/brigitte.png',
    },
    // 솔저:76 
    {
        name: '[솔저:76] 플레이 시간',
        value : ['soldier-76','플레이 시간','플레이 시간',null,'[솔저:76] 플레이시간'],
        info : '솔저:76의 플레이 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/soldier-76.png',
    },
    {
        name: '[솔저:76] 승률',
        value : ['soldier-76','승률','승률' ,null,'[솔저:76] 승률'],
        info : '솔저:76 영웅의 승률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/soldier-76.png',
    },
    {
        name: '[솔저:76] 목숨당 처치',
        value : ['soldier-76','목숨당 처치','목숨당 처치','게임당 평균','[솔저:76] 목숨당 처치'],
        info : '솔저:76의 목숨당 처치횟수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/soldier-76.png',
    },
    {
        name: '[솔저:76] 명중률',
        value : ['soldier-76','명중률','명중률','게임당 평균','[솔저:76] 명중률'],
        info : '솔저:76의 명중률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/soldier-76.png',
    },
    {
        name: '[솔저:76] 치명타',
        value : ['soldier-76','치명타 - 10분당 평균','치명타','10분당 평균','[솔저:76] 치명타'],
        info : '솔저:76의 10분당 평균 치명타 (헤드샷)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/soldier-76.png',
    },
    {
        name: '[솔저:76] 치명타 명중률',
        value : ['soldier-76','치명타 명중률','치명타 명중률','게임당 평균','[솔저:76] 치명타명중률'],
        info : '솔저:76의 치명타 명중률 (헤드샷 명중률)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/soldier-76.png',
    },
    {
        name: '[솔저:76] 처치수',
        value : ['soldier-76','처치 - 10분당 평균','처치수','10분당 평균','[솔저:76] 처치수'],
        info : '솔저:76의 10분당 평균 처지수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/soldier-76.png',
    },
    {
        name: '[솔저:76] 죽은횟수',
        value : ['soldier-76','죽음 - 10분당 평균','죽은 횟수','10분당 평균','[솔저:76] 죽은횟수'],
        info : '솔저:76의 10분당 평균 죽은횟수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/soldier-76.png',
    },
    {
        name: '[솔저:76] 치유량',
        value : ['soldier-76','치유량 - 10분당 평균','치유량','10분당 평균','[솔저:76] 치유량'],
        info : '솔저:76의 10분당 평균 치유량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/soldier-76.png',
    },
    {
        name: '[솔저:76] 결정타',
        value : ['soldier-76','결정타 - 10분당 평균','결정타 수','10분당 평균','[솔저:76] 결정타'],
        info : '솔저:76의 10분당 평균 결정타 (막타)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/soldier-76.png',
    },
    {
        name: '[솔저:76] 단독처치',
        value : ['soldier-76','단독 처치 - 10분당 평균','단독 처치수','10분당 평균','[솔저:76] 단독처치'],
        info : '솔저:76의 10분당 단독 처치수 (솔로킬 수)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/soldier-76.png',
    },
    {
        name: '[솔저:76] 영웅피해량',
        value : ['soldier-76','영웅에게 준 피해 - 10분당 평균','영웅에게 준 피해량','10분당 평균','[솔저:76] 영웅피해량'],
        info : '솔저:76의 10분당 상대 영웅에 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/soldier-76.png',
    },
    {
        name: '[솔저:76] 방벽피해량',
        value : ['soldier-76','방벽에 준 피해 - 10분당 평균','방벽에게 준 피해량','10분당 평균','[솔저:76] 방벽피해량'],
        info : '솔저:76의 10분당 방벽에 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/soldier-76.png',
    },
    {
        name: '[솔저:76] 모든피해량',
        value : ['soldier-76','준 모든 피해 - 10분당 평균','준 모든 피해량','10분당 평균','[솔저:76] 모든피해량'],
        info : '솔저:76의 10분당 방벽과 상대 영웅 에게 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/soldier-76.png',
    },
    {
        name: '[솔저:76] 임무 기여 시간',
        value : ['soldier-76','임무 기여 시간 - 10분당 평균','임무 기여 시간','10분당 평균','[솔저:76] 임무기여시간'],
        info : '솔저:76의 10분당 임무 기여 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/soldier-76.png',
    },
    {
        name: '[솔저:76] 임무 기여 처치',
        value : ['soldier-76','임무 기여 처치 - 10분당 평균','임무 기여 처치','10분당 평균','[솔저:76] 임무기여처치'],
        info : '솔저:76의 10분당 임무 기여 처지수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/soldier-76.png',
    },
    {
        name: '[솔저:76] 폭주 시간',
        value : ['soldier-76','폭주 시간 - 10분당 평균','폭주 시간','10분당 평균','[솔저:76] 폭주 시간'],
        info : '솔저:76의 10분당 폭주 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/soldier-76.png',
    },
    {
        name: '[솔저:76] 근접 공격 적중률',
        value : ['soldier-76','근접 공격 적중률','폭주 시간','게임당 평균','[솔저:76] 근접 공격 적중률'],
        info : '솔저:76의 근접 공격 적중률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/soldier-76.png',
    },
    {
        name: '[솔저:76] 나선 로켓으로 처치',
        value : ['soldier-76','나선 로켓으로 처치 UNDEFINED:회 - 10분당 평균','나선 로켓으로 처치','10분당 평균','[솔저:76] 나선 로켓으로 처치'],
        info : '솔저:76의 10분당 나선 로켓으로 처치수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/soldier-76.png',
    },
    {
        name: '[솔저:76] 자가 치유량',
        value : ['soldier-76','자가 치유 - 10분당 평균','자가 치유량','10분당 평균','[솔저:76] 자가 치유량'],
        info : '솔저:76의 10분당 자가 치유량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/soldier-76.png',
    },
    {
        name: '[솔저:76] 전술 조준경으로 처치',
        value : ['soldier-76','전술 조준경으로 처치 - 10분당 평균','전술 조준경으로 처치','10분당 평균','[솔저:76] 전술 조준경으로 처치'],
        info : '솔저:76의 10분당 전술 조준경으로 처치수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/soldier-76.png',
    },
    {
        name: '[솔저:76] 방어형 도움',
        value : ['soldier-76','방어형 도움','방어형 도움','10분당 평균','[솔저:76] 방어형 도움'],
        info : '솔저:76의 방어형 도움',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/soldier-76.png',
    },
    // 솜브라 
    {
        name: '[솜브라] 플레이 시간',
        value : ['sombra','플레이 시간','플레이 시간',null,'[솜브라] 플레이시간'],
        info : '솜브라의 플레이 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/sombra.png',
    },
    {
        name: '[솜브라] 승률',
        value : ['sombra','승률','승률' ,null,'[솜브라] 승률'],
        info : '솜브라 영웅의 승률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/sombra.png',
    },
    {
        name: '[솜브라] 목숨당 처치',
        value : ['sombra','목숨당 처치','목숨당 처치','게임당 평균','[솜브라] 목숨당 처치'],
        info : '솜브라의 목숨당 처치횟수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/sombra.png',
    },
    {
        name: '[솜브라] 명중률',
        value : ['sombra','명중률','명중률','게임당 평균','[솜브라] 명중률'],
        info : '솜브라의 명중률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/sombra.png',
    },
    {
        name: '[솜브라] 치명타',
        value : ['sombra','치명타 - 10분당 평균','치명타','10분당 평균','[솜브라] 치명타'],
        info : '솜브라의 10분당 평균 치명타 (헤드샷)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/sombra.png',
    },
    {
        name: '[솜브라] 치명타 명중률',
        value : ['sombra','치명타 명중률','치명타 명중률','게임당 평균','[솜브라] 치명타명중률'],
        info : '솜브라의 치명타 명중률 (헤드샷 명중률)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/sombra.png',
    },
    {
        name: '[솜브라] 처치수',
        value : ['sombra','처치 - 10분당 평균','처치수','10분당 평균','[솜브라] 처치수'],
        info : '솜브라의 10분당 평균 처지수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/sombra.png',
    },
    {
        name: '[솜브라] 죽은횟수',
        value : ['sombra','죽음 - 10분당 평균','죽은 횟수','10분당 평균','[솜브라] 죽은횟수'],
        info : '솜브라의 10분당 평균 죽은횟수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/sombra.png',
    },
    {
        name: '[솜브라] 치유량',
        value : ['sombra','치유량 - 10분당 평균','치유량','10분당 평균','[솜브라] 치유량'],
        info : '솜브라의 10분당 평균 치유량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/sombra.png',
    },
    {
        name: '[솜브라] 결정타',
        value : ['sombra','결정타 - 10분당 평균','결정타 수','10분당 평균','[솜브라] 결정타'],
        info : '솜브라의 10분당 평균 결정타 (막타)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/sombra.png',
    },
    {
        name: '[솜브라] 단독처치',
        value : ['sombra','단독 처치 - 10분당 평균','단독 처치수','10분당 평균','[솜브라] 단독처치'],
        info : '솜브라의 10분당 단독 처치수 (솔로킬 수)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/sombra.png',
    },
    {
        name: '[솜브라] 영웅피해량',
        value : ['sombra','영웅에게 준 피해 - 10분당 평균','영웅에게 준 피해량','10분당 평균','[솜브라] 영웅피해량'],
        info : '솜브라의 10분당 상대 영웅에 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/sombra.png',
    },
    {
        name: '[솜브라] 방벽피해량',
        value : ['sombra','방벽에 준 피해 - 10분당 평균','방벽에게 준 피해량','10분당 평균','[솜브라] 방벽피해량'],
        info : '솜브라의 10분당 방벽에 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/sombra.png',
    },
    {
        name: '[솜브라] 모든피해량',
        value : ['sombra','준 모든 피해 - 10분당 평균','준 모든 피해량','10분당 평균','[솜브라] 모든피해량'],
        info : '솜브라의 10분당 방벽과 상대 영웅 에게 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/sombra.png',
    },
    {
        name: '[솜브라] 임무 기여 시간',
        value : ['sombra','임무 기여 시간 - 10분당 평균','임무 기여 시간','10분당 평균','[솜브라] 임무기여시간'],
        info : '솜브라의 10분당 임무 기여 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/sombra.png',
    },
    {
        name: '[솜브라] 임무 기여 처치',
        value : ['sombra','임무 기여 처치 - 10분당 평균','임무 기여 처치','10분당 평균','[솜브라] 임무기여처치'],
        info : '솜브라의 10분당 임무 기여 처지수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/sombra.png',
    },
    {
        name: '[솜브라] 폭주 시간',
        value : ['sombra','폭주 시간 - 10분당 평균','폭주 시간','10분당 평균','[솜브라] 폭주 시간'],
        info : '솜브라의 10분당 폭주 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/sombra.png',
    },
    {
        name: '[솜브라] 근접 공격 적중률',
        value : ['sombra','근접 공격 적중률','근접 공격 적중률','게임당 평균','[솜브라] 근접 공격 적중률'],
        info : '솜브라의 근접 공격 적중률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/sombra.png',
    },
    {
        name: '[솜브라] EMP 맞힌 적',
        value : ['sombra','EMP 맞힌 적 - 10분당 평균','EMP 맞힌 적','10분당 평균','[솜브라] EMP 맞힌 적'],
        info : '솜브라의 10분당 EMP 맞힌 적수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/sombra.png',
    },
    {
        name: '[솜브라] 해킹한 적',
        value : ['sombra','해킹한 적 - 10분당 평균','해킹한 적','10분당 평균','[솜브라] 해킹한 적'],
        info : '솜브라의 10분당 해킹한 적수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/sombra.png',
    },
     // 시그마 
     {
        name: '[시그마] 플레이 시간',
        value : ['sigma','플레이 시간','플레이 시간',null,'[시그마] 플레이시간'],
        info : '시그마의 플레이 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/sigma.png',
    },
    {
        name: '[시그마] 승률',
        value : ['sigma','승률','승률' ,null,'[시그마] 승률'],
        info : '시그마 영웅의 승률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/sigma.png',
    },
    {
        name: '[시그마] 목숨당 처치',
        value : ['sigma','목숨당 처치','목숨당 처치','게임당 평균','[시그마] 목숨당 처치'],
        info : '시그마의 목숨당 처치횟수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/sigma.png',
    },
    {
        name: '[시그마] 명중률',
        value : ['sigma','명중률','명중률','게임당 평균','[시그마] 명중률'],
        info : '시그마의 명중률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/sigma.png',
    },
    {
        name: '[시그마] 치명타',
        value : ['sigma','치명타 - 10분당 평균','치명타','10분당 평균','[시그마] 치명타'],
        info : '시그마의 10분당 평균 치명타 (헤드샷)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/sigma.png',
    },
    {
        name: '[시그마] 치명타 명중률',
        value : ['sigma','치명타 명중률','치명타 명중률','게임당 평균','[시그마] 치명타명중률'],
        info : '시그마의 치명타 명중률 (헤드샷 명중률)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/sigma.png',
    },
    {
        name: '[시그마] 처치수',
        value : ['sigma','처치 - 10분당 평균','처치수','10분당 평균','[시그마] 처치수'],
        info : '시그마의 10분당 평균 처지수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/sigma.png',
    },
    {
        name: '[시그마] 죽은횟수',
        value : ['sigma','죽음 - 10분당 평균','죽은 횟수','10분당 평균','[시그마] 죽은횟수'],
        info : '시그마의 10분당 평균 죽은횟수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/sigma.png',
    },
    {
        name: '[시그마] 치유량',
        value : ['sigma','치유량 - 10분당 평균','치유량','10분당 평균','[시그마] 치유량'],
        info : '시그마의 10분당 평균 치유량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/sigma.png',
    },
    {
        name: '[시그마] 결정타',
        value : ['sigma','결정타 - 10분당 평균','결정타 수','10분당 평균','[시그마] 결정타'],
        info : '시그마의 10분당 평균 결정타 (막타)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/sigma.png',
    },
    {
        name: '[시그마] 단독처치',
        value : ['sigma','단독 처치 - 10분당 평균','단독 처치수','10분당 평균','[시그마] 단독처치'],
        info : '시그마의 10분당 단독 처치수 (솔로킬 수)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/sigma.png',
    },
    {
        name: '[시그마] 영웅피해량',
        value : ['sigma','영웅에게 준 피해 - 10분당 평균','영웅에게 준 피해량','10분당 평균','[시그마] 영웅피해량'],
        info : '시그마의 10분당 상대 영웅에 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/sigma.png',
    },
    {
        name: '[시그마] 방벽피해량',
        value : ['sigma','방벽에 준 피해 - 10분당 평균','방벽에게 준 피해량','10분당 평균','[시그마] 방벽피해량'],
        info : '시그마의 10분당 방벽에 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/sigma.png',
    },
    {
        name: '[시그마] 모든피해량',
        value : ['sigma','준 모든 피해 - 10분당 평균','준 모든 피해량','10분당 평균','[시그마] 모든피해량'],
        info : '시그마의 10분당 방벽과 상대 영웅 에게 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/sigma.png',
    },
    {
        name: '[시그마] 임무 기여 시간',
        value : ['sigma','임무 기여 시간 - 10분당 평균','임무 기여 시간','10분당 평균','[시그마] 임무기여시간'],
        info : '시그마의 10분당 임무 기여 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/sigma.png',
    },
    {
        name: '[시그마] 임무 기여 처치',
        value : ['sigma','임무 기여 처치 - 10분당 평균','임무 기여 처치','10분당 평균','[시그마] 임무기여처치'],
        info : '시그마의 10분당 임무 기여 처지수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/sigma.png',
    },
    {
        name: '[시그마] 폭주 시간',
        value : ['sigma','폭주 시간 - 10분당 평균','폭주 시간','10분당 평균','[시그마] 폭주 시간'],
        info : '시그마의 10분당 폭주 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/sigma.png',
    },
    {
        name: '[시그마] 근접 공격 적중률',
        value : ['sigma','근접 공격 적중률','근접 공격 적중률','게임당 평균','[시그마] 근접 공격 적중률'],
        info : '시그마의 근접 공격 적중률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/sigma.png',
    },
    {
        name: '[시그마] 강착으로 처치',
        value : ['sigma','강착으로 처치 - 10분당 평균','강착으로 처치','10분당 평균','[시그마] 강착으로 처치'],
        info : '시그마의 10분당 강착으로 처치수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/sigma.png',
    },
    {
        name: '[시그마] 방벽으로 막은 피해',
        value : ['sigma','막은 피해 - 10분당 평균','방벽으로 막은 피해','10분당 평균','[시그마] 방벽으로 막은 피해'],
        info : '시그마의 10분당 방벽으로 막은 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/sigma.png',
    },
    {
        name: '[시그마] 키네틱 손아귀로 흡수한 피해',
        value : ['sigma','흡수한 피해 - 10분당 평균','키네틱 손아귀로 흡수한 피해','10분당 평균','[시그마] 키네틱 손아귀로 흡수한 피해'],
        info : '시그마의 10분당 키네틱 손아귀로 흡수한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/sigma.png',
    },
    {
        name: '[시그마] 중력 붕괴로 처치',
        value : ['sigma','중력 붕괴로 처치 - 10분당 평균','중력 붕괴로 처치','10분당 평균','[시그마] 중력 붕괴로 처치'],
        info : '시그마의 10분당 중력 붕괴로 처치수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/sigma.png',
    },
    {
        name: '[시그마] 공격형 도움',
        value : ['sigma','공격형 도움 - 10분당 평균','공격형 도움','10분당 평균','[시그마] 공격형 도움'],
        info : '시그마의 10분당 중력 붕괴로 처치수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/sigma.png',
    },
    // 시메트라 
     {
        name: '[시메트라] 플레이 시간',
        value : ['symmetra','플레이 시간','플레이 시간',null,'[시메트라] 플레이시간'],
        info : '시메트라의 플레이 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/symmetra.png',
    },
    {
        name: '[시메트라] 승률',
        value : ['symmetra','승률','승률' ,null,'[시메트라] 승률'],
        info : '시메트라 영웅의 승률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/symmetra.png',
    },
    {
        name: '[시메트라] 목숨당 처치',
        value : ['symmetra','목숨당 처치','목숨당 처치','게임당 평균','[시메트라] 목숨당 처치'],
        info : '시메트라의 목숨당 처치횟수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/symmetra.png',
    },
    {
        name: '[시메트라] 명중률',
        value : ['symmetra','명중률','명중률','게임당 평균','[시메트라] 명중률'],
        info : '시메트라의 명중률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/symmetra.png',
    },
    {
        name: '[시메트라] 치명타',
        value : ['symmetra','치명타 - 10분당 평균','치명타','10분당 평균','[시메트라] 치명타'],
        info : '시메트라의 10분당 평균 치명타 (헤드샷)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/symmetra.png',
    },
    {
        name: '[시메트라] 치명타 명중률',
        value : ['symmetra','치명타 명중률','치명타 명중률','게임당 평균','[시메트라] 치명타명중률'],
        info : '시메트라의 치명타 명중률 (헤드샷 명중률)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/symmetra.png',
    },
    {
        name: '[시메트라] 처치수',
        value : ['symmetra','처치 - 10분당 평균','처치수','10분당 평균','[시메트라] 처치수'],
        info : '시메트라의 10분당 평균 처지수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/symmetra.png',
    },
    {
        name: '[시메트라] 죽은횟수',
        value : ['symmetra','죽음 - 10분당 평균','죽은 횟수','10분당 평균','[시메트라] 죽은횟수'],
        info : '시메트라의 10분당 평균 죽은횟수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/symmetra.png',
    },
    {
        name: '[시메트라] 치유량',
        value : ['symmetra','치유량 - 10분당 평균','치유량','10분당 평균','[시메트라] 치유량'],
        info : '시메트라의 10분당 평균 치유량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/symmetra.png',
    },
    {
        name: '[시메트라] 결정타',
        value : ['symmetra','결정타 - 10분당 평균','결정타 수','10분당 평균','[시메트라] 결정타'],
        info : '시메트라의 10분당 평균 결정타 (막타)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/symmetra.png',
    },
    {
        name: '[시메트라] 단독처치',
        value : ['symmetra','단독 처치 - 10분당 평균','단독 처치수','10분당 평균','[시메트라] 단독처치'],
        info : '시메트라의 10분당 단독 처치수 (솔로킬 수)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/symmetra.png',
    },
    {
        name: '[시메트라] 영웅피해량',
        value : ['symmetra','영웅에게 준 피해 - 10분당 평균','영웅에게 준 피해량','10분당 평균','[시메트라] 영웅피해량'],
        info : '시메트라의 10분당 상대 영웅에 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/symmetra.png',
    },
    {
        name: '[시메트라] 방벽피해량',
        value : ['symmetra','방벽에 준 피해 - 10분당 평균','방벽에게 준 피해량','10분당 평균','[시메트라] 방벽피해량'],
        info : '시메트라의 10분당 방벽에 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/symmetra.png',
    },
    {
        name: '[시메트라] 모든피해량',
        value : ['symmetra','준 모든 피해 - 10분당 평균','준 모든 피해량','10분당 평균','[시메트라] 모든피해량'],
        info : '시메트라의 10분당 방벽과 상대 영웅 에게 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/symmetra.png',
    },
    {
        name: '[시메트라] 임무 기여 시간',
        value : ['symmetra','임무 기여 시간 - 10분당 평균','임무 기여 시간','10분당 평균','[시메트라] 임무기여시간'],
        info : '시메트라의 10분당 임무 기여 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/symmetra.png',
    },
    {
        name: '[시메트라] 임무 기여 처치',
        value : ['symmetra','임무 기여 처치 - 10분당 평균','임무 기여 처치','10분당 평균','[시메트라] 임무기여처치'],
        info : '시메트라의 10분당 임무 기여 처지수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/symmetra.png',
    },
    {
        name: '[시메트라] 폭주 시간',
        value : ['symmetra','폭주 시간 - 10분당 평균','폭주 시간','10분당 평균','[시메트라] 폭주 시간'],
        info : '시메트라의 10분당 폭주 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/symmetra.png',
    },
    {
        name: '[시메트라] 근접 공격 적중률',
        value : ['symmetra','근접 공격 적중률','근접 공격 적중률','게임당 평균','[시메트라] 근접 공격 적중률'],
        info : '시메트라의 근접 공격 적중률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/symmetra.png',
    },
    {
        name: '[시메트라] 감시 포탑으로 처치',
        value : ['symmetra','감시 포탑으로 처치 - 10분당 평균','감시 포탑으로 처치','10분당 평균','[시메트라] 감시 포탑으로 처치'],
        info : '시메트라의 10분당 감시 포탑으로 처치수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/symmetra.png',
    },
    {
        name: '[시메트라] 일자 광선 적중률',
        value : ['symmetra','기본 발사 적중률','일자 광선 적중률','게임당 평균','[시메트라] 일자 광선 적중률'],
        info : '일자 광선 (좌클릭) 적중률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/symmetra.png',
    },
    {
        name: '[시메트라] 구체 적중률',
        value : ['symmetra','보조 발사 적중률','구체 적중률','게임당 평균','[시메트라] 구체 적중률'],
        info : '구체 (우클릭) 적중률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/symmetra.png',
    },
    {
        name: '[시메트라] 구체 명중수',
        value : ['symmetra','보조 발사 명중 - 10분당 평균','구체 명중수','10분당 평균','[시메트라] 구체 명중수'],
        info : '시메트라의 10분당 구체 맞은 적의 숫자',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/symmetra.png',
    },
    {
        name: '[시메트라] 방벽으로 막은 피해',
        value : ['symmetra','막은 피해 - 10분당 평균','방벽으로 막은 피해','10분당 평균','[시메트라] 방벽으로 막은 피해'],
        info : '시메트라의 10분당 방벽으로 막은 피해',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/symmetra.png',
    },
    {
        name: '[시메트라] 순간이동한 플레이어',
        value : ['symmetra','순간이동한 플레이어 - 10분당 평균','순간이동한 플레이어','10분당 평균','[시메트라] 순간이동한 플레이어'],
        info : '시메트라의 10분당 순간이동한 플레이어의 숫자',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/symmetra.png',
    },
    // 아나 
    {
        name: '[아나] 플레이 시간',
        value : ['ana','플레이 시간','플레이 시간',null,'[아나] 플레이시간'],
        info : '아나의 플레이 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/ana.png',
    },
    {
        name: '[아나] 승률',
        value : ['ana','승률','승률' ,null,'[아나] 승률'],
        info : '아나 영웅의 승률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/ana.png',
    },
    {
        name: '[아나] 목숨당 처치',
        value : ['ana','목숨당 처치','목숨당 처치','게임당 평균','[아나] 목숨당 처치'],
        info : '아나의 목숨당 처치횟수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/ana.png',
    },
    {
        name: '[아나] 명중률',
        value : ['ana','명중률','명중률','게임당 평균','[아나] 명중률'],
        info : '아나의 명중률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/ana.png',
    },
    {
        name: '[아나] 치명타',
        value : ['ana','치명타 - 10분당 평균','치명타','10분당 평균','[아나] 치명타'],
        info : '아나의 10분당 평균 치명타 (헤드샷)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/ana.png',
    },
    {
        name: '[아나] 치명타 명중률',
        value : ['ana','치명타 명중률','치명타 명중률','게임당 평균','[아나] 치명타명중률'],
        info : '아나의 치명타 명중률 (헤드샷 명중률)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/ana.png',
    },
    {
        name: '[아나] 처치수',
        value : ['ana','처치 - 10분당 평균','처치수','10분당 평균','[아나] 처치수'],
        info : '아나의 10분당 평균 처지수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/ana.png',
    },
    {
        name: '[아나] 죽은횟수',
        value : ['ana','죽음 - 10분당 평균','죽은 횟수','10분당 평균','[아나] 죽은횟수'],
        info : '아나의 10분당 평균 죽은횟수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/ana.png',
    },
    {
        name: '[아나] 치유량',
        value : ['ana','치유량 - 10분당 평균','치유량','10분당 평균','[아나] 치유량'],
        info : '아나의 10분당 평균 치유량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/ana.png',
    },
    {
        name: '[아나] 결정타',
        value : ['ana','결정타 - 10분당 평균','결정타 수','10분당 평균','[아나] 결정타'],
        info : '아나의 10분당 평균 결정타 (막타)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/ana.png',
    },
    {
        name: '[아나] 단독처치',
        value : ['ana','단독 처치 - 10분당 평균','단독 처치수','10분당 평균','[아나] 단독처치'],
        info : '아나의 10분당 단독 처치수 (솔로킬 수)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/ana.png',
    },
    {
        name: '[아나] 영웅피해량',
        value : ['ana','영웅에게 준 피해 - 10분당 평균','영웅에게 준 피해량','10분당 평균','[아나] 영웅피해량'],
        info : '아나의 10분당 상대 영웅에 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/ana.png',
    },
    {
        name: '[아나] 방벽피해량',
        value : ['ana','방벽에 준 피해 - 10분당 평균','방벽에게 준 피해량','10분당 평균','[아나] 방벽피해량'],
        info : '아나의 10분당 방벽에 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/ana.png',
    },
    {
        name: '[아나] 모든피해량',
        value : ['ana','준 모든 피해 - 10분당 평균','준 모든 피해량','10분당 평균','[아나] 모든피해량'],
        info : '아나의 10분당 방벽과 상대 영웅 에게 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/ana.png',
    },
    {
        name: '[아나] 임무 기여 시간',
        value : ['ana','임무 기여 시간 - 10분당 평균','임무 기여 시간','10분당 평균','[아나] 임무기여시간'],
        info : '아나의 10분당 임무 기여 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/ana.png',
    },
    {
        name: '[아나] 임무 기여 처치',
        value : ['ana','임무 기여 처치 - 10분당 평균','임무 기여 처치','10분당 평균','[아나] 임무기여처치'],
        info : '아나의 10분당 임무 기여 처지수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/ana.png',
    },
    {
        name: '[아나] 폭주 시간',
        value : ['ana','폭주 시간 - 10분당 평균','폭주 시간','10분당 평균','[아나] 폭주 시간'],
        info : '아나의 10분당 폭주 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/ana.png',
    },
    {
        name: '[아나] 근접 공격 적중률',
        value : ['ana','근접 공격 적중률','근접 공격 적중률','게임당 평균','[아나] 근접 공격 적중률'],
        info : '아나의 근접 공격 적중률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/ana.png',
    },
    {
        name: '[아나] 나노 강화제 사용횟수',
        value : ['ana','나노 강화제 주입 - 10분당 평균','나노 강화제 사용횟수','10분당 평균','[아나] 나노 강화제 사용횟수'],
        info : '아나의 10분당 나노 강화제 사용횟수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/ana.png',
    },
    {
        name: '[아나] 나노 강화제 처치기여',
        value : ['ana','나노 강화제 도움 - 10분당 평균','나노 강화제 처치기여','10분당 평균','[아나] 나노 강화제 처치기여'],
        info : '아나의 10분당 나노 강화제 처치기여수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/ana.png',
    },
    {
        name: '[아나] 소총 명중률',
        value : ['ana','소총 명중률','소총 명중률','게임당 평균','[아나] 소총 명중률'],
        info : '아나의 소총 명중률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/ana.png',
    },
    {
        name: '[아나] 저격 명중률',
        value : ['ana','저격 명중률','저격 명중률','게임당 평균','[아나] 저격 명중률'],
        info : '아나의 저격 명중률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/ana.png',
    },
    {
        name: '[아나] 자가 치유량',
        value : ['ana','자가 치유 - 10분당 평균','자가 치유량','10분당 평균','[아나] 자가 치유량'],
        info : '아나의 10분당 자가 치유량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/ana.png',
    },
    {
        name: '[아나] 치유 증폭량',
        value : ['ana','치유 증폭 - 10분당 평균','치유 증폭량','10분당 평균','[아나] 치유 증폭량'],
        info : '아나의 10분당 치유 증폭량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/ana.png',
    },
    {
        name: '[아나] 수면총으로 재운 적',
        value : ['ana','재운 적 - 10분당 평균','수면총으로 재운 적','10분당 평균','[아나] 수면총으로 재운 적'],
        info : '아나의 10분당 수면총으로 재운 적수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/ana.png',
    },
    {
        name: '[아나] 공격형 도움',
        value : ['ana','공격형 도움 - 10분당 평균','공격형 도움','10분당 평균','[아나] 공격형 도움'],
        info : '아나의 10분당 공격형 도움',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/ana.png',
    },
    {
        name: '[아나] 방어형 도움',
        value : ['ana','방어형 도움 - 10분당 평균','방어형 도움','10분당 평균','[아나] 방어형 도움'],
        info : '아나의 10분당 방어형 도움',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/ana.png',
    },
    // 애쉬 
    {
        name: '[애쉬] 플레이 시간',
        value : ['ashe','플레이 시간','플레이 시간',null,'[애쉬] 플레이시간'],
        info : '애쉬의 플레이 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/ashe.png',
    },
    {
        name: '[애쉬] 승률',
        value : ['ashe','승률','승률' ,null,'[애쉬] 승률'],
        info : '애쉬 영웅의 승률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/ashe.png',
    },
    {
        name: '[애쉬] 목숨당 처치',
        value : ['ashe','목숨당 처치','목숨당 처치','게임당 평균','[애쉬] 목숨당 처치'],
        info : '애쉬의 목숨당 처치횟수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/ashe.png',
    },
    {
        name: '[애쉬] 명중률',
        value : ['ashe','명중률','명중률','게임당 평균','[애쉬] 명중률'],
        info : '애쉬의 명중률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/ashe.png',
    },
    {
        name: '[애쉬] 치명타',
        value : ['ashe','치명타 - 10분당 평균','치명타','10분당 평균','[애쉬] 치명타'],
        info : '애쉬의 10분당 평균 치명타 (헤드샷)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/ashe.png',
    },
    {
        name: '[애쉬] 치명타 명중률',
        value : ['ashe','치명타 명중률','치명타 명중률','게임당 평균','[애쉬] 치명타명중률'],
        info : '애쉬의 치명타 명중률 (헤드샷 명중률)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/ashe.png',
    },
    {
        name: '[애쉬] 처치수',
        value : ['ashe','처치 - 10분당 평균','처치수','10분당 평균','[애쉬] 처치수'],
        info : '애쉬의 10분당 평균 처지수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/ashe.png',
    },
    {
        name: '[애쉬] 죽은횟수',
        value : ['ashe','죽음 - 10분당 평균','죽은 횟수','10분당 평균','[애쉬] 죽은횟수'],
        info : '애쉬의 10분당 평균 죽은횟수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/ashe.png',
    },
    {
        name: '[애쉬] 치유량',
        value : ['ashe','치유량 - 10분당 평균','치유량','10분당 평균','[애쉬] 치유량'],
        info : '애쉬의 10분당 평균 치유량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/ashe.png',
    },
    {
        name: '[애쉬] 결정타',
        value : ['ashe','결정타 - 10분당 평균','결정타 수','10분당 평균','[애쉬] 결정타'],
        info : '애쉬의 10분당 평균 결정타 (막타)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/ashe.png',
    },
    {
        name: '[애쉬] 단독처치',
        value : ['ashe','단독 처치 - 10분당 평균','단독 처치수','10분당 평균','[애쉬] 단독처치'],
        info : '애쉬의 10분당 단독 처치수 (솔로킬 수)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/ashe.png',
    },
    {
        name: '[애쉬] 영웅피해량',
        value : ['ashe','영웅에게 준 피해 - 10분당 평균','영웅에게 준 피해량','10분당 평균','[애쉬] 영웅피해량'],
        info : '애쉬의 10분당 상대 영웅에 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/ashe.png',
    },
    {
        name: '[애쉬] 방벽피해량',
        value : ['ashe','방벽에 준 피해 - 10분당 평균','방벽에게 준 피해량','10분당 평균','[애쉬] 방벽피해량'],
        info : '애쉬의 10분당 방벽에 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/ashe.png',
    },
    {
        name: '[애쉬] 모든피해량',
        value : ['ashe','준 모든 피해 - 10분당 평균','준 모든 피해량','10분당 평균','[애쉬] 모든피해량'],
        info : '애쉬의 10분당 방벽과 상대 영웅 에게 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/ashe.png',
    },
    {
        name: '[애쉬] 임무 기여 시간',
        value : ['ashe','임무 기여 시간 - 10분당 평균','임무 기여 시간','10분당 평균','[애쉬] 임무기여시간'],
        info : '애쉬의 10분당 임무 기여 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/ashe.png',
    },
    {
        name: '[애쉬] 임무 기여 처치',
        value : ['ashe','임무 기여 처치 - 10분당 평균','임무 기여 처치','10분당 평균','[애쉬] 임무기여처치'],
        info : '애쉬의 10분당 임무 기여 처지수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/ashe.png',
    },
    {
        name: '[애쉬] 폭주 시간',
        value : ['ashe','폭주 시간 - 10분당 평균','폭주 시간','10분당 평균','[애쉬] 폭주 시간'],
        info : '애쉬의 10분당 폭주 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/ashe.png',
    },
    {
        name: '[애쉬] 근접 공격 적중률',
        value : ['ashe','근접 공격 적중률','근접 공격 적중률','게임당 평균','[애쉬] 근접 공격 적중률'],
        info : '애쉬의 10분당 임무 기여 처지수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/ashe.png',
    },
    {
        name: '[애쉬] 다이너마이트로 처치',
        value : ['ashe','다이너마이트로 처치 - 10분당 평균','다이너마이트로 처치','10분당 평균','[애쉬] 다이너마이트로 처치'],
        info : '애쉬의 10분당 다이너마이트로 처치수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/ashe.png',
    },
    {
        name: '[애쉬] 밥으로 처치',
        value : ['ashe','밥으로 처치 - 10분당 평균','밥으로 처치','10분당 평균','[애쉬] 밥으로 처치'],
        info : '애쉬의 10분당 밥으로 처치수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/ashe.png',
    },
    {
        name: '[애쉬] 저격 명중률',
        value : ['ashe','저격 명중률','저격 명중률','게임당 평균','[애쉬] 저격 명중률'],
        info : '애쉬의 저격 명중률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/ashe.png',
    },
    {
        name: '[애쉬] 저격 치명타',
        value : ['ashe','저격 치명타 - 10분당 평균','저격 치명타','10분당 평균','[애쉬] 저격 치명타'],
        info : '애쉬의 10분당 저격 치명타',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/ashe.png',
    },
    {
        name: '[애쉬] 저격 치명타 처치',
        value : ['ashe','저격 치명타 처치 - 10분당 평균','저격 치명타 처치','10분당 평균','[애쉬] 저격 치명타 처치'],
        info : '애쉬의 10분당 저격 치명타 처치수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/ashe.png',
    },
    {
        name: '[애쉬] 충격 샷건으로 처치',
        value : ['ashe','충격 샷건으로 처치 - 10분당 평균','충격 샷건으로 처치','10분당 평균','[애쉬] 충격 샷건으로 처치'],
        info : '애쉬의 10분당 충격 샷건으로 처치수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/ashe.png',
    },

     // 애코 
     {
        name: '[애코] 플레이 시간',
        value : ['echo','플레이 시간','플레이 시간',null,'[애코] 플레이시간'],
        info : '애코의 플레이 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/echo.png',
    },
    {
        name: '[애코] 승률',
        value : ['echo','승률','승률' ,null,'[애코] 승률'],
        info : '애코 영웅의 승률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/echo.png',
    },
    {
        name: '[애코] 목숨당 처치',
        value : ['echo','목숨당 처치','목숨당 처치','게임당 평균','[애코] 목숨당 처치'],
        info : '애코의 목숨당 처치횟수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/echo.png',
    },
    {
        name: '[애코] 명중률',
        value : ['echo','명중률','명중률','게임당 평균','[애코] 명중률'],
        info : '애코의 명중률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/echo.png',
    },
    {
        name: '[애코] 치명타',
        value : ['echo','치명타 - 10분당 평균','치명타','10분당 평균','[애코] 치명타'],
        info : '애코의 10분당 평균 치명타 (헤드샷)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/echo.png',
    },
    {
        name: '[애코] 치명타 명중률',
        value : ['echo','치명타 명중률','치명타 명중률','게임당 평균','[애코] 치명타명중률'],
        info : '애코의 치명타 명중률 (헤드샷 명중률)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/echo.png',
    },
    {
        name: '[애코] 처치수',
        value : ['echo','처치 - 10분당 평균','처치수','10분당 평균','[애코] 처치수'],
        info : '애코의 10분당 평균 처지수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/echo.png',
    },
    {
        name: '[애코] 죽은횟수',
        value : ['echo','죽음 - 10분당 평균','죽은 횟수','10분당 평균','[애코] 죽은횟수'],
        info : '애코의 10분당 평균 죽은횟수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/echo.png',
    },
    {
        name: '[애코] 치유량',
        value : ['echo','치유량 - 10분당 평균','치유량','10분당 평균','[애코] 치유량'],
        info : '애코의 10분당 평균 치유량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/echo.png',
    },
    {
        name: '[애코] 결정타',
        value : ['echo','결정타 - 10분당 평균','결정타 수','10분당 평균','[애코] 결정타'],
        info : '애코의 10분당 평균 결정타 (막타)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/echo.png',
    },
    {
        name: '[애코] 단독처치',
        value : ['echo','단독 처치 - 10분당 평균','단독 처치수','10분당 평균','[애코] 단독처치'],
        info : '애코의 10분당 단독 처치수 (솔로킬 수)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/echo.png',
    },
    {
        name: '[애코] 영웅피해량',
        value : ['echo','영웅에게 준 피해 - 10분당 평균','영웅에게 준 피해량','10분당 평균','[애코] 영웅피해량'],
        info : '애코의 10분당 상대 영웅에 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/echo.png',
    },
    {
        name: '[애코] 방벽피해량',
        value : ['echo','방벽에 준 피해 - 10분당 평균','방벽에게 준 피해량','10분당 평균','[애코] 방벽피해량'],
        info : '애코의 10분당 방벽에 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/echo.png',
    },
    {
        name: '[애코] 모든피해량',
        value : ['echo','준 모든 피해 - 10분당 평균','준 모든 피해량','10분당 평균','[애코] 모든피해량'],
        info : '애코의 10분당 방벽과 상대 영웅 에게 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/echo.png',
    },
    {
        name: '[애코] 임무 기여 시간',
        value : ['echo','임무 기여 시간 - 10분당 평균','임무 기여 시간','10분당 평균','[애코] 임무기여시간'],
        info : '애코의 10분당 임무 기여 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/echo.png',
    },
    {
        name: '[애코] 임무 기여 처치',
        value : ['echo','임무 기여 처치 - 10분당 평균','임무 기여 처치','10분당 평균','[애코] 임무기여처치'],
        info : '애코의 10분당 임무 기여 처지수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/echo.png',
    },
    {
        name: '[애코] 폭주 시간',
        value : ['echo','폭주 시간 - 10분당 평균','폭주 시간','10분당 평균','[애코] 폭주 시간'],
        info : '애코의 10분당 폭주 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/echo.png',
    },
    {
        name: '[애코] 근접 공격 적중률',
        value : ['echo','근접 공격 적중률','근접 공격 적중률','게임당 평균','[애코] 근접 공격 적중률'],
        info : '애코의 근접 공격 적중률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/echo.png',
    },
    {
        name: '[애코] 광선 집중 명중률',
        value : ['echo','광선 집중 명중률','광선 집중 명중률','게임당 평균','[애코] 광선 집중 명중률'],
        info : '애코의 광선 집중 명중률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/echo.png',
    },
    {
        name: '[애코] 점착 폭탄 명중률',
        value : ['echo','점착 폭탄 명중률','점착 폭탄 명중률','게임당 평균','[애코] 점착 폭탄 명중률'],
        info : '애코의 점착 폭탄 명중률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/echo.png',
    },
    {
        name: '[애코] 광선 집중으로 처치',
        value : ['echo','광선 집중으로 처치 - 10분당 평균','광선 집중으로 처치','10분당 평균','[애코] 광선 집중으로 처치'],
        info : '애코의 10분당 광선 집중으로 처치수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/echo.png',
    },
    {
        name: '[애코] 점착 폭탄 명중',
        value : ['echo','점착 폭탄 명중 - 10분당 평균','점착 폭탄 명중','10분당 평균','[애코] 점착 폭탄 명중'],
        info : '애코의 10분당 점착 폭탄 명중수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/echo.png',
    },
    {
        name: '[애코] 점착 폭탄으로 처치',
        value : ['echo','점착 폭탄으로 처치 - 10분당 평균','점착 폭탄으로 처치','10분당 평균','[애코] 점착 폭탄으로 처치'],
        info : '애코의 10분당 점착 폭탄으로 처치수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/echo.png',
    },
    {
        name: '[애코] 복제로 처치',
        value : ['echo','복제로 처치 - 10분당 평균','복제로 처치','10분당 평균','[애코] 복제로 처치'],
        info : '애코의 10분당 복제로 처치수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/echo.png',
    },

     // 오리사 
     {
        name: '[오리사] 플레이 시간',
        value : ['orisa','플레이 시간','플레이 시간',null,'[오리사] 플레이시간'],
        info : '오리사의 플레이 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/orisa.png',
    },
    {
        name: '[오리사] 승률',
        value : ['orisa','승률','승률' ,null,'[오리사] 승률'],
        info : '오리사 영웅의 승률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/orisa.png',
    },
    {
        name: '[오리사] 목숨당 처치',
        value : ['orisa','목숨당 처치','목숨당 처치','게임당 평균','[오리사] 목숨당 처치'],
        info : '오리사의 목숨당 처치횟수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/orisa.png',
    },
    {
        name: '[오리사] 명중률',
        value : ['orisa','명중률','명중률','게임당 평균','[오리사] 명중률'],
        info : '오리사의 명중률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/orisa.png',
    },
    {
        name: '[오리사] 치명타',
        value : ['orisa','치명타 - 10분당 평균','치명타','10분당 평균','[오리사] 치명타'],
        info : '오리사의 10분당 평균 치명타 (헤드샷)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/orisa.png',
    },
    {
        name: '[오리사] 치명타 명중률',
        value : ['orisa','치명타 명중률','치명타 명중률','게임당 평균','[오리사] 치명타명중률'],
        info : '오리사의 치명타 명중률 (헤드샷 명중률)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/orisa.png',
    },
    {
        name: '[오리사] 처치수',
        value : ['orisa','처치 - 10분당 평균','처치수','10분당 평균','[오리사] 처치수'],
        info : '오리사의 10분당 평균 처지수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/orisa.png',
    },
    {
        name: '[오리사] 죽은횟수',
        value : ['orisa','죽음 - 10분당 평균','죽은 횟수','10분당 평균','[오리사] 죽은횟수'],
        info : '오리사의 10분당 평균 죽은횟수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/orisa.png',
    },
    {
        name: '[오리사] 치유량',
        value : ['orisa','치유량 - 10분당 평균','치유량','10분당 평균','[오리사] 치유량'],
        info : '오리사의 10분당 평균 치유량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/orisa.png',
    },
    {
        name: '[오리사] 결정타',
        value : ['orisa','결정타 - 10분당 평균','결정타 수','10분당 평균','[오리사] 결정타'],
        info : '오리사의 10분당 평균 결정타 (막타)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/orisa.png',
    },
    {
        name: '[오리사] 단독처치',
        value : ['orisa','단독 처치 - 10분당 평균','단독 처치수','10분당 평균','[오리사] 단독처치'],
        info : '오리사의 10분당 단독 처치수 (솔로킬 수)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/orisa.png',
    },
    {
        name: '[오리사] 영웅피해량',
        value : ['orisa','영웅에게 준 피해 - 10분당 평균','영웅에게 준 피해량','10분당 평균','[오리사] 영웅피해량'],
        info : '오리사의 10분당 상대 영웅에 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/orisa.png',
    },
    {
        name: '[오리사] 방벽피해량',
        value : ['orisa','방벽에 준 피해 - 10분당 평균','방벽에게 준 피해량','10분당 평균','[오리사] 방벽피해량'],
        info : '오리사의 10분당 방벽에 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/orisa.png',
    },
    {
        name: '[오리사] 모든피해량',
        value : ['orisa','준 모든 피해 - 10분당 평균','준 모든 피해량','10분당 평균','[오리사] 모든피해량'],
        info : '오리사의 10분당 방벽과 상대 영웅 에게 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/orisa.png',
    },
    {
        name: '[오리사] 임무 기여 시간',
        value : ['orisa','임무 기여 시간 - 10분당 평균','임무 기여 시간','10분당 평균','[오리사] 임무기여시간'],
        info : '오리사의 10분당 임무 기여 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/orisa.png',
    },
    {
        name: '[오리사] 임무 기여 처치',
        value : ['orisa','임무 기여 처치 - 10분당 평균','임무 기여 처치','10분당 평균','[오리사] 임무기여처치'],
        info : '오리사의 10분당 임무 기여 처지수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/orisa.png',
    },
    {
        name: '[오리사] 폭주 시간',
        value : ['orisa','폭주 시간 - 10분당 평균','폭주 시간','10분당 평균','[오리사] 폭주 시간'],
        info : '오리사의 10분당 폭주 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/orisa.png',
    },
    {
        name: '[오리사] 공격력 증폭',
        value : ['orisa','공격력 증폭 - 10분당 평균','공격력 증폭','10분당 평균','[오리사] 공격력 증폭'],
        info : '오리사의 10분당 공격력 증폭량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/orisa.png',
    },
    {
        name: '[오리사] 방벽으로 막은 피해량',
        value : ['orisa','막은 피해 - 10분당 평균','방벽으로 막은 피해량','10분당 평균','[오리사] 방벽으로 막은 피해량'],
        info : '오리사의 10분당 방벽으로 막은 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/orisa.png',
    },
    {
        name: '[오리사] 초강력 증폭기 도움',
        value : ['orisa','초강력 증폭기 도움 - 10분당 평균','초강력 증폭기 도움','10분당 평균','[오리사] 초강력 증폭기 도움'],
        info : '오리사의 10분당 초강력 증폭기 도움수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/orisa.png',
    },
    {
        name: '[오리사] 공격형 도움',
        value : ['orisa','공격형 도움 - 10분당 평균','공격형 도움','10분당 평균','[오리사] 공격형 도움'],
        info : '오리사의 10분당 공격형 도움',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/orisa.png',
    },

     // 위도우메이커 
     {
        name: '[위도우메이커] 플레이 시간',
        value : ['widowmaker','플레이 시간','플레이 시간',null,'[위도우메이커] 플레이시간'],
        info : '위도우메이커의 플레이 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/widowmaker.png',
    },
    {
        name: '[위도우메이커] 승률',
        value : ['widowmaker','승률','승률' ,null,'[위도우메이커] 승률'],
        info : '위도우메이커 영웅의 승률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/widowmaker.png',
    },
    {
        name: '[위도우메이커] 목숨당 처치',
        value : ['widowmaker','목숨당 처치','목숨당 처치','게임당 평균','[위도우메이커] 목숨당 처치'],
        info : '위도우메이커의 목숨당 처치횟수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/widowmaker.png',
    },
    {
        name: '[위도우메이커] 명중률',
        value : ['widowmaker','명중률','명중률','게임당 평균','[위도우메이커] 명중률'],
        info : '위도우메이커의 명중률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/widowmaker.png',
    },
    {
        name: '[위도우메이커] 치명타',
        value : ['widowmaker','치명타 - 10분당 평균','치명타','10분당 평균','[위도우메이커] 치명타'],
        info : '위도우메이커의 10분당 평균 치명타 (헤드샷)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/widowmaker.png',
    },
    {
        name: '[위도우메이커] 치명타 명중률',
        value : ['widowmaker','치명타 명중률','치명타 명중률','게임당 평균','[위도우메이커] 치명타명중률'],
        info : '위도우메이커의 치명타 명중률 (헤드샷 명중률)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/widowmaker.png',
    },
    {
        name: '[위도우메이커] 처치수',
        value : ['widowmaker','처치 - 10분당 평균','처치수','10분당 평균','[위도우메이커] 처치수'],
        info : '위도우메이커의 10분당 평균 처지수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/widowmaker.png',
    },
    {
        name: '[위도우메이커] 죽은횟수',
        value : ['widowmaker','죽음 - 10분당 평균','죽은 횟수','10분당 평균','[위도우메이커] 죽은횟수'],
        info : '위도우메이커의 10분당 평균 죽은횟수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/widowmaker.png',
    },
    {
        name: '[위도우메이커] 치유량',
        value : ['widowmaker','치유량 - 10분당 평균','치유량','10분당 평균','[위도우메이커] 치유량'],
        info : '위도우메이커의 10분당 평균 치유량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/widowmaker.png',
    },
    {
        name: '[위도우메이커] 결정타',
        value : ['widowmaker','결정타 - 10분당 평균','결정타 수','10분당 평균','[위도우메이커] 결정타'],
        info : '위도우메이커의 10분당 평균 결정타 (막타)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/widowmaker.png',
    },
    {
        name: '[위도우메이커] 단독처치',
        value : ['widowmaker','단독 처치 - 10분당 평균','단독 처치수','10분당 평균','[위도우메이커] 단독처치'],
        info : '위도우메이커의 10분당 단독 처치수 (솔로킬 수)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/widowmaker.png',
    },
    {
        name: '[위도우메이커] 영웅피해량',
        value : ['widowmaker','영웅에게 준 피해 - 10분당 평균','영웅에게 준 피해량','10분당 평균','[위도우메이커] 영웅피해량'],
        info : '위도우메이커의 10분당 상대 영웅에 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/widowmaker.png',
    },
    {
        name: '[위도우메이커] 방벽피해량',
        value : ['widowmaker','방벽에 준 피해 - 10분당 평균','방벽에게 준 피해량','10분당 평균','[위도우메이커] 방벽피해량'],
        info : '위도우메이커의 10분당 방벽에 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/widowmaker.png',
    },
    {
        name: '[위도우메이커] 모든피해량',
        value : ['widowmaker','준 모든 피해 - 10분당 평균','준 모든 피해량','10분당 평균','[위도우메이커] 모든피해량'],
        info : '위도우메이커의 10분당 방벽과 상대 영웅 에게 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/widowmaker.png',
    },
    {
        name: '[위도우메이커] 임무 기여 시간',
        value : ['widowmaker','임무 기여 시간 - 10분당 평균','임무 기여 시간','10분당 평균','[위도우메이커] 임무기여시간'],
        info : '위도우메이커의 10분당 임무 기여 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/widowmaker.png',
    },
    {
        name: '[위도우메이커] 임무 기여 처치',
        value : ['widowmaker','임무 기여 처치 - 10분당 평균','임무 기여 처치','10분당 평균','[위도우메이커] 임무기여처치'],
        info : '위도우메이커의 10분당 임무 기여 처지수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/widowmaker.png',
    },
    {
        name: '[위도우메이커] 근접 공격 적중률',
        value : ['widowmaker','근접 공격 적중률','근접 공격 적중률','게임당 평균','[위도우메이커] 근접 공격 적중률'],
        info : '위도우메이커의 근접 공격 적중률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/widowmaker.png',
    },
    {
        name: '[위도우메이커] 맹독 지뢰로 처치',
        value : ['widowmaker','맹독 지뢰로 처치 - 10분당 평균','맹독 지뢰로 처치','10분당 평균','[위도우메이커] 맹독 지뢰로 처치'],
        info : '위도우메이커의 10분당 맹독 지뢰로 처치수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/widowmaker.png',
    },
    {
        name: '[위도우메이커] 저격 명중률',
        value : ['widowmaker','저격 명중률','저격 명중률','게임당 평균','[위도우메이커] 저격 명중률'],
        info : '위도우메이커의 저격 명중률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/widowmaker.png',
    },
    {
        name: '[위도우메이커] 저격 치명타 명중률',
        value : ['widowmaker','저격 치명타 명중률','저격 치명타 명중률','게임당 평균','[위도우메이커] 저격 치명타 명중률'],
        info : '위도우메이커의 저격 치명타(헤드샷) 명중률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/widowmaker.png',
    },
    {
        name: '[위도우메이커] 저격 치명타',
        value : ['widowmaker','저격 치명타 - 10분당 평균','저격 치명타','10분당 평균','[위도우메이커] 저격 치명타'],
        info : '위도우메이커의 10분당 저격 치명타수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/widowmaker.png',
    },
    {
        name: '[위도우메이커] 저격 치명타 처치',
        value : ['widowmaker','저격 치명타 처치 - 10분당 평균','저격 치명타 처치','10분당 평균','[위도우메이커] 저격 치명타 처치'],
        info : '위도우메이커의 10분당 저격 치명타 처치수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/widowmaker.png',
    },
    {
        name: '[위도우메이커] 처치 시야 지원',
        value : ['widowmaker','처치 시야 지원 - 10분당 평균','처치 시야 지원','10분당 평균','[위도우메이커] 처치 시야 지원'],
        info : '위도우메이커의 적외선 투시를 통한 10분당 처치 시야 지원',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/widowmaker.png',
    },
     // 윈스턴 
     {
        name: '[윈스턴] 플레이 시간',
        value : ['winston','플레이 시간','플레이 시간',null,'[윈스턴] 플레이시간'],
        info : '윈스턴의 플레이 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/winston.png',
    },
    {
        name: '[윈스턴] 승률',
        value : ['winston','승률','승률' ,null,'[윈스턴] 승률'],
        info : '윈스턴 영웅의 승률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/winston.png',
    },
    {
        name: '[윈스턴] 목숨당 처치',
        value : ['winston','목숨당 처치','목숨당 처치','게임당 평균','[윈스턴] 목숨당 처치'],
        info : '윈스턴의 목숨당 처치횟수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/winston.png',
    },
    {
        name: '[윈스턴] 명중률',
        value : ['winston','명중률','명중률','게임당 평균','[윈스턴] 명중률'],
        info : '윈스턴의 명중률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/winston.png',
    },
    {
        name: '[윈스턴] 치명타',
        value : ['winston','치명타 - 10분당 평균','치명타','10분당 평균','[윈스턴] 치명타'],
        info : '윈스턴의 10분당 평균 치명타 (헤드샷)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/winston.png',
    },
    {
        name: '[윈스턴] 치명타 명중률',
        value : ['winston','치명타 명중률','치명타 명중률','게임당 평균','[윈스턴] 치명타명중률'],
        info : '윈스턴의 치명타 명중률 (헤드샷 명중률)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/winston.png',
    },
    {
        name: '[윈스턴] 처치수',
        value : ['winston','처치 - 10분당 평균','처치수','10분당 평균','[윈스턴] 처치수'],
        info : '윈스턴의 10분당 평균 처지수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/winston.png',
    },
    {
        name: '[윈스턴] 죽은횟수',
        value : ['winston','죽음 - 10분당 평균','죽은 횟수','10분당 평균','[윈스턴] 죽은횟수'],
        info : '윈스턴의 10분당 평균 죽은횟수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/winston.png',
    },
    {
        name: '[윈스턴] 치유량',
        value : ['winston','치유량 - 10분당 평균','치유량','10분당 평균','[윈스턴] 치유량'],
        info : '윈스턴의 10분당 평균 치유량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/winston.png',
    },
    {
        name: '[윈스턴] 결정타',
        value : ['winston','결정타 - 10분당 평균','결정타 수','10분당 평균','[윈스턴] 결정타'],
        info : '윈스턴의 10분당 평균 결정타 (막타)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/winston.png',
    },
    {
        name: '[윈스턴] 단독처치',
        value : ['winston','단독 처치 - 10분당 평균','단독 처치수','10분당 평균','[윈스턴] 단독처치'],
        info : '윈스턴의 10분당 단독 처치수 (솔로킬 수)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/winston.png',
    },
    {
        name: '[윈스턴] 영웅피해량',
        value : ['winston','영웅에게 준 피해 - 10분당 평균','영웅에게 준 피해량','10분당 평균','[윈스턴] 영웅피해량'],
        info : '윈스턴의 10분당 상대 영웅에 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/winston.png',
    },
    {
        name: '[윈스턴] 방벽피해량',
        value : ['winston','방벽에 준 피해 - 10분당 평균','방벽에게 준 피해량','10분당 평균','[윈스턴] 방벽피해량'],
        info : '윈스턴의 10분당 방벽에 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/winston.png',
    },
    {
        name: '[윈스턴] 모든피해량',
        value : ['winston','준 모든 피해 - 10분당 평균','준 모든 피해량','10분당 평균','[윈스턴] 모든피해량'],
        info : '윈스턴의 10분당 방벽과 상대 영웅 에게 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/winston.png',
    },
    {
        name: '[윈스턴] 임무 기여 시간',
        value : ['winston','임무 기여 시간 - 10분당 평균','임무 기여 시간','10분당 평균','[윈스턴] 임무기여시간'],
        info : '윈스턴의 10분당 임무 기여 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/winston.png',
    },
    {
        name: '[윈스턴] 임무 기여 처치',
        value : ['winston','임무 기여 처치 - 10분당 평균','임무 기여 처치','10분당 평균','[윈스턴] 임무기여처치'],
        info : '윈스턴의 10분당 임무 기여 처지수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/winston.png',
    },
    {
        name: '[윈스턴] 폭주 시간',
        value : ['winston','폭주 시간 - 10분당 평균','폭주 시간','10분당 평균','[윈스턴] 폭주 시간'],
        info : '윈스턴의 10분당 폭주 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/winston.png',
    },
    {
        name: '[윈스턴] 근접 공격 적중률',
        value : ['winston','근접 공격 적중률','근접 공격 적중률','게임당 평균','[윈스턴] 근접 공격 적중률'],
        info : '윈스턴의 근접 공격 적중률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/winston.png',
    },
    {
        name: '[윈스턴] 근접 공격으로 처치',
        value : ['winston','근접 공격으로 처치 - 10분당 평균','근접 공격으로 처치','10분당 평균','[윈스턴] 근접 공격으로 처치'],
        info : '윈스턴의 10분당 근접 공격으로 처치수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/winston.png',
    },
    {
        name: '[윈스턴] 방벽으로 막은 피해량',
        value : ['winston','막은 피해 - 10분당 평균','방벽으로 막은 피해량','10분당 평균','[윈스턴] 방벽으로 막은 피해량'],
        info : '윈스턴의 10분당 방벽으로 막은 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/winston.png',
    },
    {
        name: '[윈스턴] 밀쳐낸 플레이어',
        value : ['winston','밀쳐낸 플레이어 - 10분당 평균','밀쳐낸 플레이어','10분당 평균','[윈스턴] 밀쳐낸 플레이어'],
        info : '윈스턴의 10분당 밀쳐낸 플레이어',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/winston.png',
    },
    {
        name: '[윈스턴] 원시의 분노 근접 공격 적중률',
        value : ['winston','원시의 분노 근접 공격 적중률','원시의 분노 근접 공격 적중률','게임당 평균','[윈스턴] 원시의 분노 근접 공격 적중률'],
        info : '윈스턴의 원시의 분노 근접 공격 적중률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/winston.png',
    },
    {
        name: '[윈스턴] 원시의 분노로 처치',
        value : ['winston','원시의 분노로 처치 - 10분당 평균','원시의 분노로 처치','10분당 평균','[윈스턴] 원시의 분노로 처치'],
        info : '윈스턴의 10분당 원시의 분노로 처치',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/winston.png',
    },
    {
        name: '[윈스턴] 점프 팩으로 처치',
        value : ['winston','점프 팩으로 처치 - 10분당 평균','점프 팩으로 처치','10분당 평균','[윈스턴] 점프 팩으로 처치'],
        info : '윈스턴의 10분당 점프 팩으로 처치',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/winston.png',
    },
    {
        name: '[윈스턴] 테슬라 캐논 적중률',
        value : ['winston','테슬라 캐논 적중률','테슬라 캐논 적중률','게임당 평균','[윈스턴] 테슬라 캐논 적중률'],
        info : '윈스턴의 테슬라 캐논 적중률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/winston.png',
    },
     // 자리야 
     {
        name: '[자리야] 플레이 시간',
        value : ['zarya','플레이 시간','플레이 시간',null,'[자리야] 플레이시간'],
        info : '자리야의 플레이 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/zarya.png',
    },
    {
        name: '[자리야] 승률',
        value : ['zarya','승률','승률' ,null,'[자리야] 승률'],
        info : '자리야 영웅의 승률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/zarya.png',
    },
    {
        name: '[자리야] 목숨당 처치',
        value : ['zarya','목숨당 처치','목숨당 처치','게임당 평균','[자리야] 목숨당 처치'],
        info : '자리야의 목숨당 처치횟수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/zarya.png',
    },
    {
        name: '[자리야] 명중률',
        value : ['zarya','명중률','명중률','게임당 평균','[자리야] 명중률'],
        info : '자리야의 명중률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/zarya.png',
    },
    {
        name: '[자리야] 치명타',
        value : ['zarya','치명타 - 10분당 평균','치명타','10분당 평균','[자리야] 치명타'],
        info : '자리야의 10분당 평균 치명타 (헤드샷)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/zarya.png',
    },
    {
        name: '[자리야] 치명타 명중률',
        value : ['zarya','치명타 명중률','치명타 명중률','게임당 평균','[자리야] 치명타명중률'],
        info : '자리야의 치명타 명중률 (헤드샷 명중률)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/zarya.png',
    },
    {
        name: '[자리야] 처치수',
        value : ['zarya','처치 - 10분당 평균','처치수','10분당 평균','[자리야] 처치수'],
        info : '자리야의 10분당 평균 처지수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/zarya.png',
    },
    {
        name: '[자리야] 죽은횟수',
        value : ['zarya','죽음 - 10분당 평균','죽은 횟수','10분당 평균','[자리야] 죽은횟수'],
        info : '자리야의 10분당 평균 죽은횟수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/zarya.png',
    },
    {
        name: '[자리야] 치유량',
        value : ['zarya','치유량 - 10분당 평균','치유량','10분당 평균','[자리야] 치유량'],
        info : '자리야의 10분당 평균 치유량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/zarya.png',
    },
    {
        name: '[자리야] 결정타',
        value : ['zarya','결정타 - 10분당 평균','결정타 수','10분당 평균','[자리야] 결정타'],
        info : '자리야의 10분당 평균 결정타 (막타)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/zarya.png',
    },
    {
        name: '[자리야] 단독처치',
        value : ['zarya','단독 처치 - 10분당 평균','단독 처치수','10분당 평균','[자리야] 단독처치'],
        info : '자리야의 10분당 단독 처치수 (솔로킬 수)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/zarya.png',
    },
    {
        name: '[자리야] 영웅피해량',
        value : ['zarya','영웅에게 준 피해 - 10분당 평균','영웅에게 준 피해량','10분당 평균','[자리야] 영웅피해량'],
        info : '자리야의 10분당 상대 영웅에 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/zarya.png',
    },
    {
        name: '[자리야] 방벽피해량',
        value : ['zarya','방벽에 준 피해 - 10분당 평균','방벽에게 준 피해량','10분당 평균','[자리야] 방벽피해량'],
        info : '자리야의 10분당 방벽에 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/zarya.png',
    },
    {
        name: '[자리야] 모든피해량',
        value : ['zarya','준 모든 피해 - 10분당 평균','준 모든 피해량','10분당 평균','[자리야] 모든피해량'],
        info : '자리야의 10분당 방벽과 상대 영웅 에게 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/zarya.png',
    },
    {
        name: '[자리야] 임무 기여 시간',
        value : ['zarya','임무 기여 시간 - 10분당 평균','임무 기여 시간','10분당 평균','[자리야] 임무기여시간'],
        info : '자리야의 10분당 임무 기여 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/zarya.png',
    },
    {
        name: '[자리야] 임무 기여 처치',
        value : ['zarya','임무 기여 처치 - 10분당 평균','임무 기여 처치','10분당 평균','[자리야] 임무기여처치'],
        info : '자리야의 10분당 임무 기여 처지수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/zarya.png',
    },
    {
        name: '[자리야] 폭주 시간',
        value : ['zarya','폭주 시간 - 10분당 평균','폭주 시간','10분당 평균','[자리야] 폭주 시간'],
        info : '자리야의 10분당 폭주 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/zarya.png',
    },
    {
        name: '[자리야] 근접 공격 적중률',
        value : ['zarya','근접 공격 적중률','근접 공격 적중률','게임당 평균','[자리야] 근접 공격 적중률'],
        info : '자리야의 근접 공격 적중률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/zarya.png',
    },
    {
        name: '[자리야] 고에너지로 처치',
        value : ['zarya','고에너지로 처치 - 10분당 평균','고에너지로 처치','10분당 평균','[자리야] 고에너지로 처치'],
        info : '자리야의 10분당 고에너지로 처치수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/zarya.png',
    },
    {
        name: '[자리야] 기본 발사 적중률',
        value : ['zarya','기본 발사 적중률','기본 발사 적중률','게임당 평균','[자리야] 기본 발사 적중률'],
        info : '자리야의 기본 발사 적중률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/zarya.png',
    },
    {
        name: '[자리야] 방벽으로 막은 피해',
        value : ['zarya','막은 피해 - 10분당 평균','방벽으로 막은 피해','10분당 평균','[자리야] 방벽으로 막은 피해'],
        info : '자리야의 10분당 방벽으로 막은 피해수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/zarya.png',
    },
    {
        name: '[자리야] 방벽 씌우기',
        value : ['zarya','방벽 씌우기 - 10분당 평균','방벽 씌우기','10분당 평균','[자리야] 방벽 씌우기'],
        info : '자리야의 10분당 방벽 씌우기수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/zarya.png',
    },
    {
        name: '[자리야] 중력자탄으로 처치',
        value : ['zarya','중력자탄으로 처치 - 10분당 평균','중력자탄으로 처치','10분당 평균','[자리야] 중력자탄으로 처치'],
        info : '자리야의 10분당 중력자탄으로 처치수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/zarya.png',
    },
    {
        name: '[자리야] 평균 에너지',
        value : ['zarya','평균 에너지','평균 에너지','게임당 평균','[자리야] 평균 에너지'],
        info : '자리야의 평균 에너지',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/zarya.png',
    },
    {
        name: '[자리야] 공격형 도움',
        value : ['zarya','공격형 도움 - 10분당 평균','공격형 도움','10분당 평균','[자리야] 공격형 도움'],
        info : '자리야의 공격형 도움',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/zarya.png',
    },
    {
        name: '[자리야] 방어형 도움',
        value : ['zarya','방어형 도움 - 10분당 평균','방어형 도움','10분당 평균','[자리야] 방어형 도움'],
        info : '자리야의 방어형 도움',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/zarya.png',
    },
     // 정크렛 
     {
        name: '[정크렛] 플레이 시간',
        value : ['junkrat','플레이 시간','플레이 시간',null,'[정크렛] 플레이시간'],
        info : '정크렛의 플레이 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/junkrat.png',
    },
    {
        name: '[정크렛] 승률',
        value : ['junkrat','승률','승률' ,null,'[정크렛] 승률'],
        info : '정크렛 영웅의 승률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/junkrat.png',
    },
    {
        name: '[정크렛] 목숨당 처치',
        value : ['junkrat','목숨당 처치','목숨당 처치','게임당 평균','[정크렛] 목숨당 처치'],
        info : '정크렛의 목숨당 처치횟수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/junkrat.png',
    },
    {
        name: '[정크렛] 명중률',
        value : ['junkrat','명중률','명중률','게임당 평균','[정크렛] 명중률'],
        info : '정크렛의 명중률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/junkrat.png',
    },
    {
        name: '[정크렛] 치명타',
        value : ['junkrat','치명타 - 10분당 평균','치명타','10분당 평균','[정크렛] 치명타'],
        info : '정크렛의 10분당 평균 치명타 (헤드샷)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/junkrat.png',
    },
    {
        name: '[정크렛] 치명타 명중률',
        value : ['junkrat','치명타 명중률','치명타 명중률','게임당 평균','[정크렛] 치명타명중률'],
        info : '정크렛의 치명타 명중률 (헤드샷 명중률)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/junkrat.png',
    },
    {
        name: '[정크렛] 처치수',
        value : ['junkrat','처치 - 10분당 평균','처치수','10분당 평균','[정크렛] 처치수'],
        info : '정크렛의 10분당 평균 처지수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/junkrat.png',
    },
    {
        name: '[정크렛] 죽은횟수',
        value : ['junkrat','죽음 - 10분당 평균','죽은 횟수','10분당 평균','[정크렛] 죽은횟수'],
        info : '정크렛의 10분당 평균 죽은횟수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/junkrat.png',
    },
    {
        name: '[정크렛] 치유량',
        value : ['junkrat','치유량 - 10분당 평균','치유량','10분당 평균','[정크렛] 치유량'],
        info : '정크렛의 10분당 평균 치유량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/junkrat.png',
    },
    {
        name: '[정크렛] 결정타',
        value : ['junkrat','결정타 - 10분당 평균','결정타 수','10분당 평균','[정크렛] 결정타'],
        info : '정크렛의 10분당 평균 결정타 (막타)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/junkrat.png',
    },
    {
        name: '[정크렛] 단독처치',
        value : ['junkrat','단독 처치 - 10분당 평균','단독 처치수','10분당 평균','[정크렛] 단독처치'],
        info : '정크렛의 10분당 단독 처치수 (솔로킬 수)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/junkrat.png',
    },
    {
        name: '[정크렛] 영웅피해량',
        value : ['junkrat','영웅에게 준 피해 - 10분당 평균','영웅에게 준 피해량','10분당 평균','[정크렛] 영웅피해량'],
        info : '정크렛의 10분당 상대 영웅에 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/junkrat.png',
    },
    {
        name: '[정크렛] 방벽피해량',
        value : ['junkrat','방벽에 준 피해 - 10분당 평균','방벽에게 준 피해량','10분당 평균','[정크렛] 방벽피해량'],
        info : '정크렛의 10분당 방벽에 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/junkrat.png',
    },
    {
        name: '[정크렛] 모든피해량',
        value : ['junkrat','준 모든 피해 - 10분당 평균','준 모든 피해량','10분당 평균','[정크렛] 모든피해량'],
        info : '정크렛의 10분당 방벽과 상대 영웅 에게 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/junkrat.png',
    },
    {
        name: '[정크렛] 임무 기여 시간',
        value : ['junkrat','임무 기여 시간 - 10분당 평균','임무 기여 시간','10분당 평균','[정크렛] 임무기여시간'],
        info : '정크렛의 10분당 임무 기여 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/junkrat.png',
    },
    {
        name: '[정크렛] 임무 기여 처치',
        value : ['junkrat','임무 기여 처치 - 10분당 평균','임무 기여 처치','10분당 평균','[정크렛] 임무기여처치'],
        info : '정크렛의 10분당 임무 기여 처지수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/junkrat.png',
    },
    {
        name: '[정크렛] 폭주 시간',
        value : ['junkrat','폭주 시간 - 10분당 평균','폭주 시간','10분당 평균','[정크렛] 폭주 시간'],
        info : '정크렛의 10분당 폭주 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/junkrat.png',
    },
    {
        name: '[정크렛] 근접 공격 적중률',
        value : ['junkrat','근접 공격 적중률','근접 공격 적중률','게임당 평균','[정크렛] 근접 공격 적중률'],
        info : '정크렛의 근접 공격 적중률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/junkrat.png',
    },
    {
        name: '[정크렛] 덫에 걸린 적',
        value : ['junkrat','덫에 걸린 적 - 10분당 평균','덫에 걸린 적','10분당 평균','[정크렛] 덫에 걸린 적'],
        info : '정크렛의 10분당 덫에 걸린 적수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/junkrat.png',
    },
    {
        name: '[정크렛] 죽이는 타이어로 처치',
        value : ['junkrat','죽이는 타이어로 처치 - 10분당 평균','죽이는 타이어로 처치','10분당 평균','[정크렛] 죽이는 타이어로 처치'],
        info : '정크렛의 10분당 죽이는 타이어로 처치수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/junkrat.png',
    },
    {
        name: '[정크렛] 충격 지뢰로 처치',
        value : ['junkrat','충격 지뢰로 처치 - 10분당 평균','충격 지뢰로 처치','10분당 평균','[정크렛] 충격 지뢰로 처치'],
        info : '정크렛의 10분당 충격 지뢰로 처치수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/junkrat.png',
    },
    {
        name: '[정크렛] 공격형 도움',
        value : ['junkrat','공격형 도움 - 10분당 평균','공격형 도움','10분당 평균','[정크렛] 공격형 도움'],
        info : '정크렛의 공격형 도움',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/junkrat.png',
    },
     // 젠야타 
     {
        name: '[젠야타] 플레이 시간',
        value : ['zenyatta','플레이 시간','플레이 시간',null,'[젠야타] 플레이시간'],
        info : '젠야타의 플레이 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/zenyatta.png',
    },
    {
        name: '[젠야타] 승률',
        value : ['zenyatta','승률','승률' ,null,'[젠야타] 승률'],
        info : '젠야타 영웅의 승률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/zenyatta.png',
    },
    {
        name: '[젠야타] 목숨당 처치',
        value : ['zenyatta','목숨당 처치','목숨당 처치','게임당 평균','[젠야타] 목숨당 처치'],
        info : '젠야타의 목숨당 처치횟수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/zenyatta.png',
    },
    {
        name: '[젠야타] 명중률',
        value : ['zenyatta','명중률','명중률','게임당 평균','[젠야타] 명중률'],
        info : '젠야타의 명중률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/zenyatta.png',
    },
    {
        name: '[젠야타] 치명타',
        value : ['zenyatta','치명타 - 10분당 평균','치명타','10분당 평균','[젠야타] 치명타'],
        info : '젠야타의 10분당 평균 치명타 (헤드샷)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/zenyatta.png',
    },
    {
        name: '[젠야타] 치명타 명중률',
        value : ['zenyatta','치명타 명중률','치명타 명중률','게임당 평균','[젠야타] 치명타명중률'],
        info : '젠야타의 치명타 명중률 (헤드샷 명중률)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/zenyatta.png',
    },
    {
        name: '[젠야타] 처치수',
        value : ['zenyatta','처치 - 10분당 평균','처치수','10분당 평균','[젠야타] 처치수'],
        info : '젠야타의 10분당 평균 처지수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/zenyatta.png',
    },
    {
        name: '[젠야타] 죽은횟수',
        value : ['zenyatta','죽음 - 10분당 평균','죽은 횟수','10분당 평균','[젠야타] 죽은횟수'],
        info : '젠야타의 10분당 평균 죽은횟수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/zenyatta.png',
    },
    {
        name: '[젠야타] 치유량',
        value : ['zenyatta','치유량 - 10분당 평균','치유량','10분당 평균','[젠야타] 치유량'],
        info : '젠야타의 10분당 평균 치유량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/zenyatta.png',
    },
    {
        name: '[젠야타] 결정타',
        value : ['zenyatta','결정타 - 10분당 평균','결정타 수','10분당 평균','[젠야타] 결정타'],
        info : '젠야타의 10분당 평균 결정타 (막타)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/zenyatta.png',
    },
    {
        name: '[젠야타] 단독처치',
        value : ['zenyatta','단독 처치 - 10분당 평균','단독 처치수','10분당 평균','[젠야타] 단독처치'],
        info : '젠야타의 10분당 단독 처치수 (솔로킬 수)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/zenyatta.png',
    },
    {
        name: '[젠야타] 영웅피해량',
        value : ['zenyatta','영웅에게 준 피해 - 10분당 평균','영웅에게 준 피해량','10분당 평균','[젠야타] 영웅피해량'],
        info : '젠야타의 10분당 상대 영웅에 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/zenyatta.png',
    },
    {
        name: '[젠야타] 방벽피해량',
        value : ['zenyatta','방벽에 준 피해 - 10분당 평균','방벽에게 준 피해량','10분당 평균','[젠야타] 방벽피해량'],
        info : '젠야타의 10분당 방벽에 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/zenyatta.png',
    },
    {
        name: '[젠야타] 모든피해량',
        value : ['zenyatta','준 모든 피해 - 10분당 평균','준 모든 피해량','10분당 평균','[젠야타] 모든피해량'],
        info : '젠야타의 10분당 방벽과 상대 영웅 에게 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/zenyatta.png',
    },
    {
        name: '[젠야타] 임무 기여 시간',
        value : ['zenyatta','임무 기여 시간 - 10분당 평균','임무 기여 시간','10분당 평균','[젠야타] 임무기여시간'],
        info : '젠야타의 10분당 임무 기여 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/zenyatta.png',
    },
    {
        name: '[젠야타] 임무 기여 처치',
        value : ['zenyatta','임무 기여 처치 - 10분당 평균','임무 기여 처치','10분당 평균','[젠야타] 임무기여처치'],
        info : '젠야타의 10분당 임무 기여 처지수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/zenyatta.png',
    },
    {
        name: '[젠야타] 폭주 시간',
        value : ['zenyatta','폭주 시간 - 10분당 평균','폭주 시간','10분당 평균','[젠야타] 폭주 시간'],
        info : '젠야타의 10분당 폭주 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/zenyatta.png',
    },
    {
        name: '[젠야타] 근접 공격 적중률',
        value : ['zenyatta','근접 공격 적중률','근접 공격 적중률','10분당 평균','[젠야타] 근접 공격 적중률'],
        info : '젠야타의 근접 공격 적중률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/zenyatta.png',
    },
    {
        name: '[젠야타] 자가 치유량',
        value : ['zenyatta','자가 치유 - 10분당 평균','자가 치유량','10분당 평균','[젠야타] 자가 치유량'],
        info : '젠야타의 10분당 자가 치유량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/zenyatta.png',
    },
    {
        name: '[젠야타] 공격형 도움',
        value : ['zenyatta','공격형 도움 - 10분당 평균','공격형 도움','10분당 평균','[젠야타] 공격형 도움'],
        info : '젠야타의 10분당 공격형 도움',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/zenyatta.png',
    },
    {
        name: '[젠야타] 방어형 도움',
        value : ['zenyatta','방어형 도움 - 10분당 평균','방어형 도움','10분당 평균','[젠야타] 방어형 도움'],
        info : '젠야타의 10분당 방어형 도움',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/zenyatta.png',
    },
     // 토르비욘 
     {
        name: '[토르비욘] 플레이 시간',
        value : ['torbjorn','플레이 시간','플레이 시간',null,'[토르비욘] 플레이시간'],
        info : '토르비욘의 플레이 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/torbjorn.png',
    },
    {
        name: '[토르비욘] 승률',
        value : ['torbjorn','승률','승률' ,null,'[토르비욘] 승률'],
        info : '토르비욘 영웅의 승률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/torbjorn.png',
    },
    {
        name: '[토르비욘] 목숨당 처치',
        value : ['torbjorn','목숨당 처치','목숨당 처치','게임당 평균','[토르비욘] 목숨당 처치'],
        info : '토르비욘의 목숨당 처치횟수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/torbjorn.png',
    },
    {
        name: '[토르비욘] 명중률',
        value : ['torbjorn','명중률','명중률','게임당 평균','[토르비욘] 명중률'],
        info : '토르비욘의 명중률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/torbjorn.png',
    },
    {
        name: '[토르비욘] 치명타',
        value : ['torbjorn','치명타 - 10분당 평균','치명타','10분당 평균','[토르비욘] 치명타'],
        info : '토르비욘의 10분당 평균 치명타 (헤드샷)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/torbjorn.png',
    },
    {
        name: '[토르비욘] 치명타 명중률',
        value : ['torbjorn','치명타 명중률','치명타 명중률','게임당 평균','[토르비욘] 치명타명중률'],
        info : '토르비욘의 치명타 명중률 (헤드샷 명중률)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/torbjorn.png',
    },
    {
        name: '[토르비욘] 처치수',
        value : ['torbjorn','처치 - 10분당 평균','처치수','10분당 평균','[토르비욘] 처치수'],
        info : '토르비욘의 10분당 평균 처지수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/torbjorn.png',
    },
    {
        name: '[토르비욘] 죽은횟수',
        value : ['torbjorn','죽음 - 10분당 평균','죽은 횟수','10분당 평균','[토르비욘] 죽은횟수'],
        info : '토르비욘의 10분당 평균 죽은횟수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/torbjorn.png',
    },
    {
        name: '[토르비욘] 치유량',
        value : ['torbjorn','치유량 - 10분당 평균','치유량','10분당 평균','[토르비욘] 치유량'],
        info : '토르비욘의 10분당 평균 치유량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/torbjorn.png',
    },
    {
        name: '[토르비욘] 결정타',
        value : ['torbjorn','결정타 - 10분당 평균','결정타 수','10분당 평균','[토르비욘] 결정타'],
        info : '토르비욘의 10분당 평균 결정타 (막타)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/torbjorn.png',
    },
    {
        name: '[토르비욘] 단독처치',
        value : ['torbjorn','단독 처치 - 10분당 평균','단독 처치수','10분당 평균','[토르비욘] 단독처치'],
        info : '토르비욘의 10분당 단독 처치수 (솔로킬 수)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/torbjorn.png',
    },
    {
        name: '[토르비욘] 영웅피해량',
        value : ['torbjorn','영웅에게 준 피해 - 10분당 평균','영웅에게 준 피해량','10분당 평균','[토르비욘] 영웅피해량'],
        info : '토르비욘의 10분당 상대 영웅에 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/torbjorn.png',
    },
    {
        name: '[토르비욘] 방벽피해량',
        value : ['torbjorn','방벽에 준 피해 - 10분당 평균','방벽에게 준 피해량','10분당 평균','[토르비욘] 방벽피해량'],
        info : '토르비욘의 10분당 방벽에 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/torbjorn.png',
    },
    {
        name: '[토르비욘] 모든피해량',
        value : ['torbjorn','준 모든 피해 - 10분당 평균','준 모든 피해량','10분당 평균','[토르비욘] 모든피해량'],
        info : '토르비욘의 10분당 방벽과 상대 영웅 에게 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/torbjorn.png',
    },
    {
        name: '[토르비욘] 임무 기여 시간',
        value : ['torbjorn','임무 기여 시간 - 10분당 평균','임무 기여 시간','10분당 평균','[토르비욘] 임무기여시간'],
        info : '토르비욘의 10분당 임무 기여 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/torbjorn.png',
    },
    {
        name: '[토르비욘] 임무 기여 처치',
        value : ['torbjorn','임무 기여 처치 - 10분당 평균','임무 기여 처치','10분당 평균','[토르비욘] 임무기여처치'],
        info : '토르비욘의 10분당 임무 기여 처지수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/torbjorn.png',
    },
    {
        name: '[토르비욘] 폭주 시간',
        value : ['torbjorn','폭주 시간 - 10분당 평균','폭주 시간','10분당 평균','[토르비욘] 폭주 시간'],
        info : '토르비욘의 10분당 폭주 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/torbjorn.png',
    },
    {
        name: '[토르비욘] 근접 공격 적중률',
        value : ['torbjorn','근접 공격 적중률','근접 공격 적중률','게임당 평균','[토르비욘] 근접 공격 적중률'],
        info : '토르비욘의 근접 공격 적중률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/torbjorn.png',
    },
    {
        name: '[토르비욘] 방어구 팩 생성',
        value : ['torbjorn','방어구 팩 생성 - 10분당 평균','방어구 팩 생성','10분당 평균','[토르비욘] 방어구 팩 생성'],
        info : '토르비욘의 10분당 방어구 팩 생성',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/torbjorn.png',
    },
    {
        name: '[토르비욘] 초고열 용광로로 처치',
        value : ['torbjorn','초고열 용광로로 처치 - 10분당 평균','초고열 용광로로 처치','10분당 평균','[토르비욘] 초고열 용광로로 처치'],
        info : '토르비욘의 10분당 초고열 용광로로 처치수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/torbjorn.png',
    },
    {
        name: '[토르비욘] 직접 처치',
        value : ['torbjorn','토르비욘이 직접 처치 - 10분당 평균','폭주 시직접 처치','10분당 평균','[토르비욘] 직접 처치'],
        info : '토르비욘의 10분당 포탑,용광로를 제외한 직접 처치수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/torbjorn.png',
    },
    {
        name: '[토르비욘] 포탑으로 준 피해량',
        value : ['torbjorn','포탑으로 준 피해 - 10분당 평균','포탑으로 준 피해량','10분당 평균','[토르비욘] 포탑으로 준 피해량'],
        info : '토르비욘의 10분당 포탑으로 준 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/torbjorn.png',
    },
    {
        name: '[토르비욘] 포탑으로 처치',
        value : ['torbjorn','포탑으로 처치 - 10분당 평균','포탑으로 처치','10분당 평균','[토르비욘] 포탑으로 처치'],
        info : '토르비욘의 10분당 포탑으로 처치수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/torbjorn.png',
    },
     // 트레이서 
     {
        name: '[트레이서] 플레이 시간',
        value : ['tracer','플레이 시간','플레이 시간',null,'[트레이서] 플레이시간'],
        info : '트레이서의 플레이 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/tracer.png',
    },
    {
        name: '[트레이서] 승률',
        value : ['tracer','승률','승률' ,null,'[트레이서] 승률'],
        info : '트레이서 영웅의 승률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/tracer.png',
    },
    {
        name: '[트레이서] 목숨당 처치',
        value : ['tracer','목숨당 처치','목숨당 처치','게임당 평균','[트레이서] 목숨당 처치'],
        info : '트레이서의 목숨당 처치횟수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/tracer.png',
    },
    {
        name: '[트레이서] 명중률',
        value : ['tracer','명중률','명중률','게임당 평균','[트레이서] 명중률'],
        info : '트레이서의 명중률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/tracer.png',
    },
    {
        name: '[트레이서] 치명타',
        value : ['tracer','치명타 - 10분당 평균','치명타','10분당 평균','[트레이서] 치명타'],
        info : '트레이서의 10분당 평균 치명타 (헤드샷)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/tracer.png',
    },
    {
        name: '[트레이서] 치명타 명중률',
        value : ['tracer','치명타 명중률','치명타 명중률','게임당 평균','[트레이서] 치명타명중률'],
        info : '트레이서의 치명타 명중률 (헤드샷 명중률)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/tracer.png',
    },
    {
        name: '[트레이서] 처치수',
        value : ['tracer','처치 - 10분당 평균','처치수','10분당 평균','[트레이서] 처치수'],
        info : '트레이서의 10분당 평균 처지수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/tracer.png',
    },
    {
        name: '[트레이서] 죽은횟수',
        value : ['tracer','죽음 - 10분당 평균','죽은 횟수','10분당 평균','[트레이서] 죽은횟수'],
        info : '트레이서의 10분당 평균 죽은횟수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/tracer.png',
    },
    {
        name: '[트레이서] 치유량',
        value : ['tracer','치유량 - 10분당 평균','치유량','10분당 평균','[트레이서] 치유량'],
        info : '트레이서의 10분당 평균 치유량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/tracer.png',
    },
    {
        name: '[트레이서] 결정타',
        value : ['tracer','결정타 - 10분당 평균','결정타 수','10분당 평균','[트레이서] 결정타'],
        info : '트레이서의 10분당 평균 결정타 (막타)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/tracer.png',
    },
    {
        name: '[트레이서] 단독처치',
        value : ['tracer','단독 처치 - 10분당 평균','단독 처치수','10분당 평균','[트레이서] 단독처치'],
        info : '트레이서의 10분당 단독 처치수 (솔로킬 수)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/tracer.png',
    },
    {
        name: '[트레이서] 영웅피해량',
        value : ['tracer','영웅에게 준 피해 - 10분당 평균','영웅에게 준 피해량','10분당 평균','[트레이서] 영웅피해량'],
        info : '트레이서의 10분당 상대 영웅에 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/tracer.png',
    },
    {
        name: '[트레이서] 방벽피해량',
        value : ['tracer','방벽에 준 피해 - 10분당 평균','방벽에게 준 피해량','10분당 평균','[트레이서] 방벽피해량'],
        info : '트레이서의 10분당 방벽에 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/tracer.png',
    },
    {
        name: '[트레이서] 모든피해량',
        value : ['tracer','준 모든 피해 - 10분당 평균','준 모든 피해량','10분당 평균','[트레이서] 모든피해량'],
        info : '트레이서의 10분당 방벽과 상대 영웅 에게 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/tracer.png',
    },
    {
        name: '[트레이서] 임무 기여 시간',
        value : ['tracer','임무 기여 시간 - 10분당 평균','임무 기여 시간','10분당 평균','[트레이서] 임무기여시간'],
        info : '트레이서의 10분당 임무 기여 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/tracer.png',
    },
    {
        name: '[트레이서] 임무 기여 처치',
        value : ['tracer','임무 기여 처치 - 10분당 평균','임무 기여 처치','10분당 평균','[트레이서] 임무기여처치'],
        info : '트레이서의 10분당 임무 기여 처지수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/tracer.png',
    },
    {
        name: '[트레이서] 폭주 시간',
        value : ['tracer','폭주 시간 - 10분당 평균','폭주 시간','10분당 평균','[트레이서] 폭주 시간'],
        info : '트레이서의 10분당 폭주 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/tracer.png',
    },
    {
        name: '[트레이서] 근접 공격 적중률',
        value : ['tracer','근접 공격 적중률','근접 공격 적중률','게임당 평균','[트레이서] 근접 공격 적중률'],
        info : '트레이서의 근접 공격 적중률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/tracer.png',
    },
    {
        name: '[트레이서] 역행을 통한 생명력 회복',
        value : ['tracer','생명력 회복 - 10분당 평균','역행을 통한 생명력 회복','10분당 평균','[트레이서] 역행을 통한 생명력 회복'],
        info : '트레이서의 역행을 통한 10분당 생명력 회복',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/tracer.png',
    },
    {
        name: '[트레이서] 자가 치유량',
        value : ['tracer','자가 치유 - 10분당 평균','자가 치유량','10분당 평균','[트레이서] 자가 치유량'],
        info : '트레이서의 10분당 자가 치유량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/tracer.png',
    },
    {
        name: '[트레이서] 펄스 폭탄 부착',
        value : ['tracer','펄스 폭탄 부착 - 10분당 평균','펄스 폭탄 부착','10분당 평균','[트레이서] 펄스 폭탄 부착'],
        info : '트레이서의 10분당 펄스 폭탄 부착수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/tracer.png',
    },
    {
        name: '[트레이서] 펄스 폭탄으로 처치',
        value : ['tracer','펄스 폭탄으로 처치 - 10분당 평균','펄스 폭탄으로 처치','10분당 평균','[트레이서] 펄스 폭탄으로 처치'],
        info : '트레이서의 10분당 펄스 폭탄으로 처치수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/tracer.png',
    },
     // 파라 
     {
        name: '[파라] 플레이 시간',
        value : ['pharah','플레이 시간','플레이 시간',null,'[파라] 플레이시간'],
        info : '파라의 플레이 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/pharah.png',
    },
    {
        name: '[파라] 승률',
        value : ['pharah','승률','승률' ,null,'[파라] 승률'],
        info : '파라 영웅의 승률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/pharah.png',
    },
    {
        name: '[파라] 목숨당 처치',
        value : ['pharah','목숨당 처치','목숨당 처치','게임당 평균','[파라] 목숨당 처치'],
        info : '파라의 목숨당 처치횟수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/pharah.png',
    },
    {
        name: '[파라] 명중률',
        value : ['pharah','명중률','명중률','게임당 평균','[파라] 명중률'],
        info : '파라의 명중률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/pharah.png',
    },
    {
        name: '[파라] 처치수',
        value : ['pharah','처치 - 10분당 평균','처치수','10분당 평균','[파라] 처치수'],
        info : '파라의 10분당 평균 처지수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/pharah.png',
    },
    {
        name: '[파라] 죽은횟수',
        value : ['pharah','죽음 - 10분당 평균','죽은 횟수','10분당 평균','[파라] 죽은횟수'],
        info : '파라의 10분당 평균 죽은횟수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/pharah.png',
    },
    {
        name: '[파라] 치유량',
        value : ['pharah','치유량 - 10분당 평균','치유량','10분당 평균','[파라] 치유량'],
        info : '파라의 10분당 평균 치유량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/pharah.png',
    },
    {
        name: '[파라] 결정타',
        value : ['pharah','결정타 - 10분당 평균','결정타 수','10분당 평균','[파라] 결정타'],
        info : '파라의 10분당 평균 결정타 (막타)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/pharah.png',
    },
    {
        name: '[파라] 단독처치',
        value : ['pharah','단독 처치 - 10분당 평균','단독 처치수','10분당 평균','[파라] 단독처치'],
        info : '파라의 10분당 단독 처치수 (솔로킬 수)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/pharah.png',
    },
    {
        name: '[파라] 영웅피해량',
        value : ['pharah','영웅에게 준 피해 - 10분당 평균','영웅에게 준 피해량','10분당 평균','[파라] 영웅피해량'],
        info : '파라의 10분당 상대 영웅에 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/pharah.png',
    },
    {
        name: '[파라] 방벽피해량',
        value : ['pharah','방벽에 준 피해 - 10분당 평균','방벽에게 준 피해량','10분당 평균','[파라] 방벽피해량'],
        info : '파라의 10분당 방벽에 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/pharah.png',
    },
    {
        name: '[파라] 모든피해량',
        value : ['pharah','준 모든 피해 - 10분당 평균','준 모든 피해량','10분당 평균','[파라] 모든피해량'],
        info : '파라의 10분당 방벽과 상대 영웅 에게 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/pharah.png',
    },
    {
        name: '[파라] 임무 기여 시간',
        value : ['pharah','임무 기여 시간 - 10분당 평균','임무 기여 시간','10분당 평균','[파라] 임무기여시간'],
        info : '파라의 10분당 임무 기여 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/pharah.png',
    },
    {
        name: '[파라] 임무 기여 처치',
        value : ['pharah','임무 기여 처치 - 10분당 평균','임무 기여 처치','10분당 평균','[파라] 임무기여처치'],
        info : '파라의 10분당 임무 기여 처지수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/pharah.png',
    },
    {
        name: '[파라] 폭주 시간',
        value : ['pharah','폭주 시간 - 10분당 평균','폭주 시간','10분당 평균','[파라] 폭주 시간'],
        info : '파라의 10분당 폭주 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/pharah.png',
    },
    {
        name: '[파라] 근접 공격 적중률',
        value : ['pharah','근접 공격 적중률','근접 공격 적중률','10분당 평균','[파라] 근접 공격 적중률'],
        info : '파라의 근접 공격 적중률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/pharah.png',
    },
    {
        name: '[파라] 로켓 명중',
        value : ['pharah','로켓 명중 - 10분당 평균','로켓 명중','10분당 평균','[파라] 로켓 명중'],
        info : '파라의 10분당 로켓 명중수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/pharah.png',
    },
    {
        name: '[파라] 폭주 시간',
        value : ['pharah','폭주 시간 - 10분당 평균','폭주 시간','10분당 평균','[파라] 폭주 시간'],
        info : '파라의 10분당 임무 기여 처지수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/pharah.png',
    },
    {
        name: '[파라] 포화로 처치',
        value : ['pharah','포화로 처치 - 10분당 평균','포화로 처치','10분당 평균','[파라] 포화로 처치'],
        info : '파라의 10분당 포화로 처치수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/pharah.png',
    },
     // 한조 
     {
        name: '[한조] 플레이 시간',
        value : ['hanzo','플레이 시간','플레이 시간',null,'[한조] 플레이시간'],
        info : '한조의 플레이 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/hanzo.png',
    },
    {
        name: '[한조] 승률',
        value : ['hanzo','승률','승률' ,null,'[한조] 승률'],
        info : '한조 영웅의 승률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/hanzo.png',
    },
    {
        name: '[한조] 목숨당 처치',
        value : ['hanzo','목숨당 처치','목숨당 처치','게임당 평균','[한조] 목숨당 처치'],
        info : '한조의 목숨당 처치횟수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/hanzo.png',
    },
    {
        name: '[한조] 명중률',
        value : ['hanzo','명중률','명중률','게임당 평균','[한조] 명중률'],
        info : '한조의 명중률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/hanzo.png',
    },
    {
        name: '[한조] 치명타',
        value : ['hanzo','치명타 - 10분당 평균','치명타','10분당 평균','[한조] 치명타'],
        info : '한조의 10분당 평균 치명타 (헤드샷)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/hanzo.png',
    },
    {
        name: '[한조] 치명타 명중률',
        value : ['hanzo','치명타 명중률','치명타 명중률','게임당 평균','[한조] 치명타명중률'],
        info : '한조의 치명타 명중률 (헤드샷 명중률)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/hanzo.png',
    },
    {
        name: '[한조] 처치수',
        value : ['hanzo','처치 - 10분당 평균','처치수','10분당 평균','[한조] 처치수'],
        info : '한조의 10분당 평균 처지수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/hanzo.png',
    },
    {
        name: '[한조] 죽은횟수',
        value : ['hanzo','죽음 - 10분당 평균','죽은 횟수','10분당 평균','[한조] 죽은횟수'],
        info : '한조의 10분당 평균 죽은횟수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/hanzo.png',
    },
    {
        name: '[한조] 치유량',
        value : ['hanzo','치유량 - 10분당 평균','치유량','10분당 평균','[한조] 치유량'],
        info : '한조의 10분당 평균 치유량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/hanzo.png',
    },
    {
        name: '[한조] 결정타',
        value : ['hanzo','결정타 - 10분당 평균','결정타 수','10분당 평균','[한조] 결정타'],
        info : '한조의 10분당 평균 결정타 (막타)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/hanzo.png',
    },
    {
        name: '[한조] 단독처치',
        value : ['hanzo','단독 처치 - 10분당 평균','단독 처치수','10분당 평균','[한조] 단독처치'],
        info : '한조의 10분당 단독 처치수 (솔로킬 수)',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/hanzo.png',
    },
    {
        name: '[한조] 영웅피해량',
        value : ['hanzo','영웅에게 준 피해 - 10분당 평균','영웅에게 준 피해량','10분당 평균','[한조] 영웅피해량'],
        info : '한조의 10분당 상대 영웅에 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/hanzo.png',
    },
    {
        name: '[한조] 방벽피해량',
        value : ['hanzo','방벽에 준 피해 - 10분당 평균','방벽에게 준 피해량','10분당 평균','[한조] 방벽피해량'],
        info : '한조의 10분당 방벽에 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/hanzo.png',
    },
    {
        name: '[한조] 모든피해량',
        value : ['hanzo','준 모든 피해 - 10분당 평균','준 모든 피해량','10분당 평균','[한조] 모든피해량'],
        info : '한조의 10분당 방벽과 상대 영웅 에게 가한 피해량',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/hanzo.png',
    },
    {
        name: '[한조] 임무 기여 시간',
        value : ['hanzo','임무 기여 시간 - 10분당 평균','임무 기여 시간','10분당 평균','[한조] 임무기여시간'],
        info : '한조의 10분당 임무 기여 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/hanzo.png',
    },
    {
        name: '[한조] 임무 기여 처치',
        value : ['hanzo','임무 기여 처치 - 10분당 평균','임무 기여 처치','10분당 평균','[한조] 임무기여처치'],
        info : '한조의 10분당 임무 기여 처지수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/hanzo.png',
    },
    {
        name: '[한조] 폭주 시간',
        value : ['hanzo','폭주 시간 - 10분당 평균','폭주 시간','10분당 평균','[한조] 폭주 시간'],
        info : '한조의 10분당 폭주 시간',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/hanzo.png',
    },
    {
        name: '[한조] 근접 공격 적중률',
        value : ['hanzo','근접 공격 적중률','근접 공격 적중률','게임당 평균','[한조] 근접 공격 적중률'],
        info : '한조의 근접 공격 적중률',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/hanzo.png',
    },
    {
        name: '[한조] 폭풍 화살로 처치',
        value : ['hanzo','폭풍 화살로 처치 - 10분당 평균','폭풍 화살로 처치','10분당 평균','[한조] 폭풍 화살로 처치'],
        info : '한조의 10분당 폭풍 화살로 처치수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/hanzo.png',
    },
    {
        name: '[한조] 용의 일격으로 처치',
        value : ['hanzo','용의 일격으로 처치 - 10분당 평균','용의 일격으로 처치','10분당 평균','[한조] 용의 일격으로 처치'],
        info : '한조의 10분당 용의 일격으로 처치수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/hanzo.png',
    },
    {
        name: '[한조] 음파 화살을 통한 처치 시야 지원',
        value : ['hanzo','처치 시야 지원 - 10분당 평균','음파 화살을 통한 처치 시야 지원','10분당 평균','[한조] 음파 화살을 통한 처치 시야 지원'],
        info : '한조의 음파 화살을 통한 10분당 처치 시야 지원수',
        img : 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/hanzo.png',
    },
  
]

    
////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////

const mapStateToProps = (state) => ({
    // exemple : state.exemple,
})

function mapDispatchToProps(dispatch){
    return {
    //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(OverwatchRankEdit);
  
