////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, {useState, useEffect, useRef } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { Route, Link } from 'react-router-dom';
import { useHistory } from "react-router";
// 라이브러리 
import { useGoogleLogin } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google'// 구글 로그인
// 설정 파일
import * as config from 'config/OverlapConfig'

import { withRouter } from 'react-router-dom';

import * as apiAccount from 'service/api/apiAccount'

// 위젯 
import MiniView from "components/viewer/MiniView"
import OverlapInfo from "components/Lobby/space/module/OverlapInfo"
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import 'sass/login.scss'

// 이미지 연결
import logoW from 'assets/logoW.svg';
import youtube from 'assets/main/lobby/social/youtube.png';
import twitch from 'assets/main/lobby/social/twitch.png';
import afreeca from 'assets/main/lobby/social/afreeca.png';
import facebook from 'assets/main/lobby/social/facebook.png';
import naver from 'assets/main/lobby/social/naver.png';

// 서비스 연결
import * as view from 'service/api/view';
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

function ViewCreate(props) {
    // const todos = useSelector(state => state.todos)
    // const dispatch = useDispatch()
    // const addTodo = text => dispatch(addTodo(text))


    let defaultViewData = {
      name : '',
      resolution : {width : 1920, height : 1080},
      private : false,
    }

    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////
    // state
    const [textValue, setTextValue] = useState('');
    const [resolutionValue, setResolutionValue] = useState('HD');
    const [ViewData, setViewData] = useState(defaultViewData);

    // rel 
    // const SpaceRef = useRef(null);
    ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////
      useEffect(() => {
        console.log('ViewData : ',ViewData);
        if(ViewData.resolution.width == 1920 && ViewData.resolution.height == 1080){
          setResolutionValue('HD');
        }
        if(ViewData.resolution.width == 1280 && ViewData.resolution.height == 720){
          setResolutionValue('HQ');
        }
        if(ViewData.resolution.width == 3840 && ViewData.resolution.height == 2160){
          setResolutionValue('4K');
        }
      }, []);
    ////////////////////////////////////////////////////////
    // Fuction
    ////////////////////////////////////////////////////////
      function valueChange(key,value) {
        let newObject = ViewData;
        newObject[key] = value;
        setViewData(newObject);
      }
      function handleTextChange(event) {
        setTextValue(event.target.value);
        valueChange('name',event.target.value)
      }
      console.log(resolutionValue)


    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////
    return (
        <div className='overlapCreateWrap SpaceWrap'>
            <section className='overlapCreate'>
              <div className='overlapSide'>
              <div className='title'>
                <label>
                  <p>제목</p>
                  <input type="text" value={textValue} onChange={handleTextChange} placeholder={'새로운 뷰 의 이름'} />
                </label>
              </div>
              <div className='resolution'>
                <p>해상도</p>
      
                  {/* <button>
                    <span className="material-symbols-outlined iconfont icon"> high_quality </span>
                  </button> */}
                  <div>
                    <button className={resolutionValue=='HD'? ' select':''}>
                      <span className={"material-symbols-outlined iconfont icon"}> hd </span>
                    </button>
                    <button className={resolutionValue=='4K'? ' select':''}>
                    <span className={"material-symbols-outlined iconfont icon"}> 4k </span>
                    </button>
                  </div>

              </div>
              <div className='direction'>
                  <p> 방향 </p>
                  <div>
                    <button className={resolutionValue=='HD'? ' select':''}>
                      <span className={"material-symbols-outlined iconfont icon"}> crop_landscape </span>
                    </button>
                    <button className={resolutionValue=='4K'? ' select':''}>
                    <span className={"material-symbols-outlined iconfont icon"}> crop_portrait </span>
                    </button>
                  </div>          

              </div>
              {/* <div className='private'>
                  <p> 공개 여부 </p>
                  <div>
                    <button className={resolutionValue=='HD'? ' select':''}>
                      <span className={"material-symbols-outlined iconfont icon"}> visibility </span>
                    </button>
                    <button className={resolutionValue=='4K'? ' select':''}>
                    <span className={"material-symbols-outlined iconfont icon"}> visibility_off </span>
                    </button>
                  </div>          
              </div> */}
              </div>
              <div className='leftSide'>

                <button className='create' onClick={()=>view.create(props.spaceId,ViewData)}>
                  뷰 생성하기
                </button>
              </div>
                    
            </section>
        </div>
    );
  }

  export default ViewCreate;

  