////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { Route, Link } from 'react-router-dom';
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결


// 이미지 연결
import logoW from 'assets/logoW.svg';
import logo from 'assets/logo.svg';
import twitchLogo from 'assets/main/twitchlogo.png';
import edit from 'assets/main/edit.png';
import bot from 'assets/main/bot.png';
import control from 'assets/main/control.png';
import banner1 from 'assets/main/banner1.png';

// 컴포넌트 연결
import SpaceGuideList from "components/Lobby/space/module/SpaceGuideList"


// 서비스 연결
import * as guide from 'service/event/guide';
import * as Space from 'service/api/space' ; 
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class SpaceSet extends Component {
    
    state = {
        userId : '',
        screenData : [{"type":"Text","version":1,"uuid":"cd0155f64c81477dad4511694c54a203","alias":null,"data":{"position":{"show":true,"x":"555","y":"322"},"style":{"textAlign":"center","fontSize":"200"},"size":{},"value":{"text":"테스트중!!!!!!!!!!"},"preset":null,"display":{},"meta":{}},"animation":{"mountStart":null,"mount":null,"mountEnd":null}}],
        zoom : 0,
        infoData : null,
        spaceList : null,
    }

    async componentDidMount () {
        await this.getSpace()
    }

    constructor(props) {
        super(props);
        this.broadcast = React.createRef();
      }

    componentDidUpdate(prevProps){
        
    }

    copy = async (value) => {
        if (navigator.clipboard) {
           await navigator.clipboard.writeText(value).then(() => {
                alert('복사되었습니다. 이제 붙여넣으세요')
            });
          } else {
            alert('구형브라우저를 사용중이라 복사에 실패하였습니다.')
          }
    }

    addSpace = async (title)=>{
   

    }

    getSpace = async ()=>{

    }

    spaceList = ()=>{

    }

    render() { 
        return (
            <div className = "Lobby_innerPage" >
            <div className = "Lobby_common Lobby_SpaceOverlap" ref={this.spaceRef} >
                <div className='space_overlapList'>
                    <div className='spaceBox overlapList'>
                        <SpaceGuideList guideList={guide.spaceGuide}  lobbyChange={this.props.lobbyChange}/>
                    </div>
                </div>
               
            </div>
        </div>
        )
    }
}
////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////

const mapStateToProps = (state) => ({
    screenData : state.screenData,
    storeScreenNo : state.screenNo,
    account : state.account,
})
function mapDispatchToProps(dispatch){
    return {
    //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(SpaceSet);
  