function preset(preset,self,data,style){
    let fullsize = data.size.height
    let width = data.size.width*90/100 
    let profile = self.state.profile
    let heroData = data.value.heroData
    let dataList = []
    let dataField =''
    console.log(data.value.heroPosition,'테스트')
    try{

    }catch{

    }
    switch (preset) {
        case 'normal': 
            console.log('normal')
            // switch (tset ) {
            switch (profileState(profile)) {
                case "public":
                    dataField 
                    = (
                        <ul className="userRank">
                            <li className="userRanklist userRanktanker ">
                                <div className={getTierText(self.userRank('tanker'))}>
                                    <img className="tierImg" src={self.tierRank('tanker')}/>
                                    <span>{self.userRank('tanker')?self.userRank('tanker'):''}</span>
                                </div>
                            </li>
                            <li className="userRanklist userRankdps">
                                <div className={getTierText(self.userRank('dps'))}>
                                    <img className="tierImg" src={self.tierRank('dps')}/>
                                    <span>{self.userRank('dps')?self.userRank('dps'):''}</span>
                                </div>
                            </li>
                            <li className="userRanklist userRanksuppot">
                                <div className={getTierText(self.userRank('suppot'))}>
                                    <img className="tierImg" src={self.tierRank('suppot')}/>
                                    <span>{self.userRank('suppot')?self.userRank('suppot'):''}</span>
                                </div>
                            </li>
                        </ul>
                    )      
                    break;
                case "private":
                    dataField 
                    = (
                        <div className="profilePrivate" style={{width}}>
                            <h3 >
                                프로필이 비공개 되었습니다.
                            </h3>
                        </div>
             
                    )                     
                    break;
                case "Error":
                    dataField 
                    = (<div className="profileError" style={{width}}>
                        <h3>
                            프로필을 찾을 수 없습니다.<br/>
                        </h3>
                    </div>
                    )                  
                    break;                 
                default:
                    dataField 
                    = (
                        <div className="profileLode" style={{width}}>
                        <h3>
                            프로필 검색중입니다.
                        </h3>
                    </div>
                )   
                    break;
            }
            return(
                <div className={"object "+self.props.widgetName+"_preset_"+preset} ref={self.widget}  style={{...style.object}} >
                    <div className="userName" style={{height:fullsize*40/100+'px',fontSize:fullsize*30/100+'px',lineHeight:fullsize*40/100+'px'}}>
                    {data.value.userAlias?
                            <h3 className="userName"  >
                                <div >
                                    {data.value.userAlias}
                                </div>
                                <div >
                                    ({self.getUserAccount(profile)})
                                </div>
                            </h3>
                            :
                            <h3 className="userName" >
                                {self.getUserAccount(profile)} 님의 점수
                            </h3>
                            }
                    </div>

                    <div style={{height:fullsize*60/100+'px',fontSize:fullsize*30/100+'px',lineHeight:fullsize*50/100+'px'}}>
                        {dataField}
                    </div>
                </div>

        )
        case 'normalShort': 
            switch (profileState(profile)) {
                case "public":
                    dataField 
                    = (<ul className="userRank">
                            {data.value.heroPosition=='tanker'? 
                            <li className="userRanklist userRanktanker ">
                                <img className="positionImg" src={positionIMG('tanker')}/>
                                <div className={getTierText(self.userRank('tanker')) +' rankinfo'}>
                                        <img className="tierImg" src={self.tierRank('tanker')}/>
                                    <span>{self.userRank('tanker')?self.userRank('tanker'):<small>----</small>}</span>
                                </div>

                            </li>
                            :''}
                            {data.value.heroPosition=='dps'? 
                            <li className="userRanklist userRankdps">
                                <img className="positionImg" src={positionIMG('dps')}/>
                                <div className={getTierText(self.userRank('dps')) +' rankinfo'}>
                                        <img className="tierImg" src={self.tierRank('dps')}/>
                                    <span>{self.userRank('dps')?self.userRank('dps'):<small>----</small>}</span>
                                </div>
                            </li>
                            :''}
                            {data.value.heroPosition=='suppot'? 
                            <li className="userRanklist userRanksuppot">
                                <img className="positionImg" src={positionIMG('suppot')}/>
                                <div className={getTierText(self.userRank('suppot'))+' rankinfo'}>
                                        <img className="tierImg" src={self.tierRank('suppot')}/>
                                    <span>{self.userRank('suppot')?self.userRank('suppot'):<small>----</small>}</span>
                                </div>
                            </li>
                            :''}
                        </ul> 
                    )      
                    break;
                case "private":
                    dataField 
                    = (<div className="profilePrivate" style={{width}}>
                            <h3 >
                                비공개
                            </h3>
                        </div>
                    )                     
                    break;
                case "Error":
                    dataField 
                    = (<div className="profileError" style={{width}}>
                        <h3>
                        검색실패 <br/>
                        </h3>
                    </div>
                    )                  
                    break;                 
                default:
                    dataField 
                    = (<div className="profileLode" style={{width}}>
                        <h3>
                            로딩중
                        </h3>
                    </div>
                    )   
                    break;
                }
            return(
                <div className={"object "+self.props.widgetName+"_preset_"+preset} ref={self.widget}  style={{...style.object}} >
                    <div className="userName" style={{height:fullsize*40/100+'px',fontSize:fullsize*20/100+'px',lineHeight:fullsize*40/100+'px'}}>
                    {data.value.userAlias?
                            <h3 className="userName"  >
                                <div >
                                    {data.value.userAlias}
                                </div>
                                <div >
                                    ({self.getUserAccount(profile)})
                                </div>
                            </h3>
                            :
                            <h3 className="userName" >
                                {self.getUserAccount(profile)}
                            </h3>
                            }
                    </div>

                    <div style={{height:fullsize*60/100+'px',fontSize:fullsize*30/100+'px',lineHeight:fullsize*50/100+'px'}}>
                        {dataField}
                    </div>
                    {/* <div style={{height:fullsize*100/100+'px',fontSize:fullsize*50/100+'px',lineHeight:fullsize*80/100+'px'}}>
                        {dataField}
                    </div> */}
                </div>
            )
            switch (profileState(profile)) {
                case "public":
                    dataField 
                    = (
                        <ul className="userRank">
                            <li className="userRanklist userRanktanker ">
                                <div className={getTierText(self.userRank('tanker'))}>
                                    <img src={self.tierRank('tanker')}/>
                                    <span>{self.userRank('tanker')?self.userRank('tanker'):''}</span>
                                </div>

                            </li>
                            <li className="userRanklist userRankdps">
                                <div className={getTierText(self.userRank('dps'))}>
                                    <img src={self.tierRank('dps')}/>
                                    <span>{self.userRank('dps')?self.userRank('dps'):''}</span>
                                </div>
                            </li>
                            <li className="userRanklist userRanksuppot">
                                <div className={getTierText(self.userRank('suppot'))}>
                                    <img src={self.tierRank('suppot')}/>
                                    <span>{self.userRank('suppot')?self.userRank('suppot'):''}</span>
                                </div>
                            </li>
                        </ul>
           
                    )      
                    break;
                case "private":
                    dataField 
                    = (
                        <div className="profilePrivate" style={{width}}>
                            <h3 >
                                프로필이 비공개 되었습니다.
                            </h3>
                        </div>
             
                    )                     
                    break;
                case "Error":
                    dataField 
                    = (<div className="profileError" style={{width}}>
                        <h3>
                            프로필을 찾을 수 없습니다.<br/>
                        </h3>
                    </div>
                    )                  
                    break;                 
                default:
                    dataField 
                    = (
                        <div className="profileLode" style={{width}}>
                        <h3>
                            프로필 검색중입니다.
                        </h3>
                    </div>
                )   
                    break;
            }
            return(
                <div className={"object "+self.props.widgetName+"_preset_"+preset} ref={self.widget}  style={{...style.object}} >


                    <div className="userName" style={{height:fullsize*40/100+'px',fontSize:fullsize*30/100+'px',lineHeight:fullsize*40/100+'px'}}>
                    {data.value.userAlias?
                            <h3 className="userName"  >
                                <div >
                                    {data.value.userAlias}
                                </div>
                                <div >
                                    ({self.getUserAccount(profile)})
                                </div>
                            </h3>
                            :
                            <h3 className="userName" >
                                {self.getUserAccount(profile)} 님의 점수
                            </h3>
                            }
                    </div>

                    <div style={{height:fullsize*60/100+'px',fontSize:fullsize*30/100+'px',lineHeight:fullsize*50/100+'px'}}>
                        {dataField}
                    </div>
                </div>

        )
        case 'simple': 
            switch (profileState(profile)) {
                case "public":
                    dataField 
                    = (<ul className="userRank">
                            <li className="userRanklist userRanktanker ">
                                <div className={getTierText(self.userRank('tanker'))}>
                                    <img className="tierImg" src={self.tierRank('tanker')}/>
                                    <span>{self.userRank('tanker')?self.userRank('tanker'):<small>----</small>}</span>
                                </div>

                            </li>
                            <li className="userRanklist userRankdps">
                                <div className={getTierText(self.userRank('dps'))}>
                                    <img className="tierImg" src={self.tierRank('dps')}/>
                                    <span>{self.userRank('dps')?self.userRank('dps'):<small>----</small>}</span>
                                </div>
                            </li>
                            <li className="userRanklist userRanksuppot">
                                <div className={getTierText(self.userRank('suppot'))}>
                                    <img className="tierImg" src={self.tierRank('suppot')}/>
                                    <span>{self.userRank('suppot')?self.userRank('suppot'):<small>----</small>}</span>
                                </div>
                            </li>
                        </ul> 
                    )      
                    break;
                case "private":
                    dataField 
                    = (<div className="profilePrivate" style={{width}}>
                            <h3 >
                                프로필이 비공개 되었습니다.
                            </h3>
                        </div>
                    )                     
                    break;
                case "Error":
                    dataField 
                    = (<div className="profileError" style={{width}}>
                        <h3>
                            프로필을 찾을 수 없습니다.<br/>
                        </h3>
                    </div>
                    )                  
                    break;                 
                default:
                    dataField 
                    = (<div className="profileLode" style={{width}}>
                        <h3>
                            프로필 검색중입니다.
                        </h3>
                    </div>
                    )   
                    break;
            }
            return(
                <div className={"object "+self.props.widgetName+"_preset_"+preset} ref={self.widget}  style={{...style.object}} >
                    <div style={{height:fullsize*100/100+'px',fontSize:fullsize*50/100+'px',lineHeight:fullsize*80/100+'px'}}>
                        {dataField}
                    </div>
                </div>
            )
        case 'simpleShort': 
            switch (profileState(profile)) {
                case "public":
                    dataField 
                    = (<ul className="userRank">
                            {data.value.heroPosition=='tanker'? 
                            <li className="userRanklist userRanktanker ">
                                <img className="positionImg" src={positionIMG('tanker')}/>
                                <div className={getTierText(self.userRank('tanker')) +' rankinfo'}>
                                        <img height="100%" className="tierImg" src={self.tierRank('tanker')}/>
                                    <span>{self.userRank('tanker')?self.userRank('tanker'):<small>----</small>}</span>
                                </div>

                            </li>
                            :''}
                            {data.value.heroPosition=='dps'? 
                            <li className="userRanklist userRankdps">
                                <img className="positionImg" src={positionIMG('dps')}/>
                                <div className={getTierText(self.userRank('dps')) +' rankinfo'}>
                                        <img height="100%" className="tierImg" src={self.tierRank('dps')}/>
                                    <span>{self.userRank('dps')?self.userRank('dps'):<small>----</small>}</span>
                                </div>
                            </li>
                            :''}
                            {data.value.heroPosition=='suppot'? 
                            <li className="userRanklist userRanksuppot">
                                <img className="positionImg" src={positionIMG('suppot')}/>
                                <div className={getTierText(self.userRank('suppot'))+' rankinfo'}>
                                        <img className="tierImg" src={self.tierRank('suppot')}/>
                                    <span>{self.userRank('suppot')?self.userRank('suppot'):<small>----</small>}</span>
                                </div>
                            </li>
                            :''}
                        </ul> 
                    )      
                    break;
                case "private":
                    dataField 
                    = (<div className="profilePrivate" style={{width}}>
                            <h3 >
                                비공개
                            </h3>
                        </div>
                    )                     
                    break;
                case "Error":
                    dataField 
                    = (<div className="profileError" style={{width}}>
                        <h3>
                        검색실패 <br/>
                        </h3>
                    </div>
                    )                  
                    break;                 
                default:
                    dataField 
                    = (<div className="profileLode" style={{width}}>
                        <h3>
                            로딩중
                        </h3>
                    </div>
                    )   
                    break;
                }
            return(
                <div className={"object "+self.props.widgetName+"_preset_"+preset} ref={self.widget}  style={{...style.object}} >
                    <div style={{height:fullsize*100/100+'px',fontSize:fullsize*50/100+'px',lineHeight:fullsize*80/100+'px'}}>
                        {dataField}
                    </div>
                </div>
            )
        case 'clear': 
            switch (profileState(profile)) {
                case "public":
                    dataField 
                    = (<ul className="userRank">
                            <li className="userRanklist userRanktanker ">
                                <div className={getTierText(self.userRank('tanker')) +' rankinfo'}>
               
                                        <img className="positionImg" src={positionIMG('tanker')}/>
                                        <img className="tierImg" src={self.tierRank('tanker')}/>

                                    <span>{self.userRank('tanker')?self.userRank('tanker'):<small>----</small>}</span>
                                </div>

                            </li>
                            <li className="userRanklist userRankdps">
                                <div className={getTierText(self.userRank('dps')) +' rankinfo'}>
  
                                        <img className="positionImg" src={positionIMG('dps')}/>
                                        <img className="tierImg" src={self.tierRank('dps')}/>
                   
                                  
                                    <span>{self.userRank('dps')?self.userRank('dps'):<small>----</small>}</span>
                                </div>
                            </li>
                            <li className="userRanklist userRanksuppot">
                                <div className={getTierText(self.userRank('suppot'))+' rankinfo'}>

                                        <img className="positionImg" src={positionIMG('suppot')}/>
                                        <img className="tierImg" src={self.tierRank('suppot')}/>
                
                                    <span>{self.userRank('suppot')?self.userRank('suppot'):<small>----</small>}</span>
                                </div>
                            </li>
                        </ul> 
                    )      
                    break;
                case "private":
                    dataField 
                    = (<div className="profilePrivate" style={{width}}>
                            <h3 >
                                프로필이 비공개 되었습니다.
                            </h3>
                        </div>
                    )                     
                    break;
                case "Error":
                    dataField 
                    = (<div className="profileError" style={{width}}>
                        <h3>
                            프로필을 찾을 수 없습니다.<br/>
                        </h3>
                    </div>
                    )                  
                    break;                 
                default:
                    dataField 
                    = (<div className="profileLode" style={{width}}>
                        <h3>
                            프로필 검색중입니다.
                        </h3>
                    </div>
                    )   
                    break;
            }
            return(
                <div className={"object "+self.props.widgetName+"_preset_"+preset} ref={self.widget}  style={{...style.object}} >
                    <div style={{height:fullsize*100/100+'px',fontSize:fullsize*50/100+'px',lineHeight:fullsize*80/100+'px'}}>
                        {dataField}
                    </div>
                </div>
            )
        case 'clearShort': 
            switch (profileState(profile)) {
                case "public":
                    dataField 
                    = (<ul className="userRank">
                            {data.value.heroPosition=='tanker'? 
                            <li className="userRanklist userRanktanker ">
                                <div className={getTierText(self.userRank('tanker')) +' rankinfo'}>
            
                                        <img className="positionImg" src={positionIMG('tanker')}/>
                                        <img className="tierImg" src={self.tierRank('tanker')}/>

                                    <span>{self.userRank('tanker')?self.userRank('tanker'):<small>----</small>}</span>
                                </div>

                            </li>
                             :''}
                             {data.value.heroPosition=='dps'? 
                            <li className="userRanklist userRankdps">
                                <div className={getTierText(self.userRank('dps')) +' rankinfo'}>

                                        <img className="positionImg" src={positionIMG('dps')}/>
                                        <img className="tierImg" src={self.tierRank('dps')}/>
                
                                
                                    <span>{self.userRank('dps')?self.userRank('dps'):<small>----</small>}</span>
                                </div>
                            </li>
                            :''}
                            {data.value.heroPosition=='suppot'? 
                            <li className="userRanklist userRanksuppot">
                                <div className={getTierText(self.userRank('suppot'))+' rankinfo'}>

                                        <img className="positionImg" src={positionIMG('suppot')}/>
                                        <img className="tierImg" src={self.tierRank('suppot')}/>
                
                                    <span>{self.userRank('suppot')?self.userRank('suppot'):<small>----</small>}</span>
                                </div>
                            </li>
                            :''}
                        </ul> 
                    )      
                    break;
                case "private":
                    dataField 
                    = (<div className="profilePrivate" style={{width}}>
                            <h3 >
                                비공개
                            </h3>
                        </div>
                    )                     
                    break;
                case "Error":
                    dataField 
                    = (<div className="profileError" style={{width}}>
                        <h3>
                           검색실패 <br/>
                        </h3>
                    </div>
                    )                  
                    break;                 
                default:
                    dataField 
                    = (<div className="profileLode" style={{width}}>
                        <h3>
                            로딩중
                        </h3>
                    </div>
                    )   
                    break;
                }
            return(
                <div className={"object "+self.props.widgetName+"_preset_"+preset} ref={self.widget}  style={{...style.object}} >
                    <div style={{height:fullsize*100/100+'px',fontSize:fullsize*50/100+'px',lineHeight:fullsize*80/100+'px'}}>
                        {dataField}
                    </div>
                </div>
            )
        default:
            <div className={"object unknownPreset"} ref={self.widget} style={{...style.object}} >
                <p>알수없는 프리셋</p>
            </div>
    }
}
export function data(self,style) {
    return preset(self.props.data.preset,self,self.props.data,style)
}



function getTierImg (point) {

    if (point>4400) {
        return "https://overlap.s3-ap-northeast-1.amazonaws.com/widget/Overwatch/Ranker_icon.png"
    } else if (point>4000) {
        return "https://overlap.s3-ap-northeast-1.amazonaws.com/widget/Overwatch/GrandMaster_icon.png"
    } else if (point>3500) {
        return "https://overlap.s3-ap-northeast-1.amazonaws.com/widget/Overwatch/Master_icon.png"
    } else if (point>3000) {
        return "https://overlap.s3-ap-northeast-1.amazonaws.com/widget/Overwatch/Diamond_icon.png"  
    } else if (point>2500) {
        return "https://overlap.s3-ap-northeast-1.amazonaws.com/widget/Overwatch/Platinum_icon.png"
    } else if (point>2000) {
        return "https://overlap.s3-ap-northeast-1.amazonaws.com/widget/Overwatch/Gold_icon.png"
    } else if (point<1500) {
        return "https://overlap.s3-ap-northeast-1.amazonaws.com/widget/Overwatch/Silver_icon.png"
    } else if (point>0) {
        return "https://overlap.s3-ap-northeast-1.amazonaws.com/widget/Overwatch/Bronze_icon.png"
    } else {

    }
}
function getTierText (point) {

    if (point>4400) {
        return 'ranker'
    } else if (point>4000) {
        return 'grandmaster'
    } else if (point>3500) {
        return 'master'
    } else if (point>3000) {
        return 'diamond'
    } else if (point>2500) {
        return 'platinum'
    } else if (point>2000) {
        return 'gold'
    } else if (point<1500) {
        return 'silver'
    } else if (point>0) {
        return 'bronze'
    } else {
        return 'underrank'
    }
}



function positionIMG(position){
        switch (position) {     
            case 'tanker':
                return "https://overlap.s3-ap-northeast-1.amazonaws.com/widget/Overwatch/Tank_icon.svg" 
            case 'dps':
                return "https://overlap.s3-ap-northeast-1.amazonaws.com/widget/Overwatch/Damage_icon.svg"
            case 'suppot':
                return "https://overlap.s3-ap-northeast-1.amazonaws.com/widget/Overwatch/Support_icon.svg"
            default:
                return '';
        }
}
    
function getProfileImage(profile){
    if(profile=="Error"){
        return 'https://d15f34w2p8l1cc.cloudfront.net/overwatch/c3090e3a1dccc58f143ff53801bc0cecb139f0eb1278f157d0b5e29db9104bed.png'
    }
    if (profile) {
        return profile.profileImage
    }else{
        return 'https://d15f34w2p8l1cc.cloudfront.net/overwatch/c3090e3a1dccc58f143ff53801bc0cecb139f0eb1278f157d0b5e29db9104bed.png'
    }
}
    
function getUserAccount(profile){
    if (profile) {
        return profile.userAccount
    }else{
        return '검색중...'
    }
}

function profileState(profile){
    if(profile=="Error"){
        return "Error"
    }
    if (profile) {
        return profile.profileState
    }
    else{
        return false
    }
}

function getMostImg(mostList,size=null){
    let custom = {}
    if (!mostList){
        return '전적없음'
    }
    if (size){
    let custom = {height:size+'px'}
    }

    let res = []
    for (let index = 0; index < mostList.length; index++) {
        res[index] = (
            <img src={"https://d1u1mce87gyfbn.cloudfront.net/hero/"+ mostList[index].hero+"/hero-select-portrait.png"}
            height={size+'px'}
            />
        )
    }
    return res
}



