////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widgetEdit.scss"
import "sass/animation.scss"

// 이미지 연결
import logoW from 'assets/logoW.svg';

// 컴포넌트 연결
import Show from './NumberModule' //넘버 모듈

// 서비스 연결
import * as Event from 'service/event/Actions' ; 
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

class AnimationModule extends Component {

    state = {
        search : null,
    }

    list = () =>{
        let list = this.props.list
        if (this.state.search){
            list = this.props.list.filter( data =>(data.name.search(this.state.search) != -1));
        }

        list = list.map(
            (info,index) => (
            <div className="animationList">
                <div className="animationView">
                    <div className="animationContainer">
                        <div className="animationSample">
                            <img src={logoW}/>
                          
                        </div>
                     
                    </div>
                   
                </div>
                <button className="animationChange" onClick={()=>this.props.change(this.props.type,info.value)}>
                {info.name}
                </button>
            </div>

            ) 
        );    
        return list
    }

    render() { 

        let placeholder = '검색 해보세요'
        if(this.props.placeholder){
            placeholder = this.props.placeholder
        }

        return (
            <label className="AnimationModule" htmlfor="fname"> 
            애니메이션 종류
            <div className="listUp">
                {this.list()}
            </div>

 
            </label>
        );
    }
}

////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    // screenData : state.screenData,
})

function mapDispatchToProps(dispatch){
    return {
    //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(AnimationModule);
  