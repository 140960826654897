////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widgetEdit.scss"


// 컴포넌트 연결
import MediaSourceModule from '../editModule/MediaSourceModule'; // 텍스트 모듈
import ColorModule from '../editModule/ColorModule'; // 색상 모듈
import DropdownModule from '../editModule/DropdownModule';
import SwitchModule from '../editModule/SwitchModule'; // 체크박스 모듈
// 서비스 연결

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class CameraEdit extends Component {
    
    state = {
        cameraList : [],
    }

    change = (name,value) =>{
        this.props.callBack('style',name,value)
    }
    valueChange = (name,value) =>{
        this.props.callBack('value',name,value)
    }


    componentDidMount(){
        let cameraList = ['자동설정'] 
        if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
            console.error("enumerateDevices() not supported.");
            return;
          }
          navigator.mediaDevices.enumerateDevices()
          .then((devices) => {
            for (let index = 0; index < devices.length; index++) {
                if (devices[index].kind == 'videoinput'){
                    cameraList.push(devices[index].label)
                }
            }
            this.setState({
                cameraList : cameraList
            })
          })
          .catch(function(err) {

          });

    }


    render() { 
        const Style = {
            label: {
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
            },
            textarea : {
                width: '100%',
                resize: 'none',
                boxSizing:'border-box',
            },
            mediaView : {
                width: '100%',
                MaxHeight : '500px',
            }

        }

        return (
            <Fragment>
            <fieldset className="EditModuleField" style={Style.fieldset} >
                <legend style={Style.legend} > 웹캠 설정 </legend>
                <p className="notice">
                    웹캠의 카메라 설정은 방송을 송출하는 <br/> 컴퓨터에서 설정해야 합니다.
                </p>
                <DropdownModule value={this.props.data.value.device} type ="device" change={this.valueChange} title="카메라" dropdown ={this.state.cameraList}/>
            </fieldset>
            </Fragment>
        );
    }
}

////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    // screenData : state.screenData,
})

function mapDispatchToProps(dispatch){
    return {
    //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(CameraEdit);
  