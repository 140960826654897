////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widgetEdit.scss"


// 컴포넌트 연결
// import Show from '../show/Show'; // 메뉴 헤더

// 서비스 연결
import * as Utility from 'service/other/Utility'; 

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class FileUploadModule extends Component {


    constructor(props) {
        super(props);
        this.fileUploadRef = React.createRef();
    }

    state = {
        dropdown : false
    }

    title = () =>{
        if(this.props.title)
        {
            return (<h5 className='EditModuleTitle'>{this.props.title}</h5>)
        }
    }
    fileReader = (e) => {
        let file = e.target.files[0];
        Utility.imageCompressor(file,this.imageReader)
    }

    imageReader = (file) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = e => {
        this.props.change(this.props.type,e.target.result)
        };
    }

    render() { 
        return (
          <div className="FileUploadEditModule EditModule">
            {this.title()}
            <label className="FileUploadFild" for={"inputFile_"+this.props.title}>
                <span className="FileUploadButton">업로드</span>
                <input className="FileUpload" id={"inputFile_"+this.props.title} type="file" accept='image/*"/' ref={this.fileUploadRef} onSubmit={(e)=>this.fileReader(e)}  onChange={(e)=>this.fileReader(e)} ></input>
            </label> 

          </div>
        );
    }
}

////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    // screenData : state.screenData,
})

function mapDispatchToProps(dispatch){
    return {
    //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(FileUploadModule);