////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widgetEdit.scss"


// 컴포넌트 연결
import TextModule from '../editModule/TextModule'; // 텍스트 모듈
import TextSizeModule from '../editModule/TextSizeModule'; // 텍스트 크기 모듈
import FontModule from '../editModule/FontModule'; // 폰트 종류 모듈
import ColorModule from '../editModule/ColorModule'; // 색상 모듈
import RangeModule from '../editModule/RangeModule'; //범위 모듈 
import RadioButtonModule from '../editModule/RadioButtonModule';
// 서비스 연결

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class ScrollCaptionEdit extends Component {
    
    state = {
        value : ''

    }

    change = (name,value) =>{
        console.log(name,value,'테스트')
        this.props.callBack('style',name,value)
    }
    valueChange = (name,value) =>{

        switch (name) {
            case 'fontSize':
                // px 때문에 비교 불가 
                if(value>this.props.data.size.height){
                    console.log('텍스트 증가필요 ')
                }
                break;
        
            default:
                break;
        }
        this.props.callBack('value',name,value)

    }
    
    render() { 

        const Style = {
            label: {
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
            },
            textarea : {
                width: '100%',
                resize: 'none',
                boxSizing:'border-box',
            },
            mediaView : {
                width: '100%',
                MaxHeight : '500px',
            }

        }
        return (
            <Fragment>

            <fieldset className="EditModuleField" style={Style.fieldset} >
                <legend style={Style.legend} > 이동속도 </legend>
                <RadioButtonModule value={this.props.data.value.speed} list={[{name:'빠르게',value:30},{name:'보통',value:15},{name:'느리게',value:5},{name:'멈춤',value:0}]} type ="speed" title="텍스트속도"  change={this.valueChange} />
            </fieldset>

            <fieldset className="EditModuleField" style={Style.fieldset} >
                <legend style={Style.legend} > 텍스트 </legend>
                <TextModule value={this.props.data.value.text} fontFamily={this.props.data.style.fontFamily} type ="text" placeholder="여기에 텍스트를 입력해주세요"  change={this.valueChange}/>

                {/* <ImageButtonModule value={this.props.data.value.textAlign} type ="verticalAlign" data={[{val:'flex-start',image:upperButton},{val:'center',image:midButton},{val:'flex-end',image:lowerButton}]} type="verticalAlign" title="상하 정렬"  change={this.change}/> */}
                <ColorModule value={this.props.data.style.color} type ="color" title="글자색상"change={this.change}/>
                <TextSizeModule value={this.props.data.style.fontSize} type ="fontSize" change={this.change}/>
                <FontModule  value={this.props.data.style.fontFamily} type ="fontFamily" change={this.change}/>
                <ColorModule value={this.props.data.style.fill} type ="fill" title="배경색상" change={this.change}/>
                <RangeModule value={this.props.data.style.fillOpacity} type ="fillOpacity" title="배경투명도" valueView="%" min="0" max="100" change={this.change} />
            </fieldset>

            {/* <details>
                <summary>
                    외곽선
                </summary>
                <fieldset className="EditModuleField" style={Style.fieldset} >
                <legend >외곽선</legend>
                <RangeModule value={this.props.data.style.textOutline} type ="textOutline"valueView="%" min="0" max="150" title="두께" step="10" change={this.change} />
                <ColorModule value={this.props.data.style.outlineColor} type ="outlineColor" title="색상" change={this.change}/>
            </fieldset>

            </details> */}
            </Fragment>
        );
    }
}

////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    // screenData : state.screenData,
})

function mapDispatchToProps(dispatch){
    return {
    //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(ScrollCaptionEdit);
  