////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { Route, Link } from 'react-router-dom';
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/frontPage.scss"

// 이미지 연결
import logo from 'assets/logo.svg';
// 컴포넌트 연결
// import Text from './template/Text'; // 메뉴 헤더
import FrontNav from './FrontNav'; // 작은 스크린
// 서비스 연결
import * as Template from 'service/event/template';
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class FrontTemplate extends Component {
    
    state = {
        zoom : 0,
        page : 0
    }

    constructor(props) {
        super(props);
        this.myRef = React.createRef();
      }

    componentDidMount() {
        window.scrollTo(0, 0);
        // let count = setInterval(this.page, 3000)
        // this.setState({
        //     zoom : this.myRef.current.clientWidth*1.0/1920,
        //     interval: count,
        // })
    }

    page = () =>{
        this.setState({
            page : this.state.page+1
        })
    }  

    componentWillUnmount() {
        clearInterval(this.state.interval);
    }

    render() { 
        let templateList = Template.object('OverwatchLeages_white')
        let info = Template.object('FrontInfo')
        let infoNO = this.state.page%info.pages.length
        let pageNo = 0
        pageNo = this.state.page%templateList.pages.length
        return (
            <div id = "TemplateInfoPage" >
                <FrontNav/>
                {/* 동영상 소개 */}
                <section id = "infoPageTitle">
                    <div className="titleText">
                        템플릿 소개
                    </div>
                    <div className="titleImg relative">
                    <img className="template1 templateImg" src="https://overlap.s3-ap-northeast-1.amazonaws.com/front/img/tamplate1.png"/>
                    <img className="template2 templateImg" src="https://overlap.s3-ap-northeast-1.amazonaws.com/front/img/tamplate2.png"/>
                    <img className="template3 templateImg" src="https://overlap.s3-ap-northeast-1.amazonaws.com/front/img/tamplate3.png"/>
                    </div>
                </section>
                {/* 오버랩의 위젯 알아보기 */}
                <section id="templateInfo" className="relative">
                    <div className="infoText">
                        <h3>템플릿을<br/> 소개합니다</h3>
                        <p>템플릿을 통해 간단한 수정 만으로 <br/> 빠르게 행사를 준비 할 수 있습니다.</p>
                    </div>
                    <img className="templateEx templateImg" src="https://overlap.s3-ap-northeast-1.amazonaws.com/front/img/tamplateEx.png"/>
                    <p className="template1Text">
                        팀 이름을<br/> 넣을 수 있습니다
                    </p>
                    <p className="template2Text">
                        팀 로고를<br/> 넣을 수 있습니다
                    </p>
                    <p className="template3Text">
                        대회 이름을<br/> 바꿀 수 있습니다
                    </p>
                    <img className="arrow1" src="https://overlap.s3-ap-northeast-1.amazonaws.com/front/img/templateArrow1.png"/>
                    <img className="arrow2" src="https://overlap.s3-ap-northeast-1.amazonaws.com/front/img/templateArrow2.png"/>
                    <img className="arrow3" src="https://overlap.s3-ap-northeast-1.amazonaws.com/front/img/templateArrow3.png"/>
                </section>
               
                 
                <section id="templateLIST" className="relative">
                    <img className="templateLISTback" src="https://overlap.s3-ap-northeast-1.amazonaws.com/front/img/templateback.png"/>
                    <div className="infoText">
                        <h3>다양한 용도의 템플릿이 <br/>준비되어 있습니다.</h3>
                        <p>미리 만들어진 템플릿을 통해서 <br/>빠르게 행사를 준비 할 수 있습니다.</p>
                    </div>
                </section>
                <section id="gameTemplate" className=" standardArea">
                        <div className="relative templateArea">
                            <div className="infoText">
                            <h3>게임 대회용 템플릿</h3>
                            <p>오버워치와 리그오브레전드 대회를 <br/>열 수 있는 템플릿 입니다.</p>
                            </div>
                            <img className="game_match" src="https://overlap.s3-ap-northeast-1.amazonaws.com/front/img/game_match.png"/>
                            <img className="game_team" src="https://overlap.s3-ap-northeast-1.amazonaws.com/front/img/game_team.png"/>
                            <img className="game_ready" src="https://overlap.s3-ap-northeast-1.amazonaws.com/front/img/game_ready.png"/>
                            <video className="game_scoreboard" src="https://overlap.s3-ap-northeast-1.amazonaws.com/front/img/game_scoreboard.mp4" autoplay="autoplay" loop muted="muted"></video>
                            <div className="infoText1">
                            <h3>오늘의경기 <br/> 참여팀소개<br/> 경기대기실<br/> 스코어보드</h3>
                            <p>다양한 상황에서 사용 할 수 있도록 준비되어 있습니다.</p>
                            </div>
                        </div>
                </section>
                {/* <section id="gameTemplate" className=" standardArea">
                        <div className="relative templateArea">
                            <div className="infoText">
                            <h3>행사용 템플릿</h3>
                            <p>다양한 행사를 준비 할 수 있는 탬플릿입니다.</p>
                            </div>
                            <div className="game_match gray"></div>
                            <div className="game_team gray"></div>
                            <div className="game_ready gray"></div>
                            <div className="game_scoreboard gray"></div>
                            <div className="infoText1">
                            <h3>종교 행사 <br/>결혼식 <br/>오리엔테이션<br/> </h3>
                            <p>여러 행사에 맞춤형 템플릿이 준비되어 있습니다.</p>
                            </div>
                        </div>
                </section> */}
                {/* <section id="gameTemplate" className=" standardArea">
                        <div className="relative templateArea">
                            <div className="infoText">
                            <h3>게임 대회용 템플릿</h3>
                            <p>오버워치와 리그오브레전드 대회를 <br/>열 수 있는 템플릿 입니다.</p>
                            </div>
                            <img className="game_match" src="https://overlap.s3-ap-northeast-1.amazonaws.com/front/img/game_match.png"/>
                            <img className="game_team" src="https://overlap.s3-ap-northeast-1.amazonaws.com/front/img/game_team.png"/>
                            <img className="game_ready" src="https://overlap.s3-ap-northeast-1.amazonaws.com/front/img/game_ready.png"/>
                            <video className="game_scoreboard" src="https://overlap.s3-ap-northeast-1.amazonaws.com/front/img/game_scoreboard.mp4" autoplay="autoplay" loop muted="muted"></video>
                            <div className="infoText1">
                            <h3>오늘의경기 <br/> 참여팀소개<br/> 경기대기실<br/> 스코어보드</h3>
                            <p>다양한 상황에서 사용 할 수 있도록 준비되어 있습니다.</p>
                            </div>
                        </div>
                </section> */}
            </div>
        );
    }
}
////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    passToken : state.passToken,
})

function mapDispatchToProps(dispatch){
    return {
    //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(FrontTemplate);
  