// 서비스 연결
import * as Utility from 'service/other/Utility' ; 

function hexToRGB(hex) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return [r, g, b];
  }
  
  function darkenRGB([r, g, b], percentage) {
    const factor = (100 - percentage) / 100;
    const darkened = [r, g, b].map(value => Math.floor(value * factor));
    return `rgb(${darkened[0]}, ${darkened[1]}, ${darkened[2]})`;
  }
  
  function darkenHex(hex, percentage) {
    const rgb = hexToRGB(hex);
    return darkenRGB(rgb, percentage);
  }
  function calculateFontSizeByHeight(elementHeight, minFontSize = 10, maxFontSize = 100) {
  let fontSize = elementHeight * 0.2; // 높이의 20%를 폰트 크기로 설정
  fontSize = Math.max(minFontSize, Math.min(fontSize, maxFontSize)); // 폰트 크기 제한
  return fontSize;
}

function preset(preset,self,data,style){

    let left = Object.assign(style.left,style.team)
    let right = Object.assign(style.right,style.team)

    const TeamData = data.value.teamData
    const LeftName= TeamData?.[0]?.teamName??'TEAM';
    const RightName= TeamData?.[1]?.teamName??'TEAM';
    
    const boxHeight = (Number(data?.size?.height));
    const boxwidth = (Number(data?.size?.width));




    console.log('TeamData',TeamData)

    switch (preset) {
        case 'default':
            return(
            <div className="ScoreBoard defaultScoreBoard" ref={self.widget} style={{...style.object}}>
           
                <div className="leftTeam teamInfo" style={{background:style?.teamStyle[0]?.background??"#59CBE8",color:style?.teamStyle[0]?.color??"#ffffff",...style.team}}>
                    <div className="teamName" style={style.team}>
                        {LeftName}
                    </div>
                    {TeamData?.[0]?.teamLogo?
                    <div className="teamLogo">
                        <img src={TeamData?.[0]?.teamLogo} />
                    </div>
                    : null}
                    <div className="teamScroe" style={style.team}>
                        {TeamData?.[0]?.teamScore??0}
                    </div>
                </div>
                {data.value.matchInfo?
                <div className="matchInfo"> 
                    <pre style={style.info} >{data.value.matchInfo}</pre>
                </div>
                :null}
                <div className="rightTeam teamInfo" style={{background:style?.teamStyle[1]?.background??"#F77397",color:style?.teamStyle[1]?.color??"#ffffff",...style.team}}>
                    <div className="teamName" style={style.team}>
                        {RightName}
                    </div>
                    {TeamData?.[1]?.teamLogo?
                    <div className="teamLogo">
                        <img src={TeamData?.[1]?.teamLogo} />
                    </div>
                         : null}
                    <div className="teamScroe" style={style.team}>
                        {TeamData?.[1]?.teamScore??0}
                    </div>
                </div>
            </div>
        )

        case 'tilted':
            return(
            <div className="ScoreBoard tiltedScoreBoard" ref={self.widget} style={{...style.object}}>
           
                <div className="leftTeam teamInfo">
                    <div className="teamName" style={{background:style?.teamStyle[0]?.background??"#59CBE8",color:style?.teamStyle[0]?.color??"#ffffff",transform:  (TeamData?.[0]?.teamLogo?'translateX(-250px)':'translateX(-100px)'),...style.team}}>
                        {LeftName}
                    </div>
                    {TeamData?.[0]?.teamLogo?
                    <div className="teamLogo" style={{background:darkenHex(style?.teamStyle[0]?.background??"#59CBE8",100)}}>
                        <img src={TeamData?.[0]?.teamLogo} />
                    </div>
                    : null}
                    <div className="teamScroe" style={style.team}>
                        {TeamData?.[0]?.teamScore??0}
                    </div>
                </div>
                <pre className="matchInfo" style={style.info}> 
                    {data.value.matchInfo}
                </pre>
                <div className="rightTeam teamInfo">
                    <div className="teamName" style={{background:style?.teamStyle[1]?.background??"#F77397",color:style?.teamStyle[1]?.color??"#ffffff",transform:  (TeamData?.[1]?.teamLogo?'translateX(250px)':'translateX(100px)'), ...style.team}}>
                        {RightName}
                    </div>
                    {TeamData?.[1]?.teamLogo?
                    <div className="teamLogo"style={{background:darkenHex(style?.teamStyle[1]?.background??"#F77397",100)}}>
                        <img src={TeamData?.[1]?.teamLogo} />
                    </div>
                         : null}
                    <div className="teamScroe" style={style.team}>
                        {TeamData?.[1]?.teamScore??0}
                    </div>
                </div>
            </div>
        )

        case 'boxStyleN':

      
            // const teamNameSize = boxwidth - (boxHeight/10) * 3 - (boxHeight/10) * 3 ;
            const boxStyleNteamScoreSize = ((boxHeight/10) * 3 - 20)
            console.log('프리셋 체크',style)
            return(
            <div className="ScoreBoard boxStyleNScoreBoard"  ref={self.widget} style={{...style.object}}>
                <div className="matchInfo" style={{...style.info,height:(boxHeight/10) * 3}}>
                    <span>{data.value.matchInfo}</span>
                </div>
                <div className="teamArea" style={{...style.info,height:(boxHeight/10) * 6}}>
                    <div className="leftTeam teamInfo" style={{background:style?.teamStyle[0]?.background??"#59CBE8",height:(boxHeight/10) * 3}}>
                        {TeamData?.[0]?.teamLogo?
                        <div className="teamLogo" style={{background:darkenHex(style?.teamStyle[0]?.background??"#59CBE8",10),height:(boxHeight/10) * 3, width : (boxHeight/10) * 3}}>
                            <img src={TeamData?.[0]?.teamLogo} />
                        </div>
                        : null}
                        <div className="teamName" style={{color:style?.teamStyle[0]?.color??"#ffffff",...style.team,height:(boxHeight/10) * 3,fontSize :Utility.fontCalculator((boxHeight/10) * 3),lineHeight:(boxHeight/10) * 3+'px'}}>
                            <span>{LeftName}</span>
                        </div>
                        <div className="teamScroe" style={{...style.team,color:'black',margin:10,height:boxStyleNteamScoreSize, width : boxStyleNteamScoreSize,fontSize :Utility.fontCalculator(boxStyleNteamScoreSize),lineHeight:(boxHeight/10) * 3+'px'}}>
                            <span>{TeamData?.[0]?.teamScore??0}</span>
                        </div>
                    </div>
                    <div className="rightTeam teamInfo" style={{background:style?.teamStyle[1]?.background??"#F77397",height:(boxHeight/10) * 3}}>
                    {TeamData?.[1]?.teamLogo?
                        <div className="teamLogo" style={{background:darkenHex(style?.teamStyle[0]?.background??"#59CBE8",10),height:(boxHeight/10) * 3, width : (boxHeight/10) * 3}}>
                            <img src={TeamData?.[1]?.teamLogo} />
                        </div>
                        : null}
                        <div className="teamName" style={{color:style?.teamStyle[1]?.color??"#ffffff",...style.team,height:(boxHeight/10) * 3,fontSize :Utility.fontCalculator((boxHeight/10) * 3),lineHeight:(boxHeight/10) * 3+'px'}}>
                            <span>{RightName}</span>
                        </div>
                        <div className="teamScroe" style={{...style.team,color:'black',margin:10,height:boxStyleNteamScoreSize, width : boxStyleNteamScoreSize,fontSize :Utility.fontCalculator(boxStyleNteamScoreSize),lineHeight:(boxHeight/10) * 3+'px'}}>
                            <span>{TeamData?.[1]?.teamScore??0}</span>
                        </div>
                    </div>
                </div>
            </div>
        )

        case 'sports':

            return(
            <div className="ScoreBoard sportsScoreBoard"  ref={self.widget} style={{...style.object}}>
                <div className="ScoreBox">
                    <div className="leftTeam teamInfo" style={{background:style?.teamStyle[0]?.background??"#59CBE8",color:style?.teamStyle[0]?.color??"#ffffff",...style.team}}>
                        <div className="teamName LeftName" style={{height:boxHeight,fontSize :Utility.fontCalculator(boxHeight)}} ><span>{LeftName}</span></div>
                        <div className="teamScroe" style={{width:boxHeight,height:boxHeight,fontSize :Utility.fontCalculator(boxHeight)}} ><span>{TeamData?.[0]?.teamScore??0}</span></div>
                    </div>
                    <div className="rightTeam teamInfo" style={{background:style?.teamStyle[1]?.background??"#F77397",color:style?.teamStyle[1]?.color??"#ffffff",...style.team}}>
                        <div className="teamScroe" style={{width:boxHeight,height:boxHeight,fontSize :Utility.fontCalculator(boxHeight)}} ><span>{TeamData?.[1]?.teamScore??0}</span></div>
                        <div className="teamName RightName" style={{ height:boxHeight,fontSize :Utility.fontCalculator(boxHeight)}} ><span>{RightName}</span></div>
                    </div>
                </div>
            </div>
        )

        case 'sportsWithInfo':

        return(
        <div className="ScoreBoard sportsScoreBoard"  ref={self.widget} style={{...style.object}}>
            <div className="ScoreBox">
                <div className="leftTeam teamInfo" style={{background:style?.teamStyle[0]?.background??"#59CBE8",color:style?.teamStyle[0]?.color??"#ffffff",...style.team}}>
                    <div className="teamName LeftName" style={{height:boxHeight,fontSize :Utility.fontCalculator(boxHeight)}} ><span>{LeftName}</span></div>
                    <div className="teamScroe" style={{width:boxHeight,height:boxHeight,fontSize :Utility.fontCalculator(boxHeight)}} ><span>{TeamData?.[0]?.teamScore??0}</span></div>
                </div>
                <div className="rightTeam teamInfo" style={{background:style?.teamStyle[1]?.background??"#F77397",color:style?.teamStyle[1]?.color??"#ffffff",...style.team}}>
                    <div className="teamScroe" style={{width:boxHeight,height:boxHeight,fontSize :Utility.fontCalculator(boxHeight)}} ><span>{TeamData?.[1]?.teamScore??0}</span></div>
                    <div className="teamName RightName" style={{ height:boxHeight,fontSize :Utility.fontCalculator(boxHeight)}} ><span>{RightName}</span></div>
                </div>
            </div>
        </div>
    )
        
       
        default:
            return(
            <div className={"object unknownPreset"} ref={self.widget} style={{...style.object}} >
                <p>알수없는 프리셋 {preset} </p>
            </div>
        )
    }
}
// export function data(data,style,self) {
//    return preset(data.preset,data,style,self)
// }

export function data(self,style) {
    return preset(self.props.data.preset,self,self.props.data,style)
}

