////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widget.scss"

// [프로바이더] 연결
import EditContext from 'service/context/EditContext' ; 

// 컴포넌트 연결
import WidgetOverRay from '../Edit/editController/WidgetOverRay'; // 메뉴 헤더

// 서비스 연결
import * as Preset from 'components/widget/preset/TimerPreset';
import * as Event from '../../service/event/Actions' ; 
import * as Widget from 'service/edit/WidgetService' ; 
import * as Model from '../../service/value/Model' ; 
import * as EnCoder from '../../service/engine/EnCoder' ; 
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class TimerV2 extends Component {
    
    static defaultProps = {
        widgetName: 'Timer',
      }


    //[임시] 컨텍스트 연결
    static contextType = EditContext;

    state = {
        polling : 0,
    }

    constructor(props) {
        super(props);
        this.widget = React.createRef();
        this.canvas = React.createRef();
        this.animation = null;
        this.ctx = null;
        this.PlayTime = 0;
        

        this.last = 99999999999999999;
        this.start = 0;
    }


    componentDidMount() {
        // -- 캔버스를 생성합니다.
        this.animation = Preset.canvasInit({current:this.canvas.current,ctx:this.ctx,setAnimationID:this.setAnimationID,data:this.props.data,cache:this.getCache,start:this.state,last:this.last,self:this})
    }

    getCache = () => {

        if(this.context){
            const Cache = this.context.getChannelCache({'alias':this.props.alias,'type':this.props.type,'uuid':this.props.uuid})
            return Cache;
        }
    }


    componentDidUpdate(prevProps) {
        cancelAnimationFrame(this.animation)
        // console.log('채널 캐쉬',this.context.channelCache)
        // 새로운 애니메이션을 시작합니다.
        this.animation = Preset.canvasInit({current:this.canvas.current,ctx:this.ctx,setAnimationID:this.setAnimationID,data:this.props.data,cache:this.getCache,start:this.state,last:this.last,self:this})
    }

    setAnimationID = (id) => {
        cancelAnimationFrame(this.animation)
        this.animation = id;
    }
      
    liveTick = ()=>{
        // const WidgetTime = this.props.data.
        // const SetTime = Number(data?.value?.time)??600;
        console.log('channelCache',this.context?.channelCache)

    }

    componentWillUnmount(){
        clearInterval(this.IntervalID);
        cancelAnimationFrame(this.animation)
    }

    render() {

        const Style = this.props.getStyle(this.props.data)

        const Height =this.props.data?.size?.height;
        const Width = this.props.data?.size?.width;
        const MinSize = Width<Height?Width:Height;
        const Animation = this.props.getAnimation(this.props.animation,this.props.data)

        // 드레그를 통한 위젯 이동

        switch (this.props.parents) {

            case 'edit':
                // 편집시 화면이동 트렌스레이션 효과 방지
                Style.layout.transition = false
                Style.object.transition = false
                return (         
                    <div className = {this.props.parents + " widgetObject "+this.props.widgetName+Widget.activate(this.props.data)} style={{...Style.layout,...Animation.layout}} >
                        {Preset.data(this,Style)}
                    </div>
                );

            case 'broadcast':
                return (
                    <div className = {this.props.parents + " widgetObject "+this.props.widgetName+Widget.activate(this.props.data)} style={{...Style.layout,...Animation.layout}}>
                        {Preset.data(this,Style)}
                    </div>
                );
          
            case 'view':
                return (
                    <div className = {this.props.parents + " widgetObject "+this.props.widgetName+Widget.activate(this.props.data)} style={{...Style.layout,...Animation.layout}}>
                        {Preset.data(this,Style)}
                    </div>
                );
        }

    }
}
////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    userState : state.userState,
})

function mapDispatchToProps(dispatch){
    return {
        //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}


  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(TimerV2);
  