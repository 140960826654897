////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { useState, useEffect, useContext, Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widgetEdit.scss"


// 컴포넌트 연결
import GuideBalloon from 'components/common/form/GuideBalloon'// 가이드 벌룬
// import Show from '../show/Show'; // 메뉴 헤더

// 서비스 연결
import * as Event from '../../../service/event/Actions' ; 
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
// value={1} model="ms" title="시간" type ="startTime" 

/** [에딧 모듈] 시간을 지정하고 편집하는 툴입니다.
 * (대상위젯, 편집펑션, 편집할 객체 키, 타이틀 ,옵션)
* @param {number|string} value : 편집할 대상 위젯
* @param {string} title : 해당 목록의 제목입니다. 
* @param {function} change : 편집용 함수
* @param {*}option : 추가 옵션입니다.
    */
    

function TimeModule({value,title,type,change,option}) {


        ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////

    // set

    // state
    const [getTime, setTime] = useState(Number(value)??null);
    const [hours, setHours] = useState(Number(value)??null);
    const [minutes, setMinutes] = useState(Number(value)??null);
    const [seconds, setSeconds] = useState(Number(value)??null);

    // rel 

    ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////
    useEffect(() => {

        return () => {
        // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
        };
      }, []);


      useEffect(() => {
        const elapsedSeconds = Number(value)
        setHours(Math.floor(elapsedSeconds / 3600)) 
        setMinutes(Math.floor((elapsedSeconds % 3600) / 60))
        setSeconds(elapsedSeconds % 60)
        return () => {
        // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
        };
      }, [value]);

      
      useEffect(() => {

        return () => {
        // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
        };
      }, [hours,minutes,seconds]);


    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////


    const timeChange = (unit, value) => {


        let modifyTime =  (hours??0 * 3600) + (minutes??0 * 60) + seconds??0 ; 

        switch (unit) {
            case 'hours':
                modifyTime =  (value * 3600) + (minutes * 60) + seconds ; 
                break;
            case 'minutes':
                modifyTime =  (hours * 3600) + (value * 60) + seconds; 
                break;
            case 'seconds':
                modifyTime =  (hours * 3600) + (minutes * 60) + value ; 
                break;        
            default:
                break;
        }

        console.log('modifyTime',unit, value, hours, minutes, seconds);
        change(type,modifyTime)
    }

    return(
        <Fragment>
        <style jsx>{`${ModuleStyle}`}</style>
        <div className="EditModule TimeModule TimeModuleV2"> 
            {title?<div className="TimeModuleTitle">
            {title}
            </div>
            :null}
            <div className="TimeModuleOption">
                <GuideBalloon guideText={<p> 시간 </p>} width={30} direction='top'>
                    <div className = "timeInput"> 
                    <input type="number" name="hrs" min='0' max='24' placeholder='00' value={hours>=10?hours:'0'+hours} onBlur={(e) => timeChange('hours',Number(e.target.value)>24? 24: Number(e.target.value))} onChange={(e) => timeChange('hours',Number(e.target.value))}/><span>:</span>
                    </div>
                </GuideBalloon>
                <GuideBalloon guideText={<p> 분 </p>} width={30} direction='top'>
                    <div className = "timeInput">
                    <input type="number" name="min" min='0' max='60' placeholder='00' value={minutes>=10?minutes:'0'+minutes} onBlur={(e) => timeChange('minutes',Number(e.target.value))} onChange={(e) => timeChange('minutes',Number(e.target.value))}/><span>:</span>
                    </div>
                </GuideBalloon>
                <GuideBalloon guideText={<p> 초 </p>} width={30} direction='top'>
                    <div className = "timeInput">
                        <input type="number" name="sec" min='0' max='60' placeholder='00' value={seconds>=10?seconds:'0'+seconds} onBlur={(e) => timeChange('seconds',Number(e.target.value))} onChange={(e) => timeChange('seconds',Number(e.target.value))}/> 
                     </div>
                </GuideBalloon>
            </div>
        </div>
        </Fragment>
    )

}
export default TimeModule;

////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////

const ModuleStyle = `

.TimeModule{

}
.TimeModule .TimeModuleOption{
    display : flex;
}
.TimeModule .timeInput input{
    width : 30px;
    text-align: center;
    font-family: "프리텐다드SemiBold", sans-serif;
}
.TimeModule input {
    background-color: #444;
    padding: 2px 11px;
    border-radius: 2px;
    color: #000;
    color: #fff;
    border: 1px solid #444;
}

/* Chrome, Safari, Edge, Opera */
.TimeModule input::-webkit-outer-spin-button,
.TimeModule input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.TimeModule input[type=number] {
  -moz-appearance: textfield;
}
`;

