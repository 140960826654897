////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component, Fragment , Profiler, createContext} from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { Route, Link } from 'react-router-dom';
import _ from 'lodash';
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/edit.scss"


// 컴포넌트 연결

// 서비스 연결

import * as Event from 'service/event/Actions' ; 
import * as Utility from'service/other/Utility.js' ; 
import * as viewAPI from 'service/api/view' ; 
import * as pageAPI from 'service/api/page' ; 
import { token } from 'service/event/Account'; 
import EditContext from 'service/context/EditContext';
import { widget } from 'service/value/Model';
import { object } from 'service/event/template';

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

class ViewProvider extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        sidebar : 'Screen',
        view : null,
        pageList : [],
        loading : true,
        leave : false,
        pageData : [],
        channelCache : {},
        getChannelCache : this.getChannelCache
    };
      this.viewSocket  = viewAPI.start();
      this.pageSocket  = pageAPI.start();
    }

    async componentDidMount()  {

        // this.joinView(this.props.uuid)

        this.viewSocket.on('ready_view', () => {
            this.viewSocket.emit('request_view', {
                uuid : this.props.uuid,
                accountToken : token('account'), 
            });
        });

        this.viewSocket.on('receive_view', (viewData) => {
            console.log('갱신됨 : ',viewData,this.props.uuid);
            this.setState({
                view:viewData,
            })
        });

        this.viewSocket.on('receive_channelCache', (cache) =>  {
            this.setState({
                channelCache: cache,
            });
        });

        this.viewSocket.on('connect', (token) => {
            console.log(`📡 소켓 : View 소켓이 연결되었습니다, 연결된 뷰 : ${this.props.uuid} `);
            this.joinView(this.props.uuid)
        });

        this.pageSocket.on('ready_page', (uuid) => {
            console.log('[소켓 페이지] 페이지 시작',uuid)
            this.pageSocket.emit('request_page', {
                uuid : uuid,
                accountToken : token('account'), 
            }); 
        });

        this.pageSocket.on('response_page', (page) => {
            console.log('[소켓 페이지] response_page',page);
            // console.log('[소켓 페이지] 📩 서버로 부터 요청한 페이지를 받습니다.',page)
            this.updatePage(page)
        });


        this.pageSocket.on('refresh_page', (page) => {
            console.log('[소켓 페이지] refresh_page',page);

            // console.log('[소켓 페이지] : 📩 서버로 부터 갱신된 페이지를 받습니다. ',page);
            this.updatePage(page)
        });

        this.pageSocket.on('disconnect', (e) => {
            console.log('[소켓 페이지] : page 소켓 연결 중단',e);
            this.pageReloadControl(this.viewPointer(this.state?.view?.view_pointer))
          });

          this.pageSocket.on('connect', (e) => {
            console.log('[소켓 페이지] : page 소켓 연결 시작',e);
          });


    }

    componentDidUpdate(prevProps, prevState) {
        const prevList = this.viewPointer(prevState?.view?.view_pointer);
        const nextList = this.viewPointer(this.state?.view?.view_pointer);

        if (_.isEqual(prevList, nextList) == false) {
            this.pageDynamicControl(prevList, nextList);
        }
      }

    ////////////////////////////////////////////////////////
    // 소켓 연결 부분
    ////////////////////////////////////////////////////////

    /** joinView | View 소켓에 접속합니다.
     * @param {string} uuid 접속할 뷰의 아이디
     * @returns 
     *  */ 
    joinView = (uuid) => {
        this.viewSocket.emit('join', {
            uuid : uuid,
            accountToken : token('account'), 
            deviceID : null,
        });
    }
     /** leaveView | View 소켓에서 나갑니다.
     * @param {string} uuid 접속할 뷰의 아이디
     * @returns 
     *  */ 
    leaveView = (uuid) => {
        this.viewSocket.emit('leave', uuid);
    }
     /** updatePage | Page 소켓에서 페이지를 받아 뷰어에 적용시킵니다.
     * @param {object} page 오버랩의 페이지 객체
     * @returns 
     *  */ 
    updatePage = (page) =>{
        const pageUUID = page.uuid; 
        const prevPageData = this.state.pageData; 
        const viewPointer = this.state?.view?.view_pointer?.map(point => point.uuid);
        const nextPageData = prevPageData.filter(page => viewPointer.includes(page.uuid)&&pageUUID!=page.uuid);
        nextPageData.push(page)

        console.log('[소켓 페이지] viewPointer :',page)

        this.setState({
            pageData : nextPageData
        })
    }

    /** viewPointer | View 소켓에서 나옵니다..
     * @param {string} uuid 접속할 뷰의 아이디
     * @returns 
     *  */ 
    viewPointer = (pointerList) => {
        if(typeof(pointerList) == 'object'){
            const sortedPage = pointerList.sort((a, b) => a.order - b.order).map(item => item.uuid);
            return sortedPage;
        }else{
            return null;
        }
    }

    /** pageDynamicControl | 페이지 리스트가 변경되는 경우 
     * @param {string} uuid 접속할 뷰의 아이디
     * @returns 
     *  */ 
    pageDynamicControl = (prevPage,nextPage) =>{

        prevPage = prevPage??[];
        nextPage = nextPage??[];

        const includePage = nextPage.filter(page => !prevPage.includes(page));
        const removePage = prevPage.filter(page => !nextPage.includes(page));

        console.log('includePage',includePage)
        console.log('removePage',removePage)
        for (let index in includePage) {
            console.log('includePage',includePage[index]);
            this.includePage(includePage[index])
        }
        for (let index in removePage) {
            console.log('removePage',removePage[index]);
            this.removePage(removePage[index])
        }
        
    }

    pageReloadControl = (includePage) =>{
        for (let index in includePage) {
            console.log('includePage',includePage[index]);
            this.includePage(includePage[index])
        }
  
    }



    ////////////////////////////////////////////////////////
    // 채널캐쉬 관련 (channelCache)
    ////////////////////////////////////////////////////////

    // setChannelCache = (modifiedData,uuid) =>{

    //     let modifyCache =  Utility.deepCopy(this.state.channelCache);

    //     modifyCache[uuid] = modifiedData;
        
    //     this.setState({
    //         channelCache : modifyCache
    //     },(()=>console.log('수정됨 캐쉬 :',this.state.channelCache)))
        
    // }

    getChannelCache = (target) => {
        const identifier =  (target.alias?`alias_${target.type}_${target.alias}`:`uuid_${target.type}_${target.uuid}`);
        const Cache = this.state.channelCache?.[identifier]  
        if(Cache){
            return Cache;
        }else {
            return {}; 
        }
    }
    

    /** includePage | Page 소켓에 접속합니다.
     * @param {string} uuid 접속할 뷰의 아이디
     * @returns 
     *  */ 
    includePage = (pageUUID) => {
        this.pageSocket.emit('join', {
            uuid : pageUUID,
            accountToken : token('account'), 
            deviceID : null,
        });
    }

    /** removePage | Page 소켓에서 나갑니다.
     * @param {string} pageUUID 접속할 뷰의 아이디
     * @returns 
     *  */ 
    removePage = (pageUUID) => {
        console.log('')
        this.pageSocket.emit('leave', pageUUID);
    }

    componentWillUnmount() {
        // 방을 나갑니다.
        console.log('소켓을 종료합니다.')
        this.leaveView(this.props.uuid)
        this.viewSocket.disconnect('unmonut');
        this.pageSocket.disconnect('unmonut');
    }
  
    render() {
        console.log('체크',this.props.children)
      return (
        <EditContext.Provider value={this.state}> 
          {this.state.loading ? this.props.children :<div>로딩입니다.</div>}
        </EditContext.Provider>
      );
    }
  }

////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////

    // export default Login;
    export default ViewProvider;