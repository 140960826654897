////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/edit.scss"

// 이미지 연결
// import videoIcon from 'assets/video.svg';
// import imageIcon from 'assets/image.svg';
// import timerIcon from 'assets/timer.svg';

// 서비스 연결
import * as Modal from '../../../service/event/ModalControl';
import * as Template from '../../../service/event/template';
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class EditBackgroundSelect extends Component {
    
    state = {
        show : true,
        select : false,
        target : null,
        cameraList:null,
        device : null,
        deviceRefresh: true,
        color : 'white',
    }

    constructor(props) {
        super(props);
        this.videoRef = React.createRef();
      }
    

    backgroundChange = (mode,source) =>{
        localStorage.setItem('EditBackground',JSON.stringify({mode,source}));
        Modal.OverlayRemove('EditBackground_'+this.props.background)
        this.props.editBackgroundDispatch(mode,source)
    }

    backgroundMode = () => {
        switch (this.props.background) {
            case 'solid':
                return(
                    <Fragment>
                    <h3 className="editBGTitle"> 편집화면 배경 변경 </h3>
                    <div className="modalContent">   
                        <div className="viewSide">  
                            <div className={"preview "+this.state.color}/>
                        </div>
                        <div  className="selectSide"> 
                        <ul>
                            <li> 
                                <p>1. 원하는 배경을 선택합니다.</p>
                                <div className="colorlist">
                                    <div className={"colorSet white "+(this.state.color=='white'?'colorSelect':'')} onClick={()=>this.setState({ color : 'white'})}/>
                                    <div className={"colorSet opacify "+(this.state.color=='opacify'?'colorSelect':'')} onClick={()=>this.setState({ color : 'opacify'})}/>
                                    <div className={"colorSet black "+(this.state.color=='black'?'colorSelect':'')} onClick={()=>this.setState({ color : 'black'})}/>
                                    <div className={"colorSet green "+(this.state.color=='green'?'colorSelect':'')} onClick={()=>this.setState({ color : 'green'})}/>
                                </div>
                            </li>
                            <li> 
                                <p>2. 원하는 배경이 선택되었으면 <br/>선택 버튼을 누릅니다.</p>
                            </li>
                        </ul>
                        </div>
                    </div>
                    <div className="modalsubmit"> 
                    <input type="button" value="취소" onClick={()=>Modal.OverlayRemove('EditBackground_'+this.props.background)}/>
                    <input type="button" value="선택" onClick={()=>this.backgroundChange('solid',this.state.color)}/>
                    </div>
                    </Fragment>
                )
            case 'broadcasting':
                return(
                    <Fragment>
                    <h3 className="editBGTitle"> 편집화면 배경 변경 </h3>
                    <div className="modalContent">  
                        <div className="viewSide">  
                    
                        </div>
                        <div  className="selectSide">  

                        </div>
                    </div>
                    <div className="modalsubmit"> 
                    <input type="button" value="취소" onClick={()=>Modal.OverlayRemove('EditBackground_'+this.props.background)}/>
                    <input type="button" value="선택" onClick={()=>this.backgroundChange('solid',this.state.color)}/>
                    </div>
                    </Fragment>
                )
            case 'capture':
                let deviceList =null
                let permission = true
                if(this.state.cameraList){
                    deviceList = this.state.cameraList.map((list) => (<option key={list.deviceId} value={list.label}>{list.label}</option>));
                    let myDevice = this.deviceSearch(this.state.device)
                    this.startVideo(myDevice)
                    if(this.state.cameraList[0].deviceId == ''){
                        permission = false
                    }  
                }
                return(
                    <Fragment>
                    <h3 className="editBGTitle"> 편집화면 배경 변경 </h3>
                    <div className="modalContent">  
                        <div className="viewSide">  
                            {this.captureObject()}
                        </div>
                        <div  className="selectSide"> 
                        <ul>
                            <li> 
                                <p>1. 카메라 권한을 승인해주세요.</p>
                            </li>
                            <li> 
                                <p>2. 배경으로 사용할 캡쳐보드를 선택합니다.</p>
                            <form>
                                {this.state.cameraList&&permission?
                                    <select onChange={(event)=>this.setState({device : event.target.value,deviceRefresh : true})}>
                                    {deviceList}
                                    </select>
                                :
                                   <p className="warning">사용할 수 있는 하드웨어가 없습니다.</p> 
                                }
                            </form>
                            </li>
                            <li> 
                                <p>3. 원하는 배경이 선택되었으면 <br/>완료 버튼을 누릅니다.</p>
                            </li>
                        </ul>
                        </div>
                    </div>
                    <div className="modalsubmit"> 
                    <input type="button" value="취소" onClick={()=>Modal.OverlayRemove('EditBackground_'+this.props.background)}/>
                    {this.state.cameraList&&permission?
                    <input type="button" value="선택" onClick={()=>this.backgroundChange('capture',this.state.device)}/>
                    :''}
                    </div>
                    </Fragment>
                )
            case 'obs':
                let obs =null
                let label = null
                let obspermission = true    
                if(this.state.cameraList){
                    if(this.state.cameraList[0].deviceId == ''){
                        obspermission = false
                    }  
                    obs = this.state.cameraList.filter(list => list.label.includes('OBS Virtual Camera'))
                    try{
                        this.startVideo(obs[0].deviceId)
                        label =obs[0].label
                    }
                    catch{

                    }
                }
                return(
                    <Fragment>
                    <h3 className="editBGTitle"> 편집화면 배경 변경 </h3>
                    <div className="modalContent">      
                        <div className="viewSide">  
                            {this.captureObject()}
                        </div>
                        <div  className="selectSide"> 
                        <ul>
                            <li> 
                                <p>1. 카메라 권한을 승인해주세요.</p>
                            </li>
                            <li> 
                                <p>2. OBS 프로그램을 실행합니다. </p>
                            </li>
                            <li> 
                                <p>3. OBS 프로그램에서 가상카메라를 켭니다.</p>
                            </li>
                            <li> 
                                <p>4. OBS 프로그램에서 선택 버튼을 누릅니다.</p>
                            </li>
                        </ul>
                        </div>
                    </div>
                    <div className="modalsubmit"> 
                    <input type="button" value="취소" onClick={()=>Modal.OverlayRemove('EditBackground_'+this.props.background)}/>
                    {this.state.cameraList&&obspermission?
                    <input type="button" value="선택" onClick={()=>this.backgroundChange('obs',label)}/>:''}
                    </div>
                    </Fragment>
                )
            case 'computer':
                // this.startCapture()
                return(
                    <Fragment>
                    <h3 className="editBGTitle"> 편집화면 배경 변경 </h3>
                    <div className="modalContent"> 
                        <div className="viewSide">  
                            {this.captureObject()}
                        </div>
                        <div  className="selectSide"> 
                        <ul>
                            <li> 
                                <p>1. 카메라 권한을 승인해주세요.</p>
                            </li>
                            <li> 
                                <p>2. 여기를 눌러 화면을 선택합니다. </p>
                                <input type="button" value={'화면캡쳐'} onClick={()=>this.startCapture()}/>
                                <input type="button" value={'종료'} onClick={()=>this.stopCapture()}/>
                            </li>
                            <li> 
                                <p>3. OBS 프로그램에서 가상카메라를 켭니다.</p>
                            </li>
                            <li> 
                                <p>4. OBS 프로그램에서 선택 버튼을 누릅니다.</p>
                            </li>
                        </ul>
                        </div>
                    </div>
                    <div className="modalsubmit"> 
                    <input type="button" value="취소" onClick={()=>Modal.OverlayRemove('EditBackground_'+this.props.background)}/>
                    {/* <input type="button" value="선택" onClick={()=>this.backgroundChange('solid',this.state.color)}/> */}
                    </div>
                    </Fragment>
                )
        default:
            break;
        }
   
    }



    captureObject = () => {
        if(this.state.deviceRefresh){
            setTimeout(this.setState({deviceRefresh : false}),1)
            return
        }else{
            return(
                <video autoPlay ref={this.videoRef} />
            )
        }

    }

    startCapture = async(options) => {
        let captureStream = null;
        this.stopCapture()
        try {
          captureStream = await navigator.mediaDevices.getDisplayMedia(options);
          if (this.videoRef && this.videoRef.current && !this.videoRef.current.srcObject) {
            this.videoRef.current.srcObject = captureStream;
            // this.setState({deviceRefresh : true})
        }       

        } catch(err) {
          console.error("Error: " + err);
        }
    }

     stopCapture  = () =>  {
        if (this.videoRef && this.videoRef.current && !this.videoRef.current.srcObject) {
        let videoElem = this.videoRef.current
        try{
            let tracks = videoElem.srcObject.getTracks();
      
            tracks.forEach(track => track.stop());
            videoElem.srcObject = null;
        } catch(err) {
            console.error("Error: " + err);
        }

        }
      }

    startVideo = async (device) => {
        if(!device){
            device = true
        }
        if (navigator.mediaDevices){
            const stream = await navigator.mediaDevices.getUserMedia({ video: { deviceId: device }});
            if (this.videoRef && this.videoRef.current && !this.videoRef.current.srcObject) {
                this.videoRef.current.srcObject = stream;
            }       
        }
    };

    deviceSearch = (devicelabel) => {
        if(this.state.cameraList){
            for (let index = 0; index < this.state.cameraList.length; index++) {
                if(this.state.cameraList[index].label == devicelabel)
                {
                   return this.state.cameraList[index].deviceId
                }
            }
        }
        return null
    }

    componentDidMount(){
        let cameraList = [] 
        let defaultDevice = null
        if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
            console.log("enumerateDevices() not supported.");
            return;
          }
          navigator.mediaDevices.enumerateDevices()
          .then((devices) => {
            for (let index = 0; index < devices.length; index++) {
                if (devices[index].kind == 'videoinput'){
                    cameraList.push({label:devices[index].label,deviceId:devices[index].deviceId})
                
                }
            }
            if (cameraList){
                defaultDevice = cameraList[0].label
            }
            this.setState({
                cameraList : cameraList,
                device :defaultDevice
            },)
          })
          .catch(function(err) {

          });
    }

    render() { 
        return (
            <div id = "EditBackgroundModalBG" onClick={()=>Modal.OverlayRemove('EditBackground_'+this.props.background)} >
                <div id = "EditBackgroundModal" onClick={(e)=>e.stopPropagation()} >
                    {this.backgroundMode()}
                </div>
            </div>
            
        );
    }
}
////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    screenData : state.screenData,
    editBackground : state.editBackground,
})

function mapDispatchToProps(dispatch){
    return {
        
        editBackgroundDispatch : (type,source) => dispatch({type:'editBackground',mode : type,source:source}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(EditBackgroundSelect);
  