////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widgetEdit.scss"


// 컴포넌트 연결
import ColorModule from '../editModule/ColorModule'; // 색상 모듈
import SwitchModule from '../editModule/SwitchModule'; // 색상 모듈
import SelectModule from '../editModule/SelectModule';

// 서비스 연결

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class SizeEdit extends Component {
    
    state = {
    }

    heroesChange = (name,value) =>{

        this.props.callBack('value',name,OVERWATCHELIST[value])
    }
    presetChange = (name,value) =>{
        this.props.callBack('preset',name,value)
    }
    change = (name,value) =>{
        this.props.callBack('style',name,value)
    }

    heroesList=()=>{
        // return ['dva','genji','doomfist','reinhardt','wrecking-ball','roadhog','lucio','reaper']
        return Object.keys(OVERWATCHELIST)
    }

    render() { 

        const Style = {
            label: {
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
            },
            textarea : {
                width: '100%',
                resize: 'none'
            }

        }
        return (
            <fieldset className="EditModuleField" style={Style.fieldset} >
                <legend style={Style.legend}> 스타일 </legend>
                    <SelectModule value={this.props.data.preset} list={['오버워치 기본','연블리스타일']}  title="가로 크기" type ="preset" change={this.presetChange}/>
                <legend style={Style.legend}> 영웅 </legend>
                    <SelectModule value={OVERWATCHELISTReturn[this.props.data.value.heroes]} list={this.heroesList()}  title="가로 크기" type ="heroes" change={this.heroesChange}/>
                    <SwitchModule value={this.props.data.style.useFill} title="배경사용" type ="useFill" change={this.change}/>
                    <ColorModule value={this.props.data.style.fill} type ="fill" title="배경색" change={this.change} />
            </fieldset>
        );
    }
}

////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////
const OVERWATCHELIST ={'없음': null,'디바': 'dva','겐지': 'genji','둠피스트': 'doomfist','라인하르트': 'reinhardt','레킹볼': 'wrecking-ball','로드호그': 'roadhog','루시우': 'lucio','리퍼': 'reaper','맥크리': 'mccree','메르시': 'mercy','메이': 'mei','모이라': 'moira','바스티온': 'bastion','바티스트': 'baptiste','브리기테': 'brigitte','솔저:76': 'soldier-76','솜브라': 'sombra','시그마': 'sigma','시메트라': 'symmetra','아나': 'ana','애쉬': 'ashe','에코': 'echo','오리사': 'orisa','위도우메이커': 'widowmaker','윈스턴': 'winston','자리야': 'zarya','정크랫': 'junkrat','젠야타': 'zenyatta','토르비욘': 'torbjorn','트레이서': 'tracer','파라': 'pharah','한조': 'hanzo'}

const OVERWATCHELISTReturn ={'dva': '디바','genji':'겐지','doomfist': '둠피스트','reinhardt' :'라인하르트' ,'wrecking-ball' :'레킹볼','roadhog':'로드호그','lucio':'루시우','reaper':'리퍼','mccree':'맥크리','mercy':'메르시','mei':'메이','moira':'모이라','bastion':'바스티온','baptiste':'바티스트','brigitte':'브리기테','soldier-76':'솔저:76','sombra':'솜브라','sigma':'시그마','symmetra':'시메트라','ana':'아나','ashe':'애쉬','echo':'에코','orisa':'오리사','widowmaker':'위도우메이커','winston':'윈스턴' ,'zarya':'자리야','junkrat':'정크랫','zenyatta':'젠야타','torbjorn':'토르비욘','tracer':'트레이서','pharah':'파라','hanzo':'한조'}

////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////

const mapStateToProps = (state) => ({
    // exemple : state.exemple,
})

function mapDispatchToProps(dispatch){
    return {
    //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(SizeEdit);
  