
////////////////////////////////////////////////////////
// Account 관련 펑션
////////////////////////////////////////////////////////

// import { Grade } from "@material-ui/icons";

// 이미지 연결
import obsIcon from 'assets/pages/lobby/space_guide_obs.png';
import logoW from 'assets/logoW.svg';
import Guide from 'pages/lobby/Guide';
////////////////////////////////////////////////////////
/** Token
 * @param authData 소켓 IO 를 통해서 오버랩을 체크를 합니다.
 * @returns 오버랩 IO 객체
 */
////////////////////////////////////////////////////////
export function token(key) {

    switch (key) {
        case 'account':
            return sessionStorage.getItem("JWT");
    
        default:
            return null;
    }

}

export function removeToken(key) {

    switch (key) {
        case 'account':
            return sessionStorage.removeItem("JWT");
    
        default:
            return null;
    }

}


export const viewGuide = [
    // {
    //     GuideTitle : '채널 알아보기',
    //     GuideText: '채널에 대해 소개해드려요',
    //     GuideIcon : null,
    //     IconColor : 'red',
    //     GuideLink : null,
    //     GuideImageComponet : <svg className="icon" width="30px" height="30px">
    //     <circle cx="15" cy="15" r="12" fill="red" stroke="#fff" strokeWidth="4.5" />
    // </svg>,
    // },
    {
        GuideTitle : 'OBS 연결하기',
        GuideText: '여기를 눌러서 OBS와 연결하세요',
        GuideIcon : null,
        IconColor : null,
        GuideLink : {lobby:'Guide',lobbyState:'linkOBS'},
        GuideImageComponet : <img style={{width:'70px',height:'70px'}} src={obsIcon}/>,
    },

]

export const overlapGuide = [
    {
        GuideTitle : '오버랩 프로젝트 알아보기',
        GuideText: '오버랩이 처음이신가요 ?',
        GuideIcon : null,
        IconColor : 'orange',
        GuideLink : {lobby:'Guide',lobbyState:'startGuide'},
        GuideImageComponet : <img className='icon' style={{width:'30px',height:'30px'}}  src={logoW}/>,
    },

]


export const dashBoardGuide = [
    {
        GuideTitle : '아직 준비중인 페이지에요',
        GuideText: '스페이스를 요약해서 보여드릴 예정이에요',
        GuideIcon : 'warning',
        IconColor : null,
        GuideLink : null,
        GuideImageComponet : null,
    },
    {
        GuideTitle : '대시보드 알아보기',
        GuideText: '대시보드 사용법을 알려드립니다.',
        GuideIcon : 'pie_chart',
        IconColor : '#F96E6C',
        GuideLink : null,
        GuideImageComponet : null,
    },

]

export const spaceGuide = [
    {
        GuideTitle : '아직 준비중인 페이지에요',
        GuideText: '스페이스를 설정하는 페이지에요',
        GuideIcon : 'warning',
        IconColor : null,
        GuideLink : null,
        GuideImageComponet : null,
    },

]