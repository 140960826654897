////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, {useState, useEffect, useRef } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { Route, Link } from 'react-router-dom';
import { useHistory } from "react-router";
// 라이브러리 
import { useGoogleLogin } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google'// 구글 로그인
// 설정 파일
import * as config from 'config/OverlapConfig'

import { withRouter } from 'react-router-dom';

import * as apiAccount from 'service/api/apiAccount'

// 컴포넌트 연결
import ViewInfo from "components/Lobby/space/module/ViewInfo"
import OverlapInfo from "components/Lobby/space/module/OverlapInfo"
import GuideBalloon from 'components/common/form/GuideBalloon'; // 말풍선 가이드
import SwitchModule from 'components/Edit/editModule/SwitchModule'; // 체크박스 모듈
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import 'sass/login.scss'

// 이미지 연결
import logoW from 'assets/logoW.svg';

// 서비스 연결
import * as Editor from 'service/edit/EditService'; 
import * as overlap from 'service/api/overlap';
import * as Utility from 'service/other/Utility' ; 
import * as view from 'service/api/view';
import { utils } from 'sortablejs';
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

function ChannelCreate({spaceId,setMode}) {
    // const todos = useSelector(state => state.todos)
    // const dispatch = useDispatch()
    // const addTodo = text => dispatch(addTodo(text))


    let defaultChannelData = {
      name : '',
      resolution : {width : 1920, height : 1080},
      private : false,
    }

    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////
    // state
    const [textValue, setTextValue] = useState('');
    const [resolutionValue, setResolutionValue] = useState('HD');

    const [Resolution, setResolution] = useState({...defaultChannelData.resolution});


    // 2단계 채널 정보 관련
    const [ChannelData, setChannelData] = useState(defaultChannelData);
    const [ChannelOption, setChannelOption] = useState('select');
    const [ChannelList, setChannelList] = useState([]);
    const [CreateStep, setCreateStep] = useState(1);
    const [ChannelSelect, setChannelSelect] = useState(null); // 선택한 채널의 uuid


    const [Flag_AutoMake, setFlag_AutoMake] = useState(true); // 채널 자동 생성


    // 선택한 채널의 uuid


    const [Direction, setDirection] = useState('horizontal'); //horizontal,vertical

    // rel 
    // const SpaceRef = useRef(null);
    ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////
      useEffect(() => {

        getChannel();

      }, []);

      useEffect(() => {
        console.log('width',Resolution.width,'height',Resolution.height)
        resolutionControl(Resolution.width,Resolution.height);
      }, [Resolution,Direction]);
    ////////////////////////////////////////////////////////
    // Fuction
    ////////////////////////////////////////////////////////


      const changeChannel = ({key,subKey,value}) => {
        console.log('key,subKey,value',key,subKey,value)
        const Data =  Utility.deepCopy(ChannelData)
        if(subKey){
          Data[key][subKey] = value;
        }else{
          Data[key] = value;
        }
        setChannelData(Data)
      }
      

      const getChannel = async() => {
        const viewList = await view.list(spaceId);
        if(viewList.length == 0){
          setChannelOption('make')
        }
        setChannelList(viewList);
      }

      const valueChange = (key,value) =>  {
        let newObject = Utility.deepCopy(ChannelData);
        newObject[key] = value;
        setChannelData(newObject);
        console.log('newObject',newObject);
      }
      function handleTextChange(event) {
        setTextValue(event.target.value);
        valueChange('name',event.target.value)
      }
      console.log(resolutionValue)


      /**
       * 오버랩 생성 버튼을 누르면 오버랩을 생성합니다.
       */
      const createOverlap = async () => {

        const Channel= Utility.deepCopy(ChannelData);

        if(Channel.name == ''){
          Channel.name = '새로운채널';
        }
        const res =  await view.create(spaceId,Channel)
        setMode(null);
      }

      /**
       * 해상도를 조절합니다.
       */
      const resolutionControl = (width,height) => {

        switch (Direction) {
          case 'horizontal':
            changeChannel({key:'resolution',value:{width : Number(width), height : Number(height)}})
            break;
          case 'vertical':
            changeChannel({key:'resolution',value:{width : Number(height), height : Number(width)}})

          default:
            break;
        }
      }

     


    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////
    return (
        <div id="overlapCreateScreen" className='overlapCreate SpaceWrap'>
            <style jsx>{`${SpaceModuleStyle}${ContainerStyle}`}</style>

            <div id="MainContent">

            <div className='overlapSetWrap'>
              <section className='overlapBasicField'>
                <div className='overlapBasicField'>
                  <h3>
                    새로운 채널을 생성합니다. 
                  </h3>
                  <small>
                    채널은 오버랩의 프로젝트를 스트리밍·녹화 프로그램과 연결해주는 역활을 합니다.
                  </small>
                </div>
              
                <div  className= 'bothField'>
                <div className='overlapPreViewField'>
                  <OverlapInfo key={'preView'} setSelect={()=>(console.log('1'))} index={1} windowSize={null} data={{title:ChannelData['name']==''||!ChannelData['name']?'새로운 채널':ChannelData['name'],resolution_width:ChannelData.resolution.width,resolution_height:ChannelData.resolution.height}} spaceId={'ex'}/>
                  <span className='preViewInfo' >{Editor.icon({icon:'resize',size:20,lineHeight:25})} <p>{ChannelData.resolution.width}*{ChannelData.resolution.height}</p></span>
                  </div>

                  <div className='overlapInputField'>
                    <p>채널 이름</p>
                    <input className='overlapTitleInput' placeholder="새로운 채널" value={ChannelData.title} onChange={(e)=>handleTextChange(e)}/>
                    <p>채널 화면 방향</p>
                    <div className='buttonList'>

                      <button className={Direction == 'horizontal'?'select':'unselect'} onClick={()=>{setDirection('horizontal'); }}>
                      {Editor.icon({icon:'crop_landscape',size:25,lineHeight:30})}
                        가로
                      </button>
                      <button className={Direction == 'vertical'?'select':'unselect'} onClick={()=>{setDirection('vertical'); }}>
                      {Editor.icon({icon:'crop_portrait',size:25,lineHeight:30})}
                        세로
                      </button>
                      <button className={Direction == 'custom'?'select':'unselect'}  onClick={()=>{setDirection('custom'); }}>
                      {Editor.icon({icon:'crop_rotate',size:25,lineHeight:30})}
                        커스텀
                      </button>

                    </div>

                    <p>채널 해상도</p>
                    {Direction == 'horizontal' || Direction == 'vertical' ? 

                    <div className='buttonList'>

                    <button className={Resolution.width == 1920 && Resolution.height == 1080 ?'select':'unselect' }  onClick={()=>{setResolution({width : 1920, height : 1080}); }}>
                    {Editor.icon({icon:'HD',size:25,lineHeight:30})}
                      HD 해상도
                    </button>
                    <button  className={Resolution.width == 3840 && Resolution.height == 2160 ?'select':'unselect' } onClick={()=>{setResolution({width : 3840, height : 2160}); }}>
                    {Editor.icon({icon:'4K',size:25,lineHeight:30})}
                      4K 해상도
                    </button>

                    </div>
                    
                    :null}
                    {Direction == 'custom' ? 
                    
                    <div className='customResolution'>
                        <input className='resolutionInput' type='number' value={ChannelData.resolution.width} onChange={(e)=>changeChannel({key:'resolution',subKey:'width',value:Number(e.target.value)})}/> 
                        {Editor.icon({icon:'close',size:20,lineHeight:30})}
                        <input className='resolutionInput' type='number' value={ChannelData.resolution.height} onChange={(e)=>changeChannel({key:'resolution',subKey:'height',value:Number(e.target.value)})}/> 
                    </div>
                    :null }
                    {/* <textarea  className='overlapSummaryInput' rows="4" maxlength="200" placeholder="오버랩에 대한 소개 (옵션) ">
                      이 오버랩 프로젝트에 대해 설명해주세요
                    </textarea> */}
                  </div>
                  
                </div>
              </section>
              </div>
            </div>

            <section id='CreateControlField'> 
            <div className='PrevButton'> 

                <button onClick={()=>setMode(null)}>
                  채널 목록으로
                </button>

            </div>
            <div className='CreateState'> 

            </div>
            <div className='NextButton'> 
              <button onClick={()=>createOverlap()}>
                  채널 생성
              </button>
            </div>
        
            </section>  

        </div>
    );
  }

  export default ChannelCreate;

  
  const SpaceModuleStyle = `
  .overlapCreate {

  }
  
  `;
  
  const ContainerStyle = `
  @keyframes start {
    0% {
      opacity: 0;

    }
    100% {
      opacity: 1;

    }
  }

  .overlapCreateTest {
    animation: start 0.6s forwards;
      display: flex;
      justify-content: center;
      width: 100%;
  }

  .overlapSetWrap {
    width: calc(100% - 60px);
    margin : 20px;
    padding : 10px;
    border-radius: 10px;
    background: #2D2D2D;
  }
  

  .overlapSetWrap  .overlapTitle{
    width: calc(100% - 40px);
    margin : 20px;
    display: flex;
    flex-direction: column;
  }

  #overlapCreateScreen {
    display: flex;
    width: 100%;
    padding-right: 8px; /* 스크롤바의 너비 */
    height: calc( 100vh - 40px );
    overflow: hidden; /* 스크롤바를 기본적으로 숨깁니다. */
    flex-direction: column;
  }

  #overlapCreateScreen:hover {
    padding-right: 0px; /* 스크롤바의 너비 */
    overflow: auto; /* 마우스 오버 시 스크롤바를 표시합니다. */
  }

  /* 크로스 브라우저 호환성을 위한 스크롤바 스타일링 */
  .overlapCreateScreen::-webkit-scrollbar {
      width: 8px; /* 스크롤바의 너비 */
  }

  .overlapCreateScreen::-webkit-scrollbar-thumb {
      background-color: #888; /* 스크롤바의 색상 */
      border-radius: 4px; /* 스크롤바의 모서리 */
  }

  .overlapCreateScreen::-webkit-scrollbar-track {
      background: #1c1c1c; /* 스크롤 트랙의 색상 */
  }

  

  #overlapCreateScreen  .overlapTitleInput{
    width: 500px;
    height: 50px;
    margin : 10px 0px;
    background-color: #444;
    border: none;
    color : #ffffff;
    font-size : 30px;
    margin-top : 10px;
    line-height: 50px;
    border-radius: 5px;
    box-shadow: none;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-family: "프리텐다드SemiBold", sans-serif;

  }

  #overlapCreateScreen .bothField{
    display :flex;
    justify-content: center;
  }
  #overlapCreateScreen .overlapBasicField {
    margin: 20px;
  }

  #overlapCreateScreen .overlapPreViewField{
    display :flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 300px;

  }
  #overlapCreateScreen .overlapPreViewField .preViewInfo{
    display :flex;
    justify-content: center;
    align-items: center;
  }
  #overlapCreateScreen .overlapPreViewField .preViewInfo p{
    margin-left : 10px;
  }

  #overlapCreateScreen .overlapInputField .customResolution{
    display : flex;
    justify-content: start;
    align-items: center;
  }

  #overlapCreateScreen .overlapInputField .customResolution .resolutionInput{
    background-color: #444;
    border : none;
    color : #fff;
    height: 40px;
    border-radius: 5px ;
    margin: 10px 0px;
    font-size : 20px;
    width: 100px;
    text-align: center;
  }

  /* Chrome, Safari, Edge, Opera */
  #overlapCreateScreen .overlapInputField .customResolution input::-webkit-outer-spin-button,
  #overlapCreateScreen .overlapInputField .customResolution input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  /* Firefox */
  #overlapCreateScreen .overlapInputField .customResolution input[type=number] {
    -moz-appearance: textfield;
  }




  

  #overlapCreateScreen .overlapFileExample{
    position: relative;
    width: 192px;
    height: 108px;
    background-color: rgb(28, 28, 28);    
  }

  #overlapCreateScreen .overlapInputField{
    display: flex;
    flex-direction: column;
    flex : 1; 
    margin : 0px 20px;
  }

  #overlapCreateScreen .overlapBasicField button {
    width: 100px;
    height: 70px;
    color : #fff;
    background-color: #363636;
    margin : 10px 10px 10px 0px;
    border-radius: 5px ;
  }

  #overlapCreateScreen .select {
    background-color: rgb(28, 28, 28);    
  }

  

  #overlapCreateScreen .preView{
    position: absolute;
    width: 192px;
    height: 108px;
    overflow: hidden;
    border-radius: 5px ;
    transition: all 0.5s;
}

 #overlapCreateScreen.readyPreView{
      background-color: rgb(28, 28, 28);
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto;
  }


  #overlapCreateScreen .ChannelBasicField {
    margin: 10px;
  }

  #overlapCreateScreen .ChannelSetField {
    display : flex;
    background-color: #222;
    border-radius: 5px ;
    margin : 10px 0px;
    padding : 0px 10px;
    
  }

  #overlapCreateScreen .ChannelSetField .ChannelRadioButton{
    display : flex;
    width: 300px;
    height: 50px;
    margin : 10px 0px;
    align-items: center;
    background-color: #363636;
    border-radius: 5px ;
    padding-left : 10px;
  }

  #overlapCreateScreen .buttonList .select{
    background-color: rgb(28, 28, 28);
  }
  #overlapCreateScreen .ChannelSetField .ChannelRadioButton.select{
    background-color: rgb(28, 28, 28);
  }



  #overlapCreateScreen .ChannelSetField  .ChannelSelectField{
    width: 100%;
    max-height: 500px;
    margin : 10px 0px;
    margin-left : 10px;
    border-radius: 5px ;
    background-color: #363636;
    overflow : auto;
  }
  #overlapCreateScreen .selectContainer{
    margin : 10px;
    
  }


  #overlapCreateScreen .ChannelInfo{
    padding :20px;
  }
  #overlapCreateScreen .ChannelInfo.select{
    border-radius: 10px;
  }

  


  #overlapCreateScreen .selectContainer .channelList{
    width: 100%;
    padding-top: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    grid-auto-rows: 170px;
  }



  #overlapCreateScreen  #CreateControlField  {
    display: flex;
    width: calc(100% - 40px) ;
    padding : 0px 20px;
    justify-content: space-between;
    height: 100px;
    margin-top: 20px;
  }


  #overlapCreateScreen  #CreateControlField .PrevButton{
    color: white;
    width: 150px;
  }
  #overlapCreateScreen  #CreateControlField .PrevButton button{
    color: white;
    width: 150px;
    height: 50px;
    background-color: #363636;
    border-radius: 10px;
  }

  #overlapCreateScreen  #CreateControlField .CreateState{
    color: white;
    flex : 1;
    text-align: center;
  }

  #overlapCreateScreen  #CreateControlField .NextButton{
    color: white;
    width: 150px;
  }
  #overlapCreateScreen  #CreateControlField .NextButton button{
    color: white;
    width: 150px;
    height: 50px;
    background-color: #363636;
    border-radius: 10px ;
  }


  /* 채널 필드 */

  #overlapCreateScreen #ChannelCreateField{
    margin : 20px 0px;

  }
  
  #overlapCreateScreen #ChannelCreateField .ChannelCreateOption{
    display: flex;
    width: 100% ;
    justify-content: space-between;
    height: 50px;

    align-items: center;
    max-width: 500px ;

  }

  #overlapCreateScreen .switch{
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
    
  }

  #overlapCreateScreen .switch input {
      width: 0;
      height: 0;
      opacity: 0;

    }
  #overlapCreateScreen .switch .slider {

        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $EditModuleButton;
        -webkit-transition: .4s;
        transition: .4s;
        border-radius: 34px;
    }
    #overlapCreateScreen .switch .slider:before {
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.45), 0 3px 3px rgba(0, 0, 0, 0.15);
        position: absolute;
        content: "";
        height: 16px;
        width: 16px;
        left: 2px;
        bottom: 2px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
        border-radius: 50%;
        
      }
    #overlapCreateScreen .switch input:checked + .slider {
        background-color: orange;
      }s
      
      #overlapCreateScreen .switch input:focus + .slider {
        box-shadow: 0 0 1px orange;
      }
      
      #overlapCreateScreen .switch input:checked + .slider:before {
        -webkit-transform: translateX(20px);
        -ms-transform: translateX(20px);
        transform: translateX(20px);
      }
  
}




  `;