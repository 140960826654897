////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { useState, useEffect, useContext, Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widgetEdit.scss"

// 컴포넌트 연결
import MediaSourceModule from 'components/Edit/editModule/MediaSourceModule'; // 텍스트 모듈
import ImageButtonModule from 'components/Edit/editModule/ImageButtonModule'; // 이미지 버튼을 눌러 여러 옵션중 값을 정합니다.
import ErrorModule from 'components/Edit/editModule/ErrorModule'; // 잘못된 위젯의 값이 있을때 편집기를 대체합니다.
import GuideBalloon from 'components/common/form/GuideBalloon'// 가이드 벌룬

// 서비스 연결
import * as Editor from 'service/edit/EditService'; 
import * as fileAPI from 'service/api/file' ; 
import * as ED from 'service/engine/decoder/EditorDecoder'; 

// [프로바이더]
import EditContext from 'service/context/EditContext' ; 


////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////


/** [에디터] 텍스트 편집 툴 입니다.
 * (대상위젯, 편집펑션, 편집할 객체 키, 타이틀 ,옵션)
* @param {widget} target : 편집할 대상 위젯
* @param {function} modify : 편집용 함수
* @param {{
* size: { width : number, height : number }
* }} keyName : 해당 에디터가 수정하는 값 입니다. value : {'textValue', 수정할 대상값,} 
* @param {{
* editTitle: string,textValue: string
* }} title : 편집기에 표시될 제목들 입니다.
* @param {{
* fixToggle : boolean 
* openToggle : boolean
* }} option : 추가 옵션입니다. fixToggle : 토글을 고정합니다. openToggle : 필드의 기본 상태를 열림으로 봅니다.
*/


function ImageEditForm({target,modify,keyName,title,option}) {

/**
     * 위젯을 수정합니다.
     *  @param {bool} classify : 수정할 값의 분류를 적습니다.
     *  @param {bool} key : 수정할 키의 이름을 적습니다.
     *  @param {bool} value : 해당페이지가 선택한 뷰에서 방송중인지 확인합니다.
     */

    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////

    //set
    const context = useContext(EditContext);
    const uuid = target.uuid
    // state
    const [Toggle, setToggle] = useState(option?.openToggle==undefined?true:option?.openToggle);
    const [OpenForm, setOpenForm] = useState(option?.openForm==undefined?true:option?.openForm);

    const [GetImageAction, setGetImageAction] = useState(null);
    
    const [ImageData, setImageData] = useState(null);
    const [ImageURL, setImageURL] = useState(null);

    // rel 
    const {mediaType,mediaSource} = keyName ;
    ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////
    useEffect(() => {
        setGetImageAction(ED.getValue(target,mediaType))
        // 여기에 컴포넌트가 마운트되었을 때 실행할 코드를 작성합니다.
        getImageURL(ED.getValue(target,mediaType),ED.getValue(target,mediaSource))
        return () => {
        // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
        };
      }, []);

      useEffect(() => {
        setGetImageAction(ED.getValue(target,mediaType))
        getImageURL(ED.getValue(target,mediaType),ED.getValue(target,mediaSource))
        // 여기에 컴포넌트가 마운트되었을 때 실행할 코드를 작성합니다.
        return () => {
        // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
        };
      }, [ED.getValue(target,mediaSource)]);

      useEffect(() => {
        if(ImageURL){
            imageDataCheck(ImageURL)
        }
        return () => {
        // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
        };
      }, [ImageURL]);
      

     
    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////

    // 해당 에디터에서 편집이 가능한 값 목


    const modifyImage = (source) => {
        modify(mediaSource.category,[mediaType.key,mediaSource.key],[GetImageAction,source],uuid);
        // modify(mediaType.category,mediaType.key,GetImageAction,uuid);
        imageDataCheck(source)
    }

    const getImageURL = async (type,source) =>{
      
        switch (type) {
            case 'link':
                setImageURL(source)
                break;
            case 'library':
                if(source){
                    const Image = await fileAPI.image(source)
                    setImageURL(Image?.file_path)
                }
                break;        
            default:
                break;
        }
    }
    const getImageSource = (url) => {
        return new Promise(function(resolve, reject){
            var img = new Image()
            img.onload = function(){
                resolve(img)
            }
            img.onerror = function(){
                reject(null)
                 
            }
            img.src = url
        })
    }



    const imageDataCheck = (mediaSource) => {
        getImageSource(mediaSource).then((imgElement)=>{
            setImageData(imgElement)
        }).catch((errorUrl)=>{
            setImageData(false)
        })
    }


    const setImageSource = (type) => {

        if(type == ''){

        }else if (type == 'library'){
            context.panelControl('bottom','addImage')
        }
      
        setGetImageAction(type)
    }
    

    const imageInfo = () => {

        const Style = {
            label: {
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
            },
            textarea : {
                width: '100%',
                resize: 'none',
                boxSizing:'border-box',
            },
        }
        
        return(
            <Fragment>
       
            <article className="EditPart" >
                <div className='MediaSourcePreview'>
                    <div className='MediaSourceImgPreviewField'>
                        {ImageData?.width?
                            <picture className='MediaSourceImgPreview'>
                                <source srcSet={ImageURL}/>
                                <img src={ImageURL}/>
                            </picture>
                        :
                            <div className='MediaSourceImgPreview'>
                                <div className='sourceError'>
                                {Editor.icon({icon:'hide_image',size:70,lineHeight:70})}
                                <p>이미지를 찾을 수 없습니다.</p>
                     
                                </div>
           
                            </div>
                        }
                        
                    </div>

                    <div className='MediaSourceInfo'>
                        <GuideBalloon guideText={<p>이미지의 원본<br/>가로 사이즈 <br/> {ImageData?.naturalWidth}px </p>} width={100} direction='top'>
                        <div className={ImageData?.width? 'InfoBox':'InfoBox disable' }>
                            <div>
                            {Editor.icon({icon:'width',size:20,lineHeight:20})}
                            </div>
                            <div>
                                {ImageData?.naturalWidth??'-'}
                            </div>
                        </div>
                        </GuideBalloon>

                        <GuideBalloon guideText={<p>이미지의 원본<br/>세로 사이즈 <br/> {ImageData?.naturalHeight}px </p>} width={100} direction='top'>
                        <div className={ImageData?.height? 'InfoBox':'InfoBox disable' }>
                            <div>
                            {Editor.icon({icon:'height',size:20,lineHeight:20})}
                            </div>
                            <div>
                                {ImageData?.naturalHeight??'-'}
                            </div>
                        </div>
                        </GuideBalloon>
                        
                        <GuideBalloon guideText={<p>이미지의 원본 비율<br/> 1 : {(ImageData?.width /ImageData?.height).toFixed(2)} </p>} width={150} direction='top'>
                         <div className={ImageData?.height? 'InfoBox':'InfoBox disable' }>
                            <div>
                            {Editor.icon({icon:'pageless',size:20,lineHeight:20})}
                            </div>
                            <div>
                                {ImageData?.width?(ImageData?.width /ImageData?.height).toFixed(2):'-'}
                            </div>
                        </div>
                        </GuideBalloon>
                    </div>
                </div>
            </article>
            </Fragment>
            
        )
        

    }

    const imageAppend = () => {

        return (
            <Fragment>

            <article className="EditPart" >
            <div className='ButtonModule'>
             <h5>이미지 가저오기</h5>
                <div>
                    <ImageButtonModule value={GetImageAction} type ="mediaType"  data={[{val:"link",icon:"add_link"},{val:"library",icon:"folder"},]} change={(type,value)=>setImageSource(value)}/>
                </div>
             </div>
            {GetImageAction=='link'? 
             <div className='ButtonModule'>
                <MediaSourceModule value={ED.getValue(target,mediaSource)} target={'mediaSource'} type={mediaSource?.key} title="인터넷에서 이미지 주소"  placeholder={'여기에 이미지 URL을 복사해주세요'} change={(type,value)=>modifyImage(value)} />
             </div>
            :null }

            </article>
            </Fragment>
        )
    }



    return (
    <Fragment>
        <style jsx>{`${FormStyle}`}</style>
        <section className='ImageEditForm'>
            {Editor.editorDetail('이미지 가저오기','add_photo_alternate',OpenForm,setOpenForm,Toggle)}
            <div className={OpenForm?'editContainer containerOpen':'editContainer containerClose'}>
                {/* {Editor.editorSubTitle('텍스트 설정',"edit_note")} */}
                {imageAppend()}
         
            </div>
        </section>
        <section className='ImageEditForm'>
            {Editor.editorDetail('이미지','image_search',OpenForm,setOpenForm,false)}
            <div className={OpenForm?'editContainer containerOpen':'editContainer containerClose'}>
                {imageInfo()}
            </div>
        </section>
    </Fragment>
    );

}

export default ImageEditForm;


const FormStyle = `

.ImageEditForm .MediaSourceImgPreviewField{
    display:flex;
    width: calc ( 100% - 10px ) ;
    height : 150px;

    background-color: #222;
    margin : 10px 0px;
    padding : 5px;
    border-radius : 5px;
}
.ImageEditForm .MediaSourceImgPreview {
    display: flex;
    width: 100%;
    height : 150px;
    object-fit: scale-down;
}

.ImageEditForm .MediaSourceImgPreview img {
    display: flex;
    width: 100%;
    height : 150px;
    object-fit: scale-down;
}
.ImageEditForm  .MediaSourceInfo{
    display: flex;
    width: 100%;
}

.ImageEditForm .sourceError{
    margin : auto;
    display :flex;
    flex-direction: column;
    align-items: center;
}

.ImageEditForm .InfoBox{
    margin-right : 10px;
    display :flex;
    border-radius : 5px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50px;
    height : 50px;
    font-size : 15px;
    color : #999;
    background-color: #222;
    opacity: 1;
    overflow: hidden;
    transition: all 1s ease;
}

.ImageEditForm .InfoBox.disable{
    width: 50px;
    height : 0px;
    opacity: 0;

}
`;