////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widgetEdit.scss"


// 컴포넌트 연결
import NumberModule from '../editModule/NumberModule'; // 숫자 모듈
import SwitchModule from '../editModule/SwitchModule';

// 서비스 연결

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class MorphingEdit extends Component {
    
    state = {
    }

    static defaultProps = {

      }
    change = (name,value) =>{
        this.props.callBack('style',name,value)
    }

    render() { 
        return (
            <Fragment>
                <fieldset className="EditModuleField" >
                    <legend> 부드러운 이동 </legend>
                    <SwitchModule value={this.props.data.style.morphing} type ="morphing" title="사용" change={this.change}/>
                    {/* <NumberModule value={(this.props.value.rotatez?this.props.value.rotatez:0)} min='-360' max='360' title="회전" type ="rotatez" change={this.change}/> */}
                </fieldset>
            </Fragment>
        );
    }
}

////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    // screenData : state.screenData,
})

function mapDispatchToProps(dispatch){
    return {
    //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(MorphingEdit);
  