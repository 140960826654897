////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { Route, Link } from 'react-router-dom';
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결

// 컴포넌트 연결
// 이미지 연결
import OBSmain from 'assets/tutorial/OBSmain.png';
import OBSmenu from 'assets/tutorial/OBSmenu.png';
import OBSmenu2 from 'assets/tutorial/OBSmenu2.png';
import browser from 'assets/tutorial/browser.png';
// 서비스 연결
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class Tutorial extends Component {
    
    state = {
        page : 1
    }


    menu = (mode) =>{

        switch (mode) {
            case 2:
                return (
                    <Fragment>
                        <img id="OBSmenu" src={OBSmenu}/>
                        <img id="OBSmenu2" src={OBSmenu2}/>
                    </Fragment>
                    )
            case 3:
                return (
                    <Fragment>
                        <img id="OBSbrowser" src={browser}/>
                    </Fragment>
                    )       
            default:
                <Fragment>
            </Fragment>
        }

    }

    componentDidUpdate(prevProps){
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-R99737TJCR", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
        }
    }
    render() { 


        return (
            <div id = "TutorialOBS" className={"OBSMODE"+this.props.mode%4}>
                <img id="OBSmain" src={OBSmain}></img>
                {this.menu(this.props.mode%4)}
            </div>
        );
    }
}
////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    passToken : state.passToken,
})

function mapDispatchToProps(dispatch){
    return {
    //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(Tutorial);
  