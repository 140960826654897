////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/edit.scss"


// 컴포넌트 연결
import WidgetList from './ApeendWidget'; // 메뉴 헤더
import Setting from './EditScreenOption'; // 메뉴 헤더
import EditInfo from './EditOverlapInfo' //정보메뉴
// 서비스 연결
import * as Rest from '../../../service/api/rest' ; 
import * as Screen from '../../../service/api/screen' ; 
import * as Modal from '../../../service/event/ModalControl';

// 이미지 연결
import saveComplete from 'assets/edit/saveComplete.png';
import saveError from 'assets/edit/saveError.png';
import saveRefresh from 'assets/edit/saveRefresh.png';
import information from 'assets/edit/information.png';
import undoBtn from 'assets/edit/undo.png';

import editSetting from 'assets/edit/EditScreenSetting.png';
import widgetList from 'assets/edit/widgetList.png';

import arrow from 'assets/edit/arrow.png';



////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class EditNavHeader extends Component {
    
    state = {
        navigation : "allWidget",
        viewMode : false,
        saveError: false,
        errorRefresh : 0,
        customScrollValue : 0,
        customScrollLevel : 0,
        customScrollTime : 0
    }

    constructor(props) {
        super(props);
        this.customScrollRef = React.createRef();
        this.preventDefault = (e) => {e.preventDefault()}
      }


    componentDidMount() {
        this.customScrollRef.current.addEventListener('wheel', this.preventDefault)
        // let count = setInterval(console.log('hi'), 1000)
        let count = setInterval(this.refresh, 2000)
        this.setState({
            interval: count,
        },)
    }

    componentWillUnmount() {
        this.customScrollRef.current.addEventListener('wheel', this.preventDefault)
        clearInterval(this.state.interval);
    }

    viewMode = (value = null) =>{
        if (value == null){
            this.setState({
                viewMode : !this.state.viewMode
            },)
        } else {
            this.setState({
                viewMode : value
            })
        }
    }
    
    bottomNav = () => {
        switch (this.state.navigation) {
            case 'setEdit':
                return(
                    <Setting/>     
                )
        
            default:
                return(
                    <WidgetList viewMode={this.viewMode} list={this.state.navigation}/>
                )
        }
    
    }

    navChange = (value="basicWidget") =>{
        this.setState({
            navigation : value,
            customScrollValue : 0,
            customScrollLevel : 0,
        })
    }

    ////////////////////////////////////////////////////////
    // refresh (특정 시간마다 스크린을 업데이트 해줍니다.)
    // 위젯이 이동 할때 해당위치를 % 로 계산해서 이동시켜줍니다.
    ////////////////////////////////////////////////////////
    refresh = () =>{
        if(this.props.autoSave){
            if(this.state.errorRefresh<65 ){
                // if (this.props.lastModify){
                //     let response = Screen.save()
                //     response
                //     .then((result) => {
                //         this.props.autoSaveDispatch()
                //         this.setState({
                //             saveError: false,
                //             errorRefresh : 0
                //         })
                //         }
                //     )
                //     .catch((error) => {
                //         console.log('save 4',error)
                //         let errorMessage = false
                //         switch (error.message) {
                //             case 'Network Error':
                //                 errorMessage = ( 
                //                 <div id="saveErrorBox">
                //                     <h3>
                //                     인터넷 연결 안됨
                //                     </h3>
                //                     <p>
                //                         인터넷에 연결되지 않았습니다
                //                         <br/> 연결이 복구되면 자동으로 저장됩니다.
                //                     </p>
                //                 </div>
                //                 )
                //                 break;
                //             case 'Request failed with status code 404':
                //                 errorMessage = ( 
                //                 <div id="saveErrorBox">
                //                     <h3>
                //                     서버 점검중
                //                     </h3>
                //                     <p>
                //                         오버랩 서버가 닫혀있습니다.
                //                         <br/> 서버 점검중이 아닌지 확인해보세요
                //                     </p>
                //                 </div>
                //                 )
                //                 break;    
                //             case 'Request failed with status code 500':
                //                 errorMessage = ( 
                //                 <div id="saveErrorBox">
                //                     <h3>
                //                         서버와 통신오류
                //                     </h3>
                //                     <p>
                //                         재시도 횟수 : {this.state.errorRefresh} <br/>
                //                         서버와 연결을 다시 시도하고 있습니다.
                //                     </p>
                //                 </div>
                //                 )
                //                 break;                          
                //             default:
                //                 errorMessage = ( 
                //                     <div id="saveErrorBox">
                //                         <h3>
                //                         알수없는 오류
                //                         </h3>
                //                         <p>
                //                         {error.message}
                //                         </p>
                //                     </div>
                //                     )
                //                 break;
                //         }
                //         this.setState({
                //             saveError: errorMessage,
                //             errorRefresh : this.state.errorRefresh+1
                //         },)
                //         // 재시도 횟수가 많으면 대응 해야 할듯 
                //         // this.state.errorRefresh<5 ? setTimeout(()=> this.refresh(), 2000) : setTimeout(()=> this.refresh(), 60000);
                //         }
                //     )
                // }
                
            }else{
                let errorMessage = false

                errorMessage = ( 
                    <div id="saveErrorBox">
                        <h3>
                            서버와 통신오류
                        </h3>
                        <p>
                            (F5) 키를 눌러서 새로고침 해주세요
                        </p>
                    </div>
                    )
                
                this.setState({
                    saveError: errorMessage,
                },)
            }
           
        }
    }


    // tokenToId = () =>{
    //     let response =  Rest.request('tokentoid','GET','',sessionStorage.getItem("JWT"))
    //     response
    //     .then((result) => {
    //         this.setState({
    //             userId : result.data.userId
    //         })
    //         }
    //     )
    //     .catch((error) => error)
    // }
    

    saveButton = () =>{
        console.log('saveButton',this.props.lastModify)
        if(this.props.autoSave){

            if (this.props.lastModify){
                console.log('saveButton',this.props.lastModify)
                if(this.state.saveError){
                    console.log('saveButton',this.state.saveError)
                    return(
                    <Fragment>
                        <button id="saveError" onClick={()=>Screen.save()}><img className="buttonImg" src={saveError}/></button>
                        {this.state.saveError}
                    </Fragment>
                    
                    )
                }
                return(<button id="autoSaving" onClick={()=>Screen.save()}><img className="buttonImg" src={saveRefresh}/></button>)
            }else{
                return(<button id="autoSave"><img className="buttonImg" src={saveComplete}/></button>)       
            }
        } else {
            return(<button id="ScreenSave" onClick={()=>Screen.save()}><img src={saveComplete}/></button>)
        }
    }
    wheelControl = (e) =>{
        if ( this.state.customScrollTime > new Date().getTime() ){
            return
        }
        if(!this.customScrollRef.current.querySelector('#WidgetSelectField')){
            return
        }
        const WidgetButtonSize = window.innerHeight/100*12
        let ScrollLevel  = this.state.customScrollLevel 

        if (e.deltaY < 0){
            if(ScrollLevel > Math.round(this.customScrollRef.current.querySelector('#WidgetSelectField').offsetHeight/WidgetButtonSize)-1){ScrollLevel = Math.round(this.customScrollRef.current.querySelector('#WidgetSelectField').offsetHeight/WidgetButtonSize)-1}
            if(ScrollLevel > 0){ScrollLevel = ScrollLevel - 1}
            this.customScrollRef.current.scrollTo({top:WidgetButtonSize*(ScrollLevel)}) 
        }
        if (e.deltaY > 0){
            if(ScrollLevel < Math.round(this.customScrollRef.current.querySelector('#WidgetSelectField').offsetHeight/WidgetButtonSize)-1){ScrollLevel = ScrollLevel + 1}
            this.customScrollRef.current.scrollTo({top:WidgetButtonSize*(ScrollLevel)}) 
        }
        this.setState({
            customScrollValue : e.target.scrollTop,
            customScrollLevel : ScrollLevel,
            customScrollTime : new Date().getTime() +300
        })
    }

    render() { 
        return (
            <div id = "EditNavHeader" className={'ViewMode'+this.state.viewMode}>
                <EditInfo/>
                <div id = "WidgetSetting" className="no-drag">
                    <div id="StateArea">
                        <div id="bottombtn">
                        {this.saveButton()}
                        <button id="Undo" className={(true?'disable':'able')} onClick={()=>Screen.undo()}><img className="buttonImg" src={undoBtn}/></button>
                        <button id="Tips" onClick={()=>Modal.OverlayPush('EditTip')}><img className="buttonImg" src={information}/></button>
                        </div>
                    </div>
                    <div id="WidgetArea">
                        
                        <div id="bottomNav">
                            
                            {/* <div id="editSeting">
                                <img className="widgetListImg" src={widgetList}/>
                                <ul>
                                    <li>자막</li>
                                    <li>그래픽</li>
                                </ul>
                            </div> */}
                             <div id="editSeting">
                                <img className="widgetListImg" src={editSetting}/>
                                <ul>
                                    <li className={(this.state.navigation=="setEdit"?'select':'unselect')} onClick={()=>this.navChange('setEdit')}>화면</li>
                                    <li className={(this.state.navigation=="setVariable"?'select':'unselect')} onClick={()=>this.navChange('setVariable')}>변수</li>
                                </ul>
                            </div>
                            <div id="widgetList">
                                <img className="widgetListImg" src={widgetList}/>
                                <ul>
                                    {/* <li className={(this.state.navigation=="favoritesWidget"?'select':'unselect')} onClick={()=>this.navChange('favoritesWidget')}>즐겨찾기</li> */}
                             
                                    
                                    <li className={(this.state.navigation=="basicWidget"?'select':'unselect')} onClick={()=>this.navChange('basicWidget')}>기본</li>
                                    <li className={(this.state.navigation=="broadcastWidget"?'select':'unselect')} onClick={()=>this.navChange('broadcastWidget')}>방송</li>
                                    <li className={(this.state.navigation=="gameWidget"?'select':'unselect')} onClick={()=>this.navChange('gameWidget')}>게임</li>
                                    <li className={(this.state.navigation=="allWidget"?'select':'unselect')} onClick={()=>this.navChange('allWidget')}>모두</li>
                                    {/* <li className={(this.state.navigation=="broadcastWidget"?'select':'unselect')} onClick={()=>this.navChange('broadcastWidget')}>방송</li>
                                     <li className={(this.state.navigation=="gameWidget"?'select':'unselect')} onClick={()=>this.navChange('gameWidget')}>게임</li>
                                    <li className={(this.state.navigation=="leagueWidget"?'select':'unselect')} onClick={()=>this.navChange('leagueWidget')}>대회</li>
                                    <li className={(this.state.navigation=="etcWidget"?'select':'unselect')} onClick={()=>this.navChange('etcWidget')}>자막</li>
                                    <li className={(this.state.navigation=="graphicWidget"?'select':'unselect')} onClick={()=>this.navChange('graphicWidget')}>그래픽</li> */}
                                </ul>
                            </div>

                        </div>
                        
                        <button id="movebnt" onClick={()=>this.viewMode()}><img className={(this.state.viewMode?'expansion':'contract')} src={arrow}/></button>
                    </div>
                  
                </div>
                <div id="WidgetPlace" ref={this.customScrollRef} onWheel={this.wheelControl} >
                    {this.bottomNav()}
                </div>
            </div>
        );
    }
}
////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    history : state.history,
    lastModify : state.lastModify,
    autoSave : state.autoSave,
    broadCast : state.broadCast,
    editScreen : state.editScreen,

})

function mapDispatchToProps(dispatch){
    return {
    //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
        autoSaveDispatch : () => dispatch({type:'autoSave',value : null}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(EditNavHeader);
  