

function preset(preset,self,data,style){
    let fullsize = data.size.height
    let width = data.size.width*90/100 
    let profile = self.state.profile
    let heroData = data.value.heroData
    let dataList = []
    try{

    }catch{

    }
    switch (preset) {
        case 'normal': 
            if(heroData){

                for (let index = 0; index < heroData.length; index++) {
                    let info = heroData[index]
                    let mostType = 'all'
                    if (info.hero =='other'){
                        switch (info.type) {
                            case '탱커모스트영웅':
                                mostType = 'tanker'
                                break;
                            case '딜러모스트영웅':
                                mostType = 'dps'
                                break;
                            case '힐러모스트영웅':
                                mostType = 'support'
                                break;    
                            case '모스트영웅':
                                mostType = 'all'
                                break;                      
                            default:
                                break;
                        }
                        
                        dataList[index] = (<li className="profileDataList" style={{height:fullsize*10/100+'px', margin:fullsize*2.5/100+'px'+' 0px ',backgroundColor:style.databox.background,color:style.databox.color}} > 
                        <div className="profileDataType" style={{fontSize:data.size.height*3/100+'px',lineHeight:data.size.height*10/100+'px'}} >
                            <img src ={'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/'+info.hero+'.png'}
                                style={{height:fullsize*10/100+'px' ,width:fullsize*10/100+'px',}}
                            >
                            </img>
                            <div className="profileDatainfo" >
                                <span style={{fontSize:data.size.height*5/100+'px',lineHeight:data.size.height*6/100+'px'}} >
                                    {info['title']}
                                </span>
                                <span style={{fontSize:data.size.height*3/100+'px',lineHeight:data.size.height*4/100+'px'}} >
                                    {(info['detail']?info['detail']:'')}
                                </span>
                            </div>
                   
                        </div>
                        <div className="profileDataValue" style={{fontSize:data.size.height*7.5/100+'px',lineHeight:data.size.height*10/100+'px'}}>
                        <div className="MostList">
                        {getMostImg(self.getMost(mostType),data.size.height*10/100)}
                        </div>
                        </div> 
                        </li>) 

                       

                    } else {
                        dataList[index] = (<li className="profileDataList" style={{height:fullsize*10/100+'px', margin:fullsize*2.5/100+'px'+' 0px ',backgroundColor:style.databox.background,color:style.databox.color}} > 
                                <div className="profileDataType" style={{fontSize:data.size.height*3/100+'px',lineHeight:data.size.height*10/100+'px'}} >
                                <img src ={'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/original/'+info.hero+'.png'}
                                        style={{height:fullsize*10/100+'px' ,width:fullsize*10/100+'px',}}
                                    >
                                    </img>
                                    <div className="profileDatainfo" >
                                        <span style={{fontSize:data.size.height*5/100+'px',lineHeight:data.size.height*6/100+'px'}} >
                                            {info['title']}
                                        </span>
                                        <span style={{fontSize:data.size.height*3/100+'px',lineHeight:data.size.height*4/100+'px'}} >
                                            {(info['detail']?info['detail']:'')}
                                        </span>
                                    </div>
                           
                                </div>
                                <div className="profileDataValue" style={{fontSize:data.size.height*7.5/100+'px',lineHeight:data.size.height*10/100+'px'}}>
                                {self.getProfile(info)}
                                </div> 
                                </li>) 
                    }
                }
            }
            let dataField =''
            // switch (tset ) {
            switch (profileState(profile)) {
                case "public":
                    dataField 
                    = (
                        <div className="profileData" style={{width}}>
                            <ul className="profileDatas">
                                {dataList}
                            </ul>
                        </div>
           
                    )      
                    break;
                case "private":
                    dataField 
                    = (
                        <div className="profilePrivate" style={{width}}>
                            <h1>
                                프로필이 비공개 되었습니다.
                            </h1>
                        </div>
             
                    )                     
                    break;
                case "Error":
                    dataField 
                    = (<div className="profileError" style={{width}}>
                        <h1>
                            프로필을 찾을 수 없습니다.<br/>
                            (배틀코드를 확인해보세요)
                        </h1>
                    </div>
                    )                  
                    break;                 
                default:
                    dataField 
                    = (
                        <div className="profileLode" style={{width}}>
                        <h1>
                            프로필 검색중입니다.
                        </h1>
                    </div>
                )   
                    break;
            }
            return(
                <div className={"object "+self.props.widgetName+"_preset_"+preset} ref={self.widget} style={{...style.object,backgroundColor:style.profile.background,color:style.profile.color}}>
                    <div className="padding">
                    <div>
                    <div className="userInfo" style={{height:fullsize*20/100+'px'}} >
                        <img className="userImage" style={{height:fullsize*20/100+'px',width:fullsize*20/100+'px'}}  src={getProfileImage(profile)} />
                        <div className="userTitle">
                            {data.value.userAlias?
                            <h3 className="userName" style={{fontSize:fullsize*8/100+'px',lineHeight:fullsize*15/100+'px',height:fullsize*15/100+'px'}} >
                                <div style={{fontSize:fullsize*8/100+'px',lineHeight:fullsize*10/100+'px',height:fullsize*10/100+'px'}}>
                                    {data.value.userAlias}
                                </div>
                                <div  style={{fontSize:fullsize*3/100+'px',lineHeight:fullsize*5/100+'px',height:fullsize*5/100+'px'}}>
                                    ({self.getUserAccount(profile)})
                                </div>
                            </h3>
                            :
                            <h3 className="userName" style={{fontSize:fullsize*8/100+'px',lineHeight:fullsize*15/100+'px',height:fullsize*15/100+'px'}} >
                                {self.getUserAccount(profile)}
                            </h3>
                            }
                                
                          
                            <ul className="userRank" style={{fontSize:fullsize*4/100+'px',lineHeight:fullsize*5/100+'px',height:fullsize*5/100+'px'}} >
                                {self.userRank('tanker')&&self.showPosition('tanker')? 
                                    <li className="userRanklist userRanktanker">
                                        <div className="positionBox" height={fullsize*3/100+'px'} style={{width:fullsize*3/100+'px',height:fullsize*3/100+'px'}} >
                                            <img className="positionIcon" height={fullsize*2/100+'px'} src={positionIMG('tanker')}/> 
                                        </div>
                                        
                                        {self.tierRank('tanker')?
                                            <img className="tierIcon" height={fullsize*5/100+'px'} src={self.tierRank('tanker')} /> 
                                        : ''}
                                        {self.userRank('tanker')?
                                            <b>{self.userRank('tanker')}</b>
                                        : ''}
                                    </li>
                                : ""}
                                {self.userRank('dps')&&self.showPosition('dps')? 
                                <li className="userRanklist userRankdps">
                                <div className="positionBox" height={fullsize*3/100+'px'} style={{width:fullsize*3/100+'px',height:fullsize*3/100+'px'}} >
                                        <img className="positionIcon" height={fullsize*2/100+'px'} src={positionIMG('dps')}/> 
                                    </div>
                                    {self.tierRank('dps')?
                                        <img className="tierIcon" height={fullsize*5/100+'px'} src={self.tierRank('dps')} /> 
                                    : ''}
                                    {self.userRank('dps')?
                                        <b>{self.userRank('dps')}</b>
                                    : ''}
                                </li>
                                : ""}
                                {self.userRank('suppot')&&self.showPosition('suppot')? 
                                <li className="userRanklist userRanksuppot">
                                <div className="positionBox" height={fullsize*3/100+'px'} style={{width:fullsize*3/100+'px',height:fullsize*3/100+'px'}} >
                                        <img className="positionIcon" height={fullsize*2/100+'px'} src={positionIMG('suppot')}/> 
                                    </div>
                                    {self.tierRank('suppot')?
                                        <img className="tierIcon" height={fullsize*5/100+'px'} src={self.tierRank('suppot')} /> 
                                    : ''}
                                    {self.userRank('suppot')?
                                        <b>{self.userRank('suppot')}</b>
                                    : ''}   
                                </li>
                                : ""}
                            </ul>
                        </div>

                    </div>
                    <hr/>
                    
                    <div className="dataField" style={{height:fullsize*70/100+'px',width}}>
                        {dataField}
                    </div>
                    </div>
                    </div>
                </div>

        )

        default:
            <div className={"object unknownPreset"} ref={self.widget} style={{...style.object}} >
                <p>알수없는 프리셋</p>
            </div>
    }
}
export function data(self,style) {
    return preset(self.props.data.preset,self,self.props.data,style)
}




function positionIMG(position){
        switch (position) {     
            case 'tanker':
                return "https://overlap.s3-ap-northeast-1.amazonaws.com/widget/Overwatch/Tank_icon.svg" 
            case 'dps':
                return "https://overlap.s3-ap-northeast-1.amazonaws.com/widget/Overwatch/Damage_icon.svg"
            case 'suppot':
                return "https://overlap.s3-ap-northeast-1.amazonaws.com/widget/Overwatch/Support_icon.svg"
            default:
                return '';
        }
}
    
function getProfileImage(profile){
    if(profile=="Error"){
        return 'https://d15f34w2p8l1cc.cloudfront.net/overwatch/c3090e3a1dccc58f143ff53801bc0cecb139f0eb1278f157d0b5e29db9104bed.png'
    }
    if (profile) {
        return profile.profileImage
    }else{
        return 'https://d15f34w2p8l1cc.cloudfront.net/overwatch/c3090e3a1dccc58f143ff53801bc0cecb139f0eb1278f157d0b5e29db9104bed.png'
    }
}
    
function getUserAccount(profile){
    if (profile) {
        return profile.userAccount
    }else{
        return '검색중...'
    }
}

function profileState(profile){
    if(profile=="Error"){
        return "Error"
    }
    if (profile) {
        return profile.profileState
    }
    else{
        return false
    }
}

function getMostImg(mostList,size=null){
    let custom = {}
    if (!mostList){
        return '전적없음'
    }
    if (size){
    let custom = {height:size+'px'}
    }

    let res = []
    for (let index = 0; index < mostList.length; index++) {
        res[index] = (
            <img src={"https://d1u1mce87gyfbn.cloudfront.net/hero/"+ mostList[index].hero+"/hero-select-portrait.png"}
            height={size+'px'}
            />
        )
    }
    return res
}



