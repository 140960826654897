////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widgetEdit.scss"

// 컴포넌트 연결
// import Show from '../show/Show'; // 메뉴 헤더

// 서비스 연결
import * as Model from 'service/value/Model' ; 

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

class PresetEdit extends Component {

    state = {
    }

    change = (name,value) =>{
        console.log('preset',name,value)
        this.props.callBack('preset',name,value)
    }
    presetChange = (value) => {
        this.props.callBack('preset',value)
    }
    
    presetList = () =>{
        try {
                let list = Model.presetFinder(this.props.data.type).map(
                (info,index) => (<li className={this.props.data.preset==info.name?'selectPreset presetList':'presetList'} onClick={()=>this.presetChange(info)}>{info.nameKo}</li>) 
            );    
            return list
        }catch{
            return null
        }
    }


    render() { 

        const Style = {
            label: {
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
            },
            textarea : {
                width: '100%',
                resize: 'none'
            }

        }

        return (
            <Fragment>
                <fieldset className="EditModuleField" style={Style.fieldset} >
                    <legend style={Style.legend} > 선택된 프리셋 </legend>
                    {this.props.data.preset}
                </fieldset>
      
                <fieldset className="EditModuleField" style={Style.fieldset} >
                    <legend style={Style.legend} > 프리셋 선택 </legend>
                    {this.presetList()}
                </fieldset>
            </Fragment>
        );
    }
}

////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    // screenData : state.screenData,
})

function mapDispatchToProps(dispatch){
    return {
    //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(PresetEdit);
  