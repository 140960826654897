////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component, Fragment , useState, useEffect, useRef} from 'react';

import { Route, Link } from 'react-router-dom';
// [리덕스]스토어 연결

// 이미지 연결
import logoW from 'assets/logoW.svg';
import PromotionVideo from 'assets/Promotion/overlapPromotion1.mp4';

// 컴포넌트 연결

import OverviewSection from 'components/Promotion/OverviewSection'; // 중요한 내용 색션
import BottomSection from 'components/Promotion/BottomSection'; // 바텀 부분 색션
import ShareNav from 'components/share/ShareNav'; // 공유 메뉴

import ShareObject from 'components/share/ShareObject'; // 공유 대상

import AccountBanner from 'components/Promotion/AccountBanner'; // 어카운트 관련 배너
// SASS&CSS 연결
import "sass/frontPage.scss"
// 서비스 연결
import * as Editor from 'service/edit/EditService' ; 
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

function SharePanel ({devicesList,screenList,StreamList,screenShare, stopShare}) {

    const [shareType, setShareType] = useState('video'); // video, mic, screen


    const stream = () => {

        let  shareList = [] 

        switch (shareType) {

            case 'video':
                shareList = devicesList.map(device => {
                    const deviceId = device?.deviceId;
                    return(<ShareObject stream ={StreamList[deviceId]} streamID = {deviceId} stopShare={stopShare} />)
                });
                  
                break;

                case 'screen':
    
                    shareList = screenList.map(screen => {
                        const screenid = screen?.screenid;
                        console.log('스크린 체커' ,StreamList )
                        return(<ShareObject stream = {StreamList[screenid]} streamID = {screenid} stopShare={stopShare} />)
                    });
                    console.log('스크린 체커' ,screenList )
                    if(shareList.length == 0){
                        shareList.push
                        (<div className='shareEX' onClick={()=>{screenShare('screen1')}}>
                        {Editor.icon({icon:'screen_share',size:100,lineHeight:100,color:'#e0e0e0'})}
                        <p>새로운 화면 공유하기</p>
                        </div>)
                    }
                      
                    break;
        
            default:
                break;
        }

        return shareList

    } 
 
    return  (
    <Fragment>
    <style jsx>{`${PanelStyle}`}</style>
        <div id = "SharePanel">
            <ShareNav shareType={shareType} setShareType={setShareType} />
            <div id = "ShareList">
                {stream()}
            </div>
        </div>
    </Fragment>
    );

}

export default SharePanel;



const PanelStyle = `

#SharePanel{
    display: flex;
    width : 100%;
    height : 100%;
    background-color: #3F3F3F;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

#ShareList{
    width : 100%;
    height : 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.shareEX{
    width : 300px;
    height : 300px;
    background-color: #222;
    border-radius: 10px;
    color : #e0e0e0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

`;