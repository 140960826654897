////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";


// SASS&CSS 연결
import "sass/widget.scss"

// [프로바이더] 연결
import EditContext from 'service/context/EditContext' ; 

// 컴포넌트 연결
import WidgetOverRay from '../Edit/editController/WidgetOverRay'; // 메뉴 헤더
import * as Preset from './preset/NoticePreset';

// 서비스 연결
import * as Event from '../../service/event/Actions' ; 
import * as Widget from 'service/edit/WidgetService' ; 
import * as Model from '../../service/value/Model' ; 
import * as EnCoder from '../../service/engine/EnCoder' ; 
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class ScrollCaption extends Component {
    
    static defaultProps = {
        widgetName: 'ScrollCaption',
      }


    //[임시] 컨텍스트 연결
    static contextType = EditContext;

    state = {
        speed : this.props.data.value.speed,
        text : this.props.data.value.text
    }
    
    constructor(props) {
        super(props);
        this.widget = React.createRef();
    }

    marqueeChange = ( ) => {
        // if(this.props.data.value.speed != this.state.speed||this.props.data.value.text != this.state.text){
        if(this.props.data.value.speed != this.state.speed){
            this.setState({
                speed : this.props.data.value.speed,
                text : this.props.data.value.text
            })

        }
    }
    
    marqueeRender = (Style)=>{
        // if(this.props.data.value.speed != this.state.speed||this.props.data.value.text != this.state.text){
        if(this.props.data.value.speed != this.state.speed){
            setTimeout(this.marqueeChange,1)
           return 
        }else{

            let opacity= Math.round(this.props.data.style.fillOpacity/100 * 255).toString(16)
            if (opacity.length==1){ opacity= 0+opacity }
            let background = this.props.data.style.fill+opacity
           return (
            <div className="marquee" style={{...Style.object,'background':background,'lineHeight':Style.object.height}}>
                <marquee scrollamount={this.props.data.value.speed}>
                    {this.props.data.value.text!=''?this.props.data.value.text:'여기에 텍스트를 입력하세요'}
                </marquee>
            </div>
            )
        }
    } 

    render() {     

        const Style = this.props.getStyle(this.props.data)
        const Animation = this.props.getAnimation(this.props.animation,this.props.data)
        const MergeStyle = Widget.styleMerge(Style,Animation);
        // 드레그를 통한 위젯 이동

        switch (this.props.parents) {
    
            case 'edit':
                // 편집시 화면이동 트렌스레이션 효과 방지
                MergeStyle.layout.transition = false
                MergeStyle.object.transition = false
                return (
                    <div ref={(ref) => this.object=ref }  className = {this.props.parents + " widgetObject "+this.props.widgetName+Widget.activate(this.props.data)}  style={MergeStyle.layout} >
                        <div className="marquee" style={{...MergeStyle.object,...MergeStyle.background}}>
                            {/* <p className="marqueeText">{this.props.data.value.text} </p> */}
                            {this.marqueeRender(MergeStyle)}
                        </div>
                    </div>
                );
                
            case 'broadcast':
                return (
                    <div ref={(ref) => this.object=ref }  className = {this.props.parents + " widgetObject "+this.props.widgetName+Widget.activate(this.props.data)}  style={MergeStyle.layout} >
                        <div className="marquee" style={{...MergeStyle.object,...MergeStyle.background}}>
                            {/* <p className="marqueeText">{this.props.data.value.text} </p> */}
                            {this.marqueeRender(MergeStyle)}
                        </div>
                    </div>
                );
          
            case 'view':
                return (
                    <div ref={(ref) => this.object=ref }  className = {this.props.parents + " widgetObject "+this.props.widgetName+Widget.activate(this.props.data)}  style={MergeStyle.layout} >
                        <div className="marquee" style={{...MergeStyle.object,...MergeStyle.background}}>
                            {/* <p className="marqueeText">{this.props.data.value.text} </p> */}
                            {this.marqueeRender(MergeStyle)}
                        </div>
                    </div>
                );
        }

    }
}
////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    userState : state.userState,
})

function mapDispatchToProps(dispatch){
    return {
        //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}


  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(ScrollCaption);
  