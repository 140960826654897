////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component, Fragment , useState, useEffect, useRef} from 'react';
import * as Editor from 'service/edit/EditService' ; 
import { Route, Link } from 'react-router-dom';
// [리덕스]스토어 연결

// 이미지 연결
import logoW from 'assets/logoW.svg';
import PromotionVideo from 'assets/Promotion/overlapPromotion1.mp4';

// 컴포넌트 연결
import HeroSection from 'components/Promotion/HeroSection'; // 오버랩 인트로 색션
import OverviewSection from 'components/Promotion/OverviewSection'; // 중요한 내용 색션
import BottomSection from 'components/Promotion/BottomSection'; // 바텀 부분 색션
import FeatureSection from 'components/Promotion/FeatureSection'; // 오버랩의 특징 설명 색션

import AccountBanner from 'components/Promotion/AccountBanner'; // 어카운트 관련 배너
// SASS&CSS 연결
import "sass/frontPage.scss"

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////


function NoticePage () {
    
    const [name, setName] = useState(''); // 입력된 이름을 저장할 상태
    const [FrontPageState, setFrontPageState] = useState(''); // 입력된 이름을 저장할 상태
    const [submittedName, setSubmittedName] = useState(''); // 제출된 이름을 저장할 상태
  


    const getPost = ({title = '공지사항',date = '2020-10-10'} = {}) => {

        let post = (
            <Link  to="/notice?uuid=1">
           <div className='NoticeContent'>
                <h3 className='NoticeTitle'>
                    {title}
                </h3>
                <div className='NoticeDate'>
                    {date}
                </div>
            </div>
      
            </Link>
    
        )

        return post;
    }
  


    return  (
    <Fragment>
    <style jsx>{`${PageStyle}`}</style>
      <div id = "NoticePage" className='' >
   
                  
       
        <div id = "NoticePageHeader" >
            <Link  to="/notice">
            <div className='back'>
                {Editor.icon({icon:'arrow_back',size:25,lineHeight:30,color:'#000'})}
                목록으로 이동
            </div>
            </Link>

        <h2>공지사항 타이틀</h2>
            </div>
        <div id = "NoticeContent" >
            <div>

            </div>
            <p>
            법원은 최고법원인 대법원과 각급법원으로 조직된다. 국무회의는 정부의 권한에 속하는 중요한 정책을 심의한다. 모든 국민은 통신의 비밀을 침해받지 아니한다.

원장은 국회의 동의를 얻어 대통령이 임명하고, 그 임기는 4년으로 하며, 1차에 한하여 중임할 수 있다. 국회의원은 국회에서 직무상 행한 발언과 표결에 관하여 국회외에서 책임을 지지 아니한다.

정기회의 회기는 100일을, 임시회의 회기는 30일을 초과할 수 없다. 국회는 헌법 또는 법률에 특별한 규정이 없는 한 재적의원 과반수의 출석과 출석의원 과반수의 찬성으로 의결한다. 가부동수인 때에는 부결된 것으로 본다.

모든 국민은 인간으로서의 존엄과 가치를 가지며, 행복을 추구할 권리를 가진다. 국가는 개인이 가지는 불가침의 기본적 인권을 확인하고 이를 보장할 의무를 진다.

헌법에 의하여 체결·공포된 조약과 일반적으로 승인된 국제법규는 국내법과 같은 효력을 가진다. 헌법개정안이 제2항의 찬성을 얻은 때에는 헌법개정은 확정되며, 대통령은 즉시 이를 공포하여야 한다.

형사피해자는 법률이 정하는 바에 의하여 당해 사건의 재판절차에서 진술할 수 있다. 국무총리는 대통령을 보좌하며, 행정에 관하여 대통령의 명을 받아 행정각부를 통할한다.

대통령은 국회에 출석하여 발언하거나 서한으로 의견을 표시할 수 있다. 정당의 설립은 자유이며, 복수정당제는 보장된다. 의무교육은 무상으로 한다. 대법관의 임기는 6년으로 하며, 법률이 정하는 바에 의하여 연임할 수 있다.

정당은 그 목적·조직과 활동이 민주적이어야 하며, 국민의 정치적 의사형성에 참여하는데 필요한 조직을 가져야 한다. 국회는 국가의 예산안을 심의·확정한다.

모든 국민은 건강하고 쾌적한 환경에서 생활할 권리를 가지며, 국가와 국민은 환경보전을 위하여 노력하여야 한다. 대한민국의 주권은 국민에게 있고, 모든 권력은 국민으로부터 나온다.

행정권은 대통령을 수반으로 하는 정부에 속한다. 예비비는 총액으로 국회의 의결을 얻어야 한다. 예비비의 지출은 차기국회의 승인을 얻어야 한다. 체포·구속·압수 또는 수색을 할 때에는 적법한 절차에 따라 검사의 신청에 의하여 법관이 발부한 영장을 제시하여야 한다. 다만, 현행범인인 경우와 장기 3년 이상의 형에 해당하는 죄를 범하고 도피 또는 증거인멸의 염려가 있을 때에는 사후에 영장을 청구할 수 있다.

감사원은 원장을 포함한 5인 이상 11인 이하의 감사위원으로 구성한다. 재의의 요구가 있을 때에는 국회는 재의에 붙이고, 재적의원과반수의 출석과 출석의원 3분의 2 이상의 찬성으로 전과 같은 의결을 하면 그 법률안은 법률로서 확정된다.

국가는 재해를 예방하고 그 위험으로부터 국민을 보호하기 위하여 노력하여야 한다. 지방의회의 조직·권한·의원선거와 지방자치단체의 장의 선임방법 기타 지방자치단체의 조직과 운영에 관한 사항은 법률로 정한다.

법률안에 이의가 있을 때에는 대통령은 제1항의 기간내에 이의서를 붙여 국회로 환부하고, 그 재의를 요구할 수 있다. 국회의 폐회중에도 또한 같다. 타인의 범죄행위로 인하여 생명·신체에 대한 피해를 받은 국민은 법률이 정하는 바에 의하여 국가로부터 구조를 받을 수 있다.

제1항의 지시를 받은 당해 행정기관은 이에 응하여야 한다. 법률은 특별한 규정이 없는 한 공포한 날로부터 20일을 경과함으로써 효력을 발생한다. 국무위원은 국무총리의 제청으로 대통령이 임명한다.

국회의원이 회기전에 체포 또는 구금된 때에는 현행범인이 아닌 한 국회의 요구가 있으면 회기중 석방된다. 외국인은 국제법과 조약이 정하는 바에 의하여 그 지위가 보장된다.

이 헌법은 1988년 2월 25일부터 시행한다. 다만, 이 헌법을 시행하기 위하여 필요한 법률의 제정·개정과 이 헌법에 의한 대통령 및 국회의원의 선거 기타 이 헌법시행에 관한 준비는 이 헌법시행 전에 할 수 있다.

대통령은 조국의 평화적 통일을 위한 성실한 의무를 진다. 국무위원은 국정에 관하여 대통령을 보좌하며, 국무회의의 구성원으로서 국정을 심의한다. 모든 국민은 고문을 받지 아니하며, 형사상 자기에게 불리한 진술을 강요당하지 아니한다.
            </p>
        </div>

      </div>
      </Fragment>
    );

}

export default NoticePage;



const PageStyle = `

#NoticePage{
    display :flex;
    flex-direction: column;
    width : 100%;
    min-height : 100vh;
    color : #fff;
    background-color : #202020;
    font-family: '프리텐다드ExtraBold';
    align-items: center;

}

#NoticePageHeader {
    max-width : 1200px;
    width : 100%;
    height: 200px;
    margin-top : 50px;
    background-color : #fff;
    color : #202020;
    display :flex;
    flex-direction: column;
    justify-content: space-between;
}

#NoticePageHeader h2 {
    font-size : 40px;
    margin-left : 30px;
}

#NoticePage a {
    text-decoration: none;
}

#NoticePageHeader .back{
    display: flex;
    line-height: 30px;
    margin-left : 30px;
    margin-top : 30px;

}   

#NoticeContent{
    max-width : 1200px;
    width : 100%;
    margin-top : 20px;
    display : flex;
    line-height: 1.5;
}
#PromotionContent .NoticeContent{
    max-width : 1200px;
    width : 100%;
    height: 100px;
    margin : 20px 0px;
    display :flex;
    border-bottom : solid 1px #fff;
    justify-content: space-between;
    color : #C0C0C0;
}

#PromotionContent .NoticeContent:hover{
    color : #fff;
}

#PromotionContent .NoticeTitle{
    flex : 3;
    height: 80px;
    display :flex;
    flex-direction: column;
    justify-content: end;
    font-size : 30px;
    padding : 0px 10px;
    box-sizing: border-box;
}
#PromotionContent .NoticeDate{
    flex : 1;
    height: 80px;
    display :flex;
    flex-direction: column;
    justify-content: end;
    font-size : 20px;
    padding : 0px 10px;
    align-items: end;
    box-sizing: border-box;
}

#PromotionPage #PromotionContent{
    position: relative;
    display :flex;
    flex-direction: column;
    width : 100%;

}




`;