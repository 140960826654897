import React, { Component , Fragment } from 'react';
function preset(preset,self,data,style){
    let fullsize = data.size.height
    let width = data.size.width
    let profile = self.state.profile
    let dataList = []
    let dataField =''

    let opacity= Math.round(data.style.fillOpacity/100 * 255).toString(16)
    if (opacity.length==1){ opacity= 0+opacity }

    let header_opacity= Math.round(data.style.header_fillOpacity/100 * 255).toString(16)
    if (opacity.length==1){ opacity= 0+opacity }

    switch (preset) {
        case 'normal':
            if(fullsize/100*20<data.style.header_fontSize){
                style.header.fontSize=fullsize/100*20+'px'
            }
            if(fullsize/100*30<data.style.fontSize){
                style.body.fontSize=fullsize/100*30+'px'
            }
            return(
                <div className={"object "+self.props.widgetName+"_preset_"+preset} ref={self.widget} style={{...style.object,}} >
                    <div className="NewsHeader" style={{...style.headerBox,}}>
                        <div style={{...style.header,}}>
                            {data.value.header_text?data.value.header_text:'타이틀을 입력해주세요'}
                        </div>
                    </div>
                    <div className="NewsBody" style={{...style.bodyBox,}}>
                        <pre  style={{...style.body,}}>
                            {data.value.text?data.value.text:'본문을 입력해주세요'}
                        </pre>
                    </div>
                </div>
            )
        default:
            return(
                <div className={"object unknownPreset"} ref={self.widget} style={{...style.object}} >
                    <p>알수없는 프리셋</p>
                </div>
                )
    }
}
export function data(self,style) {
    return preset(self.props.data.preset,self,self.props.data,style)
}

