////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { useState, useEffect, useContext, Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widgetEdit.scss"


// 컴포넌트 연결
import GuideBalloon from 'components/common/form/GuideBalloon'// 가이드 벌룬
import ImageButtonModule from 'components/Edit/editModule/ImageButtonModule'; // 이미지 버튼을 눌러 여러 옵션중 값을 정합니다.
// import Show from '../show/Show'; // 메뉴 헤더

// 이미지 연결
import logoW from 'assets/logoW.svg';

// 서비스 연결
import * as Editor from 'service/edit/EditService'; 
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
function BanPickModule({index,target,modify,keyList,mode,moduleState,removeModule}) {
    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////

    // set

    // state

    // rel 

   ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////
    useEffect(() => {
        // 여기에 컴포넌트가 마운트되었을 때 실행할 코드를 작성합니다.
        return () => {
        // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
        };
    }, []);
    
    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////
    console.log('target',target)
    return (
        <Fragment>
            {/* CSS 는 상위 컴포넌트인 ScoreboardEditForm 에 있습니다. */}
        <div className={'BanPickModule '+target?.type}>
            <div className='BanPickModuleHeader '>

                <p className='BanPickModuleIndex'> {index+1}</p>
                <div className='BanPickTeam'> 
                    <button className={target.team=='left'?'select':''} onClick={()=>modify(index,'team','left')}>
                    {Editor.icon({icon:'keyboard_arrow_left',size:25,lineHeight:30})}
                    </button>
                    <button className={target.team=='right'?'select':''}  onClick={()=>modify(index,'team','right')}>
                    {Editor.icon({icon:'keyboard_arrow_right',size:25,lineHeight:30})}
                    </button>
                </div>

                <div className='BanPickTimer'> 
                    <button className={target.timer==30?'select':''}   onClick={()=>modify(index,'timer',30)}>
                    <p>{Editor.icon({icon:'30fps',size:20,lineHeight:30})}</p>
                    </button>
                    <button className={target.timer==60?'select':''}   onClick={()=>modify(index,'timer',60)}>
                    <p>{Editor.icon({icon:'60fps',size:20,lineHeight:30})}</p>
                    </button>
                    <button className={target.timer==0?'select':''}   onClick={()=>modify(index,'timer',0)}>
                    <p>{Editor.icon({icon:'all_inclusive',size:20,lineHeight:30})}</p>
                    </button>
                </div>

                

                <div className='BanPickModuleDel' onClick={()=>removeModule(index)}>
                {Editor.icon({icon:'delete',size:25,lineHeight:30})}
                </div>
            </div>
            {/* <div className='BanPickModuleInfo'>
                <ImageButtonModule value={target.team} title={"팀"} type = {'team'} data={[{val:'left',icon:'first_page',text:'text'},{val:'right',icon:'last_page',text:'text'},]} change={(type,value)=>console.log()}/> 
            </div>
       
            <div className='BanPickTimer'>
                <p>제한시간</p> <input className='BanPickTimerInput' value={target?.teamPoint??''} max={99} placeholder='0' pattern="^\d{2}$" type='number'  onChange={(e)=>modify('teamPoint',Number(e.target.value)??0)}  />
            </div> */}


        </div>
        </Fragment>
    )
}
export default BanPickModule;

