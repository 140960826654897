import React, { Component , Fragment } from 'react';
// 서비스 연결
import * as Utility from 'service/other/Utility'; 
import * as WidgetData from 'service/value/WidgetData' ; 

////////////////////////////////////////////////////////
// Particles preset 파티클 프리셋
////////////////////////////////////////////////////////

function preset(preset,self,data,style){
    let fullsize = data.size.height
    let width = data.size.width
    let profile = self.state.profile
    let dataList = []
    let dataField =''

    let opacity= Math.round(data.style.fillOpacity/100 * 255).toString(16)
    if (opacity.length==1){ opacity= 0+opacity }

    let header_opacity= Math.round(data.style.header_fillOpacity/100 * 255).toString(16)
    if (opacity.length==1){ opacity= 0+opacity }


    switch (preset) {
        case 'normal':
            // self.ctx.fillRect(25, 25, 100, 100);
            return(
                <div className={"object "+self.props.widgetName+"_preset_"+preset} ref={self.widget} style={{...style.object}}>
                    <canvas width={width} height={fullsize} className='canvas' ref={self.canvas} />
                </div>
            )
        default:
            return(
                <div className={"object unknownPreset"} ref={self.widget} style={{...style.object}} >
                    <p>알수없는 프리셋</p>
                </div>
                )
    }
}
export function data(self,style) {
    return preset(self.props.data.preset,self,self.props.data,style)
}

////////////////////////////////////////////////////////
// Particles preset 파티클 프리셋
////////////////////////////////////////////////////////


export function presetAnimate(self,preset){
    return normal_canvas_init(self,self.props.data)
}

function normal_canvas_init (self,data){
    self.particles = []

    for (let index = 0; index < data.value.particleCount; index++) {

        self.particles[index] =  new Particle(
            index,{
                // 캔버스의 크기
                drawSizeWidth: data.size.width,
                drwaSizeHeight: data.size.height,
                particleSpeed : data.value.particleSpeed,
                sizeMin : data.value.particleSize/2,
                sizeMax :  data.value.particleSize,
                particleStyle :  data.value.particleStyle,
            }
        )
    }

    return normalpresetDraw(self,data);
}



export function normalpresetDraw(self,data){
    self.ctx.clearRect(0,0,self.props.data.size.width,self.props.data.size.height)
    for (let index = 0; index < self.particles.length; index++) {
        const particle = self.particles[index]
        particle.draw(self)
    }
    let rel =  requestAnimationFrame(() => normalpresetDraw(self,data));
    self.animationRel = rel
}


export function normal_canvas_set (self,data){
    for (let index = 0; index < data.value.particleCount; index++) {
        self.particles[index].redraw({
            drawSizeWidth: Number(data.size.width),
            drwaSizeHeight: Number(data.size.height),
            particleSpeed : Number(data.value.particleSpeed),
            sizeMin : Number(data.value.particleSize)/2,
            sizeMax :  Number(data.value.particleSize),
            particleStyle : data.value.particleStyle,
        })
    }
}


class Particle {
    constructor(index,option={}){
        
        this.index = index
       
        this.sizeMin = option?.sizeMin ? option.sizeMin : 10;
        this.sizeMax = option?.sizeMax ? option.sizeMax : 30;
        this.drawSizeWidth = option?.drawSizeWidth ?  option?.drawSizeWidth: 0;
        this.drwaSizeHeight = option?.drwaSizeHeight ? option?.drawSizeWidth: 0;

        this.particleSpeed = option?.particleSpeed ? option?.particleSpeed: 1;
        this.particleStyle = option.particleStyle

        this.randomSpeed = Utility.random({min:1,max:3})

        this.reset()
    }

    

    reset(){
        let size = Utility.random({min:this.sizeMin,max:this.sizeMax,type:'int'})
        this.speed = this.randomSpeed * (this.particleSpeed/10)

        this.x = Utility.random({min:0,max:this.drawSizeWidth,type:'int'})
        this.y =  Utility.random({min:-this.drwaSizeHeight,max:-size,type:'int'})
        this.width = size
        this.height = size

        const Asset = new Image()
        const AssetError = new Image()
        AssetError.src = "https://overlap.s3.ap-northeast-1.amazonaws.com/defult/logoW.svg"
        this.AssetError = AssetError

        let particleAssets = WidgetData.get('Particles')
        try {
            let assetsLen = Object.keys(particleAssets[this.particleStyle]?.images).length
            if(assetsLen){
                Asset.src =  particleAssets[this.particleStyle].images[Utility.random({min:0,max:assetsLen-1,type:'int'})]
                this.style = Asset
            }
        } catch (error) {
            Asset.src = "https://overlap.s3.ap-northeast-1.amazonaws.com/defult/logoW.svg"
            this.style = Asset
        }



    }

    redraw(option={}){

        this.drawSizeWidth = option?.drawSizeWidth ?  option?.drawSizeWidth: 0;
        this.drwaSizeHeight = option?.drwaSizeHeight ? option?.drawSizeWidth: 0;

        this.particleSpeed = option?.particleSpeed ? option?.particleSpeed: 1;

        
        this.speed = this.randomSpeed * (this.particleSpeed/10)

        if(this.sizeMax!=option?.sizeMax){
            this.sizeMin = option?.sizeMin ? option.sizeMin : 10;
            this.sizeMax = option?.sizeMax ? option.sizeMax : 30;


            let size = Utility.random({min:this.sizeMin,max:this.sizeMax,type:'int'})

            this.width = size
            this.height = size
        }

        if(this.particleStyle!=option?.particleStyle ){
            this.particleStyle = option?.particleStyle
            const Asset = new Image()
            let particleAssets = WidgetData.get('Particles')
            try {
                let assetsLen = Object.keys(particleAssets[this.particleStyle]?.images).length
                if(assetsLen){
                    Asset.src =  particleAssets[this.particleStyle].images[Utility.random({min:0,max:assetsLen-1,type:'int'})]
                    this.style = Asset
                }
            } catch (error) {
                Asset.src = "https://overlap.s3.ap-northeast-1.amazonaws.com/defult/logoW.svg"
                this.style = Asset
            }
    
    
        }
    }

    draw(self){

        // this.height = this.heigh + 1 
        this.y = this.y + this.speed
        // this.x = Math.sin(this.y)
       
        if (this.y > this.drwaSizeHeight){
            this.reset()
        }
        try {
            self.ctx.drawImage(
                this.style,
                this.x, 
                this.y, 
                this.width, 
                this.height
            )
        } catch (error) {
            self.ctx.drawImage(
                this.AssetError,
                this.x, 
                this.y, 
                this.width, 
                this.height
            )
        }

    }
}