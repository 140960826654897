////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, {useState, useEffect, useRef } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { Route, Link } from 'react-router-dom';
import { useHistory } from "react-router";
// 라이브러리 
import { useResizeDetector } from 'react-resize-detector';// 컴포넌트의 사이즈 감지
import html2canvas from 'html2canvas'; // 엘리먼트를 이미지로 변경하기
// 설정 파일
import * as config from 'config/OverlapConfig'

// [프로바이더]
import EditContext from 'service/context/EditContext' ; 

// [컴포넌트] 위젯 
// import MiniView from "components/viewer/MiniView"
// [리덕스]스토어 연결
// import store from "store";

// SASS&CSS 연결
// import 'sass/login.scss'

// 이미지 연결
// import logoW from 'assets/logoW.svg';


// 서비스 연결
import * as DeCoder from 'service/engine/DeCoder' ; 
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

/** [디스플레이] 
 * 프리뷰용도로 제작된 디스플레이 입니다.
 * @param {*} pageList 오버랩의 페이지 객체
 * @param {number|null} previewWidth 컴포넌트의 가로 사이즈
 * @param {number|null} previewHeight 컴포넌트의 세로 사이즈
 * @param {number} referenceWidth 디스플레이의 해상도
 * @param {number} referenceHeight 디스플레이의 해상도
 * @param {*} view 오버랩의 뷰 객체
 * @param {*} option 디스플레이에 적용할 옵션
 * @returns 
 */


const PreviewDisplay =  React.memo(function PreviewDisplay({ pageList = null, pageUuid=null, page = null, previewWidth = null, previewHeight = null, referenceWidth, referenceHeight, view = null, option = {} }) {
    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////

    // state
    const [PageIndex, setPageIndex] = useState(0);
   
    // ref
    const { width, height, ref } = useResizeDetector(
        
    );
    // const displayRef = useRef(null);

    ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////


    ////////////////////////////////////////////////////////
    // Fuction
    ////////////////////////////////////////////////////////

    const findPage = (uuid) => {

            if(pageList==null){
                return []
            }


            let target = pageList?.filter(item => item?.uuid === uuid);

            // 원하는 페이지가 없는경우 (!pageUuid) 페이지 리스트의 첫 페이지를 임의로 가져옵니다.


            if(target.length == 0){
                target = pageList
            }
            return target?.[0];
    }



    const DisplayCore = () => {
        if(zoom){
            return DeCoder.run({'page_widget':findPage(pageUuid)?.page_widget},'view',{animation:"mountStart"})
        }
    }

    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////
    const zoom = (width / referenceWidth)??0;

    const displaySizeControl = {
        height: '100%',
        aspectRatio:  `${referenceWidth} / ${referenceHeight}`,
      };

    const zoomControl = {
        transform:  `scale(${zoom})`,
    };

    return (
        <div className='PreviewDisplayContainer' >
        <style jsx>{`${DisplayStyle}`}</style>
            <div style={displaySizeControl} className='PreviewDisplay transparent-background' ref={ref} >
                <div  style={zoomControl} className='DisplayCore'  >
                    {DisplayCore()}
                </div>
            </div>
        </div>
    );
  });

  export default PreviewDisplay;
////////////////////////////////////////////////////////
// css 부분
////////////////////////////////////////////////////////
const DisplayStyle = `
    .PreviewDisplayContainer {
        width: 100%;
        height: 100%;
        display:flex;
        justify-content: center;
        align-items: center;
        pointer-events: none;
    }
    .PreviewDisplayContainer .PreviewDisplay{

        border-radius: 8px;
        box-sizing: border-box;
        box-shadow: 0 6px 16px rgba(0,0,0,.15);
        overflow: hidden;

        
    }
    .PreviewDisplayContainer .DisplayCore{
        width: 100%;
        height: 100%;
        transform-origin: 0% 0%; 
     
    }

    .PreviewDisplayContainer .transparent-background {
        background-image: linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff),
        linear-gradient(45deg, #fff 25%, #ccc 25%, #ccc 75%, #fff 75%, #fff);
        background-size: 8px 8px;
        background-position: 0 0, 4px 4px;
      }
 
`;


