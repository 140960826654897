////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widgetEdit.scss"


// 컴포넌트 연결
import TextModule from '../editModule/TextModule'; // 텍스트 모듈
import SentenceModule from '../editModule/SentenceModule';
import TextSizeModule from '../editModule/TextSizeModule'; // 텍스트 크기 모듈
import FontModule from '../editModule/FontModule'; // 폰트 종류 모듈
import ColorModule from '../editModule/ColorModule'; // 색상 모듈
import SwitchModule from '../editModule/SwitchModule'; // 체크박스 모듈
import ImageButtonModule from '../editModule/ImageButtonModule';
import RangeModule from '../editModule/RangeModule'; //범위 모듈 
// 서비스 연결

// 이미지 연결

import leftButton from 'assets/sidebar/text/left.png';
import centerButton from 'assets/sidebar/text/center.png';
import rightButton from 'assets/sidebar/text/right.png';
import justifyButton from 'assets/sidebar/text/justify.png';

import upperButton from 'assets/sidebar/text/upper.png';
import midButton from 'assets/sidebar/text/mid.png';
import lowerButton from 'assets/sidebar/text/lower.png';

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class NewsCaptionEdit extends Component {
    
    state = {
    }

    change = (name,value) =>{
        this.props.callBack('style',name,value)
    }
    valueChange = (name,value) =>{
        this.props.callBack('value',name,value)
    }

    render() { 

        const Style = {
            label: {
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
            },
            textarea : {
                width: '100%',
                resize: 'none'
            }

        }
        return (
            <Fragment>
            <fieldset className="EditModuleField" style={Style.fieldset} >
                <legend style={Style.legend} > 타이틀 </legend>
                <SentenceModule value={this.props.data.value.header_text} placeholder={"타이틀을 입력해주세요"} title="타이틀" type ="header_text" change={this.valueChange} callBackMode="onblur"/>
                <ImageButtonModule value={this.props.data.style.header_textAlign} type ="header_textAlign" data={[{val:'left',image:leftButton,text:'text'},{val:'center',image:centerButton},{val:'right',image:rightButton},{val:'justify',image:justifyButton}]} title="좌우 정렬"  change={this.change}/>
                {/* <ImageButtonModule value={this.props.data.value.textAlign} type ="verticalAlign" data={[{val:'flex-start',image:upperButton},{val:'center',image:midButton},{val:'flex-end',image:lowerButton}]} type="verticalAlign" title="상하 정렬"  change={this.change}/> */}
                <ColorModule value={this.props.data.style.header_color} type ="header_color" change={this.change}/>
                <TextSizeModule value={this.props.data.style.header_fontSize} type ="header_fontSize" change={this.change}/>
                <FontModule  value={this.props.data.style.header_fontFamily} type ="header_fontFamily" change={this.change}/>
                <ColorModule value={this.props.data.style.header_fill} type ="header_fill" title="배경색" change={this.change} />
                <RangeModule value={this.props.data.style.header_fillOpacity} type ="header_fillOpacity" title="배경투명도" valueView="%" min="0" max="100" change={this.change} />
            </fieldset>
            <fieldset className="EditModuleField" style={Style.fieldset} >
                <legend style={Style.legend} > 본문 </legend>
                <TextModule value={this.props.data.value.text} fontFamily={this.props.data.style.fontFamily} title="본문" type ="text" placeholder="본문을 입력해주세요"  change={this.valueChange}/>
                <ImageButtonModule value={this.props.data.style.textAlign} type ="textAlign" data={[{val:'left',image:leftButton,text:'text'},{val:'center',image:centerButton},{val:'right',image:rightButton},{val:'justify',image:justifyButton}]} title="좌우 정렬"  change={this.change}/>
                {/* <ImageButtonModule value={this.props.data.value.textAlign} type ="verticalAlign" data={[{val:'flex-start',image:upperButton},{val:'center',image:midButton},{val:'flex-end',image:lowerButton}]} type="verticalAlign" title="상하 정렬"  change={this.change}/> */}
                <ColorModule value={this.props.data.style.color} type ="color" change={this.change}/>
                <TextSizeModule value={this.props.data.style.fontSize} type ="fontSize" change={this.change}/>
                <FontModule  value={this.props.data.style.fontFamily} type ="fontFamily" change={this.change}/>
                <ColorModule value={this.props.data.style.fill} type ="fill" title="배경색" change={this.change} />
                <RangeModule value={this.props.data.style.fillOpacity} type ="fillOpacity" title="배경투명도" valueView="%" min="0" max="100" change={this.change} />
            </fieldset>
            </Fragment>
        );
    }
}

////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    // screenData : state.screenData,
})

function mapDispatchToProps(dispatch){
    return {
    //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(NewsCaptionEdit);
  