////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, {useState, useEffect, useRef } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { Route, Link } from 'react-router-dom';
import { useHistory } from "react-router";
// 라이브러리 
import { useGoogleLogin } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google'// 구글 로그인
// 설정 파일
import * as config from 'config/OverlapConfig'

import { withRouter } from 'react-router-dom';

import * as apiAccount from 'service/api/apiAccount'

// [컴포넌트] 위젯 
import MiniView from "components/viewer/MiniView"
import StoreUpload from "components/store/StoreUpload"
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import 'sass/login.scss'

// 이미지 연결
import logoW from 'assets/logoW.svg';
import youtube from 'assets/main/lobby/social/youtube.png';
import twitch from 'assets/main/lobby/social/twitch.png';
import afreeca from 'assets/main/lobby/social/afreeca.png';
import facebook from 'assets/main/lobby/social/facebook.png';
import naver from 'assets/main/lobby/social/naver.png';


// 서비스 연결
import * as overlap from 'service/api/overlap';
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

function SpaceModuleServiceContainer({service,icon}) {
      ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////
    // state
    const [Mode, setMode] = useState('list');
    // ref
    // const { width, height, ref } = useResizeDetector();
    // const displayRef = useRef(null);


    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////


    return (
        <div className='SpaceModuleServiceContainer' >
        <style jsx>{`${SpaceModuleStyle}${ContainerStyle}`}</style>
        <div>
        <div className='serviceBox'>
                서비스명
                <div className='serviceImage'>
                    {/* <img src={youtube}/> */}
                </div>
                <div className='serviceTitle'>
                    {service}
                </div>
            </div>
        </div>
    </div>
    );
  }

  export default SpaceModuleServiceContainer;
////////////////////////////////////////////////////////
// css 부분
////////////////////////////////////////////////////////
const SpaceModuleStyle = `


`;

const ContainerStyle = `
    .SpaceModuleServiceContainer {
        width : 600px;
        height : 200px;
        overflow : hidden;
        background : #444;
        border-radius : 10px;
        margin : 20px;
        
    }
   
`;