////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widgetEdit.scss"


// 컴포넌트 연결
import SizeModule from '../editModule/SizeModule';//위치 모듈

// 서비스 연결
import * as Model from 'service/value/Model' ; 
import * as EnCoder from 'service/engine/EnCoder'; 
import * as Editor from 'service/edit/EditService'; 
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class SizeEdit extends Component {
    
    state = {
        editorToggle : true
    }

    change = (name,value) =>{
        this.props.callBack('size',name,value)
    
    }

    render() { 
        const Style = {
            label: {
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
            },
            textarea : {
                width: '100%',
                resize: 'none'
            }
        }        
        let widthValue = ''
        let heightValue = ''
        if(this.props.data.size.width){
            widthValue = this.props.data.size.width
            if(String(widthValue).indexOf('%')!=-1 ){
                widthValue = EnCoder.percentValue(widthValue,'width') 
            }
        }
        if(this.props.data.size.height){
            heightValue = this.props.data.size.height
            if(String(heightValue).indexOf('%')!=-1 ){
                heightValue = EnCoder.percentValue(heightValue,'height') 
            }
        }
        return (
            <section className='SizeEdit'>
                {Editor.WidgetEditTitle(this,{title:'크기',toggle:false,icon:'open_in_full'})}
                <div className={this.state.editorToggle?'editContainer containerOpen':'editContainer containerClose'}>
                <article className="EditPart MediaSourceModule" >
                    <SizeModule value={widthValue} max={Infinity} title="길이" type ="width" change={this.change}/>
                    <SizeModule value={heightValue} max={Infinity} title="높이" type ="height" change={this.change}/>
                </article>
                </div>
            </section>
        );
    }
}

////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////

const mapStateToProps = (state) => ({
    // exemple : state.exemple,
})

function mapDispatchToProps(dispatch){
    return {
    //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(SizeEdit);
  