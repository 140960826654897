////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { Route, Link } from 'react-router-dom';
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결


// 이미지 연결
import logoW from 'assets/logoW.svg';
import logo from 'assets/logo.svg';
import twitchLogo from 'assets/main/twitchlogo.png';
import edit from 'assets/main/edit.png';
import bot from 'assets/main/bot.png';
import control from 'assets/main/control.png';
import banner1 from 'assets/main/banner1.png';

// 컴포넌트 연결
import SpaceGuideList from "components/Lobby/space/module/SpaceGuideList"


// 서비스 연결
import * as guide from 'service/event/guide';
import * as Space from 'service/api/space' ; 
import * as overlap from 'service/api/overlap';
import * as view from 'service/api/view';
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class SpaceDashboard extends Component {
    
    state = {
        userId : '',
        screenData : [{"type":"Text","version":1,"uuid":"cd0155f64c81477dad4511694c54a203","alias":null,"data":{"position":{"show":true,"x":"555","y":"322"},"style":{"textAlign":"center","fontSize":"200"},"size":{},"value":{"text":"테스트중!!!!!!!!!!"},"preset":null,"display":{},"meta":{}},"animation":{"mountStart":null,"mount":null,"mountEnd":null}}],
        zoom : 0,
        infoData : null,
        spaceList : null,
        viewList: null,
        overlapList : null
    }

    async componentDidMount () {
        const viewList = await view.list(this.props.space?.id)??[];
        const overlapList = await overlap.list(this.props.space?.id)??[];
        console.log('viewList',viewList,overlapList);
        this.setState({viewList,overlapList})

    }

    constructor(props) {
        super(props);
        this.broadcast = React.createRef();
      }

    componentDidUpdate(prevProps){
        
    }

    copy = async (value) => {
        if (navigator.clipboard) {
           await navigator.clipboard.writeText(value).then(() => {
                alert('복사되었습니다. 이제 붙여넣으세요')
            });
          } else {
            alert('구형브라우저를 사용중이라 복사에 실패하였습니다.')
          }
    }

    addSpace = async (title)=>{
   

    }

    getSpace = async ()=>{

    }

    spaceList = ()=>{

    }


    projectEmpty = () => {

        return (

            <div>

            </div>
        )

    }


    channelEmpty = () => {

    }


    render() { 

        console.log('viewList',this.state.viewList,this.state.overlapList);
        return (
            <div className = "Lobby_innerPage" >
                <style jsx>{`${ContainerStyle}`}</style>
                <div className = "Lobby_common Lobby_SpaceOverlap" ref={this.spaceRef} >
                    <div className='space_overlapList'>
                        <div className='spaceBox overlapList'>
                            {/* <SpaceGuideList guideList={guide.dashBoardGuide}/> */}
                        </div>

                    </div>
                    <div id="SpaceContents">
                        <div id='SpaceProject'>

                            <div id='SpaceProjectHeader'>
                                <div className='HeaaderImage'>
                                <img className='icon' width={100} height={100} src={logoW}/>
                                </div>
                                <p className='HeaaderTitle'>
                                최근에 사용한 프로젝트
                                </p>
                            </div>

                
                            {/* {this.state.viewList?.length == 0? '생성된 프로젝트가 없습니다.': '생성된 프로젝트가 없습니다.'}
                            {this.state.viewList?.length } */}
                        </div>

                        <div id='SpaceChannel'>

                        <div id='SpaceChannelHeader'>
                                <div className='HeaaderImage'>
                                <svg className="broadcastScreenLive" width="100px" height="100px" xmlns="http://www.w3.org/2000/svg" version="1.1">
                                <circle cx="50" cy="50" r="30" fill="gray" stroke="#fff" strokeWidth="10" filter="url(#shadow)" />
                            </svg>
                                </div>
                                <p className='HeaaderTitle'>
                                최근에 사용한 채널
                                </p>
                            </div>

                            {/* {this.state.overlapList?.length == 0? '생성된 프로젝트가 없습니다.': '생성된 프로젝트가 없습니다.'}
                            {this.state.overlapList?.length } */}
                        </div>
                    </div>

           
                
                </div>
            </div>
        )
    }
}
////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////

const ContainerStyle = `

    .space_Info{
        width: 600px;
        height: 300px;
        background-color : red;
        
    }

    #SpaceContents{
        margin : 20px 20px 0px 20px;
        display :flex;
        width: 100%;
        height: 300px;
    }
    #SpaceProject{
        width: 600px;
        height: 300px;
        margin-right : 20px;
        background-color : #333;
        border-radius: 15px;
    }

    #SpaceChannel{
        width: 300px;
        height: 300px;
        margin-right : 20px;
        background-color : #333;
        border-radius: 15px;
    }
    
    #SpaceProjectHeader{
        width: 100%;
        height: 100%;
        display : flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        overflow:hidden;

        background-color : #444;

    }
    #SpaceProjectHeader .HeaaderImage{
        margin-bottom : 20px;
    }

    #SpaceProjectHeader .HeaaderTitle{
        font-size : 20px;
    }

    #SpaceChannelHeader{
        width: 100%;
        height: 100%;
        display : flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        overflow:hidden;

        background-color : #444;
    }
    #SpaceChannelHeader .HeaaderImage{
        margin-bottom : 20px;
    }

    #SpaceChannelHeader .HeaaderTitle{
        font-size : 20px;
    }

    @keyframes moveBox {
        0%{
            height: 100%;
            background-color : #333;
        }
        100% {
            width: 100%;
            height: 50px;
        
        }
    }

`;
////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////


  // export default Login;
  export default SpaceDashboard;
  