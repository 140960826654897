////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment, Profiler } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { BrowserRouter as Router, Route } from 'react-router-dom';
// [리덕스]스토어 연결
import store from "store";

// [프로바이더]
import ViewProvider from 'service/provider/ViewProvider';

// SASS&CSS 연결
import "sass/viewPage.scss"


// 컴포넌트 연결

import ViewScreen from 'components/ChannelPage/screen/ViewScreen'; // 구형 디스플레이
import ChannelField from 'components/ChannelPage/ChannelField'; // 구형 디스플레이
// import BroadcastDisplay from 'components/display/BroadcastDisplay'; // 신형 디스플레이
// 서비스 연결
import * as DeCoder from '../../service/engine/DeCoder' ; 
import * as Screen from '../../service/api/screen' ; 
import * as view from 'service/api/view';
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class ViewPage extends Component {
    
    state = {
        screenData : '',
        view : null,
        height:0,
        width:0,
        zoom: 0,
    }
    constructor(props) {
        super(props);
        // this.myRef = React.createRef();
        this.state = {
            selectObjectTracker : this.props.selectTemplate,
            uuid : this.props?.location?.state?.uuid??null,
            overlapID : this.props?.location?.state?.id??null,
            overlapToken : null,
        };
      }

    componentDidUpdate(prevProps){
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-R99737TJCR", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
        }
    }

    async componentDidMount() {
        // const uuid = this.props.match.params.uuid;
        // let viewUUID = null;
        // if(uuid){
        //     viewUUID = await view.get(view.uuidConverter('db',uuid));
        // }
        // console.log('viewUUID',viewUUID);
        // this.setState({
        //     view : viewUUID,
        //     uuid : uuid
        // })
    }
     componentWillUnmount(){
        // window.removeEventListener('resize', this.screenResize)
    //    clearInterval(this.state.screenRefresh)
       
     }

    render() { 
       
        return (
            <Fragment>
            <ViewProvider uuid={view.uuidConverter('db',this.props.match.params.uuid)}>
                <div id = "ViewPage">
                    <ChannelField/>
                </div>
            </ViewProvider>
            </Fragment>
        );
    }
}
////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////


  export default ViewPage;
  