import React, { Component , Fragment } from 'react';
// 서비스 연결
import * as Utility from 'service/other/Utility'; 
import * as WidgetData from 'service/value/WidgetData' ; 

////////////////////////////////////////////////////////
// Timer preset 파티클 프리셋
////////////////////////////////////////////////////////
function preset(preset,self,data,style){


    const Height =data?.size?.height;
    const Width = data?.size?.width;
    const MinSize = Width<Height?Width:Height;
    const Ref = self.widget;
    const Ctx = self.canvas;
    const Name = self.props.widgetName;

    switch (preset) {

        case 'static':
            return(
                <div className={"object "+Name+"_preset_"+preset} ref={Ref} style={{...style.object}} >
                    <canvas ref={Ctx} width={Width} height={Height} />
                </div>
            )
        case 'liveCircle1':
            return(
                <div className={"object "+self.props.widgetName+"_preset_"+preset} ref={self.widget} style={{...style.object}} >
                    <img src="https://overlap.s3.ap-northeast-1.amazonaws.com/widget/LiveIcon/liveCircle1.png"/>
                </div>
            )
        case 'liveSpeechIcon1':
            return(
                <div className={"object "+self.props.widgetName+"_preset_"+preset} ref={self.widget} style={{...style.object}} >
                    <img src="https://overlap.s3.ap-northeast-1.amazonaws.com/widget/LiveIcon/liveSpeechIcon1.png"/>
                </div>
            )
        case 'liveSquare1':
            return(
                <div className={"object "+self.props.widgetName+"_preset_"+preset} ref={self.widget} style={{...style.object}} >
                    <img src="https://overlap.s3.ap-northeast-1.amazonaws.com/widget/LiveIcon/liveSquare1.png"/>
                </div>
            )
        case 'liveSquare2':
            return(
                <div className={"object "+self.props.widgetName+"_preset_"+preset} ref={self.widget} style={{...style.object}} >
                    <img src="https://overlap.s3.ap-northeast-1.amazonaws.com/widget/LiveIcon/liveSquare2.png"/>
                </div>
            )
        default:
            return(
                <div className={"object unknownPreset"} ref={self.widget} style={{...style.object}} >
                    <p>알수없는 프리셋</p>
                </div>
                )
    }
}
export function data(self,style) {
    return preset(self.props.data.preset,self,self.props.data,style)
}


////////////////////////////////////////////////////////
// Timer Animation | 애니메이션
////////////////////////////////////////////////////////


export function  getTimer (data, cache) {



    const timerType = data.value.timerType
    const Timer = {
        start : cache?.atStart??null,
        end : cache?.atEnd??0,
        pause : cache?.pauseTime??0,
        state : cache?.timerState??'stop',
        fps : 300
    }

    const WidgetTime =  data.value.time;
    let playTime = WidgetTime;

    // console.log('구해진 시간 테스트:','Timer',Timer,'timerType',timerType,'WidgetTime',WidgetTime );
    switch (timerType) {
        case 'start':
            if(Timer.state=='play') {
                playTime = (WidgetTime - Math.floor((Date.now() - Timer.start) / 1000))
            }
            if(Timer.state=='pause') {
                playTime = (WidgetTime - Math.floor((Timer.pause-Timer.start) / 1000))
            }
            if(Timer.state=='stop') {
                playTime = (WidgetTime)
            }
            break;

        case 'end':
            const now = new Date(); // 현재 시간
            const startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate()); // 오늘 자정
            const secondsElapsed =  Math.floor((now.getTime() - startOfDay.getTime()) / 1000); // 밀리초 차이를 초로 변환       

            if(Timer.state=='play') {
                playTime =(Timer.end-secondsElapsed)
            }
            if(Timer.state=='pause') {
                playTime =(Timer.end-secondsElapsed)
            }
            if(Timer.state=='stop') {
                playTime = 0
            }
            break;
    
        default:
            break;
    }

    // console.log('[테스트 데이터]','체크 상태',Timer.end,secondsElapsed);
    playTime = playTime<0 ? 0 : playTime
    
    return playTime;

}


export function canvasInit({current,ctx,setAnimationID,data,cache,fps,self}){


    if(current==null){
        return
    }

    ctx = current.getContext("2d");


    // -- 애니메이션 속도를 제어합니다.

    const Now  = Date.now();

    const Refresh = {
        start : Now,
        last : 0,
        fps : 300
    }


    // data. 

    // -- 캔버스를 초기화 합니다.. -- 


    // -- 처음 생성시 값이 변하지 않는것들
    const Init = {}

    Init['height'] = Number(data?.size?.height);
    Init['width'] = Number(data?.size?.width);

    Init['fontFamily'] = data?.style?.fontFamily;
    Init['color'] = data?.style?.color;
    Init['preset'] = data?.preset;

    const Cache = cache()
    const SetTime = getTimer(data,Cache);

    const Hours = Math.floor(SetTime / 3600)
    const Minutes = Math.floor((SetTime % 3600) / 60)
    const Seconds = SetTime % 60
    



    switch (Init.preset) {
        case 'static':
            const currentTime = (Hours!=0?Hours+':':'')+(Minutes>=10?Minutes:'0'+Minutes)+':'+(Seconds>=10?Seconds:'0'+Seconds);
            let exText 
            if(Hours!=0){
                exText = '00:00:00'
            }else{
                exText = '00:00:00' 
            }

            let minFontSize = 1; // 최소 폰트 크기
            let maxFontSize = 1000; // 최대 폰트 크기
        
            let fontSize  = null
            // 이진 탐색을 사용하여 적절한 폰트 크기를 찾음
            while (minFontSize <= maxFontSize) {
                fontSize = Math.floor((minFontSize + maxFontSize) / 2); // 중간 폰트 크기 계산
                ctx.font = `${fontSize}px ${Init['fontFamily']}`;
                const textWidth = ctx.measureText(exText).width;
        
                    if (textWidth > Init.width*0.9) {
                        maxFontSize = fontSize - 1; // 폰트 크기를 줄임
                    } else {
                        minFontSize = fontSize + 1; // 폰트 크기를 늘임
                    }
            }
            Init['fontSize'] = fontSize
            ctx.fillStyle = Init['color'];
            ctx.font = `${fontSize}px ${Init['fontFamily']}`;

            // 그림자
            // if( Number(data?.style?.shadowValue)??0 == 0 ){
            //     ctx.shadowColor = data?.style?.shadowColor??'#000000'; // 그림자 색상 (반투명 검정색)
            //     ctx.shadowBlur = Number(data?.style?.shadowValue)??0; // 그림자 흐림 정도
                
            //     switch (data?.style?.shadowDirection??'RightBottom') {
            //         case 'RightTop':
            //             ctx.shadowOffsetX = 5; // 수평 오프셋
            //             ctx.shadowOffsetY = -5; // 수직 오프셋
            //             break;
            //         case 'LeftTop':
            //             ctx.shadowOffsetX = -5; // 수평 오프셋
            //             ctx.shadowOffsetY = -5; // 수직 오프셋
            //             break;
            //         case 'RightBottom':
            //             ctx.shadowOffsetX = 5; // 수평 오프셋
            //             ctx.shadowOffsetY = 5; // 수직 오프셋
            //             break;
            //         case 'LeftBottom':
            //             ctx.shadowOffsetX = -5; // 수평 오프셋
            //             ctx.shadowOffsetY = 5; // 수직 오프셋
            //             break;           
            //         default:
            //             break;
            //     }

            // }
   

            const measureText = ctx.measureText(currentTime);
            Init['VerticalAlignment'] = ((measureText.actualBoundingBoxAscent + measureText.actualBoundingBoxDescent) );
            ctx.clearRect(0, 0, Init.width, Init.height); // 캔버스 클리어

        break;

        default:

        break;

    }


    canvasDraw({ctx,data,cache,Init,setID:setAnimationID,Refresh});
}


export function canvasDraw({ctx,data,cache,setID,Init,Refresh}){

    if(Date.now()-Refresh.last > Refresh.fps){
    const Height = Number(data?.size?.height);
    const Width = Number(data?.size?.width);

    const FontFamily = data?.style?.fontFamily;
    const Preset = data?.preset;
    ctx.clearRect(0, 0, Width, Height); // 캔버스 클리어

    
    if(ctx){
        switch (Preset) {
            case 'static':

                const Cache = cache()
                const SetTime = getTimer(data,Cache);
                
                // console.log('구해진 시간 :',SetTime );
 

                const Hours = Math.floor(SetTime / 3600)
                const Minutes = Math.floor((SetTime % 3600) / 60)
                const Seconds = SetTime % 60
    
                const currentTime = (Hours!=0?Hours+':':'')+(Minutes>=10?Minutes:'0'+Minutes)+':'+(Seconds>=10?Seconds:'0'+Seconds);
                // console.log('currentTime',currentTime);
                // let currentTime = '10:00';
                ctx.textBaseline = 'middle';
                ctx.textAlign = "center"
           
                ctx.fillText(currentTime,Init.width*0.5,Init.height/2);
                // ctx.fillText(currentTime,Init.width*0.05,Init.0);
              

                // ctx.fillText(Init['fontFamily'],0,200);

            break;
    
            case 'liveCircle1':
    
            break;
    
            case 'liveSpeechIcon1':
    
            break;
    
            case 'liveSquare1':
    
            break;
    
            case 'liveSquare2':
    
            break;
    
            default:
    
        }
        Refresh.last = Date.now();
    }


    
    
    }

    // let animationFrameId = requestAnimationFrame(() => canvasDraw({ctx,data,animation,start,last}));


    // const test = requestAnimationFrame(()=> updateCanvas(ctx,canvasRef,x,requestId)); // 다음 프레임을 위한 애니메이션 프레임 요청

     const test =  requestAnimationFrame(() => canvasDraw({ctx,data,cache,setID,Init,Refresh}));

     setID(test)

    // 애니메이션 식별자를 저장
    // animation = animationFrameId;
    
}