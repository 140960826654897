////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widgetEdit.scss"

//
import exampleFilter from 'assets/exampleFilter.jpg';
// 컴포넌트 연결
import InfoButtonModule from '../editModule/InfoButtonModule';//위치 모듈
import RotateModule from '../editModule/RotateModule'; // 색상 모듈
// 서비스 연결

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class TransformEdit extends Component {
    
    state = {
    }

    change = (name,value) =>{
        this.props.callBack('style',name,value)
    }

    render() { 
        const Style = {
            label: {
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
            },
            textarea : {
                width: '100%',
                resize: 'none'
            }
        }

        return (
            <fieldset className="EditModuleField TransformEdit" style={Style.fieldset} >
                <legend style={Style.legend} > 변형 </legend>
                <div>

                </div>
                <RotateModule value={this.props.data.style.rotateZ} valueView="도" type ="rotateZ" min="-180" max="180" title="회전" change={this.change} />
                <RotateModule value={this.props.data.style.rotateY} valueView="도" type ="rotateY" min="-180" max="180" title="상하회전" change={this.change} />
                <RotateModule value={this.props.data.style.rotateX} valueView="도" type ="rotateX" min="-180" max="180" title="좌우회전" change={this.change} />
                {/* <ImageButtonModule value={this.props.data.style.scale} type ="scale" data={[{val:100,image:invert_3,text:'text',title:"100% 색반전"},{val:66,image:invert_2,title:"66% 색반전"},{val:33,image:invert_1,title:"33% 색반전"},{val:0,image:invert_0,title:"색반전 없음"}]} title="색반전"  change={this.change}/> */}
                <RotateModule value={this.props.data.style.scale} valueView="%" type ="scale" min="0" max="500" title="배율" change={this.change} />
            </fieldset>
        );
    }
}

////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////

const mapStateToProps = (state) => ({
    // exemple : state.exemple,
})

function mapDispatchToProps(dispatch){
    return {
    //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(TransformEdit);
  