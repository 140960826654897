////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widgetEdit.scss"


// 컴포넌트 연결
// import Show from '../show/Show'; // 메뉴 헤더

// 서비스 연결

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class FontModule extends Component {



state = {
    font : this.props.value,
    dropdown : false
}

click = (font) =>{
    this.props.change(this.props.type,font)
    this.setState({
        dropdown : false,
        font : font
    })
}

fontList = () =>{

    let list = []

    for (let index = 0; index < FONTLIST.length; index++) {
        list.push(React.createElement('li',{'style':{fontFamily:FONTLIST[index]},'onClick': ()=> this.click(FONTLIST[index])},FONTLIST[index])) 
    }
    return list
   
}

    render() { 

        

        return (
            <label className="EditModule FontModule" htmlfor="fname"> 
                <p>{this.props.title?this.props.title:'폰트'}</p>
                <div className="FontModuleButton optionButton" style={{fontFamily:this.props.value}}  onClick={()=>this.setState({
                    dropdown : !this.state.dropdown
                })}>
                    <span>
                    {this.props.value} 
                    </span>
                    <span className="FontModuleMore">
                    ▼
                    </span>
                 
                
                </div>
                {this.state.dropdown?  
                <ul className="dropdown">
                    {this.fontList()}
                </ul>
                :
                ''
                }
        
            </label>
        );
    }
}

const FONTLIST=['나눔스퀘어','나눔고딕','나눔바른고딕','나눔바른펜','한나체','한나체Air','에스코어드림Thin','에스코어드림Light','에스코어드림Medium','에스코어드림Bold','에스코어드림Heavy','프리텐다드Light','프리텐다드Regular','프리텐다드Medium','프리텐다드SemiBold','프리텐다드Black','어그로체Light','어그로체Medium','어그로체Bold','망고빙수','주아체','기랑해랑체','도현체','연성체','을지로체','을지로10년후체','메이플스토리체','삼립호빵체','이사만루체','마포꽃섬','조선궁서체','DOS명조','타자기체','당당해체','교보손글씨','잘난체']

////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    // screenData : state.screenData,
})

function mapDispatchToProps(dispatch){
    return {
    //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(FontModule);
  