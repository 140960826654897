// -- 위젯의 정의파일 Model 입니다. 해당 파일을 widgetModel 파일에 import 시켜야 사용 가능 합니다. -- 

////////////////////////////////////////////////////////
// Scoreboard | 스코어보드 
// 경기의 점수판을 실시간으로 기록합니다.
////////////////////////////////////////////////////////

// -- 컴포넌트 불러오기 -- ('components/widget/[위젯이름]') 위치로 가저올 수 있습니다.
import BanPick from 'components/widget/BanPick';// 컴포넌트
import { forEach } from 'lodash';

// -- 스타일 불러오기 -- ('service/model/widget/[스타일이름]') 위치로 가저올 수 있습니다.
import * as common from 'service/model/widget/commonStyle'; // 자주 사용되는 스타일 메이커 가저오기
import * as common_v2 from 'service/model/widget/commonStyle_V2'; // 자주 사용되는 스타일 메이커 가저오기
import * as keyframe from 'service/model/widget/keyframe'; // 자주 사용되는 스타일 메이커 가저오기
// -- 기타 함수 불러오기 -- ('service/model/widget/[스타일이름]') 위치로 가저올 수 있습니다.
// import * as [이름] from 'service/[경로]'; 


////////////////////////////////////////////////////////
// 컴포넌트 정의
// 해당 위젯 모댈과 연결할 리엑트 컴포넌트를 정의 합니다.
////////////////////////////////////////////////////////
// export const Component = [가저올 컴포넌트] ; 
export const Component = BanPick; 

////////////////////////////////////////////////////////
// 레퍼런스 정의
// 해당 위젯의 기본 설정값을 넣습니다.
////////////////////////////////////////////////////////
export const Reference = {
  position: {x: "0", y: "730", show : true},
  size: {width: "1920", height: "350"},
  style : {
    shadowValue: "0", 
    shadowColor: "#000000",
    shadowDirection : "RightBottom",
    fill : "#000000",
    borderRadius : 0,
    objectFit : 'fill',
    clipNode : [],
    ...common.filterDefault,
    ...common.transformDefault,
    
    fontSize : 50,
    FontFamily : '프리텐다드SemiBold',
    PickBoxFontFamily : '프리텐다드SemiBold',

    TimerFill : '#ffffff',
    BenBoxFill : '#ffffff',
    PickBoxFill : '#ffffff',
    LeftTeamFill : '#ffffff',
    rightTeamFill : '#ffffff',

  },
  value: {
    banPickAsset : 'overwatch',
    banPickOption : [],
    banPickRule : [{"team":"right","type":"ban","timer":"30","chosen":false,"selected":false},{"team":"left","type":"pick","timer":"30","chosen":false,"selected":false}],"banPickRule":[{"type":"ban","team":"left","timer":"30","chosen":false,"selected":false},{"type":"ban","team":"right","timer":"30","chosen":false,"selected":false},{"type":"ban","team":"left","timer":"30","chosen":false,"selected":false},{"type":"ban","team":"right","timer":"30","chosen":false,"selected":false},{"type":"ban","team":"left","timer":"30","chosen":false,"selected":false},{"type":"ban","team":"right","timer":"30","chosen":false,"selected":false},{"type":"pick","team":"left","timer":"30","chosen":false,"selected":false},{"type":"pick","team":"right","timer":"30","chosen":false,"selected":false},{"type":"pick","team":"right","timer":"30","chosen":false,"selected":false},{"type":"pick","team":"left","timer":"30","chosen":false,"selected":false},{"type":"pick","team":"left","timer":"30","chosen":false,"selected":false},{"type":"pick","team":"right","timer":"30","chosen":false,"selected":false},{"type":"ban","team":"left","timer":"30","chosen":false,"selected":false},{"type":"ban","team":"right","timer":"30","chosen":false,"selected":false},{"type":"ban","team":"left","timer":"30","chosen":false,"selected":false},{"type":"ban","team":"right","timer":"30","chosen":false,"selected":false},{"type":"pick","team":"right","timer":"30","chosen":false,"selected":false},{"type":"pick","team":"left","timer":"30","chosen":false,"selected":false},{"type":"pick","team":"left","timer":"30","chosen":false,"selected":false},{"type":"pick","team":"right","timer":"30","chosen":false,"selected":false}],
    teamData : [],
    option: null,
  },
  display:{
    show : true
  },
  animation:{
    mountStart:{keyFrame:null,delay:null,timing:null},
    mount:{keyFrame:null,delay:null,timing:null},
    mountEnd:{keyFrame:null,delay:null,timing:null},
    morphing:true
  },
  preset : 'default'
}



////////////////////////////////////////////////////////
// 스타일 정의
// 해당 위젯의 CSS 스타일을 정의합니다.
////////////////////////////////////////////////////////
export function style(data) {
  let layout = {}
  let object = {}
  let property = Object.keys(data)
  if(property.includes('position')){
    layout = Object.assign(layout,common.position({widget:data.position}))
  }
  if(property.includes('size')){
    object = Object.assign(object,common.size({widget:data.size}))
  }
  if(property.includes('style')){
    // 외곽선 
    object = Object.assign(object,common.border({widget:data.style}))
    // 필터 + 그림자 + 투명도
    object = Object.assign(object,common.filter({widget:data.style}))
    // 모핑
    layout = Object.assign(layout,common.morphing({widget:data.animation}))
    object = Object.assign(object,common.morphing({widget:data.animation}))
    // 변형
    object = Object.assign(object,common.transform({widget:data.style}))
    // 자르기
    object = Object.assign(object,common.clipPath({widget:data.style}))
    // 이미지정렬
    object = Object.assign(object,common.objectFit({widget:data.style}))
  }
  return {layout,object}
}
  
////////////////////////////////////////////////////////
// 프리셋 정의
// 해당 위젯의 CSS  프리셋을 정의합니다.
////////////////////////////////////////////////////////
// 프리셋을 사용하지 않는 위젯의 경우
// export const Preset = null
// 프리셋을 사용하는 위젯의 경우
export const Preset = [
    {name:'default', nameKo:'기본',},
]

////////////////////////////////////////////////////////
// 애니메이션 정의
// 해당 위젯의 기본 설정값을 넣습니다.
////////////////////////////////////////////////////////
/**
 * 사용 가능한 애니메이션 효과를 정의합니다.
 * @param {*} state // 애니메이션 효과 종류 mountStart&mountEnd
 * @param {*} preset // 위젯의 프리셋
 * @returns 
 */
export function availableAnimation (state,preset=null) {

  const List = keyframe.basic(state)

  let customList = {}

  switch (preset) {
    case 'presetName':
      if(state == 'mountStart'){
        customList = {
          'custom' :  {
            info : {name:'fade in',nameKo:'페이드인',icon:'blur_on'},
            timing : {max:5000,min:500,default:1000},
            target :{layout:{keyframe:'widget_animation_fadeIn'}},
          },
        }
      }

      else if(state == 'mountEnd'){
        customList = {
          'custom' :  {
            info : {name:'fade in',nameKo:'페이드인',icon:'blur_on'},
            timing : {max:5000,min:500,default:1000},
            target :{layout:{keyframe:'widget_animation_fadeIn'}},
          },
        }
      }
      break;
    default:
      break;
  }

  const MergedList = Object.assign( customList, List );
  return MergedList;
}
/**
 * animation 애니메이션 효과를 정의합니다.
 * @param {*} state // 애니메이션 효과 종류 mountStart&mountEnd
 * @param {*} data  // 위젯의 데이터
 * @param {*} preset // 해당 프리셋에서 유효한 애니메이션인지 검증합니다. 
 * @returns 
 */
export function animation (state,data,preset=null) {
  let output = {}
  switch (state) {
    case 'mountStart':

    const List = availableAnimation(state,preset)
    output = keyframe.css({widgetAnimation:data.animation[state],availableAnimation:List})
    break;

    default:
      break;
  }
  return output
}
////////////////////////////////////////////////////////
// 커스텀 펑션 정의
// 해당 위젯 및 위젯 모델에서 사용하는 평선을 정의합니다.
////////////////////////////////////////////////////////


export const Asset = [
  {name:'overwatch', nameKo:'오버워치',summary:'오버워치 캐릭터들로 이루워진 에셋입니다.',
    data:[
    {"name": "Ana", "nameKo": "아나", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/3429c394716364bbef802180e9763d04812757c205e1b4568bc321772096ed86.png"},
    {"name": "Ashe", "nameKo": "애쉬", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/8dc2a024c9b7d95c7141b2ef065590dbc8d9018d12ad15f76b01923986702228.png"},
    {"name": "Baptiste", "nameKo": "바티스트", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/f979896f74ba22db2a92a85ae1260124ab0a26665957a624365e0f96e5ac5b5c.png"},
    {"name": "Bastion", "nameKo": "바스티온", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/4d715f722c42215072b5dd0240904aaed7b5285df0b2b082d0a7f1865b5ea992.png"},
    {"name": "Brigitte", "nameKo": "브리기테", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/48392820c6976ee1cd8dde13e71df85bf15560083ee5c8658fe7c298095d619a.png"},
    {"name": "Cassidy", "nameKo": "캐서디", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/6cfb48b5597b657c2eafb1277dc5eef4a07eae90c265fcd37ed798189619f0a5.png"},
    {"name": "D.Va", "nameKo": "디바", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/ca114f72193e4d58a85c087e9409242f1a31e808cf4058678b8cbf767c2a9a0a.png"},
    {"name": "Doomfist", "nameKo": "둠피스트", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/13750471c693c1a360eb19d5ace229c8599a729cd961d72ebee0e157657b7d18.png"},
    {"name": "Echo", "nameKo": "에코", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/f086bf235cc6b7f138609594218a8385c8e5f6405a39eceb0deb9afb429619fe.png"},
    {"name": "Genji", "nameKo": "겐지", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/4edf5ea6d58c449a2aeb619a3fda9fff36a069dfbe4da8bc5d8ec1c758ddb8dc.png"},
    {"name": "Hanzo", "nameKo": "한조", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/aecd8fa677f0093344fab7ccb7c37516c764df3f5ff339a5a845a030a27ba7e0.png"},
    {"name": "Illari", "nameKo": "일리아리", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/5ea986038f9d307bd4613d5e6f2c4c8e7f15f30ceeeabbdd7a06637a38f17e1f.png"},
    {"name": "Junker Queen", "nameKo": "정커퀸", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/cef2406b2244b80506f83b8fb9ebaf214f41fa8795cbeef84026cd8018561d04.png"},
    {"name": "Junkrat", "nameKo": "정크랫", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/037e3df083624e5480f8996821287479a375f62b470572a22773da0eaf9441d0.png"},
    {"name": "Kiriko", "nameKo": "키리코", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/088aff2153bdfa426984b1d5c912f6af0ab313f0865a81be0edd114e9a2f79f9.png"},
    {"name": "Lifeweaver", "nameKo": "라이프위버", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/39d4514f1b858bc228035b09d5a74ed41f8eeefc9a0d1873570b216ba04334df.png"},
    {"name": "Lucio", "nameKo": "루시우", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/e2ff2527610a0fbe0c9956f80925123ef3e66c213003e29d37436de30b90e4e1.png"},
    {"name": "Mei", "nameKo": "메이", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/1533fcb0ee1d3f9586f84b4067c6f63eca3322c1c661f69bfb41cd9e4f4bcc11.png"},
    {"name": "Mercy", "nameKo": "메르시", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/2508ddd39a178d5f6ae993ab43eeb3e7961e5a54a9507e6ae347381193f28943.png"},
    {"name": "Mauga", "nameKo": "마우가", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/9ee3f5a62893091d575ec0a0d66df878597086374202c6fc7da2d63320a7d02e.png"},
    {"name": "Moira", "nameKo": "모이라", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/000beeb5606e01497897fa9210dd3b1e78e1159ebfd8afdc9e989047d7d3d08f.png"},
    {"name": "Orisa", "nameKo": "오리사", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/71e96294617e81051d120b5d04b491bb1ea40e2933da44d6631aae149aac411d.png"},
    {"name": "Pharah", "nameKo": "파라", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/f8261595eca3e43e3b37cadb8161902cc416e38b7e0caa855f4555001156d814.png"},
    {"name": "Ramattra", "nameKo": "라마트라", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/3e0367155e1940a24da076c6f1f065aacede88dbc323631491aa0cd5a51e0b66.png"},
    {"name": "Reaper", "nameKo": "리퍼", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/2edb9af69d987bb503cd31f7013ae693640e692b321a73d175957b9e64394f40.png"},
    {"name": "Reinhardt", "nameKo": "라인하르트", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/490d2f79f8547d6e364306af60c8184fb8024b8e55809e4cc501126109981a65.png"},
    {"name": "Roadhog", "nameKo": "로드호그", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/72e02e747b66b61fcbc02d35d350770b3ec7cbaabd0a7ca17c0d82743d43a7e8.png"},
    {"name": "Sigma", "nameKo": "시그마", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/cd7a4c0a0df8924afb2c9f6df864ed040f20250440c36ca2eb634acf6609c5e4.png"},
    {"name": "Sojourn", "nameKo": "소전", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/a53bf7ad9d2f33aaf9199a00989f86d4ba1f67c281ba550312c7d96e70fec4ea.png"},
    {"name": "Soldier: 76", "nameKo": "솔저: 76", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/20b4ef00ed05d6dba75df228241ed528df7b6c9556f04c8070bad1e2f89e0ff5.png"},
    {"name": "Sombra", "nameKo": "솜브라", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/bca8532688f01b071806063b9472f1c0f9fc9c7948e6b59e210006e69cec9022.png"},
    {"name": "Symmetra", "nameKo": "시메트라", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/7f2024c5387c9d76d944a5db021c2774d1e9d7cbf39e9b6a35b364d38ea250ac.png"},
    {"name": "Torbjörn", "nameKo": "토르비욘", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/1309ab1add1cc19189a2c8bc7b1471f88efa1073e9705d2397fdb37d45707d01.png"},
    {"name": "Tracer", "nameKo": "트레이서", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/a66413200e934da19540afac965cfe8a2de4ada593d9a52d53108bb28e8bbc9c.png"},
    {"name": "Venture", "nameKo": "벤처", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/7e33dd756c8a1abca519af6c3bf26813f2f81d39885373539efcf8442c4bc647.png"},
    {"name": "Widowmaker", "nameKo": "위도우메이커", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/a714f1cb33cc91c6b5b3e89ffe7e325b99e7c89cc8e8feced594f81305147efe.png"},
    {"name": "Winston", "nameKo": "윈스턴", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/bd9c8e634d89488459dfc1aeb21b602fa5c39aa05601a4167682f3a3fed4e0ee.png"},
    {"name": "Wrecking Ball", "nameKo": "레킹볼", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/5c18e39ce567ee8a84078f775b9f76a2ba891de601c059a3d2b46b61ae4afb42.png"},
    {"name": "Zarya", "nameKo": "자리야", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/8819ba85823136640d8eba2af6fd7b19d46b9ee8ab192a4e06f396d1e5231f7a.png"},
    {"name": "Juno", "nameKo": "주노", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/585b2d60cbd3c271b6ad5ad0922537af0c6836fab6c89cb9979077f7bb0832b5.png"},
    {"name": "Zenyatta", "nameKo": "젠야타", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/71cabc939c577581f66b952f9c70891db779251e8e70f29de3c7bf494edacfe4.png"}
]

},
{
  "name": "leagueoflegends",
  "nameKo": "리그 오브 레전드",
  "summary": "리그 오브 레전드 챔피언들로 이루어진 에셋입니다.",
  "data": [
    {"name": "Aatrox", "nameKo": "아트록스", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Aatrox_0.jpg"},
    {"name": "Ahri", "nameKo": "아리", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Ahri_0.jpg"},
    {"name": "Akali", "nameKo": "아칼리", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Akali_0.jpg"},
    {"name": "Akshan", "nameKo": "아크샨", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Akshan_0.jpg"},
    {"name": "Alistar", "nameKo": "알리스타", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Alistar_0.jpg"},
    {"name": "Amumu", "nameKo": "아무무", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Amumu_0.jpg"},
    {"name": "Anivia", "nameKo": "애니비아", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Anivia_0.jpg"},
    {"name": "Annie", "nameKo": "애니", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Annie_0.jpg"},
    {"name": "Aphelios", "nameKo": "아펠리오스", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Aphelios_0.jpg"},
    {"name": "Ashe", "nameKo": "애쉬", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Ashe_0.jpg"},
    {"name": "Aurelion Sol", "nameKo": "아우렐리온 솔", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/AurelionSol_0.jpg"},
    {"name": "Azir", "nameKo": "아지르", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Azir_0.jpg"},
    {"name": "Bard", "nameKo": "바드", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Bard_0.jpg"},
    {"name": "Bel'Veth", "nameKo": "벨베스", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Belveth_0.jpg"},
    {"name": "Blitzcrank", "nameKo": "블리츠크랭크", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Blitzcrank_0.jpg"},
    {"name": "Brand", "nameKo": "브랜드", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Brand_0.jpg"},
    {"name": "Braum", "nameKo": "브라움", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Braum_0.jpg"},
    {"name": "Caitlyn", "nameKo": "케이틀린", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Caitlyn_0.jpg"},
    {"name": "Camille", "nameKo": "카밀", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Camille_0.jpg"},
    {"name": "Cassiopeia", "nameKo": "카시오페아", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Cassiopeia_0.jpg"},
    {"name": "Cho'Gath", "nameKo": "초가스", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Chogath_0.jpg"},
    {"name": "Corki", "nameKo": "코르키", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Corki_0.jpg"},
    {"name": "Darius", "nameKo": "다리우스", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Darius_0.jpg"},
    {"name": "Diana", "nameKo": "다이애나", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Diana_0.jpg"},
    {"name": "Dr. Mundo", "nameKo": "문도 박사", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/DrMundo_0.jpg"},
    {"name": "Draven", "nameKo": "드레이븐", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Draven_0.jpg"},
    {"name": "Ekko", "nameKo": "에코", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Ekko_0.jpg"},
    {"name": "Elise", "nameKo": "엘리스", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Elise_0.jpg"},
    {"name": "Evelynn", "nameKo": "이블린", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Evelynn_0.jpg"},
    {"name": "Ezreal", "nameKo": "이즈리얼", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Ezreal_0.jpg"},
    {"name": "Fiddlesticks", "nameKo": "피들스틱", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Fiddlesticks_0.jpg"},
    {"name": "Fiora", "nameKo": "피오라", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Fiora_0.jpg"},
    {"name": "Fizz", "nameKo": "피즈", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Fizz_0.jpg"},
    {"name": "Galio", "nameKo": "갈리오", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Galio_0.jpg"},
    {"name": "Gangplank", "nameKo": "갱플랭크", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Gangplank_0.jpg"},
    {"name": "Garen", "nameKo": "가렌", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Garen_0.jpg"},
    {"name": "Gnar", "nameKo": "나르", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Gnar_0.jpg"},
    {"name": "Gragas", "nameKo": "그라가스", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Gragas_0.jpg"},
    {"name": "Graves", "nameKo": "그레이브즈", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Graves_0.jpg"},
    {"name": "Gwen", "nameKo": "그웬", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Gwen_0.jpg"},
    {"name": "Hecarim", "nameKo": "헤카림", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Hecarim_0.jpg"},
    {"name": "Heimerdinger", "nameKo": "하이머딩거", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Heimerdinger_0.jpg"},
    {"name": "Illaoi", "nameKo": "일라오이", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Illaoi_0.jpg"},
    {"name": "Irelia", "nameKo": "이렐리아", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Irelia_0.jpg"},
    {"name": "Ivern", "nameKo": "아이번", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Ivern_0.jpg"},
    {"name": "Janna", "nameKo": "잔나", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Janna_0.jpg"},
    {"name": "Jarvan IV", "nameKo": "자르반 4세", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/JarvanIV_0.jpg"},
    {"name": "Jax", "nameKo": "잭스", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Jax_0.jpg"},
    {"name": "Jayce", "nameKo": "제이스", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Jayce_0.jpg"},
    {"name": "Jhin", "nameKo": "진", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Jhin_0.jpg"},
    {"name": "Jinx", "nameKo": "징크스", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Jinx_0.jpg"},
    {"name": "Kai'Sa", "nameKo": "카이사", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Kaisa_0.jpg"},
    {"name": "Kalista", "nameKo": "칼리스타", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Kalista_0.jpg"},
    {"name": "Karma", "nameKo": "카르마", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Karma_0.jpg"},
    {"name": "Karthus", "nameKo": "카서스", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Karthus_0.jpg"},
    {"name": "Kassadin", "nameKo": "카사딘", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Kassadin_0.jpg"},
    {"name": "Katarina", "nameKo": "카타리나", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Katarina_0.jpg"},
    {"name": "Kayle", "nameKo": "케일", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Kayle_0.jpg"},
    {"name": "Kayn", "nameKo": "케인", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Kayn_0.jpg"},
    {"name": "Kennen", "nameKo": "케넨", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Kennen_0.jpg"},
    {"name": "Kha'Zix", "nameKo": "카직스", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Khazix_0.jpg"},
    {"name": "Kindred", "nameKo": "킨드레드", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Kindred_0.jpg"},
    {"name": "Kled", "nameKo": "클레드", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Kled_0.jpg"},
    {"name": "Kog'Maw", "nameKo": "코그모", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/KogMaw_0.jpg"},
    {"name": "LeBlanc", "nameKo": "르블랑", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Leblanc_0.jpg"},
    {"name": "Lee Sin", "nameKo": "리 신", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/LeeSin_0.jpg"},
    {"name": "Leona", "nameKo": "레오나", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Leona_0.jpg"},
    {"name": "Lillia", "nameKo": "릴리아", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Lillia_0.jpg"},
    {"name": "Lissandra", "nameKo": "리산드라", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Lissandra_0.jpg"},
    {"name": "Lucian", "nameKo": "루시안", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Lucian_0.jpg"},
    {"name": "Lulu", "nameKo": "룰루", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Lulu_0.jpg"},
    {"name": "Lux", "nameKo": "럭스", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Lux_0.jpg"},
    {"name": "Malphite", "nameKo": "말파이트", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Malphite_0.jpg"},
    {"name": "Malzahar", "nameKo": "말자하", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Malzahar_0.jpg"},
    {"name": "Maokai", "nameKo": "마오카이", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Maokai_0.jpg"},
    {"name": "Master Yi", "nameKo": "마스터 이", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/MasterYi_0.jpg"},
    {"name": "Miss Fortune", "nameKo": "미스 포츈", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/MissFortune_0.jpg"},
    {"name": "Mordekaiser", "nameKo": "모데카이저", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Mordekaiser_0.jpg"},
    {"name": "Morgana", "nameKo": "모르가나", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Morgana_0.jpg"},
    {"name": "Nami", "nameKo": "나미", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Nami_0.jpg"},
    {"name": "Nasus", "nameKo": "나서스", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Nasus_0.jpg"},
    {"name": "Nautilus", "nameKo": "노틸러스", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Nautilus_0.jpg"},
    {"name": "Neeko", "nameKo": "니코", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Neeko_0.jpg"},
    {"name": "Nidalee", "nameKo": "니달리", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Nidalee_0.jpg"},
    {"name": "Nilah", "nameKo": "닐라", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Nilah_0.jpg"},
    {"name": "Nocturne", "nameKo": "녹턴", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Nocturne_0.jpg"},
    {"name": "Nunu & Willump", "nameKo": "누누와 윌럼프", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Nunu_0.jpg"},
    {"name": "Olaf", "nameKo": "올라프", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Olaf_0.jpg"},
    {"name": "Orianna", "nameKo": "오리아나", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Orianna_0.jpg"},
    {"name": "Ornn", "nameKo": "오른", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Ornn_0.jpg"},
    {"name": "Pantheon", "nameKo": "판테온", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Pantheon_0.jpg"},
    {"name": "Poppy", "nameKo": "뽀삐", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Poppy_0.jpg"},
    {"name": "Pyke", "nameKo": "파이크", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Pyke_0.jpg"},
    {"name": "Qiyana", "nameKo": "키아나", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Qiyana_0.jpg"},
    {"name": "Quinn", "nameKo": "퀸", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Quinn_0.jpg"},
    {"name": "Rakan", "nameKo": "라칸", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Rakan_0.jpg"},
    {"name": "Rammus", "nameKo": "람머스", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Rammus_0.jpg"},
    {"name": "Rek'Sai", "nameKo": "렉사이", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/RekSai_0.jpg"},
    {"name": "Rell", "nameKo": "렐", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Rell_0.jpg"},
    {"name": "Renata Glasc", "nameKo": "레나타 글라스크", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Renata_0.jpg"},
    {"name": "Renekton", "nameKo": "레넥톤", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Renekton_0.jpg"},
    {"name": "Rengar", "nameKo": "렝가", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Rengar_0.jpg"},
    {"name": "Riven", "nameKo": "리븐", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Riven_0.jpg"},
    {"name": "Rumble", "nameKo": "럼블", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Rumble_0.jpg"},
    {"name": "Ryze", "nameKo": "라이즈", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Ryze_0.jpg"},
    {"name": "Samira", "nameKo": "사미라", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Samira_0.jpg"},
    {"name": "Sejuani", "nameKo": "세주아니", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Sejuani_0.jpg"},
    {"name": "Senna", "nameKo": "세나", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Senna_0.jpg"},
    {"name": "Seraphine", "nameKo": "세라핀", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Seraphine_0.jpg"},
    {"name": "Sett", "nameKo": "세트", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Sett_0.jpg"},
    {"name": "Shaco", "nameKo": "샤코", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Shaco_0.jpg"},
    {"name": "Shen", "nameKo": "쉔", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Shen_0.jpg"},
    {"name": "Shyvana", "nameKo": "쉬바나", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Shyvana_0.jpg"},
    {"name": "Singed", "nameKo": "신지드", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Singed_0.jpg"},
    {"name": "Sion", "nameKo": "사이온", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Sion_0.jpg"},
    {"name": "Sivir", "nameKo": "시비르", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Sivir_0.jpg"},
    {"name": "Skarner", "nameKo": "스카너", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Skarner_0.jpg"},
    {"name": "Sona", "nameKo": "소나", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Sona_0.jpg"},
    {"name": "Soraka", "nameKo": "소라카", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Soraka_0.jpg"},
    {"name": "Swain", "nameKo": "스웨인", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Swain_0.jpg"},
    {"name": "Sylas", "nameKo": "사일러스", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Sylas_0.jpg"},
    {"name": "Syndra", "nameKo": "신드라", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Syndra_0.jpg"},
    {"name": "Tahm Kench", "nameKo": "탐 켄치", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/TahmKench_0.jpg"},
    {"name": "Taliyah", "nameKo": "탈리야", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Taliyah_0.jpg"},
    {"name": "Talon", "nameKo": "탈론", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Talon_0.jpg"},
    {"name": "Taric", "nameKo": "타릭", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Taric_0.jpg"},
    {"name": "Teemo", "nameKo": "티모", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Teemo_0.jpg"},
    {"name": "Thresh", "nameKo": "쓰레쉬", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Thresh_0.jpg"},
    {"name": "Tristana", "nameKo": "트리스타나", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Tristana_0.jpg"},
    {"name": "Trundle", "nameKo": "트런들", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Trundle_0.jpg"},
    {"name": "Tryndamere", "nameKo": "트린다미어", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Tryndamere_0.jpg"},
    {"name": "Twisted Fate", "nameKo": "트위스티드 페이트", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/TwistedFate_0.jpg"},
    {"name": "Twitch", "nameKo": "트위치", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Twitch_0.jpg"},
    {"name": "Udyr", "nameKo": "우디르", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Udyr_0.jpg"},
    {"name": "Urgot", "nameKo": "우르곳", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Urgot_0.jpg"},
    {"name": "Varus", "nameKo": "바루스", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Varus_0.jpg"},
    {"name": "Vayne", "nameKo": "베인", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Vayne_0.jpg"},
    {"name": "Veigar", "nameKo": "베이가", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Veigar_0.jpg"},
    {"name": "Vel'Koz", "nameKo": "벨코즈", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Velkoz_0.jpg"},
    {"name": "Vex", "nameKo": "벡스", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Vex_0.jpg"},
    {"name": "Vi", "nameKo": "바이", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Vi_0.jpg"},
    {"name": "Viego", "nameKo": "비에고", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Viego_0.jpg"},
    {"name": "Viktor", "nameKo": "빅토르", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Viktor_0.jpg"},
    {"name": "Vladimir", "nameKo": "블라디미르", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Vladimir_0.jpg"},
    {"name": "Volibear", "nameKo": "볼리베어", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Volibear_0.jpg"},
    {"name": "Warwick", "nameKo": "워윅", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Warwick_0.jpg"},
    {"name": "Wukong", "nameKo": "오공", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/MonkeyKing_0.jpg"},
    {"name": "Xayah", "nameKo": "자야", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Xayah_0.jpg"},
    {"name": "Xerath", "nameKo": "제라스", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Xerath_0.jpg"},
    {"name": "Xin Zhao", "nameKo": "신 짜오", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/XinZhao_0.jpg"},
    {"name": "Yasuo", "nameKo": "야스오", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Yasuo_0.jpg"},
    {"name": "Yone", "nameKo": "요네", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Yone_0.jpg"},
    {"name": "Yorick", "nameKo": "요릭", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Yorick_0.jpg"},
    {"name": "Yuumi", "nameKo": "유미", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Yuumi_0.jpg"},
    {"name": "Zac", "nameKo": "자크", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Zac_0.jpg"},
    {"name": "Zed", "nameKo": "제드", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Zed_0.jpg"},
    {"name": "Zeri", "nameKo": "제리", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Zeri_0.jpg"},
    {"name": "Ziggs", "nameKo": "직스", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Ziggs_0.jpg"},
    {"name": "Zilean", "nameKo": "질리언", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Zilean_0.jpg"},
    {"name": "Zoe", "nameKo": "조이", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Zoe_0.jpg"},
    {"name": "Zyra", "nameKo": "자이라", "image": "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/Zyra_0.jpg"}
  ]
}

]

