////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widgetEdit.scss"


// 컴포넌트 연결
import SizeModule from '../editModule/SizeModule'; //위치 모듈
import ColorModule from '../editModule/ColorModule'; // 색상 모듈
import RangeModule from '../editModule/RangeModule'; // 색상 모듈
// 서비스 연결

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class StrokeEdit extends Component {
    
    state = {
    }

    change = (name,value) =>{
        this.props.callBack('style',name,value)
    }

    sizeChange = (name,value) =>{
        this.props.callBack('size',name,value)
    }


    render() { 

        const Style = {
            label: {
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
            },
            textarea : {
                width: '100%',
                resize: 'none',
                boxSizing:'border-box',
            },
            mediaView : {
                width: '100%',
                MaxHeight : '500px',
            }

        }
        let legend = '외곽선'
        if (this.props.type){
            legend = '외곽선'
        }


        return (
            <fieldset className="EditModuleField" style={Style.fieldset} >
                <legend style={Style.legend} > {legend} </legend>
                <RangeModule value={this.props.data.style.strokeWidth} type ="strokeWidth" valueView="px" min="0" max="30" title="선두께" change={this.change} />
                <ColorModule value={this.props.data.style.strokeColor} type ="strokeColor" title="선색상" change={this.change}/>
            </fieldset>
        );
    }
}

////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    // screenData : state.screenData,
})

function mapDispatchToProps(dispatch){
    return {
    //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(StrokeEdit);
  