////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/edit.scss"
import "sass/editOverlay.scss"
// 이미지 연결
import quick from 'assets/guide/quick.png';
import intro from 'assets/guide/intro.png';
import live from 'assets/guide/live.png';
import template from 'assets/guide/template.png';
import hotkey from 'assets/guide/hotkey.png';
import menu from 'assets/guide/menu.png';

// 컴포넌트 연결
import TemplateModal from './TemplateModal'; // 메뉴 헤더

// 서비스 연결
import * as Data from './EditTipData' ; 
import * as Modal from '../../../service/event/ModalControl';
// import * as EeCoder from 'service/engine/EnCoder';
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class EditTip extends Component {
    
    state = {
        target : 'front'
    }

    change = (target) => {
        this.setState({
            target   
        })
    }

    exit = ()=> {
        Modal.OverlayRemove('EditTip')
    }

    render() { 
        return (
            <div id="EditTip" onClick={()=>Modal.OverlayRemove('EditTip')}>
                <div className="tip" onClick={(e)=>e.stopPropagation()}>
                    <div className="tipBox">
                        <ul className="list">
                            <li onClick={()=>this.change('front')}>
                                <div className="listIMG">
                                <img src={intro}/>
                                </div>
                                <div className="listTitel">
                                    사용설명서<br/>안내
                                </div>
                            </li>
                            <li onClick={()=>this.change('guide')}>
                                <div className="listIMG">
                                <img src={quick}/>
                                </div>
                                <div className="listTitel">
                                    편집화면<br/>
                                    간단가이드
                                </div>
                            </li>
                            <li onClick={()=>this.change('onair')}>
                                <div className="listIMG">
                                <img src={live}/>
                                </div>
                                <div className="listTitel">
                                    송출화면<br/>
                                    변경하는방법 
                                </div>
                            </li>
                            <li onClick={()=>this.change('template')}>
                                <div className="listIMG">
                                <img src={template}/>
                                </div>
                                <div className="listTitel">
                                    템플릿을 통해<br/>
                                    바로 사용하기
                                </div>
                            </li>
                            <li onClick={()=>this.change('hotkey')}>
                                <div className="listIMG">
                                <img src={hotkey}/>
                                </div>
                                <div className="listTitel">
                                    오버랩 단축키<br/>
                                    알아보기
                                </div>
                            </li>
                        </ul>
                        <div  className="dictionary">
                            {Data.list(this.state.target,this).info}
                        </div>
                    </div>
                    <div className="tipNav">
                        <div className="exitBnt" onClick={()=>this.exit() } >
                            편집 시작하기

                        </div>
                        
                    </div>
                </div>
            </div>  
        );
    }
}
////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    overlay : state.editOverlay,
})

function mapDispatchToProps(dispatch){
    return {
        editOverlayPush : (edit) => dispatch({type:'editOverlayPush',edit}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(EditTip);
  