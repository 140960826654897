////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, {useState, useEffect, useContext, useRef } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { Route, Link } from 'react-router-dom';
import { useHistory } from "react-router";
// 라이브러리 
import { useGoogleLogin } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google'// 구글 로그인
// 설정 파일
import * as config from 'config/OverlapConfig'

import { withRouter } from 'react-router-dom';

import * as apiAccount from 'service/api/apiAccount'

// [컴포넌트] 위젯 
import SpaceGuide from "components/Lobby/space/module/SpaceGuide"
// [프로바이더]
import EditContext from 'service/context/EditContext' ; 

// SASS&CSS 연결
import 'sass/login.scss'

// 이미지 연결
import logoW from 'assets/logoW.svg';
import youtube from 'assets/main/lobby/social/youtube.png';
import twitch from 'assets/main/lobby/social/twitch.png';
import afreeca from 'assets/main/lobby/social/afreeca.png';
import facebook from 'assets/main/lobby/social/facebook.png';
import naver from 'assets/main/lobby/social/naver.png';


// 서비스 연결
import * as overlap from 'service/api/overlap';
import * as view from 'service/api/view';

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

function SpaceGuideList({guideList,lobbyRoutes,width=300}) {
    // const todos = useSelector(state => state.todos)
    // const dispatch = useDispatch()
    // const addTodo = text => dispatch(addTodo(text))


    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////
    //set
    const context = useContext(EditContext);

    // state
    const [ViewList, setList] = useState(null);
    // rel 
    ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////

      useEffect(() => {
        console.log('Component did mount or update');
    
        // Cleanup function (optional)
        return () => {
          console.log('Component will unmount');
        };
      },[]);

    ////////////////////////////////////////////////////////
    // Fuction
    ////////////////////////////////////////////////////////
    const GuideList = () => {

        if(guideList){
            const List = guideList.map((item) => (
            <div style={{width}} onClick={()=>{lobbyRoutes({target:item.GuideLink?.lobby,option:item.GuideLink?.lobbyState} )}}>
                <SpaceGuide GuideTitle={item.GuideTitle} GuideText={item.GuideText} GuideImageComponet={item.GuideImageComponet} GuideIcon={item.GuideIcon} IconColor={item.IconColor}/>
            </div>
           ));
            return List
        }
    }
    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////


    return (
        <div className='SpaceModuleContainer GuideListContainer' >
        <style jsx>{`${SpaceModuleStyle}${ContainerStyle}`}</style>
            <div className='guideField' style={{width}}>
                {GuideList()}
            </div>
        </div>
    );
  }

  export default SpaceGuideList;
////////////////////////////////////////////////////////
// css 부분
////////////////////////////////////////////////////////
const SpaceModuleStyle = `
    .ViewListContainer {

    }

`;

const ContainerStyle = `
    .GuideListContainer {
        position: absolute;
        left: 20px;
        top: 20px;
        width: calc(100% - 20px);
        flex :1;
        z-index: 1;
        pointer-events: none;
    }
    .GuideListContainer .guideField{
        pointer-events: all;
    }

    .GuideListContainer .activeField{
        width: 50px;
        height: 50px;
        background-color: #444;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .GuideListContainer .guideIcon{
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
   
`;