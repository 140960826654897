
import { green } from '@material-ui/core/colors';
import React, { Component , Fragment } from 'react';
// 서비스 연결
import * as Utility from 'service/other/Utility'; 
import * as WidgetData from 'service/value/WidgetData' ; 

////////////////////////////////////////////////////////
// Timer preset 파티클 프리셋
////////////////////////////////////////////////////////
function preset(preset,self,data,style){


    const { height: Height, width: Width } = data?.size ?? {};
    const Ref = self.widget;
    const Ctx = self.canvas;
    const Name = self.props.widgetName;
    
    console.log('preset',preset);
    switch (preset) {

        case 'gradient':
            return(
                <div className={"object "+Name+"_preset_"+preset} ref={Ref} style={{...style.object}} >
                    <canvas ref={Ctx} width={Width} height={Height} />
                </div>
            )
        case 'pattern':
            return(
                <div className={"object "+self.props.widgetName+"_preset_"+preset} ref={self.widget} style={{...style.object}} >
                    <img src="https://overlap.s3.ap-northeast-1.amazonaws.com/widget/LiveIcon/liveCircle1.png"/>
                </div>
            )
        default:
            return(
                <div className={"object unknownPreset"} ref={self.widget} style={{...style.object}} >
                    <p>알수없는 프리셋</p>
                </div>
                )
    }
}
export function data(self,style) {
    return preset(self.props.data.preset,self,self.props.data,style)
}


////////////////////////////////////////////////////////
// Timer Animation | 애니메이션
////////////////////////////////////////////////////////


// export function  getTimer (data, cache) {



//     const timerType = data.value.timerType
//     const Timer = {
//         start : cache?.atStart??null,
//         end : cache?.atEnd??0,
//         pause : cache?.pauseTime??0,
//         state : cache?.timerState??'stop',
//         fps : 300
//     }

//     const WidgetTime =  data.value.time;
//     let playTime = WidgetTime;

//     // console.log('구해진 시간 테스트:','Timer',Timer,'timerType',timerType,'WidgetTime',WidgetTime );
//     switch (timerType) {
//         case 'start':
//             if(Timer.state=='play') {
//                 playTime = (WidgetTime - Math.floor((Date.now() - Timer.start) / 1000))
//             }
//             if(Timer.state=='pause') {
//                 playTime = (WidgetTime - Math.floor((Timer.pause-Timer.start) / 1000))
//             }
//             if(Timer.state=='stop') {
//                 playTime = (WidgetTime)
//             }
//             break;

//         case 'end':
//             const now = new Date(); // 현재 시간
//             const startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate()); // 오늘 자정
//             const secondsElapsed =  Math.floor((now.getTime() - startOfDay.getTime()) / 1000); // 밀리초 차이를 초로 변환       

//             if(Timer.state=='play') {
//                 playTime =(Timer.end-secondsElapsed)
//             }
//             if(Timer.state=='pause') {
//                 playTime =(Timer.end-secondsElapsed)
//             }
//             if(Timer.state=='stop') {
//                 playTime = 0
//             }
//             break;
    
//         default:
//             break;
//     }

//     // console.log('[테스트 데이터]','체크 상태',Timer.end,secondsElapsed);
//     playTime = playTime<0 ? 0 : playTime
    
//     return playTime;

// }


/**
 * 캔버스를 초기화 합니다.
 * @param {current,ctx,setAnimationID,data,cache,fps,self} 
 */
export function canvasInit({current,ctx,setAnimationID,data,cache,option}){

    if(current==null){
        return
    }

    ctx = current.getContext("2d");
    const Width = Number(data?.size?.width);
    const Height = Number(data?.size?.height);
    const Now  = Date.now();
    const Refresh = {
        start : Now,
        last : 0,
        fps : 30
    }

    switch (data?.preset) {
        case 'gradient':
            const setting = {
                pixelRatio : (Width>Height) ? 2 : 1 ,
                totalParticles : 30,
                particles : [],
                maxRadius : Width/2,
                minRadius : Width/5
            };

            let curColor = 0
            console.log('data?.value.gradientColor',data?.value.gradientColor,hexToRGB(data?.value.gradientColor?.[0]??'#ffffff'))
            for (let index = 0; index < setting.totalParticles; index++) {
                const item = new GradientParticle(
                    Math.random()* Width,
                    Math.random()* Height,
                    Math.random()* (setting.maxRadius - setting.minRadius) + setting.minRadius,hexToRGB(data?.value.gradientColor?.[curColor]??'#ffffff')
                );
                if (++curColor >= data?.value?.gradientColor?.length){
                    curColor = 0;
                }
                setting.particles[index] = item;
            }

            gradientDraw({setting,ctx,data,cache,setID:setAnimationID,Refresh,option});
        break;

        case 'pattern':

        break;

        default:

    }



}


function hexToRGB(hex) {
    const r = parseInt(hex.slice(1, 3), 16);  // Red
    const g = parseInt(hex.slice(3, 5), 16);  // Green
    const b = parseInt(hex.slice(5, 7), 16);  // Blue
    
    return { red: r, green: g, blue: b };
  }

  
export function resize(){

}


class GradientParticle {
    constructor(x,y,radius, color){
        this.x = x;
        this.y = y;
        this.radius = radius;
        this.color = color;
        this.speed = 10;
        this.vx = Math.random() * 4;
        this.vy = Math.random() * 4;
        this.sinValue = Math.random();
    }   
    
    animate(ctx, stageWidth, stageHeight){
        this.sinValue += 0.01;
        this.radius += Math.sin(this.sinValue)

        this.x += this.vx;
        this.y += this.vy;


        if(this.x < 0){
            this.vx *= -1;
            this.x += this.speed
        } else if (this.x > stageWidth) {
            this.vx *= -1;
            this.x -= this.speed;
        }

        if(this.y < 0){
            this.vy *= -1;
            this.y += this.speed;
        } else if (this.y > stageHeight) {
            this.vy *= -1;
            this.y -= this.speed;
        }

        ctx.beginPath();
        
        const g = ctx.createRadialGradient(
            this.x,
            this.y,
            this.radius * 0.01,
            this.x,
            this.y,
            this.radius
        );
        
        g.addColorStop(0, `rgba(${this.color.red},${this.color.green},${this.color.blue},1)`);
        g.addColorStop(1, `rgba(${this.color.red},${this.color.green},${this.color.blue},0)`);
        

        ctx.fillStyle = g;
        if(this.x < 0 || this.y < 0 , this.radius <0){
            console.error('잘못된 값 :', this.x, this.y, this.radius, 0, Math.PI*2, false)
        }
        // console.log('arc',this.x, this.y, this.radius, 0, Math.PI*2, false)
        ctx.arc(this.x, this.y, this.radius, 0, Math.PI*2, false)
        ctx.fill()
    }
}


function gradientDraw ({setting,ctx,data,cache,setID,Refresh,option}){

    if(option?.parents=='view'){

    }
    if(Date.now()-Refresh.last > Refresh.fps){

    const Width = Number(data?.size?.width);
    const Height = Number(data?.size?.height);
    ctx.clearRect(0, 0, Width, Height);
    ctx.fillStyle = '#ffffff';
    
    ctx.fillRect(0,0,Width, Height);
    for (let index = 0; index < setting.totalParticles; index++) {
        const item = setting.particles[index];
        item.animate(ctx,Width,Height)
    }
    Refresh.last = Date.now();
   
    }
    if(option?.parents=='view'){
        console.log('gradientDraw,')
        return
    }

    const AnimationID =  requestAnimationFrame(() => gradientDraw({setting,ctx,data,cache,setID,Refresh}));
    setID(AnimationID)

}

// export function canvasDraw({ctx,data,cache,setID,Init,Refresh}){

//     if(Date.now()-Refresh.last > Refresh.fps){
//     const Height = Number(data?.size?.height);
//     const Width = Number(data?.size?.width);

//     const FontFamily = data?.style?.fontFamily;
//     const Preset = data?.preset;
//     ctx.clearRect(0, 0, Width, Height); // 캔버스 클리어

    
//     if(ctx){
//         switch (Preset) {
//             case 'static':

//                 const Cache = cache()
//                 const SetTime = getTimer(data,Cache);
                
//                 // console.log('구해진 시간 :',SetTime );

//                 const Hours = Math.floor(SetTime / 3600)
//                 const Minutes = Math.floor((SetTime % 3600) / 60)
//                 const Seconds = SetTime % 60
    
//                 const currentTime = (Hours!=0?Hours+':':'')+(Minutes>=10?Minutes:'0'+Minutes)+':'+(Seconds>=10?Seconds:'0'+Seconds);
//                 // console.log('currentTime',currentTime);
//                 // let currentTime = '10:00';
//                 ctx.fillText(currentTime,Init.width*0.05,Init.VerticalAlignment);
//                 // ctx.fillText(Init['fontFamily'],0,200);

//             break;
    
//             case 'liveCircle1':
    
//             break;
    
//             case 'liveSpeechIcon1':
    
//             break;
    
//             case 'liveSquare1':
    
//             break;
    
//             case 'liveSquare2':
    
//             break;
    
//             default:
    
//         }
//         Refresh.last = Date.now();
//     }


    
    
//     }

//     // let animationFrameId = requestAnimationFrame(() => canvasDraw({ctx,data,animation,start,last}));


//     // const test = requestAnimationFrame(()=> updateCanvas(ctx,canvasRef,x,requestId)); // 다음 프레임을 위한 애니메이션 프레임 요청

//      const test =  requestAnimationFrame(() => canvasDraw({ctx,data,cache,setID,Init,Refresh}));

//      setID(test)

//     // 애니메이션 식별자를 저장
//     // animation = animationFrameId;
    
// }