////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widgetEdit.scss"


// 컴포넌트 연결
// import Show from '../show/Show'; // 메뉴 헤더

// 서비스 연결

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class SentenceBlurModule extends Component {

    state = {
        value:''
    }

    
    static defaultProps = {
        callBackMode: 'onChange',
      }
    
    force(click){
        if (click){
            console.log('포커스',this.module)
            if(this.module){
                this.module.focus()
            }
            this.props.templateUnClick()
        }
    }
    componentWillUnmount () {
        if(this.module){
            this.module.blur()
        }
    }

    callBack = (e) =>{
        if (this.state.value!=''){
            this.props.change(this.props.type,this.state.value)
            this.setState({
                value:''
            })
        }
    } 

    render() { 
        this.force(this.props.templateCilck)
        return (
            <label className="SentenceSubmit" htmlfor="fname"> 
                <p >{this.props.title?this.props.title:'본문'}</p>
                <div className="SentenceField">
                    {}
                <input type="text" ref={(ref) => this.module=ref } 
                    placeholder={this.props.value} 
                    style={this.props.fontFamily?{fontFamily:this.props.fontFamily}:{fontFamily:'나눔스퀘어'}} 
                    value={this.state.value}
                    onChange={(e) => this.setState({value:e.target.value})}
                    />   
                <input type="submit" value="변경" onClick={(e) => this.callBack(e)} />
                </div>
                
            </label>
        );
    }
}

////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    templateCilck : state.templateCilck,
})

function mapDispatchToProps(dispatch){
    return {
        templateUnClick : () => dispatch({type:'templateClick',click : false}),
    //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(SentenceBlurModule);
  