////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widget.scss"
import "./preset/ClockPreset.scss"

// [프로바이더] 연결
import EditContext from 'service/context/EditContext' ; 

// 컴포넌트 연결
import WidgetOverRay from '../Edit/editController/WidgetOverRay'; // 메뉴 헤더
import * as Preset from './preset/ClockPreset';

// 서비스 연결
import * as Rest from '../../service/api/rest' ; 
import * as Event from '../../service/event/Actions' ; 
import * as Widget from '../../service/event/widget' ; 
import * as Model from '../../service/value/Model' ; 
import * as EnCoder from '../../service/engine/EnCoder' ; 
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class Clock extends Component {
    
    static defaultProps = {
        widgetName: 'Clock',
      }

    //[임시] 컨텍스트 연결
    static contextType = EditContext;

    state = {
        ...Widget.DefaultState(this),
        moveStartX : 0,
        moveStartY : 0,
        mode : 'Clock',
        play : false,
        time : 0,
    }

    constructor(props) {
        super(props);
        this.widget = React.createRef();
    }
    
    DefaultMethod = (type,value) =>{
        Widget.DefaultMethod(this,type,value)
    }
    
    componentDidMount() {
        this.timer()
        if (this.props.parents == 'broadcast'){
            let count = setInterval(this.timer, 1000)
            this.setState({
                interval: count,
            },)
        } else if (this.props.parents == 'edit') {
            let count = setInterval(this.timer, 1000)
            this.setState({
                interval: count,
            },) 
        }
    }

    componentWillUnmount() {
        clearInterval(this.state.interval);
    }

    timer = () => {
        this.setState({time: Rest.serverTime() });
    }


    outputTime = (kind) => {
        let date = new Date();
        switch (kind) {
            case 'hr':
                // Date 객체에서 "시간" 구하기
                let hours = date.getHours().toString()
                if (hours<10){hours ='0'+ hours}
                // 1시간일때 -> 01 시간으로 바꿔주기
                return hours;
            case 'min':
                // Date 객체에서 "분" 구하기
                let minutes = date.getMinutes().toString()
                // 1분일때 -> 01 분으로 바꿔주기
                if (minutes<10){minutes ='0'+ minutes}
                return minutes;
            case 'sec':
                // Date 객체에서 "초" 구하기
                let seconds = date.getSeconds().toString()
                // 1초일때 -> 01 초으로 바꿔주기
                if (seconds<10){seconds ='0'+ seconds}
                return seconds;
            case 'ms':
                
                break;                
            default:
                break;
        }
    }

    outputText = (kind) => {
        let date = new Date();
        let hours = date.getHours().toString()
        let minutes = date.getMinutes().toString()
        let seconds = date.getSeconds().toString()

        // 0 이하의 숫자가 나오는 경우
        if (seconds<10){seconds ='0'+ seconds}
        if (minutes<10){minutes ='0'+ minutes}
        
        const HRTABLE ={
            'krText' : '시',
            'enText' : 'h',
            'colon' : ':',
        }
        const MINTABLE ={
            'krText' : '분',
            'enText' : 'm',
            'colon' : ':',
        }
        const SECTABLE ={
            'krText' : '초',
            'enText' : 's',
            'colon' : ':',
        }
        const MDTABLE ={
            'krText' : {'AM' : '오전', 'PM' : '오후'},
            'enText' : {'AM' : 'AM', 'PM' : 'PM'},
            'colon' : {'AM' : 'AM', 'PM' : 'PM'},
        }
        switch (kind) {
            case 'md':
                let date = new Date();
                return (MDTABLE[this.props.data.value.outputText][(date.getHours()<12?'AM':'PM')]!=undefined?MDTABLE[this.props.data.value.outputText][(date.getHours()<12?'AM':'PM')]:'')
            case 'hr':
                return (HRTABLE[this.props.data.value.outputText]!=undefined? HRTABLE[this.props.data.value.outputText]:'시');
            case 'min':
                return (MINTABLE[this.props.data.value.outputText]!=undefined? MINTABLE[this.props.data.value.outputText]:'분');
            case 'sec':
                return (SECTABLE[this.props.data.value.outputText]!=undefined? SECTABLE[this.props.data.value.outputText]:'초');
            case 'ms':
                
                break;                
            default:
                break;
        }

    }

    

    outputType = () => {
        // let date = new Date(this.state.time);
        let date = new Date();
        let meridiemText = ""
        let hours = date.getHours()
        let minutes = date.getMinutes()
        let seconds = date.getSeconds()

        // 10초 미만의 숫자
        if (seconds<10){seconds ='0'+ seconds}
        if (minutes<10){minutes ='0'+ minutes}
        //if (hours<10){hours ='0'+ hours}
        switch (this.props.data.value.outputText) {
    
            case 'krText':
                if(!this.props.data.value.use24hour){
                    if (this.props.data.value.meridiemText){
                        if (hours<12){ meridiemText = "오전" }else { meridiemText = "오후"}
                    }
                    hours = hours%12
                }
                return (
                    meridiemText+' '+
                    hours + '시 '+
                    minutes + '분 '+
                    seconds + '초'
                    )
            case 'enText':
                if(!this.props.data.value.use24hour){
                    if (this.props.data.value.meridiemText){
                        if (hours<12){ meridiemText = "AM" }else { meridiemText = "PM"}
                    }
                    hours = hours%12
                }
                return (
                    meridiemText+' '+
                    hours + 'H '+
                    minutes + 'M '+
                    seconds + 'S'
                    )
            case 'colon':
                if(!this.props.data.value.use24hour){
                    if (this.props.data.value.meridiemText){
                        if (hours<12){ meridiemText = "AM" }else { meridiemText = "PM"}
                    }
                    hours = hours%12
                }
                return (
                    meridiemText+' '+
                    hours + ':'+
                    minutes + ':'+
                    seconds 
                    )
            default:
                return (
                    hours + ':'+
                    minutes + ':'+
                    seconds
                    )
        }
    }
   
    render() {
        let Style = Model.widget(this.props)
        // 드레그를 통한 위젯 이동

        Style.layout= { ...Style.layout,transform: 'translate('+this.state.dragMoveX+'px, '+this.state.dragMoveY+'px)'}
        switch (this.props.parents) {

            case 'edit':
                // 편집시 화면이동 트렌스레이션 효과 방지
                Style.layout.transition = false
                Style.object.transition = false
                if(this.props.userState.browser== "firefox"&&"safari"){
                    return (         
                        <div ref={(ref) => this.object=ref }  className = {this.props.parents + " widgetObject "+this.props.widgetName+Widget.display(this.props)} style={Style.layout} tabIndex={0} draggable="true" 
                            onKeyDown={(e)=>Widget.keyDown(e,this)}
                            onClick={(e)=> Widget.isfocus(e,this)} 
                            onMouseDown={(e)=> Widget.mouseControl(e,this)} 	
                            //드레그 이벤트	
                            onDragOver={(e)=>Widget.alt_drag(e,this)} 
                            onDragStart={(e) => Widget.alt_dragStart(e,this)} 
                            onDragEnd={(e) => Widget.alt_dragEnd(e,this)} 
                            onDrag={(e)=>e.stopPropagation()} 
                            //드랍 이벤트 
                            onDrop={(e)=> Widget.drop(e,this)} 
                            onDragLeave={(e)=> Widget.dragLeave(e,this)} 
                            onDragEnter={(e)=> Widget.dragEnter(e,this)} 
                        >
                               {Preset.data(this,Style)}
                        {/* <p className ="Clock" style={Style.object} ref={this.widget} > {this.outputType()} </p>    */}
                            {/* <div className='overRay'/> */}
                            { this.props.focus ? <WidgetOverRay target={this.props} object={this.widget.current} mode={'8_Direction'} dragLock={this.state.dragLock} DefaultMethod={this.DefaultMethod}/> :"" } 
                        </div>
                    );
                }else{
                    return (         
                        <div ref={(ref) => this.object=ref }  className = {this.props.parents + " widgetObject "+this.props.widgetName+Widget.display(this.props)} style={Style.layout} tabIndex={0} draggable="true" 
                            onKeyDown={(e)=>Widget.keyDown(e,this)}
                            onClick={(e)=> Widget.isfocus(e,this)} 
                            onMouseDown={(e)=> Widget.mouseControl(e,this)} 	
                            //드레그 이벤트
                            onDrag={(e)=>Widget.drag(e,this)} 
                            onDragStart={(e) => Widget.dragStart(e,this)} 
                            onDragEnd={(e) => Widget.dragEnd(e,this)} 
                            //드랍 이벤트 
                            onDrop={(e)=> Widget.drop(e,this)} 
                            onDragOver={(e)=> Widget.dragOver(e,this)} 
                            onDragLeave={(e)=> Widget.dragLeave(e,this)} 
                            onDragEnter={(e)=> Widget.dragEnter(e,this)} 
                        >
                               {Preset.data(this,Style)}
                        {/* <p className ="Clock" style={Style.object} ref={this.widget} > {this.outputType()} </p>    */}
                            {/* <div className='overRay'/> */}
                            { this.props.focus ? <WidgetOverRay target={this.props} object={this.widget.current} mode={'8_Direction'}/> :"" } 
                        </div>
                    );
                }

            case 'broadcast':
                return (
                    <div ref={(ref) => this.object=ref }  className = {this.props.parents + " widgetObject "+this.props.widgetName+Widget.display(this.props)} style={Style.layout} >
                        {Preset.data(this,Style)}  
                    </div>
                );
          
            case 'view':
                return (
                    <div ref={(ref) => this.object=ref }  className = {this.props.parents + " widgetObject "+this.props.widgetName+Widget.display(this.props)} style={Style.layout} >
                        {Preset.data(this,Style)}
                    </div>
                );
        }

    }
}
////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    userState : state.userState,
})

function mapDispatchToProps(dispatch){
    return {
        //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(Clock);
  