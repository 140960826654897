////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widgetEdit.scss"


// 컴포넌트 연결
import MediaSourceModule from '../editModule/MediaSourceModule'; // 텍스트 모듈
import ColorModule from '../editModule/ColorModule'; // 색상 모듈
import SizeModule from '../editModule/SizeModule';
import SwitchModule from '../editModule/SwitchModule'; // 체크박스 모듈
// 서비스 연결
import * as Editor from 'service/edit/EditService'; 
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class ADalertEdit extends Component {
    
    state = {
        value : '',
        editorToggle : true
    }

    change = (name,value) =>{
        this.props.callBack('style',name,value)
    }
    valueChange = (name,value) =>{
        this.props.callBack('value',name,value)
    }
    
    radioSelect = (e) =>{
        switch (e.target.value) {
            case 'AD':
                this.valueChange('type',e.target.value)
                break;
            case 'support':
                this.valueChange('type',e.target.value)
                break;
            case 'noneAD':
                this.valueChange('type',e.target.value)
                break;
            case 'other':
                this.valueChange('type',e.target.value)
                break;
            default:
 
                break;
        }
    }
    render() { 

        const Style = {
            label: {
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
            },
            textarea : {
                width: '100%',
                resize: 'none',
                boxSizing:'border-box',
            },
            mediaView : {
                width: '100%',
                MaxHeight : '500px',
            }

        }
        return (
            <section className='ADalertEdit'>
                {Editor.WidgetEditTitle(this,{title:'문구',toggle:false,icon:'open_in_full'})}
                <div className={this.state.editorToggle?'editContainer containerOpen':'editContainer containerClose'}>
                <article className="EditPart SentenceModule" >

                <div onClick={(e)=>this.radioSelect(e)}>
                <label ><input type="radio" value="AD" checked={this.props.data.value.type=='AD'?"checked":''} />  유료 광고 포함</label>
                </div>
                <div onClick={(e)=>this.radioSelect(e)}>
                <label><input type="radio"  value='support' checked={this.props.data.value.type=='support'?"checked":''} />  제조사로 부터 제품을 제공받음</label>
                </div>
                <div onClick={(e)=>this.radioSelect(e)}>
                <label><input type="radio" value='noneAD' checked={this.props.data.value.type=='noneAD'?"checked":''} />  무료 광고 포함</label>
                </div>
                <div onClick={(e)=>this.radioSelect(e)}>
                <label><input type="radio"  value='other' checked={this.props.data.value.type=='other'?"checked":''} />  기타</label>
                </div>
                <input type="text" ref={(ref) => this.module=ref } 
                    placeholder='여기에 문구를 적어주세요'
                    value={this.props.data.value.text}
                    onChange={(e) => this.valueChange('text',e.target.value)}
                    />   
                </article>
                </div>
            </section>
        );
    }
}

////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    // screenData : state.screenData,
})

function mapDispatchToProps(dispatch){
    return {
    //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(ADalertEdit);
  