////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";


// SASS&CSS 연결
import "sass/widget.scss"
import "./preset/ParticlesPreset.scss"

// [프로바이더] 연결
import EditContext from 'service/context/EditContext' ; 

// 컴포넌트 연결
import WidgetOverRay from '../Edit/editController/WidgetOverRay'; // 메뉴 헤더
import * as Preset from './preset/ParticlesPreset';

// 서비스 연결
import * as Event from '../../service/event/Actions' ; 
import * as Widget from 'service/edit/WidgetService' ; 
import * as Model from '../../service/value/Model' ; 
import * as EnCoder from '../../service/engine/EnCoder' ;
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class Particles extends Component {
    
    static defaultProps = {
        widgetName: 'Particles',
      }


    //[임시] 컨텍스트 연결
    static contextType = EditContext;
    
    state = {
        polling : 0,
    }
    
    constructor(props) {
        super(props);
        this.widget = React.createRef();
        this.canvas = React.createRef();
        this.ctx = null;
        this.particles = [];

    }

    componentDidMount() {
        this.ctx = this.canvas.current.getContext("2d")
        this.animate()
    }

    componentDidUpdate(prevProps) {
        if(this.props.data.value.particleCount != prevProps.data.value.particleCount){
            cancelAnimationFrame(this.animationRel)
            this.animate()
        }else{
            Preset.normal_canvas_set(this,this.props.data)
        }
    }

    componentWillUnmount(){
        cancelAnimationFrame(this.animationRel)
    }

    animate = (t) => {
        let tew1 = Preset.presetAnimate(this)
    }
    
    render() {
        const Style = this.props.getStyle(this.props.data)
        const Animation = this.props.getAnimation(this.props.animation,this.props.data)
        const MergeStyle = Widget.styleMerge(Style,Animation);

        // 드레그를 통한 위젯 이동
        // MergeStyle.layout= { ...MergeStyle.layout,transform: 'translate('+this.state.dragMoveX+'px, '+this.state.dragMoveY+'px)'}
        switch (this.props.parents) {
    
            case 'edit':
                // 편집시 화면이동 트렌스레이션 효과 방지
                MergeStyle.layout.transition = false
                MergeStyle.object.transition = false
                return (
                    <div className = {this.props.parents + " widgetObject "+this.props.widgetName+Widget.activate(this.props.data)}  style={MergeStyle.layout} >
                        {Preset.data(this,MergeStyle)}
                    </div>
                );
            case 'broadcast':
                return (
                    <div className = {this.props.parents + " widgetObject "+this.props.widgetName+Widget.activate(this.props.data)}  style={MergeStyle.layout} >
                        {Preset.data(this,MergeStyle)}
                    </div>
                );
          
            case 'view':
                return (
                    <div className = {this.props.parents + " widgetObject "+this.props.widgetName+Widget.activate(this.props.data)}  style={MergeStyle.layout} >
                        {Preset.data(this,MergeStyle)}
                    </div>
                );
        }

    }
}
////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    userState : state.userState,
})

function mapDispatchToProps(dispatch){
    return {
        //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}


  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(Particles);
  