////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widget.scss"

// [프로바이더] 연결
// import EditContext from 'service/context/EditContext' ; 

// 컴포넌트 연결
import WidgetOverRay from '../Edit/editController/WidgetOverRay'; // 메뉴 헤더

// 서비스 연결
import * as Event from '../../service/event/Actions' ; 
import * as Widget from 'service/edit/WidgetService' ; 
import * as Model from '../../service/value/Model' ; 
import * as WD from 'service/engine/decoder/WidgetDecoder' ; 
import * as EnCoder from '../../service/engine/EnCoder' ; 
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class Text extends Component {
    
    static defaultProps = {
        widgetName: 'Text',
      }

    //[임시] 컨텍스트 연결
    // static contextType = EditContext;
    
    state = {

    }

    constructor(props) {
        super(props);
        this.widget = React.createRef();
    }

    render() {

        const Style = this.props.getStyle(this.props.data)
        const Animation = this.props.getAnimation(this.props.animation,this.props.data)
        const MergeStyle = Widget.styleMerge(Style,Animation);

        const Height =this.props.data?.size?.height;
        const Width = this.props.data?.size?.width;
        const MinSize = Width<Height?Width:Height;

        switch (this.props.parents) {

            case 'edit':
                // 편집시 화면이동 트렌스레이션 효과 방지
                MergeStyle.layout.transition = false
                MergeStyle.object.transition = false
                return (         
                    <div className = {this.props.parents + " widgetObject "+this.props.widgetName+Widget.activate(this.props.data)} style={MergeStyle.layout}>
                        <pre style={{...MergeStyle.object,alignItems:Style.object.textVerticalAlign}}>{(this.props.data.value.text?this.props.data.value.text:<span style={{opacity:'50%'}}>여기에 텍스트를 입력해주세요</span>)}</pre>
                    </div>
                );
            case 'broadcast':
                return (
                    <div ref={(ref) => this.object=ref }  className = {this.props.parents + " widgetObject "+this.props.widgetName+Widget.activate(this.props.data)} style={MergeStyle.layout} >
                        <pre style={MergeStyle.object} >{this.props.data.value.text}</pre>
                    </div>
                );
          
            case 'view':
                return (
                    <div ref={(ref) => this.object=ref }  className = {this.props.parents + " widgetObject "+this.props.widgetName+Widget.activate(this.props.data)} style={MergeStyle.layout} >
                        <pre style={MergeStyle.object} >{this.props.data.value.text}</pre>
                    </div>
                );
        }

    }
}
////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    userState : state.userState,
})

function mapDispatchToProps(dispatch){
    return {
        //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}


  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(Text);
  