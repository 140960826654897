////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component, Fragment , useState, useEffect} from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { useHistory,useLocation } from 'react-router-dom';
// 라이브러리 

// [리덕스]스토어 연결
import store from "store";
// [프로바이더]


// SASS&CSS 연결
// import "sass/edit.scss"

// 이미지 연결
import logoW from 'assets/logoW.svg';

// 컴포넌트 연결
import WidgetListPanel from 'components/Edit/editPanel/preferences/utilPreferences/WidgetListPanel';//오버레이

// 서비스 연결
import * as Editor from 'service/edit/EditService'; 

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
function AppendWidget({size,icon,image,title,widget,option,version,create,dropAppendWidget}) {


    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////
    //set

    // state
    const [PanelState, setPanelState] = useState('widget');

    // rel 

   ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////
    useEffect(() => {
        // 여기에 컴포넌트가 마운트되었을 때 실행할 코드를 작성합니다.

        return () => {
        // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
        };
      }, []);



      const ContainerStyle = {
        'width': size,
        'height': size,
        'paddingTop' :(size/20)*3,
        'paddingBottom' :(size/20)*2,
        'box' :size/10,
      }

      const IconStyle = {
        'width': size/2,
        'height': size/2,
      }

      const TextStyle = {
        'width':  size,
        'height': size/5.
      }


      const UseIcon = (icon) => {

        if(icon){
          return(
            <div className = 'appendWidgetImage'  style={IconStyle} >
            {Editor.icon({icon:icon,size:size/2.5,lineHeight:size/2})}
            </div>
          )
        }else{
          return(
            <div className = 'appendWidgetImage'  style={IconStyle} >
            <img src={logoW}  style={IconStyle}/>
            </div>
          )
        }
     
      }

      const UseImage = (image) => {
        return(
          <div className = 'appendWidgetImage' style={IconStyle}>
          <img src={image?image:logoW}  style={IconStyle}/>
          </div>
        )
      }

      const onDragStart = (e) => {

        const preset = option.preset??null
        const positionX = option.positionX??null
        const positionY = option.positionY??null

        const Data = {
          widget,
          positionX,
          positionY,
          preset,
          version,
          option
        }

        dropAppendWidget(widget,Data)
        // e.dataTransfer.setData('text/plain', 'This is a draggable item');
      };

      const onDragEnd = (e) => {
        // console.log(e,'드레그끝')
        dropAppendWidget(null)
      };

      const addWidget=(e)=>{
        const preset = option.preset??null
        const positionX = option.positionX??null
        const positionY = option.positionY??null
        create(widget,positionX,positionY,preset,version,option)
      }

      
    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////
    return (
        <Fragment>
                <style jsx>{`${EditPageStyle}`}</style>
                <div className = 'appendWidgetContainer' style={ContainerStyle} draggable 
                onDragStart={onDragStart}
                onDragEnd={onDragEnd}
                onClick={addWidget}
                >
                  {image?UseImage(image):UseIcon(icon)}
                  <div className = 'appendWidgetTitle'  style={TextStyle} >
                  {title}
                  </div>
                </div>
        </Fragment>
    );

}

////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    // mainScreen : state.mainScreen,
})

function mapDispatchToProps(dispatch){
    return {
        // initOverlap : (overlapData) => dispatch({type:'initOverlap',overlap : overlapData}),
    }
}

  // export default EditPage;
export default connect(mapStateToProps,mapDispatchToProps)(AppendWidget);
  


  const EditPageStyle = `

  .appendWidgetContainer{
    display: flex;
    margin: 10px 0px 0px 10px;
    flex-direction: column;
    border-radius : 10px;
    background-color: #2C2C2C;
    box-shadow: 0 2px 5px rgba(0,0,0,.15);
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    transition: transform 0.1s ease-out, box-shadow 0.1s ease-out,  background 0.1s ease-out;
  }

  .appendWidgetContainer:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: transparent;
      border-radius: 10px;
      z-index: -1;
  }

  .appendWidgetContainer:active{
    transform: scale(0.80);
    background-color: #1C1C1C;
  }

  .appendWidgetContainer:active:before {
       transform: scale(1.25);
  }

  .appendWidgetContainer .appendWidgetTitle{
    pointer-events : none;
    margin-top:5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size : 12px;
  }

  .appendWidgetContainer .appendWidgetImage{
    pointer-events : none;
    font-size : 12px;
    display: flex;
    justify-content : center;
    align-items : center;
  }

  .appendWidgetContainer .appendWidgetImage img{
    pointer-events : none;
    object-fit: scale-down;
  }
  `;

  