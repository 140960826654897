import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // 다음 렌더링에서 폴백 UI가 보이도록 상태를 업데이트합니다.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // 오류 로깅 등 추가 작업을 수행할 수 있습니다.
    console.error('ErrorBoundary에서 오류를 포착했습니다:', error, info);
  }

  render() {
    if (this.state.hasError) {
      // 폴백 UI를 렌더링합니다.
      return <div></div>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;