////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { useState, useEffect, useContext, Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// 라이브러리 

// [리덕스]스토어 연결

// [프로바이더] 연결


// SASS&CSS 연결
// import "sass/edit.scss"



// 이미지 연결
import logoW from 'assets/logoW.svg';

// 컴포넌트 연결
import PageDisplay from 'components/display/StaticDisplay';
import GuideBalloon from 'components/common/form/GuideBalloon';

// 서비스 연결
import * as Editor from 'service/edit/EditService'; 
import { dragTransparent } from 'service/event/common' ; 
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
/**
 * PageNav 편집페이지목록 에서 

 * @param {EditProvider} context :  해당 오버랩 프로젝트의 세로 크기를 정의합니다.
 * 
 * @returns 
 */

function PageNav({context}) {

    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////
    // set

    // state
    // const [PageTitle, setPageTitle] = useState(page?.page_title);


    // rel 

   ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////
    useEffect(() => {
        // 여기에 컴포넌트가 마운트되었을 때 실행할 코드를 작성합니다.

        return () => {
        // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
        };
      }, []);
    
      

      
    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////
    
    // const addPage = () =>{
    //   context.addPage();
    // }


      console.log('context.selectPageIndex',context.selectPageIndex);

    const PageIndex = context.selectPageIndex!=null?context.selectPageIndex+1:null;

    return (
      <Fragment>
      <style jsx>{`${EditPageStyle}`}</style>
      <nav id="PageNav">
        <GuideBalloon guideText='새 페이지를 추가합니다.' direction='right'>
        <button className='PageButton' onClick={()=>context.addPage(context.selectPageIndex)} >
          {Editor.icon({icon:'new_window',size:20,lineHeight:30})}
        </button>
        </GuideBalloon>
        <GuideBalloon guideText={<span>스토어에서 페이지를<br/> 추가합니다.</span>} direction='right'>
        <button className='PageButton' onClick={()=> (context.extendEditor? context.setExtendEditor(null): context.setExtendEditor('template'))}>
          {Editor.icon({icon:'shop',size:20,lineHeight:30})}
        </button>
        </GuideBalloon>
        <GuideBalloon guideText={PageIndex?'지금 선택된 페이지 입니다.':'선택된 페이지가 표시됩니다.'} direction='right'>
        <button className='PageButton' style={{background:PageIndex?'orange':'gray'}}>
          {PageIndex?PageIndex:Editor.icon({icon:'select',size:20,lineHeight:30,color:'#ffffff'})}
        </button>
        </GuideBalloon>


      </nav>

      </Fragment>
    );

}

////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    // mainScreen : state.mainScreen,
})

function mapDispatchToProps(dispatch){
    return {
        // initOverlap : (overlapData) => dispatch({type:'initOverlap',overlap : overlapData}),
    }
}

  // export default EditPage;
export default connect(mapStateToProps,mapDispatchToProps)(PageNav);
  


  const EditPageStyle = `

  #PageNav{
    position: fixed;
    z-index : 9;
    display: flex;
    flex-direction: column;
    margin-top : 5px;
  }

  #PageNav .PageButton{
    width: 30px;
    height: 30px;
    margin : 5px 10px 5px 10px;
    background : #2C2C2C;
    border-radius : 5px;
    box-shadow: 
        0 4px 6px rgba(0, 0, 0, 0.2), /* 상대적으로 가까운 그림자 */
        0 1px 3px rgba(0, 0, 0, 0.1); /* 멀리 떨어진 더 퍼진 그림자 */
    transition: transform 0.3s ease, box-shadow 0.3s ease;    
    overflow : hidden; 
  }

  .PageButton:hover {
    transform: translateY(-2px); /* 버튼을 위로 약간 올림 */
    
    /* 호버시 그림자 변경 */
    box-shadow: 
        0 6px 8px rgba(0, 0, 0, 0.25),
        0 2px 4px rgba(0, 0, 0, 0.15);
}

  `; 

  