////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { useState, useEffect, useContext, Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widgetEdit.scss"


// 컴포넌트 연결
import GuideBalloon from 'components/common/form/GuideBalloon'// 가이드 벌룬
import ImageButtonModule from 'components/Edit/editModule/ImageButtonModule'; // 이미지 버튼을 눌러 여러 옵션중 값을 정합니다.
// import Show from '../show/Show'; // 메뉴 헤더

// 이미지 연결
import logoW from 'assets/logoW.svg';

// 서비스 연결
import * as Editor from 'service/edit/EditService'; 
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
function VarTeam({target,index,select,remove,selectTeam=false}) {
    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////

    // set

    // state

    // rel 

   ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////
    useEffect(() => {
        // 여기에 컴포넌트가 마운트되었을 때 실행할 코드를 작성합니다.
        return () => {
        // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
        };
    }, []);
    
    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////

    const selectHandler = (target) =>{

        if (select){
            select(target)
        }

    }

    return (
        <Fragment>
            {/* CSS 는 상위 컴포넌트인 ScoreboardEditForm 에 있습니다. */}
        <div className={selectTeam?'VarTeam select' :'VarTeam'} onClick={()=>selectHandler(target)}>

        <div className='TeamHeader' >
        <div className='TeamLogo'>
            <img src={target?.teamLogo??logoW} />
        </div>
        <div className='TeamLogoLeft' style={{background:`linear-gradient(110deg, #222222 50%, ${target?.teamColor??'#333'} 51%)`}}>

        </div>
            <div className='TeamName' style={{backgroundColor:target?.teamColor,color:target?.teamFontColor??'#fff'}}>
            {!target?.teamName?`새로운팀 `:target?.teamName}
            </div>
            {remove?
            <div className='TeamOption'>
                <button onClick={(event)=>{remove(target.id);event.stopPropagation()}}>
                {Editor.icon({icon:'remove',size:20,lineHeight:20})}
                </button>
            </div>
            :null}
        </div>

        
        <div className='TeamContent'>
    

        </div>   
            
        </div>
        </Fragment>
    )
}
export default VarTeam;

