////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widgetEdit.scss"


// 컴포넌트 연결
import TimeModule from '../editModule/TimeModule'; // 타이머 모듈
import RadioButtonModule from '../editModule/RadioButtonModule'; //범위 모듈 
import ImageButtonModule from '../editModule/ImageButtonModule';

// 서비스 연결
import * as Editor from 'service/edit/EditService'; 
// 이미지 연결

import time_1m from 'assets/sidebar/timer/time_1m.png';
import time_3m from 'assets/sidebar/timer/time_3m.png';
import time_5m from 'assets/sidebar/timer/time_5m.png';
import time_10m from 'assets/sidebar/timer/time_10m.png';

import leftButton from 'assets/sidebar/text/left.png';
import centerButton from 'assets/sidebar/text/center.png';
import rightButton from 'assets/sidebar/text/right.png';
import justifyButton from 'assets/sidebar/text/justify.png';

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class TextEdit extends Component {
    
    state = {
        editorToggle : true
    }

    change = (name,value) =>{
        this.props.callBack('style',name,value)
    }
    valueChange = (name,value) =>{
        this.props.callBack('value',name,value)
    }

    radioChange = (e) => {
        this.setState({
            raido : e.target.value
        })
    }

    render() { 

        const Style = {
            label: {
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
            },
            textarea : {
                width: '100%',
                resize: 'none'
            }
        }
        return (
            <section className='TextEdit'>
                {Editor.WidgetEditTitle(this,{title:'시간설정',toggle:false,icon:'more_time'})}
                <div className={this.state.editorToggle?'editContainer containerOpen':'editContainer containerClose'}>
                    <article className="EditPart MediaSourceModule" >
                        <div  className="timerSelect" >
                        <TimeModule value={this.props.data.value.time} model="ms" title="시간" type ="time" change={this.valueChange}/>
                        </div>
                        <ImageButtonModule value={this.props.data.value.time} type ="time" data={[{val:60,image:time_1m},{val:180,image:time_3m},{val:300,image:time_5m},{val:600,image:time_10m},]} title="빠른설정"  change={this.valueChange}/>

                        <ImageButtonModule value={this.props.data.style.textAlign} type ="textAlign" data={[{val:'left',image:leftButton,text:'text'},{val:'center',image:centerButton},{val:'right',image:rightButton},{val:'justify',image:justifyButton}]} title="좌우 정렬"  change={this.change}/>
                    </article>
                </div>
            <Fragment>

            </Fragment>
            </section>
        );
    }
}

////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    // screenData : state.screenData,
})

function mapDispatchToProps(dispatch){
    return {
    //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(TextEdit);
  