////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, {useState, useEffect, Fragment, useContext, useRef } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { useHistory,useLocation } from 'react-router-dom';
// [리덕스]스토어 연결
import store from "store";
// [프로바이더]
import EditContext from 'service/context/EditContext' ; 

// SASS&CSS 연결
// import "sass/edit.scss"

// 이미지 연결
import logoW from 'assets/logoW.svg';

// 컴포넌트 연결
// import ex from 'components/edit/editScreen/ex'; // 설명

// 서비스 연결
import * as Editor from 'service/edit/EditService'; 


////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
function MenuPanel() {


    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////
    //set
    const context = useContext(EditContext);

    // state
    const [MetaMenu, setMetaMenu] = useState(false);
    // const [Ex, setEx] = useState(null);
    const history = useHistory();
    // rel 

   ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////
    useEffect(() => {
        // 여기에 컴포넌트가 마운트되었을 때 실행할 코드를 작성합니다.

        return () => {
        // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
        };
      }, []);


    const goLobby = () => {
      history.push({
        pathname: '/main',
      });
    }

    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////
    return (
        <Fragment>
                <style jsx>{`${EditPageStyle}`}</style>
                <div id = "MenuPanel">

                    <div className = "defaultDivision" onClick={()=>{setMetaMenu(!MetaMenu);context.setExtendEditor(null)}}>
                        <div className='buttonDivision'>
                            <button id = "LobbyButton">
                                <img className = "buttonLogoImage" src={logoW}/>
                            </button>
                        </div>
                        <div className='infoDivision'>
                            <p className='panelTitle'> 가이드 및 이동  <div style={{margin:'0px 5px '}}>{Editor.icon({icon: MetaMenu?"stat_1":"stat_minus_1",size:15,lineHeight:20,color:'#ffffff'})}</div></p>
                        </div>
                    </div>
                    
                    <div className = { MetaMenu?"on expandDivision":"off expandDivision"}>
                   
                      <div className="topNav" >
                      <button className="MetaMenuButton" onClick={()=>goLobby()}>
                        <div className="MenuImage">
                        {Editor.icon({icon:'arrow_back',size:25,lineHeight:35,color:'#ffffff'})}
                        </div>
                        <div className="MenuInfo">
                          <div className="MenuTitle">
                            로비 페이지로 이동
                          </div>
                        </div>  
                      </button>
                      
                        {/* <button className="MetaMenuButton">
                          <div className="MenuImage">
                          {Editor.icon({icon:'edit_document',size:25,lineHeight:35,color:'#ffffff'})}
                          </div>
                          <div className="MenuInfo">
                            <div className="MenuTitle">
                              프로젝트 설정
                            </div>
                          </div>  
                        </button> */}

                        {/* <button className="MetaMenuButton">
                          <div className="MenuImage">
                          {Editor.icon({icon:'page_info',size:25,lineHeight:35,color:'#ffffff'})}
                          </div>
                          <div className="MenuInfo">
                            <div className="MenuTitle">
                              편집화면 설정
                            </div>
                          </div>  
                        </button> */}

                      </div>
                      <div className="bottomNav" >

                      {context.extendEditor != 'guide'?
                          <button className="MetaMenuButton" onClick={()=> (context.setExtendEditor('guide'))}>
                          <div className="MenuImage">
                          {Editor.icon({icon:'info',size:25,lineHeight:35,color:'#ffffff'})}
                          </div>
                          <div className="MenuInfo">
                            <div className="MenuTitle">
                              편집 가이드
                            </div>
                          </div>  
                          </button>
                        :
                          <button className="MetaMenuButton" onClick={()=>{setMetaMenu(false);context.setExtendEditor(null)}}>
                          <div className="MenuImage">
                          {Editor.icon({icon:'arrow_forward',size:25,lineHeight:35,color:'#ffffff'})}
                          </div>
                          <div className="MenuInfo">
                            <div className="MenuTitle">
                              돌아가기
                            </div>
                          </div>  
                        </button>
                        }

                       
                        <button className="MetaMenuButton" onClick={()=>window.open('/guide', '_blank')}>
                          <div className="MenuImage">
                          {Editor.icon({icon:'book',size:25,lineHeight:35,color:'#ffffff'})}
                          </div>
                          <div className="MenuInfo">
                            <div className="MenuTitle">
                             가이드 페이지로 이동 <div style={{marginLeft:'5px'}}></div>  {Editor.icon({icon:'open_in_new',size:15,lineHeight:20,color:'#ffffff'})}
                            </div>
                          </div>  
                        </button>

                      </div>

                     
                    </div>

          
                </div>
        </Fragment>
    );

}

////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    // mainScreen : state.mainScreen,
})

function mapDispatchToProps(dispatch){
    return {
        // initOverlap : (overlapData) => dispatch({type:'initOverlap',overlap : overlapData}),
    }
}

  // export default EditPage;
export default connect(mapStateToProps,mapDispatchToProps)(MenuPanel);
  


  const EditPageStyle = `

  #MenuPanel {
    z-index : 100;
    background-color: #3F3F3F;
    border-radius: 5px;
    width: 320px;
    transition: height 0.3s ease;
    position: relative;

  }

  #MenuPanel .defaultDivision{
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    background-color: #2C2C2C;
    border-radius: 5px;
    width: 100%;
  }


  #MenuPanel .expandDivision{
    margin-top :43.5px;
    transition: all 0.3s ease;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;

    justify-content: space-between;
    position: absolute;
    border-radius: 5px;
    top:0px;
    background-color: #3F3F3F;
    overflow: hidden;

  }
  #MenuPanel .on.expandDivision{
    height: calc( 100vh - 80px );
    box-sizing: border-box;

  }
  #MenuPanel .off.expandDivision{
    height: 0px;
    
  }

  #MenuPanel .topNav{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
  }

  #MenuPanel .bottomNav{
     display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-bottom : 10px;
  }
  #MenuPanel .buttonDivision{

  }


  #MenuPanel #LobbyButton {
    display: flex;
    background-color: #FF9900;
    border-radius: 999px;
    width: 24px;
    margin : 6px;
    height: 24px;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    
  }  
  
  
  #MenuPanel #LobbyButton .buttonLogoImage{
    width: 70%;
    height: 70%;
    
  }

  #MenuPanel .infoDivision{
    
  }

  #MenuPanel .infoDivision .panelTitle{
    margin : 6px;
    display : flex;
  }
  

  #MenuPanel .MetaMenuButton{
    background-color: #2C2C2C;
    border-radius: 5px;
    width: calc( 100% - 20px);
    height: 48px;
    margin : 10px 10px 0px 10px ;
    color : white;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    border : 1px solid #2C2C2C;
   
  }

  #MenuPanel .MetaMenuButton:hover{
    background: #444;
    border : 1px solid white;
    box-sizing: border-box;
  }
  
  #MenuPanel .MetaMenuButton .MenuImage{

    background: #3F3F3F;
    width: 35px;
    height: 35px;
    border-radius: 999px;
    margin-left :10px;

  }

  #MenuPanel .MetaMenuButton:hover .MenuImage{

    background: #2C2C2C;
    width: 35px;
    height: 35px;
    border-radius: 999px;
    margin-left :10px;

  }

  #MenuPanel .MetaMenuButton .MenuInfo{
    display: flex;
    flex-direction: column;
    flex : 1;
    height: 40px;
    margin : 4px 10px;
  }

  #MenuPanel .MetaMenuButton .MenuTitle{
    display: flex;
    line-height : 40px;
    justify-content: start;
    align-items: center;
    font-size : 20px;
    height: 40px;
    
  }
  #MenuPanel .MetaMenuButton .MenuString{
    font-family: '프리텐다드Regular';
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    font-size : 15px;
    height: 25px;
  }
  `;
  