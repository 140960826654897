import React, { Component , Fragment } from 'react';
function preset(preset,self,data,style){
    let fullsize = data.size.height
    let width = data.size.width
    let profile = self.state.profile
    let dataList = []
    let dataField =''

    let opacity= Math.round(data.style.fillOpacity/100 * 255).toString(16)
    if (opacity.length==1){ opacity= 0+opacity }
    let main_fill = data.style.main_fill+opacity
    let sub_fill = data.style.sub_fill+opacity

    let now = new Date().getTime();
    let dday = Math.ceil((new Date(data.value.date) - now) / (1000 * 60 * 60 * 24));
    switch (preset) {
        case 'calendar':

                return(
                    <div className={"object "+self.props.widgetName+"_preset_"+preset} ref={self.widget}  style={{...style.object}}>
                        <div className="head" style={{'backgroundColor':main_fill,'color':data.style.main_color}}>
                            <h3>{data.value.info?data.value.info:'일정제목'}</h3>
                           
                        </div>
                        <div className="body" style={{'backgroundColor':sub_fill,'color':data.style.sub_color}}>
                            <div className="date">
                            <p>{data.value.date?data.value.date:'날짜입력'}</p>
                            </div>
                            {
                                data.value.date?
                                <div className="day">
                                {(new Date(data.value.date) - now)>0 ?<h1>{'D-'+dday}</h1>:<h3>{dday*-1+'일 경과'}</h3>}
                                </div>
                                :
                                <div className="day disable">
                                <h3>D-DAY</h3>
                                </div>
                            }
                          
                         
                        </div>
                    </div>
                )
        case 'miniWidget':

            return(
                <div className={"object "+self.props.widgetName+"_preset_"+preset} ref={self.widget}  style={{...style.object}}>
                    <div className="head" style={{'backgroundColor':main_fill,'color':data.style.main_color}}>
                        <h3>{data.value.info?data.value.info:'일정제목'}</h3>
                        
                    </div>
                    <div className="body" style={{'backgroundColor':sub_fill,'color':data.style.sub_color}}>
                        {
                            data.value.date?
                            <div className="day">
                            {(new Date(data.value.date) - now)>0 ?<h1>{'D-'+dday}</h1>:<h3>{dday*-1+'일 경과'}</h3>}
                            </div>
                            :
                            <div className="day disable">
                            <h3>D-DAY</h3>
                            </div>
                        }
                    </div>
                </div>
            )    
        default:
            return(
                <div className={"object unknownPreset"} ref={self.widget} style={{...style.object}} >
                    <p>알수없는 프리셋</p>
                </div>
                )
    }
}
export function data(self,style) {
    return preset(self.props.data.preset,self,self.props.data,style)
}

