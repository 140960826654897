import React, { Component } from 'react';
import io from 'socket.io-client';

class ChatRoom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roomId: '',
      username: '',
      message: '',
      messages: []
    };
    this.socket = io('http://localhost:3030/overlap');
  }

  componentDidMount() {
    this.socket.on('chat message', (message) => {
      this.setState({
        messages: [...this.state.messages, message]
      });
    });
  }

  handleRoomIdChange = (event) => {
    this.setState({ roomId: event.target.value });
  }

  handleUsernameChange = (event) => {
    this.setState({ username: event.target.value });
  }

  handleMessageChange = (event) => {
    this.setState({ message: event.target.value });
  }

  handleJoinRoom = () => {
    this.socket.emit('join room', this.state.roomId);
  }

  handleLeaveRoom = () => {
    this.socket.emit('leave room', this.state.roomId);
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const { username, message } = this.state;
    this.socket.emit('chat message', `${username}: ${message}`, this.state.roomId);
    this.setState({ message: '' });
  }

  render() {
    return (
      <div>
        <h1>Chat Room</h1>
        <div>
          <input type="text" placeholder="Room ID" value={this.state.roomId} onChange={this.handleRoomIdChange} />
          <button onClick={this.handleJoinRoom}>Join Room</button>
          <button onClick={this.handleLeaveRoom}>Leave Room</button>
        </div>
        <ul>
          {this.state.messages.map((message, index) => (
            <li key={index}>{message}</li>
          ))}
        </ul>
        <form onSubmit={this.handleSubmit}>
          <input type="text" placeholder="Username" value={this.state.username} onChange={this.handleUsernameChange} />
          <input type="text" placeholder="Message" value={this.state.message} onChange={this.handleMessageChange} />
          <button>Send</button>
        </form>
      </div>
    );
  }
}

export default ChatRoom;