import { forEach } from 'lodash';
import React, {  Fragment } from 'react';
import * as Editor from 'service/edit/EditService'; 

function preset(preset,self,data,style){

    const Width = Number(data?.size?.width??0);
    const Height = Number(data?.size?.height??0);

    
    const Cache = self?.context?.getChannelCache({'alias':self.props.alias,'type':self.props.type,'uuid':self.props.uuid})??{};
    const Team = data?.value?.teamData;
    let timerAreaHeight = 20;
    let banAreaHeight = 60;
    let pickAreaHeight = Height-timerAreaHeight-banAreaHeight; 

    const BanPickList = Cache?.banPickList;
    const Step = Cache?.step;
    
    switch (preset) {
        case 'default':

            const getPick = (asset,team,index,focus) =>{

                if(asset){
                return(
                    <Fragment>
                     
                    {team=='right'? <div className="pickLine" />:null}
                   
                    <div className="pickElement">
                            {asset?.image? 
                            <div className="pickImage">
                                <img src={asset?.image}/>
                            </div>
                            : 
                            <div className="pickIcon">
                                {Editor.icon({icon:'account_circle',size:80,lineHeight:100,color:'#bbb'})} 
                            </div>
                            }
       
                        <div className="pickInfo">
                            <div className="pickTarget">
           
                                <h2 className="pickName">{asset?.nameKo}</h2>
                            </div>
       
                            <h2 className="pickUser">{Team?.[team=='left'?0:1]?.player?.[index]?.name}</h2>
                        </div>

                    </div>
                    {team=='left'? <div className="pickLine" />:null}
                    </Fragment>
                )}
                else{
                    return(
                        <Fragment>

                        {team=='right'? <div className="pickLine" />:null}
           
                        <div className="pickElement">
                            <div className="pickIcon">
                                {Editor.icon({icon:'account_circle',size:80,lineHeight:100,color:'#bbb'})} 
                            </div>
                            <div className="pickInfo">
                                <div className="pickTarget">
                 
                                    <h2 className="pickName">{asset?.nameKo}</h2>
                                </div>
        
                                <h2 className="pickUser">{Team?.[team=='left'?0:1]?.player?.[index]?.name}</h2>
                            </div>
                            {focus?
                            <div className="TargetPoint" style={{ background: `linear-gradient(to bottom, transparent, ${Team?.[team=='left'?0:1]?.teamColor})`}} />
                            :null}
                        </div>
                        {team=='left'? <div className="pickLine" />:null}
                        </Fragment>
                    )
                }
            }


            const getBan = (asset,index,focus) =>{

                if(asset){
                    return(
                        <Fragment>
                            <div className="banElement" style={{width:banAreaHeight,height:banAreaHeight }}>
                                <div className="banImage">
                                <img src={asset?.image}/>
                                </div>
                            </div>
                        </Fragment>
                    )
                }
                else{
                    return(
                        <Fragment>
                            <div className="banElement" style={{width:banAreaHeight,height:banAreaHeight }}>
                                <div className="banIcon">
                                    {Editor.icon({icon:'close',size:40,lineHeight:50,color:'#bbb'})} 
                                </div>
                                {focus?
                                <div className="TargetPoint" />
                                :null}
                            </div>

                        </Fragment>
                    )
                }


            }

            const LeftPickRule = [];
            const LeftBanRule = [];
            const RightPickRule = [];
            const RightBanRule = [];
            const RuleList = data?.value?.banPickRule??[]

            let ruleIndex = 0
            RuleList.forEach((element) => {
             
                element['index'] = ruleIndex;
                ruleIndex = ruleIndex +1;
                if(element.type == 'pick'){
                    if(element.team == 'left'){
                        LeftPickRule.push(element)
                    }else if(element.team == 'right'){
                        RightPickRule.push(element)
                    }
                }else if(element.type == 'ban'){
                    if(element.team == 'left'){
                        LeftBanRule.push(element)
                    }else if(element.team == 'right'){
                        RightBanRule.push(element)
                    }
                }

                
            });

            return(
            <div className="BanPick defaultBanPick" ref={self.widget} style={{...style.object}}>

                <section className="banArea" style={{height:banAreaHeight }}>
                    <div className="leftTeam">
                        {LeftBanRule.map((e,index)=>{ return(getBan(BanPickList?.['leftBan']?.[index],index,Step==e.index))})}

                    </div>
                    <div  className="rightTeam">
                        {RightBanRule.map((e,index)=>{ return(getBan(BanPickList?.['rightBan']?.[index],index,Step==e.index))})}

                    </div>
                </section>
                <section className="timerArea" style={{height:timerAreaHeight }}>
                    <div className='timer'/>
                </section>
                <section className="pickArea" style={{height:pickAreaHeight }}>
                    <div className="leftTeam">
                    {LeftPickRule.map((e,index)=>{ return(getPick(BanPickList?.['leftPick']?.[index],'left',index,Step==e.index))})}
                    </div>
                    <div className="centerInfo">
                            
                        <div  className="mainInfo">
                            <div  className="leftTeamInfo">
                                {Team?.[0]?.teamLogo? 
                                    <img className="TeamImage" src={Team?.[0]?.teamLogo}/>
                                : null }
                                <p className="TeamName">
                                {Team?.[0]?.teamName??'왼쪽팀'}
                                </p>
                            </div>
                            <div  className="centerTeamInfo">
                                <p className="centerPoint">
                                -
                                </p>
                            </div>
                            <div  className="rightTeamInfo">
                                {Team?.[1]?.teamLogo? 
                                    <img className="TeamImage" src={Team?.[1]?.teamLogo}/>
                                : null }
                                <p className="TeamName">
                                {Team?.[1]?.teamName??'오른쪽팀'}
                                </p>
                            </div>
                        </div>
  
                    </div>
                    <div  className="rightTeam">
                        {RightPickRule.map((e,index)=>{ return(getPick(BanPickList?.['rightPick']?.[index],'right',index,Step==e.index))})}
                    </div>
                </section>
         
            </div>
        )
        default:
            return(
            <div className={"object unknownPreset"} ref={self.widget} style={{...style.object}} >
                <p>알수없는 프리셋 {preset} </p>
            </div>
        )
    }
}
// export function data(data,style,self) {
//    return preset(data.preset,data,style,self)
// }

export function data(self,style) {
    return preset(self.props.data.preset,self,self.props.data,style)
}

