////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widgetEdit.scss"


// 컴포넌트 연결
// import Show from '../show/Show'; // 메뉴 헤더

// 서비스 연결
import rotateZero from 'assets/sidebar/rotate/rotateZero.png';
import rotatePlus5 from 'assets/sidebar/rotate/rotatePlus5.png';
import rotateMinus5 from 'assets/sidebar/rotate/rotateMinus5.png';


import enlargement from 'assets/sidebar/rotate/enlargement.png';
import reduction from 'assets/sidebar/rotate/reduction.png';
import scaleReset from 'assets/sidebar/rotate/scaleReset.png';

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class RotateModule extends Component {

    buttonClick = (value)=>{

        const min = Number(this.props.min)
        const max = Number(this.props.max)


        let rotate
        if(max<this.props.value+value){
            rotate = min +5
        }else if (min>=this.props.value+value){
            rotate = max
        }else{
            rotate = this.props.value+value
        }

        
        this.props.change(this.props.type,rotate)
    }


    render() { 
   
        let step = 1
        let plus
        let minus
        let reset
        let resetValue = 0
        if(this.props.step){
            step = this.props.steps
        }
        switch (this.props.valueView) {
            case '도':
                reset = rotateZero
                plus = rotatePlus5
                minus = rotateMinus5
                resetValue = 0

                break;
    
            case '%':
                reset = scaleReset
                plus = enlargement
                minus = reduction
                resetValue = 100
                break;
        
            default : 
                break;
        }

        return (
            <label className="EditModule RotateModule" htmlfor="fname"> 
                <p className="editNav" >{this.props.title?this.props.title:'옵션'}</p>
                <div className="valueView">
                    <span className="rotateText" >
                        {/* {(this.props.valueView?this.props.value+''+this.props.valueView+' ':'')} */}
                        {(this.props.valueView?
                            <Fragment>
                            <input className='' type='number' value={this.props.value} min='-180' max='180' onChange={(e) => this.props.change(this.props.type,e.target.value)} />
                            <sub>{this.props.valueView}</sub>
                            </Fragment>
                            :'')}
                    </span>
                    <span className="rotateButtonList" >
                    <div className={'optionButton '} onClick={(e) => this.props.change(this.props.type,resetValue)} >
                        <img className="ButtonImage" src={reset}/>
                    </div>
                    <div className={'optionButton '} onClick={(e) => this.buttonClick(5)} >
                        <img className="ButtonImage" src={plus}/>
                    </div>
                    <div className={'optionButton '} onClick={(e) => this.buttonClick(-5)} >
                        <img className="ButtonImage" src={minus}/>
                    </div>
                    </span>
                {/* <input type="range" className="rangeInput" name="volume" min={this.props.min} step={step} max={this.props.max} value={this.props.value} onChange={(e) => this.props.change(this.props.type,e.target.value)} /> */}
                </div>

            </label>
        );
    }
}

////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    // screenData : state.screenData,
})

function mapDispatchToProps(dispatch){
    return {
    //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(RotateModule);
  