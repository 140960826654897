
////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결

// 라이브러리 
import io from 'socket.io-client';
// 설정 파일
import * as config from 'config/OverlapConfig'
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결

// 이미지 연결

// 서비스 연결
import * as Rest from 'service/api/rest' ;
import { token } from 'service/event/Account' ; 

////////////////////////////////////////////////////////
// store 연결
////////////////////////////////////////////////////////

const SERVERURL = config.SERVERS[0].serverEndPoint+":"+'3030';
////////////////////////////////////////////////////////
/** pageStart
 * @returns 페이지 IO 객체
 */
////////////////////////////////////////////////////////

export function start() {
    const view = io(config.SERVERS[0].serverEndPoint+":"+'3030'+'/page');
    return view;
}


////////////////////////////////////////////////////////
/** overlapcCreate
 * @param spaceId 오버랩을 생성할 스페이스의 ID를 적습니다.
 * @param data 오버랩 생성에 필요한 정보를 적습니다. {title,resolution,private,private,summary}
 * @returns 오버랩 IO 객체
 */
////////////////////////////////////////////////////////

export async function getPageForUUID(uuid) {

    let response =  await Rest.request('/page/uuid','Get',{"uuid":uuid},token('account'));
    if(response?.status == 200){
        console.log('response',response)

        return response?.data?.data;
    }
    return [];
}

