////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { useState, useEffect, useContext, useRef, Fragment } from 'react';
import * as chzzk from "chzzk";
import io from 'socket.io-client';
// SASS&CSS 연결
import "./preset/ChatV1Preset.scss"
// [프로바이더]
import EditContext from 'service/context/EditContext' ; 

// 서비스 연결
import * as Widget from 'service/edit/WidgetService' ; 
import * as Preset from './preset/ChatV1Preset';
import * as chatAPI from 'service/api/chat' ; 
import * as Utility from 'service/other/Utility'; 
import * as Editor from 'service/edit/EditService' ; 
import { widget } from 'service/value/Model';
import { token } from 'service/event/Account';
import { display } from 'service/event/widget';
import { forEach } from 'lodash';
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////



function ChatV1({keyValue,pageKey,alias,type,key,id,uuid,getStyle,getAnimation,animation,parents,data}){


    ////////////////////////////////////////////////////////
    // Const
    ////////////////////////////////////////////////////////

    // set
    const WidgetRef = useRef(null);
    const CacheRef = useRef(null); // currentTime을 저장할 ref 생성
    const context = useContext(EditContext);

    const SocketRef = useRef(null); // useRef 추가
    const ChatList = useRef([]); // useRef 추가
    const PrevServiceRef = useRef([]); // useRef 추가

    /* 모든 위젯에서만 사용되는 고유 상수 */
    const Style = getStyle(data) // Style | 스타일을 가지고 옵니다.
    const Animation = getAnimation(animation,data) // Animation | 애니메이션을 가지고 옵니다.
    const MergeStyle = Widget.styleMerge(Style,Animation); // MergeStyle | 스타일과 애니메이션을 합칩니다.


    const WidgetActivate  = Widget.activate(data); // WidgetActivate | 위젯이 숨김상태인지 아닌지 판단합니다.
    const Width = data?.size?.width // Width | 위젯의 가로 사이즈
    const Height =data?.size?.height // Height | 위젯의 세로 사이즈
    // /* 해당 위젯에서만 사용되는 고유 상수 */
    // const MediaSource = data.value.mediaSource

    const [socket, setSocket] = useState(null);
    const [messages, setMessages] = useState([]);
    // const [PrevService, setPrevService] = useState(data?.value?.service);

    // const [YoutubeState, setYoutubeState] = useState('stop');
    // const [Progress, setProgress] = useState('load');

    // // const [VideoType, setVideoType] = useState(() => data.func.getVideoType(MediaSource));
    // const prevVideoTypeRef = useRef(VideoType);

    // const [ Cache,setCache] = useState(null);
    // const [SeekTime,setSeekTime] = useState(0);

    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////

    useEffect(() => {
        if(parents != 'view'){
            mount();
        }

        return () => {
            if(parents != 'view'){
                unmount();
            }
        } 
      }, []);


    const mount = () => {
        // 뷰 모드로 위젯을 생성하면 소켓을 사용하지 않습니다.
        if(parents == 'view'){
            return 
        }
        // 소켓을 가져옵니다.
        SocketRef.current = chatAPI.start();
        // 채팅을 가지고 올 서비스와 토큰을 저장합니다.
        PrevServiceRef.current = data?.value?.service;
        // 채팅을 가지고 옵니다.
        getChat(PrevServiceRef.current);
    }

    const unmount = () => {
        console.log('[Chat] unmount',unmount)

        // delChat();
        if(PrevServiceRef.current&&SocketRef.current){
            SocketRef.current.emit('leave', {
                uuid: PrevServiceRef.current?.[0]?.token,
                accountToken: token('account'),
            });
            SocketRef.current.disconnect();
        }
    }

    // 서비스 토큰 삭제 -> 새로운 토큰 연결 해야 하는데 반대로 연결 -> 삭제 그로 인한 사이드 이펙트
    useEffect(() => {
        if(parents != 'view'){
            if(!compareObjects(PrevServiceRef.current,data?.value?.service)){
                PrevServiceRef.current = data?.value?.service;
                // chatServiceChange(PrevServiceRef.current)
                refreshSocket(PrevServiceRef.current);
            }
    
        }

    }, [data?.value?.service]);

    /**
     * 컴포넌트와 연결된 채팅 서비스를 변경합니다.
     * @param {*} service 
     */
    const chatServiceChange = (service) =>{
        if(service){
            refreshSocket(service)
        }

    }

    const refreshSocket = async (service) => {
        if(SocketRef){
            await SocketRef.current.emit('leave', {
                uuid: service?.[0]?.token,
                accountToken: token('account'),
            });
            SocketRef.current.off('message');
            setMessages([])
    
            getChat(PrevServiceRef.current);
        }
    }

    ////////////////////////////////////////////////////////
    // Func
    ////////////////////////////////////////////////////////


    const getChat = (service) => {
        const ServiceList = []

        // 서비스가 없는 경우
        if(service.length == 0){
            // 소켓 메시지를 받지 않도록 설정합니다.
            // SocketRef.current.off('message')
            return
        }

        for (const chat of service) {
            if(chat?.token == null  || chat?.platform == null){
                continue;
            }
            // SocketRef.current.off('message')
            SocketRef.current.emit('join', {
                uuid : chat.token,
                platform : chat.platform,
                accountToken : token('account'), 
            });
            ServiceList.push(`${chat.platform}_${chat.token}`);
        }

        ChatList.current = ServiceList;

        if(ServiceList){
            SocketRef.current.off('message')
            SocketRef.current.on('message', (message) => {
                setMessages((prevMessages) => {
                    // 기존 메시지가 50개 이상이면 앞에서부터 제거
                    const updatedMessages = prevMessages.length >= 50 ? prevMessages.slice(1) : prevMessages;
                    // 새 메시지를 추가한 배열 반환
                    return [...updatedMessages, message];
                });
            });
        }

    }

    const delChat = () => {

        console.log('[Chat] ChatList.current',ChatList.current);
        SocketRef.current.off('message')
        if(PrevServiceRef.current&&SocketRef.current){
            SocketRef.current.emit('leave', {
                uuid: PrevServiceRef.current?.[0]?.token,
                accountToken: token('account'),
            });
        }
    }

    
    const chatStart =  async (chzzkChat) => {
        await chzzkChat.connect()
    }
  
    
    ////////////////////////////////////////////////////////
    // Widget lander
    ////////////////////////////////////////////////////////

    switch (parents) {
        /* 편집화면에서 보이는 위젯의 모습 */
        case 'edit':
            // 편집화면에서는 애니메이션 효과를 비활성화 합니다.
            MergeStyle.layout.transition = false
            MergeStyle.object.transition = false
            return (
                <Fragment>
                <style jsx>{`${WidgetStyle}`}</style>
                <div style={{...MergeStyle.layout}} className = {parents + " widgetObject "+type+WidgetActivate} >
                {Preset.getPreset(data,type,MergeStyle,{messages:messages.length == 0 ? TempChat:messages})}
                </div>
                </Fragment>
        
            );
        /* 방송화면에서 보이는 위젯의 모습 */
        case 'broadcast':
            return (
                <Fragment>
                <style jsx>{`${WidgetStyle}`}</style>
                <div style={{...MergeStyle.layout}} className = {parents + " widgetObject "+type+WidgetActivate} >
                {Preset.getPreset(data,type,MergeStyle,{messages:messages.length == 0 ? TempChat:messages})}
                </div>
                </Fragment>
        
            );
        /* 미리보기 화면에서 보이는 위젯의 모습 */
        case 'view':
            return (
                <Fragment>
                <style jsx>{`${WidgetStyle}`}</style>
                <div style={{...MergeStyle.layout}} className = {parents + " widgetObject "+type+WidgetActivate} >
                {Preset.getPreset(data,type,MergeStyle,{messages:TempChat})}
                </div>
                </Fragment>
        
            );
        default:
            return (
                <Fragment>
                <style jsx>{`${WidgetStyle}`}</style>
                <div style={{...MergeStyle.layout}} className = {parents + " widgetObject "+type+WidgetActivate} >
                {Preset.getPreset(data,type,MergeStyle,{messages:TempChat})}
                </div>
                </Fragment>
            );

    }


    
}

export default ChatV1;


/**
 *  JSON.stringify를 사용하여 두 객체를 문자열로 변환한 후 비교
 */
function compareObjects(obj1, obj2) {
    // JSON.stringify를 사용하여 두 객체를 문자열로 변환한 후 비교
    return JSON.stringify(obj1) === JSON.stringify(obj2);
}

const WidgetStyle = `
.widgetObject .YTOBJ{
    display :flex;
    object-fit: cover;
}

.widgetObject.Video {
}

.chat-container {
  display: flex;
  flex-direction: column-reverse;
  height: 400px;
  overflow-y: auto;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
}

.message {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.widgetObject.Video .youtubeWrap{
    display: flex;
    position: relative; 
    width: 100%;
    height: 100%;
}

.widgetObject.Video .youtubeWrap .youtubePreviewImage{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
}

.widgetObject.Video .youtubeWrap .youtubeVideo {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
}
 
.widgetObject.Video .youtubeWrap .youtubeInfo {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
}
 

`;


const TempChat= [{
    "message": "이곳에 메시지가 표시됩니다.",
    "timestamp": "",
    "user": "오버랩",
    "userID": "0000",
    "userRole": "common_user",
    "userImage": "",
    "option": {}
},{
    "message": "이곳에 메시지가 표시됩니다.",
    "timestamp": "",
    "user": "오버랩",
    "userID": "0000",
    "userRole": "common_user",
    "userImage": "",
    "option": {}
},{
    "message": "이곳에 메시지가 표시됩니다.",
    "timestamp": "",
    "user": "오버랩",
    "userID": "0000",
    "userRole": "common_user",
    "userImage": "",
    "option": {}
},{
    "message": "이곳에 메시지가 표시됩니다.",
    "timestamp": "",
    "user": "오버랩",
    "userID": "0000",
    "userRole": "common_user",
    "userImage": "",
    "option": {}
},{
    "message": "이곳에 메시지가 표시됩니다.",
    "timestamp": "",
    "user": "오버랩",
    "userID": "0000",
    "userRole": "common_user",
    "userImage": "",
    "option": {}
},{
    "message": "이곳에 메시지가 표시됩니다.",
    "timestamp": "",
    "user": "오버랩",
    "userID": "0000",
    "userRole": "common_user",
    "userImage": "",
    "option": {}
},{
    "message": "이곳에 메시지가 표시됩니다.",
    "timestamp": "",
    "user": "오버랩",
    "userID": "0000",
    "userRole": "common_user",
    "userImage": "",
    "option": {}
},{
    "message": "이곳에 메시지가 표시됩니다.",
    "timestamp": "",
    "user": "오버랩",
    "userID": "0000",
    "userRole": "common_user",
    "userImage": "",
    "option": {}
}]