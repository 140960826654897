////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widgetEdit.scss"


// 컴포넌트 연결
import TextModule from '../editModule/TextModule'; // 텍스트 모듈
import TextSizeModule from '../editModule/TextSizeModule'; // 텍스트 크기 모듈
import FontModule from '../editModule/FontModule'; // 폰트 종류 모듈
import ColorModule from '../editModule/ColorModule'; // 색상 모듈
import AnimationModule from '../editModule/AnimationModule'; // 애니메이션
import ListModule from '../editModule/ListModule'; // 리스트
import ImageButtonModule from '../editModule/ImageButtonModule';
import RangeModule from '../editModule/RangeModule'; //범위 모듈 
import RadioButtonModule from '../editModule/RadioButtonModule'; //범위 모듈 
import MediaSourceModule from '../editModule/MediaSourceModule'; //범위 모듈 
import InfoButtonModule from '../editModule/InfoButtonModule';//위치 모듈
import RotateModule from '../editModule/RotateModule'; // 색상 모듈
// 서비스 연결

// 이미지 연결
import exampleFilter from 'assets/exampleFilter.jpg';

import leftButton from 'assets/sidebar/text/left.png';
import centerButton from 'assets/sidebar/text/center.png';
import rightButton from 'assets/sidebar/text/right.png';
import justifyButton from 'assets/sidebar/text/justify.png';

import upperButton from 'assets/sidebar/text/upper.png';
import midButton from 'assets/sidebar/text/mid.png';
import lowerButton from 'assets/sidebar/text/lower.png';

import blur_0 from 'assets/sidebar/other/blur_0.png';
import blur_1 from 'assets/sidebar/other/blur_1.png';
import blur_2 from 'assets/sidebar/other/blur_2.png';
import blur_3 from 'assets/sidebar/other/blur_3.png';

import grayscale_0 from 'assets/sidebar/other/grayscale_0.png';
import grayscale_1 from 'assets/sidebar/other/grayscale_1.png';
import grayscale_2 from 'assets/sidebar/other/grayscale_2.png';
import grayscale_3 from 'assets/sidebar/other/grayscale_3.png';

import sepia_0 from 'assets/sidebar/other/sepia_0.png';
import sepia_1 from 'assets/sidebar/other/sepia_1.png';
import sepia_2 from 'assets/sidebar/other/sepia_2.png';
import sepia_3 from 'assets/sidebar/other/sepia_3.png';

import invert_0 from 'assets/sidebar/other/invert_0.png';
import invert_1 from 'assets/sidebar/other/invert_1.png';
import invert_2 from 'assets/sidebar/other/invert_2.png';
import invert_3 from 'assets/sidebar/other/invert_3.png';

import { data } from '../../widget/preset/BroadcastAlertPreset';


////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class BackgroundPatternDesignEdit extends Component {
    
    state = {
    }

    change = (name,value) =>{
        this.props.callBack('style',name,value)
    }
    valueChange = (name,value) =>{
        this.props.callBack('value',name,value)
    }

    render() { 

        const Style = {
            label: {
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
            },
            textarea : {
                width: '100%',
                resize: 'none'
            }

        }

        const TEXTTYPE= (
            <Fragment>
            <TextModule value={this.props.data.value.text} fontFamily={this.props.data.style.pattern_fontFamily} type ="text" placeholder="여기에 텍스트를 입력해주세요"  change={this.valueChange}/>
            <ImageButtonModule value={this.props.data.style.pattern_textAlign} type ="pattern_textAlign" data={[{val:'left',image:leftButton,text:'text'},{val:'center',image:centerButton},{val:'right',image:rightButton},{val:'justify',image:justifyButton}]} title="좌우 정렬"  change={this.change}/>
            <ColorModule value={this.props.data.style.pattern_color} type ="pattern_color" change={this.change}/>
            <TextSizeModule value={this.props.data.style.pattern_fontSize} type ="pattern_fontSize" change={this.change}/>
            <FontModule  value={this.props.data.style.pattern_fontFamily} type ="pattern_fontFamily" change={this.change}/>
           
            </Fragment>      
            )

        const IMAGETYPE = (
            <Fragment>
                <MediaSourceModule value={this.props.data.value.mediaSource} target={this.props.type} type='mediaSource' placeholder={'여기에 이미지 URL을 복사해주세요'} change={this.valueChange}/>
                <img style={Style.mediaView} width={'100%'} src={this.props.data.value.mediaSource}/>
            </Fragment>      
            )

        let contents = '배경 종류를 선택해주세요'
        // 타입에 따라서 에디터 변경
        
        switch (this.props.data.value.type) {
            case 'text':
                contents = TEXTTYPE
                break;
            case 'image':
                contents = IMAGETYPE
                break;
        
            default:
                break;
        }


        return (
            <Fragment>
            
            <fieldset className="EditModuleField" style={Style.fieldset} >
                <legend style={Style.legend} > 패턴 </legend>
                <RadioButtonModule value={this.props.data.value.type} list={[{name:'텍스트',value:'text'},{name:'이미지',value:'image'}]} type ="type" title="패턴 종류"  change={this.valueChange} />
                {contents}
                <ColorModule value={this.props.data.style.fill} type ="fill" title="배경색" change={this.change} />
                <RangeModule value={this.props.data.style.fillOpacity} type ="fillOpacity" title="배경투명도" valueView="%" min="0" max="100" change={this.change} />
                <RangeModule value={this.props.data.style.pattern_shadowValue} type ="pattern_shadowValue" min="0" max="10" title="패턴그림자" change={this.change} />
                <ColorModule value={this.props.data.style.pattern_shadowColor} type ="pattern_shadowColor" title="그림자색상" change={this.change}/>
                <details>
                    <summary>
                        패턴 필터
                    </summary>
                    <fieldset className="EditModuleField FilterEdit" style={{...Style.fieldset,border:'none'}} >
                        <ImageButtonModule value={this.props.data.style.pattern_blur} type ="pattern_blur" data={[{val:20,image:blur_3,text:'text',title:"매우 흐리게"},{val:10,image:blur_2,title:"흐리게"},{val:5,image:blur_1,title:"약간 흐리게"},{val:0,image:blur_0,title:"흐리지 않게"}]} title="흐리게"  change={this.change}/>
                        <ImageButtonModule value={this.props.data.style.pattern_grayscale} type ="pattern_grayscale" data={[{val:100,image:grayscale_3,text:'text',title:"완전한 회색톤"},{val:66,image:grayscale_2,title:"진한 회색톤"},{val:33,image:grayscale_1,title:"약한 회색톤"},{val:0,image:grayscale_0,title:"회색톤 없음"}]} title="회색조"  change={this.change}/>
                        <ImageButtonModule value={this.props.data.style.pattern_sepia} type ="pattern_sepia" data={[{val:100,image:sepia_3,text:'text',title:"완전한 세피아"},{val:66,image:sepia_2,title:"진한 세피아"},{val:33,image:sepia_1,title:"약한 세피아"},{val:0,image:sepia_0,title:"세피아 없음"}]} title="세피아"  change={this.change}/>
                        <ImageButtonModule value={this.props.data.style.pattern_invert} type ="pattern_invert" data={[{val:100,image:invert_3,text:'text',title:"100% 색반전"},{val:66,image:invert_2,title:"66% 색반전"},{val:33,image:invert_1,title:"33% 색반전"},{val:0,image:invert_0,title:"색반전 없음"}]} title="색반전"  change={this.change}/>
            
                        {/* <InfoButtonModule value={this.props.data.style.pattern_blur} select={true} type ="pattern_blur" change={this.change} title="흐리게" min='0' max='40' infoText="exampleFilter" infoImg={exampleFilter}/>
                        <InfoButtonModule value={this.props.data.style.pattern_grayscale} select={true} type ="pattern_grayscale" change={this.change} title="회색조" min='0' max='100' infoText="exampleFilter" infoImg={exampleFilter}/>
                        <InfoButtonModule value={this.props.data.style.pattern_hueRotate} select={true} min='0' max='360' type ="pattern_hueRotate" change={this.change} title="색조변경" infoText="exampleFilter" infoImg={exampleFilter}/>
                        <InfoButtonModule value={this.props.data.style.pattern_invert} select={true} type ="pattern_invert" change={this.change} title="색반전" infoText="exampleFilter" infoImg={exampleFilter}/>
                        <InfoButtonModule value={this.props.data.style.pattern_saturate} select={true} type ="pattern_saturate" change={this.change} title="채도" min='100' max='1000' infoText="exampleFilter" infoImg={exampleFilter}/>
                        <InfoButtonModule value={this.props.data.style.pattern_sepia} select={true} type ="pattern_sepia" change={this.change} title="세피아"  min='0' max='100' infoText="exampleFilter" infoImg={exampleFilter}/> */}
                    </fieldset>
                </details>

                <details>
                    <summary>
                        패턴 변형
                    </summary>
                        <RotateModule value={this.props.data.style.pattern_rotateZ} valueView="도" type ="pattern_rotateZ" min="-180" max="180" title="회전" change={this.change} />
                        <RotateModule value={this.props.data.style.pattern_rotateY} valueView="도" type ="pattern_rotateY" min="-180" max="180" title="상하회전" change={this.change} />
                        <RotateModule value={this.props.data.style.pattern_rotateX} valueView="도" type ="pattern_rotateX" min="-180" max="180" title="좌우회전" change={this.change} />
                        <RotateModule value={this.props.data.style.pattern_scale} valueView="%" type ="pattern_scale" min="0" max="500" title="배율" change={this.change} />
                        {/* <RangeModule value={this.props.data.style.pattern_rotateZ} valueView="도" type ="pattern_rotateZ" min="-180" max="180" title="회전" change={this.change} />
                        <RangeModule value={this.props.data.style.pattern_rotateY} valueView="도" type ="pattern_rotateY" min="-180" max="180" title="상하회전" change={this.change} />
                        <RangeModule value={this.props.data.style.pattern_rotateX} valueView="도" type ="pattern_rotateX" min="-180" max="180" title="좌우회전" change={this.change} />
                        <RangeModule value={this.props.data.style.pattern_scale} valueView="%" type ="pattern_scale" min="0" max="500" title="배율" change={this.change} /> */}
                </details>


            </fieldset>
            
            <fieldset className="EditModuleField" style={Style.fieldset} >
                <legend style={Style.legend} > 패턴 애니메이션 </legend>
                <RadioButtonModule value={this.props.data.value.speed} list={[{name:'빠르게',value:1.5},{name:'보통',value:3},{name:'느리게',value:10}]} type ="speed" title="전환속도"  change={this.valueChange} />
                <RadioButtonModule value={this.props.data.value.animationTiming} list={[{name:'기본',value:'linear'},{name:'부드럽게',value:'ease-in-out'},{name:'바운드',value:'cubic-bezier(0.2,-2,0.8,2)'}]} type ="animationTiming" title="전환방법"  change={this.valueChange} />
                <ListModule list={PatternDesignAnimation} title="애니메이션" placeholder="여기서 애니메이션을 검색해보세요" type ="patternDesign" change={this.valueChange} />

            </fieldset>

            </Fragment>
        );
    }
}

////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////
const PatternDesignAnimation = [
    {
        name: '왼쪽위 이동',
        value : 'patternDesignTopLeft',
        info : '↗ 방향을 향해 이동',
        img : '',
    },
    {
        name: '왼쪽아래 이동',
        value : 'patternDesignTopRight',
        info : '↘ 방향을 향해 이동',
        img : '',
    },
    {
        name: '오른쪽위 이동',
        value : 'patternDesignBottomRight',
        info : '↖ 방향을 향해 이동',
        img : '',
    },
    {	
        name: '오른쪽아래 이동',
        value : 'patternDesignBottomLeft',
        info : '↙ 방향을 향해 이동',
        img : '',
    },
    {	
        name: '회전',
        value : 'patternDesignMove',
        info : '원을 그리며 이동',
        img : '',
    },]
////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    // screenData : state.screenData,
})

function mapDispatchToProps(dispatch){
    return {
    //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(BackgroundPatternDesignEdit);
  