
////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결

// 라이브러리 
import io from 'socket.io-client';
// 설정 파일
import * as config from 'config/OverlapConfig'
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결

// 이미지 연결

// 서비스 연결
import * as Rest from 'service/api/rest' ;
import { token } from 'service/event/Account' ; 

////////////////////////////////////////////////////////
// store 연결
////////////////////////////////////////////////////////



const SERVERURL = config.SERVERS[0].serverEndPoint+":"+'3030';
////////////////////////////////////////////////////////
/** viewIO start
 * @param authData 소켓 IO 를 통해서 오버랩을 체크를 합니다.
 * @returns 뷰 IO 객체
 */
////////////////////////////////////////////////////////

export function start() {

    const view = io(config.SERVERS[0].serverEndPoint+":"+'3030'+'/view');
    return view;
}

////////////////////////////////////////////////////////
/** viewCreate
 * @param spaceId 뷰를 생성할 스페이스의 ID를 적습니다.
 * @param data 뷰 생성에 필요한 정보를 적습니다. {title,resolution,private,private,summary}
 * @returns 뷰 IO 객체
 */
////////////////////////////////////////////////////////

export async function create(space_id,data) {
    if(data.name == ''){
        data['name'] = '새로운 채널';
    }

    const createData = Object.assign(data, {space_id});

    let response =  await Rest.request('/view/create','Post',createData,token('account'));
    if(response?.status == 200){
        return response?.data?.data;
    }
    return false;
}


////////////////////////////////////////////////////////
/** del
 * @param overlap_id 뷰를 생성할 스페이스의 ID를 적습니다.
 * @param space_id 뷰 생성에 필요한 정보를 적습니다. {title,resolution,private,private,summary}
 * @returns 뷰 IO 객체
 */
////////////////////////////////////////////////////////


export async function del(view_id,space_id) { 
    let response =  await Rest.request('/view/delete','Post',{space_id,view_id},token('account'));
    if(response?.status == 200){
        console.log('response',response)
        return true;
    }
    return false;
}


////////////////////////////////////////////////////////
/** viewList
 * @param space_id 뷰 목록을 가저올 스페이스의 id
 * @returns 해당 스페이스의 뷰 목록
 */
////////////////////////////////////////////////////////

export async function list(space_id) {
    try {
        let response =  await Rest.request('/view/list','Get',{"space_id":space_id},token('account'));
        if(response?.status == 200){
            console.log('response',response)
            return response?.data?.data;
        }
        return [];
    } catch (error) {
        return [];
    }
 
}

// ////////////////////////////////////////////////////////
/** get
 * @param uuid 타겟 뷰의 uuid
 * @returns view IO 객체
 */
// ////////////////////////////////////////////////////////

export async function get(uuid) {
    let response =  await Rest.request('/view','Get',{uuid},token('account'));
    
    if(response?.status == 200){
        console.log('response',response)
        return response?.data?.data;
    }
    return [];
}


////////////////////////////////////////////////////////
/** uuidConverter
 * @param type 오버랩을 생성할 스페이스의 ID를 적습니다.
 * @param uuid 오버랩 생성에 필요한 정보를 적습니다. {title,resolution,private,private,summary}
 * @returns 오버랩 IO 객체
 */
////////////////////////////////////////////////////////
export function uuidConverter(type,uuid) {
    
    switch (type) {
        case 'url':
            return uuid.replace(/-/g, '');
        case 'db':
            return uuid.slice(0, 8) + '-' + uuid.slice(8, 12) + '-' + uuid.slice(12, 16) + '-' + uuid.slice(16, 20) + '-' + uuid.slice(20);
    }

}

