////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { useState, useEffect, useContext, Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// 라이브러리 

// [리덕스]스토어 연결

// [프로바이더] 연결


// SASS&CSS 연결
// import "sass/edit.scss"



// 이미지 연결
import logoW from 'assets/logoW.svg';

// 컴포넌트 연결
import PageDisplay from 'components/display/StaticDisplay';
import GuideBalloon from 'components/common/form/GuideBalloon';

// 서비스 연결
import * as Editor from 'service/edit/EditService'; 
import { dragTransparent } from 'service/event/common' ; 
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
/**
 * PagePreview 편집페이지에서 페이지 미리보기 를 제공하는 컴포넌트입니다.
 * @param {number} index : 페이지의 순서 번호를 받습니다.
 * @param {bool} select : 해당페이지가 선택되었는지 확인합니다.
 * @param {bool} broadcast : 해당페이지가 선택한 뷰에서 방송중인지 확인합니다.
 * @param {OverlapPage} page : 미리 보기 할 오버랩의 페이지 객체를 받습니다. 
 * @param {number} width : 미리 보기의 가로 크기 를 정의합니다.
 * @param {number} height :  미리 보기의 세로 크기 를 정의합니다
 * @param {number} referenceWidth : 해당 오버랩 프로젝트의 가로 크기를 정의합니다.
 * @param {number} referenceHeight :  해당 오버랩 프로젝트의 세로 크기를 정의합니다.
 * @param {function} duplicate : 페이지를 복재합니다.
 * @param {function} setBroadcast : 방송중인 브로드 캐스트 화면을 변경합니다.
 * @param {string} channel :  선택된 채널의 UUID
 * @returns 
 */

function PagePreview({index,uuid,checkOrder,select,duplicate,broadcast,page,width,height,referenceWidth,referenceHeight,selectPage,contextShow,pageRename,setBroadcast,panelControl,channel}) {

    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////
    // set

    // state
    const [PageTitle, setPageTitle] = useState(page?.page_title);
    const [Drag, setDrag] = useState(false);

    // rel 

   ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////
    useEffect(() => {
        // 여기에 컴포넌트가 마운트되었을 때 실행할 코드를 작성합니다.

        return () => {
        // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
        };
      }, []);

    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////
    
    const rename = () =>{
      if(page?.page_title!=PageTitle){
        pageRename(PageTitle,page.uuid)
      }
    }

    const ShowContext = (e) =>{
      contextShow(e,page.uuid);
      e.stopPropagation()
      e.preventDefault()
    }

    const DragStart = (e) => {
      dragTransparent(e)

    }

    const DragEnd = () => {
      
    }

    const mouseAciton = (e) =>{
      switch (e.buttons){
        // 왼쪽 마우스클릭
        case 1 :
            selectPage(page.uuid);
            break;
        // 오른쪽 마우스클릭
        case 2 :

            break;
        // 왼쪽,오른쪽 동시 마우스클릭
        case 3 :
 
            break;
        // 휠버튼
        case 4 :

            break;

        default :
       }

    }

    let pageClassName = ''

    if(select){
      pageClassName += ' selectPage';
    }

    if(broadcast){
      pageClassName += ' broadcastPage';
    }
    

    return (
      <Fragment>
      <div onDragStart={DragStart} onDragEnd={DragEnd} className={pageClassName} onMouseDown={(e)=>{mouseAciton(e)}} onContextMenu={(e)=>ShowContext(e)} title={uuid}>  
        <div className="PagePreviewHeader">
          <div className="PagePreviewIndex">
            {index}
          </div>
          <div className="PagePreviewTitle">
            <input value={PageTitle} placeholder={'페이지'} onChange={(e)=>setPageTitle(e.target.value)} onMouseOut={()=>rename()}/>
              </div>
        </div>
        <div className="PagePreviewArea">

          <div className="PagePreviewContainer" style={{height:height}}>

            <div className="PagePreviewHover">
              <div className="PagePreviewState" style={{height}}> </div>
              <div className="PagePreviewDummy" style={{width,height}}/>
              <div className="PagePreviewOptions" style={{height}}> 
                {channel ? 
                  <GuideBalloon guideText={'페이지를 송출합니다.'} width={140} direction='topright'>
                       {!broadcast?
                  <button className='PageButton' style={{backgroundColor:'red'}} onClick={()=>{setBroadcast(uuid)}}>
                 
                  <svg id = "broadcastIcon"  width="25px" height="25px">
                    <circle cx="12.5" cy="12.5" r="6" fill={"red"} stroke="#fff" strokeWidth="2" />
                  </svg>
                  
                  </button>
                        : 
                        <button className='PageButton' style={{backgroundColor:'red'}} onClick={()=>{}}>
                        {Editor.icon({icon:'pause',size:15,lineHeight:25})}
                         </button>
                        }
                  </GuideBalloon>
                :
                <GuideBalloon guideText={'프로젝트와 연결된 채널이 없습니다.'} width={240} direction='topright'>
                <button className='PageButton ' onClick={()=>{panelControl('utility','channel')}} >
                         {/* {Editor.icon({icon:'hide_source',size:15,lineHeight:25})} */}
                <svg id = "broadcastIcon"  width="25px" height="25px">
                        <circle cx="12.5" cy="12.5" r="6" fill={"gray"} stroke={"#fff"} strokeWidth="2" />
                        {/* <line x1="6.5" y1="6.5" x2="18.5" y2="18.5" stroke="#fff" strokeWidth="2"/> */}
                      {/* <line x1="18.5" y1="6.5" x2="6.5" y2="18.5" stroke="#fff" strokeWidth="1"/> */}
                      </svg>
                </button>
                </GuideBalloon>
                }
              <GuideBalloon guideText={'페이지를 복제합니다.'} width={140} direction='topright'>
              <button className='PageButton'  onClick={()=>{duplicate(uuid)}}>
                {Editor.icon({icon:'content_copy',size:15,lineHeight:25})}
              </button>
              </GuideBalloon>
              <GuideBalloon guideText={'상세 메뉴를 표시합니다.'} width={160} direction='topright'>
                <button className='PageButton' onClick={(e)=>ShowContext(e)}>
                  {Editor.icon({icon:'arrow_drop_down',size:15,lineHeight:25})}
                </button>
              </GuideBalloon>

              
              </div>
            </div>
            <div className="PagePreviewDisplay">
            
              <div className="PagePreviewObject" style={{width,height}}>

                <PageDisplay page={page} previewWidth={width} previewHeight={height} referenceWidth={referenceWidth} referenceHeight={referenceHeight} option={{align:'center'}} />
              </div>

            </div>

          </div>
        </div>
      </div>
      </Fragment>
    );

}

////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    // mainScreen : state.mainScreen,
})

function mapDispatchToProps(dispatch){
    return {
        // initOverlap : (overlapData) => dispatch({type:'initOverlap',overlap : overlapData}),
    }
}

  // export default EditPage;
export default connect(mapStateToProps,mapDispatchToProps)(PagePreview);
  
