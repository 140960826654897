
////////////////////////////////////////////////////////
// Account 관련 펑션
////////////////////////////////////////////////////////

////////////////////////////////////////////////////////
/** Token
 * @param authData 소켓 IO 를 통해서 오버랩을 체크를 합니다.
 * @returns 오버랩 IO 객체
 */
////////////////////////////////////////////////////////
export function token(key) {

    switch (key) {
        case 'account':
            return sessionStorage.getItem("JWT");
    
        default:
            return null;
    }

}

export function removeToken(key) {

    switch (key) {
        case 'account':
            return sessionStorage.removeItem("JWT");
    
        default:
            return null;
    }

}
