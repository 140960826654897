////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widgetEdit.scss"


// 컴포넌트 연결
// import Show from '../show/Show'; // 메뉴 헤더

// 서비스 연결

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class DropdownModule extends Component {



state = {
    dropdown : false
}

click = (value) =>{
    this.props.change(this.props.type,value)
    this.setState({
        dropdown : false,
    })
}


dropdownList = () =>{
    let type = Object.prototype.toString.call(this.props.dropdown).slice(8,-1)
    if (type == 'Object') {
        console.log('객체')
        }else{
        console.log(type)
        }

        let list 
        switch (type) {
            case 'Object':
                list = this.props.dropdown.map(
                    (info) => (<Fragment> <li className="dropdownList" onClick={()=>this.click(info.value)}>{info.name} </li></Fragment> ) 
                );    
                return list
                break;
            case 'Array':
                list = this.props.dropdown.map(
                    (name) => (<Fragment> <li className="dropdownList" onClick={()=>this.click(name)}>{name} </li></Fragment> ) 
                );    
                return list
                break;       
            default:
                break;
        }

}

    render() { 
        console.log(this.props.dropdown)
        return (
            <label className="EditModule" htmlfor="fname"> 
                <p>{this.props.title?this.props.title:'리스트'}</p>
                <div className="dropdownSelet" style={{fontFamily:this.props.value}}  onClick={()=>this.setState({
                    dropdown : !this.state.dropdown
                })}>
                   {(this.props.value?this.props.value:'자동설정')}
                </div>
                {this.state.dropdown?  
                <ul className="dropdown">
                    {this.dropdownList()}
                </ul>
                :
                ''
                }
        
            </label>
        );
    }
}

const FONTLIST=['나눔스퀘어','나눔고딕','나눔바른고딕','나눔바른펜','한나체','한나체Air','주아체','기랑해랑체','도현체','연성체','을지로체','을지로10년후체','메이플스토리체','삼립호빵체','이사만루체','마포꽃섬','조선궁서체','DOS명조',]

////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    // screenData : state.screenData,
})

function mapDispatchToProps(dispatch){
    return {
    //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(DropdownModule);
  

  