////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widgetEdit.scss"


// 컴포넌트 연결
import ImageButtonModule from '../editModule/ImageButtonModule' // 이미지 모듈
import ColorModule from '../editModule/ColorModule'; // 색상 모듈
import RangeModule from '../editModule/RangeModule'; // 색상 모듈
// 서비스 연결
import * as Editor from 'service/edit/EditService'; 
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class ShadowEdit extends Component {
    
    state = {
        editorToggle : false
    }

    change = (name,value) =>{
        this.props.callBack('style',name,value)
    }

    iconStyle = (option) =>{

        const ShadowDirection  = {
            LeftTop : '-5px -5px 0px ',
            LeftBottom : '-5px 5px 0px ',
            RightTop : '5px -5px 0px ',
            RightBottom : '5px 5px 0px ',
        }
        const Rirection  = {
            LeftTop : {top: '5px',left: '5px'},
            LeftBottom : {top: '0px',left: '5px'},
            RightTop : {top: '5px',left: '0px'},
            RightBottom : {top: '0px',left: '0px'},
        }
        


        let opacity= Math.round(this.props.data.style.shadowValue/10 * 255).toString(16)
        if (opacity.length==1){ opacity= 0+opacity }
        switch (option) {
            case 'LeftTop':
                return { 
                    top: '5px',
                    left: '5px',
                    filter: 'drop-shadow(-5px -5px 0px '+this.props.data.style.shadowColor+opacity+')'
                }
            case 'LeftBottom':
                return { 
                    top: '0px',
                    left: '5px',
                    filter: 'drop-shadow(-5px 5px 0px '+this.props.data.style.shadowColor+opacity+')'
                }
            case 'RightTop':
                return { 
                    top: '5px',
                    left: '0px',
                    filter: 'drop-shadow(5px -5px 0px '+this.props.data.style.shadowColor+opacity+')'
                }
            case 'RightBottom':
                return { 
                    top: '0px',
                    left: '0px',
                    filter: 'drop-shadow(5px 5px 0px '+this.props.data.style.shadowColor+opacity+')'
                }
            case 'shadowLarge':
                return { 
                    ...Rirection[this.props.data.style.shadowDirection],
                    filter: 'drop-shadow('+ShadowDirection[this.props.data.style.shadowDirection]+this.props.data.style.shadowColor+'e6'+')'
                }
            case 'shadowMedium':
                return { 
                    ...Rirection[this.props.data.style.shadowDirection],
                    filter: 'drop-shadow('+ShadowDirection[this.props.data.style.shadowDirection]+this.props.data.style.shadowColor+'99'+')'
                }
            case 'shadowSmall':
                return { 
                    ...Rirection[this.props.data.style.shadowDirection],
                    filter: 'drop-shadow('+ShadowDirection[this.props.data.style.shadowDirection]+this.props.data.style.shadowColor+'4d'+')'
                }
            case 'shadowNone':
                return { 
                    ...Rirection[this.props.data.style.shadowDirection],
                    filter: 'drop-shadow('+ShadowDirection[this.props.data.style.shadowDirection]+this.props.data.style.shadowColor+'00'+')'
                }
            default:
                break;
        }
    } 

    shadowDirection = () =>{
        let property = Object.keys(this.props.data.style)
        if (property.includes('shadowDirection')){
            let list = (
                <label className={"ButtonModule shadowDirectionModule "+(this.props.data.style.shadowValue==0?'moduleHide':'') } htmlfor="fname"> 
                    <p>방향</p>
                    <span className="ButtonList" >
                    <div className={'shadowButton optionButton '+(this.props.data.style.shadowDirection=="RightTop"?'buttonSelect':'')} onClick={()=>this.change('shadowDirection','RightTop')}>
                        <div className="shadowEx shadowExRightTop " style={this.iconStyle('RightTop')}/>
                    </div>
                    <div className={'shadowButton optionButton '+(this.props.data.style.shadowDirection=="LeftTop"?'buttonSelect':'')} onClick={()=>this.change('shadowDirection','LeftTop')}>
                        <div className="shadowEx shadowExLeftTop "  style={this.iconStyle('LeftTop')}/>
                    </div>
                    <div className={'shadowButton optionButton '+(this.props.data.style.shadowDirection=="RightBottom"?'buttonSelect':'')} onClick={()=>this.change('shadowDirection','RightBottom')}>
                        <div className="shadowEx shadowExRightBottom "  style={this.iconStyle('RightBottom')}/>
                    </div> 
                    <div className={'shadowButton optionButton '+(this.props.data.style.shadowDirection=="LeftBottom"?'buttonSelect':'')} onClick={()=>this.change('shadowDirection','LeftBottom')}>
                        <div className="shadowEx shadowExLeftBottom "  style={this.iconStyle('LeftBottom')}/>
                    </div>
                    </span>
                </label>
            )
            return (list)    
        }
    }
    shadowDeep= () =>{
        let property = Object.keys(this.props.data.style)
        if (property.includes('shadowValue')){
            let list = (
                <label className="ButtonModule" htmlfor="fname"> 
                    <p>강도</p>
                    <span className="ButtonList" >
                    <div className={'shadowButton optionButton '+(this.props.data.style.shadowValue=='9'?'buttonSelect':'')} onClick={()=>this.change('shadowValue','9')}>
                        <div className={"shadowEx shadowLarge"} style={this.iconStyle('shadowLarge')} />
                    </div>
                    <div className={'shadowButton optionButton '+(this.props.data.style.shadowValue=='5'?'buttonSelect':'')} onClick={()=>this.change('shadowValue','5')}>
                        <div className={"shadowEx shadowMedium"} style={this.iconStyle('shadowMedium')}/>
                    </div> 
                    <div className={'shadowButton optionButton '+(this.props.data.style.shadowValue=='2'?'buttonSelect':'')} onClick={()=>this.change('shadowValue','2')}>
                        <div className={"shadowEx shadowSmall"} style={this.iconStyle('shadowSmall')}/>
                    </div>
                    <div className={'shadowButton optionButton '+(this.props.data.style.shadowValue=='0'?'buttonSelect':'')} onClick={()=>this.change('shadowValue','0')}>
                        <div className={"shadowEx shadowExnone"} style={this.iconStyle('shadowNone')}/>
                    </div>
                    </span>
                </label>
            )
            return list
        }



    }

    render() { 

        let opacity= Math.round(this.props.data.style.shadowValue/10 * 255).toString(16)
        if (opacity.length==1){ opacity= 0+opacity }
        
        const Style = {
            label: {
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
            },
            textarea : {
                width: '100%',
                resize: 'none',
                boxSizing:'border-box',
            },
            mediaView : {
                width: '100%',
                MaxHeight : '500px',
            }

        }
        let legend = '그림자'
        if (this.props.type){
            legend = '그림자'
        }

        return (
            
            <section className='ShadowEdit'>
                {Editor.WidgetEditTitle(this,{title:'그림자',toggle:true,icon:'flip_to_front'})}
                <div className={this.state.editorToggle?'editContainer containerOpen':'editContainer containerClose'}>
                    <article className="EditPart" >
                        {this.shadowDeep()}         
                        <div className={'useShadow '+(this.props.data.style.shadowValue==0?'moduleHide':'')}>
                            {this.shadowDirection()}
                            {/* <RangeModule value={this.props.data.style.shadowValue} type ="shadowValue" min="0" max="10" title="퍼짐" change={this.change} /> */}
                            <ColorModule value={this.props.data.style.shadowColor} type ="shadowColor" title="색상" change={this.change}/>
                        </div>
                    </article>
                </div>
            </section>

        );
    }
}

////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////



////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    // screenData : state.screenData,
})

function mapDispatchToProps(dispatch){
    return {
    //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(ShadowEdit);
  