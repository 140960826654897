////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { useState, useEffect, useContext, Fragment } from 'react';
import * as config from 'config/OverlapConfig'



const ServerName = config.SERVERS[0].serverName;

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////


function DebugArea() {



    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////


    ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////
    useEffect(() => {

        // 토글의 사용 여부 및 필드의 열림 상태를 정의합니다.

        // 여기에 컴포넌트가 마운트되었을 때 실행할 코드를 작성합니다.

        return () => {
        // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
        };
      }, []);

    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////

    // 해당 에디터에서 편집이 가능한 값 목록

    return (
        <Fragment>
            <style jsx>{`${DebugStyle}`}</style>
            {window.location.hostname === 'localhost'?
            <div id="DebugArea">
                <div id="ServerInfo">
                    <div id="TestIcon" className={window.location.hostname === 'localhost'?'local':'online'} />
                    
                    <div id="TestIcon" className={ServerName === 'overlapTest'?'local':'online'} />
  
                </div>
            </div>
        :null}
        </Fragment>

    );

}

export default DebugArea;

const DebugStyle = `
#DebugArea{
    position: absolute;
    width: 100vw;
    height: 100vh;
    pointer-events: none;
    z-index: 10000;
    top:0;
    display:flex;
    justify-content: end;
}   

#DebugArea #ServerInfo{

    display:flex;
}

#DebugArea #ServerInfo #TestIcon{
    pointer-events: all;
    width: 10px;
    height : 10px;
    margin : 10px 10px 10px 0px;
    border-radius : 20px;
    box-shadow: 
    0px 2px 4px rgba(0, 0, 0, 0.15),
    0px 6px 8px rgba(0, 0, 0, 0.15),
    0px 12px 16px rgba(73, 25, 25, 0.1),
    0px 18px 24px rgba(0, 0, 0, 0.15);
}

#DebugArea #ServerInfo #TestIcon.local{
    background : red;
}

#DebugArea #ServerInfo #TestIcon.online{
    background : green;
}


`;