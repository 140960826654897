////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, {useState, useEffect, useRef } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { Route, Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
// 라이브러리 
import { useGoogleLogin } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google'// 구글 로그인
// 설정 파일
import * as config from 'config/OverlapConfig'

import * as apiAccount from 'service/api/apiAccount'

// [컴포넌트] 위젯 
import MiniView from "components/viewer/MiniView"
import OverlapInfo from "components/Lobby/space/module/OverlapInfo"
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import 'sass/login.scss'

// 이미지 연결
import logoW from 'assets/logoW.svg';
import youtube from 'assets/main/lobby/social/youtube.png';
import twitch from 'assets/main/lobby/social/twitch.png';
import afreeca from 'assets/main/lobby/social/afreeca.png';
import facebook from 'assets/main/lobby/social/facebook.png';
import naver from 'assets/main/lobby/social/naver.png';


// 서비스 연결
import * as page from 'service/api/page';
import PreView from 'components/viewer/PreView';
import PreviewDisplay from 'components/display/PreviewDisplay';
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

function SpaceModuleOverlapDummy(props) {

    const history = useHistory();

    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////
    // state
    const [getPreview, setPreview] = useState([]);
    // rel 
    ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////

    //   useEffect(() => {
    //     console.log('Component did mount or update');
    //     return () => {
    //       console.log('Component will unmount');
    //     };
    //   },[]);

    ////////////////////////////////////////////////////////
    // Fuction
    ////////////////////////////////////////////////////////

    useEffect(() => {
        getPagePreview()
    }, [props.overlap]);

    async function getPagePreview(){
        const NewPreview = [];
        if(props.overlap?.page_order?.[0]){
            const overLapPreview = await page.getPageForUUID(props.overlap.page_order[0]);
            NewPreview.push(overLapPreview[0])
            setPreview(NewPreview)
        }else{
            setPreview([])
        }
    }

    function timeAgo(dateString) {
        const then = new Date(dateString);
        const now = new Date();
        const diffInSeconds = Math.floor((now - then) / 1000);
      
        if (diffInSeconds < 180) {
          return `지금`;
        }
        if (diffInSeconds < 3600) {
          return `${Math.floor(diffInSeconds / 60)}분 전`;
        }
        if (diffInSeconds < 86400) {
          return `${Math.floor(diffInSeconds / 3600)}시간 전`;
        }
        return `${Math.floor(diffInSeconds / 86400)}일 전`;
      }

    const goEditOverlap = () => {
        history.push({
          pathname: '/edit',
          state: {id: props.overlap.id, uuid: props.overlap.uuid, spaceId: props.spaceId},
        });
      }

    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////
    return (
        <div className='SpaceModuleContainer SpaceOverlapDummy' >
            <style jsx>{`${SpaceModuleStyle}${ContainerStyle}`}</style>
            <div className='dummyLogo'>
              <svg className="broadcastScreenLive" width="100px" height="100px" xmlns="http://www.w3.org/2000/svg" version="1.1">
                    <circle cx="50" cy="50" r="30" fill="gray" stroke="#fff" strokeWidth="10" filter="url(#shadow)" />
                </svg>
            </div>
            <div className='dummyText'>
            채널목록 에서 사용할 채널을 선택해주세요
            </div>
            
    </div>
    );
  }

  export default SpaceModuleOverlapDummy;

////////////////////////////////////////////////////////
// css 부분
////////////////////////////////////////////////////////
const SpaceModuleStyle = `
    .SpaceOverlapDummy {
        display :flex;
        width: 100%;
        height: 200px;  
        flex-direction: column;
        justify-content: center;
    }
    @keyframes slideUp {
        0% {
          transform: translateY(120%); /* 시작 위치: 아래 */

        }
        100% {
          transform: translateY(0); /* 끝 위치: 원래 위치 */

        }
      }
`;

const ContainerStyle = `

    .SpaceOverlapDummy .dummyLogo{
        width: 100%;
        height: 100px;
        display :flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .SpaceOverlapDummy .dummyLogo svg{
        width: 100px;
        height: 100px;
        filter: brightness(0.5);
    }

    .SpaceOverlapDummy .dummyText{
        display :flex;
        width: 100%;
        height: 30px;
        flex-direction: column;
        margin-top : 10px;
        display: flex;
        text-align: center;
        color: #adadad;
        font-size: 20px;
    }

      

`;

