////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, {useState, useEffect, useRef } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { Route, Link } from 'react-router-dom';
import { useHistory } from "react-router";
// 라이브러리 

// 설정 파일
import * as config from 'config/OverlapConfig'

// [컴포넌트] 위젯 
import TextBalloon from 'components/common/form/TextBalloon'
import GuideBalloon from 'components/common/form/GuideBalloon'
// import MiniView from "components/viewer/MiniView"
// [리덕스]스토어 연결
// import store from "store";

// SASS&CSS 연결
// import 'sass/login.scss'

// 이미지 연결
// import logoW from 'assets/logoW.svg';


// 서비스 연결
import * as DeCoder from 'service/engine/DeCoder' ; 
import * as Editor from 'service/edit/EditService'; 
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

/** [위젯선택] 
 * 선택된 위젯을 컨트롤 할 수 있는 컨트롤러 입니다.
 * @param {number} x 위젯의 가로 시작 좌표
 * @param {number} y 위젯의 세로 시작 좌표
 * @returns 
 */


 const WidgetOption =  React.memo(function WidgetOption({icon,action,info}) {
    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////
    // state
    // ref

    // const displayRef = useRef(null);

    ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////

      // useEffect(() => {
      //   // console.log('Component did mount or update');
      //   // getOverlapList() 
      //   // Cleanup function (optional)
      //   return () => {
      //     // console.log('Component will unmount');
      //   };
      // },[]);

    ////////////////////////////////////////////////////////
    // Fuction
    ////////////////////////////////////////////////////////

    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////

    return(
        <button className='WidgetOption'
        onClick={()=>action()}
        >
              <style jsx>{`${GuideStyle}`}</style>
              <GuideBalloon guideText={info} direction='top'>
              <span> {Editor.icon({icon:icon,size:20,lineHeight:30,color:'#ffffff'})}</span>
              </GuideBalloon>
        </button>
    )

  });

  export default WidgetOption;
////////////////////////////////////////////////////////
// css 부분
////////////////////////////////////////////////////////
const GuideStyle = `
    .WidgetOption{
        position: relative;
    }

`;

