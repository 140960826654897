////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { transform } from "@babel/core";
import * as babel from "@babel/core";
// [리덕스]스토어 연결
import store from "store";

// 컴포넌트 연결

// 서비스 연결
import * as Screen from '../../../service/api/screen' ; 
import * as DeCoder from '../../../service/engine/DeCoder' ; 
import * as Event from '../../../service/event/Actions' ; 
import * as Modal from '../../../service/event/ModalControl'
// 이미지 연결
import basicGrid from 'assets/edit/setting/basicGrid.png'
import expansionGrid from 'assets/edit/setting/expansionGrid.png'
import cinemaGrid from 'assets/edit/setting/cinemaGrid.png'
import noneGrid from 'assets/edit/setting/noneGrid.png'

import solidBG from 'assets/edit/setting/solidBackground.png'
import computerBG from 'assets/edit/setting/computerBackground.png'
import captureBG from 'assets/edit/setting/captureBackground.png'
import obsBG from 'assets/edit/setting/obsBackground.png'
import broadcastingBG from 'assets/edit/setting/broadcastingBackground.svg'

import widgetShow from 'assets/edit/setting/widgetShow.png'
import widgetHide from 'assets/edit/setting/widgetHide.png'

import templateIcon from 'assets/template.png';
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class EditScreenOption extends Component {
    
    state = {

    }

    changeBackground = (type) => {
        Modal.OverlayPush('EditBackground_'+type)
    }

    changeGuideLine = (type) => {
        localStorage.setItem('GuideLine',type);
        this.props.guideLineDispatch(type)
    }


    changeWidgetShow = (state) => {
        localStorage.setItem('WidgetShow',state);
        this.props.widgetShowDispatch(state)
    }
    
    
    render() { 
        return (
            <div id = "EditScreenOption">    
                <div className="setList">
                    <h3 className="setTitle">
                    편집화면 배경
                    </h3>
                    <ul className="setOption editBackground">
                        <li>
                            <div className={"optionBox " +(this.props.editBackground.mode=='solid'?'select':'')} onClick={()=>this.changeBackground('solid')}><img src={solidBG}/></div>
                            <p> 단색</p>
                        </li>
                        <li>
                            <div className={"optionBox disable " +(this.props.editBackground.mode=='broadcasting'?'select':'')} 
                            // onClick={()=>this.changeBackground('broadcasting')}
                            ><img src={broadcastingBG}/></div>
                            <p> 방송</p>
                        </li>
                        <li>
                            <div className={"optionBox " +(this.props.editBackground.mode=='capture'?'select':'')} onClick={()=>this.changeBackground('capture')}><img src={captureBG}/></div>
                            <p> 캡쳐보드</p>
                        </li>
                        <li>
                            <div className={"optionBox " +(this.props.editBackground.mode=='obs'?'select':'')} onClick={()=>this.changeBackground('obs')}><img src={obsBG}/></div>
                            <p> OBS</p>
                        </li>
                        <li>
                            <div className={"optionBox disable " +(this.props.editBackground.mode=='computer'?'select':'')} 
                            // onClick={()=>this.changeBackground('computer')}
                            ><img src={computerBG}/></div>
                            <p> 내화면</p>
                        </li>
                    </ul>
                </div>
                <div className="setList">
                    <h3 className="setTitle">
                    편집 안내선 
                    </h3>
                    <ul className="setOption">
                        <li>
                            <div className={"optionBox " +(this.props.guideLine==null?'select':'')} onClick={()=>this.changeGuideLine(null)}><img src={noneGrid}/></div>
                            <p> 없음</p>
                        </li>
                        <li>
                            <div className={"optionBox " +(this.props.guideLine=='basic'?'select':'')} onClick={()=>this.changeGuideLine('basic')}><img src={basicGrid}/></div>
                            <p> 십자선</p>
                        </li>
                        <li>
                            <div className={"optionBox " +(this.props.guideLine=='expansion'?'select':'')} onClick={()=>this.changeGuideLine('expansion')}><img src={expansionGrid}/></div>
                            <p> 안내선</p>
                        </li>
                        <li>
                            <div className={"optionBox " +(this.props.guideLine=='cinema'?'select':'')} onClick={()=>this.changeGuideLine('cinema')}><img src={cinemaGrid}/></div>
                            <p> 시네마</p>
                        </li>
                    </ul>
                </div>               
                <div className="setList">
                    <h3 className="setTitle">
                    위젯 기본값
                    </h3>
                    <ul className="setOption">
                        <li>
                            <div className={"optionBox " +(this.props.widgetShow?'select':'')} onClick={()=>this.changeWidgetShow(true)}><img src={widgetShow}/></div>
                            <p> 보이기 </p>
                        </li>
                        <li>
                            <div className={"optionBox " +(!this.props.widgetShow?'select':'')} onClick={()=>this.changeWidgetShow(false)}><img src={widgetHide}/></div>
                            <p> 숨기기 </p>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}
////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({

    guideLine :state.guideLine,
    editBackground :state.editBackground,
    widgetShow :state.widgetShow,
})

function mapDispatchToProps(dispatch){
    return {
        guideLineDispatch : (line) => dispatch({type:'guideLine',line : line}),
        widgetShowDispatch : (state) => dispatch({type:'widgetShow',state : state}),
        editBackgroundDispatch : (Background,source) => dispatch({type:'editBackground',Background : Background,source:source}),


    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(EditScreenOption);
  