////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/edit.scss"
import "sass/show.scss"


// 서비스 연결
import * as DeCoder from '../../service/engine/DeCoder' ; 
import * as Screen from '../../service/api/screen' ; 

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class MiniView extends Component {
    
    state = {
        update : 0,
        activation : false,
    }

    constructor(props) {
        super(props);
        this.viewRef = React.createRef();
        
    }

    deCoding = () => {
        return DeCoder.run({'page_widget':this.props.data},'view',this.props.update)
    }
    
    componentWillReceiveProps (nextProps){
        const io = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                // 해당 요소가 화면에 보이는 경우
                if (entry.intersectionRatio > 0) {
                    if(this.state.activation==false){
                    this.setState({activation:true})
                    }
                }
                // 해당 요소가 화면에 보이지 않는경우
                else {
                    if(this.state.activation==true){
                    this.setState({activation:false})
                    }
                }
            })
        })
        io.observe(this.viewRef.current) 
    }

    render() { 
        let screen = this.deCoding()
        const STYLE={
            layout : {
                display:'flex',
                flex : 1,  
                // backgroundColor :'#fff',
            },          
            screen : {
                // backgroundColor :'#fff',
                position: 'absolute',
                width: this.props.broadCast.size.width+'px', 
                height: this.props.broadCast.size.height+'px',
                top: ((this.props.broadCast.size.height*this.props.zoom)-this.props.broadCast.size.height)/2, 
                left: ((this.props.broadCast.size.width*this.props.zoom)-this.props.broadCast.size.width)/2,
                transform: 'scale('+this.props.zoom+')',
            }
        }
        return (
            <div className = "MiniView"  ref={this.viewRef} style={STYLE.layout}>
                {/* {this.state.activation ? */}
                <Fragment>
                <div className = "MiniViewScreen" style={STYLE.screen} >{screen}</div>
                <div className = "MiniViewCover" onContextMenu={(e)=> e.preventDefault()} contextMenu={(e)=>e.preventDefault()}/>
                </Fragment>
                {/* :<div className = "MiniViewloding" ></div> } */}
            </div>
        );
    }
}
////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    screenNo : state.screenNo,
    broadCast : state.broadCast,
})

function mapDispatchToProps(dispatch){
    return {
    //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(MiniView);
  