////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widget.scss"
import "./preset/CryptoCurrencyPreset.scss"

// [프로바이더] 연결
import EditContext from 'service/context/EditContext' ; 

// 컴포넌트 연결
import WidgetOverRay from '../Edit/editController/WidgetOverRay'; // 메뉴 헤더
import * as Preset from './preset/CryptoCurrencyPreset';

// 서비스 연결
import * as Rest from '../../service/api/rest' ; 
import * as Widget from '../../service/event/widget' ; 
import * as Model from '../../service/value/Model' ; 
import * as EnCoder from '../../service/engine/EnCoder' ; 
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

class CryptoCurrency extends Component {
    
    static defaultProps = {
        widgetName: 'CryptoCurrency',
      }

    //[임시] 컨텍스트 연결
    static contextType = EditContext;

    state = {
        ...Widget.DefaultState(this),
        moveStartX : 0,
        moveStartY : 0,
        coin:null,
        polling : 0,
        coinChange : this.props.data.value.coin,
  
    }

    constructor(props) {
        super(props);
        this.widget = React.createRef();
    }

    DefaultMethod = (type,value) =>{
        Widget.DefaultMethod(this,type,value)
    }

    componentDidMount() {
        if(this.props.parents != 'view'){
        this.currencyPrice()
        // AJAX 통신 마운트
        let count = setInterval(this.currencyPrice, 10000)
        // 내부 카운터 마운트
        let polling = setInterval(this.polling, 1000)
        this.setState({
            restInterval: count,
            pollingInterval : polling,
        },)
        }
    }

    componentWillUnmount() {
        if(this.props.parents != 'view'){
            // AJAX 통신 언마운트
            clearInterval(this.state.restInterval); 
            // 내부 카운터 언마운트
            clearInterval(this.state.pollingInterval);
        }
    }
    
    ////////////////////////////////////////////////////////
    // polling  내부적으로 사용하는 카운터 함수, 마운트 될때 생성되고 언마운트 되면 사라진다.
    ////////////////////////////////////////////////////////
    polling = () =>{
        this.setState({
            polling : this.state.polling +1
        })
    }

    ////////////////////////////////////////////////////////
    // currencyPrice async 를 사용해서 루프안에서 AJAX 통신을 구현한다.
    ////////////////////////////////////////////////////////
    currencyPrice = async () => {
        let coinList = {}
        let response 
        let myCoin = this.props.data.value.coin
        if(this.props.parents != 'view'&& myCoin!=null){
            for (let index = 0; index < myCoin.length; index++) {
                switch (myCoin[index].exchange) {
                    case 'upbit_KRW':
                        response = await Rest.CurrencyAPI(myCoin[index].exchange,myCoin[index].currency)
                        ////////////////////////////////////////////////////////
                        coinList[myCoin[index].currency] = {}
                        coinList[myCoin[index].currency].tradePrice = response.data[0].tradePrice
                        coinList[myCoin[index].currency].openingPrice = response.data[0].openingPrice
                        coinList[myCoin[index].currency].currency = myCoin[index].currency
                        coinList[myCoin[index].currency].buying = myCoin[index].buying
                        coinList[myCoin[index].currency].amount = myCoin[index].amount
                        coinList[myCoin[index].currency].revenue = myCoin[index].revenue
                        break;
                    case 'coinone':
                        response = await Rest.CurrencyAPI(myCoin[index].exchange,myCoin[index].currency)
                        ////////////////////////////////////////////////////////
                        coinList[myCoin[index].currency] = {}
                        coinList[myCoin[index].currency].tradePrice = response.data[0].last
                        coinList[myCoin[index].currency].openingPrice = response.data[0].first
                        coinList[myCoin[index].currency].currency = myCoin[index].currency
                        coinList[myCoin[index].currency].buying = myCoin[index].buying
                        coinList[myCoin[index].currency].amount = myCoin[index].amount
                        coinList[myCoin[index].currency].revenue = myCoin[index].revenue
                        break;               
                    default:
                        break;
                }



                ////////////////////////////////////////////////////////
            }
            if(this.props.data.value.total){
                coinList['total']={}
            }
            this.setState({
                coin : coinList
            })
        } else{
            this.setState({
                coin : null
            })
        }

    }

    totalPrice = () =>{
        let myCoin = Object.keys(this.state.coin).filter((element) => element !== 'total');  
        let account = 0
        let noRevenueWarning =true
        for (let index = 0; index < myCoin.length; index++) {
                if(this.state.coin[myCoin[index]].revenue &&this.state.coin[myCoin[index]].amount && this.state.coin[myCoin[index]].buying){
                    account = account+ (this.state.coin[myCoin[index]].tradePrice - this.state.coin[myCoin[index]].buying) * this.state.coin[myCoin[index]].amount
                    noRevenueWarning = false
                }
        }
        ////////////////////////////////////////////////////////
        // 수익이 있는 코인이 하나도 없는경우
        ////////////////////////////////////////////////////////
        if(noRevenueWarning){
            return <span>수익을 입력해주세요</span>
        }
        ////////////////////////////////////////////////////////
        // 수익 계산
        ////////////////////////////////////////////////////////
        if(account>0){
            ////////////////////////////////////////////////////////
            // 수익이 1000만원 이상인 경우 단위를 '만원' 으로 변경
            ////////////////////////////////////////////////////////
            if(account>100000000){
                account = Number((account/10000).toFixed(0))
                return <span className="long">{account.toLocaleString('ko-KR')+'만 원'}</span>
            }
            ////////////////////////////////////////////////////////
            // 수익률이 + 인경우  
            ////////////////////////////////////////////////////////
            account = Number(account.toFixed(0))
            return <span className="long">{account.toLocaleString('ko-KR')+'원'}</span>
        }else if (account<0){
            ////////////////////////////////////////////////////////
            // 수익률이 - 인 경우
            ////////////////////////////////////////////////////////
            account = Number(account.toFixed(0))
            return <span className="short">{'-'+(account*-1).toLocaleString('ko-KR')+'원'}</span>
        }else
            ////////////////////////////////////////////////////////
            // 수익률이 0 인 경우
            ////////////////////////////////////////////////////////
            account = Number(account.toFixed(0))
            return <span>{account.toLocaleString('ko-KR')+'원'}</span>
    }

    totalPercent = () =>{
        let myCoin = Object.keys(this.state.coin).filter((element) => element !== 'total');  
        let account = 0
        let principal = 0 
        let noRevenueWarning =true
        for (let index = 0; index < myCoin.length; index++) {
                if(this.state.coin[myCoin[index]].revenue &&this.state.coin[myCoin[index]].amount && this.state.coin[myCoin[index]].buying){
                    account = account+ (this.state.coin[myCoin[index]].tradePrice - this.state.coin[myCoin[index]].buying) * this.state.coin[myCoin[index]].amount
                    principal = principal+ this.state.coin[myCoin[index]].buying * this.state.coin[myCoin[index]].amount
                    noRevenueWarning = false
                }
        }
        ////////////////////////////////////////////////////////
        // 수익이 있는 코인이 하나도 없는경우
        ////////////////////////////////////////////////////////
        if(noRevenueWarning){
            return ''
        }
        let percent = (account/principal)*100    
        if(percent>0){
            if(percent>1000){
                return <span className="long">{percent.toFixed(0)+'%'}</span>
            }
            return <span className="long">{percent.toFixed(2)+'%'}</span>
        }else if (percent<0){
            return <span className="short">{percent.toFixed(2)+'%'}</span>
        }else
            return <span>{percent.toFixed(2)+'%'}</span>
    }


    tradePrice = (selectCoin)=>{
        // if (typeof(this.state.coin)=='object'){
        //     return 'Error : 미지원코인'
        // }

        if (selectCoin=='total'){
            return this.totalPrice()
        }
        if(this.state.coin){  
            let keys = Object.keys(this.state.coin)
            if(keys.includes(selectCoin)){
                if(this.state.coin[selectCoin].tradePrice){
                    return this.state.coin[selectCoin].tradePrice.toLocaleString('ko-KR') +'원'
                }
            }
            else{
               return '거래소를 확인해주세요'
            }
        }else{
            if(this.props.parents == 'edit'){
                return "가상화폐를 추가해보세요"
            }
            if(this.props.parents == 'show'){
                return ""
            }

        }
    }
        
    changePercent = (selectCoin)=>{
        if (selectCoin=='total'){
            return this.totalPercent()
        }
        if(this.state.coin){      
            try{ 
                let percent = this.state.coin[selectCoin].tradePrice/this.state.coin[selectCoin].openingPrice*100-100    
                if(percent>0){
                    return <span className="long">{percent.toFixed(2)+'%'}</span>
                }else if (percent<0){
                    return <span className="short">{percent.toFixed(2)+'%'}</span>
                }else
                    return <span>{percent.toFixed(2)+'%'}</span>
            }
            catch{
                return ""
            }

        }else{
            return ""
        }
    }
    // 수익 표시하기를 선택한 경우 수익을 표시합니다.
    revenueShow = (selectCoin)=>{
        if(this.state.coin){   
            try{ 
            return this.state.coin[selectCoin].revenue
            }
            catch{
            return  false
            }
          
        }
    }


    revenue = (selectCoin)=>{
        if (selectCoin=='total'){
            return this.totalPrice()
        }
        if(this.state.coin){
            if (this.state.coin[selectCoin].buying > 0 && this.state.coin[selectCoin].amount > 0 ){
                let percent = (this.state.coin[selectCoin].tradePrice - this.state.coin[selectCoin].buying) * this.state.coin[selectCoin].amount
                ////////////////////////////////////////////////////////
                // 수익 계산
                ////////////////////////////////////////////////////////
                if(percent>0){
                    ////////////////////////////////////////////////////////
                    // 수익이 1000만원 이상인 경우 단위를 '만원' 으로 변경
                    ////////////////////////////////////////////////////////
                    if(percent>100000000){
                        percent = Number((percent/10000).toFixed(0))
                        return <span className="long">{percent.toLocaleString('ko-KR')+'만 원'}</span>
                    }
                    ////////////////////////////////////////////////////////
                    // 수익률이 + 인경우  
                    ////////////////////////////////////////////////////////
                    percent = Number(percent.toFixed(0))
                    return <span className="long">{percent.toLocaleString('ko-KR')+'원'}</span>
                }else if (percent<0){
                    ////////////////////////////////////////////////////////
                    // 수익률이 - 인 경우
                    ////////////////////////////////////////////////////////
                    percent = Number(percent.toFixed(0))
                    return <span className="short">{'-'+(percent*-1).toLocaleString('ko-KR')+'원'}</span>
                }else
                    ////////////////////////////////////////////////////////
                    // 수익률이 0 인 경우
                    ////////////////////////////////////////////////////////
                    percent = Number(percent.toFixed(0))
                    return <span>{percent.toLocaleString('ko-KR')+'원'}</span>
            ////////////////////////////////////////////////////////
            // 평균 단가( buying )나 수량 ( amount ) 중 하나가 null 인경우
            ////////////////////////////////////////////////////////
            }else{
                return "수익을 입력해주세요"
            }
        }else{
            return ""
        }
    }

    revenuePercent  = (selectCoin)=>{
        if (selectCoin=='total'){
            return this.totalPercent()
        }
        if(this.state.coin){     
            let percent = this.state.coin[selectCoin].tradePrice/this.state.coin[selectCoin].buying*100-100    

            if( this.state.coin[selectCoin].amount==0 ){
                return ""
            }

            if(percent>0){
                if(percent>1000){
                    return <span className="long">{percent.toFixed(0)+'%'}</span>
                }
                return <span className="long">{percent.toFixed(2)+'%'}</span>
            }else if (percent<0){
                return <span className="short">{percent.toFixed(2)+'%'}</span>
            }else
                return <span>{percent.toFixed(2)+'%'}</span>
        }else{
            return ""
        }
    }

    render() {
        if(this.state.coinChange != this.props.data.value.coin){
            this.setState({
               coinChange : this.props.data.value.coin
            })
            this.currencyPrice()
        }
        let Style = Model.widget(this.props)
        // 드레그를 통한 위젯 이동
        Style.layout= { ...Style.layout,transform: 'translate('+this.state.dragMoveX+'px, '+this.state.dragMoveY+'px)'}
        switch (this.props.parents) {

            case 'edit':
                // 편집시 화면이동 트렌스레이션 효과 방지
                Style.layout.transition = false
                Style.object.transition = false
                if(this.props.userState.browser== "firefox"&&"safari"){
                    return (         
                            <div ref={(ref) => this.object=ref } className = {this.props.parents + " widgetObject "+this.props.widgetName+Widget.display(this.props)} style={Style.layout} tabIndex={0} draggable="true" 
                                onKeyDown={(e)=>Widget.keyDown(e,this)}
                                onClick={(e)=> Widget.isfocus(e,this)} 
                                onMouseDown={(e)=> Widget.mouseControl(e,this)} 	
                                //드레그 이벤트	
                                onDragOver={(e)=>Widget.alt_drag(e,this)} 
                                onDragStart={(e) => Widget.alt_dragStart(e,this)} 
                                onDragEnd={(e) => Widget.alt_dragEnd(e,this)} 
                                onDrag={(e)=>e.stopPropagation()} 
                                //드랍 이벤트 
                                onDrop={(e)=> Widget.drop(e,this)} 
                                onDragLeave={(e)=> Widget.dragLeave(e,this)} 
                                onDragEnter={(e)=> Widget.dragEnter(e,this)} 
                            >
                            {Preset.data(this,Style)}
                                { this.props.focus ? <WidgetOverRay target={this.props} object={this.widget.current} mode={'4_Direction'}  dragLock={this.state.dragLock} DefaultMethod={this.DefaultMethod}/> :"" } 
                            </div>
                    );
                }else{
                    return (         
                        <div ref={(ref) => this.object=ref } className = {this.props.parents + " widgetObject "+this.props.widgetName+Widget.display(this.props)} style={Style.layout} tabIndex={0} draggable="true" 
                            onKeyDown={(e)=>Widget.keyDown(e,this)}
                            onClick={(e)=> Widget.isfocus(e,this)} 
                            onMouseDown={(e)=> Widget.mouseControl(e,this)} 	
                            //드레그 이벤트
                            onDrag={(e)=>Widget.drag(e,this)} 
                            onDragStart={(e) => Widget.dragStart(e,this)} 
                            onDragEnd={(e) => Widget.dragEnd(e,this)} 
                            //드랍 이벤트 
                            onDrop={(e)=> Widget.drop(e,this)} 
                            onDragOver={(e)=> Widget.dragOver(e,this)} 
                            onDragLeave={(e)=> Widget.dragLeave(e,this)} 
                            onDragEnter={(e)=> Widget.dragEnter(e,this)} 
                        >
                            {Preset.data(this,Style)}
                            { this.props.focus ? <WidgetOverRay target={this.props} object={this.widget.current} mode={'4_Direction'}/> :"" } 
                        </div>
                    );
                }
            case 'broadcast':

                return (
                    <div ref={(ref) => this.object=ref } className = {this.props.parents + " widgetObject "+this.props.widgetName+Widget.display(this.props)} style={Style.layout} >
                        {Preset.data(this,Style)}
                    </div>
                );
          
            case 'view':

                return (
                    <div ref={(ref) => this.object=ref } className = {this.props.parents + " widgetObject "+this.props.widgetName+Widget.display(this.props)} style={Style.layout} >
                        {Preset.data(this,Style)}
                    </div>
                );
        }

    }
}
////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    userState : state.userState,
})

function mapDispatchToProps(dispatch){
    return {
        //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}


  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(CryptoCurrency);
  