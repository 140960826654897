////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { useState, useEffect, useContext, Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widgetEdit.scss"

// 컴포넌트 연결
import ImageButtonModule from '../editModule/ImageButtonModule'; // 이미지 버튼을 눌러 여러 옵션중 값을 정합니다.
import ErrorModule from '../editModule/ErrorModule'; // 잘못된 위젯의 값이 있을때 편집기를 대체합니다.


// 서비스 연결
import * as Editor from 'service/edit/EditService'; 
import * as ED from 'service/engine/decoder/EditorDecoder'; 
// 이미지
import round_0 from 'assets/sidebar/other/round_0.png';
import round_1 from 'assets/sidebar/other/round_1.png';
import round_2 from 'assets/sidebar/other/round_2.png';
import round_3 from 'assets/sidebar/other/round_3.png';
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////


/** [에디터] 텍스트 편집 툴 입니다.
 * (대상위젯, 편집펑션, 편집할 객체 키, 타이틀 ,옵션)
* @param {widget} target : 편집할 대상 위젯
* @param {function} modify : 편집용 함수
* @param {{
* size: { width : number, height : number }
* }} keyName : 해당 에디터가 수정하는 값 입니다. value : {'textValue', 수정할 대상값,} 
* @param {{
* editTitle: string,textValue: string
* }} title : 편집기에 표시될 제목들 입니다.
* @param {{
* fixToggle : boolean 
* openToggle : boolean
* }} option : 추가 옵션입니다. fixToggle : 토글을 고정합니다. openToggle : 필드의 기본 상태를 열림으로 봅니다.
*/


function RadiusEditForm({target,modify,keyName,title,option}) {

/**
     * 위젯을 수정합니다.
     *  @param {bool} classify : 수정할 값의 분류를 적습니다.
     *  @param {bool} key : 수정할 키의 이름을 적습니다.
     *  @param {bool} value : 해당페이지가 선택한 뷰에서 방송중인지 확인합니다.
     */

    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////

    // set
    const uuid = target.uuid
    // state
    const [Toggle, setToggle] = useState(option?.openToggle==undefined?true:option?.openToggle);
    const [OpenForm, setOpenForm] = useState(option?.openForm==undefined?true:option?.openForm);

    // rel 

    ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////
    useEffect(() => {

        // 토글의 사용 여부 및 필드의 열림 상태를 정의합니다.

        // 여기에 컴포넌트가 마운트되었을 때 실행할 코드를 작성합니다.

        return () => {
        // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
        };
      }, []);

    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////

    // 해당 에디터에서 편집이 가능한 값 목록
    const {borderRadius} = keyName ;

    const borderRadiusShort = () =>{
        
        if (borderRadius){
            let list = (
                    
                    <span className="ShortButtonList" >
                        <div className={'shortButton shortOptionButton '+(ED.getValue(target,borderRadius)==MaxRadius?'shortButtonSelect':'')} onClick={()=>modify(borderRadius.category,borderRadius.key,MaxRadius,uuid)}>
                            <img src={round_3} width={15} height={15}/>
                        </div>
                        <div className={'shortButton shortOptionButton '+(ED.getValue(target,borderRadius)==25?'shortButtonSelect':'')} onClick={()=>modify(borderRadius.category,borderRadius.key,25,uuid)}> 
                            <img src={round_2} width={15} height={15}/>
                        </div>
                        <div className={'shortButton shortOptionButton '+(ED.getValue(target,borderRadius)==10?'shortButtonSelect':'')} onClick={()=>modify(borderRadius.category,borderRadius.key,10,uuid)}>
                            <img src={round_1} width={15} height={15}/>
                        </div> 
                        <div className={'shortButton shortOptionButton '+(ED.getValue(target,borderRadius)==0?'shortButtonSelect':'')} onClick={()=>modify(borderRadius.category,borderRadius.key,0,uuid)}> 
                            <img src={round_0} width={15} height={15}/>
                        </div>
                    </span>
               
            )
            return list
        }
    }


    const MaxRadius = Math.min(target.data.size.width,target.data.size.height)/2


    const Options = {}


    if(option.closeEdit){
        Options.closeEdit = (<div className='closeEdit'>
            {borderRadiusShort()} 
        </div>)
    }

    return (
    <Fragment>
        <style jsx>{`${FormStyle}`}</style>
        <section className='RadiusEditForm'>
            {Editor.editorDetail('모서리','rounded_corner',OpenForm,setOpenForm,Toggle,Options)}
            <div className={OpenForm?'editContainer containerOpen':'editContainer containerClose'}>
                {/* {Editor.editorSubTitle('텍스트 설정',"edit_note")} */}
                <article className="EditPart" >
                {borderRadius?<ImageButtonModule value={ED.getValue(target,borderRadius)} type={borderRadius?.key} data={[{val:MaxRadius,image:round_3,text:'text',title:"원형모서리"},{val:25,image:round_2,title:"둥근모서리"},{val:10,image:round_1,title:"약간 둥근모서리"},{val:0,image:round_0,title:"각진모서리"}]} title={borderRadius?.title??"둥글게"} change={(type,value)=>modify(borderRadius?.category,type,value,uuid)}/>:''}
                </article>
            </div>
        </section>
    </Fragment>
    );

}

export default RadiusEditForm;


const FormStyle = `

.RadiusEditForm .closeEdit{
    height: 25px;
    display:flex;
    background-color: #444;
    border-radius :5px;
    padding : 0px;
}

.RadiusEditForm .closeEdit .ShortButtonList{
    display : flex; 
    width: 110px;
    justify-content: space-around;
    align-items: center;
 
 }
 .RadiusEditForm .closeEdit .shortButton{
     width: 20px;
     height: 20px;
     display : flex;
     align-items: center; 
     justify-content: center;
 }
 .RadiusEditForm .closeEdit .shortButtonSelect{
    background-color: #262626;
    border-radius : 2.5px;
}
`;