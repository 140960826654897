////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { useState, useEffect, useContext, Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widgetEdit.scss"

// 컴포넌트 연결
import ColorModule from '../editModule/ColorModule'; // 이미지 버튼을 눌러 여러 옵션중 값을 정합니다.
import ErrorModule from '../editModule/ErrorModule'; // 잘못된 위젯의 값이 있을때 편집기를 대체합니다.
import ColorModuleV2 from '../editModule/ColorModuleV2'; // 이미지 버튼을 눌러 여러 옵션중 값을 정합니다.

// 서비스 연결
import * as Editor from 'service/edit/EditService'; 
import * as ED from 'service/engine/decoder/EditorDecoder'; 
import * as Utility from'service/other/Utility.js' ; 
// 이미지 연결
import opacity_0 from 'assets/sidebar/other/opacity_0.png';
import opacity_1 from 'assets/sidebar/other/opacity_1.png';
import opacity_2 from 'assets/sidebar/other/opacity_2.png';
import opacity_3 from 'assets/sidebar/other/opacity_3.png';
import { Edit } from '@material-ui/icons';

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////


/** [에디터] 텍스트 편집 툴 입니다.
 * (대상위젯, 편집펑션, 편집할 객체 키, 타이틀 ,옵션)
* @param {widget} target : 편집할 대상 위젯
* @param {function} modify : 편집용 함수
* @param {{
* size: { width : number, height : number }
* }} keyName : 해당 에디터가 수정하는 값 입니다. value : {'textValue', 수정할 대상값,} 
* @param {{
* editTitle: string,textValue: string
* }} title : 편집기에 표시될 제목들 입니다.
* @param {{
* fixToggle : boolean 
* openToggle : boolean
* }} option : 추가 옵션입니다. fixToggle : 토글을 고정합니다. openToggle : 필드의 기본 상태를 열림으로 봅니다.
*/


function BG_GradientEditForm({target,modify,keyName,title,option}) {

/**
     * 위젯을 수정합니다.
     *  @param {bool} classify : 수정할 값의 분류를 적습니다.
     *  @param {bool} key : 수정할 키의 이름을 적습니다.
     *  @param {bool} value : 해당페이지가 선택한 뷰에서 방송중인지 확인합니다.
     */

    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////

    // set
    const uuid = target.uuid
    // state
    const [Toggle, setToggle] = useState(option?.openToggle==undefined?true:option?.openToggle);
    const [OpenForm, setOpenForm] = useState(option?.openForm==undefined?true:option?.openForm);

    const [Gradient, setGradient] = useState([]);
    const [SelectColor, setSelectColor] = useState('#000000');
    // rel 

    ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////
    useEffect(() => {

        setGradient(ED.getValue(target,gradientColor))

        // 여기에 컴포넌트가 마운트되었을 때 실행할 코드를 작성합니다.

        return () => {
        // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
        };
      }, []);

    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////

    // 해당 에디터에서 편집이 가능한 값 목록
    const {gradientColor} = keyName ;

    const addColor = (color) => {
        const GradientArray = ED.getValue(target,gradientColor);
        GradientArray.push(color)
        modify(gradientColor?.category,gradientColor?.key,GradientArray,uuid)
        setGradient(GradientArray)
    }

    const delColor = (index,color) => {
        const GradientArray = Utility.deepCopy(Gradient)
        GradientArray.splice(index, 1);
        modify(gradientColor?.category,gradientColor?.key,GradientArray,uuid)
        setGradient(GradientArray)
    }

    const changeColor = (index, color)=>{
        const GradientArray = Utility.deepCopy(Gradient)
        GradientArray[index] = color;
        console.log('GradientArray',GradientArray);
        // modify(gradientColor?.category,gradientColor?.key,GradientArray,uuid)
        setGradient(GradientArray)
    }


    const setColor = (index, color)=>{
        modify(gradientColor?.category,gradientColor?.key,Gradient,uuid)
    }


    const PaletteList = Gradient.map(
        (item,index) => (
        <div className="GradientBox" >
            <div className="GradientBoxDel" onClick={()=>delColor(index)}>
                {Editor.icon({icon:'close',size:15,lineHeight:20})}
            </div>
            <ColorModuleV2 value={item} type={index} title={`색상 ${index+1}`} change={(index,value)=>{changeColor(index,value)}} blur={()=>setColor()}/>
         </div>
        ) 
    );  
    
    return (
    <Fragment>
        <style jsx>{`${FormStyle}`}</style>
        <section className='BG_GradientEditForm'>

            {Editor.editorDetail('배경 설정','background_grid_small',OpenForm,setOpenForm,Toggle)}
            <div className={OpenForm?'editContainer containerOpen':'editContainer containerClose'}>
                {Editor.editorSubTitle('배경 설정',"stroke_full")}
                <article className="EditPart" >
                    
                    <nav>  
                        
                    </nav>
                    <div>
                        <div className='colorPalette'>

                        </div>
                        {PaletteList}
                        <div className='apeendColor' onClick={()=>addColor('#ffffff')}>
                        {Editor.icon({icon:'add',size:25,lineHeight:30})} 새로운 컬러 추가
                        </div>
                    </div>
                </article>
            </div>
        </section>
    </Fragment>
    );

}

{/* <div  className='TeamBasicData' >
<input className='TeamNameInput' placeholder='팀 이름 입력' value={SelectTeam?.teamName??''} onChange={(e)=>modify('teamName',e.target.value)} onBlur={()=>update()} />

<div className='TeamColor'>
<small>팀 색상</small> <input className='TeamColorInput'value={SelectTeam?.teamColor} type='color'  onChange={(e)=>modify('teamColor',e.target.value)} onBlur={()=>update()}  />
</div>
<div className='TeamColor'>
<small>팀 폰트 색상</small> <input className='TeamColorInput' value={SelectTeam?.teamFontColor} type='color'  onChange={(e)=>modify('teamFontColor',e.target.value)} onBlur={()=>update()}  />
</div>

</div> */}

export default BG_GradientEditForm;


const FormStyle = `

    .BG_GradientEditForm .GradientPalette{
        font-family: "프리텐다드SemiBold", sans-serif;
        border-radius: 5px;
        background: #222222;
        height : 30px;
        box-shadow: 3.5px 3.5px 10px #252525, -3.5px -3.5px 10px #636363;
        margin: 10px 0px;
        width: calc(100% - 10px);
        padding: 5px 5px;
        display: flex;
    }
        
    .BG_GradientEditForm .GradientBox{
        width: calc(100% - 20px);
        padding : 0px 10px;
        height : 40px;
        margin : 5px 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #222222;
        border-radius : 5px
    }
    .BG_GradientEditForm .GradientBoxDel{
        width : 20px;
        height : 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right : 10px;
        border-radius : 5px;
        transition: background-color 0.3s ease;
    }
    .BG_GradientEditForm .GradientBoxDel:hover{
        background: red;

    }
    .BG_GradientEditForm .apeendColor{
       width: calc(100% - 20px);
        padding : 0px 10px;
        height : 40px;
        margin : 5px 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #333;
        border-radius : 5px;
        transition: background-color 0.3s ease;
    }
    
    .BG_GradientEditForm .apeendColor:hover{
        background: #222222;   
    }
    

`;

function hexToRGB(hex) {
    const r = parseInt(hex.slice(1, 3), 16);  // Red
    const g = parseInt(hex.slice(3, 5), 16);  // Green
    const b = parseInt(hex.slice(5, 7), 16);  // Blue
    
    return { red: r, green: g, blue: b };
  }

function rgbToHex({ red, green, blue }) {
const toHex = (value) => value.toString(16).padStart(2, "0").toUpperCase();

return `#${toHex(red)}${toHex(green)}${toHex(blue)}`;
}