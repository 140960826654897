////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { Route, Link } from 'react-router-dom';
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/main.scss"

// 이미지 연결
import logoW from 'assets/logoW.svg';
import twitchLogo from 'assets/main/twitchlogo.png';
import edit from 'assets/main/edit.png';
import bot from 'assets/main/bot.png';
import control from 'assets/main/control.png';
import banner1 from 'assets/main/banner1.png';

// 컴포넌트 연결
// import Text from './template/Text'; // 메뉴 헤더

// 서비스 연결
import * as Rest from 'service/api/rest' ; 
import { token, removeToken } from 'service/event/Account'; 
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class Main extends Component {
    
    state = {
        userId : ''
    }

    noMakeAlert = () => {
        alert("준비중인 기능입니다.");
    } 
    
    // tokenToId = () =>{
    //     let response =  Rest.request('tokentoid','GET','',sessionStorage.getItem("JWT"))
    //     response
    //     .then((result) => {
    //         this.setState({
    //             userId : result.data.userId
    //         })
    //         }
    //     )
    //     .catch((error) => error)
    // }

    logout = () => {
        removeToken("account")
        window.location.href='/';
    }
    componentDidMount() {
        // 토큰 검사 필요 


        
        // if (this.state.userId==null){
            this.tokenToId()
        // }
    }    
    

    componentDidUpdate(prevProps){
        
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-R99737TJCR", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
        }
    }
    render() { 

        return (
            <div id = "MainPage" >
                <header>
                    <nav>
                        <div id="MainPageLogo">
                            <img className="mainLogo" src={logoW}/> <h1>OVERLAP</h1>
                        </div>
                        <ul id="nav">
                            <li>
                            <Link to="/edit">편집하기</Link>
                            </li>
                            <li>
                            <a onClick={() => window.open('/show/'+this.state.userId, '_blank')}>미리보기</a>
                            {/* <Link to={"/show/"+this.state.userId}></Link> */}
                            </li>
                            <li>
                            <a onClick={()=>this.noMakeAlert()}>컨트롤러</a>
                            </li>
                        </ul>
                        <button id="logout" onClick={()=>this.logout()}>
                            로그아웃 
                        </button>
                    </nav>
                </header>
                <main>
                    <div className="banner">
             
                        <Link to="/tutorial">
                        <div className="tutorial">
                            <img src={banner1}/>
                        </div>
                        </Link>
                    </div>
                
                    <div className="cardList">
                        <Link to="/edittutorial">
                        <div className="actionCard">
                            <div className="cardImg">
                                <img src={edit}/>
                            </div>
                            
                            <div className="cardText">
                                <h3 className="cardName">편집하기</h3>
                                <p className="cardInfo">
                                방송 화면을 커스터마이징<br/>
                                할 수 있습니다
                                </p>
                            </div>
                        </div>
                        </Link>
                        <div className="actionCard">
                            <div className="cardImg">
                                <img src={control}/>
                            </div>
                            
                            <div className="cardText">
                                <h3 className="cardName">[준비중]컨트롤러</h3>
                                <p className="cardInfo">
                                실시간 방송을 손쉽게 <br/>
                                제어 할 수 있습니다.  
                                </p>
                            </div>
                        </div>

                        <div className="actionCard">
                            <div className="cardImg">
                                <img src={bot}/>
                            </div>
                            
                            <div className="cardText">
                                <h3 className="cardName">[준비중]봇설정</h3>
                                <p className="cardInfo">
                                트위치, 유튜브와 연동해서<br/>
                                실시간 채팅과시청자수를 가져옵니다. 
                                </p>
                            </div>
                        </div>

                        <div className="actionCard">
                            <div className="cardImg">
                                <img src={twitchLogo}/>
                            </div>
                            
                            <div className="cardText">
                                <h3 className="cardName">[준비중]연동설정</h3>
                                <p className="cardInfo">
                                트위치, 유튜브와 연동해서<br/>
                                실시간 채팅과시청자수를 가져옵니다. 
                                </p>
                            </div>
                        </div>
                    </div>

                        <div>
                            개발자와 연락이 필요하신가요?  <br/> <a href="https://discord.gg/uYMhv8sZ4W">디스코드</a>, 오픈채팅
                        </div>
                        {/* <input value={"https://spark-obs.web.app/show/"+this.state.userId} type="text"/> */}
                </main>
                <div>
                    
                </div>
                

            </div>
        );
    }
}
////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    screenData : state.screenData,
    passToken : state.passToken,
})

function mapDispatchToProps(dispatch){
    return {
    //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(Main);
  