////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { Route, Link } from 'react-router-dom';
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/frontPage.scss"

// 이미지 연결
import logo from 'assets/logo.svg';
// 컴포넌트 연결
// import Text from './template/Text'; // 메뉴 헤더
import FrontNav from './FrontNav'; // 작은 스크린
// 서비스 연결
import * as Template from 'service/event/template';
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class FrontWidget extends Component {
    
    state = {
        zoom : 0,
        page : 0
    }

    constructor(props) {
        super(props);
        this.myRef = React.createRef();
      }

    componentDidMount() {
        // let count = setInterval(this.page, 3000)
        // this.setState({
        //     zoom : this.myRef.current.clientWidth*1.0/1920,
        //     interval: count,
        // })
    }

    page = () =>{
        this.setState({
            page : this.state.page+1
        })
    }  

    componentWillUnmount() {
        clearInterval(this.state.interval);
    }

    render() { 
        let templateList = Template.object('OverwatchLeages_white')
        let info = Template.object('FrontInfo')
        let infoNO = this.state.page%info.pages.length
        let pageNo = 0
        pageNo = this.state.page%templateList.pages.length
        return (
            <div id = "WidgetInfoPage" >
                <FrontNav/>
                {/* 동영상 소개 */}
                <section id = "infoPageTitle">
                    <div className="titleText">
                        위젯 소개
                    </div>
                    <div className="titleImg relative">
                    {/* <img className="cryptoCurrency" src="https://overlap.s3-ap-northeast-1.amazonaws.com/front/img/frontCryptoCurrency.png"/> */}
                    <img className="reinhardt" src="https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/yeonvelyStyle/reinhardt.png"/>
                    <img className="mccree" src="https://overlap.s3-ap-northeast-1.amazonaws.com/widget/OverwatchHeroes/yeonvelyStyle/mccree.png"/>
                    {/* <img className="timer" src="https://overlap.s3-ap-northeast-1.amazonaws.com/front/img/frontTimer.png"/> */}
                    </div>
           
                </section>
                {/* 오버랩의 위젯 알아보기 */}
                <section id="widgetInfo" className="relative">
                    <div className="infoText">
                        <h3>위젯을<br/> 소개합니다</h3>
                        <p> 나의 방송에 다양한 위젯을 추가해보세요</p>
                    </div>
                    <img className="cryptoCurrency" src="https://overlap.s3-ap-northeast-1.amazonaws.com/front/img/frontCryptoCurrency.png"/>
                    <img className="timer" src="https://overlap.s3-ap-northeast-1.amazonaws.com/front/img/frontTimer.png"/>
                    <div className="infoTextTimer">
                        <h3>타이머를 <br/>추가해보세요</h3>
                        <p> 다용도로 사용 할 수 있습니다.</p>
                    </div>
                    {/* <img className="arrow1" src="https://overlap.s3-ap-northeast-1.amazonaws.com/front/img/templateArrow1.png"/>
                    <img className="arrow2" src="https://overlap.s3-ap-northeast-1.amazonaws.com/front/img/templateArrow2.png"/>
                    <img className="arrow3" src="https://overlap.s3-ap-northeast-1.amazonaws.com/front/img/templateArrow3.png"/> */}
                </section>
                <section className="standardArea" >
        
                    준비중입니다 끝내주는 소개를 만들어볼게요
      
                </section>
            </div>
        );
    }
}
////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    passToken : state.passToken,
})

function mapDispatchToProps(dispatch){
    return {
    //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(FrontWidget);
  