import { forEach } from "lodash";

/**
 * 위젯 객체의 폰트 옵션을 찾고
 * 대상 css : color,fontSize,fontWeight,fontFamily,textAlign,verticalAlign,textOutline,outlineColor,textShadowColor,textShadowValue
 * @param {} style  : 대상의 스타일
 * @param {Object} target : 편집 가능한 속성
 * @param {string} color : 편집 가능한 속성
 * @param {number} fontSize : 편집 가능한 속성
 * @param {number} fontWeight : 편집 가능한 속성
 * @param {string} fontFamily : 편집 가능한 속성
 * @param {string} textAlign : 편집 가능한 속성
 * @param {string} verticalAlign : 편집 가능한 속성
 * @param {string} textOutline : 편집 가능한 속성
 * @param {string} outlineColor : 편집 가능한 속성
 * @param {string} textShadowColor : 편집 가능한 속성
 * @param {string} textShadowValue : 편집 가능한 속성
 * @returns 
 */
export function font ({style,target}) {
  
  const hasKey = Object.keys(target)

  const List = ['color','fontWeight','fontFamily','textAlign','verticalAlign'];
  let result = {}

  List.forEach(element => {
    if(hasKey.includes(element)){
      result[element] = style[target[element]]
    }
  });

  if(hasKey.includes('fontSize')){
    result['fontSize'] = style[target['fontSize']] + 'px'
  }

  // TODO ,'textOutline','outlineColor','textShadowColor','textShadowValue' 속성 만들기
  return result
}

