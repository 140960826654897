////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { Route, Link } from 'react-router-dom';
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결


// 이미지 연결
import logoW from 'assets/logoW.svg';
import twitchLogo from 'assets/main/twitchlogo.png';
import edit from 'assets/main/edit.png';
import bot from 'assets/main/bot.png';
import control from 'assets/main/control.png';
import banner1 from 'assets/main/banner1.png';

// 컴포넌트 연결
// import Text from './template/Text'; // 메뉴 헤더
import Tutorial from "../Tutorial"

// 서비스 연결
import * as Rest from 'service/api/rest' ; 
import * as Screen from 'service/api/screen' ; 
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class Guide extends Component {
    
    state = {
        userId : ''
    }


    screenLode = () => {
        let response = Screen.broadcastScreenload()
        response
        .then((result) => {
            let resultData = [JSON.parse(result.data.body)]
            resultData = resultData[0]
            if (this.state.screenData!=resultData){
                this.setState({
                    screenData : resultData
                })
            }
        })
        .catch((error) => {
            console.log(error)
        })
    }


    render() { 
        
        return (
            <div className = "Lobby_innerPage" >


            <div id = "Lobby_Guide" >
               <div className = "guideinfo" >
                   <div  className = "infoLeft">
                    <img className="logo" src={logoW}/>
                        <h1>
                            여기서 오버랩의 사용법을 알아보세요
                        </h1>
                    </div>
               </div>
               <div className = "guideState" >
                    <div className = "myState" >
                        <div className = "line"/>
                        <div className="stap stapone"> 1 </div>
                        <div className="stap staptwo"> 2 </div>
                        <div className="stap stapthree"> 3 </div>
                        <div className="stap stapfour"> 4 </div>
                    </div>
                    <div  className = "guidelist" >
                        <div className = "guideCard" >
                            <div className = "guideCardImg" >

                            </div>
                            <div className = "guideCardTitle" >
                            송출프로그램 (OBS,Xsplit)과 오버랩을 연결해보세요
                            </div>
               
                        </div>
                        <div className = "guideCard" >
                            <div className = "guideCardImg" >

                            </div>
                            <div className = "guideCardTitle" >
                            오버랩을 편집 하는 방법을 알아보세요
                            </div>
                        </div>
                        <div className = "guideCard" >
                            <div className = "guideCardImg" >

                            </div>
                            <div className = "guideCardTitle" >
                            오버랩을 편집 하는 방법을 알아보세요
                            </div>
                        </div>
                        <div className = "guideCard" >
                            <div className = "guideCardImg" >

                            </div>
                            <div className = "guideCardTitle" >
                            오버랩 내에서 웹캠, 캡쳐, 변수 사용방법을 알아보세요 
                            </div>
                        </div>
                    </div>
               </div>
            </div>
            </div>
        )
    }
}
////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    screenData : state.screenData,
    storeScreenNo : state.screenNo,
})

function mapDispatchToProps(dispatch){
    return {
    //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(Guide);
  