////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { Route, Link } from 'react-router-dom';
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import 'sass/login.scss'

// 이미지 연결
import logoW from 'assets/logoW.svg';

// 서비스 연결
import * as Rest from 'service/api/rest' ; 

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class NewAccountSetting extends Component {
    
    state = {
        userName : null
    }

    account = (e) =>{

        if (this.state.userName==null){
            alert("닉네임을 입력해주세요");
            return 
        }

        let response =  Rest.fullUrlrequest('http://127.0.0.1:8000/login/','Post',{"localLoginID":this.state.overlapId,"localLoginPW":this.state.password})
        response
        .then((result) => {
                console.log(result)
                sessionStorage.setItem("JWT",result.data.token);
                if(result.data.account == false){
                    window.location.href='/NewAcoountSetting';
                }else if (result.data.account == true){
                    window.location.href='/main';
                }
            }
        )
        .catch((error) => {
            alert("잘못된 아이디&비밀번호 입니다");
        }
        
        )
    }
        

    settings = () =>{
        return(
            <div id="userNameSetting">
                <h2 className='setTitle'>
                    프로필 설정
                </h2>
                <div className='setField'>
                    {/* <div className='setUserImg'>
                        <p>
                        프로필이미지
                        </p>
                
                        <img className='selectIMG' src={logoW}/>
                        <div>
                        <img className='listIMG' src={logoW}/>
                        <img className='listIMG' src={logoW}/>
                        <img className='listIMG' src={logoW}/>
                        <img className='listIMG' src={logoW}/>
                        <img className='listIMG' src={logoW}/>
                        </div>
                    </div> */}
                    <div className='setUserName'>
                        <p>
                        닉네임
                        </p>
                        <input></input>
                    </div>
                </div>
                <div className='AccountSettingButtons'>
                    <div className='buttonDiv'>
                    {/* <button className='prev'>이전</button> */}
                    </div>
                    <div className='buttonDiv'>
                    <button className='next'  onClick={(e)=>this.account(e)}>완료</button>
                    </div>
  

                </div>
            </div>
        )

    }


    render() { 

        return (
            <div id="NewAccountSetting">
            <main>
                {this.settings()}
    
                </main>

            </div>
  
        );
    }
}
////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    //exemple : state.exemple,
})

function mapDispatchToProps(dispatch){
    return {
    //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(NewAccountSetting);
  