////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { Route, Link } from 'react-router-dom';
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/screen.scss"


// 컴포넌트 연결

// 서비스 연결
import * as DeCoder from '../../service/engine/DeCoder' ; 
import * as Screen from '../../service/api/screen' ; 

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class Show extends Component {
    
    state = {
        screenData : '',
        height:0,
        width:0,
        zoom: 0,
        hover:false,
    }
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
      }

    screenLode = () => {
        let response = Screen.broadcastScreenload(this.props.match.params.userid)
        response
        .then((result) => {
            let resultData = [JSON.parse(result.data.body)]
            resultData = resultData[0]
            if (this.state.screenData!=resultData){
                this.setState({
                    screenData : resultData
                })
            }
        })
        .catch((error) => {
            console.log(error)
        })
    }
    deCoding = () => {
        console.log(this.state.screenData)
        return DeCoder.run(this.state.screenData,'broadcast')
    }

    componentDidUpdate(prevProps){
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-R99737TJCR", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
        }
    }

    componentDidMount() {
        // this.screenLode()
        // let screenRefresh = setInterval(() => this.screenLode(), 2000);

         window.addEventListener('resize', this.screenResize)
         this.setState({
            // screenRefresh : screenRefresh,
             height: this.myRef.current.clientHeight,
             width: this.myRef.current.clientWidth,
             zoom : Math.min(this.myRef.current.clientWidth/this.props.broadCast.size.width,this.myRef.current.clientHeight/this.props.broadCast.size.height)
         })
    }
     componentWillUnmount(){
        window.removeEventListener('resize', this.screenResize)
    //    clearInterval(this.state.screenRefresh)
       
     }
     screenResize = () => {
         console.log(this.myRef.current,'중요')
        this.setState ({
                height: this.myRef.current.clientHeight,
                width: this.myRef.current.clientWidth,
                zoom : Math.min(this.myRef.current.clientWidth/this.props.broadCast.size.width,this.myRef.current.clientHeight/this.props.broadCast.size.height)
         })
    }
    hoverNav = (show) => {
        if (show){
            return (
                <div className="hoverView">
                    <nav className="hoverNav">
                        <span>
                        <li><Link to="/main">돌아가기</Link></li>
                        <li><Link to="/tutorial">연결가이드</Link></li>
                        </span>
        
                        <li>송출해상도 :{this.props.broadCast.size.width}X{this.props.broadCast.size.height} </li>
                    </nav>
                </div>
            )}
        else {
            return(
        <div className="hoverView opacity">
            <nav className="hoverNav">
                <span>
                <li><Link to="/main">돌아가기</Link></li>
                <li><Link to="/tutorial">연결가이드</Link></li>
                </span>

                <li>송출해상도 :{this.props.broadCast.size.width}X{this.props.broadCast.size.height} </li>
            </nav>
        </div>
            )
            
        }
    }

    render() { 
        // this.data()
        const Style = {
            LAYOUT : {
                width: this.props.broadCast.size.width+'px', 
                height: this.props.broadCast.size.height+'px',
                top: (this.state.height-this.props.broadCast.size.height)/2, 
                left: (this.state.width-this.props.broadCast.size.width)/2,
                transform: 'scale('+this.state.zoom+')',
                position: 'relative'
            },
        }
        return (
            <div  id = "ShowPage" ref={this.myRef} onMouseOver={()=>this.setState({hover:true})} onMouseLeave={()=>this.setState({hover:false})}>
                <div className='screenWrap' style={Style.LAYOUT}>
                    {this.deCoding()}
                </div>
                    {this.hoverNav(this.state.hover)}
            </div>
        );
    }
}
////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    screenData : state.screenData,
    screenNo : state.screenNo,
    broadCast : state.broadCast,
})

function mapDispatchToProps(dispatch){
    return {
    //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(Show);
  