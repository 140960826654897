////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/edit.scss"

// 이미지 연결
// import videoIcon from 'assets/video.svg';
// import imageIcon from 'assets/image.svg';
// import timerIcon from 'assets/timer.svg';

// 컴포넌트 연결
import TemplateModal from './TemplateModal'; // 메뉴 헤더
import EditTip from './EditTip'; // 메뉴 헤더
import EditBackgroundSelect from './EditBackgroundSelect'; // 메뉴 헤더

// 서비스 연결
// import * as DeCoder from '../..s/service/transform/DeCoder' ; 
// import * as EeCoder from 'service/engine/EnCoder';
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class EditOverlay extends Component {
    
    state = {
        templateModal : true,
    }
    
    TemplateModal = () =>{
        this.setState({
            templateModal : false,
        })
    }
    overlay = () => {
        let overlay = []
        if(this.props.overlay.indexOf('TemplateModal')!=-1){
            overlay.push(<TemplateModal/>) 
        }
        if(this.props.overlay.indexOf('EditTip')!=-1){
            overlay.push(<EditTip exit={this.tipBox}/>) 
        }
        if(this.props.overlay.indexOf('EditBackground_solid')!=-1){
            overlay.push(<EditBackgroundSelect background={'solid'}/>) 
        }
        if(this.props.overlay.indexOf('EditBackground_broadcasting')!=-1){
            overlay.push(<EditBackgroundSelect background={'broadcasting'}/>) 
        }
        if(this.props.overlay.indexOf('EditBackground_capture')!=-1){
            overlay.push(<EditBackgroundSelect background={'capture'}/>) 
        }
        if(this.props.overlay.indexOf('EditBackground_obs')!=-1){
            overlay.push(<EditBackgroundSelect background={'obs'}/>) 
        }
        if(this.props.overlay.indexOf('EditBackground_computer')!=-1){
            overlay.push(<EditBackgroundSelect background={'computer'}/>) 
        }
        return overlay
    }

    render() { 
        return (
            <div id="EditOverlay">
                {this.overlay()}
            </div>  
        );
    }
}
////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    overlay : state.editOverlay,
})

function mapDispatchToProps(dispatch){
    return {
        editOverlayPush : (edit) => dispatch({type:'editOverlayPush',edit}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(EditOverlay);
  