////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widgetEdit.scss"

// 이미지 연결


// 컴포넌트 연결
import Show from './NumberModule' //넘버 모듈

// 서비스 연결

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

class InfoButtonModule extends Component {

    render() { 

        return (
            <label className="InfoButtonModule" htmlfor="fname"> 
            <button onClick={() => this.props.change(this.props.type,this.props.value)}>
            <div className="dataArea">
                <img src={this.props.infoImg} />
                <div className="textArea">
                    <h3>
                        {this.props.title}
                    </h3>
                    {/* <p>
                        {this.props.infoText}
                    
                    </p> */}
                    <input type="range" className="rangeInput" name="volume" min={this.props.min} max={this.props.max} value={this.props.value} onChange={(e) => this.props.change(this.props.type,e.target.value)} />
                    {/* <input type="range" name="volume" min={this.props.min} max={this.props.max} ></input> */}
                </div>
            </div>
            </button>
            </label>
        );
    }
}

////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    // screenData : state.screenData,
})

function mapDispatchToProps(dispatch){
    return {
    //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(InfoButtonModule);
  