import React, { Component , Fragment } from 'react';

import * as EnCoder from 'service/engine/EnCoder'; 

import logoW from 'assets/logoW.svg';

function preset(preset,self,data,style,option){
    let fullsize = data.size.height
    let width = data.size.width
    let profile = self.state.profile
    let dataList = []
    let dataField =''

    let opacity= Math.round(data.style.fillOpacity/100 * 255).toString(16)
    if (opacity.length==1){ opacity= 0+opacity }

    let header_opacity= Math.round(data.style.header_fillOpacity/100 * 255).toString(16)
    if (opacity.length==1){ opacity= 0+opacity }

    switch (preset) {        
        case 'patternDesign':
                let widthValue = ''
                let heightValue = ''
                if(data.size.width){
                    widthValue = data.size.width
                    if(String(widthValue).indexOf('%')!=-1 ){
                        widthValue = EnCoder.percentValue(widthValue,'width') 
                    }
                }
                if(data.size.height){
                    heightValue = data.size.height
                    if(String(heightValue).indexOf('%')!=-1 ){
                        heightValue = EnCoder.percentValue(heightValue,'height') 
                    }
                }
                let widthSize =  Number(widthValue)+Number(data.value.patternSize)*2
                let heightSize =  Number(heightValue)+Number(data.value.patternSize)*2
                let patternNum = Math.floor(widthSize/Number(data.value.patternSize)) * Math.floor(heightSize/Number(data.value.patternSize))
                let pattern = []

                let patternData = ('NO DATA')
                    switch (data.value.type) {
                        case 'text':
                            patternData = (<pre className="test111" style={{...style.pattern,'textShadow':'0 35px 25px rgba(0, 0, 0, 0.05), 0 5px 5px rgba(0, 0, 0, 0.3)' }} >{data.value.text?data.value.text:'텍스트를\n입력하세요'}</pre>)
                            break;
                        case 'image':
                            patternData = (<img style={{...style.pattern,}} src={data.value.mediaSource?data.value.mediaSource:logoW}/>)
                            break;               
                    }


                for (let index = 0; index < patternNum; index++) {
                    pattern.push(
                        <div className="pattern" style={{'width':data.value.patternSize,'height':data.value.patternSize}}>
                        {patternData}
                        </div>
                    )
                }
                if(option=='broadcast'||option=='edit'){
                    return(
                        <div className={"object "+self.props.widgetName+"_preset_"+preset} ref={self.widget} style={{...style.object}} >
                            <div className="patternDesign" style={{
                                'width': Math.floor(widthSize/Number(data.value.patternSize)) * data.value.patternSize +'px',
                                'height': Math.floor(heightSize/Number(data.value.patternSize)) * data.value.patternSize +'px',
                                'animation': data.value.speed + 's '+data.value.animationTiming+' infinite normal none running ' + data.value.patternDesign
                                }}>
                                {pattern}
                            </div>
                        </div>
                    )
                }
            if(option=='view'){
                return(
                    <div className={"object "+self.props.widgetName+"_preset_"+preset} ref={self.widget} style={{...style.object}} >
                        <div className="patternDesign" style={{
                            'width': Math.floor(widthSize/Number(data.value.patternSize)) * data.value.patternSize +'px',
                            'height': Math.floor(heightSize/Number(data.value.patternSize)) * data.value.patternSize +'px',
                            }}>
                            {pattern}
                        </div>
                    </div>
                )
            }
        default:
            return(
                <div className={"object unknownPreset"} ref={self.widget} style={{...style.object}} >
                    <p>알수없는 프리셋</p>
                </div>
                )
    }
}
export function data(self,style,option="broadcast") {
    return preset(self.props.data.preset,self,self.props.data,style,option)
}

