////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widgetEdit.scss"


// 컴포넌트 연결
import SentenceModule from '../editModule/SentenceModule'; // 텍스트 모듈

// 서비스 연결

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class AliasEdit extends Component {
    
    state = {
    }

    aliasChange = (name,value) =>{
        if (name=='alias'){
            this.props.callBack('alias',name,value)
        }
    }
    // valueChange = (name,value) =>{
    //     this.props.callBack('value',name,value)
    // }

    render() { 

        const Style = {
            label: {
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
            },
            textarea : {
                width: '100%',
                resize: 'none',
                boxSizing:'border-box',
            },
            mediaView : {
                width: '100%',
                MaxHeight : '500px',
            }

        }
        let legend = '연결'
        if (this.props.type){
            legend = '연결'
        }
        let tempAlias = this.props.type +'_'+ this.props.uuid.substring(0,4)
        return (
            <Fragment>
            <fieldset className="EditModuleField" style={Style.fieldset} >
                <p className="notice">
                 위젯 이름은 부드러운 이동중에<br/> 다른화면의 위젯과 연결해주는 역활을 합니다.
                </p>
                <legend style={Style.legend} > {legend} </legend>
                <SentenceModule value={this.props.alias} placeholder={tempAlias} title="위젯이름" type ="alias" change={this.aliasChange || ''}/>
            </fieldset>
            </Fragment>
        );
    }
}

////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    // screenData : state.screenData,
})

function mapDispatchToProps(dispatch){
    return {
    //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(AliasEdit);
  