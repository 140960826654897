////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { Route, Link } from 'react-router-dom';
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/frontPage.scss"

// 이미지 연결
import logo from 'assets/logo.svg';
// 컴포넌트 연결
// import Text from './template/Text'; // 메뉴 헤더
// 서비스 연결
import * as Template from 'service/event/template';
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class FrontNav extends Component {
    
    state = {
        zoom : 0,
        page : 0
    }

    constructor(props) {
        super(props);
        this.myRef = React.createRef();
      }

    componentDidMount() {

    }

    page = () =>{

    }  

    componentWillUnmount() {

    }

    render() { 
        return (
            <div id = "TemplateInfoPage" >
                <header id="FrontHeader" >
                    <div id="FrontHeaderArea" >
                        <nav>
                            <Link id="FrontHeaderLogo"  to="/">
                            <img className="mainLogo" src={logo}/> <h1>OVERLAP</h1>
                            </Link>
                            <a href="https://jungingook.notion.site/528a710df51e43bcb93c30cb51e9d7ff?pvs=25" className="link"  target="_blank" rel="noopener noreferrer">
                                <h3>공지사항</h3>
                            </a>
                            <a href="https://jungingook.notion.site/v0-5-0-a85f43983f504d1fae7b9f52801aac28" className="link"  target="_blank" rel="noopener noreferrer">
                                <h3>업데이트</h3>
                            </a>
                            {/* <Link className="link"  to="/template">
                            <h3>오버랩사용법</h3>
                            </Link> */}
                        </nav>
                        <nav>
                            {/* <Link className="singUP able btn" to="/singup">
                            회원가입                    
                            </Link> */}
                            <Link className="login able btn" to="/login">
                                오버랩 시작하기
                            </Link>
                        </nav>
                    </div>
                </header>

            </div>
        );
    }
}
////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    // passToken : state.passToken,
})

function mapDispatchToProps(dispatch){
    return {
    //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(FrontNav);
  