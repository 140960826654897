////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widgetEdit.scss"


// 컴포넌트 연결
// import Show from '../show/Show'; // 메뉴 헤더

// 서비스 연결

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class TextSizeModule extends Component {

    render() { 
        return (
            <label className="EditModule" htmlfor="fname"> 
                <p>{this.props.title?this.props.title:'글자크기'}</p>
                <div>
                {/* <button onChange={(e) => this.props.change('fontSize',20)}>▲</button>
                <button onChange={(e) => this.props.change('fontSize',10)}>▼</button> */}
                <input type="number" min="10" max="200" value={this.props.value} onChange={(e) => this.props.change(this.props.type,e.target.value)} />
                </div>
            
            </label>
        );
    }
}

////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    // test : state.test,
})

function mapDispatchToProps(dispatch){
    return {
    //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(TextSizeModule);
  