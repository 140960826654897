////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widgetEdit.scss"


// 컴포넌트 연결
import ImageButtonModule from '../editModule/ImageButtonModule'; // 색상 모듈
import RangeModule from '../editModule/RangeModule'; // 색상 모듈

// 서비스 연결
import * as Editor from 'service/edit/EditService'; 

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class BrightnessEdit extends Component {
    
    state = {
    }

    change = (name,value) =>{
        this.props.callBack('style',name,value)
    }

    render() { 

        const Style = {
            label: {
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
            },
            textarea : {
                width: '100%',
                resize: 'none',
                boxSizing:'border-box',
            },
            mediaView : {
                width: '100%',
                MaxHeight : '500px',
            }

        }
        let legend = '밝기&명도'
        if (this.props.type){
            legend = '밝기&명도'
        }


        return (
            <section className='BrightnessEdit'>
            {Editor.WidgetEditTitle(this,{title:'밝기&명도',toggle:true,icon:'contrast'})}
            <div className={this.state.editorToggle?'editContainer containerOpen':'editContainer containerClose'}>
            <article className="EditPart MediaInfoModule" > 
                    <ImageButtonModule value={this.props.data.style.brightness} type ="brightness" data={[{val:50,icon:'exposure_neg_2',text:'text',title:"밝기 50%"},{val:75,icon:'exposure_neg_1',text:'text',title:"밝기 75%"},{val:100,icon:'exposure_zero',title:"밝기 100%"},{val:125,icon:'exposure_plus_1',title:"밝기 125%"},{val:150,icon:'exposure_plus_2',title:"밝기 150%"}]} title="밝기"  change={this.change}/>
                    <ImageButtonModule value={this.props.data.style.contrast} type ="contrast" data={[{val:50,icon:'exposure_neg_2',text:'text',title:"명도 50%"},{val:75,icon:'exposure_neg_1',text:'text',title:"명도 75%"},{val:100,icon:'exposure_zero',title:"명도 100%"},{val:125,icon:'exposure_plus_1',title:"명도 125%"},{val:150,icon:'exposure_plus_2',title:"명도 150%"}]} title="명도"  change={this.change}/>
                </article>
                </div>
            </section>
        );
    }
}

////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    // screenData : state.screenData,
})

function mapDispatchToProps(dispatch){
    return {
    //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(BrightnessEdit);
  