////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widgetEdit.scss"


// 컴포넌트 연결
import TextModule from '../editModule/TextModule'; // 텍스트 모듈
import TextSizeModule from '../editModule/TextSizeModule'; // 텍스트 크기 모듈
import FontModule from '../editModule/FontModule'; // 폰트 종류 모듈
import ColorModule from '../editModule/ColorModule'; // 색상 모듈
import SwitchModule from '../editModule/SwitchModule'; // 체크박스 모듈
import ImageButtonModule from '../editModule/ImageButtonModule';
import RangeModule from '../editModule/RangeModule'; //범위 모듈 
import NumberModule from '../editModule/NumberModule'; //넘버 모듈 

// 서비스 연결
import * as Editor from 'service/edit/EditService'; 
// 이미지 연결

import leftButton from 'assets/sidebar/text/left.png';
import centerButton from 'assets/sidebar/text/center.png';
import rightButton from 'assets/sidebar/text/right.png';
import justifyButton from 'assets/sidebar/text/justify.png';

import upperButton from 'assets/sidebar/text/upper.png';
import midButton from 'assets/sidebar/text/mid.png';
import lowerButton from 'assets/sidebar/text/lower.png';

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class TextEdit extends Component {
    
    state = {
        editorToggle : true
    }

    change = (name,value) =>{
        console.log(name,value,'테스트')
        this.props.callBack('style',name,value)
    }
    valueChange = (name,value) =>{
        this.props.callBack('value',name,value)
    }
    outLineChange = (name,value) =>{
        this.props.callBack('style',"textOutline",value*100)
    }

    render() { 

        const Style = {
            label: {
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
            },
            textarea : {
                width: '100%',
                resize: 'none'
            }

        }
        console.log()
        return (
            <section className='SizeEdit'>
                {Editor.WidgetEditTitle(this,{title:'텍스트',toggle:false,icon:'title'})}
                <div className={this.state.editorToggle?'editContainer containerOpen':'editContainer containerClose'}>
                {Editor.WidgetEditSubhead({text:'텍스트 설정',icon:"edit_note"})}
                <article className="EditPart MediaSourceModule" >

                <TextModule value={this.props.data.value.text} fontFamily={this.props.data.style.fontFamily} type ="text" placeholder="여기에 텍스트를 입력해주세요"  change={this.valueChange}/>
                <ImageButtonModule value={this.props.data.style.textAlign} type ="textAlign" data={[{val:'left',image:leftButton,text:'text'},{val:'center',image:centerButton},{val:'right',image:rightButton},{val:'justify',image:justifyButton}]} title="좌우 정렬"  change={this.change}/>
                {/* <ImageButtonModule value={this.props.data.value.textAlign} type ="verticalAlign" data={[{val:'flex-start',image:upperButton},{val:'center',image:midButton},{val:'flex-end',image:lowerButton}]} type="verticalAlign" title="상하 정렬"  change={this.change}/> */}
                <ColorModule value={this.props.data.style.color} type ="color" change={this.change}/>
                <TextSizeModule value={this.props.data.style.fontSize} type ="fontSize" change={this.change}/>
                <FontModule  value={this.props.data.style.fontFamily} type ="fontFamily" change={this.change}/>
                <SwitchModule value={this.props.data.style.useFill} title="배경사용" type ="useFill" change={this.change}/>
                <ColorModule value={this.props.data.style.fill} type ="fill" title="배경색" change={this.change} />

                </article>
                {Editor.WidgetEditSubhead({text:'외곽선 설정',icon:"border_style"})}
                <article className="EditPart MediaSourceModule" >

                {/* <legend >외곽선 {this.props.data.value.textOutline}</legend> */}
                <NumberModule value={Number(this.props.data.style.textOutline/100)} min='0' max='99' step="0.5" title="선두께" type ="textOutline" change={this.outLineChange}/>
                <ColorModule value={this.props.data.style.outlineColor} type ="outlineColor" title="선색상" change={this.change}/>
                </article>
                </div>
            </section>
        );
    }
}

////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    // screenData : state.screenData,
})

function mapDispatchToProps(dispatch){
    return {
    //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(TextEdit);
  