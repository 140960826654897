////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widgetEdit.scss"
// https://static.upbit.com/logos/ETC.png

// 컴포넌트 연결
import ColorModule from '../editModule/ColorModule'; // 색상 모듈
import SwitchModule from '../editModule/SwitchModule'; // 색상 모듈
import ListModule from '../editModule/ListModule'; // 리스트 모듈
import RangeModule from '../editModule/RangeModule'; // 범위 모듈
import RadioButtonModule from '../editModule/RadioButtonModule';; // 라디오 버튼 모듈
// 서비스 연결

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class CryptoCurrencyEdit extends Component {

    static defaultProps = {
        editName: 'CryptoCurrencyEdit',
      }
      
    state = {
        coinList :this.props.data.value.coin,
        coinAdd : false,
        exchange :'upbit_KRW'
    }

    currencyChange = (newCoinList) =>{
        if(newCoinList.length== 0){
            newCoinList = null
        }
        this.props.callBack('value','coin',newCoinList)

    }

    currencyAdd = (name,value) => {
        let newCoinList =  []
        if(this.state.coinList){
            newCoinList = this.state.coinList.slice()
        }
        newCoinList.push({currency : value,
        buying : null,
        revenue : false ,
        amount : 0,
        exchange : this.state.exchange,
        option: '',
        })
        this.setState({
            coinAdd : false,
            coinList : newCoinList,
        },this.currencyChange(newCoinList))
    }
    currencyBuying = (e,index) => {
        let newCoinList = this.state.coinList.slice() 
        newCoinList[index].buying = e.target.value
        this.setState({
            coinAdd : false,
            coinList : newCoinList,
        },this.currencyChange(newCoinList))
    }
    currencyAmount = (e,index) => {
        let newCoinList = this.state.coinList.slice() 
        newCoinList[index].amount = e.target.value
        this.setState({
            coinAdd : false,
            coinList : newCoinList,
        },this.currencyChange(newCoinList))
    }
    
    currencyDelect = (index)=>{
        let newCoinList = this.state.coinList.slice() 
        newCoinList.splice(index,1)
        this.setState({
            coinList : newCoinList,
        },this.currencyChange(newCoinList))
    }

    presetChange = (name,value) =>{
        this.props.callBack('preset',name,value)
    }
    change = (name,value) =>{
        this.props.callBack('style',name,value)
    }
    valueChange = (name,value) =>{
        this.props.callBack('value',name,value)
    }
    // 코인목록에서 코인의 한글명을 가저옵니다.
    coinName = (coin) => {
        try{
            let coinData = this.coinExchange(coin.exchange)
            return coinData[coin.currency].ko
        }catch{
            return ''
        }
       
    }
    coinExchange = ()=> {
        switch (this.state.exchange) {
            case 'upbit_KRW':
                return CoinData_upbit_KRW
            case 'coinone':
                return CoinData_coinone 
            default:
                return {}
        }
    }
    
    setExchange=(name,value)=>{
        this.setState({
            exchange : value
        })
    }

    showBuying = (index,vlaue) =>{
            let newCoinList = this.state.coinList.slice() 
            newCoinList[index].revenue = vlaue
            this.setState({
                coinAdd : false,
                coinList : newCoinList,
            },this.currencyChange(newCoinList))
    }

    currencyList = () =>{
        if (this.state.coinList){
            let list = this.state.coinList.map(
                (info,index) => (
                <div className="myCryptoCurrency">
                    <div className="upper"><span> <img src ={'https://static.upbit.com/logos/'+info.currency+'.png'}/><h3>{this.coinName(info)}</h3></span><button className="currencyDelect" onClick={()=>this.currencyDelect(index)}>x</button></div>
                    <div className="lower">
                        <SwitchModule value={info.revenue} title="수익 표시하기" type ={index} change={this.showBuying}/>
                        {(info.revenue? 
                        <Fragment>
                        {/* <hr/> */}
                        <div><p>평균단가 :</p> <input type="number" value={info.buying} onChange={(e)=>{this.currencyBuying(e,index)}}/></div>
                        <div><p> 수량 :</p>  <input type="number" value={info.amount} onChange={(e)=>{this.currencyAmount(e,index)}}/></div>
                        </Fragment>
                        :''
                        )}
                    </div>
                </div> 
                ) 
            );    
            return list
        }else{
            return (
            <div className="coinAddAlert">
                <button className="newCoinAdd" onClick={()=>this.setState({coinAdd : true})} >코인추가</button>
                <p>버튼을 눌러서 가상화폐를 추가해보세요</p>
            </div>
            )
        }
    }

    coinToList=()=>{
        let listData =[]        
        let coinData = this.coinExchange()

        const coinList = Object.keys(coinData)

        for (let index = 0; index < coinList.length; index++) {
            let temp = {}
            temp.name = coinData[coinList[index]].ko;
            temp.value = coinData[coinList[index]].coin;
            temp.info = coinData[coinList[index]].info;
            temp.img = 'https://static.upbit.com/logos/'+coinData[coinList[index]].coin+'.png' ;
            listData.push(temp)
        }
        return(listData)
    }
 
    render() { 
        this.coinToList()
        const Style = {
            label: {
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
            },
            textarea : {
                width: '100%',
                resize: 'none'
            }
        }
        return (
            <Fragment>
            <fieldset className="EditModuleField" style={Style.fieldset} >
                <legend style={Style.legend} > 설정 </legend>
            <RadioButtonModule value={this.props.data.value.speed} list={[{name:'빠르게',value:2},{name:'보통',value:4},{name:'느리게',value:10}]} type ="speed" title="전환속도"  change={this.valueChange} />
            </fieldset>

            <fieldset className={"EditModuleField "+this.props.editName} style={Style.fieldset} >
                <legend style={Style.legend}> 내 가상화폐  ({this.state.coinList?this.state.coinList.length:'0'}/10)</legend>
                    <label htmlfor="fname"> 
                    <div className="editMode">
                    {(this.state.coinAdd? 
                        <Fragment>
                            <div className="coinAlert">
                            <button className="newCoinCancel" onClick={()=>this.setState({coinAdd : false}) }> 취소 </button>
                            <h3> 코인 추가하기 </h3>
                            </div>
                            {/* <RadioButtonModule value={this.state.exchange} list={[{name:'업비트',value:'upbit_KRW'},{name:'코인원',value:'coinone'}]} type ="exchange" title="거래소"  change={this.setExchange} />  */}
                        </Fragment>
                    : 
                        <Fragment>
                            <div className="coinAlert">
                            <h3> 보유중인 코인 </h3>
                            <button className="newCoinAdd" onClick={()=>this.setState({coinAdd : true}) }> 코인추가 </button>
                            </div>
                        </Fragment>
                    )}

                    </div>
                    <div className="test">

                    {(this.state.coinAdd? 
                        <Fragment>
                            <ListModule list={this.coinToList()} title="가상화폐" placeholder="여기서 코인을 검색해보세요" type ="currency" change={this.currencyAdd}/>
                        </Fragment>
                    : 
                        <Fragment>
                              <SwitchModule value={this.props.data.value.total} title="총수익 보기" type ="total" change={this.valueChange}/>
                            {this.currencyList()}
                        </Fragment>
                    )}
                
                    </div>
                    
                    </label>
            </fieldset>

            </Fragment>
        );
    }
}

////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////

const CoinData_upbit_KRW = {  
'BTC': 
    {
    coin : 'BTC',
    ko : '비트코인',
    fill : '',
    info :'비트코인은 최초로 구현된 디지털 자산입니다. P2P 네트워크 기술을 이용하여 관리합니다.',
    tag: []
    },
'ETH': 
    {
    coin : 'ETH',
    ko : '이더리움',
    fill : '',
    info :'비트코인 다음으로 독보적인 위치에 있는 코인으로 블록체인을 활용한 모든 것을 프로그래밍할 수 있는 플랫폼입니다.',
    tag: []
    }, 
'ADA': 
    {
    coin : 'ADA',
    ko : '에이다',
    fill : '',
    info :'에이다는 금융,다양한 분야에 응용 프로그램을 실행할 수 있는 3세대 블록체인 디지털 자산입니다.',
    tag: []
    },                    
'XRP': 
    {
    coin : 'XRP',
    ko : '리플',
    fill : '',
    info :' 리플 프로토콜 에서 사용되는 디지털 자산 입니다. 전 세계의 화폐를 몇 초 내로 통화 거래가 가능합니다.',
    tag: []
    },
'LTC': 
    {
    coin : 'LTC',
    ko : '라이트코인',
    fill : '',
    info :'비트코인에 기초해 만들어진 디지털 자산으로, 좀 더 나은 디지털 자산이 되는 것이 라이트 코인의 목표 입니다.',
    tag: []
    }, 
'LINK': 
    {
    coin : 'LINK',
    ko : '체인링크',
    fill : '',
    info :'체인링크는 외부 데이터를 이더리움 블록체인으로 가져온 후, 스마트 컨트랙트를 사용한 데이터 피딩 서비스 구현하였습니다',
    tag: []
    }, 
'THETA': 
    {
    coin : 'THETA',
    ko : '쎄타토큰',
    fill : '',
    info :'쎄타토큰은 블록체인 기반 비디오 전송 네트워크로 수익 창출 기회를 제공하고, 전송 비용 또한 절감할 수 있는 솔루션입니다.',
    tag: []
    },
'BCH': 
    {
    coin : 'BCH',
    ko : '비트코인캐시',
    fill : '',
    info :'비트코인캐시는 비트코인 블록체인에서 하드포크되어 나온 비트코인캐시 블록체인에서 사용되는 디지털 자산입니다',
    tag: []
    }, 
'XLM': 
    {
    coin : 'XLM',
    ko : '스텔라루멘',
    fill : '',
    info :'스텔라 루멘은 스텔라 결제 네트워크에서 사용되는 디지털 자산입니다. 은행, 결제시스템, 사람을 연결해주는 플랫폼입니다.',
    tag: []
    },                    
'DOGE': 
    {
    coin : 'DOGE',
    ko : '도지코인',
    fill : '',
    info :'시바견을 마스코트로 하는 장난처럼 시작한 가상화폐 입니다.',
    tag: []
    },
'TRX': 
    {
    coin : 'TRX',
    ko : '트론',
    fill : '',
    info :'스토리지 기술을 이용해 엔터테인먼트 생태계를 구축하기 위한 코인 입니다',
    tag: []
    }, 
'GAS': 
    {
    coin : 'GAS',
    ko : '가스',
    fill : '',
    info :'GAS는 네오를 구성하는 두 가지 네이티브 토큰 중 하나 입니다. ',
    tag: []
    },   
'GLM': 
    {
    coin : 'GLM',
    ko : '골렘',
    fill : '',
    info :'',
    tag: []
    },   
'GRS': 
    {
    coin : 'GRS',
    ko : '그리스톨코인',
    fill : '',
    info :'',
    tag: []
    },   
'NEO': 
    {
    coin : 'NEO',
    ko : '네오',
    fill : '',
    info :'',
    tag: []
    },     
'XEM': 
    {
    coin : 'XEM',
    ko : '넴',
    fill : '',
    info :'',
    tag: []
    },   
'DMT': 
    {
    coin : 'DMT',
    ko : '디마켓',
    fill : '',
    info :'',
    tag: []
    },   
'MANA': 
    {
    coin : 'MANA',
    ko : '디센트럴랜드',
    fill : '',
    info :'',
    tag: []
    },   
'DKA': 
    {
    coin : 'DKA',
    ko : '디카르고',
    fill : '',
    info :'',
    tag: []
    }, 
'LAMB': 
    {
    coin : 'LAMB',
    ko : '람다',
    fill : '',
    info :'',
    tag: []
    },   
'LOOM': 
    {
    coin : 'LOOM',
    ko : '룸네트워크',
    fill : '',
    info :'',
    tag: []
    },   
'LSK': 
    {
    coin : 'LSK',
    ko : '리스크',
    fill : '',
    info :'',
    tag: []
    },   
'RFR': 
    {
    coin : 'RFR',
    ko : '리퍼리움',
    fill : '',
    info :'',
    tag: []
    },     
'MARO': 
    {
    coin : 'MARO',
    ko : '마로',
    fill : '',
    info :'',
    tag: []
    },   
'MED': 
    {
    coin : 'MED',
    ko : '메디블록',
    fill : '',
    info :'',
    tag: []
    },   
'MFT': 
    {
    coin : 'MFT',
    ko : '메인프레임',
    fill : '',
    info :'',
    tag: []
    },   
'META': 
    {
    coin : 'META',
    ko : '메타디움',
    fill : '',
    info :'',
    tag: []
    },
'MTL': 
    {
    coin : 'MTL',
    ko : '메탈',
    fill : '',
    info :'',
    tag: []
    },   
'MOC': 
    {
    coin : 'MOC',
    ko : '모스코인',
    fill : '',
    info :'',
    tag: []
    },   
'MBL': 
    {
    coin : 'MBL',
    ko : '무비블록',
    fill : '',
    info :'',
    tag: []
    },   
'MLK': 
    {
    coin : 'MLK',
    ko : '밀크',
    fill : '',
    info :'',
    tag: []
    },     
'BAT': 
    {
    coin : 'BAT',
    ko : '베이직어텐션토큰',
    fill : '',
    info :'',
    tag: []
    },   
'BORA': 
    {
    coin : 'BORA',
    ko : '보라',
    fill : '',
    info :'',
    tag: []
    },   
'VET': 
    {
    coin : 'VET',
    ko : '비체인',
    fill : '',
    info :'',
    tag: []
    },   
'BTG': 
    {
    coin : 'BTG',
    ko : '비트코인골드',
    fill : '',
    info :'',
    tag: []
    },
'BSV': 
    {
    coin : 'BSV',
    ko : '비트코인에스브이',
    fill : '',
    info :'',
    tag: []
    },   
'BCHA': 
    {
    coin : 'BCHA',
    ko : '비트코인캐시에이비씨',
    fill : '',
    info :'',
    tag: []
    },   
'BTT': 
    {
    coin : 'BTT',
    ko : '비트토렌트',
    fill : '',
    info :'',
    tag: []
    },   
'SAND': 
    {
    coin : 'SAND',
    ko : '샌드박스',
    fill : '',
    info :'',
    tag: []
    },     
'SRM': 
    {
    coin : 'SRM',
    ko : '세럼',
    fill : '',
    info :'',
    tag: []
    },   
'UPP': 
    {
    coin : 'UPP',
    ko : '센티넬프로토콜',
    fill : '',
    info :'',
    tag: []
    },   
'SOLVE': 
    {
    coin : 'SOLVE',
    ko : '솔브케어',
    fill : '',
    info :'',
    tag: []
    },   
'SXP': 
    {
    coin : 'SXP',
    ko : '스와이프',
    fill : '',
    info :'',
    tag: []
    },
'SNT': 
    {
    coin : 'SNT',
    ko : '스테이터스네트워크토큰',
    fill : '',
    info :'',
    tag: []
    },   
'STORJ': 
    {
    coin : 'STORJ',
    ko : '스토리지',
    fill : '',
    info :'',
    tag: []
    },   
'STMX': 
    {
    coin : 'STMX',
    ko : '스톰엑스',
    fill : '',
    info :'',
    tag: []
    },   
'STRK': 
    {
    coin : 'STRK',
    ko : '스트라이크',
    fill : '',
    info :'',
    tag: []
    },     
'STRAX': 
    {
    coin : 'STRAX',
    ko : '스트라티스',
    fill : '',
    info :'',
    tag: []
    },   
'STEEM': 
    {
    coin : 'STEEM',
    ko : '스팀',
    fill : '',
    info :'',
    tag: []
    },   
'SBD': 
    {
    coin : 'SBD',
    ko : '스팀달러',
    fill : '',
    info :'',
    tag: []
    },   
'CVC': 
    {
    coin : 'CVC',
    ko : '시빅',
    fill : '',
    info :'',
    tag: []
    }, 
'SC': 
    {
    coin : 'SC',
    ko : '시아코인',
    fill : '',
    info :'',
    tag: []
    },   
'TT': 
    {
    coin : 'TT',
    ko : '썬더토큰',
    fill : '',
    info :'',
    tag: []
    },   
'SSX': 
    {
    coin : 'SSX',
    ko : '썸씽',
    fill : '',
    info :'',
    tag: []
    },   
'TFUEL': 
    {
    coin : 'TFUEL',
    ko : '쎄타퓨엘',
    fill : '',
    info :'',
    tag: []
    },     
'ARDR': 
    {
    coin : 'ARDR',
    ko : '아더',
    fill : '',
    info :'',
    tag: []
    },   
'AERGO': 
    {
    coin : 'AERGO',
    ko : '아르고',
    fill : '',
    info :'',
    tag: []
    },   
'IOST': 
    {
    coin : 'IOST',
    ko : '아이오에스티',
    fill : '',
    info :'',
    tag: []
    },   
'IOTA': 
    {
    coin : 'IOTA',
    ko : '아이오타',
    fill : '',
    info :'',
    tag: []
    }, 
'ICX': 
    {
    coin : 'ICX',
    ko : '아이콘',
    fill : '',
    info :'',
    tag: []
    },   
'EMC2': 
    {
    coin : 'EMC2',
    ko : '아인스타이늄',
    fill : '',
    info :'',
    tag: []
    },   
'ARK': 
    {
    coin : 'ARK',
    ko : '아크',
    fill : '',
    info :'',
    tag: []
    },   
'AHT': 
    {
    coin : 'AHT',
    ko : '아하토큰',
    fill : '',
    info :'',
    tag: []
    },     
'AQT': 
    {
    coin : 'AQT',
    ko : '알파쿼크',
    fill : '',
    info :'',
    tag: []
    },   
'ADX': 
    {
    coin : 'ADX',
    ko : '애드엑스',
    fill : '',
    info :'',
    tag: []
    },   
'ANKR': 
    {
    coin : 'ANKR',
    ko : '앵커',
    fill : '',
    info :'',
    tag: []
    },   
'REP': 
    {
    coin : 'REP',
    ko : '어거',
    fill : '',
    info :'',
    tag: []
    },
'IQ': 
    {
    coin : 'IQ',
    ko : '에브리피디아',
    fill : '',
    info :'',
    tag: []
    },   
'STPT': 
    {
    coin : 'STPT',
    ko : '에스티피',
    fill : '',
    info :'',
    tag: []
    },   
'EDR': 
    {
    coin : 'EDR',
    ko : '엔도르',
    fill : '',
    info :'',
    tag: []
    },   
'ENJ': 
    {
    coin : 'ENJ',
    ko : '엔진코인',
    fill : '',
    info :'',
    tag: []
    },     
'LBC': 
    {
    coin : 'LBC',
    ko : '엘비알와이크레딧',
    fill : '',
    info :'',
    tag: []
    },   
'ELF': 
    {
    coin : 'ELF',
    ko : '엘프',
    fill : '',
    info :'',
    tag: []
    },   
'MVL': 
    {
    coin : 'MVL',
    ko : '엠블',
    fill : '',
    info :'',
    tag: []
    },   
'OMG': 
    {
    coin : 'OMG',
    ko : '오미세고',
    fill : '',
    info :'',
    tag: []
    },
'ORBS': 
    {
    coin : 'ORBS',
    ko : '오브스',
    fill : '',
    info :'',
    tag: []
    },   
'ONT': 
    {
    coin : 'ONT',
    ko : '온톨로지',
    fill : '',
    info :'',
    tag: []
    },   
'ONG': 
    {
    coin : 'ONG',
    ko : '온톨로지가스',
    fill : '',
    info :'',
    tag: []
    },   
'OBSR': 
    {
    coin : 'OBSR',
    ko : '옵저버',
    fill : '',
    info :'',
    tag: []
    },     
'WAXP': 
    {
    coin : 'WAXP',
    ko : '왁스',
    fill : '',
    info :'',
    tag: []
    },   
'WAVES': 
    {
    coin : 'WAVES',
    ko : '웨이브',
    fill : '',
    info :'',
    tag: []
    },   
'IGNIS': 
    {
    coin : 'IGNIS',
    ko : '이그니스',
    fill : '',
    info :'',
    tag: []
    },   
'ETC': 
    {
    coin : 'ETC',
    ko : '이더리움클래식',
    fill : '',
    info :'',
    tag: []
    },
'EOS': 
    {
    coin : 'EOS',
    ko : '이오스',
    fill : '',
    info :'',
    tag: []
    },   
'JST': 
    {
    coin : 'JST',
    ko : '저스트',
    fill : '',
    info :'',
    tag: []
    },   
'ZRX': 
    {
    coin : 'ZRX',
    ko : '제로엑스',
    fill : '',
    info :'',
    tag: []
    },   
'ZIL': 
    {
    coin : 'ZIL',
    ko : '질리카',
    fill : '',
    info :'',
    tag: []
    },     
'CHZ': 
    {
    coin : 'CHZ',
    ko : '칠리즈',
    fill : '',
    info :'',
    tag: []
    },   
'KAVA': 
    {
    coin : 'KAVA',
    ko : '카바',
    fill : '',
    info :'',
    tag: []
    },   
'KNC': 
    {
    coin : 'KNC',
    ko : '카이버네트워크',
    fill : '',
    info :'',
    tag: []
    },   
'CRE': 
    {
    coin : 'CRE',
    ko : '캐리프로토콜',
    fill : '',
    info :'',
    tag: []
    },
    'KMD': 
    {
    coin : 'KMD',
    ko : '코모도',
    fill : '',
    info :'',
    tag: []
    },   
'CBK': 
    {
    coin : 'CBK',
    ko : '코박토큰',
    fill : '',
    info :'',
    tag: []
    },   
'ATOM': 
    {
    coin : 'ATOM',
    ko : '코스모스',
    fill : '',
    info :'',
    tag: []
    },   
'QKC': 
    {
    coin : 'QKC',
    ko : '쿼크체인',
    fill : '',
    info :'',
    tag: []
    },     
'QTCON': 
    {
    coin : 'QTCON',
    ko : '퀴즈톡',
    fill : '',
    info :'',
    tag: []
    },   
'QTUM': 
    {
    coin : 'QTUM',
    ko : '퀸텀',
    fill : '',
    info :'',
    tag: []
    },   
'CRO': 
    {
    coin : 'CRO',
    ko : '크립토닷컴체인',
    fill : '',
    info :'',
    tag: []
    },   
'XTZ': 
    {
    coin : 'XTZ',
    ko : '테조스',
    fill : '',
    info :'',
    tag: []
    }, 
'TON': 
    {
    coin : 'TON',
    ko : '톤',
    fill : '',
    info :'',
    tag: []
    },   
'TSHP': 
    {
    coin : 'TSHP',
    ko : '트웰브쉽스',
    fill : '',
    info :'',
    tag: []
    },   
'POWR': 
    {
    coin : 'POWR',
    ko : '파워렛저',
    fill : '',
    info :'',
    tag: []
    },   
'PUNDIX': 
    {
    coin : 'PUNDIX',
    ko : '펀디엑스',
    fill : '',
    info :'',
    tag: []
    },     
'PCI': 
    {
    coin : 'PCI',
    ko : '페이코인',
    fill : '',
    info :'',
    tag: []
    },   
'POLY': 
    {
    coin : 'POLY',
    ko : '폴리매쓰',
    fill : '',
    info :'',
    tag: []
    },   
'DOT': 
    {
    coin : 'DOT',
    ko : '폴카닷',
    fill : '',
    info :'',
    tag: []
    },   
'PLA': 
    {
    coin : 'PLA',
    ko : '플레이댑',
    fill : '',
    info :'',
    tag: []
    },
'FCT2': 
    {
    coin : 'FCT2',
    ko : '피르마체인',
    fill : '',
    info :'',
    tag: []
    },   
'PXL': 
    {
    coin : 'PXL',
    ko : '픽셀',
    fill : '',
    info :'',
    tag: []
    },   
'HIVE': 
    {
    coin : 'HIVE',
    ko : '하이브',
    fill : '',
    info :'',
    tag: []
    },   
'HUNT': 
    {
    coin : 'HUNT',
    ko : '헌트',
    fill : '',
    info :'',
    tag: []
    },     
'HBAR': 
    {
    coin : 'HBAR',
    ko : '헤데라해시그래프',
    fill : '',
    info :'',
    tag: []
    },   
'HUM': 
    {
    coin : 'HUM',
    ko : '휴먼스케이프',
    fill : '',
    info :'',
    tag: []
    },
}


const CoinData_coinone = {
'HUM': 
    {
    coin : 'HUM',
    ko : '휴먼스케이프',
    fill : '',
    info :'',
    tag: []
    },
}
////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////

const mapStateToProps = (state) => ({
    // exemple : state.exemple,
})

function mapDispatchToProps(dispatch){
    return {
    //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(CryptoCurrencyEdit);
  