////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widgetEdit.scss"

//
import exampleFilter from 'assets/exampleFilter.jpg';
// 컴포넌트 연결
import InfoButtonModule from '../editModule/InfoButtonModule';//위치 모듈
import ImageButtonModule from '../editModule/ImageButtonModule';
import RangeModule from '../editModule/RangeModule';
// 서비스 연결
import * as Editor from 'service/edit/EditService'; 

// 이미지
import blur_0 from 'assets/sidebar/other/blur_0.png';
import blur_1 from 'assets/sidebar/other/blur_1.png';
import blur_2 from 'assets/sidebar/other/blur_2.png';
import blur_3 from 'assets/sidebar/other/blur_3.png';

import grayscale_0 from 'assets/sidebar/other/grayscale_0.png';
import grayscale_1 from 'assets/sidebar/other/grayscale_1.png';
import grayscale_2 from 'assets/sidebar/other/grayscale_2.png';
import grayscale_3 from 'assets/sidebar/other/grayscale_3.png';

import sepia_0 from 'assets/sidebar/other/sepia_0.png';
import sepia_1 from 'assets/sidebar/other/sepia_1.png';
import sepia_2 from 'assets/sidebar/other/sepia_2.png';
import sepia_3 from 'assets/sidebar/other/sepia_3.png';

import invert_0 from 'assets/sidebar/other/invert_0.png';
import invert_1 from 'assets/sidebar/other/invert_1.png';
import invert_2 from 'assets/sidebar/other/invert_2.png';
import invert_3 from 'assets/sidebar/other/invert_3.png';

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class FilterEdit extends Component {
    
    state = {
        editorToggle : false,
    }

    change = (name,value) =>{
        this.props.callBack('style',name,value)
    }

    render() { 
        const Style = {
            label: {
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
            },
            textarea : {
                width: '100%',
                resize: 'none'
            }
        }

        return (
            <section className='FilterEdit'>
                {Editor.WidgetEditTitle(this,{title:'필터',toggle:true,icon:'camera'})}
                <div className={this.state.editorToggle?'editContainer containerOpen':'editContainer containerClose'}>
                    <article className="EditPart MediaInfoModule" > 
                    <ImageButtonModule value={this.props.data.style.blur} type ="blur" data={[{val:20,image:blur_3,text:'text',title:"매우 흐리게"},{val:10,image:blur_2,title:"흐리게"},{val:5,image:blur_1,title:"약간 흐리게"},{val:0,image:blur_0,title:"흐리지 않게"}]} title="흐리게"  change={this.change}/>
                    <ImageButtonModule value={this.props.data.style.grayscale} type ="grayscale" data={[{val:100,image:grayscale_3,text:'text',title:"완전한 회색톤"},{val:66,image:grayscale_2,title:"진한 회색톤"},{val:33,image:grayscale_1,title:"약한 회색톤"},{val:0,image:grayscale_0,title:"회색톤 없음"}]} title="회색조"  change={this.change}/>
                    <ImageButtonModule value={this.props.data.style.sepia} type ="sepia" data={[{val:100,image:sepia_3,text:'text',title:"완전한 세피아"},{val:66,image:sepia_2,title:"진한 세피아"},{val:33,image:sepia_1,title:"약한 세피아"},{val:0,image:sepia_0,title:"세피아 없음"}]} title="세피아"  change={this.change}/>
                    <ImageButtonModule value={this.props.data.style.invert} type ="invert" data={[{val:100,image:invert_3,text:'text',title:"100% 색반전"},{val:66,image:invert_2,title:"66% 색반전"},{val:33,image:invert_1,title:"33% 색반전"},{val:0,image:invert_0,title:"색반전 없음"}]} title="색반전"  change={this.change}/>

                    {/* <RangeModule value={this.props.data.style.brightness} type ="brightness" valueView="%" min="0" max="200" title="밝기" change={this.change} />
                    <RangeModule value={this.props.data.style.contrast} type ="contrast"valueView="%" min="0" max="200" title="명도" change={this.change} /> */}
                    </article>
                </div>
            </section>

        );
    }
}

////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////

const mapStateToProps = (state) => ({
    // exemple : state.exemple,
})

function mapDispatchToProps(dispatch){
    return {
    //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(FilterEdit);
  