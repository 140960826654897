////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { useState, useEffect, useContext, useRef, Fragment } from 'react';
import YouTube from 'react-youtube';

// [프로바이더]
// import EditContext from 'service/context/EditContext' ; 

// 서비스 연결
import * as Widget from 'service/edit/WidgetService' ; 
import * as fileAPI from 'service/api/file' ; 
import * as Utility from 'service/other/Utility'; 
import * as Editor from 'service/edit/EditService' ; 
import { widget } from 'service/value/Model';
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

function ImageV2({keyValue,pageKey,alias,type,key,id,uuid,getStyle,getAnimation,animation,parents,data}){

    ////////////////////////////////////////////////////////
    // Const
    ////////////////////////////////////////////////////////

    // set
    const WidgetRef = useRef(null);
    // const context = useContext(EditContext);

    /* 모든 위젯에서만 사용되는 고유 상수 */
    const Style = getStyle(data) // Style | 스타일을 가지고 옵니다.
    const Animation = getAnimation(animation,data) // Animation | 애니메이션을 가지고 옵니다.
    const MergeStyle = Widget.styleMerge(Style,Animation); // MergeStyle | 스타일과 애니메이션을 합칩니다.


    const WidgetActivate  = Widget.activate(data); // WidgetActivate | 위젯이 숨김상태인지 아닌지 판단합니다.
    const Width = data?.size?.width // Width | 위젯의 가로 사이즈
    const Height =data?.size?.height // Height | 위젯의 세로 사이즈
    const MinSize = Width<Height?Width:Height;
    /* 해당 위젯에서만 사용되는 고유 상수 */
    const MediaSource = data.value.mediaSource
    const MediaType = data.value.mediaType

    const [Progress, setProgress] = useState('load');
    const [ImageSource, setImageSource] = useState(null);
    const [PreviewSource, setPreviewSource] = useState(null);


    useEffect(() => {
        getImageSource(MediaType,MediaSource)
        return () => {
        };
      }, [MediaSource,MediaType]);

    const getImageSource = async (type,source) => {
        switch (type) {
            case 'link':
                setImageSource(source)
                setPreviewSource(source)
                break;
            case 'library':
                if(source){
                    const Image = await fileAPI.image(source)
                    setImageSource(Image?.file_path)
                    setPreviewSource(Image?.file_preview??Image?.file_preview)
                }
                break;        
            default:
                break;
        }
        setProgress('done');
    }


         
    

    ////////////////////////////////////////////////////////
    // Widget lander
    ////////////////////////////////////////////////////////
    switch (parents) {
        /* 편집화면에서 보이는 위젯의 모습 */
        case 'edit':
            // 편집화면에서는 애니메이션 효과를 비활성화 합니다.
            MergeStyle.layout.transition = false
            MergeStyle.object.transition = false

            if(MediaSource){
                return (
                    <Fragment>
                    <style jsx>{`${WidgetStyle}`}</style>
                    <div style={{...MergeStyle.layout}} className = {parents + " widgetObject "+type+WidgetActivate}>
                        <img className={'imageWrap imageProgress'+Progress}  src={ImageSource} style={{...MergeStyle.object}}/>
                    </div>
                    </Fragment>
            
                );
            }else{
                return (  
                    <Fragment>
                    <style jsx>{`${WidgetStyle}`}</style>
           
                    <div style={{...MergeStyle.layout}} className = {parents + " widgetObject "+type+WidgetActivate}>
                    <div className={"object unknown"} style={{...MergeStyle.object,'backgroundColor':'#444444','borderRadius':'10px','display':'flex','justifyContent':'center','align-items': 'center','flexDirection': 'column'}}>
                        {MinSize < 200 ?
                            <Fragment>
                            {Editor.icon({icon:'hide_image',size:MinSize/2,lineHeight:MinSize/2})}
                            </Fragment>
                            :
                            <Fragment>
                            {Editor.icon({icon:'hide_image',size:MinSize/5,lineHeight:MinSize/5})}
                            <p style={{'fontSize': MinSize/20,'margin': MinSize/30,}}>
                                선택된 이미지가 없습니다.
                            </p>
                            </Fragment>
                        }
                    </div>
                     
                    </div>
                    </Fragment>
                    );
            }
 
        /* 방송화면에서 보이는 위젯의 모습 */
        case 'broadcast':
            if(MediaSource){
                return (
                    <Fragment>
                    <style jsx>{`${WidgetStyle}`}</style>
                    <div style={MergeStyle.layout} className = {parents + " widgetObject "+type+WidgetActivate} >
                        <img className={'imageWrap imageProgress'+Progress}  src={ImageSource} style={{...MergeStyle.object}}/>
                    </div>
                    </Fragment>
                )
            }else{
                return null;
            };
        /* 미리보기 화면에서 보이는 위젯의 모습 */
        case 'view':
            return (
                <Fragment>
                <style jsx>{`${WidgetStyle}`}</style>
                <div style={{...MergeStyle.layout}} className = {parents + " widgetObject "+type+WidgetActivate} >
                    <img className={'imageWrap imageProgress'+Progress}  src={PreviewSource} style={{...MergeStyle.object}}/>
                </div>
                </Fragment>
        
            );
        default:
            return (
                <Fragment>
                <style jsx>{`${WidgetStyle}`}</style>
                <div style={Style.layout} className = {parents + " widgetObject "+type+WidgetActivate} >
                    {/* {getYoutube()} */}
                </div>
                </Fragment>
            );

    }

}

// // 커스텀 비교 함수 정의
// const areEqual = (prevProps, nextProps) => {
//     console.log('prevProps',prevProps,'nextProps',nextProps);

//     console.log('Equal : alias ',prevProps.alias === nextProps.alias,'prevProps : alias ',prevProps.alias,'nextProps : alias ',nextProps.alias);
//     console.log('Equal : animation ',prevProps.animation === nextProps.animation,'prevProps : animation ',prevProps.animation,'nextProps : animation ',nextProps.animation);
//     console.log('Equal : control ',prevProps.control === nextProps.control,'prevProps : control ',prevProps.control,'nextProps : control ',nextProps.control);
//     console.log('Equal : data ',prevProps.data === nextProps.data,'prevProps : data ',prevProps.data,'nextProps : data ',nextProps.data);
//     console.log('Equal : getAnimation ',prevProps.getAnimation === nextProps.getAnimation,'prevProps : getAnimation ',prevProps.getAnimation,'nextProps : getAnimation ',nextProps.getAnimation);
//     console.log('Equal : getStyle ',prevProps.getStyle === nextProps.getStyle,'prevProps : getStyle ',prevProps.getStyle,'nextProps : getStyle ',nextProps.getStyle);
//     console.log('Equal : id ',prevProps.id === nextProps.id,'prevProps : id ',prevProps.id,'nextProps : id ',nextProps.id);
//     console.log('Equal : keyValue ',prevProps.keyValue === nextProps.keyValue,'prevProps : keyValue ',prevProps.keyValue,'nextProps : keyValue ',nextProps.keyValue);
//     console.log('Equal : pageKey ',prevProps.pageKey === nextProps.pageKey,'prevProps : pageKey ',prevProps.pageKey,'nextProps : pageKey ',nextProps.pageKey);
//     console.log('Equal : parents ',prevProps.parents === nextProps.parents,'prevProps : parents ',prevProps.parents,'nextProps : parents ',nextProps.parents);
//     console.log('Equal : type ',prevProps.type === nextProps.type,'prevProps : type ',prevProps.type,'nextProps : type ',nextProps.type);
//     console.log('Equal : update ',prevProps.update === nextProps.update,'prevProps : update ',prevProps.update,'nextProps : update ',nextProps.update);
//     console.log('Equal : uuid ',prevProps.uuid === nextProps.uuid,'prevProps : uuid ',prevProps.uuid,'nextProps : uuid ',nextProps.uuid);
//     console.log('Equal : key ',prevProps.key === nextProps.key,'prevProps : key ',prevProps.key,'nextProps : key ',nextProps.key);



//     return true
//   };

// export default React.memo(ImageV2,areEqual);

export default ImageV2;


const WidgetStyle = ``;


// ////////////////////////////////////////////////////////
// // import 부분
// ////////////////////////////////////////////////////////
// // 모듈 연결
// import React, { Component , Fragment } from 'react';
// import { connect } from "react-redux"; // 리덕스 연결

// // [리덕스]스토어 연결
// import store from "store";

// // SASS&CSS 연결
// import "sass/widget.scss"

// // [프로바이더] 연결
// // import EditContext from 'service/context/EditContext' ; 

// // 컴포넌트 연결
// import WidgetOverRay from '../edit/editController/WidgetOverRay'; // 메뉴 헤더

// // 이미지
// import none from 'assets/none.png';

// // 서비스 연결
// import * as Event from '../../service/event/Actions' ; 
// import * as Widget from 'service/edit/WidgetService' ; 
// import * as Model from '../../service/value/Model' ; 
// import * as Editor from 'service/edit/EditService' ; 
// import * as EnCoder from '../../service/engine/EnCoder' ; 
// class ImageV2 extends Component {
    
//     static defaultProps = {
//         widgetName: 'Image',
//       }

//     //[임시] 컨텍스트 연결
//     // static contextType = EditContext;
    
//     state = {
//     }

//     constructor(props) {
//         super(props);
//         this.widget = React.createRef();
//     }

//     render() {


//         const Style = this.props.getStyle(this.props.data)
//         const Animation = this.props.getAnimation(this.props.animation,this.props.data)
//         const MergeStyle = Widget.styleMerge(Style,Animation);

//         const Height =this.props.data?.size?.height;
//         const Width = this.props.data?.size?.width;
//         const MinSize = Width<Height?Width:Height;


//         let mediaSource= null
//         if (this.props.data.value.mediaSource) {
//             mediaSource = this.props.data.value.mediaSource
//         }

//         // 드레그를 통한 위젯 이동

//         switch (this.props.parents) {

//             case 'edit':
//                 // 편집시 화면이동 트렌스레이션 효과 방지
//                 MergeStyle.layout.transition = false
//                 MergeStyle.object.transition = false
//                 if (!this.props.data.value.mediaSource) {
//                     mediaSource = 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/Example/image.png'
//                     return (         
//                         <div className = {this.props.parents + " widgetObject "+this.props.widgetName+Widget.activate(this.props.data)} style={MergeStyle.layout} >
//                         <div className={"object unknown"} style={{...MergeStyle.object,'backgroundColor':'#444444','borderRadius':'10px','display':'flex','justifyContent':'center','align-items': 'center','flexDirection': 'column'}}>
//                             {MinSize < 200 ?
//                             <Fragment>
//                             {Editor.icon({icon:'hide_image',size:MinSize/2,lineHeight:MinSize/2})}
//                             </Fragment>
//                             :
//                             <Fragment>
//                             {Editor.icon({icon:'hide_image',size:MinSize/5,lineHeight:MinSize/5})}
//                             <p style={{'fontSize': MinSize/20,'margin': MinSize/30,}}>
//                                 선택된 이미지가 없습니다.
//                             </p>
//                             </Fragment>
//                             }
//                             </div>
//                     </div>
//                     );
//                 }

                
//                 return (
//                     <div object={this.object} className = {this.props.parents + " widgetObject "+this.props.widgetName+Widget.activate(this.props.data)} style={MergeStyle.layout} >
//                         <img style={MergeStyle.object} src={mediaSource}></img>    
//                     </div>
//                 );

//             case 'broadcast':
//                 if (!this.props.data.value.mediaSource) {
//                     mediaSource = none
//                 }
//                 return (
//                 <div object={this.object} className = {this.props.parents + " widgetObject "+this.props.widgetName+Widget.activate(this.props.data)} style={MergeStyle.layout} >
//                     <img style={MergeStyle.object} src={mediaSource}></img>    
//                 </div>
//                 );
          
//             case 'view':
//                 if (!this.props.data.value.mediaSource) {
//                     mediaSource = none
//                 }
//                 return (
//                     <div object={this.object} className = {this.props.parents + " widgetObject "+this.props.widgetName+Widget.activate(this.props.data)} style={MergeStyle.layout} >
//                         <img style={MergeStyle.object} src={mediaSource}></img>    
//                     </div>
//                 );
//         }

//     }
// }
////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
// const mapStateToProps = (state) => ({
//     userState : state.userState,
// })

// function mapDispatchToProps(dispatch){
//     return {
//         //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
//     }
// }


//   // export default Login;
//   export default connect(mapStateToProps,mapDispatchToProps)(ImageV2);
  