function preset(preset,self,data,style){
    let fullsize = data.size.height
    let width = data.size.width
    let profile = self.state.profile
    let dataList = []
    let dataField =''
    switch (preset) {
        case 'normal_Horizontal':
            return(
                <div className={"object "+self.props.widgetName+"_preset_"+preset} ref={self.widget} style={{...style.object}} >
                    <div className="">
                        다음일정
                    </div>
                    <div>
                        일정없음
                    </div>
                </div>
            )
        case 'normal_Vertical':
            return(
                <div className={"object "+self.props.widgetName+"_preset_"+preset} ref={self.widget} style={{...style.object}} >
                    <div>
                        다음일정
                    </div>
                    <div>
                        일정없음
                    </div>
                </div>
            )    
        case 'pinkPostit_Horizontal':
            return(
                <div className={"object "+self.props.widgetName+"_preset_"+preset} ref={self.widget} style={{...style.object}} >
 
                </div>
            )    
        case 'pinkPostit_vertical':
            return(
                <div className={"object "+self.props.widgetName+"_preset_"+preset} ref={self.widget} style={{...style.object}} >
   
                </div>
            )   
        default:
            return(
                <div className={"object unknownPreset"} ref={self.widget} style={{...style.object}} >
                    <p>알수없는 프리셋</p>
                </div>
                )
    }
}
export function data(self,style) {
    return preset(self.props.data.preset,self,self.props.data,style)
}

