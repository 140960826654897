////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { useState, useEffect, useRef } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결

// 위젯 
import SpaceModuleViewGuideList from 'pages/lobby/space/sm_view/SpaceModuleViewGuideList';

// 이미지 연결
import logoW from 'assets/logoW.svg';
import logo from 'assets/logo.svg';
import twitchLogo from 'assets/main/twitchlogo.png';
import edit from 'assets/main/edit.png';
import bot from 'assets/main/bot.png';
import control from 'assets/main/control.png';
import banner1 from 'assets/main/banner1.png';

// 컴포넌트 연결

import SpaceModuleFileList from './sm_library/SpaceModuleFileList';

// 서비스 연결
import * as fileAPI from 'service/api/file' ; 
import * as Space from 'service/api/space' ; 
import * as guide from 'service/event/guide';
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

const SpaceLibrary = ({space}) => {

    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////

    const [mode, setMode] = useState(null);
    const [select, setSelect] = useState(null);
    const [view, setView] = useState(null);
    const [FileList, setFileList] = useState(false);
    const [Loading, setLoading] = useState(false);
    const [deletePreview, setDeletePreview] = useState(null);
    const spaceRef = useRef();

    useEffect(() => {
        getFileList() 
      },[]);

    const getFileList = async () => {

        console.log('space',space)
        const LIST  = await fileAPI.getList(space?.id);
        setFileList(LIST)

        setLoading(true);
    }


    switch (mode) {
        case 'append':
            return (
                <div className="Lobby_innerPage">
                    <div className="Lobby_common Lobby_SpaceOverlap" ref={spaceRef}>
                        {/* <ChannelCreate spaceId={props.space?.id} setMode={handleSetMode} /> */}
                    </div>
                </div>
            );
        default:
            return (
                <div className="Lobby_innerPage">
                    <SpaceModuleFileList space={space} fileList={FileList} getFileList={getFileList} loading={Loading} />
                </div>
            );
    }
};

////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////


  // export default Login;
  export default SpaceLibrary;
  