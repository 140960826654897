////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component, Fragment , useState, useEffect, useRef} from 'react';

import { Route, Link } from 'react-router-dom';
// [리덕스]스토어 연결

// 이미지 연결
import logoW from 'assets/logoW.svg';
import PromotionVideo from 'assets/Promotion/overlapPromotion1.mp4';

// 컴포넌트 연결
import SharePanel from 'components/share/SharePanel'; // 오버랩 인트로 색션
import OverviewSection from 'components/Promotion/OverviewSection'; // 중요한 내용 색션
import BottomSection from 'components/Promotion/BottomSection'; // 바텀 부분 색션
import FeatureSection from 'components/Promotion/FeatureSection'; // 오버랩의 특징 설명 색션

import AccountBanner from 'components/Promotion/AccountBanner'; // 어카운트 관련 배너
// SASS&CSS 연결
import "sass/frontPage.scss"
// 서비스 연결
import * as Editor from 'service/edit/EditService' ; 
import { forEach } from 'lodash';
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////


function RTCSharePage () {

    const [devicesList, setDevices] = useState([]);
    const [screenList, setScreen] = useState([]);

    const [StreamList, setStreamList] = useState({});
    const [selectedDeviceId, setSelectedDeviceId] = useState('');
    const videoRef = useRef(null);

    useEffect(() => {
        
        //init
        initPage();
    
      }, []);

      useEffect(() => {
        devicesList.forEach(element => {

          console.log('element',element.deviceId)

          apeendStream(element.deviceId)
          
        });
      }, [devicesList]);



    const initPage = async () => {
        console.log('초기화')
        await getDeviceState()
    }

    const getDeviceState = async () => {
      try {
            // MediaDevices API를 사용하여 연결된 모든 미디어 장치 정보를 가져옴
            const allDevices = await navigator.mediaDevices.enumerateDevices();
            console.log('allDevices',allDevices);
            // 비디오 입력 장치만 필터링
            const videoDevices = allDevices.filter(device => device.kind === 'videoinput');
            setDevices(videoDevices);
          } catch (error) {
            console.error('Error accessing media devices: ', error);
          }
    }

    const apeendStream = async (deviceId) => {

      console.log('deviceId')
      try {
        const Stream = await navigator.mediaDevices.getUserMedia({
          video: { 
            deviceId: { exact: deviceId },
            width: { exact: 640 },
            height: { exact: 480 },
         },

        });
        setStreamList((prevState) => ({ ...prevState, [deviceId]: Stream }));
      } catch (error) {
        console.error('Error accessing the selected camera: ', error);
      }
    }

    const removeStream = (shareId) => {
      if (StreamList[shareId]) {
        StreamList[shareId].getTracks().forEach((track) => {
          track.stop(); // 모든 트랙 중지
        });
        setStreamList((prevStreamList) => {
          const { [shareId]: _, ...newStreamList } = prevStreamList; // deviceId 키 삭제 후 나머지 복사
          return newStreamList;
        });

        const filteredScreen = screenList.filter(item => item.screenid !== shareId);
        setScreen(filteredScreen)

        const filteredDevice = devicesList.filter(item => item.deviceId !== shareId);
        setDevices(filteredDevice)
      }
    }

    const screenShare =  async (screenid) => {
      try {
        const Stream = await navigator.mediaDevices.getDisplayMedia({
          video: { 
            cursor: 'never',
            width: { ideal: 1920 }, // 원하는 해상도 너비
            height: { ideal: 1080 }, // 원하는 해상도 높이
            frameRate: { ideal: 30 } // 선택 사항: 프레임 레이트 설정
          },
          audio: true // 선택 사항
        });
          // 스트림이 중지되었을 때 감지하는 이벤트 핸들러
          Stream.getVideoTracks()[0].onended = () => {
            console.log('사용자가 화면 공유를 중지했습니다.');
            alert('화면 공유가 중지되었습니다.');
        };

        // 또는 스트림 전체가 비활성화되었을 때 감지
        Stream.oninactive = () => {
            console.log('화면 공유 스트림이 중지되었습니다.');
            alert('화면 공유가 중지되었습니다.');
        };
        setStreamList((prevState) => ({ ...prevState, [screenid]: Stream }));
        setScreen((prevState) => ([...prevState,{screenid : screenid}]));
      } catch (error) {
        console.error('Error accessing the selected camera: ', error);
      }
    }

      
    console.log('devices',devicesList);

    return  (
    <Fragment>
    <style jsx>{`${PageStyle}`}</style>
        <div id = "RTCSharePage">
            <SharePanel devicesList={devicesList}  screenList={screenList} StreamList={StreamList} screenShare={screenShare} stopShare={removeStream}/>

            {/* <div id = "SharePanel">
                <div id = "ShareHeader">
                    <div>1</div>
                    <div>2</div>
                </div>
            <h2>Select Camera:</h2>

            {devices.map((device, index) => (
                <button className='shareOption' value={device.deviceId} onClick={(e)=>setSelectedDeviceId(e.target.value)}> 
                <div>
                {Editor.icon({icon:'videocam',size:80,lineHeight:80})}
                </div>
                <div>
                {device.label}
                </div>
                
                 </button>
            ))}
        
            <button onClick={()=>{getMieda()}}> 
                링크
            </button>


            <select
                onChange={(e) => {console.log('setSelectedDeviceId : ',e.target.value);setSelectedDeviceId(e.target.value)}}
                value={selectedDeviceId}
            >
                {devices.map((device, index) => (
                <option key={device.deviceId} value={device.deviceId}>
                    Camera {index + 1}: {device.label || `Camera ${index + 1}`}
                </option>
                ))}
            </select> 
            <div>
                <video ref={videoRef} autoPlay width="200" />
            </div>

            </div> */}
      

        </div>
    </Fragment>
    );

}

export default RTCSharePage;



const PageStyle = `

#RTCSharePage{
    display :flex;
    flex-direction: column;
    width : 100vw;
    height : 100vh;
    background-color: #737373;
    font-family: '프리텐다드ExtraBold';
    justify-content : center;
    align-items : center;

}

`;