import store from "store";
import * as Screen from '../api/screen' ; 
import * as Widget from './widget' ; 
////////////////////////////////////////////////////////
// inputChange (해당객체,이밴트)
// 해당 객체의 State 에 값을 변경해줍니다. 
// 이는 state 안에 value값을 보관하고 있는경우 value 값을 갱신해줍니다.
////////////////////////////////////////////////////////

export function inputChange(thisObj,e) {
    // console.log(e.target.name,"의 값을 : ",e.target.value)
    thisObj.setState({
        [e.target.name]: e.target.value
      },);
  }

////////////////////////////////////////////////////////
// 클립보드 관련
////////////////////////////////////////////////////////
export async function copy (value) {
    if (navigator.clipboard) {
       await navigator.clipboard.writeText(value).then(() => {
        return true
        });
      } else {
        return false
      }
}

////////////////////////////////////////////////////////
// ContextMenu 관련
////////////////////////////////////////////////////////
  const screenContextDispatch = (display,traget,no,id,posX,posY,self) => ({type:'screenContext',display : display,posX,posY,traget,id,no,self})
  const objectContextDispatch = (display,traget,id,posX,posY,self) => ({type:'screenContext',display : display,posX,posY,traget,id,no:null,self})
////////////////////////////////////////////////////////
// screenContextMenu (스크린의 인덱스,마우스의 X좌표, 마우스의 Y좌표) 
// 스크린 왼쪽 클릭시 메뉴 등장
////////////////////////////////////////////////////////
  export function screenContextMenu(screenNO,screenID,posX,posY,self) {
    store.dispatch(screenContextDispatch(true,'screen',screenNO,screenID,posX,posY,self)); 
  }
////////////////////////////////////////////////////////
// objectContextMenu (위젯의 인덱스,마우스의 X좌표, 마우스의 Y좌표) 
// 위젯 왼쪽 클릭시 메뉴 등장
////////////////////////////////////////////////////////
  export function objectContextMenu(objectID,posX,posY,self=undefined) {
    store.dispatch(objectContextDispatch(true,'object',objectID,posX,posY,self)); 
  }
////////////////////////////////////////////////////////
// controlContextMenu (위젯의 인덱스,마우스의 X좌표, 마우스의 Y좌표) 
// 위젯 포지션에서 왼쪽 클릭시 메뉴 등장
////////////////////////////////////////////////////////
export function controlContextMenu(objectID,posX,posY,self=undefined) {
  store.dispatch(objectContextDispatch(true,'control',objectID,posX,posY,self)); 
}
////////////////////////////////////////////////////////
// contextMenuHide () 
// 컨텍스트 메뉴(왼쪽클릭 메뉴)삭제
////////////////////////////////////////////////////////
  export function contextMenuHide() {
    const state = store.getState(); // 현재 상태를 가져옵니다.
    const { contextMenu } = state; // 편의상 비구조화 할당
    if(contextMenu.display == true){
      store.dispatch(screenContextDispatch(false,null,null,null,0,0)); 
    }

  }


////////////////////////////////////////////////////////
// setDragImage () 
// 드레그를 이미지를 컨트롤 합니다.
////////////////////////////////////////////////////////
export function setDragImage(self,e) {
  const state = store.getState(); // 현재 상태를 가져옵니다.
  const { editScreen } = state; // 편의상 비구조화 할당
  var outline = document.createElement("div");
  var obj = document.createElement("div");
  // let crt = e.target.cloneNode(true);
  obj.style.position = "absolute";
  obj.style.width = e.target.clientWidth*editScreen.zoom +'px'
  obj.style.height = e.target.clientHeight*editScreen.zoom +'px'
  obj.style.border = "3px solid #222"
  obj.style.zIndex = "-200"
  obj.style.transform = 'rotate('+ self.props.rotatez+ 'deg)'
  document.getElementById("dragImg").appendChild(outline).appendChild(obj)
  e.dataTransfer.setDragImage(obj,e.pageX - self.object.getBoundingClientRect().left,e.pageY -self.object.getBoundingClientRect().top);
}

////////////////////////////////////////////////////////
// setDragNoneImage () 
// 드레그를 이미지를 컨트롤 합니다.
////////////////////////////////////////////////////////

export function setDragNoneImage(self,e) {
  var img = new Image();
  img.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=';
  e.dataTransfer.setDragImage(img, 0, 0);
  // var outline = document.createElement("div");
  // var obj = document.createElement("div");
  // // let crt = e.target.cloneNode(true);
  // obj.style.position = "absolute";
  // obj.style.width = '10px'
  // obj.style.height = '10px'
  // obj.style.border = "2px solid #222"
  // obj.style.zIndex = "-200"
  // obj.style.transform = 'rotate('+ self.props.rotatez+ 'deg)'
  // document.getElementById("dragImg").appendChild(outline).appendChild(obj)
  // e.dataTransfer.setDragImage(obj,10,10);
}


////////////////////////////////////////////////////////
// DeleteDragImage () 
// 드레그를 이미지를 컨트롤 합니다.
////////////////////////////////////////////////////////
export function deleteDragImage() {
  while (document.getElementById("dragImg").firstChild){
    document.getElementById("dragImg").removeChild(document.getElementById("dragImg").firstChild);
  }

}

////////////////////////////////////////////////////////
// editHotkey (해당객체,이밴트)
// 특정키가 눌리는경우 위젯의 위치를 변경해줍니다.
////////////////////////////////////////////////////////

export function editHotkey(event) {
  let key = event.key
  let ctrl = event.ctrlKey
  let alt = event.altKey
  let shift = event.shiftKey

  switch (key) {
      // ['V'또는'v'] 복사
      case 'V':
          console.log(ctrl&&shift)
          if(ctrl&&shift){
              console.log('서식 붙여넣기')
          }
          else if(ctrl){
            Widget.paste({x:20,y:20})
              console.log('붙여넣기')
          }
          break;
      case 'v':
          console.log(ctrl&&shift)
          if(ctrl&&shift){
              console.log('서식 붙여넣기')
          }
          else if(ctrl){
            Widget.paste({x:20,y:20})
              console.log('붙여넣기')
          }
          break;
      case 'ㅍ':
          console.log(ctrl&&shift)
          if(ctrl&&shift){
              console.log('서식 붙여넣기')
          }
          else if(ctrl){
            Widget.paste({x:20,y:20})
              console.log('붙여넣기')
          }
          break;
      // ['Z'또는'z'] 복사
      case 'Z':
          console.log(ctrl&&shift)
          if(ctrl&&shift){
              console.log('되돌리기 취소')
          }
          else if(ctrl){
              Screen.undo()
          }
          break;
      case 'z':
          console.log(ctrl&&shift)
          if(ctrl&&shift){

              console.log('되돌리기 취소')
          }
          else if(ctrl){
              Screen.undo()
          }
          break;
      case 'ㅋ':
        console.log(ctrl&&shift)
        if(ctrl&&shift){

            console.log('되돌리기 취소')
        }
        else if(ctrl){
            Screen.undo()
        }
        break;  
      default:
          // store.dispatch(templateClick(true));     
          break;
  }
}