////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widget.scss"
import "sass/animation.scss"
import "./preset/BackgroundV2Preset.scss"

// [프로바이더] 연결
import EditContext from 'service/context/EditContext' ; 

// 컴포넌트 연결
import WidgetOverRay from '../Edit/editController/WidgetOverRay'; // 메뉴 헤더

// 서비스 연결
import * as Preset from './preset/BackgroundV2Preset';
import * as Event from '../../service/event/Actions' ; 
import * as Widget from '../../service/event/widget' ; 
import * as Model from '../../service/value/Model' ; 
import * as EnCoder from '../../service/engine/EnCoder' ; 
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class BackgroundV2 extends Component {
    
    static defaultProps = {
        widgetName: 'Background',
      }

    //[임시] 컨텍스트 연결
    static contextType = EditContext;

    state = {
        ...Widget.DefaultState(this),
        polling : 0,
    }

    constructor(props) {
        super(props);
        this.widget = React.createRef();
        this.canvas = React.createRef();
        this.animation = null;
        this.ctx = null;
    }

    // 캔버스 관리 (시작시)
    componentDidMount() {
        // -- 캔버스를 생성합니다.
        console.log('다시시작')
        this.animation = Preset.canvasInit({current:this.canvas.current,ctx:this.ctx,setAnimationID:this.setAnimationID,data:this.props.data,cache:this.getCache,option:{parents:this.props.parents}})
    }

    // 값 변경시
    componentDidUpdate(prevProps) {

        const arraysEqual= (arr1, arr2) =>  {
            if (arr1.length !== arr2.length) return false; // 배열 길이 비교
            return arr1.every((element, index) => element === arr2[index]); // 각 요소 비교
          }

        if(prevProps.data?.size?.width != this.props.data?.size?.width || prevProps.data?.size?.height != this.props.data?.size?.height){
            cancelAnimationFrame(this.animation)
            //새로운 애니메이션을 시작합니다.
            this.animation = Preset.canvasInit({current:this.canvas.current,ctx:this.ctx,setAnimationID:this.setAnimationID,data:this.props.data,cache:this.getCache,option:{parents:this.props.parents}})
        }

        if(!arraysEqual(prevProps.data?.value?.gradientColor,this.props.data?.value?.gradientColor)){
            cancelAnimationFrame(this.animation)
            //새로운 애니메이션을 시작합니다.
            this.animation = Preset.canvasInit({current:this.canvas.current,ctx:this.ctx,setAnimationID:this.setAnimationID,data:this.props.data,cache:this.getCache,option:{parents:this.props.parents}}) 
        }

    }



    setAnimationID = (id) => {
        // console.log('setAnimationID',id);
        cancelAnimationFrame(this.animation)
        this.animation = id;
    }

    componentWillUnmount(){
        clearInterval(this.IntervalID);
        cancelAnimationFrame(this.animation)
    }



    render() {
        const Style = this.props.getStyle(this.props.data)

        const Height = this.props.data?.size?.height;
        const Width = this.props.data?.size?.width;
        const MinSize = Width<Height?Width:Height;
        // 드레그를 통한 위젯 이동
        // Style.layout= { ...Style.layout,transform: 'translate('+this.state.dragMoveX+'px, '+this.state.dragMoveY+'px)'}
        
        switch (this.props.parents) {

            case 'edit':
                // 편집시 화면이동 트렌스레이션 효과 방지
                Style.layout.transition = false
                Style.object.transition = false
                return (         
                    <div style={Style.layout} className = {this.props.parents + " widgetObject "+this.props.widgetName+Widget.display(this.props)} >
                        {Preset.data(this,Style)}
                    </div>
                );

            case 'broadcast':
                return (
                    <div style={Style.layout} className = {this.props.parents + " widgetObject "+this.props.widgetName+Widget.display(this.props)} >
                        {Preset.data(this,Style)}
                    </div>
                );
          
            case 'view':
                return (
                    <div style={Style.layout} className = {this.props.parents + " widgetObject "+this.props.widgetName+Widget.display(this.props)} >
                       {Preset.data(this,Style)}
                    </div>
                );
        }

    }
}
////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    userState : state.userState,
})

function mapDispatchToProps(dispatch){
    return {
        //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}


  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(BackgroundV2);
  