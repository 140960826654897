import { min, update } from 'lodash';
import React, { Component , Fragment } from 'react';

function preset(preset,self,data,style){
    let fullsize = data.size.height
    let width = data.size.width
    let profile = self.state.profile
    let dataList = []
    let dataField =''


    let meridiemText = ''
    let hours = self.outputTime('hr')
    let minutes = self.outputTime('min')
    let seconds = self.outputTime('sec')

    let fullTime = 0 

    if (!data.value.use24hour){
        hours = Number(self.outputTime('hr'))%12
        hours = hours.toString()
        meridiemText = self.outputText('md')
    }

   
    switch (preset) {
        case 'textStyle':

            return(
                <div  className={"object "+self.props.widgetName+"_preset_"+preset} style={style.object} ref={self.widget} > 
                    <div className={self.props.widgetName+"Wrap"} >
                    {data.value.meridiemText?
                        <span className={"mdText ClockText "+data.value.outputText} >
                            {meridiemText}
                        </span>
                    :
                        ''
                    }
                    <span className={"hrTimer ClockTimer "+data.value.outputText}>
                        {hours}
                    </span>
                    <span className={"hrText ClockText "+data.value.outputText}>
                        {self.outputText('hr')}
                    </span>
                    <span className={"minTimer ClockTimer "+data.value.outputText}>
                        {minutes}
                    </span>
                    <span className={"minText ClockText "+data.value.outputText}>
                        {self.outputText('min')}
                    </span>
                    <span className={"secTimer ClockTimer "+data.value.outputText}>
                        {seconds}
                    </span>
                    <span className={"secText ClockText "+data.value.outputText}>
                        {self.outputText('sec')}
                    </span>
                    </div>
                </div>   
            )
        case 'flipStyle':
 
            fullTime = (Number(hours)*3600)+(Number(minutes)*60)+Number(seconds)
            return(
                <div  className={"object "+self.props.widgetName+"_preset_"+preset} style={style.object} ref={self.widget} > 
                    <div className={self.props.widgetName+"Wrap"} >
                    {data.value.meridiemText?
                        <span className={"mdText ClockText "+data.value.outputText}  style={{height:fullsize+'px' ,width:fullsize*160/100+'px', fontSize:fullsize*50/100+'px',}}>
                            {meridiemText}
                        </span>
                    :
                        ''
                    }
                    <span className={"hrTimer ClockTimer "+data.value.outputText} style={{height:fullsize+'px' ,width:fullsize*144/100+'px', fontSize:fullsize*50/100+'px',}}>
                        <FilpCard index={5} time={fullTime}/>
                        <FilpCard index={4} time={fullTime}/>
                    </span>
                    <span className={"hrText ClockText "+data.value.outputText} style={{height:fullsize+'px' ,width:fullsize*36/100+'px', fontSize:fullsize*30/100+'px',}}>
                        {self.outputText('hr')}
                    </span>
                    <span className={"minTimer ClockTimer "+data.value.outputText} style={{height:fullsize+'px' ,width:fullsize*144/100+'px', fontSize:fullsize*50/100+'px',}}>
                        <FilpCard index={3} time={fullTime}/>
                        <FilpCard index={2} time={fullTime}/>
                    </span>
                    <span className={"minText ClockText "+data.value.outputText} style={{height:fullsize+'px' ,width:fullsize*36/100+'px', fontSize:fullsize*30/100+'px',}}>
                        {self.outputText('min')}
                    </span>
                    <span className={"secTimer ClockTimer "+data.value.outputText} style={{height:fullsize+'px' ,width:fullsize*144/100+'px', fontSize:fullsize*50/100+'px',}}>
                        <FilpCard index={1} time={fullTime}/>
                        <FilpCard index={0} time={fullTime}/>
                    </span>
                    <span className={"secText ClockText "+data.value.outputText} style={{height:fullsize+'px' ,width:fullsize*36/100+'px', fontSize:fullsize*30/100+'px',}}>
                        {self.outputText('sec')}
                    </span>
                    </div>
                </div>   
            )

        case 'flipWhiteStyle':

            fullTime = (Number(hours)*3600)+(Number(minutes)*60)+Number(seconds)
            return(
                <div  className={"object "+self.props.widgetName+"_preset_"+preset} style={style.object} ref={self.widget} > 
                    <div className={self.props.widgetName+"Wrap"} >
                    {data.value.meridiemText?
                        <span className={"mdText ClockText "+data.value.outputText}  style={{height:fullsize+'px' ,width:fullsize*160/100+'px', fontSize:fullsize*50/100+'px',}}>
                            {meridiemText}
                        </span>
                    :
                        ''
                    }
                    <span className={"hrTimer ClockTimer "+data.value.outputText} style={{height:fullsize+'px' ,width:fullsize*144/100+'px', fontSize:fullsize*50/100+'px',}}>
                        <FilpCard index={5} time={fullTime}/>
                        <FilpCard index={4} time={fullTime}/>
                    </span>
                    <span className={"hrText ClockText "+data.value.outputText} style={{height:fullsize+'px' ,width:fullsize*36/100+'px', fontSize:fullsize*30/100+'px',}}>
                        {self.outputText('hr')}
                    </span>
                    <span className={"minTimer ClockTimer "+data.value.outputText} style={{height:fullsize+'px' ,width:fullsize*144/100+'px', fontSize:fullsize*50/100+'px',}}>
                        <FilpCard index={3} time={fullTime}/>
                        <FilpCard index={2} time={fullTime}/>
                    </span>
                    <span className={"minText ClockText "+data.value.outputText} style={{height:fullsize+'px' ,width:fullsize*36/100+'px', fontSize:fullsize*30/100+'px',}}>
                        {self.outputText('min')}
                    </span>
                    <span className={"secTimer ClockTimer "+data.value.outputText} style={{height:fullsize+'px' ,width:fullsize*144/100+'px', fontSize:fullsize*50/100+'px',}}>
                        <FilpCard index={1} time={fullTime}/>
                        <FilpCard index={0} time={fullTime}/>
                    </span>
                    <span className={"secText ClockText "+data.value.outputText} style={{height:fullsize+'px' ,width:fullsize*36/100+'px', fontSize:fullsize*30/100+'px',}}>
                        {self.outputText('sec')}
                    </span>
                    </div>
                </div>   
            )
        default:
            return(
                <div className={"object unknownPreset"} ref={self.widget} style={{...style.object}} >
                    <p>알수없는 프리셋</p>
                </div>
                )
    }
}
export function data(self,style) {
    return preset(self.props.data.preset,self,self.props.data,style)
}




class FilpCard extends React.Component {

    state = {
        update : true,
        topNumber:'',
        botNumber:'',
        frontNumber:'',
        BackNumber:'',

        time : this.props.time,
        stack :  0,
    }

    componentDidMount (){
        let timeUpdate = setInterval(this.timeUpdate, 250)
        this.setState({
            interval: timeUpdate,
        },)
    }
    
    componentDidUpdate(){
        if( Math.abs(this.state.time - this.props.time) > 5 ){
            this.reset()
        }
    }

    reset = () =>{
        clearInterval(this.state.interval);
        let timeUpdate = setInterval(this.timeUpdate, 250)
        this.setState({   
            interval: timeUpdate,
            stack:0,
            time:this.props.time,
            topNumber:'',
            botNumber:'',
            frontNumber:'',
            BackNumber:'',
        })
    }
    
    componentWillUnmount() {
        clearInterval(this.state.interval);
    }

    timeUpdate = () => {
        let topNumber = ''
        let botNumber = ''
        let frontNumber = ''
        let BackNumber = ''
        let update = false

        if(this.getTimeText(this.state.time+1,this.props.index) != this.getTimeText(this.state.time,this.props.index)){
            update = true
        }

        switch (this.state.stack) {
            case 0:
                
                topNumber = this.getTimeText(this.state.time+1,this.props.index)
                botNumber = this.getTimeText(this.state.time,this.props.index)
                frontNumber = this.getTimeText(this.state.time,this.props.index)
                BackNumber = this.getTimeText(this.state.time,this.props.index)

                this.setState({
                    topNumber:topNumber,
                    botNumber:botNumber,
                    frontNumber:frontNumber,
                    BackNumber:BackNumber,
                    stack:(this.state.stack+1)%4,
                    time:this.state.time+1,
                    update:update
                })
                break;
            case 1:

                topNumber = this.getTimeText(this.state.time,this.props.index)
                botNumber = this.getTimeText(this.state.time-1,this.props.index)
                frontNumber = this.getTimeText(this.state.time-1,this.props.index)
                BackNumber = this.getTimeText(this.state.time,this.props.index)

                this.setState({
                    topNumber:topNumber,
                    botNumber:botNumber,
                    frontNumber:frontNumber,
                    BackNumber:BackNumber,
                    stack:(this.state.stack+1)%4
                })
                break;
            case 2:

                topNumber = this.getTimeText(this.state.time,this.props.index)
                botNumber = this.getTimeText(this.state.time-1,this.props.index)
                frontNumber = this.getTimeText(this.state.time,this.props.index)
                BackNumber = this.getTimeText(this.state.time,this.props.index)

                this.setState({
                    topNumber:topNumber,
                    botNumber:botNumber,
                    frontNumber:frontNumber,
                    BackNumber:BackNumber,
                    stack:(this.state.stack+1)%4
                })
                break;
            case 3:
            
                topNumber = this.getTimeText(this.state.time,this.props.index)
                botNumber = this.getTimeText(this.state.time,this.props.index)
                frontNumber = this.getTimeText(this.state.time,this.props.index)
                BackNumber = this.getTimeText(this.state.time,this.props.index)
                
                this.setState({ 
                    topNumber:topNumber,
                    botNumber:botNumber,
                    frontNumber:frontNumber,
                    BackNumber:BackNumber,
                    stack:(this.state.stack+1)%4,
                    update:false
               
                })
                break;
        
            default:
                break;
        }

        

    }

    getTimeText = (time,index=null) =>{
        let hours = Math.floor(time/3600).toString()
        let minutes =  Math.floor((time%3600)/60).toString()
        let seconds =  (time%60).toString()

        if (hours<10){hours ='0'+ hours}
        if (minutes<10){minutes ='0'+ minutes}
        if (seconds<10){seconds ='0'+ seconds}

        // console.log(hours,minutes,seconds)

        switch (index) {
            case 0: return Number(seconds.charAt(1))
            case 1: return Number(seconds.charAt(0))
            case 2: return Number(minutes.charAt(1))
            case 3: return Number(minutes.charAt(0))
            case 4: return Number(hours.charAt(1))
            case 5: return Number(hours.charAt(0))
            default : return hours+minutes+seconds
        }
    } 


    render() {
 
        return (
            <div className='cardWrap'>
                <div className={"card "+"update"+this.state.update}> 
                    <div className="cardTop">
                        <div className="cardNum">{this.state.topNumber}</div>
                    </div>
                     
                    <div className="cardFlip">
                        <div className="cardNumFront cardNum">{this.state.frontNumber}</div>
                        <div className="cardNumBack cardNum">{this.state.BackNumber}</div>
                    </div> 
                    <div className="cardBot">
                        <div className="cardNum test">{this.state.botNumber}</div>
                    </div>         
                </div>
                {/* <div className='check2'>
                    {this.state.time}{this.state.stack}<br/>
                    {this.state.topNumber},{this.state.frontNumber},{this.state.BackNumber},{this.state.botNumber}
                </div> */}
            </div>
        );
    }
  }
  