import * as Utility from 'service/other/Utility' ; 

function preset(preset,self,data,style){
    let fullsize = data.size.height
    let width = data.size.width
    let profile = self.state.profile
    let dataList = []
    const FillColor =  data.style.fill;
    let dataField =''
    switch (preset) {
        case 'Postit':
            const PostHead =  Utility.colorDiff(FillColor,20,20,20)
            const ContentSubColor =  Utility.colorDiff(PostHead,62,62,62)
            if(data.style.direction !="vertical"){
                return(
                    <div className={"object "+self.props.widgetName+"_preset_"+preset+" horizontal"} ref={self.widget} style={{...style.object,background:`linear-gradient(to right, ${FillColor},${FillColor},#00000000,#00000000)`}} >
                        <div className="postSticker" style={{backgroundColor:FillColor}}/>
                        <div className="postContent" style={{width:(width-20)+'px',background:`linear-gradient(135deg, ${PostHead} 60%, ${PostHead} 60%, ${PostHead} 60%, ${ContentSubColor} 100%)`}}>
                            <div className="postTextPadding"/>
                            <pre className="postMain" style={{fontFamily:style.object.fontFamily,width:(width-20)+'px',alignItems:style.object.textVerticalAlign}}>{data.value.text}</pre>
                            <div className="postTextPadding"/>
                        </div>
                    </div>
                )
            }else{
                return(
                    <div className={"object "+self.props.widgetName+"_preset_"+preset+" vertical"} ref={self.widget} style={{...style.object,background:`linear-gradient(to right, ${FillColor},${FillColor},#00000000,#00000000)`}} >
                        <div className="postSticker" style={{backgroundColor:FillColor}}/>
                        <div className="postContent" style={{width:(width)+'px',background:`linear-gradient(135deg,  ${PostHead} 60%, ${PostHead} 60%, ${PostHead} 60%, ${ContentSubColor} 100%)`}}>
                            {/* <div className="postTextPadding"/> */}
                            <pre className="postMain" style={{fontFamily:style.object.fontFamily,width:(width)+'px',alignItems:style.object.textVerticalAlign}}>{data.value.text}</pre>
                            {/* <div className="postTextPadding"/> */}
                        </div>
                    </div>
                )  
            }

        
        case 'Modern':
            return(
                <div className={"object "+self.props.widgetName+"_preset_"+preset} ref={self.widget} style={{...style.object,backgroundColor:FillColor}} >
                        <pre className="ModernMain" style={{fontFamily:style.object.fontFamily,width:(width-20)+'px',alignItems:style.object.textVerticalAlign}}>{data.value.text}</pre>
                </div>
            )
        case 'Apps':
            return(
                <div className={"object "+self.props.widgetName+"_preset_"+preset} ref={self.widget} style={{...style.object,backgroundColor:FillColor}} >
                        {/* <p className="ModernMain" style={{fontFamily:style.object.fontFamily,width:(width-20)+'px',alignItems:style.object.textVerticalAlign}}> {data.value.text}</p> */}
                        <div class="notepad" >
                            <div class="notepadNav">
                                <p>메모장</p>
                                <div class="notepadButtonArea">
                                    <div class="notepadButton" style={{background: "rgb(43, 200, 65)"}}></div>
                                    <div class="notepadButton" style={{background: "rgb(255, 187, 48)"}}></div>
                                    <div class="notepadButton" style={{background: "rgb(254, 95, 87)"}}></div>
                                </div>
                            </div>
                            <pre class="notepadMain" style={{fontFamily:style.object.fontFamily,width:(width-20)+'px',alignItems:style.object.textVerticalAlign}}>
                            {data.value.text}
                            </pre>
                        </div>
                </div>
            )
        case 'Receipt':
            return(
                <div className={"object "+self.props.widgetName+"_preset_"+preset} ref={self.widget} style={{...style.object}} >
                <div class="receipt" >
                        <h2>영수증</h2>
                        <div class="line"></div>
                        <pre class="item" style={{fontFamily:style.object.fontFamily,width:(width-20)+'px',alignItems:style.object.textVerticalAlign}}>
                        {data.value.text}
                        </pre>
                        <div class="line"></div>
                    </div>
                </div>
            )
        default:
            return(
                <div className={"object unknownPreset"} ref={self.widget} style={{...style.object}} >
                    <p>알수없는 프리셋</p>
                </div>
                )
    }
}
export function data(self,style) {
    return preset(self.props.data.preset,self,self.props.data,style)
}

