////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { useState, Fragment , useContext, useEffect} from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { useHistory,useLocation } from 'react-router-dom';

// [리덕스]스토어 연결

// [프로바이더]
import EditContext from 'service/context/EditContext' ; 

// SASS&CSS 연결
// import "sass/edit.scss"



// 이미지 연결
import logoW from 'assets/logoW.svg';

// 컴포넌트 연결

import MenuPanel from 'components/Edit/editPanel/preferences/MenuPanel'; // 상단 메뉴
import PreferencesPanel from 'components/Edit/editPanel/preferences/PreferencesPanel'; // 상단 메뉴
import UtilityPanel from 'components/Edit/editPanel/preferences/UtilityPanel'; // 상단 메뉴


// 서비스 연결


////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
function LeftPanelArea({sizeDetector,panelSize}) {

    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////


    //set
    const context = useContext(EditContext);

    // state

    // const [OverlapToken, setOverlapToken] = usePreferencesPanelState(null);

    // rel usePreferencesPanelState


   ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////
    useEffect(() => {
        // 여기에 컴포넌트가 마운트되었을 때 실행할 코드를 작성합니다.

        
        // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
        return () => {
          console.log('Component will unmount');
        };
      }, []);

    const objectDrop = (e) =>{
      e.preventDefault();
      e.stopPropagation();
    }


    // 확장메뉴 사용시 
    const EditorExtend = context.extendEditor==null ? 0 : (panelSize?.EditContainer?.width) - (panelSize?.PreferencesPanel?.width) - 40 ??0  ;

    const PanelSize = {
      left: `${EditorExtend}px`,

  }

    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////
    return (
        <Fragment>
                <style jsx>{`${EditPageStyle}`}</style>
                <div id = "LeftPanelArea" style={PanelSize} >
                <MenuPanel/>
                <div className = "marginSpace"/>
                <PreferencesPanel sizeDetector={sizeDetector}/>
                <div className = "marginSpace"/>
                <UtilityPanel sizeDetector={sizeDetector}/>

                
                </div>
        </Fragment>
    );

}

////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    mainScreen : state.mainScreen,
})

function mapDispatchToProps(dispatch){
    return {
        initOverlap : (overlapData) => dispatch({type:'initOverlap',overlap : overlapData}),
    }
}

  // export default EditPage;
export default connect(mapStateToProps,mapDispatchToProps)(LeftPanelArea);
  


  const EditPageStyle = `

  #LeftPanelArea {
    display: flex;
    flex-direction: column;
    width: 320px;
    animation: LeftPanelAreaStart ease 1s;
    margin : 20px;
    height: calc( 100vh - 40px );
    position: absolute;
    z-index : 5;
    transition : left 1s ease-in-out;
  }
  #LeftPanelArea .marginSpace{
    width: 100%;
    height: 7.5px;
  }

  @keyframes LeftPanelAreaStart {
    0% {
      transform : translateX(-320px);
    }
    100% {
      transform : translateX(0px);
    }
  }
  `;