////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widgetEdit.scss"

// 컴포넌트 연결
// import Show from '../show/Show'; // 메뉴 헤더
import ValueType from './ValueType';
// 서비스 연결

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

class SizeModule extends Component {

    render() { 

        return (
            <div className="ModuleContainer">
                <label className="EditModule" htmlfor="fname"> 
                    <p>{this.props.title?this.props.title+' ':this.props.type+' '}</p>
                    <input type="number" name="input1" min="0" max={this.props.max} value={this.props.value} onBlur={(e)=>this.props.onBlur(this.props.type,e.target.value)} onFocus={(e)=>this.props.onFocus(this.props.type,e)} onChange={(e) => this.props.change(this.props.type,e.target.value)} />
   
                    {/*  onChange={(e) => this.props.change(this.props.type,e.target.value)} */}
                </label>
            </div>
        );
    }
}

////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    // screenData : state.screenData,
})

function mapDispatchToProps(dispatch){
    return {
    //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(SizeModule);
  