////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { useState, Fragment , useContext, useEffect} from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { useHistory,useLocation } from 'react-router-dom';

// [리덕스]스토어 연결

// [프로바이더]
import EditContext from 'service/context/EditContext' ; 

// 컴포넌트 연결

// 이미지 연결
import EG_editor1 from 'assets/guide/EG_editor1.png';
import EG_editor2 from 'assets/guide/EG_editor2.png';
import EG_editor2_1 from 'assets/guide/EG_editor2-1.png';
import EG_editor3 from 'assets/guide/EG_editor3.png';
import EG_editor4 from 'assets/guide/EG_editor4.png';

import EG_page1 from 'assets/guide/EG_page1.png';
import EG_page2 from 'assets/guide/EG_page2.png';
import EG_page3 from 'assets/guide/EG_page3.png';

import EG_widget1 from 'assets/guide/EG_widget1.png';
import EG_widget2 from 'assets/guide/EG_widget2.png';
import EG_widget2_1 from 'assets/guide/EG_widget2-1.png';
import EG_widget2_2 from 'assets/guide/EG_widget2-2.png';
import EG_widget3 from 'assets/guide/EG_widget3.png';
import EG_widget3_1 from 'assets/guide/EG_widget3-1.png';
import EG_widget4 from 'assets/guide/EG_widget4.png';
import EG_widget4_1 from 'assets/guide/EG_widget4-1.png';
// 서비스 연결
import * as Editor from 'service/edit/EditService' ; 
import * as Guide from 'service/other/Guide'; 
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
function ExtendGuidePanel({}) {



    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////

    //set
    const context = useContext(EditContext);

    // state
    const [GuidePage, setGuidePage] = useState('editorBasic');
    // rel 


   ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////
    useEffect(() => {
        // 여기에 컴포넌트가 마운트되었을 때 실행할 코드를 작성합니다.

        // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
        return () => {
          console.log('Component will unmount');
        };
      }, []);



    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////
    
    return (
        <Fragment>
                <style jsx>{`${StoreStyle}`}</style>
                <div id = "ExtendGuidePanel" >

                    <div id = "ExtendGuideNav">
                        <div className = "GuideList">
                            <div className = {GuidePage=='editor'?"menu select":"menu"} onClick={()=>setGuidePage('editorBasic')}>
                            편집기 둘러보기
                            </div>
                            <div className = {GuidePage=='page'?"menu select":"menu"} onClick={()=>setGuidePage('pageBasic')}>
                            페이지 편집하기
                            </div>
                            <div className = {GuidePage=='widget'?"menu select":"menu"} onClick={()=>setGuidePage('widgetBasic')}>
                            위젯 편집하기
                            </div>
                            <div className = {GuidePage=='hotkey'?"menu select":"menu"} onClick={()=>setGuidePage('hotkeyBasic')}>
                            단축키 가이드
                            </div>
                            <div className = {GuidePage=='channel'?"menu select":"menu"} onClick={()=>setGuidePage('channelGuide')}>
                            채널 가이드
                            </div>
                        </div>
            
                    </div>
                    <div id = "ExtendGuideBody">
                      {Guide.get(GuidePage)}
                    <div>

                    </div>
    
                    </div>

                </div>
        </Fragment>
    );

}

////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    mainScreen : state.mainScreen,
})

function mapDispatchToProps(dispatch){
    return {
        initOverlap : (overlapData) => dispatch({type:'initOverlap',overlap : overlapData}),
    }
}

  // export default EditPage;
export default connect(mapStateToProps,mapDispatchToProps)(ExtendGuidePanel);
  


  const StoreStyle = `

  #ExtendGuidePanel {
    display: flex;

    width: 100%;
    height: 100%;
    animation: TemplateStorePanelStart ease 1s;
    background-color: #3F3F3F;
    border-radius: 5px;
    overflow: hidden;
  }


  #ExtendGuideNav{
    width: 200px;
    display: flex;
    height: 100%;
  }

  #ExtendGuideNav  .GuideList{
    display: flex;
    width: 200px;
    height: 100%;
    padding : 15px 0px;
    flex-direction: column;
    line-height: 40px;
    box-sizing: border-box;
    display: flex;
    justify-content: start;
    box-shadow: 1px 1px 5px #1c1c1c;
    background-color: #2C2C2C;
    border-radius: 5px;
  }

  #ExtendGuideNav .menu{
    width: 120px;
    padding : 0px 20px;
    border-radius: 5px;
    margin : 0px 20px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #ExtendGuideNav .menu.select{
    background-color: orange;
  }
  
  #ExtendGuideBody{
    display: flex;
    flex-direction: column;
    flex : 1;
    height: 100%;
    overflow:auto;
  }
 

  @keyframes TemplateStorePanelStart {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
  }
  `;


  // const NoneGuide=(
  //   <article id = "GuideBook">
  //   <div style={{marginTop:'20px'}}/>
  //   <div className = "infoMessage">
  //     가이드를 준비중입니다.
  //   </div>
  //   </article>
  // )
  // const editorGuide = (
  //   <article id = "GuideBook">
  //   <div style={{marginTop:'20px'}}/>
  //   <h3>
  //     편집기 둘러보기
  //   </h3>
  //   <div className = "infoImage">
  //     <img src={EG_editor1}/>
  //   </div>
  //   <div className = "infoMessage">
  //     위젯 편집기는 <b>편집패널,</b> <b>도구패널,</b> <b>추가패널,</b> <b>편집화면</b> 으로 나뉩니다.
  //   </div>
  //   <p><b>편집패널</b> 에서는 페이지 편집, 위젯 편집, 위젯의 효과 편집을 할 수 있습니다. </p>
  //   <p><b>도구패널</b> 에서는 연결된 채널 관리와 페이지 내 위젯의 순서, 이름 변경을 할 수 있습니다. </p>
  //   <p><b>추가패널</b> 에서는 위젯, 변수등 다양한 요소를 추가 할 수 있습니다.</p>
  //   <p><b>편집화면</b> 에서는 마우스와 키보드를 통해서 화면을 보며 위젯을 편집 할 수 있습니다. </p>
  //   <div style={{marginTop:'20px'}}/>
  //   <h3>
  //     패널 소개
  //   </h3>
    
  //   <div className = "infoImage">
  //     <img src={EG_editor2}/>
  //   </div>
  //   <div className = "infoMessage">
  //     편집 패널은 상단 메뉴를 통해서 <b> {Editor.icon({icon:'stack',size:15,lineHeight:18})} 화면편집  {Editor.icon({icon:'deployed_code',size:15,lineHeight:18})} 위젯편집  {Editor.icon({icon:'acute',size:15,lineHeight:18})} 효과편집</b>  탭으로 이동 할 수 있습니다. 
  //   </div>

  //   <div>
  //   <p> <div className='iconBox' style={{background:'#222'}}> {Editor.icon({icon:'stack',size:15,lineHeight:18})} </div>프로젝트 내 페이지를 관리하는 탭 입니다. </p>
  //   <p> <div className='iconBox' style={{background:'#222'}}> {Editor.icon({icon:'deployed_code',size:15,lineHeight:18})} </div>선택한 위젯을 편집하는 탭 입니다. </p>
  //   <p> <div className='iconBox' style={{background:'#222'}}> {Editor.icon({icon:'acute',size:15,lineHeight:18})}  </div>선택한 위젯에 에니메이션 효과를 부여하는 탭 입니다. </p>
  //   </div>

  //   <div className = "infoImage">
  //     <img src={EG_editor3}/>
  //   </div>
  //   <div className = "infoMessage">
  //     도구 패널은 상단 메뉴를 통해서 <b>채널 편집, 위젯 순서 편집</b> 탭으로 이동 할 수 있습니다. 
  //   </div>

  //   <div>
  //   <p style={{display:'flex',alignItems:'center'}}> <div className='iconBox' style={{background:'#222'}}> <svg id="broadcastIcon" width="25px" height="25px"><circle cx="12.5" cy="12.5" r="7" fill="red" stroke="#fff" stroke-width="2"></circle></svg> </div>채널을 선택하고, 채널을 관리하는 탭 입니다. </p>
  //   <p> <div className='iconBox' style={{background:'#222'}}> {Editor.icon({icon:'list',size:15,lineHeight:18})} </div>페이지내 위젯 순서를 보고 관리하는 탭 입니다. </p>
  //   </div>

  //   <div className = "infoImage">
  //     <img src={EG_editor4}/>
  //   </div>
  //   <div className = "infoMessage">
  //     추가 패널은 <b>위젯과 변수</b>를 추가 할 수 있는 패널입니다.
  //   </div>

  //   <div>
  //   <p> <div className='iconBox' style={{background:'#222'}}> {Editor.icon({icon:'widgets',size:15,lineHeight:18})} </div>위젯을 추가 할 수 있는 탭 입니다.</p>
  //   <p> <div className='iconBox' style={{background:'#222'}}> {Editor.icon({icon:'function',size:15,lineHeight:18})} </div>프로젝트 내 변수를 관리하는 탭 입니다.</p>
  //   </div>

  //   <div style={{marginTop:'20px'}}/>
  //   <p> 더 자세한 가이드는 왼쪽 메뉴에서 확인 할 수 있습니다. </p>
  //   <div style={{marginTop:'20px'}}/>
  // </article>
  // )

  // const PageGuide = (
  //   <article id = "GuideBook">
  //   <div style={{marginTop:'20px'}}/>
  //   <h3>
  //     페이지 편집하기
  //   </h3>
  //   <div className = "infoImage">
  //     <img src={EG_page1}/>
  //   </div>
  //     <p> {Editor.icon({icon:'stack',size:15,lineHeight:18})} <b>화면 편집</b>탭 에서는 프로젝트 내 페이지를 관리하며 특정 페이지를 송출 할 수 있습니다. </p>
  //     <div style={{marginTop:'20px'}}/>
  //     <h3> 왼쪽 상단 메뉴 소개</h3>
  //     <div className = "infoMessage">
  //       왼쪽 상단 메뉴는 페이지를 추가하거나 페이지의 상태에 대해 알 수 있는 도구들이 있습니다.
  //     </div>

  //     <div>
  //     <p> <div className='iconBox' style={{background:'#222'}}> {Editor.icon({icon:'new_window',size:15,lineHeight:18})} </div>프로젝트에 빈 페이지를 추가합니다. </p>
  //     <p> <div className='iconBox' style={{background:'#222'}}> {Editor.icon({icon:'shop',size:15,lineHeight:18})} </div>페이지를 다운로드 받을수 있는 스토어로 이동합니다. </p>
  //     <p> <div className='iconBox' style={{background:'orange'}}> 1 </div>편집화면에 선택된 페이지의 번호입니다. </p>
  //     </div>

  //     <div style={{marginTop:'20px'}}/>
  //     <h3> 상태별 페이지 프리뷰 </h3>
  //     <div className = "infoImage">
  //       <img src={EG_page2}/>
  //     </div>
  //     <div className = "infoMessage">
  //       페이지 상태에 따라 프리뷰는 다르게 표시됩니다. 페이지 번호와 프리뷰의 배경을 통해 페이지의 상태를 파악 할 수 있습니다.
  //     </div>
  //     <div>
  //     <p> <div className='iconBox' style={{background:'#222'}}> 2 </div>&#91;일반 페이지&#93; 편집중도 아니며 송출중도 아닌 페이지 입니다.</p>
  //     <p> <div className='iconBox' style={{background:'orange'}}> 3 </div>&#91;편집중인 페이지&#93; 편집화면에서 보여지고 있는 페이지 입니다. </p>
  //     <p> <div className='iconBox' style={{background:'red'}}> 4 </div>&#91;송출중인 페이지&#93; 선택된 채널로 송출되고 있는 페이지 입니다. </p>
  //     </div>

  //     <div style={{marginTop:'20px'}}/>
  //     <h3> 프리뷰에서 페이지 수정하기 </h3>
  //     <div className = "infoImage">
  //       <img src={EG_editor2_1} style={{width:300,margin:'auto'}} />
  //     </div>
  //     <div className = "infoMessage">
  //       페이지 프리뷰에서는 페이지 순서, 제목, 미리보기, 등을 확인하고 수정할 수 있습니다.
  //     </div>
  //     <div>
  //     <p> - 프리뷰를 클릭해서 편집할 페이지를 선택 할 수 있습니다.</p>
  //     <p> - 프리뷰를 드레그 해서 페이지 순서를 바꿀 수 있습니다.</p>
  //     <p> - 페이지의 제목 부분을 클릭하고 제목을 변경 할 수 있습니다.</p>
  //     <p> - 프리뷰를 오른쪽 클릭해서 페이지의 추가 설정 메뉴를 볼 수 있습니다.</p>
  //     </div>

  //     <div className = "infoMessage">
  //       마우스 포인터를 프리뷰에 가깝게 다가가면 페이지 관련 메뉴가 보입니다.
  //     </div>
  //     <div>
  //     <p style={{display:'flex',alignItems:'center'}}> <div className='iconBox' style={{background:'#222'}}> <svg id="broadcastIcon" width="25px" height="25px"><circle cx="12.5" cy="12.5" r="7" fill="red" stroke="#fff" stroke-width="2"></circle></svg> </div> 연결된 채널에 해당 페이지를 송출 합니다.</p>
  //     <p style={{display:'flex',alignItems:'center'}}> <div className='iconBox' style={{background:'#222'}}> <svg id="broadcastIcon" width="25px" height="25px"><circle cx="12.5" cy="12.5" r="7" fill="grey" stroke="#fff" stroke-width="2"></circle></svg> </div> <small>채널이 연결되지않으면 해당 버튼이 회색으로 바뀌며 페이지를 송출 할 수 없습니다.</small> </p>
  //     <p> <div className='iconBox' style={{background:'#222'}}> {Editor.icon({icon:'content_copy',size:15,lineHeight:18})} </div>페이지를 복사하고 붙여넣습니다. </p>      
  //     <p> <div className='iconBox' style={{background:'#222'}}> {Editor.icon({icon:'arrow_drop_down',size:15,lineHeight:18})} </div>페이지의 추가 설정 메뉴를 봅니다. 오른쪽 클릭과 같은 역활을 합니다. </p>

  //     </div>
  //     {/* <p>  마우스 포인터를 페이지에 가깝게 다가가면  </p> */}
  //   <div style={{marginTop:'20px'}}/>
  //   <h3> 추가 설정 메뉴 </h3>
  //   <div className = "infoImage">
  //       <img src={EG_page3} style={{width:300,margin:'auto'}} />
  //   </div>
  //   <div className = "infoMessage">
  //   <div className='iconBox' style={{background:'#222'}}> {Editor.icon({icon:'arrow_drop_down',size:15,lineHeight:18})}</div>버튼 또는 프리뷰에서 <b>마우스 오른쪽 클릭</b>을 해서 추가 설정 메뉴를 불러올 수 있습니다.
  //   </div>
  //   <p> 추가 설정메뉴에서 다음과 같은 설정을 할 수 있습니다.</p>
  //   <div>
  //     <p style={{display:'flex',alignItems:'center'}}> <div className='iconBox' style={{background:'#222'}}> <svg id="broadcastIcon" width="25px" height="25px"><circle cx="12.5" cy="12.5" r="7" fill="red" stroke="#fff" stroke-width="2"></circle></svg> </div>&#91;송출하기&#93; 연결된 채널에 해당 페이지를 송출 합니다.</p>
  //     <p> <div className='iconBox' style={{background:'#222'}}> {Editor.icon({icon:'content_copy',size:15,lineHeight:18})} </div>&#91;복사하기&#93; 페이지를 복사하고 붙여넣습니다. </p>      
  //     <p> <div className='iconBox' style={{background:'#222'}}> {Editor.icon({icon:'remove_selection',size:15,lineHeight:18})} </div>&#91;삭제하기&#93; 페이지를 삭제합니다. </p>
  //   </div>
  //   {/* <p> 더 자세한 가이드는 왼쪽 메뉴에서 확인 할 수 있습니다. </p> */}
  //   <div style={{marginTop:'20px'}}/>
  // </article>
  // )

  // const WidgetGuide = (
  // <article id = "GuideBook">
  //   <div style={{marginTop:'20px'}}/>
  //   <h3>
  //     위젯 편집하기
  //   </h3>
  //   <div className = "infoImage">
  //     <img src={EG_widget1} style={{width:700,margin:'auto'}} />
  //   </div>
  //   <p>
  //     편집기에서는 위젯을 <b>추가,편집,삭제</b> 하고 위젯의 <b>추가 설정 메뉴</b>를 불러올수 있습니다.
  //   </p>
  //   <div style={{marginTop:'20px'}}/>
  //   <h3>위젯 추가</h3>
  //   <div className = "infoImage">
  //   <img src={EG_editor4} style={{width:700,margin:'auto'}} />
  //   </div>
  //   <div className = "infoMessage" style={{textAlign:'center'}}>
  //   새로운 위젯을 추가하려면 편집기의 오른쪽 하단에 있는 <br/> 추가패널에서 {Editor.icon({icon:'widgets',size:15,lineHeight:18})}위젯탭을 선택해서 위젯을 추가 할 수 있습니다.
  //   </div>
  //   <div>
  //   <p> <div className='iconBox' style={{background:'#222'}}> {Editor.icon({icon:'image',size:15,lineHeight:18})} </div>&#91;이미지&#93; URL을 통해서 jpg, png, gif, webp 등의 확장자의 이미지를 화면에 표시하는 위젯입니다.</p>
  //   <p> <div className='iconBox' style={{background:'#222'}}> {Editor.icon({icon:'subscriptions',size:15,lineHeight:18})} </div>&#91;동영상&#93; URL을 통해서 유튜브 등 인터넷상에 있는 동영상을 보여주는 위젯입니다.</p>
  //   <p> <div className='iconBox' style={{background:'#222'}}> {Editor.icon({icon:'timer',size:15,lineHeight:18})} </div>&#91;타이머&#93; 특정 시간까지 남은 시간을 표시하는 위젯입니다.</p>
  //   </div>
  //   <div style={{marginTop:'20px'}}/>
  //   <h3>위젯 편집</h3>
  //   <div className = "infoImage">
  //   <img src={EG_widget2} />
  //   </div>
  //   <div className = "infoMessage" style={{textAlign:'center'}}>
  //     위젯의 편집은 주로 편집화면과 편집패널의 {Editor.icon({icon:'deployed_code',size:15,lineHeight:18})}위젯편집 탭에서 이루워집니다.
  //   </div>
  //   <div>
  //     <p>- &#91;편집 화면&#93; 편집할 위젯을 선택 할 수 있습니다. 선택 후 메뉴로 위젯을 변형시켜 옵션을 변경 할 수 있습니다.</p>
  //     <p>- &#91;편집 패널&#93; 위젯의 다양한 세부 옵션과 애니메이션 효과를 편집 할 수 있습니다.</p>
  //   </div>
  //   <p>
  //     편집화면에 있는 <b>위젯을 클릭하면</b> 왼편의 편집패널이 자동으로 해당 <b>위젯을 편집 할 수 있는 위젯편집 탭</b>으로 변경됩니다.
  //   </p>
  //   <div style={{marginTop:'20px'}}/>
  //   <h3>편집화면에서의 위젯 편집</h3>
  //   <div className = "infoImage">
  //   <img src={EG_widget2_1} style={{width:300,margin:'auto'}} />
  //   </div>
  //   <div className = "infoMessage">
  //     위젯을 선택하면 해당 위젯이 상단에 위젯 설정 메뉴와 위젯의 위치&크기를 편집할 수 있는 핸들이 나옵니다.
  //   </div>

  //   <h3>위젯 핸들을 통한 편집</h3>
  //   <div>
  //     <p> <div className='iconBox' style={{background:'#222'}}> {Editor.icon({icon:'open_with',size:15,lineHeight:18})} </div>위젯을 잡고 드레그 하면 위젯을 이동 시킬 수 있습니다. </p>
  //     <p> <div className='iconBox' style={{background:'#222'}}> {Editor.icon({icon:'left_click',size:15,lineHeight:18})} </div>위젯 핸들을 잡고 드레그 하면 위젯의 사이즈를 조절 할 수 있습니다. </p>
  //   </div>
  //   <h3>위젯 상단 메뉴를 통한 편집</h3>
  //   <div>
  //     <p> <div className='iconBox' style={{background:'#222'}}> {Editor.icon({icon:'content_copy',size:15,lineHeight:18})} </div>위젯을 복사합니다. </p>
  //     <p> <div className='iconBox' style={{background:'#222'}}> {Editor.icon({icon:'visibility_off',size:15,lineHeight:18})} </div>위젯을 송출화면에서 보이지 않도록 합니다. </p>
  //     <p> <div className='iconBox' style={{background:'#222'}}> {Editor.icon({icon:'flip_to_front',size:15,lineHeight:18})} </div>위젯 순서를 맨 앞으로 변경합니다.</p>
  //     <p> <div className='iconBox' style={{background:'#222'}}> {Editor.icon({icon:'flip_to_back',size:15,lineHeight:18})} </div>위젯 순서를 맨 뒤로 변경합니다.</p>
  //     <p> <div className='iconBox' style={{background:'#222'}}> {Editor.icon({icon:'delete',size:15,lineHeight:18})} </div>위젯을 삭제합니다</p>
  //     <p> <div className='iconBox' style={{background:'#222'}}> {Editor.icon({icon:'arrow_drop_down',size:15,lineHeight:18})} </div>위젯 추가 설정 메뉴를 보여줍니다.</p>
  //   </div>
  //   <div className = "infoMessage" style={{textAlign:'center'}}>
  //     위젯에는 순서가 있습니다. 앞에 있는 위젯이 뒤에 있는 위젯을 가리게 됩니다.
  //   </div>


  //   <div style={{marginTop:'20px'}}/>
  //   <h3>추가 설정 메뉴에서의 위젯 편집</h3>
  //   <div className = "infoImage">
  //   <img src={EG_widget2_2} style={{width:300,margin:'auto'}} />
  //   </div>
  //   <div className = "infoMessage" style={{textAlign:'center'}}>
  //   <div className='iconBox' style={{background:'#222'}}> {Editor.icon({icon:'arrow_drop_down',size:15,lineHeight:18})}</div>버튼 또는 마우스로 위젯 오른쪽 클릭을 하면 추가 설정 메뉴를 볼 수 있습니다.
  //   </div>
  //   <p> 위젯의 추가 설정 메뉴는 상단 메뉴의 옵션들과 더불어 <b>더 많은 옵션들을 제공합니다.</b></p>
  //   <div>
  //     <p> &#91;되돌리기&#93; 준비중인 기능입니다. 전에 한 행동을 취소합니다.</p>
  //     <p> &#91;순서변경&#93; 위젯의 순서를 변경합니다. 상단 메뉴보다 더 많은 옵션을 제공합니다.</p>
  //     <p> &#91;복사하기&#93; 위젯을 복사합니다. 상단 메뉴보다 더 많은 옵션을 제공합니다.</p>
  //     <p> &#91;위젯공유&#93; 준비중인 기능입니다.</p>
  //   </div>
  //   <div style={{marginTop:'20px'}}/>
  //   <h3>편집패널에서 위젯편집</h3>
  //   <div className = "infoImage">
  //   <img src={EG_widget3} style={{width:300,margin:'auto'}} />
  //   </div>


  //   <div className = "infoMessage" style={{textAlign:'center'}}>
  //     편집패널에서는 선택한 위젯의 상세정보를 수정 할 수 있습니다.
  //   </div>

  //   <div>
  //     <p> <div className='iconBox' style={{background:'#222'}}> {Editor.icon({icon:'deployed_code',size:15,lineHeight:18})} </div>위젯의 모양에 대해 편집합니다.</p>
  //     <p> <div className='iconBox' style={{background:'#222'}}> {Editor.icon({icon:'acute',size:15,lineHeight:18})} </div>위젯의 효과에 대해 편집합니다.</p>
  //   </div>

  //   <div style={{marginTop:'20px'}}/>
  //   <div className = "infoImage">
  //   <img src={EG_widget3_1} style={{width:400,margin:'auto'}} />
  //   </div>
  //   <div className = "infoMessage" style={{textAlign:'center'}}>
  //     편집패널에서는 여러 편집 요소를 주제로 다양한 폼이 있습니다.<br/>
  //     하나의 폼에서는 해당 폼의 주제, 위젯을 수정할 수 있는 버튼과 입력창이 있습니다.
  //   </div>
  //   <p> 폼을 통해서 값을 변경하면 바로 위젯이 변경됩니다. 편집화면과 송출중인 채널을 통해서 확인 할 수 있습니다. </p>

  //   <div style={{marginTop:'20px'}}/>
  //   <h3>도구 패널에서 위젯 편집하기</h3>

  //   <div className = "infoImage">
  //   <img src={EG_widget4_1} />
  //   </div>
  //   <div className = "infoMessage" style={{textAlign:'center'}}>
  //     도구 패널에서는 {Editor.icon({icon:'list',size:15,lineHeight:18})} 위젯목록 탭에서 위젯의 순서를 바꾸거나 이름을 변경 할 수 있습니다.
  //   </div>

  //   <div>
  //     <p> - 선택된 위젯은 테두리가 흰색으로 빛납니다. </p>
  //     <p> - 핸들 {Editor.icon({icon:'drag_indicator',size:15,lineHeight:18})} 부분을 드레그해서 위젯의 순서를 바꿀수 있습니다. </p>
  //     <p> - 오른쪽 클릭을 통해 위젯의 추가 설정 메뉴를 열 수 있습니다.</p>
  //   </div>
  //   <div style={{marginTop:'20px'}}/>
  // </article>
  // )