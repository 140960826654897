////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { Route, Link } from 'react-router-dom';
// [리덕스]스토어 연결
import store from "store";
// [프로바이더] 연결
import EditContext from 'service/context/EditContext' ; 
// SASS&CSS 연결
import "sass/viewPage.scss"


// 컴포넌트 연결

// 서비스 연결
import * as DeCoder from 'service/engine/DeCoder' ; 
import * as Screen from 'service/api/screen' ; 

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class ViewScreen extends Component {
    static contextType = EditContext;
    state = {

    }
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
    }
  


    render() { 
        return (
            <div id="ViewScreen">
                {this.context?.pageData.length != 0 ? DeCoder.run(this.context?.pageData[0],'broadcast',true,this.context):''}
            </div>
        );
    }
}
////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////

export default ViewScreen;
  