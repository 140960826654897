////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component, Fragment , useState, useEffect, useRef} from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { useHistory,useLocation } from 'react-router-dom';
// 라이브러리 
import { useResizeDetector } from 'react-resize-detector';// 컴포넌트의 사이즈 감지

// [리덕스]스토어 연결
import store from "store";
// [프로바이더]
import EditProvider from 'service/provider/EditProvider';

// SASS&CSS 연결
// import "sass/edit.scss"



// 이미지 연결
import logoW from 'assets/logoW.svg';

// 컴포넌트 연결

import EditContainer from 'components/Edit/editScreen/EditContainer'; // 사용자가 보는 편집화면
import LeftPanelArea from 'components/Edit/editPanel/preferences/LeftPanelArea'; // 왼쪽 편집 패널
import BottomPanelArea from 'components/Edit/editPanel/append/BottomPanelArea'; // 오른쪽 편집 패널
import ExtendPanelArea from 'components/Edit/editPanel/preferences/ExtendPanelArea'; // 왼쪽 편집 패널


import WidgetSelect from 'components/Edit/editNav/EditNavHeader'; // 탬플릿들이 있는 화면
import ContextMenu from 'components/Edit/contextMenu/ContextMenu'; // 컨텍스트 메뉴
import EditOverlay from 'components/Edit/overlay/EditOverlay';//오버레이

import AppendTemplate from 'components/Edit/mainScreen/AppendTemplate';//오버레이

// 서비스 연결

import * as Init from 'service/event/init' ; 
import * as Event from 'service/event/Actions' ; 
import * as overlapAPI from 'service/api/overlap' ; 
import { over } from 'lodash';
import { token } from 'service/event/Account'; 
import overlapContext from 'service/context/overlapContext';
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
function EditPage() {


    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////


    //set
    const history = useHistory();
    const location = useLocation();
  
    const uuID = location?.state?.uuid ?? null;
    const overlapID = location?.state?.id ?? null;
    const spaceID = location?.state?.spaceId ?? null;

 
    
    // state
    const [SizeDetector, setSizeDetector] = useState({});
    const [ScreenState, setScreenState] = useState('edit');
    const [OverlapToken, setOverlapToken] = useState(null);

    // rel 
    const PageRef = useRef(null); // 에딧 페이지 가장 최상단의 Ref (기본 이벤트 차단)
    const { width, height, ref } = useResizeDetector(); // 페이지의 사이즈 추적 

   ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////
    useEffect(() => {
        // 여기에 컴포넌트가 마운트되었을 때 실행할 코드를 작성합니다.
        Init.editPage()

        const handleWheelEvent = (event) => {
            event.preventDefault();
        };

        // 왼쪽클릭 방지
        document.addEventListener('contextmenu', (e) => {
          e.preventDefault();
        });
        document.addEventListener('click', (e) => {
            Event.contextMenuHide()
          });

        if (PageRef.current) {
          PageRef.current.addEventListener('wheel', handleWheelEvent, { passive: false });
        }

        ////////////////////////////////////////////////////////
        // 소켓 IO 체크 
        ////////////////////////////////////////////////////////

        // 오버랩 정보가 없는경우 대시보드로 돌아갑니다.
        if(uuID == null || overlapID == null){
            console.log('uuID',uuID,'overlapID',overlapID,'spaceID',spaceID);
            history.replace('/dashboard')
        }
        
        // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
        return () => {
          PageRef?.current?.removeEventListener('wheel', handleWheelEvent);
        };
      }, []);

    const objectDrop = (e) =>{
      e.preventDefault();
      e.stopPropagation();
    }


    /**
     * 패널의 사이즈를 에디터 페이지 State (SizeDetector) 에 기록합니다. 
     * @param {string} target 대상, EditContainer : 전체사이즈 , PreferencesPanel: 편집메뉴, UtilityPanel: 유틸메뉴, EditArea: 편집스크린
     * @param {*} width 
     * @param {*} height 
     */
    const panelSizeDetector = (target,width,height) =>{
      const allowed = ["EditContainer", "PreferencesPanel", "UtilityPanel", "EditArea"];
      if (allowed.includes(target)) {
        const Detector = {...SizeDetector}
        Detector[target] = {width,height}
        setSizeDetector(Detector);
      }
    }

    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////
    return (
        <Fragment>
                <style jsx>{`${EditPageStyle}`}</style>
                <EditProvider uuid={uuID} overlapID={overlapID} spaceId={spaceID} >
                  
                <div id = "EditPageV2" className='' ref={PageRef}
                    onDrop={(e)=> objectDrop(e)} 
                    onDragOver={(e)=> objectDrop(e)} 
                    onDragLeave={(e) => objectDrop(e)} 
                    onDragEnter={(e) => objectDrop(e)} 
                > 
                  <div id = "Layer_0" className='Layers'>
                  
                  <EditContainer sizeDetector={panelSizeDetector} panelSize={SizeDetector}/>
                  </div>

                  {/* <div id = "Layer_1" className='Layers'>

                  </div> */}

                  <div id = "Layer_2" className='Layers'>
                    
                  </div>

                  <div id = "Layer_3" className='Layers'>

                  </div>

                  <div id = "Layer_4" className='Layers'>
                  <ExtendPanelArea  panelSize={SizeDetector}/>
                  </div>
                    <BottomPanelArea sizeDetector={panelSizeDetector} panelSize={SizeDetector}/>
                    <LeftPanelArea sizeDetector={panelSizeDetector} panelSize={SizeDetector}/>
       
                    {/* <main id = "MainEdit" >
                        {this.mainEdit()}
                    </main>
                    <div id = "EditOption" >
                        <ContextMenu/>
                        <EditOverlay/>
                    </div> */}
                    <ContextMenu panelSize={SizeDetector}/>
                </div>
       
            </EditProvider>
        </Fragment>
    );

}

////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    mainScreen : state.mainScreen,
})

function mapDispatchToProps(dispatch){
    return {
        initOverlap : (overlapData) => dispatch({type:'initOverlap',overlap : overlapData}),
    }
}

  // export default EditPage;
export default connect(mapStateToProps,mapDispatchToProps)(EditPage);
  


  const EditPageStyle = `

  #EditPageV2 {
    @include fontSmoothing;
    display: flex;
    background-color: #737373;
    animation: backColorIn ease 1s;
    font-family: '프리텐다드SemiBold';
    color: #fff;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: relative;
  }

  .Layers{
    position: absolute;
  }

  #Layer_0{
    width: 100vw;
    height: 100vh;
    // background-color: red;

  }
  #Layer_1{
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5); /* 반투명 배경 */
    backdrop-filter: blur(5px); /* 블러 효과 */
  }
  #Layer_2{
    
  }
  #Layer_3{
    
  }
  #Layer_4{
    
  }
  @keyframes backColorIn {
    0% {
      background-color: #232323;
    }
    100% {
      background-color: #737373;
    }
  }
  `;