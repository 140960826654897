/**
 * 유틸리티 함수 모음
 * 
 * 목차:
 * 
 * - browserCheck(): 브라우저 종류를 감지합니다.
 * 
 * - widthCalculator(value): % 또는 픽셀 값을 기반으로 너비를 계산합니다.
 * 
 * - heightCalculator(value): % 또는 픽셀 값을 기반으로 높이를 계산합니다.
 * 
 * - sizeCalculator(value, type): 값과 타입에 따라 사이즈(너비 또는 높이)를 계산합니다.
 * 
 * - deepCopy(value): 주어진 값을 깊은 복사합니다.
 * 
 * - throttle(func, time=16): 함수의 실행 빈도를 제한합니다.
 * 
 * - arrayEqual(arr1, arr2): 두 배열을 비교합니다.
 * 
 * - elementEqual(element1, element2): lodash의 isEqual을 사용하여 두 요소를 깊게 비교합니다.
 * 
 * - imageCompressor(ImageURL, callBack, quality=0.6): Compressor.js를 사용하여 이미지를 압축합니다.
 * 
 * - compressImage(options): 지정된 옵션으로 이미지를 비동기적으로 압축합니다.
 * 
 * - getImageSize(imageUrl): 이미지의 크기를 가져옵니다.
 * 
 * - fileMIME(fileType): 파일의 MIME 타입을 기반으로 파일 종류를 판별합니다.
 * 
 * - fileSize(bytes): 바이트를 사람이 읽기 쉬운 문자열로 변환합니다.
 * 
 * - random(option={}): 랜덤 숫자를 생성합니다.
 * 
 * - mergeDeep(target, reference): 두 객체를 깊게 병합합니다.
 * 
 * - isObject(item): 값이 객체인지 확인합니다.
 * 
 * - setNestedObject(widget, keys, value): 객체에서 중첩된 값을 설정합니다.
 * 
 * - uuidMake(): UUID를 생성합니다.
 * 
 * - replaceGlobal(target, option='value'): 문자열에서 글로벌 변수를 처리합니다.
 * 
 * - hexToDecimal(hex): 16진수를 10진수로 변환합니다.
 * 
 * - decimalToHex(decimal): 10진수를 16진수로 변환합니다.
 * 
 * - colorDiff(hex, redDiff, greenDiff, blueDiff): 색상의 RGB 값을 조정합니다.
 * 
 * - fontCalculator(elementHeight, elementWidth, minFontSize, maxFontSize): 최적의 폰트 크기를 계산합니다.
 */

import store from "store";
import { produce } from 'immer'
import _ from 'lodash'

// 이미지 용량 정리 라이브러리
import axios from 'axios';
import Compressor from 'compressorjs';

/**
 * 사용자 에이전트 문자열을 기반으로 브라우저 종류를 감지합니다.
 * @returns {string} 브라우저 이름 ('chrome', 'safari', 'firefox' 등)
 */
export function browserCheck() {
  let agent = navigator.userAgent.toLowerCase();
  if (agent.indexOf("chrome") != -1) {
    return "chrome";
  } else if (agent.indexOf("safari") != -1) {
    return "safari";
  } else if (agent.indexOf("firefox") != -1) {
    return "firefox";
  } else if (agent.indexOf("chrome")) {
    // 추가 조건을 여기에 추가할 수 있습니다.
  } else if (agent.indexOf("chrome")) {
    // 추가 조건을 여기에 추가할 수 있습니다.
  }
}

/**
 * % 또는 픽셀 값을 기반으로 너비를 계산합니다.
 * 현재 화면 너비를 기준으로 % 값을 픽셀로 변환합니다.
 * @param {string|number} value - 너비 값 ('50%' 또는 픽셀 값)
 * @returns {number|string} 계산된 픽셀 값 또는 'auto'
 */
export function widthCalculator(value) {
  return sizeCalculator(value, 'width');
}

/**
 * % 또는 픽셀 값을 기반으로 높이를 계산합니다.
 * 현재 화면 높이를 기준으로 % 값을 픽셀로 변환합니다.
 * @param {string|number} value - 높이 값 ('50%' 또는 픽셀 값)
 * @returns {number|string} 계산된 픽셀 값 또는 'auto'
 */
export function heightCalculator(value) {
  return sizeCalculator(value, 'height');
}

/**
 * 값과 타입에 따라 사이즈(너비 또는 높이)를 계산합니다.
 * % 값을 현재 화면 크기에 맞게 픽셀로 변환합니다.
 * @param {string|number} value - 사이즈 값 ('50%' 또는 픽셀 값)
 * @param {string} type - 계산할 사이즈 타입 ('width' 또는 'height')
 * @returns {number|string|null} 계산된 픽셀 값, 'auto', 또는 잘못된 타입의 경우 null
 */
export function sizeCalculator(value, type) {
  let result;
  if (String(value).indexOf('%') != -1) {
    const state = store.getState(); // 현재 상태를 가져옵니다.
    const { broadCast } = state; // 편의를 위해 구조 분해 할당
    switch (type) {
      case 'width':
        return (broadCast.size.width / 100 * Number(value.substring(0, value.indexOf('%'))));
      case 'height':
        return (broadCast.size.height / 100 * Number(value.substring(0, value.indexOf('%'))));
      default:
        return null;
    }
  } else if (value == 'auto') {
    return 'auto';
  } else {
    return value;
  }
}

/**
 * 주어진 값을 깊은 복사합니다.
 * 상태 내의 객체를 복사할 때 유용합니다.
 * @param {object} value - 복사할 값
 * @returns {object} 깊은 복사된 값
 */
export function deepCopy(value) {
  const copy = _.cloneDeep(value);
  return copy;
}

/**
 * 함수의 실행 빈도를 제한하는 쓰로틀링 함수를 생성합니다.
 * @param {function} func - 제한할 함수
 * @param {number} [time=16] - 호출을 제한할 밀리초 단위 시간 (기본값 16ms, 약 60회/초)
 * @returns {function} 쓰로틀링된 함수
 */
export function throttle(func, time = 16) {
  const throttledFunc = _.throttle(func, time);
  return throttledFunc;
}

/**
 * 두 배열을 비교합니다.
 * 참고: 배열에 객체가 포함된 경우 참조만 비교하고 내용은 비교하지 않습니다.
 * @param {Array} arr1 - 비교할 첫 번째 배열
 * @param {Array} arr2 - 비교할 두 번째 배열
 * @returns {boolean} 배열이 같으면 true, 다르면 false
 */
export function arrayEqual(arr1, arr2) {
  // 길이가 다르면 배열이 다릅니다.
  if (arr1.length !== arr2.length) {
    return false;
  }

  // 각 요소를 비교합니다.
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }

  // 모든 요소가 같으면 배열이 같습니다.
  return true;
}

/**
 * lodash의 isEqual을 사용하여 두 요소를 깊게 비교합니다.
 * @param {*} element1 - 비교할 첫 번째 요소
 * @param {*} element2 - 비교할 두 번째 요소
 * @returns {boolean} 요소가 같으면 true, 다르면 false
 */
export function elementEqual(element1, element2) {
  return _.isEqual(element1, element2);
}

/**
 * Compressor.js를 사용하여 이미지를 압축하고 콜백 함수에 결과를 전달합니다.
 * @param {File|Blob} ImageURL - 압축할 이미지 파일 또는 Blob
 * @param {function} callBack - 압축된 이미지를 받을 콜백 함수
 * @param {number} [quality=0.6] - 압축 품질 (0 ~ 1 사이의 값)
 */
export function imageCompressor(ImageURL, callBack, quality = 0.6) {
  const state = store.getState(); // 현재 상태를 가져옵니다.
  const { broadCast } = state; // 편의를 위해 구조 분해 할당

  new Compressor(ImageURL, {
    quality: quality,
    maxWidth: broadCast?.size?.width ? broadCast?.size?.width : 1920,
    maxHeight: broadCast?.size?.height ? broadCast?.size?.height : 1080,
    success(result) {
      console.log(result, '결과');
      callBack(result);
    },
    error(err) {
      console.log(err, '에러');
      // 필요한 경우 에러 처리
    },
  });
}

/**
 * 지정된 옵션으로 이미지를 비동기적으로 압축합니다.
 * @param {Object} options - 옵션 객체
 * @param {File} options.file - 압축할 이미지 파일
 * @param {number} options.quality - 압축 품질 (0 ~ 1 사이의 값)
 * @param {number} [options.maxWidth=1920] - 이미지의 최대 너비 (픽셀)
 * @param {number} [options.maxHeight=1080] - 이미지의 최대 높이 (픽셀)
 * @returns {Promise<File>} 압축된 이미지 파일을 반환하는 Promise
 */
export async function compressImage({ file, quality = 1, maxWidth = 1920, maxHeight = 1080 }) {
  return new Promise((resolve, reject) => {
    new Compressor(file, {
      quality,
      maxWidth,
      maxHeight,
      success(result) {
        resolve(result);
      },
      error(err) {
        reject(err);
      },
    });
  });
}

/**
 * 이미지의 크기(너비와 높이)를 가져옵니다.
 * @param {string} imageUrl - 이미지의 URL
 * @returns {Promise<{width: number, height: number}>} 너비와 높이를 포함하는 객체를 반환하는 Promise
 */
export async function getImageSize(imageUrl) {
  let img = new Image();
  img.src = imageUrl;

  let dumiWidth = await img.width;
  let dumiHeight = await img.height;
  // console.log(img.width,img.height)
  let width = await img.width ? await img.width : 200;
  let height = await img.height ? await img.height : 200;
  // console.log(imageWidth2,imageHeight2)
  return { width, height };
}

/**
 * 파일의 MIME 타입을 기반으로 파일 종류를 판별합니다.
 * 지원되지 않는 파일의 경우 null을 반환합니다.
 * @param {string} fileType - 파일의 MIME 타입
 * @returns {string|null} 파일 종류 ('image', 'video', 'pdf') 또는 null
 */
export function fileMIME(fileType) {
  const imageTypes = [
    "image/png",
    "image/jpeg",
    "image/gif",
    "image/bmp",
    "image/webp",
    "image/svg+xml"
  ];

  const videoTypes = [
    "video/mp4",
    "video/webm",
    "video/ogg",
    "video/quicktime",
    "video/x-msvideo",
    "video/x-matroska",
    "video/mpeg"
  ];

  const pdfType = "application/pdf";

  if (imageTypes.includes(fileType)) {
    return "image";
  } else if (videoTypes.includes(fileType)) {
    return "video";
  } else if (fileType === pdfType) {
    return "pdf";
  } else {
    return null;
  }
}

/**
 * 파일 크기를 사람이 읽기 쉬운 문자열로 변환합니다.
 * @param {number} bytes - 파일 크기 (바이트 단위)
 * @returns {string} 적절한 단위의 파일 크기 문자열 (bytes, KB, MB 또는 GB)
 */
export function fileSize(bytes) {
  const ONE_KB = 1024; // 1 kB = 1024 bytes
  const ONE_MB = ONE_KB * 1024; // 1 MB = 1024 kB
  const ONE_GB = ONE_MB * 1024; // 1 GB = 1024 MB

  if (bytes >= ONE_GB) {
    // GB 단위로 변환하고 소수점 둘째 자리까지 표시
    const sizeInGB = (bytes / ONE_GB).toFixed(2);
    return `${sizeInGB} GB`;
  } else if (bytes >= ONE_MB) {
    // MB 단위로 변환하고 소수점 둘째 자리까지 표시
    const sizeInMB = (bytes / ONE_MB).toFixed(2);
    return `${sizeInMB} MB`;
  } else if (bytes >= ONE_KB) {
    // KB 단위로 변환하고 소수점 둘째 자리까지 표시
    const sizeInKB = (bytes / ONE_KB).toFixed(2);
    return `${sizeInKB} KB`;
  } else {
    // 바이트 단위로 표시
    return `${bytes} bytes`;
  }
}

/**
 * 랜덤 숫자를 생성합니다.
 * @param {Object} [option={}] - 랜덤 숫자 생성 옵션
 * @param {number} [option.min=0] - 최소값 (포함)
 * @param {number} [option.max=1] - 최대값 (float의 경우 제외, int의 경우 포함)
 * @param {string} [option.type='float'] - 랜덤 숫자 타입 ('int' 또는 'float')
 * @returns {number} 옵션에 따라 생성된 랜덤 숫자
 */
export function random(option = {}) {
  let min = option?.min != undefined ? option.min : 0;
  let max = option?.max != undefined ? option.max : 1;
  let type = option?.type != undefined ? option.type : 'float';

  switch (type) {
    case 'int':
      return Math.floor(Math.random() * (max + 0.99999 - min) + min);
    case 'float':
      return Math.random() * (max - min) + min;
    default:
      return Math.random() * (max - min) + min;
  }
}

/**
 * 두 객체를 깊게 병합합니다.
 * @param {Object} target - 병합할 대상 객체
 * @param {Object} reference - 기본 값을 제공하는 참조 객체
 * @returns {Object} 병합된 객체
 */
export const mergeDeep = (target, reference) => {
  const output = { ...reference };
  // target 객체의 각 프로퍼티를 순회합니다.
  Object.keys(target).forEach(key => {
    // target과 reference의 값이 모두 객체인 경우 재귀적으로 병합합니다.
    if (isObject(reference[key]) && isObject(target[key])) {
      output[key] = mergeDeep(target[key], reference[key]);
    } else {
      // 그렇지 않으면 target의 값을 사용합니다.
      output[key] = target[key];
    }
  });
  return output;
};

/**
 * 주어진 값이 객체인지 확인합니다.
 * @param {*} item - 확인할 값
 * @returns {boolean} 값이 객체이고 배열이 아니면 true, 그렇지 않으면 false
 */
function isObject(item) {
  return (item && typeof item === 'object' && !Array.isArray(item));
}

/**
 * 객체에서 중첩된 값을 설정합니다.
 * @param {Object} widget - 수정할 객체
 * @param {Array<string>} keys - 설정할 값의 경로를 나타내는 키 배열
 * @param {*} value - 설정할 값
 */
export function setNestedObject(widget, keys, value) {
  keys.reduce((acc, key, index) => {
    if (index === keys.length - 1) {
      acc[key] = value;
    } else {
      if (!acc[key]) acc[key] = {};
    }
    return acc[key];
  }, widget);
}

/**
 * UUID (버전 4)를 16진수 문자열로 생성합니다.
 * @returns {string} UUID 문자열
 */
export function uuidMake() {
  function s4() {
    return ((1 + Math.random()) * 0x10000 | 0).toString(16).substring(1);
  }
  return s4() + s4() + s4() + s4() + s4() + s4() + s4() + s4();
}

/**
 * 문자열에서 글로벌 변수를 처리하고 키 또는 값을 추출합니다.
 * @param {string} target - 글로벌 변수를 나타내는 문자열 (예: 'global_key_value')
 * @param {string} [option='value'] - 추출할 부분 ('value', 'key', 'all')
 * @returns {string|Object} 추출된 값, 키 또는 둘 다 포함하는 객체
 */
export const replaceGlobal = (target, option = 'value') => {
  if (target?.indexOf('global_') === 0) {
    let parts = target.split('_'); // 언더스코어로 문자열을 분할
    switch (option) {
      case 'value':
        return parts[parts.length - 1]; // 마지막 요소를 반환
      case 'key':
        return parts[1];
      case 'all':
        return { 'key': parts[1], 'value': parts[parts.length - 1] };
      default:
        break;
    }
  } else {
    console.error(`Utility : \n ⛔️ 글로벌 value 가 아닙니다. :  ${target} `);
  }
};

/**
 * 16진수 문자열을 10진수 숫자로 변환합니다.
 * @param {string} hex - 2자리 이상의 16진수 문자열
 * @returns {number} 10진수 숫자
 */
function hexToDecimal(hex) {
  return parseInt(hex, 16);
}

/**
 * 10진수 숫자를 16진수 문자열로 변환합니다.
 * @param {number} decimal - 10진수 숫자
 * @returns {string} 2자리 이상의 16진수 문자열
 */
function decimalToHex(decimal) {
  return decimal.toString(16).padStart(2, '0');
}

/**
 * 색상의 RGB 값을 지정된 차이만큼 조정합니다.
 * @param {string} hex - 원본 색상 (16진수 형식)
 * @param {number} redDiff - 빨간색 요소에 적용할 차이
 * @param {number} greenDiff - 초록색 요소에 적용할 차이
 * @param {number} blueDiff - 파란색 요소에 적용할 차이
 * @returns {string} 조정된 색상 (16진수 형식)
 */
export const colorDiff = (hex, redDiff, greenDiff, blueDiff) => {
  // '#' 제거 및 RGB 부분 분할
  const hexClean = hex.startsWith('#') ? hex.slice(1) : hex;
  const rHex = hexClean.slice(0, 2);
  const gHex = hexClean.slice(2, 4);
  const bHex = hexClean.slice(4, 6);

  // 16진수를 10진수로 변환
  const rDec = hexToDecimal(rHex);
  const gDec = hexToDecimal(gHex);
  const bDec = hexToDecimal(bHex);

  // 차이를 적용하고 범위를 0~255로 제한
  const newR = Math.max(0, Math.min(255, rDec + redDiff));
  const newG = Math.max(0, Math.min(255, gDec + greenDiff));
  const newB = Math.max(0, Math.min(255, bDec + blueDiff));

  // 다시 16진수로 변환
  const newRHex = decimalToHex(newR);
  const newGHex = decimalToHex(newG);
  const newBHex = decimalToHex(newB);

  // 조정된 색상 반환
  return `#${newRHex}${newGHex}${newBHex}`;
};

/**
 * 엘리먼트의 높이와 (선택적으로) 가로 크기에 따라 줄바꿈 없이 가장 크게 표시될 수 있는 폰트 크기를 계산합니다.
 * @param {number} elementHeight - 엘리먼트의 높이 (픽셀 단위)
 * @param {number} [elementWidth=null] - 엘리먼트의 가로 크기 (픽셀 단위, 선택 사항)
 * @param {number} [minFontSize=10] - 폰트 크기의 최솟값 (픽셀 단위)
 * @param {number} [maxFontSize=100] - 폰트 크기의 최댓값 (픽셀 단위)
 * @returns {number} 계산된 최적의 폰트 크기 (픽셀 단위)
 */
export const fontCalculator = (elementHeight, elementWidth = null, minFontSize = 10, maxFontSize = 100) => {
  // 높이를 기준으로 기본 폰트 크기 계산
  let fontSize = elementHeight * 0.75;

  // 가로 크기가 제공된 경우 줄바꿈 없이 가로에 맞도록 폰트 크기 조정
  if (elementWidth) {
    // 가로 길이를 기준으로 최대 폰트 크기 계산
    const maxWidthFontSize = elementWidth / 10; // 필요에 따라 이 비율을 조정하세요
    fontSize = Math.min(fontSize, maxWidthFontSize);
  }

  // 폰트 크기를 최소 및 최대 값 사이로 제한
  fontSize = Math.max(minFontSize, Math.min(fontSize, maxFontSize));

  // 계산된 폰트 크기 반환
  return fontSize;
};