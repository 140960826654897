////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { useState, useEffect, useContext, Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { SortableContainer, SortableElement,ReactSortable } from 'react-sortablejs';
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widgetEdit.scss"

// 컴포넌트 연결
import VarTeam from 'components/Edit/editVariable/varTeam/VarTeam'; // 팀 변수

import PositionModule from '../editModule/PositionModule'; // 위젯의 사이즈를 조정 하는 모듈입니다.
import ErrorModule from '../editModule/ErrorModule'; // 잘못된 위젯의 값이 있을때 편집기를 대체합니다.
import GuideBalloon from 'components/common/form/GuideBalloon'// 가이드 벌룬


import ScoreboardModule from '../editModule/ScoreboardModule'; // 스코어 보드용 팀 묘듈
import SentenceModule from '../editModule/SentenceModule'; // 텍스트 모듈

import TextSizeModule from '../editModule/TextSizeModule'; // 텍스트 크기 모듈
import FontModule from '../editModule/FontModule'; // 폰트 모듈
import TextModule from '../editModule/TextModule'; // 폰트 모듈
import ColorModule from '../editModule/ColorModule'; // 색상 모듈
import SwitchModule from '../editModule/SwitchModule'; // 체크박스 모듈
import NumberModule from '../editModule/NumberModule'; // 숫자 모듈

// 서비스 연결
import * as Utility from 'service/other/Utility'; 
import * as Editor from 'service/edit/EditService'; 
import * as ED from 'service/engine/decoder/EditorDecoder'; 
import { dragTransparent } from 'service/event/common' ; 
// 이미지 연결

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////


/** [에디터] 텍스트 편집 툴 입니다.
 * (대상위젯, 편집펑션, 편집할 객체 키, 타이틀 ,옵션)
* @param {widget} target : 편집할 대상 위젯
* @param {function} modify : 편집용 함수
* @param {{
* style: { x : number, y : number }
* }} keyName : 해당 에디터가 수정하는 값 입니다. value : {'textValue', 수정할 대상값,} 
* @param {{
* editTitle: string,textValue: string
* }} title : 편집기에 표시될 제목들 입니다.
* @param {{
* fixToggle : boolean 
* openToggle : boolean
* }} option : 추가 옵션입니다. fixToggle : 토글을 고정합니다. openToggle : 필드의 기본 상태를 열림으로 봅니다.
*/


function ScoreboardEditForm({target,modify,keyName,title,option}) {

    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////

    // set
    const uuid = target.uuid
    const {style={},meta={},size={},value={},position={},display={},preset=null} = keyName
    // state
    const [Toggle, setToggle] = useState(option?.openToggle==undefined?true:option?.openToggle);
    const [OpenForm, setOpenForm] = useState(option?.openForm==undefined?true:option?.openForm);

    const [ScoreboardMode, setScoreboardMode] = useState('list');

    // rel 

    ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////
    useEffect(() => {

        // 여기에 컴포넌트가 마운트되었을 때 실행할 코드를 작성합니다.

        return () => {
        // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
        };
      }, []);

    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////

       // 해당 에디터에서 편집이 가능한 값 목록
       const {infoFont,infoSize,infoColor,teamFont,matchInfo} = keyName ;


    return (
    <Fragment>
        <style jsx>{`${FormStyle}`}</style>
        <section className='ScoreboardEditForm'>

            {Editor.editorDetail('스코어보드','scoreboard',OpenForm,setOpenForm,Toggle)}
            <div className={OpenForm?'editContainer containerOpen':'editContainer containerClose'}>
            {Editor.WidgetEditSubhead({text:'공통 설정',icon:"image_search"})}
                <article className="EditPart">
                {matchInfo? <TextModule value={ED.getValue(target,matchInfo)} fontFamily={ED.getValue(target,infoFont)} title={matchInfo?.title??"매치 정보"} type = {matchInfo.key} placeholder="여기에 텍스트를 입력해주세요"  change={(type,value)=>modify(matchInfo.category,matchInfo.key,value,uuid)}/> : ''}
                {infoSize? <TextSizeModule  value={ED.getValue(target,infoSize)} title={infoSize?.title??"매치 정보 크기"} type={infoSize.key} change={(type,value)=>modify(infoSize.category,infoSize.key,value,uuid)}/>: ''}
                {infoColor?<ColorModule  value={ED.getValue(target,infoColor)} title={infoColor?.title??"매치 정보 색상"} type={infoColor.key} change={(type,value)=>modify(infoColor.category,infoColor.key,value,uuid)}/>: ''}
                {infoFont? <FontModule value={ED.getValue(target,infoFont)} title={infoFont?.title??"매치 정보 폰트"} type={infoFont.key} change={(type,value)=>modify(infoFont.category,infoFont.key,value,uuid)}/>: ''}
                {teamFont? <FontModule value={ED.getValue(target,teamFont)} title={teamFont?.title??"팀 폰트"} type={teamFont.key} change={(type,value)=>modify(teamFont.category,teamFont.key,value,uuid)}/>: ''}
                </article>
            </div>
        </section>
    </Fragment>
    );

}


export default ScoreboardEditForm;


const FormStyle = `

.ScoreboardEditForm {

}

.ScoreboardEditForm .teamEdit{

}
.ScoreboardEditForm  .scoreboardNav{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ScoreboardEditForm  .scoreboardNav .scoreboardButtons{
    display: flex;

}



.ScoreboardModule {
    font-family: "프리텐다드SemiBold", sans-serif;
    border-radius: 5px;
    background: #444444;
    box-shadow: 3.5px 3.5px 10px #252525, -3.5px -3.5px 10px #636363;

    margin : 20px 0px;
    padding : 0px;
    overflow :hidden;
    display: flex;
    flex-direction: column;
    position: relative;
}

.defaultField{
    display: flex;

}
  @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    
.ScoreboardModule .ScoreboardNoti{
    position: absolute;
    display : flex;
    z-index: 1;
    height: 100%;
    width: 100%;
    background-color: #00000040;
    backdrop-filter: blur(2px);
    justify-content: center;
    align-items: center;
    animation: fadeIn 1s ease forwards; 
}

.ScoreboardModule .ScoreboardNoti .NotiInfo{
    display : flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.ScoreboardModule .ScoreboardNoti .NotiInfo h3{
    background-color: #00000090;
    padding : 10px ; 
    margin : 10px 0px;
    border-radius: 5px;
    display : flex;
    line-height : 30px;
}

.ScoreboardModule .ScoreboardNoti .NotiInfo .deleteTeam{
    display : flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border : 1px solid rgba(255,0,0,1);
    padding : 10px ; 
    margin : 10px 0px;
    border-radius: 5px;
}

.ScoreboardModule .handle{
    display : flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    width: 20px;
    border-radius: 0px 0px 0px 5px;
}
.ScoreboardModule .TeamImage{
    background: #222;
    height: 50px;
    width: 50px;
    padding : 7.5px;
    margin : 7.5px;
    border-radius: 5px;

}

.ScoreboardModule .TeamImage img{
    width: 100%;
    height: 100%;
    object-fit: scale-down;

}

.ScoreboardModule .TeamColor input[type="color"] {
    /* 크기를 20px로 설정 */
    width: 20px;
    height: 20px;

    /* 원형 모양으로 만듬 */
    border-radius: 50%;

    /* 경계선 및 패딩 제거 */
    border: 2px solid rgb(114, 114, 114);
    padding: 0;

    /* 커서 모양 변경 */
    cursor: pointer;

    /* 컬러 피커의 화살표 제거 */
    -webkit-appearance: none;

    /* 내부 요소의 크기 조정 */
    overflow: hidden;
}

/* 내부 아이콘 숨기기 및 크기 조정을 위한 추가 스타일 */
.ScoreboardModule .TeamColor input[type="color"]::-webkit-color-swatch-wrapper {
    padding: 0;
    width: 20px;
    height: 20px;
}

.ScoreboardModule .TeamColor input[type="color"]::-webkit-color-swatch {
    border: none;
    border-radius: 50%;
}

.ScoreboardModule .TeamInfo{
    display: flex;
    flex-direction: column;
    flex : 1;
    margin : 0px ; 
    justify-content: space-between;
    
}

.ScoreboardModule .TeamName{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
    
}

.BanPickModule .BanPickTimer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size : 18px;
    margin : 5px 7.5px;
}

.BanPickModule .editRow{
    margin : 5px 7.5px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.BanPickModule .BanPickTimerInput {
    font-size: 20px;
    height: 20px;
    width: 50px;
    text-align: right;
    background-color: #222;
    border-radius: 2px;
    color: #fff;
    border: 1px solid #727272;
}


.ScoreboardModule .TeamPoint{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size : 18px;
    margin : 5px 7.5px;
}

.ScoreboardModule .editRow{
    margin : 5px 7.5px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.ScoreboardModule .TeamPointInput {
    font-size: 20px;
    height: 20px;
    width: 50px;
    text-align: right;
    background-color: #222;
    border-radius: 2px;
    color: #fff;
    border: 1px solid #727272;
}

.ScoreboardModule .TeamColor {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size : 18px;
    margin : 5px 7.5px;
}


.ScoreboardModule .TeamNameInput {
    background-color: #444444;
    padding: 9.5px 0px 2px 0px;
    border-radius: 2px;
    color: #fff;
    border: none;
    font-family: '프리텐다드SemiBold';
    width : 150px;
    font-size : 20px;
    margin-left : 7.5px;
}

.ScoreboardModule .TeamNameInput::placeholder {
    color: #BBB;

}

.ScoreboardModule .TeamLogoInfo{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.ScoreboardModule .TeamLogoInput{
    font-size: 10px;
    height: 20px;
    width: 150px;
    text-align: right;
    background-color: #222;
    border-radius: 2px;
    color: #fff;
    border: 1px solid #727272;
    text-align: start;
}
`;



const TempScoreboardData = [
    {
        teamName : 'team1',
        teamColor : '#ff0000',
        teamLogo : '',
        score :  0,
        subScore : 0, 
    },    {
        teamName : 'team2',
        teamColor : '#ffff00',
        teamLogo : '',
        score :  1,
        subScore : 1, 
    }
] 