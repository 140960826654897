////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, {useState, useEffect, useRef } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { Route, Link } from 'react-router-dom';
import { useHistory } from "react-router";
// 라이브러리 
import { useGoogleLogin } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google'// 구글 로그인
// 설정 파일
import * as config from 'config/OverlapConfig'

import { withRouter } from 'react-router-dom';

import * as apiAccount from 'service/api/apiAccount'

// [컴포넌트] 위젯 
import ViewInfo from "components/Lobby/space/module/ViewInfo"
import AppendFile from 'components/Edit/editPanel/append/AppendFile';
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import 'sass/login.scss'

// 이미지 연결
import logoW from 'assets/logoW.svg';
import youtube from 'assets/main/lobby/social/youtube.png';
import twitch from 'assets/main/lobby/social/twitch.png';
import afreeca from 'assets/main/lobby/social/afreeca.png';
import facebook from 'assets/main/lobby/social/facebook.png';
import naver from 'assets/main/lobby/social/naver.png';


// 서비스 연결
import * as fileAPI from 'service/api/file' ; 
import * as Editor from 'service/edit/EditService'; 
import * as Utility from 'service/other/Utility';
import * as view from 'service/api/view';
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

function SpaceModuleFileList({space,fileList,getFileList,loading}) {
    // const todos = useSelector(state => state.todos)
    // const dispatch = useDispatch()
    // const addTodo = text => dispatch(addTodo(text))


    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////
    // state
    const [SelectFile, setSelectFile] = useState(null); 
    const [FileFilter, setFileFilter] = useState(null); 
    // rel 
    ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////

    //   useEffect(() => {

    //     return () => {
    //     };
    //   },[]);

    //   useEffect(() => {
    //     console.log('Component did mount or update');
    //     setTimeout(function() {
    //         getFileList()
    //       }, 1000);
    //     // Cleanup function (optional)
    //     return () => {
    //       console.log('Component will unmount');
    //     };
    //   },[props.mode]);

    ////////////////////////////////////////////////////////
    // Fuction
    ////////////////////////////////////////////////////////

    const fileUpload = async (files) => {
        console.log('space',space)
        if (files) {
            const file = files?.[0];
            // 파일 타입 검사
            if(file?.type){
              const Type = Utility.fileMIME(file.type)
              if (Type == null) {
                  alert('업로드가 불가능한 파일입니다.');
                  return;
              }
              try {
                  await fileAPI.upload(space.id, file);
                  getFileList();
              } catch (error) {
                  console.error('파일 업로드 실패:', error);
              }
            }
        }
      };
      

    const fileContent = () =>{


        const Files = FileFilter ? fileList.filter(file => (categorizeFile(file?.file_type) == FileFilter)) : fileList;

        if(loading == false){
            return(
                <div className='veiwLoading'>
  
                </div>
            )
        }
        if(loading == true && Files?.length == 0){
            return(
                <div className='undefinedView'>
                    <p>라이브러리에 파일이 없습니다.</p>
                    <p>파일 가져오기 버튼을 눌러서 파일을 추가해주세요</p>
                </div>
            )
        }
        if(loading == true && Files?.length > 0){

            return(
                <div className='FileListWrap'>
                    {Files.map(file => (
                        <AppendFile key={file.uuid} fileData={file} select={(file)=>{setSelectFile(file)}}/>
                    ))}
                </div>
            )
        }

    }

    const fileDetail = () =>{

        return(
            <section className='FileExplorer'>
                
                <nav className='topExplorerNav'>
                    <div onClick={()=>setSelectFile(null)}>
                    {Editor.icon({icon:'close',size:20,lineHeight:30})}
                    </div>
                </nav>
                <div className='ImageExplorer'> 
                    <img  className='TargetView' src={SelectFile?.file_path}/>
                </div>
                <nav className='bottomExplorerNav'>
                    <div className='FileName'>
                        {SelectFile?.file_name}
                    </div>
                    <div className='FileInfo'>
                    <span>
                        {Editor.icon({icon:'fullscreen',size:20,lineHeight:30})}
                        <div style={{margin:1}}/>
                        {SelectFile?.file_option?.resolution.width}px * {SelectFile?.file_option?.resolution.height}px
                    </span>
                    <span>
                        {Editor.icon({icon:'save',size:20,lineHeight:30})}
                        <div style={{margin:1}}/>
                        {Utility.fileSize(SelectFile?.file_size)}
                    </span>
                    </div>

                </nav>

            </section>
        )

    }
    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////
    return (
        <div className='SpaceModuleContainer ViewListContainer' >
        <style jsx>{`${SpaceModuleStyle}${ContainerStyle}`}</style>
        <div className='spaceField'>
        <nav id='FileNav' >
            <div className='categoryList'>
                    <div className='categoryContainer' onClick={()=>setFileFilter(null)}>
                        <button 
                        className={FileFilter == null ?'selectCategory category':'category'}>
                            {Editor.icon({icon:'folder_open',size:20,lineHeight:30})}
                            &nbsp;
                            모든파일
                        </button>
                    </div>
                    <div className='categoryContainer' onClick={()=>setFileFilter('image')}>
                    <button className={FileFilter == 'image' ?'selectCategory category':'category'}>
                        {Editor.icon({icon:'photo_library',size:20,lineHeight:30})}
                        &nbsp;
                        이미지
                    </button>
                    </div>
                    <div className='categoryContainer' onClick={()=>setFileFilter('video')}>
                    <button className={FileFilter == 'video' ?'selectCategory category':'category'}>
                        {Editor.icon({icon:'video_library',size:20,lineHeight:30})}
                        &nbsp;
                        동영상
                    </button>
                    </div>
                    <div className='categoryContainer' onClick={()=>setFileFilter('document')}>
                    <button className={FileFilter == 'document' ?'selectCategory category':'category'} >
                        {Editor.icon({icon:'picture_as_pdf',size:20,lineHeight:30})}
                        &nbsp;
                        문서
                    </button>
                    </div>
                    {/* <button className='category'>
                        {Editor.icon({icon:'mic',size:20,lineHeight:30})}
                        &nbsp;
                        스트리밍
                    </button> */}
            </div>


            <div className="upload" style={{ display :'flex'}}> 
                <label htmlFor="file-upload" className="fileUploadButton" style={{width: 130, height: 30,display:'flex',margin:0,padding:0,justifyContent:'center'}}>
                {Editor.icon({icon:'drive_folder_upload',size:25,lineHeight:30})}
                <p style={{fontSize:15,marginLeft:10}}>
                파일 가져오기
                </p>
                </label>
                <input style={{display:'none',}} id="file-upload" type="file" onChange={(e)=>{fileUpload(e?.target?.files)}} />
                {/* <button>업로드</button> */}
                {/* {selectedFile && <p>선택된 파일: {selectedFile.name}</p>} */}
            </div>

            
        </nav>

            <div  id='FileContainer'
                onDrop={(e)=> {
                    e.preventDefault();
                    fileUpload(Array.from(e.dataTransfer.files));
                  }}
                  onDragOver={(e) => {
                    e.preventDefault();
                  }}
            >
                {fileContent()}
                {SelectFile?fileDetail():null}
                {/* {fileDetail()} */}
            </div>
        </div>
    </div>
    );
  }

  export default SpaceModuleFileList;
////////////////////////////////////////////////////////
// css 부분
////////////////////////////////////////////////////////
const SpaceModuleStyle = `
    .ViewListContainer {

    }

`;

const ContainerStyle = `
   #FileNav{
        height: 50px;
        width: calc(100% - 40px);
        padding: 0px 5px;
        line-height: 40px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        box-shadow: 1px 1px 5px #1c1c1c;
        background-color: #444;
        margin: 20px 20px 0px 20px ;
        border-radius: 50px;
        z-index: 2;
        position: absolute;
    }
    #OverlapMain{
        display: flex;
        width: 100%; 
        height: 100%;
    }
    #FileNav .upload {
        font-family: '프리텐다드ExtraBold';
        color: white;
        border: none;
        border-radius: 5px;
        background: #222;
        font-size: 15px;
        height: 30px;
        padding: 0px 10px;
        margin: 10px;
        margin-right: 10px;
        line-height: 30px;
        display: flex;
        transition: background 0.15s;
        background: orange;
        border-radius: 50px;
        transition: transform 0.1s ease-out, opacity 0.1s ease-out;
    }


    #FileNav .upload:active{
        transform: scale(0.80);
    }

    #FileNav .fileUploadButton{
        display: flex;
        font-size: 15px;
        height: 30px;
        padding: 0px 10px;
        margin: 10px;
        margin-right: 10px;
        line-height: 30px;
    }
    #FileNav .backButton {
        font-family: '프리텐다드ExtraBold';
        color: white;
        border: none;
        border-radius: 5px;
        background: #222;
        font-size: 15px;
        height: 30px;
        padding: 0px 10px;
        margin: 10px;
        margin-right: 10px;
        line-height: 30px;
        display: flex;
        transition: background 0.15s;
        border-radius: 50px;
    }
    #FileNav .category{
        color: white;
        height: 30px;
        padding: 0px 5px;
        margin: 10px;
        margin-right: 10px;
        font-size : 15px;
        display: flex;
        align-items: center;
        line-height 30px;
        transition: transform 0.1s ease-out, opacity 0.1s ease-out;
        opacity: 0.4;
    }

    #FileNav .category:active{
        transform: scale(0.80);
        opacity : 1;
    }

    #FileNav .selectCategory{
        opacity: 1;
    }

    #FileNav .categoryList{
        display: flex;
    }


     .ViewListContainer {
        width: 100%;
        flex :1;
    }
    .ViewListContainer .spaceField{
        display: flex;
        width: 100%; 
        height: 100%;
        flex-direction: column;
        background-color: #2D2D2D;
    }
    .ViewListContainer #FileContainer{
        width: calc(100% - 0px);

        height: 100%;
        display: flex;
        align-items: center;
        overflow-y : auto;
    }

    .ViewListContainer .FileListWrap{
        flex : 1 ;
        width: calc(100% - 40px);

        height: 100%;
        display: grid;
        gap: 10px; /* 아이템 간 여백 */
        overflow-y: auto;
        box-sizing: border-box;
        padding: 100px 20px 0px;
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
        grid-auto-rows: 150px;
        justify-content: center;
        align-items: center;
    }    

    .ViewListContainer  #FileContainer .noneFile{


    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-aline : center;
    line-height : 30px;
  }

   
  .ViewListContainer  #FileContainer .Files{

    width: 150px;
    height: 150px;
    background-color: #2c2c2c;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 24px;
    border-radius: 10px;
    position: relative;
    align-self: center; /* 수직 중앙 정렬 */
    justify-self: center; /* 수평 중앙 정렬 */
    overflow : hidden;

    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1),
                0 10px 20px rgba(0, 0, 0, 0.05);
    transition: transform 0.3s, box-shadow 0.3s;
  }
  .ViewListContainer  #FileContainer .Files .FilePreview{
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    border-radius: 5px;

  }

  .ViewListContainer  #FileContainer .Files .FilePreview .PreviewImage{
      width: 100%;
      height: 100%;
      display: flex;
      object-fit: cover;
      border-radius: 5px;
  }
  .ViewListContainer  #FileContainer .Files .FileInfo {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    background: linear-gradient(to bottom, #00000000, #00000099);
  }

    .ViewListContainer  #FileContainer .Files .FileInfo .FileButton{
      font-size : 15px;
      width: calc(100% - 5px ) ;
      height: 30px;
      line-height : 30px;
      margin-top : 5px;
      display: flex;
      justify-content: end;
      text-shadow:
        1px 1px 2px rgba(0, 0, 0, 0.2),
        2px 2px 4px rgba(0, 0, 0, 0.2),
        3px 3px 6px rgba(0, 0, 0, 0.2);
  }

  .ViewListContainer  #FileContainer .Files .FileInfo .FileName{
      font-size : 15px;
      width: calc(100% ) ;
      height: 20px;
      line-height : 20px;
      display: flex;
      margin : 0px 5px ;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size : 10px;
  }

  .ViewListContainer  #FileContainer .Files .FileOption{
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
  }


    .ViewListContainer .spaceNav{
        height: 40px;
        width: 100%;
        padding: 0px 10px;
        line-height: 40px;
        box-sizing: border-box;
        display: flex ;
        justify-content: space-between;
    }
    .ViewListContainer .NavTitle{
        margin: 0px 10px ;
        font-size: 20px;
    }
    .ViewListContainer .bnt_List{
    
    }
    .ViewListContainer .createView{
        font-family: '프리텐다드ExtraBold';
        color: white;
        border: none;
        border-radius: 5px ;
        background: #222;
        font-size: 15px;
        height: 30px;    
        padding: 0px 7.5px;
        margin: 20px 10px;            

        line-height: 30px;
        display: flex;
        transition: background 0.15s;
        background: red;
    }
    .ViewListContainer .createView .icon{
        height: 20px;
        width: 20px;
        font-size: 20px;
        object-fit: scale-down;
        margin: 5px 5px 5px 0px;
    }
  
    .ViewListContainer .veiwList{
        width: 100%;
        padding-top: 20px;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
        grid-auto-rows: 150px;
    }



    .ViewListContainer .veiwLoading{
        flex :1;
        padding-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #adadad;
        flex-direction: column;
    }
    .ViewListContainer .undefinedView{
        flex :1;
        padding-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #adadad;
        flex-direction: column;
      
    }

    .ViewListContainer  .FileExplorer{
        background-color: #1c1c1c;
        width: 100%;
        height: 100%;
        display: flex;
        position: absolute;
        z-index : 3;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

   
    .ViewListContainer  .FileExplorer .ImageExplorer{
        width: 80%;
        height: 80%;
        display: flex;      /* 자식 요소를 가운데에 배치하기 위해 flex 사용 */
        justify-content: center;  /* 가로 중앙 정렬 */
        align-items: center;      /* 세로 중앙 정렬 */
        overflow: hidden;    /* 이미지가 부모를 넘으면 잘리도록 설정 */
    }

    .ViewListContainer  .FileExplorer .TargetView{
        width: 100%;
        height: 100%;
        object-fit: contain;   /* 비율을 유지하며 부모 크기를 채움 */
        object-position: center; /* 이미지가 부모 안에서 정가운데에 위치 */

    }

    .ViewListContainer  .FileExplorer .topExplorerNav{
        width: 30px;
        height: 30px;
        border : solid 1px #fff;
        display: flex;
        justify-content: center;  /* 가로 중앙 정렬 */
        align-items: center;      /* 세로 중앙 정렬 */
        border-radius : 50px;
    
        margin : 10px;
    }

    .ViewListContainer  .FileExplorer .bottomExplorerNav{
        width: 400px;
        height: 60px;
        margin-top: 10px;
        box-shadow: 1px 1px 5px #1c1c1c;
        background-color: #444;
        border-radius : 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .ViewListContainer  .FileExplorer .bottomExplorerNav .FileName{
        width: 100%;
        height: 30px;
        line-height: 30px;
        display: flex;
        justify-content: center;
    }

    .ViewListContainer  .FileExplorer .bottomExplorerNav .FileInfo{
        width: 100%;
        height: 30px;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .ViewListContainer  .FileExplorer .bottomExplorerNav span{
        display: flex;
        line-height: 30px;
        margin : 0px 10px;
    }
    
`;


function categorizeFile(mimeType) {


    // 이미지 파일 MIME 타입
    const imageTypes = ['image/png', 'image/jpeg', 'image/gif', 'image/bmp', 'image/tiff', 'image/webp'];

    // 영상 파일 MIME 타입
    const videoTypes = ['video/mp4', 'video/mkv', 'video/avi', 'video/mov', 'video/wmv', 'video/flv', 'video/webm'];

    // 문서 파일 MIME 타입
    const documentTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                           'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                           'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                           'text/plain'];

    // 파일 타입을 기준으로 분류
    if (imageTypes.includes(mimeType)) {
        return 'image';
    } else if (videoTypes.includes(mimeType)) {
        return 'video';
    } else if (documentTypes.includes(mimeType)) {
        return 'document';
    } else {
        return 'unknown';
    }
}
