////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, {useState, useEffect, useRef } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { Route, Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
// 라이브러리 
import { useGoogleLogin } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google'// 구글 로그인
// 설정 파일
import * as config from 'config/OverlapConfig'

import * as apiAccount from 'service/api/apiAccount'

// [컴포넌트] 위젯 
import PreView from 'components/viewer/PreView';
import PreviewDisplay from 'components/display/PreviewDisplay';
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import 'sass/login.scss'

// 이미지 연결
import logoW from 'assets/logoW.svg';
import youtube from 'assets/main/lobby/social/youtube.png';
import twitch from 'assets/main/lobby/social/twitch.png';
import afreeca from 'assets/main/lobby/social/afreeca.png';
import facebook from 'assets/main/lobby/social/facebook.png';
import naver from 'assets/main/lobby/social/naver.png';


// 서비스 연결
import * as Editor from 'service/edit/EditService' ; 
import * as page from 'service/api/page';
import * as view from 'service/api/view';

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

function SpaceModuleViewPreview(props) {

    const history = useHistory();

    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////
    // state
    const [getPreview, setPreview] = useState([]);
    const [PreviewState, setPreviewState] = useState(null);
    // rel 
    ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////

      useEffect(() => {
        console.log('Component did mount or update');
        getPagePreview();
        return () => {
          console.log('Component will unmount');
        };
      },[]);

    ////////////////////////////////////////////////////////
    // Fuction
    ////////////////////////////////////////////////////////

    useEffect(() => {
        getPagePreview()
    }, [props.view]);

    async function getPagePreview(){
   
        const NewPreview = [];
        if(props.view?.view_pointer?.[0]){
            const viewPreview = await page.getPageForUUID(props.view.view_pointer?.[0]?.uuid);
            NewPreview.push(viewPreview[0])
            setPreview(NewPreview)
        }else{
            setPreview([])
        }
    }

    function timeAgo(dateString) {
        const then = new Date(dateString);
        const now = new Date();
        const diffInSeconds = Math.floor((now - then) / 1000);
      
        if (diffInSeconds < 180) {
          return `지금`;
        }
        if (diffInSeconds < 3600) {
          return `${Math.floor(diffInSeconds / 60)}분 전`;
        }
        if (diffInSeconds < 86400) {
          return `${Math.floor(diffInSeconds / 3600)}시간 전`;
        }
        return `${Math.floor(diffInSeconds / 86400)}일 전`;
      }
    const ShowView = () => {
        // console.log('Showprops.view?.uuView',props.view.uuid);
        const uuid = view.uuidConverter('url',props?.view?.uuid);
        window.open(`/view/${uuid}`, '_blank');
        // history.push({
        //   pathname: `/view/${uuid}`,
        // });
      }


      const deleteView = async () => {
        console.log('props',props);
        const Del =  await view.del(props.view.uuid,props.spaceId)
        if(Del){
            setPreviewState('deletePreview')
            props.deletePreview(props.view.uuid);
        }else{
            setPreviewState('errorPreview')
        }

        // props.refresh('refresh')
      }


    async function copyTextToClipboard(text) {
    try {
        await navigator.clipboard.writeText(text);
        alert('채널 주소가 복사되었습니다.')
        console.log("Text copied successfully");
    } catch (err) {
        console.error("Oops, unable to copy", err);
    }
    }      
    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////
    return (
        <div className={'SpaceModuleContainer ViewPreview '+PreviewState} >
            <style jsx>{`${SpaceModuleStyle}${ContainerStyle}`}</style>
            <div className="previewArea">
                <div className='previewLeft'>
                
                </div>
                <div className='previewWarp'>
                    <div className='centerScreen'>
                        <PreviewDisplay pageList={getPreview} referenceWidth={props.view.resolution_width??0} referenceHeight={props.view?.resolution_height??0}/>
                    </div>
                </div>
                <div className='previewRight'>
                    <button className='delBackground utilityButton' onClick={()=>deleteView()} >
                        <p> {Editor.icon({icon:'delete',size:25,lineHeight:50})} </p>
                    </button>
                </div>
            </div>
            {/* 
            <div className="previewArea">
                <div className='previewLeft'>
                
                </div>
                <div className='previewWarp'>
                    <div className='centerScreen'>
                        <PreviewDisplay pageList={getPreview} referenceWidth={props.view.resolution_width??0} referenceHeight={props.view?.resolution_height??0}/>
                    </div>
                </div>
                <div className='previewRight'>
                    <button className='delBackground utilityButton' onClick={()=>deleteView()} >
                            <p> {Editor.icon({icon:'delete',size:25,lineHeight:50})} </p>
                    </button>
                </div>
            </div> */}
            <div className='viewInfo'>
                <div className='viewFileTitle'>
                    {props?.view?.view_name}
                </div>
                <div className='centerInfo'>
                    <div className='viewDetail'>
                        <div className='viewDataList'>
                            <div className='viewData'>
                            <span style={{fontSize:'20px !important',lineHeight:'25px !important'}} class="material-symbols-outlined iconfont infoIcon">update</span>
                            <p>{timeAgo(props.view?.updated_at)}</p>
                            </div>
                        
                            <div className='viewData'>
                            <span style={{fontSize:'20px !important',lineHeight:'25px !important'}} class="material-symbols-outlined iconfont infoIcon">resize</span>
                            <p>{props.view?.resolution_width}*{props.view?.resolution_height}</p>
                            </div>

                            <div className='viewData'>
                            <span style={{fontSize:'20px !important',lineHeight:'25px !important'}} class="material-symbols-outlined iconfont infoIcon">layers</span>
                            <p>{props.view?.view_pointer.length??0}</p>
                            </div>

                            <div className='viewData'>
                            {/* <span style={{fontSize:'20px !important',lineHeight:'25px !important'}} class="material-symbols-outlined iconfont infoIcon">lock</span> */}
                            <span style={{fontSize:'20px !important',lineHeight:'25px !important'}} class="material-symbols-outlined iconfont infoIcon">lock_open</span>
                            <p>공개됨</p>
                            </div>                            
                        </div>
                        <div className='viewActionList'>
                        <button className='infoBackground viewMoveButton' onClick={()=>ShowView()} >
                            <span style={{fontSize:'20px !important',lineHeight:'25px !important'}} class="material-symbols-outlined iconfont infoIcon">open_in_new</span>
                        </button>
                        <div className='viewLinkField'>
                            <input className='viewLinkUrl' value={`${window.location.protocol}//${window.location.host}/view/`+view.uuidConverter('url',props?.view?.uuid)}/>
                            <button className='infoBackground viewLinkButton' onClick={()=>copyTextToClipboard(`${window.location.protocol}//${window.location.host}/view/`+view.uuidConverter('url',props?.view?.uuid))} >
                                <span style={{fontSize:'20px !important',lineHeight:'25px !important'}} class="material-symbols-outlined iconfont infoIcon">link</span>
                            </button>
                        </div>
                        </div>
                    </div>
                 
                    {/* <div className='leftControl'>
                
                    </div> */}

                </div>
                
              
            
            </div>
    </div>
    );
  }

  export default SpaceModuleViewPreview;

////////////////////////////////////////////////////////
// css 부분
////////////////////////////////////////////////////////
const SpaceModuleStyle = `
    .ViewPreview {
        animation: slideUp 0.6s forwards;
        margin: 32px 25px 17px;
        display :flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        width: calc(100% - 50px);       
    }
    @keyframes slideUp {
        0% {
            transform: translateY(120%); /* 시작 위치: 아래 */

        }
        100% {
          transform: translateY(0); /* 끝 위치: 원래 위치 */

        }
      }
`;

const ContainerStyle = `

    .ViewPreview.deletePreview .centerScreen{
        animation: delete_file 0.7s ease-in forwards;
    }
    .ViewPreview.deletePreview .viewInfo{
        animation: delete_file 0.7s ease-in forwards;
    }

    .ViewPreview .previewArea{
        display :flex;
        width: 100%;
        max-height: 30vw; 
    }
    .ViewPreview .previewArea .previewLeft{
        display :flex;
        width: 20%;
        max-height: 30vw; 
    }
    .ViewPreview .previewArea .previewRight .RightButton{
        width: 40px;
        height: 40px; 
        border-radius : 40px;
        background-color : #444;
        color: #fff;
    }
    .ViewPreview .previewArea .previewRight{
        display :flex;
        justify-content: end;
        width: 20%;
        max-height: 30vw; 
    }
    .ViewPreview .previewWarp{
        display :flex;
        width: 60%;
        max-height: 30vw; 

    }



    .ViewPreview .centerScreen{
        display :flex;
        width: 100%;
        height: 100%;
        max-height: 30vh; 
        aspect-ratio: 16 / 9;
        
    }
    .ViewPreview .viewInfo{
        width: 100%;
        overflow: hidden;
    }

    .ViewPreview .viewFileTitle{
        text-align: center;
        font-size: 20px;
        margin: 10px;
        font-family: '프리텐다드ExtraBold';
    }


    .ViewPreview .centerInfo{
        width: 100%;  
        display :flex;
        justify-content: center;
    }
    .ViewPreview .leftControl{
        display :flex;
        flex : 1;
        align-items: start;
        justify-content: start;
    }
    .ViewPreview .viewDetail{
        display :flex;
        flex-direction: column;
        align-items: center;
        max-width: 30vw; 
        width: 60%;  
        
    }
    .ViewPreview .rightInfo{
        display :flex;
        flex : 1;
        background-color: ;
    }

    .ViewPreview .viewData{
        flex-wrap: wrap;
        display :flex;
        margin-right :15px;
        line-height : 24px;
    }
    .ViewPreview .viewDataList{
        display :flex;
        justify-content: center;
    }

    .ViewPreview .infoBackground{
        background-color: #444;
        padding: 5px 10px;
        border-radius: 10px;
        display : flex;
        min-height: 40px;

    }
    .ViewPreview .viewActionList{
        display :flex;
        justify-content: center;
    }
    .ViewPreview .viewButton{
        height: 50px;
        padding: 5px 5px;
        border-radius: 10px;
        color : #fff;
        width: 50px;
        margin : 10px
    }
    .ViewPreview .viewMoveButton{
        height: 50px;
        width: 50px;
        padding: 5px 5px;
        margin : 10px;
        border-radius: 10px;

        background-color: #444;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .ViewPreview .viewLinkField{
        width: 400px;
        height: 50px;  
        display :flex;
        background-color: #2A2A2A;
        margin : 10px; 
        border-radius: 10px;
    }
    .ViewPreview .viewLinkUrl{
        flex : 1;
        display :flex;
        height: 50px;   
        line-height: 50px;
        font-size: 11px;
        text-align: center;
    }
    .ViewPreview .viewLinkButton{
        height: 50px;
        width: 50px;
        padding: 5px 5px;
        border-radius: 10px;
        background-color:  #2A2A2A;
        color : #fff;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .ViewPreview .viewLinkButton p{
        font-family: '프리텐다드SemiBold';
        width: 100%;
        height: 40px;   
        line-height: 40px;
        text-align: center;
    }

    .ViewPreview input {
        border: none; /* 테두리 제거 */
        outline: none; /* 포커스 시 나타나는 테두리 제거 */
        box-shadow: none; /* 그림자 제거 */
        -webkit-appearance: none; /* 웹킷 브라우저에서 기본 스타일 제거 */
        -moz-appearance: none; /* 파이어폭스에서 기본 스타일 제거 */
        background-color: transparent; 
        color : #fff;
    }

     
    .ViewPreview .delBackground{
        background-color: #444;
        padding: 5px 10px;
        border-radius: 10px;
        display : flex;
        min-height: 40px;
        animation: left_delBackground 0.3s 0.7s both ease-out 1;
    }
    .ViewPreview .utilityButton{
        height: 50px;
        padding: 5px 10px;
        border-radius: 10px;
        color : #fff;
        width: 50px;
        margin : 0px 10px 10px 10px;
    }
    .ViewPreview .utilityButton p{
        font-family: '프리텐다드SemiBold';
        width: 100%;
        height: 40px;   
        line-height: 40px;
        text-align: center;
        justify-content: center;
        align-items: center;
        display: flex;
    }
    
    @keyframes left_delBackground {
        0% { transform: translateX(100px); opacity: 0; }
        100% { transform: translateX(0); opacity: 1;}
    }

    @keyframes delete_file {
        0% { transform: translateY(0px) rotateZ(0deg);}
        80% { opacity: 1}
        100% { transform: translateY(500px) rotateZ(30deg); opacity: 0}
    }

`;

