
////////////////////////////////////////////////////////
// 여러곳에서 사용되는 기본펑션
////////////////////////////////////////////////////////

////////////////////////////////////////////////////////
/** dragTransparent
 * 드레그를 투명하게 합니다.
 */
////////////////////////////////////////////////////////
export function dragTransparent(e) {
    let img = new Image();
    img.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
    e.dataTransfer.setDragImage(img, 0, 0);
  };
