
function preset(preset,self,data,style){

    let myCoinList = null
    let listLen = 1
    let display = null
    let speed = data.value.speed 
    try{
        myCoinList = Object.keys(self.state.coin)
        listLen = myCoinList.length
        display = myCoinList[Math.floor(self.state.polling/speed)%listLen]
    }catch{

    }
    switch (preset) {
        case 'normal': 
            return(
            <div className={"object "+self.props.widgetName+"_preset_"+preset} ref={self.widget} style={style.object}>
                <div className="coinUpper">
                    <div className="coinData">
                        {(display&&display!='total'?<img src={'https://static.upbit.com/logos/'+display+'.png'}/>:'')}
                        <h3 className="name" style={{fontSize:data.size.height*30/100+'px',lineHeight:data.size.height*50/100+'px'}}>{(CoinData[display]?CoinData[display].ko:'가상화폐 알림판')}
                        <small style={{fontSize:data.size.height*20/100+'px',lineHeight:data.size.height*30/100+'px'}} >
                            {/* {(self.revenueShow(display) && (self.state.polling%speed >= speed/2) ?' (수익)':' (시세)')} */}
                            </small>
                        </h3>
                    </div>

                </div>
                {(self.revenueShow(display)&& (self.state.polling%speed >= speed/2) ?
                    <div className="coinLower">
                    <p className="tradePrice" style={{fontSize:data.size.height*20/100+'px',lineHeight:data.size.height*50/100+'px'}}>
                    {self.revenue(display)}
                    </p>
                    <p className="changePercent" style={{fontSize:data.size.height*20/100+'px',lineHeight:data.size.height*50/100+'px'}}>
                    {self.revenuePercent(display)}
                    </p>
                    </div>
                    : 
                    <div className="coinLower">
                    <p className="tradePrice" style={{fontSize:data.size.height*20/100+'px',lineHeight:data.size.height*50/100+'px'}}>
                    {self.tradePrice(display)}
                    </p>
                    <p className="changePercent" style={{fontSize:data.size.height*20/100+'px',lineHeight:data.size.height*50/100+'px'}}>
                    {self.changePercent(display)}
                    </p>
                    </div>
                )}
            </div>
        )
        case 'orange':

            return(
                <div className={"object "+self.props.widgetName+"_preset_"+preset} ref={self.widget} style={style.object}>
                <div className="coinImg">
                    {(data.value.currency?<img width="100px" height="100px" src={'https://static.upbit.com/logos/'+data.value.currency+'.png'}/>:'')}
                </div>
                <div className="coinInfo">
                <p className="coinInfoUpper">
                    {CoinData[data.value.coin[0].currency].ko}
                </p>
                <p className="coinInfoLower">
                    {self.tradePrice()}
                </p>
           
                </div>
    
            </div>
        )

        default:
            <div className={"object unknownPreset"} ref={self.widget} style={{...style.object}} >
                <p>알수없는 프리셋</p>
            </div>
    }
}
export function data(self,style) {

    return preset(self.props.data.preset,self,self.props.data,style)
}



const ChampionData = {  
    'Aatrox': 
        {
        Name : 'Aatrox',
        NameKo : '아트록스',
        Role : 'FIGHTER',
        Position : ['TOP'],
        info :'한때는 공허에 맞서 싸웠던 슈리마의 명예로운 수호자 아트록스와 그의 종족은 결국 공허보다 위험한 존재가 되어 룬테라의 존속을 위협했지만, 교활한 필멸자의 마법에 속아넘어가 패배하게 되었다. 수백 년에 걸친 봉인 끝에, 아트록스는 자신의 정기가 깃든 마법 무기를 휘두르는 어리석은 자들을 타락시키고 육신을 바꾸는 것으로 다시 한번 자유의 길을 찾아내었다. 이제 이전의 잔혹한 모습을 닮은 육체를 차지한 아트록스는 세상의 종말과 오랫동안 기다려온 복수를 열망한다.',
        tag: []
        },
    'Ahri': 
        {
        Name : 'Ahri',
        NameKo : '아리',
        Role : 'MAGE',
        Position : ['MID'],
        info :'룬테라가 가진 잠재력과 선천적으로 연결된 아리는 마법을 순수한 에너지의 구체로 변환할 수 있는 바스타야다. 아리는 먹잇감의 감정을 질리도록 조종하며 가지고 놀다가 마지막에 생명의 정수를 흡수하는 것을 즐긴다. 타고난 포식자지만, 먹어치우는 영혼에 담긴 기억의 편린을 받아들일 때마다 거기에 감정 이입하게 된다.',
        tag: []
        },
    '영웅의 영어이름': 
        {
        Name : '영웅의 영어이름',
        NameKo : '영웅의 한글이름',
        Role : '영웅의 역활군 *영어로',
        Position : ['영웅의 포지션 *영어로  탑 : TOP 미드 : MID 정글 : JGL 서포터 : SPT 바텀 :BOT'], 
        info :'공식 홈페이지 상 설명',
        tag: []
        },
}


const CoinData = {  
    'total': 
    {
        coin : 'total',
        ko : '총 수익',
        fill : '',
        info :'',
        tag: []
    },
    'BTC': 
        {
        coin : 'BTC',
        ko : '비트코인',
        fill : '',
        info :'',
        tag: []
        },
    'ETH': 
        {
        coin : 'ETH',
        ko : '이더리움',
        fill : '',
        info :'',
        tag: []
        }, 
    'ADA': 
        {
        coin : 'ADA',
        ko : '에이다',
        fill : '',
        info :'',
        tag: []
        },                    
    'XRP': 
        {
        coin : 'XRP',
        ko : '리플',
        fill : '',
        info :'',
        tag: []
        },
    'LTC': 
        {
        coin : 'LTC',
        ko : '라이트코인',
        fill : '',
        info :'',
        tag: []
        }, 
    'LINK': 
        {
        coin : 'LINK',
        ko : '체인링크',
        fill : '',
        info :'',
        tag: []
        }, 
    'THETA': 
        {
        coin : 'THETA',
        ko : '쎄타토큰',
        fill : '',
        info :'',
        tag: []
        },
    'BCH': 
        {
        coin : 'BCH',
        ko : '비트코인캐시',
        fill : '',
        info :'비트코인에서 하드포크되어 나온 가상화폐',
        tag: []
        }, 
    'XLM': 
        {
        coin : 'XLM',
        ko : '스텔라루멘',
        fill : '',
        info :'',
        tag: []
        },                    
    'DOGE': 
        {
        coin : 'DOGE',
        ko : '도지코인',
        fill : '',
        info :'시바견을 마스코트로 하는 장난처럼 시작한 가상화폐',
        tag: []
        },
    'TRX': 
        {
        coin : 'TRX',
        ko : '트론',
        fill : '',
        info :'스토리지 기술을 이용해 엔터테인먼트 생태계를 구축하기 위한 코인',
        tag: []
        }, 
    'GAS': 
        {
        coin : 'GAS',
        ko : '가스',
        fill : '',
        info :'GAS는 네오를 구성하는 두 가지 네이티브 토큰 중 하나 입니다. ',
        tag: []
        },   
    'GLM': 
        {
        coin : 'GLM',
        ko : '골렘',
        fill : '',
        info :'',
        tag: []
        },   
    'GRS': 
        {
        coin : 'GRS',
        ko : '그리스톨코인',
        fill : '',
        info :'',
        tag: []
        },   
    'NEO': 
        {
        coin : 'NEO',
        ko : '네오',
        fill : '',
        info :'',
        tag: []
        },     
    'XEM': 
        {
        coin : 'XEM',
        ko : '넴',
        fill : '',
        info :'',
        tag: []
        },   
    'DMT': 
        {
        coin : 'DMT',
        ko : '디마켓',
        fill : '',
        info :'',
        tag: []
        },   
    'MANA': 
        {
        coin : 'MANA',
        ko : '디센트럴랜드',
        fill : '',
        info :'',
        tag: []
        },   
    'DKA': 
        {
        coin : 'DKA',
        ko : '디카르고',
        fill : '',
        info :'',
        tag: []
        }, 
    'LAMB': 
        {
        coin : 'LAMB',
        ko : '람다',
        fill : '',
        info :'',
        tag: []
        },   
    'LOOM': 
        {
        coin : 'LOOM',
        ko : '룸네트워크',
        fill : '',
        info :'',
        tag: []
        },   
    'LSK': 
        {
        coin : 'LSK',
        ko : '리스크',
        fill : '',
        info :'',
        tag: []
        },   
    'RFR': 
        {
        coin : 'RFR',
        ko : '리퍼리움',
        fill : '',
        info :'',
        tag: []
        },     
    'MARO': 
        {
        coin : 'MARO',
        ko : '마로',
        fill : '',
        info :'',
        tag: []
        },   
    'MED': 
        {
        coin : 'MED',
        ko : '메디블록',
        fill : '',
        info :'',
        tag: []
        },   
    'MFT': 
        {
        coin : 'MFT',
        ko : '메인프레임',
        fill : '',
        info :'',
        tag: []
        },   
    'META': 
        {
        coin : 'META',
        ko : '메타디움',
        fill : '',
        info :'',
        tag: []
        },
    'MTL': 
        {
        coin : 'MTL',
        ko : '메탈',
        fill : '',
        info :'',
        tag: []
        },   
    'MOC': 
        {
        coin : 'MOC',
        ko : '모스코인',
        fill : '',
        info :'',
        tag: []
        },   
    'MBL': 
        {
        coin : 'MBL',
        ko : '무비블록',
        fill : '',
        info :'',
        tag: []
        },   
    'MLK': 
        {
        coin : 'MLK',
        ko : '밀크',
        fill : '',
        info :'',
        tag: []
        },     
    'BAT': 
        {
        coin : 'BAT',
        ko : '베이직어텐션토큰',
        fill : '',
        info :'',
        tag: []
        },   
    'BORA': 
        {
        coin : 'BORA',
        ko : '보라',
        fill : '',
        info :'',
        tag: []
        },   
    'VET': 
        {
        coin : 'VET',
        ko : '비체인',
        fill : '',
        info :'',
        tag: []
        },   
    'BTG': 
        {
        coin : 'BTG',
        ko : '비트코인골드',
        fill : '',
        info :'',
        tag: []
        },
    'BSV': 
        {
        coin : 'BSV',
        ko : '비트코인에스브이',
        fill : '',
        info :'',
        tag: []
        },   
    'BCHA': 
        {
        coin : 'BCHA',
        ko : '비트코인캐시에이비씨',
        fill : '',
        info :'',
        tag: []
        },   
    'BTT': 
        {
        coin : 'BTT',
        ko : '비트토렌트',
        fill : '',
        info :'',
        tag: []
        },   
    'SAND': 
        {
        coin : 'SAND',
        ko : '샌드박스',
        fill : '',
        info :'',
        tag: []
        },     
    'SRM': 
        {
        coin : 'SRM',
        ko : '세럼',
        fill : '',
        info :'',
        tag: []
        },   
    'UPP': 
        {
        coin : 'UPP',
        ko : '센티넬프로토콜',
        fill : '',
        info :'',
        tag: []
        },   
    'SOLVE': 
        {
        coin : 'SOLVE',
        ko : '솔브케어',
        fill : '',
        info :'',
        tag: []
        },   
    'SXP': 
        {
        coin : 'SXP',
        ko : '스와이프',
        fill : '',
        info :'',
        tag: []
        },
    'SNT': 
        {
        coin : 'SNT',
        ko : '스테이터스네트워크토큰',
        fill : '',
        info :'',
        tag: []
        },   
    'STORJ': 
        {
        coin : 'STORJ',
        ko : '스토리지',
        fill : '',
        info :'',
        tag: []
        },   
    'STMX': 
        {
        coin : 'STMX',
        ko : '스톰엑스',
        fill : '',
        info :'',
        tag: []
        },   
    'STRK': 
        {
        coin : 'STRK',
        ko : '스트라이크',
        fill : '',
        info :'',
        tag: []
        },     
    'STRAX': 
        {
        coin : 'STRAX',
        ko : '스트라티스',
        fill : '',
        info :'',
        tag: []
        },   
    'STEEM': 
        {
        coin : 'STEEM',
        ko : '스팀',
        fill : '',
        info :'',
        tag: []
        },   
    'SBD': 
        {
        coin : 'SBD',
        ko : '스팀달러',
        fill : '',
        info :'',
        tag: []
        },   
    'CVC': 
        {
        coin : 'CVC',
        ko : '시빅',
        fill : '',
        info :'',
        tag: []
        }, 
        'SC': 
        {
        coin : 'SC',
        ko : '시아코인',
        fill : '',
        info :'',
        tag: []
        },   
    'TT': 
        {
        coin : 'TT',
        ko : '썬더토큰',
        fill : '',
        info :'',
        tag: []
        },   
    'SSX': 
        {
        coin : 'SSX',
        ko : '썸씽',
        fill : '',
        info :'',
        tag: []
        },   
    'TFUEL': 
        {
        coin : 'TFUEL',
        ko : '쎄타퓨엘',
        fill : '',
        info :'',
        tag: []
        },     
    'ARDR': 
        {
        coin : 'ARDR',
        ko : '아더',
        fill : '',
        info :'',
        tag: []
        },   
    'AERGO': 
        {
        coin : 'AERGO',
        ko : '아르고',
        fill : '',
        info :'',
        tag: []
        },   
    'IOST': 
        {
        coin : 'IOST',
        ko : '아이오에스티',
        fill : '',
        info :'',
        tag: []
        },   
    'IOTA': 
        {
        coin : 'IOTA',
        ko : '아이오타',
        fill : '',
        info :'',
        tag: []
        }, 
    'ICX': 
        {
        coin : 'ICX',
        ko : '아이콘',
        fill : '',
        info :'',
        tag: []
        },   
    'EMC2': 
        {
        coin : 'EMC2',
        ko : '아인스타이늄',
        fill : '',
        info :'',
        tag: []
        },   
    'ARK': 
        {
        coin : 'ARK',
        ko : '아크',
        fill : '',
        info :'',
        tag: []
        },   
    'AHT': 
        {
        coin : 'AHT',
        ko : '아하토큰',
        fill : '',
        info :'',
        tag: []
        },     
    'AQT': 
        {
        coin : 'AQT',
        ko : '알파쿼크',
        fill : '',
        info :'',
        tag: []
        },   
    'ADX': 
        {
        coin : 'ADX',
        ko : '애드엑스',
        fill : '',
        info :'',
        tag: []
        },   
    'ANKR': 
        {
        coin : 'ANKR',
        ko : '앵커',
        fill : '',
        info :'',
        tag: []
        },   
    'REP': 
        {
        coin : 'REP',
        ko : '어거',
        fill : '',
        info :'',
        tag: []
        },
    'IQ': 
        {
        coin : 'IQ',
        ko : '에브리피디아',
        fill : '',
        info :'',
        tag: []
        },   
    'STPT': 
        {
        coin : 'STPT',
        ko : '에스티피',
        fill : '',
        info :'',
        tag: []
        },   
    'EDR': 
        {
        coin : 'EDR',
        ko : '엔도르',
        fill : '',
        info :'',
        tag: []
        },   
    'ENJ': 
        {
        coin : 'ENJ',
        ko : '엔진코인',
        fill : '',
        info :'',
        tag: []
        },     
    'LBC': 
        {
        coin : 'LBC',
        ko : '엘비알와이크레딧',
        fill : '',
        info :'',
        tag: []
        },   
    'ELF': 
        {
        coin : 'ELF',
        ko : '엘프',
        fill : '',
        info :'',
        tag: []
        },   
    'MVL': 
        {
        coin : 'MVL',
        ko : '엠블',
        fill : '',
        info :'',
        tag: []
        },   
    'OMG': 
        {
        coin : 'OMG',
        ko : '오미세고',
        fill : '',
        info :'',
        tag: []
        },
    'ORBS': 
        {
        coin : 'ORBS',
        ko : '오브스',
        fill : '',
        info :'',
        tag: []
        },   
    'ONT': 
        {
        coin : 'ONT',
        ko : '온톨로지',
        fill : '',
        info :'',
        tag: []
        },   
    'ONG': 
        {
        coin : 'ONG',
        ko : '온톨로지가스',
        fill : '',
        info :'',
        tag: []
        },   
    'OBSR': 
        {
        coin : 'OBSR',
        ko : '옵저버',
        fill : '',
        info :'',
        tag: []
        },     
    'WAXP': 
        {
        coin : 'WAXP',
        ko : '왁스',
        fill : '',
        info :'',
        tag: []
        },   
    'WAVES': 
        {
        coin : 'WAVES',
        ko : '웨이브',
        fill : '',
        info :'',
        tag: []
        },   
    'IGNIS': 
        {
        coin : 'IGNIS',
        ko : '이그니스',
        fill : '',
        info :'',
        tag: []
        },   
    'ETC': 
        {
        coin : 'ETC',
        ko : '이더리움클래식',
        fill : '',
        info :'',
        tag: []
        },
    'EOS': 
        {
        coin : 'EOS',
        ko : '이오스',
        fill : '',
        info :'',
        tag: []
        },   
    'JST': 
        {
        coin : 'JST',
        ko : '저스트',
        fill : '',
        info :'',
        tag: []
        },   
    'ZRX': 
        {
        coin : 'ZRX',
        ko : '제로엑스',
        fill : '',
        info :'',
        tag: []
        },   
    'ZIL': 
        {
        coin : 'ZIL',
        ko : '질리카',
        fill : '',
        info :'',
        tag: []
        },     
    'CHZ': 
        {
        coin : 'CHZ',
        ko : '칠리즈',
        fill : '',
        info :'',
        tag: []
        },   
    'KAVA': 
        {
        coin : 'KAVA',
        ko : '카바',
        fill : '',
        info :'',
        tag: []
        },   
    'KNC': 
        {
        coin : 'KNC',
        ko : '카이버네트워크',
        fill : '',
        info :'',
        tag: []
        },   
    'CRE': 
        {
        coin : 'CRE',
        ko : '캐리프로토콜',
        fill : '',
        info :'',
        tag: []
        },
        'KMD': 
        {
        coin : 'KMD',
        ko : '코모도',
        fill : '',
        info :'',
        tag: []
        },   
    'CBK': 
        {
        coin : 'CBK',
        ko : '코박토큰',
        fill : '',
        info :'',
        tag: []
        },   
    'ATOM': 
        {
        coin : 'ATOM',
        ko : '코스모스',
        fill : '',
        info :'',
        tag: []
        },   
    'QKC': 
        {
        coin : 'QKC',
        ko : '쿼크체인',
        fill : '',
        info :'',
        tag: []
        },     
    'QTCON': 
        {
        coin : 'QTCON',
        ko : '퀴즈톡',
        fill : '',
        info :'',
        tag: []
        },   
    'QTUM': 
        {
        coin : 'QTUM',
        ko : '퀸텀',
        fill : '',
        info :'',
        tag: []
        },   
    'CRO': 
        {
        coin : 'CRO',
        ko : '크립토닷컴체인',
        fill : '',
        info :'',
        tag: []
        },   
    'XTZ': 
        {
        coin : 'XTZ',
        ko : '테조스',
        fill : '',
        info :'',
        tag: []
        }, 
    'TON': 
        {
        coin : 'TON',
        ko : '톤',
        fill : '',
        info :'',
        tag: []
        },   
    'TSHP': 
        {
        coin : 'TSHP',
        ko : '트웰브쉽스',
        fill : '',
        info :'',
        tag: []
        },   
    'POWR': 
        {
        coin : 'POWR',
        ko : '파워렛저',
        fill : '',
        info :'',
        tag: []
        },   
    'PUNDIX': 
        {
        coin : 'PUNDIX',
        ko : '펀디엑스',
        fill : '',
        info :'',
        tag: []
        },     
    'PCI': 
        {
        coin : 'PCI',
        ko : '페이코인',
        fill : '',
        info :'',
        tag: []
        },   
    'POLY': 
        {
        coin : 'POLY',
        ko : '폴리매쓰',
        fill : '',
        info :'',
        tag: []
        },   
    'DOT': 
        {
        coin : 'DOT',
        ko : '폴카닷',
        fill : '',
        info :'',
        tag: []
        },   
    'PLA': 
        {
        coin : 'PLA',
        ko : '플레이댑',
        fill : '',
        info :'',
        tag: []
        },
    'FCT2': 
        {
        coin : 'FCT2',
        ko : '피르마체인',
        fill : '',
        info :'',
        tag: []
        },   
    'PXL': 
        {
        coin : 'PXL',
        ko : '픽셀',
        fill : '',
        info :'',
        tag: []
        },   
    'HIVE': 
        {
        coin : 'HIVE',
        ko : '하이브',
        fill : '',
        info :'',
        tag: []
        },   
    'HUNT': 
        {
        coin : 'HUNT',
        ko : '헌트',
        fill : '',
        info :'',
        tag: []
        },     
    'HBAR': 
        {
        coin : 'HBAR',
        ko : '헤데라해시그래프',
        fill : '',
        info :'',
        tag: []
        },   
    'HUM': 
        {
        coin : 'HUM',
        ko : '휴먼스케이프',
        fill : '',
        info :'',
        tag: []
        },
    }