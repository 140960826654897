////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";
// [프로바이더] 연결
import EditContext from 'service/context/EditContext' ; 

// SASS&CSS 연결
import "sass/edit.scss"


// 컴포넌트 연결
//import HeaderMenu from './HeaderMenu'; // 메뉴 헤더

// 서비스 연결
// import * as DeCoder from '../..s/service/transform/DeCoder' ; 
import * as EeCoder from '../../service/engine/EnCoder';
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class WidgetSelectBox extends Component {
    static contextType = EditContext;
    state = {
        WidgetList : "기본",
    }
    
    templateContent = (e) => { 
        this.props.viewMode(false)
        e.stopPropagation();
        this.screenDataAdd()
    }
    screenDataAdd = () =>{
        let preset = this.props.preset
        let option = this.props.option
        let nowPage = this.context.nowPage();

        if (!nowPage?.page_widget){return}

        const modifyPage = nowPage.page_widget.concat(EeCoder.add(this.props.type,this.props.x,this.props.y,{preset,option})); 

        this.context.modifyPage({'page_widget':modifyPage},'widgetAdd')
        
        let newScreenData = this.props.screenData.concat(EeCoder.add(this.props.type,this.props.x,this.props.y,{preset,option}))
        EeCoder.screenDataUpdate(newScreenData,'add')
    }

    render() { 
        return (
             <div className="widgetSelectContainer" >
                <div className="widgetSelectContent" onClick={(e)=>this.templateContent(e)}>
                    <div className="widgetSelectImg">
                        <img src={this.props.img} />
                    </div>
                    <div className="widgetSelectText" >
                        {this.props.name}
                    </div>
                </div>
             </div>

        );
    }
}
////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    screenData : state.screenData,
})

function mapDispatchToProps(dispatch){
    return {
        
      screenDataDispatch : (screenData) => dispatch({type:'screenData',data : screenData}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(WidgetSelectBox);
  