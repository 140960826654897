import store from "store";
import * as Rest from 'service/api/rest' ; 
import * as EnCoder from 'service/engine/EnCoder' ; 
import * as Utility from 'service/other/Utility'; 
////////////////////////////////////////////////////////
// store 연결
////////////////////////////////////////////////////////

////////////////////////////////////////////////////////
// save (저장할 스크린번호)
// 해당 페이지의 위젯들을 서버에 저장(갱신)합니다.
////////////////////////////////////////////////////////

export function save(inputNo=null) {
  console.log('save')
  // return new Promise(function(resolve,reject){
  // const state = store.getState(); // 현재 상태를 가져옵니다.
  // const { screenNo, screenData } = state; // 편의상 비구조화 할당

  // if(inputNo==null){inputNo = screenNo}
  // let response =  Rest.request('screendata','Put',{screen_id:inputNo,body:JSON.stringify(screenData)},sessionStorage.getItem("JWT"))
  // response
  // .then(function (response) {
  //   // 레이지 로딩 기법 
  //   resolve( {screenNo,screenData});
  // })
  // .catch(function (error) {
  //     reject(error);
  //   })
  // });  
}

////////////////////////////////////////////////////////
// undo (되돌리기를 합니다)
// 해당 페이지의 위젯들을 서버에 저장(갱신)합니다.
////////////////////////////////////////////////////////


export function undo() {
  console.error('되돌리기를 사용했으나 아직 제작중이라 사용 불가능 합니다.')
}

////////////////////////////////////////////////////////
// copy (해당스크린 데이터)
// 해당 객체를 복사합니다.
////////////////////////////////////////////////////////
/** copy | 선택한 스크린을 복사합니다.
 * @param {EditProvider} context 오버랩을 생성한 사람의 아이디
 * @param {string} screenUUID 복사할 스크린의 UUID 아이디
 * @returns 오버랩을 생성합니다. 생성 성공시 생성한 오버랩의 아이디를 반환 합니다.
 *  */ 
export function copy(context,screenUUID) {
    //TODO : screenUUID 에서 더 정확하게 값을 받아야함
    const targetScreen = context.overlap.content.page.filter((screen, index) => screen.uuid === screenUUID);
    console.log('스크린 사이즈',targetScreen)
    if(targetScreen.length == 0){
      console.error(`선택한 스크린을 찾을 수 없습니다. 선택한 스크린 의 ID : ${screenUUID} `)
      return
    }
    const copyScreen = Utility.deepCopy(targetScreen[0]);
    context.appendClipBoard('screen',copyScreen);
    return 
}

////////////////////////////////////////////////////////
// add ()
// 새로운 페이지를 만듭니다.
////////////////////////////////////////////////////////
/** add | 새 오버렙 페이지를 추가합니다.
 * @param {number} socket 오버랩을 생성한 사람의 아이디

 * @returns 오버랩을 생성합니다. 생성 성공시 생성한 오버랩의 아이디를 반환 합니다.
 *  */ 

export function add(self,index,initData) {
  self.addPage(index,initData)

}
////////////////////////////////////////////////////////
// aliasChange (별명)
// 스크린의 별명을 변경합니다.
////////////////////////////////////////////////////////
export function aliasChange(id,alias="") {
  let response =  Rest.request('screendata','Put',{screen_id:id,alias},sessionStorage.getItem("JWT"))
  response
  .then((result) => {
      return(result)
  })
  .catch((error) => {
      return(error)
  })
}

////////////////////////////////////////////////////////
// load (페이지ID)
// 선택한 페이지ID이 데이터를 가지고 옵니다.
////////////////////////////////////////////////////////
export function load(inputNo=null) {
  console.log('screendata')
    // return new Promise(function(resolve,reject){
    //     const state = store.getState(); // 현재 상태를 가져옵니다.
    //     const { screenNo, screenData } = state; // 편의상 비구조화 할당
    
    //     if(inputNo==null){inputNo = screenNo}
    //     let response =  Rest.request('screendata/'+inputNo,'GET')
    //     response
    //     .then(function (response) {
    //         // console.log(response)
    //         resolve(response);
    //       })
    //     .catch(function (error) {
    //         reject([error]);
    //       })
    //     // .then(function () {
    //     //     // always executed
    //     //   }); 
    //     });  
}

////////////////////////////////////////////////////////
// allload ()
// 모든 페이지의 데이터를 가지고 옵니다.
////////////////////////////////////////////////////////
export function allload(inputNo=null) {
    console.log('screendataall')
    // return new Promise(function(resolve,reject){
    //     const state = store.getState(); // 현재 상태를 가져옵니다.
    //     const { screenNo, screenData } = state; // 편의상 비구조화 할당
    //     let response =  Rest.request('screendataall','GET','',sessionStorage.getItem("JWT"))
    //     response
    //     .then(function (response) {
    //         console.log(response)
    //         resolve(response);
    //       })
    //     .catch(function (error) {
    //         reject(error);
    //       })
    //     // .then(function () {
    //     //     // always executed
    //     //   }); 
    //     });  
}

////////////////////////////////////////////////////////
// del (삭제할 스크린번호)
// 해당 페이지를 삭제합니다.
////////////////////////////////////////////////////////
export function del(screenIndex) {
  console.log(screenIndex)

  const state = store.getState(); // 현재 상태를 가져옵니다.
  const { screenNo } = state; // 편의상 비구조화 할당
  // 삭제후 화면이 유지되는 문제 해결 필요
  if(screenIndex==screenNo){
    console.error('해결필요');
  }
  let response =  Rest.request('screendata','DELETE',{index:screenIndex},sessionStorage.getItem("JWT"))
  response
  .then((result) => {
      return(result)
  })
  .catch((error) => {
      return(error)
  })
}

////////////////////////////////////////////////////////
// move (이동할 대상의 스크린넘버, 변경할 스크린넘버)
// 해당 
////////////////////////////////////////////////////////
/**
 * 스크린의 위치를 이동 시키는 함수입니다.
 * 
 * @param {provider} context EditProvider의 api 를 통해 제어 되기 때문에 context 가 필요합니다. 
 * @param {*} targetScreenUUID 움직일 대상의 스크린의 UUID
 * @param {*} index 움직일 위치를 결정합니다. 양수면 뒤로 음수면 앞으로 이동합니다.
 */

export function move(context,targetScreenUUID,index) {

  let orderList = context.overlap.content.order
  const targetIndex = orderList.indexOf(targetScreenUUID);
  console.log('targetIndex 이전',orderList);

  if(targetIndex !== -1) {
    const targetScreen = orderList[targetIndex];    
    orderList.splice(targetIndex, 1); 
    orderList.splice(targetIndex+index, 0, targetScreen); // 두 칸 앞의 위치에 숫자 4를 추가합니다.
}
  console.log('targetIndex 이후',orderList);
  context.modifyOverlap({'content':{'order':orderList}});

}

////////////////////////////////////////////////////////
// broadcastChange (스크린페이지)
// 브로드캐스트스크린을 변경합니다.
////////////////////////////////////////////////////////
export function broadcastChange(screenId) {
  // console.log(screenId)
  // return new Promise(function(resolve,reject){
  // let response =  Rest.request('showpage','Put',{showpage : screenId},sessionStorage.getItem("JWT"))
  // response
  // .then(function (response) {
  //   resolve(response);
  // })
  // .catch(function (error) {
  //     reject(error);
  //   })
  // });  
}

////////////////////////////////////////////////////////
// broadcastScreenload (스크린페이지)
// 브로드캐스트스크린을 변경합니다.
////////////////////////////////////////////////////////
export function broadcastScreenload(userid) {
  return new Promise(function(resolve,reject){
      let response =  Rest.request('broadcast/'+userid+'/','GET')
      response
      .then(function (response) {
          // console.log(response)
          resolve(response);
        })
      .catch(function (error) {
          reject(error);
        })
      // .then(function () {
      //     // always executed
      //   }); 
      });  
}

////////////////////////////////////////////////////////
// getScreen Data (스크린페이지)
// 스크린데이터를 가저옵니다.
////////////////////////////////////////////////////////

export function getScreenData() {
  const state = store.getState(); // 현재 상태를 가져옵니다.
  const { screenData } = state; // 편의상 비구조화 할당
  return screenData
}