////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { useState, useEffect, useContext, Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widgetEdit.scss"


// 컴포넌트 연결
import TextModule from '../editModule/TextModule'; // 텍스트 모듈
import TextSizeModule from '../editModule/TextSizeModule'; // 텍스트 크기 모듈
import FontModule from '../editModule/FontModule'; // 폰트 종류 모듈
import ColorModule from '../editModule/ColorModule'; // 색상 모듈
import SwitchModule from '../editModule/SwitchModule'; // 체크박스 모듈
import ImageButtonModule from '../editModule/ImageButtonModule';
import RangeModule from '../editModule/RangeModule'; //범위 모듈 
import NumberModule from '../editModule/NumberModule'; //넘버 모듈 

// 서비스 연결
import * as Editor from 'service/edit/EditService'; 
import * as ED from 'service/engine/decoder/EditorDecoder'; 
// 이미지 연결

import leftButton from 'assets/sidebar/text/left.png';
import centerButton from 'assets/sidebar/text/center.png';
import rightButton from 'assets/sidebar/text/right.png';
import justifyButton from 'assets/sidebar/text/justify.png';

import upperButton from 'assets/sidebar/text/upper.png';
import midButton from 'assets/sidebar/text/mid.png';
import lowerButton from 'assets/sidebar/text/lower.png';

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////


/** [에디터] 텍스트 편집 툴 입니다.
 * (대상위젯, 편집펑션, 편집할 객체 키, 타이틀 ,옵션)
* @param {widget} target : 편집할 대상 위젯
* @param {function} modify : 편집용 함수
* @param {{
* value: { text : text},
* style: { fontSize : number, fontFamily : string,textShadowValue: string, textShadowColor: string color : string,outlineColor : string, textOutline : number, }
* }} keyName : 해당 에디터가 수정하는 값 입니다. value : {'textValue', 수정할 대상값,} 
* @param {{
* editTitle: string,textValue: string
* }} title : 편집기에 표시될 제목들 입니다.
* @param {{
* fixToggle : boolean 
* openToggle : boolean
* }} option : 추가 옵션입니다. fixToggle : 토글을 고정합니다. openToggle : 필드의 기본 상태를 열림으로 봅니다.
*/


function NoticeEditForm({target,modify,keyName,title,option}) {


    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////

    // set
    const uuid = target.uuid
    const {style={},meta={},size={},value={},position={},display={},preset=null} = keyName
    // state
    const [Toggle, setToggle] = useState(option?.openToggle==undefined?true:option?.openToggle);
    const [OpenForm, setOpenForm] = useState(option?.openForm==undefined?true:option?.openForm);

    // rel 

    ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////
    useEffect(() => {

        // 여기에 컴포넌트가 마운트되었을 때 실행할 코드를 작성합니다.

        return () => {
        // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
        };
      }, []);

    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////

    // 해당 에디터에서 편집이 가능한 값 목록
    const {text,fontSize,fontFamily,textAlign,useFill,fontColor,outlineColor,textOutline} = keyName ;

    
    return (
    <Fragment>
    <style jsx>{`${FormStyle}`}</style>
      <section className='NoticeEditForm'>
          {Editor.editorDetail('텍스트','title',OpenForm,setOpenForm,Toggle)}
          <div className={OpenForm?'editContainer containerOpen':'editContainer containerClose'}>
              {Editor.editorSubTitle('텍스트 설정',"edit_note")}
              <article className="EditPart" >
              {text? <TextModule value={ED.getValue(target,text)} fontFamily={style.fontFamily} title={text?.title??"내용"} type = {text.key} placeholder="여기에 텍스트를 입력해주세요"  change={(type,value)=>modify(text.category,type,value,uuid)}/> : ''}
              {textAlign? <ImageButtonModule value={ED.getValue(target,textAlign)} title={textAlign?.title??"좌우정렬"} type = {textAlign.key} data={[{val:'left',image:leftButton,text:'text'},{val:'center',image:centerButton},{val:'right',image:rightButton},{val:'justify',image:justifyButton}]} change={(type,value)=>modify(textAlign.category,type,value,uuid)}/> : ''}
              {fontSize? <TextSizeModule value={ED.getValue(target,fontSize)} title={fontSize?.title??"글자크기"} type ={fontSize.key} change={(type,value)=>modify(fontSize.category,type,value,uuid)}/> : ''}
              {fontFamily? <FontModule value={ED.getValue(target,fontFamily)} title={fontFamily?.title??"폰트"} type ={fontFamily.key} change={(type,value)=>modify(fontFamily.category,type,value,uuid)}/> : ''}
              {useFill? <SwitchModule value={ED.getValue(target,useFill)} title={useFill?.title??"배경사용"} type ={useFill.key} change={(type,value)=>modify(useFill.category,type,value,uuid)}/> : ''}
              {fontColor? <ColorModule value={ED.getValue(target,fontColor)} title={useFill?.title??"글자색상"} type = {fontColor.key} change={(type,value)=>modify(fontColor.category,type,value,uuid)}/>: ''}
              </article>

            {/* {Editor.editorSubTitle('외곽선 설정',"border_style")}
              <article className="EditPart" >

              {textOutline? <NumberModule value={Number(ED.getValue(target,textOutline)/100)} min='0' max='99' step="0.5"  title={textOutline?.title??"선두께"} type={textOutline.key}  change={(type,value)=>modify(textOutline.category,type,value*100,uuid)}/>: ''}
              {outlineColor? <ColorModule value={ED.getValue(target,outlineColor)} title={outlineColor?.title??"선색상"} type={outlineColor.key}  change={(type,value)=>modify(outlineColor.category,type,value,uuid)}/>: ''}

              </article> */}
              
              {/* {Editor.editorSubTitle('외곽선 설정',"border_style")}
              <article className="EditPart" >

              {textOutline? <NumberModule value={Number(ED.getValue(target,textOutline)/100)} min='0' max='99' step="0.5"  title={textOutline?.title??"선두께"} type={textOutline.key}  change={(type,value)=>modify(textOutline.category,type,value*100,uuid)}/>: ''}
              {outlineColor? <ColorModule value={ED.getValue(target,outlineColor)} title={outlineColor?.title??"선색상"} type={outlineColor.key}  change={(type,value)=>modify(outlineColor.category,type,value,uuid)}/>: ''}

              </article> */}
          </div>
      </section>
    </Fragment>
    );

}

export default NoticeEditForm;

const FormStyle = `


`;