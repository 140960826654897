////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widget.scss"

// [프로바이더] 연결
import EditContext from 'service/context/EditContext' ; 

// 컴포넌트 연결
import WidgetOverRay from '../Edit/editController/WidgetOverRay'; // 메뉴 헤더

// 서비스 연결
import * as Event from '../../service/event/Actions' ; 
import * as Widget from '../../service/event/widget' ; 
import * as Model from '../../service/value/Model' ; 
import * as EnCoder from '../../service/engine/EnCoder' ; 

// 카메라 아이콘
import CameraIcon from 'assets/widget/camera.png';
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class Camera extends Component {
    
    static defaultProps = {
        widgetName: 'Camera',
      }

    //[임시] 컨텍스트 연결
    static contextType = EditContext;

    state = {
        ...Widget.DefaultState(this),
        moveStartX : 0,
        moveStartY : 0,
        cameraList :[],
        device : '자동설정',
    }

    constructor(props) {
        super(props);
        this.videoRef = React.createRef();
        this.widget = React.createRef();
    }

    DefaultMethod = (type,value) =>{
        Widget.DefaultMethod(this,type,value)
    }

    componentDidMount(){
        let cameraList = [] 
        if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
            console.log("enumerateDevices() not supported.");
            return;
          }
          navigator.mediaDevices.enumerateDevices()
          .then((devices) => {
            for (let index = 0; index < devices.length; index++) {
                if (devices[index].kind == 'videoinput'){
                    cameraList.push({label:devices[index].label,deviceId:devices[index].deviceId})
                }
            }
            this.setState({
                cameraList : cameraList
            },)
          })
          .catch(function(err) {

          });
    }

    startVideo = async (device) => {
        if(!device){
            device = true
        }
        if (navigator.mediaDevices){
            
            const stream = await navigator.mediaDevices.getUserMedia({ video: { deviceId: device }});
            if (this.videoRef && this.videoRef.current && !this.videoRef.current.srcObject) {
                this.videoRef.current.srcObject = stream;
            }       
        }
    };

    deviceSearch = (devicelabel) => {
        for (let index = 0; index < this.state.cameraList.length; index++) {
            if(this.state.cameraList[index].label == devicelabel)
            {
               return this.state.cameraList[index].deviceId
            }
        }
        return null
    }

    videoChange = ( ) => {
        if(this.props.data.value.device != this.state.device){
            this.setState({
                device : this.props.data.value.device
            })
        }
    }

    videoLink = (Style)=>{
        if(this.props.data.value.device != this.state.device){
            setTimeout(this.videoChange,1)
           return 
        }else{
           return <video style={Style.object}  autoPlay ref={this.videoRef} />
        }

    } 


    render() {
        let myDevice = this.deviceSearch(this.props.data.value.device)
        if(this.props.parents != 'view'&&this.state.cameraList.length != 0 ){
            this.startVideo(myDevice)
        }
        let notFound = false
        // 컴퓨터에 카메라가 없음
        if(this.state.cameraList.length==0){
            notFound = true 
        }
        // 컴퓨터에 해당 카메라가 없음 단 자동설정인 경우 제외
        else if(myDevice==null&&this.props.data.value.device!='자동설정'){
            notFound = true           
        }
        let Style = Model.widget(this.props)
        // 드레그를 통한 위젯 이동
        Style.layout= { ...Style.layout,transform: 'translate('+this.state.dragMoveX+'px, '+this.state.dragMoveY+'px)'}
        switch (this.props.parents) {
            case 'edit':
                // 편집시 화면이동 트렌스레이션 효과 방지
                Style.layout.transition = false
                Style.object.transition = false
                if(this.props.userState.browser== "firefox"&&"safari"){
                    return (         
                        <div ref={(ref) => this.object=ref } className = {this.props.parents + " widgetObject "+this.props.widgetName+Widget.display(this.props)} style={Style.layout} tabIndex={0} draggable="true" 
                            onKeyDown={(e)=>Widget.keyDown(e,this)}
                            onClick={(e)=> Widget.isfocus(e,this)} 
                            onMouseDown={(e)=> Widget.mouseControl(e,this)} 	
                            //드레그 이벤트	
                            onDragOver={(e)=>Widget.alt_drag(e,this)} 
                            onDragStart={(e) => Widget.alt_dragStart(e,this)} 
                            onDragEnd={(e) => Widget.alt_dragEnd(e,this)} 
                            onDrag={(e)=>e.stopPropagation()} 
                            //드랍 이벤트 
                            onDrop={(e)=> Widget.drop(e,this)} 
                            onDragLeave={(e)=> Widget.dragLeave(e,this)} 
                            onDragEnter={(e)=> Widget.dragEnter(e,this)} 
                        >
                            <img className="cameraBackground" style={Style.object}  ref={this.widget} src={CameraIcon}/>  
                            {this.videoLink(Style)}
                            <p className={(!notFound?'overlayInfo':'overlayWaring')} >
                            {(!notFound?'':'[찾을수 없음] ')}{this.props.data.value.device}
                            </p>
                            { this.props.focus ? <WidgetOverRay target={this.props} object={this.widget.current} mode={'8_Direction'} dragLock={this.state.dragLock} DefaultMethod={this.DefaultMethod}/> :"" } 
                        </div>
                    );
                }else{
                    return (         
                        <div ref={(ref) => this.object=ref } className = {this.props.parents + " widgetObject "+this.props.widgetName+Widget.display(this.props)} style={Style.layout} tabIndex={0} draggable="true" 
                            onKeyDown={(e)=>Widget.keyDown(e,this)}
                            onClick={(e)=> Widget.isfocus(e,this)} 
                            onMouseDown={(e)=> Widget.mouseControl(e,this)} 	
                            //드레그 이벤트
                            onDrag={(e)=>Widget.drag(e,this)} 
                            onDragStart={(e) => Widget.dragStart(e,this)} 
                            onDragEnd={(e) => Widget.dragEnd(e,this)} 
                            //드랍 이벤트 
                            onDrop={(e)=> Widget.drop(e,this)} 
                            onDragOver={(e)=> Widget.dragOver(e,this)} 
                            onDragLeave={(e)=> Widget.dragLeave(e,this)} 
                            onDragEnter={(e)=> Widget.dragEnter(e,this)} 
                        >
                            <img className="cameraBackground" style={Style.object}  ref={this.widget} src={CameraIcon}/>  
                            {this.videoLink(Style)}
                            <p className={(!notFound?'overlayInfo':'overlayWaring')} >
                            {(!notFound?'':'[찾을수 없음] ')}{this.props.data.value.device}
                            </p>
                            { this.props.focus ? <WidgetOverRay target={this.props} object={this.widget.current} mode={'8_Direction'}/> :"" } 
                        </div>
                    );
                }
            case 'broadcast':
                return (
                    <div ref={(ref) => this.object=ref } className = {this.props.parents + " widgetObject "+this.props.widgetName+Widget.display(this.props)} style={Style.layout} >
                        {this.videoLink(Style)}
                    </div>
                );
            case 'view':
                return (
                    <div ref={(ref) => this.object=ref } className = {this.props.parents + " widgetObject "+this.props.widgetName+Widget.display(this.props)} style={Style.layout} >
                        <div className = "CameraLoding"  style={Style.object} >
                            <img src={CameraIcon}></img>    
                        </div>

                    </div>
                );
            default:
                break;
        }

    }
}
////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    userState : state.userState,
})

function mapDispatchToProps(dispatch){
    return {
        //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}


  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(Camera);
  