////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { useState, Fragment , useContext, useEffect} from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { useHistory,useLocation } from 'react-router-dom';

// [리덕스]스토어 연결

// [프로바이더]
import EditContext from 'service/context/EditContext' ; 

// 컴포넌트 연결
import PreviewDisplay from 'components/display/PreviewDisplay' // 오버랩 디스플레이
import StaticDisplay from 'components/display/StaticDisplay'; // 오버랩 디스플레이
// import AppendPanel from 'components/edit/editPanel/append/AppendPanel'; // 상단 메뉴

// 이미지 연결
import logoW from 'assets/logoW.svg';

// 서비스 연결
import * as Editor from 'service/edit/EditService' ; 
import * as templateAPI from 'service/api/template' ; 
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
function TemplateObject({page,clickTemplate}) {



    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////

    //set
    const context = useContext(EditContext);

    // state
    let [getPreview, setPreview] = useState(null);
    // rel 


   ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////
    useEffect(() => {
        // 여기에 컴포넌트가 마운트되었을 때 실행할 코드를 작성합니다.
        getPagePreview() 
        // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
        return () => {
          console.log('Component will unmount');
        };
      }, []);

    async function getPagePreview(){
        const NewPreview = [];
        console.log('체크 데이터',page,page.page_order?.[0])
        if(page.page_order?.[0]){
            const templatePreview = await templateAPI.getPageForUUID(page.page_order?.[0]);
            console.log('templatePreview',templatePreview?.[0])
            NewPreview.push(templatePreview[0])
            setPreview(NewPreview)
        }
    }

    const setSelect =  () =>{

    }

    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////

    console.log('page',page);

    const Width = page.resolution_width;
    const Height = page.resolution_height; 
    return (
        <Fragment>
              <div className='templateFile'  key={page.uuid} onClick={()=>clickTemplate(page.uuid)} >
              <style jsx>{`${SpaceModuleStyle}${ContainerStyle}`}</style>
              <div className = {"templateInfo "+page.uuid} onClick={()=>setSelect()}>

                <div className='upperInfo'>
                  <div className = "templateState">
                  {/* <svg className="broadcastScreenLive" width="30px" height="30px" xmlns="http://www.w3.org/2000/svg" version="1.1">
                    <defs>
                        <filter id="shadow" x="-20%" y="-20%" width="140%" height="140%">
                            <feDropShadow dx="1" dy="1" stdDeviation="1"/>
                        </filter>
                    </defs>
                    <circle cx="10" cy="10" r="8" fill="red" stroke="#fff" strokeWidth="3" filter="url(#shadow)" />
                </svg> */}
                  </div> 
                  {getPreview == null? 
                    <div className = "preView readyPreView" >
                      <img className='icon' style={{aspectRatio : `${Width} / ${Height}`,}}  src={logoW}/>
                    </div> 
                    :
                    <div className = "preView">
                      <PreviewDisplay pageList={getPreview} referenceWidth={Width??0} referenceHeight={Height??0}/>  
                      {/* <MiniView zoom={0.1} data={getPreview?.[0]?.page_widget?getPreview?.[0]?.page_widget:[]} />   */}
                    </div> 
                }
                </div>
                <div className='lowerInfo'>
                  <div className = "info">
                      <div className = "title">
                      {page.title}
                      </div>
                      {/* <div className = "summary">
                      오버랩의 타이틀 오버랩의 타이틀 오버랩의 타이틀 오버랩의 타이틀 오버랩의 타이틀 오버랩의 타이틀 오버랩의 타이틀 오버랩의 타이틀
                      </div> */}
                  </div>
                </div>
              </div>
            </div>
        </Fragment>
    );

}

////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    mainScreen : state.mainScreen,
})

function mapDispatchToProps(dispatch){
    return {
        initOverlap : (overlapData) => dispatch({type:'initOverlap',overlap : overlapData}),
    }
}

  // export default EditPage;
export default connect(mapStateToProps,mapDispatchToProps)(TemplateObject);
  


  const SpaceModuleStyle = `
  .ViewListContainer {

  }
  
  `;
  
  const ContainerStyle = `
  @keyframes start {
    0% {
      opacity: 0;

    }
    100% {
      opacity: 1;

    }
  }

  .templateFile {
    animation: start 0.6s forwards;
      display: flex;
      justify-content: center;
      width: 100%;
  }
  .templateFile .templateInfo{
      justify-self: center;
      box-sizing: content-box;
      width: 192px;
      height: 150px;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      transition: all 0.3s;
  }
  .templateFile .upperInfo{
      position: relative;
      width: 192px;
      height: 108px;
  }
  .templateFile .viewState{
      position: absolute;
      display: flex;
      justify-content: end;
      width: 192px;
      padding: 5px 5px;
      height: 40px;
      z-index: 1;
  }
  .templateFile .preView{
      position: absolute;
      width: 192px;
      height: 108px;
      overflow: hidden;
      border-radius: 5px ;
      transition: all 0.5s;
  }
  .templateFile .readyPreView{
      background-color: rgb(28, 28, 28);
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto;
  }
  .templateFile .MiniViewloding{
      box-sizing: border-box;
      border: 3px solid white;       
      background-image: url('../assets/logoW.svg');
      background-repeat: no-repeat;
      background-size: 80% 80%;
      background-position: center center;    
  }
  .templateFile .lowerInfo{
  
  }
  .templateFile .info{
    display: flex;
    justify-content: center;
  }
  .templateFile .title{
      overflow: hidden;
      height: 20px;
      font-size: 15px;
      font-family: '프리텐다드Regular';
      line-height: 15px;
      margin: 10px 0px;
  }
  .templateFile .icon{
    width: 60%;
    height: 60%;
}
  
`;