////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { useState, useEffect, useContext, Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widgetEdit.scss"

// 컴포넌트 연결
import PositionModule from '../editModule/PositionModule'; // 위젯의 사이즈를 조정 하는 모듈입니다.
import ErrorModule from '../editModule/ErrorModule'; // 잘못된 위젯의 값이 있을때 편집기를 대체합니다.
import ColorModule from '../editModule/ColorModule'; // 색상 모듈
// 서비스 연결
import * as Editor from 'service/edit/EditService'; 
import * as ED from 'service/engine/decoder/EditorDecoder'; 

// 이미지 연결

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////


/** [에디터] 텍스트 편집 툴 입니다.
 * (대상위젯, 편집펑션, 편집할 객체 키, 타이틀 ,옵션)
* @param {widget} target : 편집할 대상 위젯
* @param {function} modify : 편집용 함수
* @param {{
* style: { x : number, y : number }
* }} keyName : 해당 에디터가 수정하는 값 입니다. value : {'textValue', 수정할 대상값,} 
* @param {{
* editTitle: string,textValue: string
* }} title : 편집기에 표시될 제목들 입니다.
* @param {{
* fixToggle : boolean 
* openToggle : boolean
* }} option : 추가 옵션입니다. fixToggle : 토글을 고정합니다. openToggle : 필드의 기본 상태를 열림으로 봅니다.
*/


function ShadowEditForm({target,modify,keyName,title,option}) {

    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////

    // set
    const uuid = target.uuid
    const {style={},meta={},size={},value={},position={},display={},preset=null} = keyName
    // state
    const [Toggle, setToggle] = useState(option?.openToggle==undefined?true:option?.openToggle);
    const [OpenForm, setOpenForm] = useState(option?.openForm==undefined?true:option?.openForm);

    // rel 

    ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////
    useEffect(() => {

        // 여기에 컴포넌트가 마운트되었을 때 실행할 코드를 작성합니다.

        return () => {
        // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
        };
      }, []);

    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////

       // 해당 에디터에서 편집이 가능한 값 목록
       const {color,direction,shadow} = keyName ;


       const iconStyle = (option) =>{

        const ShadowDirection  = {
            LeftTop : '-5px -5px 0px ',
            LeftBottom : '-5px 5px 0px ',
            RightTop : '5px -5px 0px ',
            RightBottom : '5px 5px 0px ',
        }
        const Rirection  = {
            LeftTop : {top: '5px',left: '5px'},
            LeftBottom : {top: '0px',left: '5px'},
            RightTop : {top: '5px',left: '0px'},
            RightBottom : {top: '0px',left: '0px'},
        }
        


        let opacity= Math.round(ED.getValue(target,shadow)/10 * 255).toString(16)
        if (opacity.length==1){ opacity= 0+opacity }
        switch (option) {
            case 'LeftTop':
                return { 
                    width: '13px',
                    height: '13px',
                    top: '5px',
                    left: '5px',
                    filter: 'drop-shadow(-5px -5px 0px '+ED.getValue(target,direction)+opacity+')'
                }
            case 'LeftBottom':
                return { 
                    width: '13px',
                    height: '13px',
                    top: '0px',
                    left: '5px',
                    filter: 'drop-shadow(-5px 5px 0px '+ED.getValue(target,direction)+opacity+')'
                }
            case 'RightTop':
                return { 
                    width: '13px',
                    height: '13px',
                    top: '5px',
                    left: '0px',
                    filter: 'drop-shadow(5px -5px 0px '+ED.getValue(target,direction)+opacity+')'
                }
            case 'RightBottom':
                return { 
                    // width: '13px',
                    // height: '13px',
                    top: '0px',
                    left: '0px',
                    filter: 'drop-shadow(5px 5px 0px '+ED.getValue(target,direction)+opacity+')'
                }
            case 'shadowLarge':
                return { 

                    ...Rirection[ED.getValue(target,direction)],
                    filter: 'drop-shadow( 5px 5px 0px '+ED.getValue(target,color)+'e6'+')'
                }
            case 'shadowMedium':
                return { 

                    ...Rirection[ED.getValue(target,direction)],
                    filter: 'drop-shadow( 5px 5px 0px '+ED.getValue(target,color)+'99'+')'
                }
            case 'shadowSmall':
                return { 
        
                    ...Rirection[ED.getValue(target,direction)],
                    filter: 'drop-shadow( 5px 5px 0px '+ED.getValue(target,color)+'4d'+')'
                }
            case 'shadowNone':
                return { 
           
                    ...Rirection[ED.getValue(target,direction)],
                    filter: 'drop-shadow( 5px 5px 0px '+ED.getValue(target,color)+'00'+')'
                }
            case 'shortShadowLarge':
                return { 

                    ...Rirection[ED.getValue(target,direction)],
                    filter: 'drop-shadow( 3px 3px 0px '+ED.getValue(target,color)+'e6'+')'
                }
            case 'shortShadowMedium':
                return { 

                    ...Rirection[ED.getValue(target,direction)],
                    filter: 'drop-shadow( 3px 3px 0px '+ED.getValue(target,color)+'99'+')'
                }
            case 'shortShadowSmall':
                return { 
        
                    ...Rirection[ED.getValue(target,direction)],
                    filter: 'drop-shadow( 3px 3px 0px '+ED.getValue(target,color)+'4d'+')'
                }
            case 'shortShadowNone':
                return { 
            
                    ...Rirection[ED.getValue(target,direction)],
                    filter: 'drop-shadow( 3px 3px 0px '+ED.getValue(target,color)+'00'+')'
                }
            default:
                break;
        }
    } 

    const shadowDirection = () =>{
        if (direction){
            let list = (
                <label className={"ButtonModule shadowDirectionModule "+(target.data.style.shadowValue==0?'moduleHide':'') } htmlfor="fname"> 
                    <p>방향</p>
                    <span className="ButtonList" >
                    <div className={'shadowButton optionButton '+(ED.getValue(target,direction)=="RightTop"?'buttonSelect':'')} onClick={()=>modify(direction.category,direction.key,'RightTop',uuid)}> 
                        <div className="shadowEx shadowExRightTop " style={iconStyle('RightTop')}/>
                    </div>
                    <div className={'shadowButton optionButton '+(ED.getValue(target,direction)=="LeftTop"?'buttonSelect':'')} onClick={()=>modify(direction.category,direction.key,'LeftTop',uuid)}> 
                        <div className="shadowEx shadowExLeftTop "  style={iconStyle('LeftTop')}/>
                    </div>
                    <div className={'shadowButton optionButton '+(ED.getValue(target,direction)=="RightBottom"?'buttonSelect':'')} onClick={()=>modify(direction.category,direction.key,'RightBottom',uuid)}> 
                        <div className="shadowEx shadowExRightBottom "  style={iconStyle('RightBottom')}/>
                    </div> 
                    <div className={'shadowButton optionButton '+(ED.getValue(target,direction)=="LeftBottom"?'buttonSelect':'')} onClick={()=>modify(direction.category,direction.key,'LeftBottom',uuid)}> 
                        <div className="shadowEx shadowExLeftBottom "  style={iconStyle('LeftBottom')}/>
                    </div>
                    </span>
                </label>
            )
            return (list)    
        }
    }

    const shadowDeep= () =>{
        if (shadow){
            let list = (
                <label className="ButtonModule" htmlfor="fname"> 
                    <p>강도</p>
                    <span className="ButtonList" >
                    <div className={'shadowButton optionButton '+(ED.getValue(target,shadow)==3?'buttonSelect':'')} onClick={()=>modify(shadow.category,shadow.key,3,uuid)}> 
                        <div className={"shadowEx shadowLarge"} style={iconStyle('shadowLarge')} />
                    </div>
                    <div className={'shadowButton optionButton '+(ED.getValue(target,shadow)==2?'buttonSelect':'')} onClick={()=>modify(shadow.category,shadow.key,2,uuid)}>
                        <div className={"shadowEx shadowMedium"} style={iconStyle('shadowMedium')}/>
                    </div> 
                    <div className={'shadowButton optionButton '+(ED.getValue(target,shadow)==1?'buttonSelect':'')} onClick={()=>modify(shadow.category,shadow.key,1,uuid)}> 
                        <div className={"shadowEx shadowSmall"} style={iconStyle('shadowSmall')}/>
                    </div>
                    <div className={'shadowButton optionButton '+(ED.getValue(target,shadow)==0?'buttonSelect':'')} onClick={()=>modify(shadow.category,shadow.key,0,uuid)}>
                        <div className={"shadowEx shadowExnone"} style={iconStyle('shadowNone')}/>
                    </div>
                    </span>
                </label>
            )
            return list
        }
    }

    const shadowDeepShort= () =>{
        if (shadow){
            let list = (
                    
                    <span className="ShortButtonList" >
                    <div className={'shortButton shortOptionButton '+(ED.getValue(target,shadow)==3?'shortButtonSelect':'')} onClick={()=>modify(shadow.category,shadow.key,3,uuid)}> 
                        <div className={"shortShadowEx shadowLarge"} style={iconStyle('shadowLarge')} />
                    </div>
                    <div className={'shortButton shortOptionButton '+(ED.getValue(target,shadow)==2?'shortButtonSelect':'')} onClick={()=>modify(shadow.category,shadow.key,2,uuid)}>
                        <div className={"shortShadowEx shadowMedium"} style={iconStyle('shadowMedium')}/>
                    </div> 
                    <div className={'shortButton shortOptionButton '+(ED.getValue(target,shadow)==1?'shortButtonSelect':'')} onClick={()=>modify(shadow.category,shadow.key,1,uuid)}> 
                        <div className={"shortShadowEx shadowSmall"} style={iconStyle('shadowSmall')}/>
                    </div>
                    <div className={'shortButton shortOptionButton '+(ED.getValue(target,shadow)==0?'shortButtonSelect':'')} onClick={()=>modify(shadow.category,shadow.key,0,uuid)}>
                        <div className={"shortShadowEx shadowExnone"} style={iconStyle('shadowNone')}/>
                    </div>
                    </span>
               
            )
            return list
        }
    }


    const Options = {}


    if(option.closeEdit){
        Options.closeEdit = (<div className='closeEdit'>
            {shadowDeepShort()} 
        </div>)
    }



    return (
    <Fragment>
        <style jsx>{`${FormStyle}`}</style>
        <section className='ShadowEditForm'>
            {Editor.editorDetail('그림자','flip_to_front',OpenForm,setOpenForm,Toggle,Options)}
            <div className={OpenForm?'editContainer containerOpen':'editContainer containerClose'}>
                <article className="EditPart" >
           
                <div className={'useShadow '+(target.data.style.shadowValue==0?'moduleHide':'')}>
                    {/* {shadowDirection()} */}
                    {shadowDeep()} 
                    {color? <ColorModule value={ED.getValue(target,color)} title={color?.title??"그림자 색상"} type={color.key} change={(type,value)=>modify(color.category,type,value,uuid)}/>: ''}
                </div>

                </article>
                
            </div>
        </section>
    </Fragment>
    );

}


export default ShadowEditForm;

const FormStyle = `
.ShadowEditForm .shadowEx{
    position: relative;
    margin: 3px;
    width: 13px;
    height: 13px;
    background-color: #999;
}
.ShadowEditForm .shadowExLeftTop{
    top: 5px;
    left: 5px;
    filter: drop-shadow(-5px -5px 0px #000);

}
.ShadowEditForm .shadowExLeftBottom{
    top: 0px;
    left: 5px;
    filter: drop-shadow(-5px 5px 0px #000);
}
.ShadowEditForm .shadowExRightTop{
    top: 5px;
    left: 0px;
    filter: drop-shadow(5px -5px 0px #000);
}
.ShadowEditForm .shadowExRightBottom{
    top: 0px;
    left: 0px;
    filter: drop-shadow(5px 5px 0px #000);
}
.ShadowEditForm .shadowExCenter{
    top: 2.5px;
    left: 2.5px;
}
.ShadowEditForm .shadowBlack{
    background-color: black;
}

.ShadowEditForm .closeEdit{
    height: 25px;
    display:flex;
    background-color: #444;
    border-radius :5px;
    padding : 0px;
}
.ShadowEditForm .closeEdit .ShortButtonList{
   display : flex; 
   width: 110px;
   justify-content: space-around;
   align-items: center;

}
.ShadowEditForm .closeEdit .shortButton{
    width: 20px;
    height: 20px;
}

.ShadowEditForm .closeEdit .shortShadowEx{

    position: relative;
    margin: 3px;
    width: 10px;
    height: 10px;
    background-color: #999;
}

.ShadowEditForm .closeEdit .shortButtonSelect .shadowEx{
    background-color: #fff;
}

.ShadowEditForm .closeEdit .shortButtonSelect{
    background-color: #262626;
    border-radius : 2.5px;
}

.shadowLarge{

}
.shadowMedium{

}
.shadowSmall{

}
`;


