////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, {useState, useEffect, useRef } from 'react';
// 라이브러리 

// 설정 파일
import * as config from 'config/OverlapConfig'

// 위젯 

// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import 'sass/login.scss'

// 이미지 연결
import logoW from 'assets/logoW.svg';

// 서비스 연결
import * as page from 'service/api/page';

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

function VideoStream({streamSource}) {

    let [getPreview, setPreview] = useState(null);
    const videoRef = useRef(null);
    ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////

    useEffect(() => {
    
        // Cleanup function (optional)
        return () => {
          console.log('Component will unmount');
        };
      },[]);
    
      useEffect(() => {
        if (videoRef.current) {
          videoRef.current.srcObject = streamSource;
        }
      }, [streamSource]);


      const Style = {
        label: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
        },
        textarea : {
            width: '100%',
            resize: 'none',
            boxSizing:'border-box',
        },
        mediaView : {
            width: '100%',
            MaxHeight : '500px',
        }

    }


    return (
        <div className='VideoStream'>
              <video style={Style.mediaView} ref={videoRef} autoPlay>

              </video>
              
        </div>
    );
  }

  export default VideoStream;

  