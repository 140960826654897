////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widgetEdit.scss"


// 컴포넌트 연결
import TimeModule from '../editModule/TimeModule'; // 타이머 모듈
import RadioButtonModule from '../editModule/RadioButtonModule'; //범위 모듈 
import ImageButtonModule from '../editModule/ImageButtonModule';
// 서비스 연결

// 이미지 연결

import time_1m from 'assets/sidebar/timer/time_1m.png';
import time_3m from 'assets/sidebar/timer/time_3m.png';
import time_5m from 'assets/sidebar/timer/time_5m.png';
import time_10m from 'assets/sidebar/timer/time_10m.png';


////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class CountdownEdit extends Component {
    
    state = {
 
    }

    change = (name,value) =>{
        this.props.callBack('style',name,value)
    }
    valueChange = (name,value) =>{
        this.props.callBack('value',name,value)
    }

    radioChange = (e) => {
        this.setState({
            raido : e.target.value
        })
    }

    render() { 

        const Style = {
            label: {
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
            },
            textarea : {
                width: '100%',
                resize: 'none'
            }
        }
        return (
            <Fragment>
            <fieldset className="EditModuleField" style={Style.fieldset} >
                <legend style={Style.legend} > 시간 설정 </legend>
                <div  className="timerSelect" >
                <TimeModule value={this.props.data.value.time} model="ms" title="시간" type ="time" change={this.valueChange}/>
                </div>
                <ImageButtonModule value={this.props.data.value.time} type ="time" data={[{val:60,image:time_1m},{val:180,image:time_3m},{val:300,image:time_5m},{val:600,image:time_10m},]} title="빠른설정"  change={this.valueChange}/>
            </fieldset>
                 
            </Fragment>
        );
    }
}

////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    // screenData : state.screenData,
})

function mapDispatchToProps(dispatch){
    return {
    //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(CountdownEdit);
  