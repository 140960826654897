// 프로모션 페이지 - 헤더색션 히어로 색션

////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component, Fragment , useState, useEffect, useRef} from 'react';

import { Route, Link } from 'react-router-dom';
// [리덕스]스토어 연결

// 이미지 연결
import logoW from 'assets/logoW.svg';
import PromotionVideo from 'assets/Promotion/overlapPromotion1.mp4';

// 서비스 연결 
import * as apiAccount from 'service/api/apiAccount';
// SASS&CSS 연결
import "sass/frontPage.scss"

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

function AccountBanner () {


   ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////
    useEffect(() => {
        test();
        // 여기에 컴포넌트가 마운트되었을 때 실행할 코드를 작성합니다.
        return () => {
        // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
        };
    }, []);

    const test =  async () => {
        const AccountState = await apiAccount.getAccount();

        console.log('AccountState',AccountState)
    
        const userProfile = await apiAccount?.getProfile();
    // 여기 문제......!!!! undefined 나오는 이유가 뭔지 체크 
        console.log('userProfile',userProfile)
    }

    return  (
    <Fragment>
    <style jsx>{`${ComponentStyle}`}</style>
        <section id='TopNavSection'>
            <Link  to="/notice">
            <button>
            공지사항
            </button> 
              
            </Link>
            <Link  to="/notice">
            <button>
            업데이트
            </button> 
              
            </Link>
        </section>
      </Fragment>
    );

}

export default AccountBanner;



const ComponentStyle = `

#TopNavSection{
    position: absolute;
    display :flex;
    width : 100vw;
    height : 50px;

    padding : 20px; 
    box-sizing: border-box;
    top: 0px;
    justify-content: center;
    align-items: center;
    z-index : 5;

    
}


#TopNavSection button{
    color : #ffffff88;
    font-size : 20px;
    font-family: '프리텐다드ExtraBold';
    transition: color 0.3s;
    margin : 0px 20px;
}

#TopNavSection button:hover{
    color : #ffffff;
    font-size : 20px;
    font-family: '프리텐다드ExtraBold';
}



`;