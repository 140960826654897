////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { useState, useEffect, useContext, Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widgetEdit.scss"


// 컴포넌트 연결
import GuideBalloon from 'components/common/form/GuideBalloon'// 가이드 벌룬
import ImageButtonModule from 'components/Edit/editModule/ImageButtonModule'; // 이미지 버튼을 눌러 여러 옵션중 값을 정합니다.
// import Show from '../show/Show'; // 메뉴 헤더

// 이미지 연결
import logoW from 'assets/logoW.svg';

// 서비스 연결
import * as Editor from 'service/edit/EditService'; 
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
function ScoreboardModule({target,modify,keyList,mode,teamState,removeTeam}) {
    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////

    // set

    // state

    // rel 

   ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////
    useEffect(() => {
        // 여기에 컴포넌트가 마운트되었을 때 실행할 코드를 작성합니다.
        return () => {
        // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
        };
    }, []);
    
    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////

    return (
        <Fragment>
            {/* CSS 는 상위 컴포넌트인 ScoreboardEditForm 에 있습니다. */}
        <div className='ScoreboardModule'>
        {teamState=='noti'||mode=='delete' ?
            <div className='ScoreboardNoti'>
                <div className='NotiInfo'>
                    {teamState=='noti'?

                            <h3>
                            {Editor.icon({icon:'warning',size:25,lineHeight:30})}
                            &nbsp;
                            비활성화 된 팀
                            </h3>

                    :null }
                    {mode=='delete'?
                    <div className='deleteTeam' onClick={()=>removeTeam(target.id)}>
                    팀 삭제하기
                    </div>
                    :null }
                </div>
            </div>
                 :null}
            <div className='defaultField'>
            {keyList?.includes('logo')?
            <div className='TeamImage'>
            <img src={target?.teamLogo??logoW} />
            </div>
            :null}
            <div className='TeamInfo'>

                <div className='TeamName'>

                <input className='TeamNameInput' placeholder='팀 이름 입력' value={target?.teamName??''} onChange={(e)=>modify('teamName',e.target.value)} />
                </div>

                <div className='TeamPoint'>
                <small>팀 점수</small> <input className='TeamPointInput' value={target?.teamPoint??''} max={99} placeholder='0' pattern="^\d{2}$" type='number'  onChange={(e)=>modify('teamPoint',Number(e.target.value)??0)}  />
                </div>

                <div className='TeamColor'>
                <small>팀 컬러</small> <input className='TeamColorInput' value={target?.teamColor??''} type='color'  onChange={(e)=>modify('teamColor',e.target.value)}  />
                </div>


            </div>
            </div>
            <div className='extendedField'>
            <div className='TeamColor'>
                <small>팀 폰트 컬러</small> <input className='TeamColorInput' value={target?.teamFontColor??''} type='color'  onChange={(e)=>modify('teamFontColor',e.target.value)}  />
                </div>
            </div>
            
                {keyList?.includes('logo')?
                <div className='TeamLogoInfo'>
                    <div className='TeamLogo editRow'>
                    <small>이미지 주소</small> 
                    <textarea className='TeamLogoInput' value={target?.teamLogo??''} onChange={(e)=>modify('teamLogo',e.target.value)} ></textarea>
                    </div>
                </div>
                :null}
        </div>
        </Fragment>
    )
}
export default ScoreboardModule;

