////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment ,Profiler} from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { Route, Link } from 'react-router-dom';

// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/edit.scss"

// 컴포넌트 연결
// import TemplateScreenList from './TemplateScreenList'; // 스크린 리스트
import MiniView from 'components/viewer/MiniView'; // 작은 스크린

// 서비스 연결
import * as Modal from '../../../service/event/ModalControl';
import * as Template from '../../../service/event/template';


////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class TemplateList extends Component {
    
    state = {
        show : true,
    }


    mainEdit = () => {
 
    }
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
      }
    componentDidMount() {
        this.setState({
            zoom : this.myRef.current.clientWidth*1.0/this.props.broadCast.size.width,
        })
   
    }
    
    render() { 
        let pagedata = this.props.teamplate?.pages[0]?.data
        if(!pagedata){ pagedata =[]}
        return (
            <div className='TemplateList' onClick={()=>this.props.changeTemplate(this.props.teamplate.key)}>
                <div className="TemplateScreenView" ref={this.myRef}>
                    <MiniView zoom={this.state.zoom} data={pagedata} />  
                </div>
                <div  className="TemplateInfo">
                <div className="TemplateProfile">
                    <h3>{this.props.teamplate.title}</h3>
                    <p>{this.props.teamplate.info}</p>
                    </div>
                    <div className="TemplateDetail">
                    {/* <p>페이지 수 : </p>
                    <p>페이지 수 : </p> */}
                    </div>
                </div>
            </div>
        );
    }
}
////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    screenRefresh : state.screenRefresh,
    broadCast : state.broadCast,
})

function mapDispatchToProps(dispatch){
    return {
        screenRefreshDispatch : (state) => dispatch({type:'screenRefresh',state : state}),
    //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(TemplateList);
  