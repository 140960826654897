////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widgetEdit.scss"


// 컴포넌트 연결
// import Show from '../show/Show'; // 메뉴 헤더

// 서비스 연결
import * as Event from '../../../service/event/Actions' ; 
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class TimeModule extends Component {


    state = {
        hrs : 0,
        min : 0,
        sec : 0,
        time : 0,
    }

    timeChange = (e) => {
        let hrs = this.state.hrs
        let min = this.state.min
        let sec = this.state.sec

        switch (e.target.name) {
            case 'hrs':
                hrs = e.target.value
                break;
            case 'min':
                min = e.target.value
                break;      
            case 'sec':
                sec = e.target.value
                break; 
            default:
                break;
        }
        this.props.change(this.props.type,Number(hrs)*3600+Number(min)*60+Number(sec))
    }

    timerView = () => {
        let count = this.props.value
        let text = []
        // 1시간 이상 시간남음
        if (count>3600){
            this.setState({
                hrs : Math.floor(count/3600)
            })
        }
        // 1분 이상 시간남음
        if (count>60){
            this.setState({
                min : Math.floor(count%3600/60)
            })
        }
        // 1분 이하 시간남음
        if (count>0){
            this.setState({
                sec : Math.floor(count%60) 
            })
        }
        // 0초
        else if (count==0){

        }
        // 남은시간 음수
        else {

        }

    }

    time = (time) => {
        let count = this.props.value
        switch (time) {
            case 'hrs':
                return Math.floor(count/3600) 
            case 'min':
                return Math.floor(count%3600/60) 
            case 'sec':
                return Math.floor(count%60) 
            default:
                break;
        }
    }

    timerSet = () => {
        this.setState({
            hrs : Math.floor(this.props.value/3600) ,
            min : Math.floor(this.props.value%3600/60) ,
            sec : Math.floor(this.props.value%60) ,
        })
    }

    componentDidMount (){
        this.timerSet()
    }   

    componentDidUpdate (prevProps, prevState){
        if(prevProps.value!=this.props.value){
            this.timerSet()
        }
    }

    render() { 
        console.log(this.props.value)
        return (
            <Fragment>
                <label className="EditModule" > 
                    <div className="TimeModuleLayout">
                        <div className = "timeInput"><input type="number" name="hrs" min='0' max='24' value={this.state.hrs} onBlur={(e) => this.timeChange(e)} onChange={(e) => this.timeChange(e)}/> <span>시간</span></div>
                        <div className = "timeInput"><input type="number" name="min" min='0' max='60' value={this.state.min} onBlur={(e) => this.timeChange(e)} onChange={(e) => this.timeChange(e)}/> <span>분</span></div>
                        <div className = "timeInput"><input type="number" name="sec" min='0' max='60' value={this.state.sec} onBlur={(e) => this.timeChange(e)} onChange={(e) => this.timeChange(e)}/> <span>초</span></div>
                    </div>
                    
                </label>
            </Fragment>

        );
    }
}

////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    // screenData : state.screenData,
})

function mapDispatchToProps(dispatch){
    return {
    //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(TimeModule);
  