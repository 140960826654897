////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component, Fragment , useState, useEffect, useRef} from 'react';

import { Route, Link } from 'react-router-dom';
// [리덕스]스토어 연결

// 이미지 연결
import logoW from 'assets/logoW.svg';
import PromotionVideo from 'assets/Promotion/overlapPromotion1.mp4';

// 컴포넌트 연결
import HeroSection from 'components/Promotion/HeroSection'; // 오버랩 인트로 색션
import OverviewSection from 'components/Promotion/OverviewSection'; // 중요한 내용 색션
import BottomSection from 'components/Promotion/BottomSection'; // 바텀 부분 색션
import FeatureSection from 'components/Promotion/FeatureSection'; // 오버랩의 특징 설명 색션

import AccountBanner from 'components/Promotion/AccountBanner'; // 어카운트 관련 배너
// SASS&CSS 연결
import "sass/frontPage.scss"

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////


function PromotionPage () {
    
    const [name, setName] = useState(''); // 입력된 이름을 저장할 상태
    const [FrontPageState, setFrontPageState] = useState(''); // 입력된 이름을 저장할 상태
    const [submittedName, setSubmittedName] = useState(''); // 제출된 이름을 저장할 상태
  
    const handleChange = (event) => {
      setName(event.target.value); // 입력 필드가 변경될 때 상태 업데이트
    };
  
    const handleSubmit = () => {
      setSubmittedName(name); // 버튼 클릭 시 이름을 제출
    };
  
    return  (
    <Fragment>
    <style jsx>{`${PageStyle}`}</style>
      <div id = "PromotionPage" className='' >
       
        <div id = "PromotionContent" >
            <HeroSection/>
            <AccountBanner/>
            {/* <OverviewSection/> */}
            <FeatureSection/>
            <BottomSection/>
            {/* <section>
                <div className='LowerLayer'>
                <video src={PromotionVideo} width={'100%'} height={'100%'} autoPlay={true} muted loop />
                </div>

                <div className='HigherLayer'>
                <div className='headText'>
                    PPT 처럼 손쉽게 만들어가는 방송
                </div>
                <div className='infoText'>
                    오버랩은 스트리밍을 관리해주는 쉽고 강력한 서비스 입니다.
                </div>
                <Link className="startLink" to="/login">
                    <button  className='loginButton'>
                        시작하기
                    </button>    
                </Link>
                </div>
            </section> */}
        </div>
        {/* <nav id = "ButtomNav" >
            <div className='moveState'>
                소개
            </div>
            <div className='moveState'>
                템플릿
            </div>
            <div className='moveState'>
                가이드
            </div>
        </nav> */}
      </div>
      </Fragment>
    );

}

export default PromotionPage;



const PageStyle = `

#PromotionPage{
    display :flex;
    flex-direction: column;
    width : 100%;
    color : #fff;
    background-color : #202020;
    font-family: '프리텐다드ExtraBold';

}


#PromotionPage #PromotionContent{
    position: relative;
    display :flex;
    flex-direction: column;
    width : 100%;

}


`;