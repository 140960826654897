////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widgetEdit.scss"

// 이미지 연결


// 컴포넌트 연결
import Show from './NumberModule' //넘버 모듈

// 서비스 연결
import * as Event from '../../../service/event/Actions' ; 
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

class ListModule extends Component {

    state = {
        search : null,
    }

    list = () =>{
        let list = this.props.list
        if (this.state.search){
            list = this.props.list.filter( data =>(data.name.search(this.state.search) != -1));
        }

        list = list.map(
            (info,index) => (
            <button className="buttonList" onClick={()=>this.props.change(this.props.type,info.value)}>
                <img  className="listImg" src={info.img}/>
                <div  className="listText" >
                <h3> {info.name} </h3>
                <p> {info.info}</p>
                </div>
            </button>
            ) 
        );    
        return list
    }

    render() { 

        let placeholder = '검색 해보세요'
        if(this.props.placeholder){
            placeholder = this.props.placeholder
        }

        return (
            <label className="ListModule" htmlfor="fname"> 
            <div className="listSearch" >
            <input placeholder={placeholder} value={this.state.value} name="search" autocomplete="off" onChange={(e) => Event.inputChange(this,e)} />
            </div>
            <div className="listUp">
                {this.list()}
            </div>
 
            </label>
        );
    }
}

////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    // screenData : state.screenData,
})

function mapDispatchToProps(dispatch){
    return {
    //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(ListModule);
  