////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component, Fragment , useState, useEffect} from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { useHistory,useLocation } from 'react-router-dom';
import { SortableContainer, SortableElement,ReactSortable } from 'react-sortablejs';
// 라이브러리 



// SASS&CSS 연결
// import "sass/edit.scss"

// 이미지 연결
import logoW from 'assets/logoW.svg';

// 컴포넌트 연결
// import VariableTeam from 'components/edit/editVariable/varTeam/VariableTeam';//오버레이
import AppendFile from 'components/Edit/editPanel/append/AppendFile';
import VarTeamPlayer from 'components/Edit/editVariable/varTeam/VarTeamPlayer';

// 서비스 연결
import * as fileAPI from 'service/api/file' ; 
import * as Editor from 'service/edit/EditService'; 
import * as Utility from 'service/other/Utility';
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
function AppendImageArea({context,panelHeight,panelWidth,modifyWidget,listOption={}}) {


    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////
    //set

    const [FileList, setFileList] = useState(false);
    const [TragetWidget, setTragetWidget] = useState(null);

    // state
    const [SelectTeam, setSelectTeam] = useState(null);

    // rel 

   ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////
    useEffect(() => {
        // 여기에 컴포넌트가 마운트되었을 때 실행할 코드를 작성합니다.
        getFile();
        return () => {
        // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
        };
      }, []);

      useEffect(() => {
        console.error('타겟변경감지 ',context.selectWidget)
        if(!TragetWidget){
          setTragetWidget(context.selectWidget)
        }
        else{
          context.panelControl('bottom','widget')
        }
        return () => {
        // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
        };
      }, [context.selectWidget]);

      
    
    const getFile = async () => {

     const Files  = await fileAPI.getList(context.spaceId);
      setFileList(Files)
    }
    
    const fileUpload = async (files) => {
      if (files) {
          const file = files?.[0];
          // 파일 타입 검사
          if(file?.type){
            const Type = Utility.fileMIME(file.type)
            if (Type == null) {
                alert('업로드가 불가능한 파일입니다.');
                return;
            }
            try {
                await fileAPI.upload(context.spaceId, file);
                getFile();
            } catch (error) {
                console.error('파일 업로드 실패:', error);
            }
          }
      }
    };


    // {mediaType : {'key' : 'mediaType', 'category':'value'},mediaSource: {'key' : 'mediaSource', 'category':'value'}
    const selectImage = (file) => {
      if (typeof context.selectWidget === 'string') {
      console.log('fileAPI',file?.uuid)
      modifyWidget('value',['mediaType','mediaSource'],['library',file?.uuid],context.selectWidget)
      } else if (Array.isArray(context.selectWidget)) {
        return 'This is an array';
      } else {
        return 'This is neither a string nor an array';
      }
       context.panelControl('bottom','widget')
    }

    let list = null
    if(FileList!==false){
      if(FileList.length==0){
        list = (
          <div id="FileListArea">
            <div className="Files" style={{background:'#303030'}}> 
                <label htmlFor="file-upload" className="fileUploadButton" style={{width: 150, height: 150,display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                {Editor.icon({icon:'drive_folder_upload',size:40,lineHeight:50})}
                <p style={{fontSize:15}}>
                파일 가져오기 
                </p>
                </label>
                <input style={{display:'none',}} id="file-upload" type="file" onChange={(e)=>{fileUpload(e?.target?.files)}} />
                {/* <button>업로드</button> */}
                {/* {selectedFile && <p>선택된 파일: {selectedFile.name}</p>} */}
              </div>
          </div>
        )
      }else{
        list = (
          <div id="FileListArea">
            <div className="Files" style={{background:'#303030'}}> 
                <label htmlFor="file-upload" className="fileUploadButton" style={{width: 150, height: 150,display:'flex',flexDirection:'column'}}>
                {Editor.icon({icon:'drive_folder_upload',size:40,lineHeight:50})}
                <p style={{fontSize:15}}>
                파일 가져오기
                </p>
                </label>
                <input style={{display:'none',}} id="file-upload" type="file" onChange={(e)=>{fileUpload(e?.target?.files)}} />
                {/* <button>업로드</button> */}
                {/* {selectedFile && <p>선택된 파일: {selectedFile.name}</p>} */}
              </div>

          {FileList.slice().reverse().map(file => (
          <AppendFile key={file.uuid} fileData={file}  select={selectImage} />
          ))}

          </div>
        )
      }
    }

    return (
        <Fragment>
                <style jsx>{`${EditPageStyle}`}</style>
                <section id = 'AppendFileArea'
                onDrop={(e)=>fileUpload(Array.from(e.dataTransfer.files))}
                onDragOver={(e) => {
                  e.preventDefault();
                }}
                >


                <div id="FileContainer">
                  {list}
                  </div>
                  {/* <div id="GetFileArea">  
                    <div id="AddFile">
                      <label htmlFor="file-upload" className="fileUploadButton">
                      {Editor.icon({icon:'drive_folder_upload',size:25,lineHeight:30})}
                      &nbsp;
                      파일 가져오기 
                      </label>
                      <input id="file-upload" type="file" accept='image/*' onChange={(e)=>{fileUpload(e)}} />
                    </div>
                    
                  </div> */}
           
              
                </section>
        </Fragment>
    );

}

////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    // mainScreen : state.mainScreen,
})

function mapDispatchToProps(dispatch){
    return {
        // initOverlap : (overlapData) => dispatch({type:'initOverlap',overlap : overlapData}),
    }
}

  // export default EditPage;
export default connect(mapStateToProps,mapDispatchToProps)(AppendImageArea);
  


  const EditPageStyle = `

  #AppendFileArea {
    display: flex;
     flex : 1 ;
  }
  #AppendFileArea #FileContainer {
    flex : 1 ;
    display: flex;

  }
  #AppendFileArea #FileListArea{
    flex : 1 ;
    display: grid;
    grid-template-columns: repeat(auto-fill, 150px);
    grid-template-rows: repeat(auto-fill, 150px);
    gap: 10px; /* 아이템 간 여백 */

    overflow-y: auto;

    box-sizing: border-box;
    padding : 10px;
    border-radius: 0px 0px 5px 5px;
  }

  #AppendFileArea #FileContainer .noneFile{
    flex : 1 ;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-aline : center;
    line-height : 30px;
  }

   
  #AppendFileArea #FileListArea .Files{
    width: 150px;
    height: 150px;
    background-color: #2c2c2c;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 24px;
    border-radius: 10px;
    position: relative;
  }
  #AppendFileArea #FileListArea .Files .FilePreview{
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    border-radius: 5px;
  }

  #AppendFileArea #FileListArea .Files .FilePreview .PreviewImage{
      width: 100%;
      height: 100%;
      display: flex;
      object-fit: cover;
      border-radius: 5px;
  }
  #AppendFileArea #FileListArea .Files .FileInfo{
     position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
  }

  #AppendFileArea #FileListArea .Files .FileInfo .FileName{
      font-size : 15px;
      width: calc(100% - 10px) ;
      height: 20px;
      line-height : 20px;
      display: flex;
      margin : 0px 5px ;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
  
  }

    #AppendFileArea #FileListArea .Files .FileInfo .FileType{
      position: absolute;
      right : 5px;
      top : 5px;
      border-radius : 2.5px;
      padding : 2.5px;
      height: 10px;
      line-height : 10px;
      font-size : 10px;
      background : #444444aa;
      font-family : 프리텐다드Regular;
}
      
  #AppendFileArea #FileListArea .Files .FileOption{
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
  }

    
  #AppendFileArea #GetFileArea{
      display: flex;
      justify-content: center;
      align-items: end;
      width: 100%;
      height: 80px;
      position: absolute;
      background:linear-gradient(to bottom, rgba(44,44,44,0), rgba(44,44,44,1));
      border-radius: 0px 0px 5px 5px;
      bottom : 0px;
  }
  #AppendFileArea #GetFileArea #AddFile{
      padding : 0px 10px;
      height: 40px;
      margin: 10px;
      border-radius : 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color : #3F3F3F;
      box-shadow: 
        0px 2px 4px rgba(0, 0, 0, 0.15),
        0px 6px 8px rgba(0, 0, 0, 0.15),
        0px 12px 16px rgba(73, 25, 25, 0.1),
        0px 18px 24px rgba(0, 0, 0, 0.15);
  }
  .fileUploadButton{
      display: flex;
      justify-content: center;
      align-items: center;
  }
  #AppendFileArea #GetFileArea #AddFile input[type="file"] {
    display: none;
  }
  

  `;
  

  const modernColors = [
  "#e4002b", // PANTONE 17-1564
  "#aa8a00", // PANTONE 18-1764
  "#0ec7b5", // PANTONE 18-1550
  "#e73312", // PANTONE 18-1945
  "#5a8dff", // PANTONE 19-3540
  "#00492b", // PANTONE 19-3642
  "#FF0A07", // PANTONE 19-4052
  "#de2027", // PANTONE 18-4244
  "#0277BD", // PANTONE 18-4250
  "#FFC900", // PANTONE 18-4728
  "#ff6b01", // PANTONE 18-5624
  "#b4936a", // PANTONE 17-6153
  "#ff3e24", // PANTONE 18-0538
  "#b69265", // PANTONE 16-0737
  "#C8102E", // PANTONE 14-0957
  "#dc0e27", // PANTONE 15-1046
  "#221814", // PANTONE 16-1356
  "#23aee5", // PANTONE 18-1451
  "#b04ec4", // PANTONE 18-1449
  "#231f20", // PANTONE 19-1317
  "#d4d7dc", // PANTONE 19-0201
  "#212021", // PANTONE 19-4019
  "#231815", // PANTONE 19-4008
  "#808080", // PANTONE 18-1655
  "#0a3091", // PANTONE 19-2047
  "#216ce2", // PANTONE 19-3542
  ];