////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { useState, useEffect, useContext, Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결

// [리덕스]스토어 연결
import store from "store";
// [프로바이더] 연결
import EditContext from 'service/context/EditContext' ; 

// SASS&CSS 연결
// import "sass/edit.scss"



// 이미지 연결
import logoW from 'assets/logoW.svg';

// 컴포넌트 연결


// 서비스 연결
import * as Editor from 'service/edit/EditService'; 
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
function EditTapNav({PreferencesType,setPreferenceType}) {

    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////
    //set
    // state
    const [WidgetList, setWidgetList] = useState([]);
    

    // rel 

   ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////
    useEffect(() => {
        // 여기에 컴포넌트가 마운트되었을 때 실행할 코드를 작성합니다.

        return () => {
        // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
        };
      }, []);
    
      

    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////
  
    // stack 화면 목록 이건 어떨지  select_window
    // deployed_code 위젯 ? widgets
    // acute 애니메이션 ? animation
    // 화면 추가 new_window

    return (
      <Fragment>
      <style jsx>{`${EditPageStyle}`}</style>
      <ul id="EditTapList">
        <li className={PreferencesType=="screen"? "selectTap defaultTap":"defaultTap" } onClick={()=>setPreferenceType('screen')}>
        {Editor.icon({icon:'stack',size:20,lineHeight:25,color:(PreferencesType=="screen"? "#ffffff":"#adadad" )})}
        <span>화면</span>
        
        </li>
        <li className={PreferencesType=="edit"||PreferencesType=="preset"||PreferencesType=="asset"? "selectTap defaultTap":"defaultTap" } onClick={()=>setPreferenceType('edit')}>
        {Editor.icon({icon:'deployed_code',size:20,lineHeight:25,color:(PreferencesType=="edit"||PreferencesType=="preset"||PreferencesType=="asset"? "#ffffff":"#adadad" )})}
        <span>위젯</span>
        </li>
      
        <li className={PreferencesType=="animation"? "selectTap defaultTap":"defaultTap" } onClick={()=>setPreferenceType('animation')}>
        {Editor.icon({icon:'acute',size:20,lineHeight:25,color:(PreferencesType=="animation"? "#ffffff":"#adadad" )})}
        <span>효과</span>
        </li>
        {/* {window.location.hostname === 'localhost' ? 
        <li className={PreferencesType=="debug"? "selectTap defaultTap":"defaultTap" } onClick={()=>setPreferenceType('debug')}>
        {Editor.icon({icon:'data_object',size:20,lineHeight:25,color:(PreferencesType=="debug"? "#ffffff":"#adadad" )})}
        <span>디버그</span>
        </li>
        :null} */}
        {/* {window.location.hostname === 'localhost' ? 
        <li className={PreferencesType=="test"? "selectTap defaultTap":"defaultTap" } onClick={()=>setPreferenceType('test')}>
        {Editor.icon({icon:'quiz',size:20,lineHeight:25,color:(PreferencesType=="test"? "#ffffff":"#adadad" )})}
        <span>디버그</span>
        </li>
        :null} */}

      </ul>
      </Fragment>
    );

}

////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    // mainScreen : state.mainScreen,
})

function mapDispatchToProps(dispatch){
    return {
        // initOverlap : (overlapData) => dispatch({type:'initOverlap',overlap : overlapData}),
    }
}

  // export default EditPage;
export default connect(mapStateToProps,mapDispatchToProps)(EditTapNav);
  


  const EditPageStyle = `

  #EditTapList, #EditTapList li {
    margin: 0;
    padding: 0;
    list-style: none;
  }

    #EditTapList{
      background-color: #2C2C2C;
      width: 100%;
      height: 40px;
      display: flex;
      overflow :hidden;
      text-align: center;
      justify-content: space-around;
    }

    #EditTapList span{
      font-size: 20px;
      height: 20px;
      width: 40px;
    }

    #EditTapList li{
      height: 60px;
      width: 40px;
      /* padding : 0px 20px; */
      display: flex;
      justify-content: center;
      align-items: center;
    }

    #EditTapList li .icon{
      height: 40px;
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    #EditTapList li span{
      font-size: 15px;
      height: 20px;
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    #EditTapList .defaultTap{
      display :flex;
      flex-direction: column;
      transition : transform 0.5s;
    }
    
    #EditTapList .defaultTap:hover{
      transform: translateY(-30px);
    }
    

  `;