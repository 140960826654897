
////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결

// 라이브러리 
import io from 'socket.io-client';
// 설정 파일
import * as config from 'config/OverlapConfig'
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결

// 이미지 연결

// 서비스 연결
import * as Rest from 'service/api/rest' ;
import { token } from 'service/event/Account'; 
import { withRouter } from 'react-router-dom';

////////////////////////////////////////////////////////
// store 연결
////////////////////////////////////////////////////////

const SERVERURL = config.SERVERS[0].serverEndPoint+":"+'3030';

////////////////////////////////////////////////////////
/** spaceIO start
 * @param authData 소켓 IO 를 통해서 오버랩을 체크를 합니다.
 * @returns 스페이스 IO 객체
 */
////////////////////////////////////////////////////////

export function start() {

    const space = io(config.SERVERS[0].serverEndPoint+":"+'3030'+'/space');
    return space;
}


////////////////////////////////////////////////////////
/** getSpace 스페이스 리스트를 가저옵니다.
 * @param authData 구글 Auth 를 통해서 받은 정보
 * @returns 오버랩 계정의 토큰
 */
////////////////////////////////////////////////////////

export async function getSpace() {
    let response =  await Rest.request('/space','GET',null,token('account'));
    return response?.data?.data;
}

////////////////////////////////////////////////////////
/** createSpace 새로운 스페이스를 생성합니다. 
 * @param accessToken 구글 Auth 를 통해서 받은 정보
 * @returns 오버랩 계정의 토큰
 */
////////////////////////////////////////////////////////

export async function createSpace(sapce_name) {
    let response =  await Rest.request('/space','POST',{sapce_name},token('account'));
    return response?.data?.data;
}

////////////////////////////////////////////////////////
/** setSpace 새로운 스페이스를 생성합니다. 
 * @param accessToken 구글 Auth 를 통해서 받은 정보
 * @returns 오버랩 계정의 토큰
 */
////////////////////////////////////////////////////////

export async function setSpace(space_id) {
    let response =  await Rest.request('/space','PUT',{space_id},token('account'));
    return response?.data?.data;
}
////////////////////////////////////////////////////////
/** deleteSpace 새로운 스페이스를 생성합니다. 
 * @param accessToken 구글 Auth 를 통해서 받은 정보
 * @returns 오버랩 계정의 토큰
 */
////////////////////////////////////////////////////////

export async function deleteSpace(sapce_name) {
    let response =  await Rest.request('/space','DELETE',{sapce_name},token('account'));
    return response?.data?.data;
}




