////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { useState, useEffect, useContext, Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// 라이브러리 

// [리덕스]스토어 연결

// [프로바이더] 연결
import EditContext from 'service/context/EditContext' ; 

// SASS&CSS 연결
// import "sass/edit.scss"



// 이미지 연결
import logoW from 'assets/logoW.svg';

// 컴포넌트 연결
import PageDisplay from 'components/display/StaticDisplay';
import ImageButtonModule from 'components/Edit/editModule/ImageButtonModule' ; 
import LegacyTextEditFrom from 'components/Edit/editorField/LegacyTextEditForm';
// 서비스 연결
import * as Editor from 'service/edit/EditService'; 
import { dragTransparent } from 'service/event/common' ; 
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
/**
 * 값이 없는 경우 나옵니다.
 */

function TapDefault() {

    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////
    //set
    const context = useContext(EditContext);

    // state
    // const [PageTitle, setPageTitle] = useState(page?.page_title);


    // rel 

   ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////
    useEffect(() => {
        // 여기에 컴포넌트가 마운트되었을 때 실행할 코드를 작성합니다.

        return () => {
        // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
        };
      }, []);
    
      

      
    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////
    
    // const addPage = () =>{
    //   context.addPage();
    // }


    const getWidget = () =>{
        // context.selectWidget
    }



    console.log('overlap',context.selectWidget,JSON.stringify(context.overlap))
    return (
      <Fragment>
      <style jsx>{`${EditPageStyle}`}</style>
      <div id='TapDefault'>
        <div id='tapInfo'>
          {Editor.icon({icon:'edit_square',size:100,lineHeight:150,color:"#adadad"})}
          <span>
            설정 가능한 요소가 없습니다.
          </span>
        </div>
      </div>


      </Fragment>
    );

}

////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    // mainScreen : state.mainScreen,
})

function mapDispatchToProps(dispatch){
    return {
        // initOverlap : (overlapData) => dispatch({type:'initOverlap',overlap : overlapData}),
    }
}

  // export default EditPage;
export default connect(mapStateToProps,mapDispatchToProps)(TapDefault);
  


  const EditPageStyle = `

  #TapDefault{
    flex: 1 1;
    padding: 0px 5%;
  }

/* 위젯정보 위젯 아이디*/
  #TapDefault #tapInfo{
    flex-direction: column;
    height: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
  }

  `; 

  