import store from "store";
import * as Utility from '../other/Utility'; 
////////////////////////////////////////////////////////
// delect (해당객체번호)
// 해당 객체를 삭제합니다.
////////////////////////////////////////////////////////
const editOverlayChange = overlay => ({type:'editOverlayChange',overlay})

export function OverlayPush(overlay) {
    const state = store.getState(); // 현재 상태를 가져옵니다.
    const { editOverlay } = state; // 편의상 비구조화 할당
    let temp = Utility.deepCopy(editOverlay)
    temp.push(overlay)
    store.dispatch(editOverlayChange(temp));   
}

export function OverlayRemove(overlay) {
    const state = store.getState(); // 현재 상태를 가져옵니다.
    const { editOverlay } = state; // 편의상 비구조화 할당
    let temp = Utility.deepCopy(editOverlay) 
    let value = temp.indexOf(overlay)
    if (value != -1){
        temp.splice(value,1)
        store.dispatch(editOverlayChange(temp));   
    }
}
