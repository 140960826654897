////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widget.scss"
import "./preset/OverwatchRankPreset.scss"

// [프로바이더] 연결
import EditContext from 'service/context/EditContext' ; 

// 컴포넌트 연결
import WidgetOverRay from '../Edit/editController/WidgetOverRay'; // 메뉴 헤더
import * as Preset from './preset/OverwatchRankPreset';

// 서비스 연결
import * as Rest from '../../service/api/rest' ; 
import * as Widget from '../../service/event/widget' ; 
import * as Model from '../../service/value/Model' ; 
import * as EnCoder from '../../service/engine/EnCoder' ; 
// import { NumberBadges } from 'twitch-js';
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

class OverwatchRank extends Component {
    
    static defaultProps = {
        widgetName: 'OverwatchRank',
      }


    //[임시] 컨텍스트 연결
    static contextType = EditContext;

    state = {
        ...Widget.DefaultState(this),
        moveStartX : 0,
        moveStartY : 0,
        polling : 0,
        profile : null,
        gameMode : 'competitive',
        mostHero : null,
        battleCode : null,
    }

    constructor(props) {
        super(props);
        this.widget = React.createRef();
    }

    DefaultMethod = (type,value) =>{
        Widget.DefaultMethod(this,type,value)
    }

    componentDidMount() {
        if(this.props.parents != 'view'){
        this.overwatchData()
        // AJAX 통신 마운트
        let count = setInterval(this.currencyPrice, 10000)
        // 내부 카운터 마운트
        // let polling = setInterval(this.polling, 1000)
        this.setState({
            restInterval: count,
            // pollingInterval : polling,
        },)
        }
    }

    componentWillUnmount() {
        if(this.props.parents != 'view'){
            // AJAX 통신 언마운트
            clearInterval(this.state.restInterval); 
            // 내부 카운터 언마운트
            clearInterval(this.state.pollingInterval);
        }
    }
    
    ////////////////////////////////////////////////////////
    // polling  내부적으로 사용하는 카운터 함수, 마운트 될때 생성되고 언마운트 되면 사라진다.
    ////////////////////////////////////////////////////////
    // polling = () =>{
    //     this.setState({
    //         polling : this.state.polling +1
    //     })
    // }

    ////////////////////////////////////////////////////////
    // currencyPrice async 를 사용해서 루프안에서 AJAX 통신을 구현한다.
    ////////////////////////////////////////////////////////
    overwatchData = async () => {
        let newProfile = {}
        let response 

        let battleCode = this.props.data.value.battleCode
        if(this.props.parents != 'view'&& battleCode!=null){
            

            try {
                response = await Rest.overwatchAPI(battleCode)
                let mostHero =this.overwatchMost(response.data)
                this.setState({
                    profile : response.data,
                    mostHero : mostHero,
                    battleCode : battleCode,
                })
            } catch( error ) {
                this.setState({
                    profile : "Error",
                    mostHero : null,
                    battleCode : battleCode,
                })
            }
  
        }
    }
    showPosition = (position)=>{
        if (this.props.data.value.heroPosition=='all'){
            return true
        }
        else if (this.props.data.value.heroPosition==position){
            return true
        }
        else {
            return false
        }
    }
    
    userRank= (position) =>{
        if(!this.state.profile||this.state.profile=="Error"||!this.props.data.value.pointShow){
            return null;
        }else{
            switch (position) {
                case 'tanker':
                    return this.state.profile.userRank.tanker
                case 'dps':
                    return this.state.profile.userRank.dps
                case 'suppot':
                    return this.state.profile.userRank.suppot            
                default:
                    return null;
            }
        }
    }

    getProfile= (heroData) =>{
        let gameMode = this.state.gameMode
        let hero = null
        if(!this.state.profile){
            return null;
        }
        // 경쟁전, 빠른대전 데이터 전환
        if(gameMode=='competitive'){
        
            let profileData = this.state.profile[gameMode]
            if(profileData){
                let data = profileData[heroData.hero]
                if(data){
                    if (data[heroData.type]) {
                        return data[heroData.type]
                    }
                }
            }
            return '전적 없음'
        }
    }

    getUserAccount=()=>{
        let account = this.props.data.value.battleCode
        try {
            account = account.substring(0,account.indexOf('#'))
            return account
        }catch{
            return ''
        }
    }

    getMost= (type) =>{
        let gameMode = this.state.gameMode

        if(!this.state.mostHero){
            return '정보없음';
        }
        // 경쟁전, 빠른대전 데이터 전환
        const TANKER = ['dva','reinhardt','wrecking-ball','roadhog','sigma','orisa','winston','zarya']
        const DPS = ['genji','doomfist','reaper','mccree','mei','bastion','soldier-76','sombra','symmetra','ashe','echo','widowmaker','junkrat','torbjorn','tracer','pharah','hanzo']
        const SUPPORT = ['lucio','mercy','moira','baptiste','brigitte','ana','zenyatta']

        let mostlist = []
        let mostHero = this.state.mostHero.slice()
        let index = 0 
        while(index < 3){
            let hero = mostHero.shift()
            if (hero==undefined){
                break
            }
            if (type == 'all'){
                mostlist.push(hero) 
                index++
            }
            else if (type == 'tanker'){
                if (TANKER.includes(hero.hero)){
                    mostlist.push(hero) 
                    index++
                }
            }
            else if (type == 'dps'){
                if (DPS.includes(hero.hero)){
                    mostlist.push(hero) 
                    index++
                }
            }
            else if (type == 'support'){
                if (SUPPORT.includes(hero.hero)){
                    mostlist.push(hero) 
                    index++
                }
            }
            else {
                return null
            }
        }
        return mostlist

        
    }

    tierRank= (position) =>{
        if(!this.state.profile||!this.props.data.value.tierShow){
            return null;
        }else{
            console.log()
            if(!this.state.profile.userRank){
                return '';
            }
            let point = null 
            switch (position) {
                case 'tanker':
                    point = this.state.profile.userRank.tanker
                    break;
                case 'dps':
                    point = this.state.profile.userRank.dps
                    break;
                case 'suppot':
                    point = this.state.profile.userRank.suppot        
                    break;    
            }
            if (!point) {
                return '';
            }  
            if (point>4400) {
                return "https://overlap.s3-ap-northeast-1.amazonaws.com/widget/Overwatch/Ranker_icon.png"
            } else if (point>4000) {
                return "https://overlap.s3-ap-northeast-1.amazonaws.com/widget/Overwatch/GrandMaster_icon.png"
            } else if (point>3500) {
                return "https://overlap.s3-ap-northeast-1.amazonaws.com/widget/Overwatch/Master_icon.png"
            } else if (point>3000) {
                return "https://overlap.s3-ap-northeast-1.amazonaws.com/widget/Overwatch/Diamond_icon.png"  
            } else if (point>2500) {
                return "https://overlap.s3-ap-northeast-1.amazonaws.com/widget/Overwatch/Platinum_icon.png"
            } else if (point>2000) {
                return "https://overlap.s3-ap-northeast-1.amazonaws.com/widget/Overwatch/Gold_icon.png"
            } else if (point<1500) {
                return "https://overlap.s3-ap-northeast-1.amazonaws.com/widget/Overwatch/Silver_icon.png"
            } else if (point>0) {
                return "https://overlap.s3-ap-northeast-1.amazonaws.com/widget/Overwatch/Bronze_icon.png"
            } else {
        
            }
        }
    }
    
    
    overwatchMost = (profile) =>{
        let playtime=[]
        let playData = null 
        // 전적검색 : 경쟁전인 경우와 빠른대전 모드일때 따라서 가저오는 전적 데이터를 다르게 설정
        // 경쟁전모드 : 경쟁전 데이터가 없는경우 빠른대전 데이터를 가저옴
        // 빠른대전 : 빠른대전 데이터가 없는경우 표시하지 않음
        if (this.state.gameMode == 'competitive'){

            if (profile['competitive'] != undefined){
                playData = profile.competitive
            } else if (profile['quickplay'] != undefined) {
                playData = profile.quickplay
            }
        } else if(profile['quickplay'] != undefined){
            playData = profile.quickplay
            if (!profile['quickplay']) {
                return null 
            }
        } else {
            // 세이프티 (경쟁전,빠른대전 모드값이 없는경우 스톱)
            return null 
        }

        // 플레이한 영웅 데이터를 가저와서 시간순으로 정렬합니다.
        if (profile) {
            for (let hero in playData) {
                let time = 0
                if (hero == 'all'){continue;}
                let targetHero = playData[hero]
                if (targetHero['플레이 시간']){
                  
                    let getTime = playData[hero]['플레이 시간'].split(':')
                    if (getTime.length>=1){
                        time = time + Number(getTime[getTime.length-1]) 
                    }
                    if (getTime.length>=2){
                        time = time + Number(getTime[getTime.length-2])*60 
                    }
                    if (getTime.length>=3){
                        time = time + Number(getTime[getTime.length-3])*3600
                    }
                    playtime.push({hero,time})
                }
            }
            let playtimeSort = playtime.sort(function(a,b){
                return b.time - a.time 
            })
            return playtimeSort
        }
    }


    render() {
        let Style = Model.widget(this.props)
    
            if(this.state.battleCode != this.props.data.value.battleCode){
                this.setState({
                    battleCode :this.props.data.value.battleCode,
                    profile : null,
                },this.overwatchData)
            }
        // 드레그를 통한 위젯 이동
        Style.layout= { ...Style.layout,transform: 'translate('+this.state.dragMoveX+'px, '+this.state.dragMoveY+'px)'}
        
        switch (this.props.parents) {

            case 'edit':
                // 편집시 화면이동 트렌스레이션 효과 방지
                Style.layout.transition = false
                Style.object.transition = false
                if(this.props.userState.browser== "firefox"&&"safari"){
                    return (         
                        <div ref={(ref) => this.object=ref }  className = {this.props.parents + " widgetObject "+this.props.widgetName+Widget.display(this.props)} style={Style.layout} tabIndex={0} draggable="true" 
                            onKeyDown={(e)=>Widget.keyDown(e,this)}
                            onClick={(e)=> Widget.isfocus(e,this)} 
                            onMouseDown={(e)=> Widget.mouseControl(e,this)} 	
                            //드레그 이벤트	
                            onDragOver={(e)=>Widget.alt_drag(e,this)} 
                            onDragStart={(e) => Widget.alt_dragStart(e,this)} 
                            onDragEnd={(e) => Widget.alt_dragEnd(e,this)} 
                            onDrag={(e)=>e.stopPropagation()} 
                            //드랍 이벤트 
                            onDrop={(e)=> Widget.drop(e,this)} 
                            onDragLeave={(e)=> Widget.dragLeave(e,this)} 
                            onDragEnter={(e)=> Widget.dragEnter(e,this)} 
                        >
                        {Preset.data(this,Style)}
                            { this.props.focus ? <WidgetOverRay target={this.props} object={this.widget.current} mode={'4_Direction'} dragLock={this.state.dragLock} DefaultMethod={this.DefaultMethod}/> :"" } 
                        </div>
                    );
                }else{
                    return (         
                        <div ref={(ref) => this.object=ref }  className = {this.props.parents + " widgetObject "+this.props.widgetName+Widget.display(this.props)} style={Style.layout} tabIndex={0} draggable="true" 
                            onKeyDown={(e)=>Widget.keyDown(e,this)}
                            onClick={(e)=> Widget.isfocus(e,this)} 
                            onMouseDown={(e)=> Widget.mouseControl(e,this)} 	
                            //드레그 이벤트
                            onDrag={(e)=>Widget.drag(e,this)} 
                            onDragStart={(e) => Widget.dragStart(e,this)} 
                            onDragEnd={(e) => Widget.dragEnd(e,this)} 
                            //드랍 이벤트 
                            onDrop={(e)=> Widget.drop(e,this)} 
                            onDragOver={(e)=> Widget.dragOver(e,this)} 
                            onDragLeave={(e)=> Widget.dragLeave(e,this)} 
                            onDragEnter={(e)=> Widget.dragEnter(e,this)} 
                        >
                        {Preset.data(this,Style)}
                            { this.props.focus ? <WidgetOverRay target={this.props} object={this.widget.current} mode={'4_Direction'}/> :"" } 
                        </div>
                    );
                }
            case 'broadcast':

                return (         
                    <div ref={(ref) => this.object=ref }  className = {this.props.parents + " widgetObject "+this.props.widgetName+Widget.display(this.props)} style={Style.layout} >
                            
                        {Preset.data(this,Style)}
                    </div>
                );
          
            case 'view':

                return (
                    <div ref={(ref) => this.object=ref }  className = {this.props.parents + " widgetObject "+this.props.widgetName+Widget.display(this.props)} style={Style.layout} >
                        
                        {Preset.data(this,Style)}
                    </div>
                );
        }

    }
}
////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    userState : state.userState,
})

function mapDispatchToProps(dispatch){
    return {
        //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}


  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(OverwatchRank);
  