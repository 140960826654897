////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widgetEdit.scss"


// 컴포넌트 연결
import MediaSourceModule from '../editModule/MediaSourceModule'; // 텍스트 모듈
import TimeModule from '../editModule/TimeModule'; // 타이머 모듈
// 서비스 연결
import * as Editor from 'service/edit/EditService'; 
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class VideoEdit extends Component {
    
    state = {
        raido : "youtube"
    }

    change = (name,value) =>{
        this.props.callBack('style',name,value)
    }
    valueChange = (name,value) =>{
        this.props.callBack('value',name,value)
    }

    videoChange = (name,value) =>{
        console.log('비디오 체크',name,value);
        let rowURL = value
        let mode = 'link'
        let mediaSource = ''
        let startTime = 0
        if (rowURL.indexOf('https://youtu.be/')==0) {
            // 유튜브 1 
            mode = 'youtube'
            mediaSource = rowURL.replace('https://youtu.be/', '')
            console.log('유튜브',rowURL.replace('https://youtu.be/', ''))
            if (rowURL.indexOf('?t=')!=-1) {
                startTime = rowURL.slice(rowURL.indexOf('?t=')+3)   
            }
        }
        else if (rowURL.indexOf('https://www.youtube.com/watch?v=')==0) {
            // 유튜브 2 
            mode = 'youtube'
            mediaSource = rowURL.replace('https://www.youtube.com/watch?v=', '')
            console.log('유튜브',rowURL.replace('https://www.youtube.com/watch?v=', ''))
        }
        else if (rowURL.indexOf('https://www.twitch.tv/videos/')==0 ) {
            mode = 'twitch'
            mediaSource = rowURL.replace('https://www.twitch.tv/videos/', '')
            // 트위치 다시보기
            console.log('트위치 다시보기',rowURL.replace('https://www.twitch.tv/videos/', ''))
            console.log('트위치 다시보기',rowURL.replace('https://www.twitch.tv/videos/', ''))
        }
        else if (rowURL.indexOf('https://www.twitch.tv/')==0&&rowURL.indexOf('/clip/')!=-1 ) {
            // 트위치 클립
            mode = 'twitchClip'
            mediaSource = rowURL.slice(rowURL.indexOf('/clip/')+6)

            console.log('트위치 클립',rowURL.slice(rowURL.indexOf('/clip/')+6))
        }
        else if (rowURL.indexOf('https://www.twitch.tv/')==0 ) {
            mode = 'twitchLive'
            mediaSource = rowURL.replace('https://www.twitch.tv/', '')
            // 트위치 생방송 
            console.log('트위치 생방송',rowURL.replace('https://www.twitch.tv/', ''))
        }

        console.log(mode,mediaSource)
        this.props.multiCallBack([
            {property:'value',name:"mode",value:mode},
            {property:'value',name:"mediaSource",value:rowURL},
            {property:'value',name:"startTime",value:startTime}
        ])
        // this.props.callBack('value',{mode,mediaSource:rowURL,startTime})
    }
    
    radioChange = (e) => {
        this.setState({
            raido : e.target.value
        })
    }

    render() { 
        let legend = '미디어소스'
        let title = "영상링크"
        if (this.props.type){
            legend = '비디오소스'
            
        }
        
        switch (this.props.data.value.mode){
            // 유튜브 임베드 일 경우
            case 'youtube' :
                title = "유튜브 링크"
                break;
            case 'twitch' :
                title = "트위치 다시보기 링크"
                break;
            case 'twitchClip' :
                title = "트위치 클립 링크"
                break;
            case 'twitchLive' :
                title = "트위치 생방송"
                break;
            case 'link' :
                title = "동영상 링크"
                break;
            default :
                title = "동영상 링크"
                console.error(this.props.mode)
                break;
        }

        

        return (
            <section className="VideoEdit" >
            {Editor.WidgetEditTitle(this,{title:'동영상',toggle:false,icon:'play_circle'})}
            {/* {Editor.WidgetEditSubhead({text:'외부에서 가저오기',icon:"edit_note"})} */}
                <article className="EditPart MediaSourceModule" >
                <MediaSourceModule value={this.props.data.value.mediaSource} target={this.props.type} title={title} type='mediaSource' change={this.videoChange}/> 
                <p>시작 시간 설정</p>
                <TimeModule value={this.props.data.value.startTime} model="ms" title="시간" type ="startTime" change={this.valueChange}/>
                {/* <img style={Style.mediaView} src={this.props.value.mediaSource}/> */}
                </article>
            </section>
        );
    }
}

// <div className="radioSelect videoSelect" >

// <input type="radio" id="videoSelectyYoutube" name="videoType" value="youtube" onClick ={(e)=>this.radioChange(e)} checked/>
// <label for="videoSelectyYoutube">유튜브</label>

// <input type="radio" id="videoSelectyTwitch" name="videoType" value="twitch" onClick ={(e)=>this.radioChange(e)} />
// <label for="videoSelectyTwitch">트위치</label>

// <input type="radio" id="videoSelectyLocal" name="videoType" value="local" onClick ={(e)=>this.radioChange(e)} />
// <label for="videoSelectyLocal">내피시</label>

// <input type="radio" id="videoSelectyLink" name="videoType" value="link" onClick ={(e)=>this.radioChange(e)} />
// <label for="videoSelectyLink">링크</label>

// </div>

////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    // screenData : state.screenData,
})

function mapDispatchToProps(dispatch){
    return {
    //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(VideoEdit);
  