////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widgetEdit.scss"


// 컴포넌트 연결
// import Show from '../show/Show'; // 메뉴 헤더

// 서비스 연결

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

class  RadioButtonModule extends Component {
    
    static defaultProps = {
        ModuleName: 'RadioButtonModule',
      }

      list = () =>{
        let list = this.props.list
        list = list.map(
            (info,index) => (
                <Fragment>
                <input type="radio" key={index} name={this.props.type} id={'RadioButtonModule'+this.props.type+(info.value?info.value:info.name)} value={(info.value?info.value:info.name)} defaultChecked={this.props.value==info.value} onClick={()=>this.props.change(this.props.type,info.value)}/>
                <label htmlFor={'RadioButtonModule'+this.props.type+(info.value?info.value:info.name)}>{info.name}</label>
                </Fragment>
            ) 
        );    
        return list
    }


    render() { 

        return (
            <label className={"EditModule "+this.props.ModuleName}> 
                <p>{this.props.title?this.props.title:this.props.type}</p>
                <span className="RadioButtonList">
                    {this.list()}
                </span>
              
            </label>
        );
    }
}

////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    // screenData : state.screenData,
})

function mapDispatchToProps(dispatch){
    return {
    //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(RadioButtonModule);
  