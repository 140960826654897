////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, {useState, useEffect, useRef } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { Route, Link } from 'react-router-dom';
import { useHistory } from "react-router";
// 라이브러리 
import { useGoogleLogin } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google'// 구글 로그인
// 설정 파일
import * as config from 'config/OverlapConfig'

import { withRouter } from 'react-router-dom';

import * as apiAccount from 'service/api/apiAccount'

// [컴포넌트] 위젯 
import MiniView from "components/viewer/MiniView"
import SelectOverlap from "components/store/SelectOverlap"
import SelectPage from "components/store/SelectPage"
import GuideBalloon from "components/common/form/GuideBalloon"
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import 'sass/login.scss'

// 이미지 연결
import logoW from 'assets/logoW.svg';
import youtube from 'assets/main/lobby/social/youtube.png';
import twitch from 'assets/main/lobby/social/twitch.png';
import afreeca from 'assets/main/lobby/social/afreeca.png';
import facebook from 'assets/main/lobby/social/facebook.png';
import naver from 'assets/main/lobby/social/naver.png';


// 서비스 연결
import * as Editor from 'service/edit/EditService'; 

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

function StoreUpload({props}) {
  



    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////
    // state
    const [Mode, setMode] = useState('list');

    const [TargetOverlap, setTargetOverlap] = useState(null);

    const [Loading, setLoading] = useState(false);
    // rel 
    ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////

    useEffect(() => {
        console.log('Component did mount or update',);
   
        // Cleanup function (optional)
        return () => {
          console.log('Component will unmount');
        };
      },[]);


      
    ////////////////////////////////////////////////////////
    // Fuction
    ////////////////////////////////////////////////////////
    const selectOverlap = (id,uuid) => {
        setTargetOverlap(id)
        setMode('pages');
    }

    const uploadTemplate = (id,uuid) => {
        setTargetOverlap(id)
        setMode('list');
    }


    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////

    return (
        <div id='StoreUpload' >
        <style jsx>{`${SpaceModuleStyle}${ContainerStyle}`}</style>
              <div className='spaceField'>
            <div className='UploadInfo' >
                <p>
                업로드 할 프로젝트를 선택해주세요
                </p>
            </div>
            {Mode=='list'? 
            <SelectOverlap props={props} select={selectOverlap}/>
            :null}
            {Mode=='pages'? 
            <SelectPage targetID={TargetOverlap} spaceID={props.spaceId} uploadTemplate={uploadTemplate} />
            :null}
        </div>

        </div>
    );
  }

  export default StoreUpload;
////////////////////////////////////////////////////////
// css 부분
////////////////////////////////////////////////////////
const SpaceModuleStyle = `


`;

const ContainerStyle = `
    #StoreUpload {
        display: flex;
        width: 100%; 
        height: 100%;
        position: relative; 
        flex-direction: column;
    }
   
    .UploadInfo{
        height: 50px;
        width: calc(100% - 40px);
        padding: 0px 20px;
        line-height: 50px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        box-shadow: 1px 1px 5px #1c1c1c;
        background-color: #444;
        margin: 20px 20px 0px 20px ;
        border-radius: 50px;
        z-index: 2;
        position: absolute;
    }

    #StoreUpload .spaceField{
        display: flex;
        width: 100%; 
        height: 100%;
        flex-direction: column;
        position: absolute; /* 부모에 대해 절대적인 위치 지정 */
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #2D2D2D; 

    }
    #StoreUpload .overlapListWrap{
        display: flex;
        flex : 1;
        overflow: scroll; 
        padding-top: 70px;
    }
    #StoreUpload .navField{
        width: 100%;
        height: 70px;
        background: #2D2D2DFF; /* fallback for old browsers */
        background: -webkit-linear-gradient(to top, #2D2D2D00, #2D2D2DFF, #2D2D2DFF); /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to top, #2D2D2D00, #2D2D2DFF, #2D2D2DFF); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        position: absolute;
        z-index:1;
    }
    #StoreUpload .spaceNav{

        height: 50px;
        width: calc(100% - 40px);
        padding: 0px 5px;
        line-height: 40px;
        box-sizing: border-box;
        display: flex ;
        justify-content: space-between;
        box-shadow: 1px 1px 5px #1c1c1c;
        background-color: #444;
        margin : 10px 20px 10px 10px;
        border-radius: 50px;
    }
    #StoreUpload .NavTitle{
        margin: 0px 10px ;
        font-size: 20px;
        line-height: 50px;
    }
    #StoreUpload .bnt_List{
    
    }
    #StoreUpload .createOverlap{
        font-family: '프리텐다드ExtraBold';
        color: white;
        border: none;
        border-radius: 5px ;
        background: #222;
        font-size: 15px;
        height: 30px;    
        padding: 0px 10px;
        margin: 10px;            
        margin-right: 10px;
        line-height: 30px;
        display: flex;
        transition: background 0.15s;
        background: orange;
        border-radius: 50px;
    }
    #StoreUpload .createOverlap .icon{
        height: 20px;
        width: 20px;
        font-size: 20px;
        object-fit: scale-down;
        margin: 5px;
    }

    #StoreUpload .overlapList{
        width: 100%;
        padding-top: 20px;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
        grid-auto-rows: 150px;
    }
    #StoreUpload .overlapLoading{
        flex :1;
        padding-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #adadad;
        flex-direction: column;
    }
    #StoreUpload .undefinedOverlap{
        flex :1;
        padding-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #adadad;
        flex-direction: column;
      
    }
`;