
////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결

// 라이브러리 
import io from 'socket.io-client';
// 설정 파일
import * as config from 'config/OverlapConfig'
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결

// 이미지 연결

// 서비스 연결
import * as Rest from 'service/api/rest' ;
import { token } from 'service/event/Account' ; 

////////////////////////////////////////////////////////
// store 연결
////////////////////////////////////////////////////////

// const SERVERURL = '//localhost'+":"+'3030';

const SERVERURL = config.SERVERS[0].serverEndPoint+":"+'6060';
////////////////////////////////////////////////////////
/** overlapStart
 * @param authData 소켓 IO 를 통해서 오버랩을 체크를 합니다.
 * @returns 오버랩 IO 객체
 */
////////////////////////////////////////////////////////

export function start() {
    // http://localhost:6060/chat/chatremove
    // const chat = io('http//localhost:6030');
    const chat = io(config.SERVERS[0].serverEndPoint+":"+'6030'+'/chat/chzzki');
    return chat;
}


// ////////////////////////////////////////////////////////
// /** overlapCreate
//  * @param spaceId 오버랩을 생성할 스페이스의 ID를 적습니다.
//  * @param data 오버랩 생성에 필요한 정보를 적습니다. {title,resolution,private,private,summary}
//  * @returns 오버랩 IO 객체
//  */
// ////////////////////////////////////////////////////////

// export async function create(space_id,data) {
//     if(data.title == ''){
//         data['title'] = '새로운 프로젝트';
//     }

//     const createData = Object.assign(data, {space_id});
//     let response =  await Rest.request('/overlap/create','Post',createData,token('account'));
//     if(response?.status == 200){
//         return true;
//     }
//     return false;
// }

// export async function createFromTemplate(space_id,template_id,custom_data) { 
//     let response =  await Rest.request('/overlap/createFromTemplate','Post',{space_id,template_id,custom_data},token('account'));
//     if(response?.status == 200){
//         console.log('response',response)
//         return true;
//     }
//     return false;
// }

// export async function del(overlap_id,space_id) { 
//     console.log('체크 account', token('account'))
//     let response =  await Rest.request('/overlap/delete','Post',{space_id,overlap_id},token('account'));
//     if(response?.status == 200){
//         console.log('response',response)
//         return true;
//     }
//     return false;
// }

// ////////////////////////////////////////////////////////
// /** overlapcList
//  * @param spaceId 오버랩 프로젝트의 목록을 가져올 스페이스의 ID 를 파라미터로 받습니다.
//  * @returns 오버랩 리스트를 반환받습니다.
//  */
// ////////////////////////////////////////////////////////

// export async function list(space_id) {
//     try {
//         let response =  await Rest.request('/overlap/list','Get',{"space_id":space_id},token('account'));
//         if(response?.status == 200){
//             return response?.data?.data;
//         }
//         return [];
//     } catch (error) {
//         return [];
//     }
// }

// ////////////////////////////////////////////////////////
// /** overlapcGet
//  * @param spaceId 오버랩을 생성할 스페이스의 ID를 적습니다.
//  * @param data 오버랩 생성에 필요한 정보를 적습니다. {title,resolution,private,private,summary}
//  * @returns 오버랩 IO 객체
//  */
// ////////////////////////////////////////////////////////

// export async function get(overlap_id,space_id) {
//     try {
//         let response =  await Rest.request('/overlap/project','Get',{"space_id":space_id,"overlap_id":overlap_id},token('account'));
//         if(response?.status == 200){
//             return response?.data?.data;
//         }
//         return [];
//     } catch (error) {
//         return [];
//     }
// }



// ////////////////////////////////////////////////////////
// /** overlapcSet
//  * @param spaceId 오버랩을 생성할 스페이스의 ID를 적습니다.
//  * @param data 오버랩 생성에 필요한 정보를 적습니다. {title,resolution,private,private,summary}
//  * @returns 오버랩 IO 객체
//  */
// ////////////////////////////////////////////////////////

// export async function setForUUID(overlap_uuid,space_id,overlap_data) {
//     try {
//         let response =  await Rest.request('/overlap/modify','post',{"space_id":space_id,"overlap_uuid":overlap_uuid,overlap_data},token('account'));
//         if(response?.status == 200){
//             return response?.data?.data;
//         }
//         return [];
//     } catch (error) {
//         return [];
//     }
// }


// // ////////////////////////////////////////////////////////
// // /** overlapStart
// //  * @param authData 소켓 IO 를 통해서 오버랩을 체크를 합니다.
// //  * @returns 오버랩 IO 객체
// //  */
// // ////////////////////////////////////////////////////////

// // export async function overlapStart() {

// //     const overlap = io("ws://example.com/my-namespace", {
// //         reconnectionDelayMax: 10000,
// //         query: {
// //           auth: "123"
// //         }
// //       });

// //     return overlap;
// // }

