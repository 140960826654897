////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { useState, useEffect, useContext, Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { useHistory,useLocation } from 'react-router-dom';

// [리덕스]스토어 연결

// [프로바이더] 연결
import EditContext from 'service/context/EditContext' ; 

// SASS&CSS 연결
// import "sass/edit.scss"



// 이미지 연결
import logoW from 'assets/logoW.svg';

// 컴포넌트 연결

import TemplateStorePanel from 'components/Edit/editPanel/preferences/extendPreferences/TemplateStorePanel'; // 템플릿
import ExtendGuidePanel from 'components/Edit/editPanel/preferences/extendPreferences/ExtendGuidePanel'; // 가이드
import ExtendTemplatePanel from 'components/Edit/editPanel/preferences/extendPreferences/ExtendTemplatePanel'; // 템플릿
import ExtendProjectSettingPanel from 'components/Edit/editPanel/preferences/extendPreferences/ExtendProjectSettingPanel'; // 가이드
import ExtendEditorSettingPanel from 'components/Edit/editPanel/preferences/extendPreferences/ExtendEditorSettingPanel'; // 템플릿



// 서비스 연결
import * as Editor from 'service/edit/EditService' ; 

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
function ExtendPanelArea({panelSize}) {


    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////

    //set
    const context = useContext(EditContext);

    // state

    // const [OverlapToken, setOverlapToken] = usePreferencesPanelState(null);

    // rel usePreferencesPanelState


   ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////
    useEffect(() => {
        // 여기에 컴포넌트가 마운트되었을 때 실행할 코드를 작성합니다.

        
        // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
        return () => {
          console.log('Component will unmount');
        };
      }, []);

    const objectDrop = (e) =>{
      e.preventDefault();
      e.stopPropagation();
    }

    
    // 확장메뉴 사용시 
    const EditorExtend = context.extendEditor==null ?  - (panelSize?.EditContainer?.width) + (panelSize?.PreferencesPanel?.width) + 40 : 0  ;

    const LeftPanel =  (panelSize?.PreferencesPanel?.width??320)+10 
    const PanelSize = {
        left: EditorExtend,
        width : `calc( 100vw - ${LeftPanel}px - 50px)`,
    }
    const BackButton = {
      left : `calc( 100vw - 50px)`,
    }

    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////

    let store = null
    
    switch (context.extendEditor) {
      case 'template':
        store = <ExtendTemplatePanel/>
        break;
      case 'guide':
        store = <ExtendGuidePanel/>
        break;
      default:
        break;
    }

    return (
        <Fragment>
                <style jsx>{`${EditorExtendStyle}`}</style>
                <div id = "ExtendPanelArea"  style={PanelSize}>
                  {store}

                </div>
             
        </Fragment>
    );

}

////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    mainScreen : state.mainScreen,
})

function mapDispatchToProps(dispatch){
    return {
        initOverlap : (overlapData) => dispatch({type:'initOverlap',overlap : overlapData}),
    }
}

  // export default EditPage;
  export default connect(mapStateToProps,mapDispatchToProps)(ExtendPanelArea);
  


  const EditorExtendStyle = `

  #ExtendPanelArea {
    display: flex;
    flex-direction: column;
    width: calc(100vw - 320px);
    margin : 20px;
    height: calc( 100vh - 40px );
    position: absolute;
    z-index : 5;
    overflow: hidden;

    transition : left 1s ease-in-out;
  }

  `;