////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { useState, useEffect, useContext, Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { useHistory,useLocation } from 'react-router-dom';
import { SortableContainer, SortableElement,ReactSortable } from 'react-sortablejs';
// [리덕스]스토어 연결
import store from "store";
// [프로바이더] 연결
import EditContext from 'service/context/EditContext' ; 

// SASS&CSS 연결
// import "sass/edit.scss"



// 이미지 연결
import logoW from 'assets/logoW.svg';

// 컴포넌트 연결
import WidgetPosition from 'components/Edit/navSubEdit/tabOrder/WidgetOrder' // 리스트
import ListOrder from 'components/Edit/editPanel/preferences/utilPreferences/ListOrder';//오버레이
// 서비스 연결
import * as Editor from 'service/edit/EditService'; 
import * as Widget from 'service/edit/WidgetService'; 
import { ContactSupportOutlined } from '@material-ui/icons';
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
function WidgetListPanel() {

    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////
    //set
    const context = useContext(EditContext);
    // state
    const [WidgetList, setWidgetList] = useState([]);
    

    /* EditContext 가 비동기 로딩으로 인해서 로딩중일때 오류를 막기 위한 State */
    const [ContextReady, setContextReady] = useState(false);

    // rel 

   ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////
    useEffect(() => {
        // 여기에 컴포넌트가 마운트되었을 때 실행할 코드를 작성합니다.

        return () => {
        // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
        };
      }, []);
    
    useEffect(() => {
        // 여기에 컴포넌트가 마운트되었을 때 실행할 코드를 작성합니다.
        if(context!=null){
            setContextReady(true);
        }
      }, [context]);
      

    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////
  
    /**
     * 편집 프로바이더에서 위젯의 목록을 가저옵니다. 
     * 이후 위젯 리스트를 만들기 위해 데이터를 가공합니다.
     */
    const getWidgetList = () =>{
        if(ContextReady){
            const widgetList = context?.nowPage()?.page_widget;
            return widgetList?.slice()?.reverse()??[];
        }else{
            return []; 
        }
    }

    /**
     * 편집 프로바이더에서 오버랩의 위젯을 수정합니다.
     */
    const update = (modifyWidgetList) => {
        if(ContextReady){
            const widgetList = modifyWidgetList.slice().reverse();
            context.modifyPage({'page_widget': widgetList}, 'modifyOrder');
        }
    };

    const setSelect = (uuid) => {
        if(ContextReady){
            context.modifySelectWidget(uuid);
        }
    };

    const setContext = (state,uuid=null,mouseX=0,mouseY=0) => {
      if(state==true){
        context.showContext(uuid,'widget',mouseX,mouseY-200)
      }else if(state==false){
        context.hideContext();
      }
    }
    

    const aliasChange = (uuid,alias) => {
      
      const widgetList = context?.nowPage()?.page_widget;
      const filterWidget = widgetList.filter(widget => widget.uuid != uuid);
      const DuplicateCheck = filterWidget.some(widget => widget.alias === alias);
      if(DuplicateCheck){
        Widget.alias(context,uuid,null)
        return true
      }
      const targetWidget = widgetList.filter(widget => widget.uuid == uuid);

      // 별명 변경사항이 없으면 절차를 무시합니다.
      if(alias==targetWidget[0]?.alias){
        return false
      }

      Widget.alias(context,uuid,alias)
      return false
    }
    
    const aliasCheck = (uuid,alias) => {
      const widgetList = context?.nowPage()?.page_widget;
      const filterWidget = widgetList.filter(widget => widget.uuid != uuid);
      const DuplicateCheck = filterWidget.some(widget => widget.alias === alias);
      if(DuplicateCheck){
        return true
      }
      return false
    }
 

    const list = getWidgetList();

    if(list.length == 0 && ContextReady){
        return (
            <Fragment>
                <style jsx>{`${EditPageStyle}`}</style>
                <div id = "WidgetListPanel">
                    <div className="panelMessage">
                        <div className="panelMessageImage">
                        {Editor.icon({icon:'widgets',size:80,lineHeight:100,color:'#adadad'})}
                        </div>
                        <div className="panelMessageInfo">
                            <p>위젯이 없습니다</p>
                            <p>새로운 위젯을 추가해보세요</p>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }


    const page = context?.nowPage();

    return (
        <Fragment>
                <style jsx>{`${EditPageStyle}`}</style>
    
                <div id = "WidgetListPanel" onClick={()=>context.hideContext()}>
                <ReactSortable
                    className="widgetOrderList" 
                    list={list}
                    setList={(order) => update(order)}
                    animation = { 200 }
                    handle = '.handle' 
                    delayOnTouchStart = { true } 
                >
                    {list.map((item,index) => (
                        <ListOrder key={page?.uuid+'_'+item.uuid} uuid={item.uuid} select={context.selectWidget==item.uuid} setSelect={setSelect} widget={item} context={(event)=>setContext(true,item.uuid,event.clientX,event.clientY)} aliasChange={aliasChange} aliasCheck={aliasCheck}/>
                    ))}
                </ReactSortable>
                </div>
        </Fragment>
    );

}

////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    // mainScreen : state.mainScreen,
})

function mapDispatchToProps(dispatch){
    return {
        // initOverlap : (overlapData) => dispatch({type:'initOverlap',overlap : overlapData}),
    }
}

  // export default EditPage;
export default connect(mapStateToProps,mapDispatchToProps)(WidgetListPanel);
  


  const EditPageStyle = `

    #WidgetListPanel{
      width: calc( 100% );
      height: 100%;
      overflow: hidden;
    }


  #WidgetListPanel .ListOrder {
    position: relative;
    background-color: #3F3F3F;
    border-radius: 5px;
    height: 25px;
    font-size : 15px;
    overflow: hidden;
    transition: height 0.3s ease, border 0.3s ease;
    width: calc( 100% - 12px );
    margin : 7.5px 2px 0px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    border: 2px solid #3F3F3F;
  }


  #WidgetListPanel .ListOrder:first-child  {
    margin-top : 10px;
    
  }

  #WidgetListPanel .ListOrder:last-child  {
    margin-bottom : 10px;
  }
  #WidgetListPanel .selectListOrder{
    border: 2px solid #FFFFFF;
  }

  #WidgetListPanel .widgetOrderList {
    width: calc(100% - 8px);
    padding-right: 8px; /* 스크롤바의 너비 */
    height: 100%;
    overflow-y: hidden;
  }

  #WidgetListPanel .widgetOrderList:hover {
    width: calc(100%);
    padding-right: 0px; /* 스크롤바의 너비 */
    overflow-y: scroll; /* 마우스 오버 시 스크롤바를 표시합니다. */
  }

  /* 크로스 브라우저 호환성을 위한 스크롤바 스타일링 */
  #WidgetListPanel .widgetOrderList:hover::-webkit-scrollbar {
      width: 8px; /* 스크롤바의 너비 */
  }

  #WidgetListPanel .widgetOrderList::-webkit-scrollbar-thumb {
      background-color: #000000aa; /* 스크롤바의 색상 */
      border-radius: 4px; /* 스크롤바의 모서리 */
  }

  #WidgetListPanel .widgetOrderList::-webkit-scrollbar-track {
      background: transparent; /* 스크롤 트랙의 색상 */
      width: 8px; /* 스크롤바의 너비 */
  }



  #WidgetListPanel .ListInfo{
    display: flex;
    box-sizing: border-box;
    padding: 0px 10px;
  }
  #WidgetListPanel .ListInfo *{

  }

  #WidgetListPanel .ListInfo .ListState{
    width: 40px;
    height: 20px;
  }

  #WidgetListPanel .ListTitle{
    color: #ffffff;
    font-family: '프리텐다드SemiBold';
  }
  #WidgetListPanel .ListTitle::placeholder{
    color: #aaaaaa;
    font-family: '프리텐다드SemiBold';
  }

  #WidgetListPanel .ListState{
    display: flex;
  }
  

  #WidgetListPanel .ListOrder{
    display: flex;
  }

  #WidgetListPanel .panelMessage{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }



  #WidgetListPanel .panelMessage .panelMessageInfo{
    color: #adadad;
    text-align: center;
  }
  `;