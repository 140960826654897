////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widgetEdit.scss"

// 컴포넌트 연결
import TextModule from '../editModule/TextModule'; // 텍스트 모듈
import SentenceModule from '../editModule/SentenceModule';
import NumberModule from '../editModule/NumberModule'; // 텍스트 크기 모듈
import FontModule from '../editModule/FontModule'; // 폰트 종류 모듈
import ColorModule from '../editModule/ColorModule'; // 색상 모듈
import RangeModule from '../editModule/RangeModule'; //범위 모듈 
import SelectModule from '../editModule/SelectModule'; // 선택리스트 모듈
import RadioButtonModule from '../editModule/RadioButtonModule';

import del from 'assets/edit/delete.png';

// 서비스 연결
import * as Utility from 'service/other/Utility'; 

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class DraftPickEdit extends Component {
    
    state = {
        value : ''

    }

    change = (name,value) =>{
        this.props.callBack('style',name,value)
    }
    valueChange = (name,value) =>{

        switch (name) {
            case 'fontSize':
                // px 때문에 비교 불가 
                if(value>this.props.data.size.height){
                    console.log('텍스트 증가필요 ')
                }
                break;
        
            default:
                break;
        }
        this.props.callBack('value',name,value)

    }

    addList = () =>{
        if( this.props.data.value.member.length<20){
            let member  = this.props.data.value.member
            member.push({name:'',position:'',memo:''})

            member = Utility.deepCopy(member)
            this.valueChange('member',member)
        }
        
    }

    memberChange = (e,index,type)  => {
        let member  = this.props.data.value.member
        member[index][type] =  e.target.value
        
        this.valueChange('member',member)
    }

    deleteList = (num) =>{
        let member  = this.props.data.value.member
        member.splice(member, 1);
        this.valueChange('member',member)
    }
   

    tablelist = (listup)=>{
        // let newlist = {'오버워치 탱커':'overwatchTanker','오버워치 공격':'overwatchDps','오버워치 지원':'overwatchSuppot'}
        let newlist = [{type: 'overwatch', ko:'없음',icon:null},{type: 'overwatch', ko:'탱커',icon:'overwatchTanker'},{type: 'overwatch', ko:'공격',icon:'overwatchDps'},{type: 'overwatch', ko:'지원',icon:'overwatchSuppot'}]
        const option = newlist.map((list,index) => (<option key={index} value={list.icon}>{list.ko}</option>));
        let list = this.props.data.value.member.map(
            (info,index) => (
                <div className="teamMember">
                    <label className="EditModule" htmlfor="fname"> 
                    팀원{index+1} <input value={this.props.data.value.member[index].name} className="text" onChange={(e)=>this.memberChange(e,index,'name')}/>
                    </label>
                    <label className="EditModule" htmlfor="fname"> 
                    메모 <input value={this.props.data.value.member[index].memo} className="text" onChange={(e)=>this.memberChange(e,index,'memo')}/>
                    </label>
                    <label className="EditModule other" htmlfor="fname"> 
                        <button className="delete" onClick={this.deleteList}>
                            삭제하기
                        </button>      
                        <div className="EditModule otherOption" >
                            <span>
                                팀장 <input type="checkbox"  checked={this.props.data.value.leader==index} onChange={()=>this.valueChange('leader',index)}/>
                            </span>      
                            <span>
                                포지션  <select value={this.props.data.value.member[index].position} onChange={(e)=>this.memberChange(e,index,'position')}>{option}</select> 
                            </span>
                        </div>
                    </label>
                </div>
            ) 
        );    

        
        return(
            <table  className="EditTableList" border="0">
            {this.props.data.value.member.length>0?
            ''
            :<p className="notice" style={{'margin':0}}>여기서 팀원을 추가 할 수 있습니다.</p>
            }
            {list}
            <div>
                {this.props.data.value.member.length<10?
                <button className="addList" onClick={()=>this.addList()}>
                <p>새 팀원 추가하기</p>
                </button>
                :''}
            </div>
            </table>
        )
    }
    
    render() { 

        const Style = {
            label: {
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
            },
            textarea : {
                width: '100%',
                resize: 'none',
                boxSizing:'border-box',
            },
            mediaView : {
                width: '100%',
                MaxHeight : '500px',
            }

        }
        return (
            <Fragment>

            <fieldset className="EditModuleField DraftPickEdit" style={Style.fieldset} >
                <legend style={Style.legend} > 팀 </legend>
                <NumberModule value={this.props.data.value.point} type ="point" title="경매 포인트" change={this.valueChange}/>
                <SentenceModule value={this.props.data.value.name} type ="name" title="팀 이름" change={this.valueChange}/>
            </fieldset>

            <fieldset className="EditModuleField DraftPickEdit" style={Style.fieldset} >
                <legend style={Style.legend} > 팀원 </legend>
                {this.tablelist()}
            </fieldset>
            

            <fieldset className="EditModuleField" style={Style.fieldset} >
                <legend style={Style.legend} > 색상 </legend>
                <ColorModule value={this.props.data.style.main_fill} type ="main_fill" title="주배경색" change={this.change}/>
                <ColorModule value={this.props.data.style.sub_fill} type ="sub_fill" title="보조배경색" change={this.change}/>
                <ColorModule value={this.props.data.style.main_color} type ="main_color" title="주글자색" change={this.change}/>
                <ColorModule value={this.props.data.style.sub_color} type ="sub_color" title="보조글자색" change={this.change}/>
                {/* <TextSizeModule value={this.props.data.style.fontSize} type ="fontSize" change={this.change}/> */}
                <FontModule  value={this.props.data.style.fontFamily} type ="fontFamily" change={this.change}/>

                <RangeModule value={this.props.data.style.fillOpacity} type ="fillOpacity" title="배경투명도" valueView="%" min="0" max="100" change={this.change} />
            </fieldset>


            </Fragment>
        );
    }
}

////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    // screenData : state.screenData,
})

function mapDispatchToProps(dispatch){
    return {
    //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(DraftPickEdit);
  