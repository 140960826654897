////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { useState, useEffect, useContext, Fragment, useCallback } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// [프로바이더] 연결
import EditContext from 'service/context/EditContext' ; 
// SASS&CSS 연결
import "sass/widgetEdit.scss"

// 컴포넌트 연결
import ImageButtonModule from '../editModule/ImageButtonModule'; // 이미지 버튼을 눌러 여러 옵션중 값을 정합니다.
import PositionModule from '../editModule/PositionModule'; // 위젯의 사이즈를 조정 하는 모듈입니다.
import ErrorModule from '../editModule/ErrorModule'; // 잘못된 위젯의 값이 있을때 편집기를 대체합니다.

// 서비스 연결
import * as Editor from 'service/edit/EditService'; 
import * as ED from 'service/engine/decoder/EditorDecoder'; 

// 이미지 연결

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////


/** [에디터] 텍스트 편집 툴 입니다.
 * (대상위젯, 편집펑션, 편집할 객체 키, 타이틀 ,옵션)
* @param {widget} target : 편집할 대상 위젯
* @param {function} modify : 편집용 함수
* @param {{
* style: { x : number, y : number }
* }} keyName : 해당 에디터가 수정하는 값 입니다. value : {'textValue', 수정할 대상값,} 
* @param {{
* editTitle: string,textValue: string
* }} title : 편집기에 표시될 제목들 입니다.
* @param {{
* fixToggle : boolean 
* openToggle : boolean
* }} option : 추가 옵션입니다. fixToggle : 토글을 고정합니다. openToggle : 필드의 기본 상태를 열림으로 봅니다.
*/


function PositionEditForm({target,modify,keyName,title,option}) {

    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////

    // set
    const context = useContext(EditContext);
    const uuid = target.uuid

    // state
    const [Toggle, setToggle] = useState(option?.openToggle==undefined?true:option?.openToggle);
    const [OpenForm, setOpenForm] = useState(option?.openForm==undefined?true:option?.openForm);

    // rel 

    

    ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////
    useEffect(() => {

        // 여기에 컴포넌트가 마운트되었을 때 실행할 코드를 작성합니다.

        return () => {
        // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
        };
      }, []);

    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////
    const {positionX,positionY,width,height} = keyName ;

    const alignX = (value) =>{
        const Resolution = context?.overlap?.meta?.resolution.width
        const Position = ED.getValue(target,positionX)
        const Size = ED.getValue(target,width)

        switch (value) {
            case 'start':
                modify(positionX.category,positionX?.key,0,uuid)
                break;
            case 'start-mid':
                modify(positionX.category,positionX?.key,Math.round(Number(Resolution)/4 - Number(Size)/2),uuid)
                break;
            case 'mid':
                modify(positionX.category,positionX?.key,Math.round(Number(Resolution)/2 - Number(Size)/2),uuid)
                break;   
            case 'mid-end':
                modify(positionX.category,positionX?.key,Math.round(Number(Resolution)/4 + Number(Resolution)/2 - Number(Size)/2),uuid)
                break;   
            case 'end':
                modify(positionX.category,positionX?.key,Math.round(Number(Resolution) - Number(Size)),uuid)
                break;      
            default:
                break;
        }
    }
    const valueCheckX = (value) =>{
        const Resolution = context?.overlap?.meta?.resolution.width
        const Position = ED.getValue(target,positionX)
        const Size = ED.getValue(target,width)

        switch (value) {

            case Math.round(Number(Resolution)/2 - Number(Size)/2) :
                return 'mid'
            case 0 :
                return 'start'
            case Math.round(Number(Resolution) - Number(Size)) :
                return 'end'
            case Math.round(Number(Resolution)/4 - Number(Size)/2) :
                return 'start-mid'
            case Math.round(Number(Resolution)/4 + Number(Resolution)/2 - Number(Size)/2) :
                return 'mid-end'
            default:
                break;
        }

    }


    const alignY= (value) =>{
        const Resolution = context?.overlap?.meta?.resolution.height
        const Position = ED.getValue(target,positionY)
        const Size = ED.getValue(target,height)
       
        switch (value) {
            case 'start':
                modify(positionY.category,positionY?.key,0,uuid)
                break;
            case 'start-mid':
                modify(positionY.category,positionY?.key,Math.round(Number(Resolution)/4 - Number(Size)/2),uuid)
                break;
            case 'mid':
                modify(positionY.category,positionY?.key,Math.round(Number(Resolution)/2 - Number(Size)/2),uuid)
                break;  
            case 'mid-end':
                modify(positionY.category,positionY?.key,Math.round(Number(Resolution)/4 + Number(Resolution)/2 - Number(Size)/2),uuid)
                break;   
            case 'end':
                modify(positionY.category,positionY?.key,Math.round(Number(Resolution) - Number(Size)),uuid)
                break;      
            default:
                break;
        }
    }
    const valueCheckY= (value) =>{
        const Resolution = context?.overlap?.meta?.resolution.height
        const Position = ED.getValue(target,positionY)
        const Size = ED.getValue(target,height)

        switch (value) {
            case Math.round(Number(Resolution)/2 - Number(Size)/2) :
                return 'mid'
            case 0 :
                return 'start'
            case Math.round(Number(Resolution) - Number(Size)) :
                return 'end'
            case Math.round(Number(Resolution)/4 - Number(Size)/2) :
                return 'start-mid'
            case Math.round(Number(Resolution)/4 + Number(Resolution)/2 - Number(Size)/2) :
                return 'mid-end'
            default:
                break;
        }

    }

    // 해당 에디터에서 편집이 가능한 값 목록

    const numberChecked = (category,type,value,uuid) =>{
        let checkValue = value.slice(0, 5);

        checkValue = checkValue.replace(/[^0-9]/g, ''); // 숫자가 아닌 모든 문자를 제거
        // checkValue = checkValue.replace(/^0+/, ''); // 앞의 0을 제거

        if(isNaN(Number(value))){
        // modify(category,type,0,uuid)
        }else{
            modify(category,type,Number(checkValue),uuid)
        }
    }

    const handleFocus = useCallback((e) => {
        e.target.select();
      }, []);
    
    const Options = {}

    if(option.closeEdit){
        Options.closeEdit = (<div className='closeEdit'>
            <input className='closeEdit_positionX' type="number" value={ED.getValue(target,positionX)} onFocus={handleFocus} onChange={(e) =>numberChecked(positionX.category,positionX?.key,e.target.value.slice(0, 5),uuid)} />
            <div className='closeEdit_Icon' >
                {Editor.icon({icon:'close_small',size:15,lineHeight:25})}
            </div>
            <input className='closeEdit_positionY' type="number" value={ED.getValue(target,positionY)} onFocus={handleFocus} onChange={(e) =>numberChecked(positionY.category,positionY?.key,e.target.value.slice(0, 5),uuid)} />
        </div>)
    }

    return (
    <Fragment>
        <style jsx>{`${FormStyle}`}</style>
        <section className='PositionEditForm'>
            {Editor.editorDetail('위치','open_with',OpenForm,setOpenForm,Toggle,Options)}
            <div className={OpenForm?'editContainer containerOpen':'editContainer containerClose'}>

                <article className="EditPart" >
                {positionX?<PositionModule value={ED.getValue(target,positionX)} onFocus={handleFocus} resolution={context?.overlap?.meta?.resolution?.width} widgetSize={ED.getValue(target,width)} title={positionX?.title??"좌우 위치"} type = {positionX?.key} change={(type,value)=>numberChecked(positionX.category,type,value,uuid)}/>:null}
                {positionX?<ImageButtonModule value={valueCheckX(ED.getValue(target,positionX))} type ="alignX" title={positionX?.title??" "}  data={[{val:"start",icon:"align_justify_flex_start"},{val:"start-mid",icon:"fiber_manual_record"},{val:"mid",icon:"align_justify_center"},{val:"mid-end",icon:"fiber_manual_record"},{val:"end",icon:"align_justify_flex_end"},]} option={{fontSize:'13px',lineHeight:'15px',buttonSize:'15px'}} change={(type,value)=>alignX(value)}/>:null}
                {positionY?<PositionModule value={ED.getValue(target,positionY)} onFocus={handleFocus} title={positionX?.title??"상하 위치"} type = {positionY?.key} change={(type,value)=>numberChecked(positionY.category,type,value,uuid)}/>:null}
                {positionY?<ImageButtonModule value={valueCheckY(ED.getValue(target,positionY))} type ="alignY" title={positionY?.title??" "}  data={[{val:"start",icon:"align_start"},{val:"start-mid",icon:"fiber_manual_record"},{val:"mid",icon:"align_center"},{val:"mid-end",icon:"fiber_manual_record"},{val:"end",icon:"align_end"},]} option={{fontSize:'13px',lineHeight:'15px',buttonSize:'15px'}}  change={(type,value)=>alignY(value)}/>:null}
                </article>
            </div>
        </section>
    </Fragment>
    );

}

export default PositionEditForm;

const FormStyle = `

.PositionEditForm .ButtonList .ButtonModule{
    padding: 0px;

}
.PositionEditForm .closeEdit{
    height: 25px;
    display:flex;
    background-color: #444;
    border-radius :5px;
    padding : 0px;
}

.PositionEditForm .closeEdit input[type="number"]{
    outline : none;
    background : none;
    border : none;
    margin : 0;
    padding : 0;
    color : #fff;
    height: 25px;
    width: 45px;
    /* Reset some default styles */
    -webkit-appearance: none; /* For Chrome */
    -moz-appearance: textfield; /* For Firefox */
    font-family: "프리텐다드Medium", sans-serif;
    font-size: 13px;

}

.PositionEditForm .closeEdit input[type="number"]::-webkit-inner-spin-button,
.PositionEditForm .closeEdit input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.PositionEditForm .closeEdit_positionX{
    display:flex;
    justify-content: flex-end;      
    text-align: end;

}
.PositionEditForm .closeEdit_positionY{
    display:flex;
    justify-content: flex-start;
    text-align: start;
}

.PositionEditForm .closeEdit_Icon{
    margin : 0px 2.5px;
}
`;