////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { useState, useEffect, useContext,useCallback, Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widgetEdit.scss"

// 컴포넌트 연결
import SwitchModule from '../editModule/SwitchModule'; // 이미지 버튼을 눌러 여러 옵션중 값을 정합니다.
import SizeModule from '../editModule/SizeModule'; // 위젯의 사이즈를 조정 하는 모듈입니다.
import ErrorModule from '../editModule/ErrorModule'; // 잘못된 위젯의 값이 있을때 편집기를 대체합니다.

// 서비스 연결
import * as Editor from 'service/edit/EditService'; 
import * as ED from 'service/engine/decoder/EditorDecoder'; 
// 이미지 연결

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////


/** [에디터] 텍스트 편집 툴 입니다.
 * (대상위젯, 편집펑션, 편집할 객체 키, 타이틀 ,옵션)
* @param {widget} target : 편집할 대상 위젯
* @param {function} modify : 편집용 함수
* @param {{
* size: { width : number, height : number }
* }} keyName : 해당 에디터가 수정하는 값 입니다. value : {'textValue', 수정할 대상값,} 
* @param {{
* editTitle: string,textValue: string
* }} title : 편집기에 표시될 제목들 입니다.
* @param {{
* fixToggle : boolean 
* openToggle : boolean
* }} option : 추가 옵션입니다. fixToggle : 토글을 고정합니다. openToggle : 필드의 기본 상태를 열림으로 봅니다.
*/


function SizeEditForm({target,modify,keyName,title,option}) {

/**
     * 위젯을 수정합니다.
     *  @param {bool} classify : 수정할 값의 분류를 적습니다.
     *  @param {bool} key : 수정할 키의 이름을 적습니다.
     *  @param {bool} value : 해당페이지가 선택한 뷰에서 방송중인지 확인합니다.
     */

    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////

    // set
    const uuid = target.uuid
    const {style={},meta={},size={},value={},position={},display={},preset=null} = keyName
    const {width,height,fixRatio} = keyName ;
    // state
    const [Toggle, setToggle] = useState(option?.openToggle==undefined?true:option?.openToggle);
    const [OpenForm, setOpenForm] = useState(option?.openForm==undefined?true:option?.openForm);

    const [Ratio, setRatio] = useState(0);

    const [WidthBuffer, setWidthBuffer] = useState(0);
    const [HeightBuffer, setHeightBuffer] = useState(0);
    // rel 

    const CheckWidth = ED.getValue(target,width);
    const CheckHeight = ED.getValue(target,height);

    ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////
    useEffect(() => {

        // 여기에 컴포넌트가 마운트되었을 때 실행할 코드를 작성합니다.

        return () => {
        // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
        };
      }, []);
      


      useEffect(() => {

        return () => {
        // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
        };
      }, [WidthBuffer]);

      useEffect(() => {

        return () => {
        // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
        };
      }, [HeightBuffer]);

    //   useEffect(() => {

    //     // 여기에 컴포넌트가 마운트되었을 때 실행할 코드를 작성합니다.
    //     if (value != 0) {
    
    //         const Ratio = value /  ED.getValue(target,width);
    //         const RoundValue = Math.round(ED.getValue(target,width) / Ratio)
    //         modify(width.category,width.key,RoundValue,uuid)
    //         }
    //     console.log('값 width 변경')
    //     return () => {
    //     // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
    //     };
    //   }, [CheckHeight]);

    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////

    // 해당 에디터에서 편집이 가능한 값 목록


    const handleFocus = (type,e) => {

        e.target.select();

        

        const PrevValue = e.target.value ;

        // 포커스 당시 숫자가 아니면 Ratio 값을 0으로 만들고 비율 반영을 하지 않습니다.
        if(isNaN(Number(PrevValue))){
            setRatio(0)
            return;
        }
        // 포커스 당시 0 이거나 잘못된 값이면 Ratio 값을 0으로 만들고 비율 반영을 하지 않습니다.
        if(!PrevValue || PrevValue ==''){
            setRatio(0)
            return;
        }

        let ratio ;
        if(type == 'width'){
            ratio = e.target.value /  ED.getValue(target,height);
            console.log('기초 원인 파악 W ',ratio, e.target.value, ED.getValue(target,height))
            if(ratio == NaN){
                setRatio(0)
            }
            setRatio(ratio)
        }else if (type == 'height'){
            ratio = e.target.value /  ED.getValue(target,width);
            console.log('기초 원인 파악 H ',ratio, e.target.value, ED.getValue(target,width))
            if(ratio == NaN){
                setRatio(0)
            }
            setRatio(ratio)
        }
      }
    
    const Options = {}

    const numberChecked = (category,type,value,uuid) =>{
        let checkValue = value.slice(0, 5);

        modify(category,type,Number(checkValue),uuid)
    }



    const ratioModify = (type,value) =>{
        console.log('ED.getValue(target,fixRatio)',ED.getValue(target,fixRatio))

        if(ED.getValue(target,fixRatio) == true){

            if (Ratio != 0 ) {
                console.log('시작 원인 파악',Ratio,ED.getValue(target,width),ED.getValue(target,height),value)
                if(type == 'width'){
                    const RoundValue = Math.round(ED.getValue(target,width) / Ratio)
                    modify(height.category,height.key,RoundValue,uuid)    
                }else if (type == 'height'){
                    const RoundValue = Math.round(ED.getValue(target,height) / Ratio)
                    modify(width.category,width.key,RoundValue,uuid)
                }
            }
        }

    }


    if(option.closeEdit){
        Options.closeEdit = (<div className='closeEdit'>
            <input className='closeEdit_width' type="number" value={ED.getValue(target,width)} onBlur={(e)=>ratioModify(width?.key,e.target.value)} onFocus={(e)=>handleFocus(width?.key,e)}  onChange={(e) =>modify(width.category,width?.key,e.target.value.slice(0, 5),uuid)} />
            <div className='closeEdit_Icon' >
                {Editor.icon({icon:'close_small',size:15,lineHeight:25})}
            </div>
            <input className='closeEdit_height' type="number" value={ED.getValue(target,height)} onBlur={(e)=>ratioModify(height?.key,e.target.value)} onFocus={(e)=>handleFocus(height?.key,e)}  onChange={(e) =>modify(height.category,height?.key,e.target.value.slice(0, 5),uuid)} />
        </div>)
    }

    return (
    <Fragment>
        <style jsx>{`${FormStyle}`}</style>
        <section className='SizeEditForm'>
            {Editor.editorDetail('크기','open_in_full',OpenForm,setOpenForm,Toggle,Options)}
            <div className={OpenForm?'editContainer containerOpen':'editContainer containerClose'}>
                {/* {Editor.editorSubTitle('텍스트 설정',"edit_note")} */}
                <article className="EditPart" >
                {width?<SizeModule value={ED.getValue(target,width)} max={Infinity} title={width?.title??'가로 크기'}  type = {width?.key} onBlur={ratioModify} onFocus={handleFocus} change={(type,value)=>modify(width?.category,type,value,uuid)}/>:''}
                {height?<SizeModule value={ED.getValue(target,height)} max={Infinity} title={width?.height??'세로 크기'} type = {height?.key} onBlur={ratioModify} onFocus={handleFocus} change={(type,value)=>modify(height?.category,type,value,uuid)}/>:''}
                {width&&height? <SwitchModule value={ED.getValue(target,fixRatio)} title={fixRatio?.title??"비율 고정"} type ={fixRatio.key} change={(type,value)=>modify(fixRatio.category,type,value,uuid)}/> : ''}
                {/* Ratio : {Ratio} */}
                </article>
            </div>
        </section>
    </Fragment>
    );

}

export default SizeEditForm;


const FormStyle = `

.SizeEditForm .ButtonList .ButtonModule{
    padding: 0px;

}
.SizeEditForm .closeEdit{
    height: 25px;
    display:flex;
    background-color: #444;
    border-radius :5px;
    padding : 0px;
}

.SizeEditForm .closeEdit input[type="number"]{
    outline : none;
    background : none;
    border : none;
    margin : 0;
    padding : 0;
    color : #fff;
    height: 25px;
    width: 45px;
    /* Reset some default styles */
    -webkit-appearance: none; /* For Chrome */
    -moz-appearance: textfield; /* For Firefox */
    font-family: "프리텐다드Medium", sans-serif;
    font-size: 13px;

}

.SizeEditForm .closeEdit input[type="number"]::-webkit-inner-spin-button,
.SizeEditForm .closeEdit input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.SizeEditForm .closeEdit_width{
    display:flex;
    justify-content: flex-end;      
    text-align: end;

}
.SizeEditForm .closeEdit_height{
    display:flex;
    justify-content: flex-start;
    text-align: start;
}

.SizeEditForm .closeEdit_Icon{
    margin : 0px 2.5px;
}

`;