////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, {useState, useEffect, useRef } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { Route, Link } from 'react-router-dom';
import { useHistory } from "react-router";
// 라이브러리 

// 설정 파일
import * as config from 'config/OverlapConfig'

// [컴포넌트] 위젯 
// import MiniView from "components/viewer/MiniView"

// [프로바이더]
import AssistContext from 'service/context/AssistContext';
// [리덕스]스토어 연결
// import store from "store";

// SASS&CSS 연결
// import 'sass/login.scss'

// 이미지 연결
// import logoW from 'assets/logoW.svg';


// 서비스 연결
import * as DeCoder from '../../service/engine/DeCoder' ; 
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

/** [디스플레이] 
 * 편집 화면에서는 
 * @param {*} page 오버랩의 페이지 객체 (1개)
 * @param {number|null} previewWidth 컴포넌트의 가로 사이즈
 * @param {number|null} previewHeight 컴포넌트의 세로 사이즈
 * @param {number} referenceWidth 디스플레이의 해상도
 * @param {number} referenceHeight 디스플레이의 해상도
 * @param {*} option 디스플레이에 적용할 옵션
 * @returns 
 */


const EditerDisplay =  React.memo(function EditerDisplay({ page = null, scale=0, global={},broadcast = false, previewWidth = null, previewHeight = null, referenceWidth, referenceHeight, position={top:0,left:0}, option={} }) {
    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////
    // state
    // const [overlapList, setList] = useState(null);
   
    // ref

    // const displayRef = useRef(null);

    ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////


      const width = previewWidth;
      const height = previewHeight;
      let zoom = (height / referenceHeight)??0;

    ////////////////////////////////////////////////////////
    // Fuction
    ////////////////////////////////////////////////////////
    const DisplayCore = (assistState) => {
        if(scale){
            return DeCoder.run(page,'edit',global,{animation:true})
        }
    }

    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////


    // 가로 대 새로비율을 구합니다.
    const Ratio = referenceHeight/referenceWidth;
    
    const ContainerSizeControl ={
        height: previewHeight,
        width : previewWidth,
        top : position.top,
        left : position.left,
        alignItems: option.align??'center',
    }

    const displaySizeControl = {

        aspectRatio:  `${referenceWidth} / ${referenceHeight}`,
      };

    if(previewHeight>=previewWidth*Ratio){
      displaySizeControl['width'] = previewWidth;
      zoom = (width / referenceWidth)??0;
    }else{
      displaySizeControl['height'] = previewHeight;
      displaySizeControl['width'] = (previewHeight * referenceWidth / referenceHeight);
      zoom = (height / referenceHeight)??0;
    }
    const zoomControl = {
        transform:  `scale(${scale})`,
    };

    const BackgroundControl = {
      width :referenceWidth,
      height :referenceHeight,
      display : 'flex'
    };
      
    return (
        <div className={broadcast?'EditerDisplayContainer Broadcast' :'EditerDisplayContainer'} style={ContainerSizeControl}   >
        {/* <style jsx>{`${DisplayStyle}${SizeStyle}`}</style> */}
        <style jsx>{`${DisplayStyle}`}</style>
        <AssistContext.Consumer>
          {assistState => (
            <div style={displaySizeControl} className='EditerDisplay'>
                <div style={zoomControl} className='EditerDisplayCore'>
                  <div id='EditerDisplayBackground' className='transparent-background' style={BackgroundControl}/>
                  {DisplayCore(assistState)}
                </div>
            </div>
          )}
          </AssistContext.Consumer>
        </div>
    );
  });

  export default EditerDisplay;
////////////////////////////////////////////////////////
// css 부분
////////////////////////////////////////////////////////
const DisplayStyle = `
    .EditerDisplayContainer {
        width: 100%;
        height: 100%;
        display:flex;
        justify-content: center;
        position: absolute;
    }
    .EditerDisplayContainer .EditerDisplay{        
        box-sizing: border-box;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 4px 0px, 
            rgba(0, 0, 0, 0.1) 0px 0px 8px 2px, 
            rgba(73, 25, 25, 0.07) 0px 0px 16px 4px, 
            rgba(0, 0, 0, 0.1) 0px 0px 24px 6px; 
        transition : box-shadow 0.3s;

    }

           @keyframes pulse {
            0% {
                box-shadow: rgba(255, 0, 0, 0.1) 0px 0px 4px 0px, 
                            rgba(255, 0, 0, 0.1) 0px 0px 8px 2px, 
                            rgba(255, 25, 25, 0.07) 0px 0px 16px 4px, 
                            rgba(255, 0, 0, 0.1) 0px 0px 24px 6px;
            }
            50% {
                box-shadow: rgba(255, 0, 0, 0.3) 0px 0px 4px 0px, 
                            rgba(255, 0, 0, 0.3) 0px 0px 8px 2px, 
                            rgba(255, 25, 25, 0.2) 0px 0px 16px 4px, 
                            rgba(255, 0, 0, 0.3) 0px 0px 24px 6px;
            }
            100% {
                box-shadow: rgba(255, 0, 0, 0.1) 0px 0px 4px 0px, 
                            rgba(255, 0, 0, 0.1) 0px 0px 8px 2px, 
                            rgba(255, 25, 25, 0.07) 0px 0px 16px 4px, 
                            rgba(255, 0, 0, 0.1) 0px 0px 24px 6px;
            }
        }

    .EditerDisplayContainer.Broadcast .EditerDisplay{
      box-shadow: rgba(255, 0, 0, 0.1) 0px 0px 4px 0px, 
       rgba(255, 0, 0, 0.1) 0px 0px 8px 2px, 
       rgba(255, 25, 25, 0.07) 0px 0px 16px 4px, 
       rgba(255, 0, 0, 0.1) 0px 0px 24px 6px;
      animation: pulse 5s infinite;
      animation-delay: 0.3s; /* 0.3초 딜레이 */
    }

    .EditerDisplayContainer #EditerDisplayBackground.transparent-background {
      width: 100%;
      height: 100%;
      display:flex;
      background-image: linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff),
                        linear-gradient(45deg, #fff 25%, #ccc 25%, #ccc 75%, #fff 75%, #fff);
      background-size: 20px 20px;
      background-position: 0 0, 10px 10px;
    }

    .white-background {
      background-color : #ffffff;
    }
    
    .EditerDisplayContainer .EditerDisplayCore{
        width: 100%;
        height: 100%;
        transform-origin: 0% 0%; 
     
    }
 
`;


