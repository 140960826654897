////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { useState , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { Route, Link } from 'react-router-dom';
import { useHistory } from "react-router";
// 라이브러리 
import { useGoogleLogin } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google'// 구글 로그인
// 설정 파일
import * as config from 'config/OverlapConfig'

import { withRouter } from 'react-router-dom';

import * as apiAccount from 'service/api/apiAccount'
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import 'sass/login.scss'

// 이미지 연결
import logoW from 'assets/logoW.svg';

// 서비스 연결
import * as Editor from 'service/edit/EditService' ; 
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////




function Login(props) {

    const [LoginAlert, setLoginAlert] = useState(null);

    const googleLogin = useGoogleLogin({
        onSuccess: codeResponse => overlapLoginwithGoogle(codeResponse),
        flow: 'auth-code',
      }
    
      );

    const overlapLoginwithGoogle = async (codeResponse) => {
        try {
            const res = await apiAccount.singin_google(codeResponse,props)
        } catch (error) {

            if (error.response) {
                // 서버가 응답한 오류 상태 코드
                console.error('서버 응답 오류:', error.response.status);
                if (error.response.status >= 500) {
                    setLoginAlert('서버 문제가 있어 데이터를 처리 하지 못했습니다.')
                } else if (error.response.status >= 400) {
                    setLoginAlert('서버 연결에 실패 하였습니다.')
                }
            } else if (error.request) {
                // 요청이 만들어졌지만, 응답을 받지 못한 경우
                setLoginAlert('서버가 응답하지 않습니다.')
            } else {
                setLoginAlert('알 수 없는 문제가 있습니다.')
                // 요청 설정 중에 문제가 발생한 경우
                console.error('요청 설정 중 오류 발생:', error.message);
            }


        }
    } 
      
    const push = () => {

    }

    return (
        <div id = "LoginPage" >
            <div id='PageMain'>
                <div id="logoBox">
                    <img src={logoW}/>
                </div>
                <h3  id="infoText" >오버랩에 오신것을 환영합니다.</h3>
                <div id="GoogleOAuth"  onClick={() => googleLogin()}>
                    <svg id="GoogleOAuthLogo" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z" fill="#4285F4"/><path d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z" fill="#34A853"/><path d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z" fill="#FBBC05"/><path d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z" fill="#EA4335"/><path d="M1 1h22v22H1z" fill="none"/></svg>
                    <div id="GoogleOAuthText" >
                    구글 로그인
                    </div>
                 </div>    
            </div>
           <div id="HoverAlert">
           {LoginAlert?
                <div className='AlertMsg'>
                    {LoginAlert}
                </div>
            :null}
            </div>
            <Link  className="MoveHome" to="/">
            {Editor.icon({icon:'arrow_back',size:25,lineHeight:30})} &nbsp; 뒤로 돌아가기              
            </Link>
        
        </div>
        
    );
  }

  export default Login;
