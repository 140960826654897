
////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// 포지션 레퍼런스
////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function position ({widget}) {
    let keys = Object.keys(widget)
    let result = {
      top: '192px',
      left: '108px',
    }
  
    if(keys.includes('x')){
      result['left'] = widget.x +'px'
    }
  
    if(keys.includes('y')){
      result['top'] = widget.y +'px'
    }
    return result
  }
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // 사이즈 레퍼런스
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  export function lineSize ({widget,width='200px',height='200px'}) {
    let keys = Object.keys(widget)
    let result = {
      width,
      height,
    }
    if(keys.includes('width')){
      if (widget.width=='auto'){
        result['width'] = 'auto'
      }else {
        result['width'] = widget.width +'px'
      }
    }
    return result
  }
  
  
  
  export function size ({widget,width='200px',height='200px'}) {
    
    let keys = Object.keys(widget)
    let result = {
      width,
      height,
    }
    if(keys.includes('width')){
      result['width'] = widget.width +'px'

      // if (String(widget.width).indexOf('%')!=-1){
      //   const state = store.getState(); // 현재 상태를 가져옵니다.
      //   const {broadCast} = state; // 편의상 비구조화 할당
      //   result['width'] = (broadCast.size.width/100*Number(widget.width.substring(0,(widget.width).indexOf('%'))))+'px'
      // } 
      // else if (widget.width=='auto'){
      //   result['width'] = 'auto'
      // } else {
      //   result['width'] = widget.width +'px'
      // }
    }
  
    if(keys.includes('height')){
      result['height'] = widget.height +'px'

      // if (String(widget.height).indexOf('%')!=-1){
      //   const state = store.getState(); // 현재 상태를 가져옵니다.
      //   const {broadCast} = state; // 편의상 비구조화 할당
      //   result['height'] = (broadCast.size.height/100*Number(widget.height.substring(0,(widget.height).indexOf('%'))))+'px'
      // } 
      // else if (widget.result=='auto'){
      //   result['height'] = 'auto'
      // }else {
      //   result['height'] = widget.height +'px'
      // }
    }
  
    // console.log(result,'result')
    return result
  }
  
  
  export function minSize ({widget,width='200px',height='200px'}) {
    let keys = Object.keys(widget)
    let result = {
      width,
      height,
    }
  
    if(keys.includes('width')){
      if (widget.width=='auto'){
        result['minWidth'] = 'auto'
      }else {
        result['minWidth'] = widget.width +'px'
      }
    }
  
    if(keys.includes('height')){
      if (widget.result=='auto'){
        result['minHeight'] = 'auto'
      }else {
        result['minHeight'] = widget.height +'px'
      }
    }
    return result
  }
  
  
  export function maxSize ({widget,width='200px',height='200px'}) {
    let keys = Object.keys(widget)
    let result = {
      width,
      height,
    }
  
    if(keys.includes('width')){
      if (widget.width=='auto'){
        result['maxWidth'] = 'auto'
      }else {
        result['maxWidth'] = widget.width +'px'
      }
    }
  
    if(keys.includes('height')){
      if (widget.result=='auto'){
        result['maxHeight'] = 'auto'
      }else {
        result['maxHeight'] = widget.height +'px'
      }
    }
    return result
  }
  
  
  ////////////////////////////////////////////////////////
  // 속성 객체
  ////////////////////////////////////////////////////////
  export function shadowOption ({strong,color='#000000',option="soft"}) {

    // if(!strong){return ''}  
    // let res = ''
    // let opacity = Math.round(strong/10 * 255).toString(16)

    function hexToRgba(hex, alpha) {
      // Hex 코드가 #으로 시작하면 제거합니다.
      hex = hex.replace(/^#/, '');
    
      // 짧은 형태의 Hex 코드(3글자)인 경우, 각 글자를 두 번 반복하여 6글자 형태로 변환합니다.
      if (hex.length === 3) {
        hex = hex.split('').map(char => char + char).join('');
      }
    
      // Hex 코드를 RGB 값으로 변환합니다.
      const bigint = parseInt(hex, 16);
      const r = (bigint >> 16) & 255;
      const g = (bigint >> 8) & 255;
      const b = bigint & 255;
    
      // 투명도를 0과 1 사이로 제한합니다.
      alpha = Math.max(0, Math.min(1, alpha));
    
      // RGBA 문자열을 반환합니다.
      return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }
    
   const val = strong*0.05

    
    if(option='soft'){
      return(
        `drop-shadow(0 2px 4px ${hexToRgba(color,val)}) drop-shadow(0 6px 8px ${hexToRgba(color,val)}) drop-shadow(0 12px 16px rgba(73, 25, 25, 0.1)) drop-shadow(0 18px 24px ${hexToRgba(color,val)}) `
      )
    }



    // switch (option) {
    //   case 'LeftTop':
    //     res = 'drop-shadow(-5px -5px 5px '+color+opacity+') drop-shadow(-35px -35px 25px '+color+'08'+') '
    //     break;
    //   case 'LeftBottom':
    //     res = 'drop-shadow(-5px 5px 5px '+color+opacity+') drop-shadow(-35px 35px 25px '+color+'08'+') '
    //     break;
    //   case 'RightTop':
    //     res = 'drop-shadow(5px -5px 5px '+color+opacity+') drop-shadow(35px -35px 25px '+color+'08'+') '
    //     break;
    //   case 'RightBottom':
    //     res = 'drop-shadow(5px 5px 5px '+color+opacity+') drop-shadow(35px 35px 25px '+color+'08'+') '
    //     break;
    //   default:
    //     break;
    // }
    // return res
  }
  
  
  
  // 필터
  export function filter ({widget}) {
  
    let keys = Object.keys(widget)
    let result =''
  
    // 밝기
    if(keys.includes('brightness')){
      result += 'brightness('+widget['brightness']+'%) '
    }
    if(keys.includes('contrast')){
      result += 'contrast('+widget['contrast']+'%) '
    }
  
    //  그림자 
  
    let shadowColor = null
    let shadowValue = null
    let shadowDirection = null
    let shadow = null
    if(keys.includes('shadowColor')){
      shadowColor = widget['shadowColor']
    }
    if(keys.includes('shadowValue')){
      shadowValue = Number(widget['shadowValue'])
    }
    // if(keys.includes('shadowDirection')){
    //   shadowDirection = widget['shadowDirection']
    // }
    if(shadowValue!=0){
      result += shadowOption({strong : shadowValue, color : shadowColor})
    }

    //  투명도 
    if(keys.includes('opacity')){
      let opacityValue = widget['opacity']
      if (opacityValue>=0){
        result += 'opacity('+opacityValue+'%) '
      }
    }
  
    // 필터
    if(keys.includes('blur')){
      result += 'blur('+widget['blur']+'px) '
    }
    if(keys.includes('grayscale')){
      result += 'grayscale('+widget['grayscale']+'%) '
    }
    if(keys.includes('hueRotate')){
      result += 'hue-rotate('+widget['hueRotate']+'deg) '
    }
    if(keys.includes('invert')){
      result += 'invert('+widget['invert']+'%) '
    }
    if(keys.includes('saturate')){
      result += 'saturate('+widget['saturate']+'%) '
    }
    if(keys.includes('sepia')){
      result += 'sepia('+widget['sepia']+'%) '
    }
    
    return {filter : result}
  }
  
  export function shortFilter ({widget,target=null}) {
    let keys = Object.keys(widget)
    let result =''


    // 밝기
    if(keys.includes('brightness')){
      result += 'brightness('+widget['brightness']+'%) '
    }
    if(keys.includes('contrast')){
      result += 'contrast('+widget['contrast']+'%) '
    }
    // 필터
    if(keys.includes('blur')){
      result += 'blur('+widget.blur+'px) '
    }
    if(keys.includes('grayscale')){
      result += 'grayscale('+widget.grayscale+'%) '
    }
    if(keys.includes('hueRotate')){
      result += 'hue-rotate('+widget.hueRotate+'deg) '
    }
    if(keys.includes('invert')){
      result += 'invert('+widget.invert+'%) '
    }
    if(keys.includes('saturate')){
      result += 'saturate('+widget.saturate+'%) '
    }
    if(keys.includes('sepia')){
      result += 'sepia('+widget.sepia+'%) '
    }
    return {filter : result}
  }

  // 컬러
  export function fill ({widget,target=null,key='background'}) {
    if (target){
      target =target+'_'
    }else{
      target = ''
    }
    let keys = Object.keys(widget)
    let result = {}
  
    if(keys.includes(target+'fill')){
      if(widget[target+'fill']){
        if (typeof(widget[target+'fill'])=='object'){
  
          let fillKeys = Object.keys(widget[target+'fill'])
          switch (widget[target+'fill'].type) {
            case 'monochrome':
              if(keys.includes(target+'fillOpacity')){
  
                let opacity= Math.round(target+'fillOpacity'/100 * 255).toString(16)
                if (opacity.length==1){ opacity= 0+opacity }
  
                result[key] = widget[target+'fill'].value+opacity
  
              }else{
                result[key] = widget[target+'fill'].value
              }
              break;
            case 'gradation':
              result[key] = widget[target+'fill'].value
              break;
            case 'image':
              result[key] = widget[target+'fill'].value
              break;
            default:
            console.error('존재하지 않는 fill 옵션',widget[target+'fill'].type)
              break;
          }
        } else {
          if(keys.includes(target+'fillOpacity')){
            let opacity= Math.round(widget[target+'fillOpacity']/100 * 255).toString(16)
            if (opacity.length==1){ opacity= 0+opacity }
            result[key] = widget[target+'fill']+opacity
          }else{
            result[key] = widget[target+'fill']
          }
        }
      }
    }
    return result
  }
  // 모션
  export function transform ({widget,target=null}) {
    if (target){
      target =target+'_'
    }else{
      target = ''
    }
    let keys = Object.keys(widget)
    let result =''
  
    // 필터
    if(keys.includes(target+'rotateZ')){
      result += 'rotateZ('+widget[target+'rotateZ']+'deg) '
    }
    if(keys.includes(target+'rotateY')){
      result += 'rotateY('+widget[target+'rotateY']+'deg) '
    }
    if(keys.includes(target+'rotateX')){
      result += 'rotateX('+widget[target+'rotateX']+'deg) '
    }
    if(keys.includes(target+'scale')){
      result += 'scale('+widget[target+'scale']/100+','+widget[target+'scale']/100+') '
    }
    return {transform : result}
  }
  
  // 외곽선
  export function border ({widget}) {
    let keys = Object.keys(widget)
    let result = {
    }
    if(keys.includes('borderColor')){
      result['borderColor'] = widget.borderColor
    }
    if(keys.includes('borderStyle')){
      result['borderStyle'] = widget.borderStyle
    }
    if(keys.includes('borderRadius')){
      result['borderRadius'] = widget.borderRadius +'px'
    }
    if(keys.includes('borderWidth')){
      result['borderWidth'] = widget.borderWidth +'px'
    }
    return result
  }
  
  // 외곽선
  export function stroke ({widget,target=null}) {
    if (target){
      target =target+'_'
    }else{
      target = ''
    }
    let keys = Object.keys(widget)
    let result = {
    }
    if(keys.includes(target+'strokeColor')){
      result['stroke'] = widget[target+'strokeColor']
    }
    if(keys.includes(target+'strokeWidth')){
      result['strokeWidth'] = widget[target+'strokeWidth']
    }
    return result
  }
  
  
  
  // 모핑
  export function morphing ({widget}) {
    let result = {}
    if(Object.keys(widget).includes('morphing')&&widget.morphing==true){
  
      if(Object.keys(widget).includes('morphingSpped')){
        if (widget) {
          result = {transition : 'all '+widget.morphingSpped+'s'}
          return result
        }
      }
      else {
        result = {transition : 'all 1s'}
        return result
      }
    }
    return result
  }
  
  
  
// 폰트
/**
 * 위젯 객체의 폰트 옵션을 찾고
 * 대상 css : color,fontSize,fontWeight,fontFamily,textAlign,verticalAlign,textOutline,outlineColor,textShadowColor,textShadowValue
 * @param {} widget
 * @param {} target : 적용될 대상 엘리먼트
 * @returns 
 */
  export function font ({widget,target}) {
    if (target){
      target =target+'_'
    }else{
      target = ''
    }
    let keys = Object.keys(widget)
    let result = {
   
    }
    let outline = ''
    let shadow = ''
    let rest = ''
    let textShadowColor = '#000000'
  
    if(keys.includes(target+'color')){
      result['color'] = widget[target+'color']
  
    }
    if(keys.includes(target+'fontSize')){
      result['fontSize'] = widget[target+'fontSize']+'px'
    }
    if(keys.includes(target+'fontWeight')){
      result['fontWeight'] = widget[target+'fontWeight']
    }
    if(keys.includes(target+'fontFamily')){
      result['fontFamily'] = widget[target+'fontFamily']
    }
    if(keys.includes(target+'textAlign')){
      result['textAlign'] = widget[target+'textAlign']
    }
    if(keys.includes(target+'lineHeight')){
      result['lineHeight'] = widget[target+'lineHeight']
    }
    if(keys.includes(target+'verticalAlign')){
      result['textVerticalAlign'] = widget[target+'verticalAlign']
    }
    if(keys.includes(target+'textOutline')&&keys.includes(target+'outlineColor')){
      if (widget[target+'textOutline']>0){
        result['-webkit-text-stroke'] = widget[target+'textOutline']/100 +'px '+widget[target+'outlineColor']
        // let textOutline = widget[target+'textOutline']/100
        // outline = '-'+widget[target+'fontSize']/25*textOutline+'px 0 '+widget[target+'outlineColor']+', 0 '+widget[target+'fontSize']/25*textOutline+'px '+widget[target+'outlineColor']+', '+widget[target+'fontSize']/25*textOutline+'px 0 '+widget[target+'outlineColor']+', 0 -'+widget[target+'fontSize']/25*textOutline+'px '+widget[target+'outlineColor'] 
      }
    }
    if(keys.includes(target+'textShadowColor')){
      textShadowColor = widget[target+'textShadowColor']
    }
    if(keys.includes(target+'textShadowValue')){
      if(outline!=''){rest =' , '}
     let textShadowValue = widget[target+'textShadowValue']
      if (textShadowValue>0){
        shadow = textShadowColor+'0d 0px '+textShadowValue*7+'px '+textShadowValue*5+'px, '+textShadowColor+'4d 0px '+textShadowValue+'px '+textShadowValue+'px' 
      }
    }
  
  
  
    result['textShadow'] = outline + rest + shadow
    return result
  }
  
  
  // 오브젝트 핏 
  export function objectFit ({widget,target=null}) {
    if (target){
      target =target+'_'
    }else{
      target = ''
    }
    let keys = Object.keys(widget)
    let result = {
    }
    if(keys.includes(target+'objectFit')){
      result['objectFit'] = widget[target+'objectFit']
    }
    return result
  }
  
  // 오브젝트 핏 
  export function clipPath ({widget,target=null}) {
    if (target){
      target =target+'_'
    }else{
      target = ''
    }
    let keys = Object.keys(widget)
    let result = {
    }
    if(keys.includes(target+'clipNode')){
      let nodeList = widget[target+'clipNode']
      if (nodeList.length<=2){
        return result
      }
      let clipPath = 'polygon('
      for (let index = 0; index < nodeList.length; index++) {
        if (nodeList[index][0]!=null&&nodeList[index][1]!=null){
          clipPath = clipPath + (nodeList[index][0]/100).toFixed(2)+'% '+(nodeList[index][1]/100).toFixed(2)+'%, '
        }
      }
      clipPath = clipPath.slice(0, - 2);
      clipPath = clipPath+ ')'
  
      result['clipPath'] = clipPath
    }
    return result
  }
  
////////////////////////////////////////////////////////
// 스텐다드 객체
////////////////////////////////////////////////////////

export function standardObject () {
  return {LAYOUT : {},OBJECT : {}}
}

export const filterDefault ={
  opacity: 100,
  brightness : 100,
  contrast: 100,
  blur:0,
  grayscale:0,
  hueRotate:0,
  invert:0,
  saturate:100,
  sepia:0,
}

export const transformDefault ={
  rotateZ:0,
  rotateY:0,
  rotateX:0,
  scale:100,
}

