// 리엑트 연결

import { widget } from "service/value/Model";

// 서비스 연결
import * as Utility from 'service/other/Utility';
import * as Model from 'service/value/Model' ;  
////////////////////////////////////////////////////////
// store 연결
////////////////////////////////////////////////////////
import store from "store";

////////////////////////////////////////////////////////

// 테스트 구문
export function connectTest() {
    console.log('Eecoder : 연결테스트')
  }


/**
 * 오버랩 객체를 템플릿 객체로 변경합니다.
 * @param {} overlap 
 */
export function convert(overlap) {

    console.log('컨버트 대상',overlap);
    overlap = Utility.deepCopy(overlap)


    const Pages =  overlap.content.page;

    const UUIDlog =  {}


    Pages.forEach((page) => {
        const page_uuid = Utility.uuidMake();
        UUIDlog[page.uuid] = page_uuid
        delete page.overlap_id;
        delete page.overlap_id;
        delete page.created_at;
        delete page.updated_at;
        delete page.id;
        page['uuid'] = page_uuid
      });

    const Order = overlap.content.order.map(key => UUIDlog[key]);


    const template = {
        title : overlap?.title?? '새 템플릿', // 템플릿의 제목입니다.
        summary :overlap?.summary?? '', // 템플릿에 대한 설명입니다.
        meta : {
            created_by : null, // 서버사이드에서 생성됩니다.
            private : 0,
            resolution : overlap.meta.resolution,
        },
        content : {
            order : Order,
            page : Pages,
            asset : overlap.content.asset
        },
        global:overlap.global
    }
    console.log('로그',template);
    return Utility.deepCopy(template);
}


// {
//     "title": "테스트 오버랩",
//     "summary": "",
//     "uuid": "f96322fc-bcca-11ed-8b4a-0200170218e7",
//     "meta": {
//         "created_at": "2023-03-07T09:32:27.000Z",
//         "updated_at": "2024-04-02T07:50:26.000Z",
//         "delete_at": null,
//         "delete": 0,
//         "space_id": 2,
//         "creator_id": 37,
//         "private": 0,
//         "tag": [],
//         "default_channel": null,
//         "resolution": {
//             "width": 1920,
//             "height": 1080
//         }
//     },
//     "content": {
//         "order": [
//             "2080c9dd0c00f16a5748656fcc5fd26b",
//             "787ba698f428afbf35a5fbf060fdd356",
//             "2595db61b8db21439d4aa4289bb7c572",
//             "31204cab661ff18f94ce2afe53a492cf",
//             "571a68d68df79ecf9fb1add6f4771955",
//             "3bfc77aa6dd9fedd813a0714eb566d34",
//             "765a9196d35eec38326266ac9f00bb32",
//             "fbd9e9c651cb1a701c6d91a5b6e05928",
//             "69ac875d06fb3e424e497da5096a78fb",
//             "5a5e2db23f067dd68bb4d20f185638a4",
//             "6c8b7c1b75b247959b2010b8a0fa32ee",
//             "0773d68ae6d73ce51ab9b0d5fbacf1e5",
//             "c742575abf0535c61358494245a8db22",
//             "d9a250e33c71bf829a19b8f369a8a5a9",
//             "619e5eb9db4f1585ead3670537636b13",
//             "b16c7cadfc12c9842008045e8d51571c",
//             "d204ff30101c11072a46bc9e018b1027",
//             "80baf619b81c01838fa7edb647d29269",
//             "c1b7d759a742fb6e038244406eca2f62",
//             "9ae1c714cc1de59f3046bcb44e43740c",
//             "5e8a9290e92cc766c70c85bdda9a8129",
//             "3fcd59a2d6304205056145a8f6986cb6"
//         ],
//         "page": [
//             {
//                 "id": 523,
//                 "created_at": "2023-12-08T10:24:59.000Z",
//                 "updated_at": "2024-04-02T07:35:11.000Z",
//                 "overlap_id": 1,
//                 "page_title": "",
//                 "page_summary": "",
//                 "page_widget": [
//                     {
//                         "data": {
//                             "meta": {},
//                             "size": {
//                                 "width": 1920,
//                                 "height": 108
//                             },
//                             "style": {
//                                 "opacity": 33,
//                                 "infoFont": "나눔스퀘어",
//                                 "borderRadius": 54
//                             },
//                             "value": {
//                                 "option": {},
//                                 "teamData": [
//                                     {
//                                         "id": "c026d00fc3bd382d556f6d7f0c705c8c",
//                                         "chosen": false
//                                     },
//                                     {
//                                         "id": "2d1fd92cd9cba3bf608a2ace4cd6884a",
//                                         "chosen": false
//                                     }
//                                 ]
//                             },
//                             "preset": "default",
//                             "display": {},
//                             "position": {
//                                 "x": "0",
//                                 "y": "0"
//                             }
//                         },
//                         "type": "Scoreboard",
//                         "uuid": "620cb9709bbb754f64c3dbcb69e12ec4",
//                         "alias": "",
//                         "chosen": false,
//                         "version": 1,
//                         "selected": false,
//                         "animation": {
//                             "mount": null,
//                             "mountEnd": null,
//                             "mountStart": null
//                         }
//                     },
//                     {
//                         "data": {
//                             "meta": {},
//                             "size": {},
//                             "style": {},
//                             "value": {
//                                 "option": {}
//                             },
//                             "display": {},
//                             "position": {
//                                 "x": "20",
//                                 "y": "173"
//                             }
//                         },
//                         "type": "Clock",
//                         "uuid": "ba001e98c5de71b43e5b12adba14d8a6",
//                         "alias": null,
//                         "chosen": false,
//                         "version": 1,
//                         "selected": false,
//                         "animation": {
//                             "mount": null,
//                             "mountEnd": null,
//                             "mountStart": null
//                         }
//                     },
//                     {
//                         "data": {
//                             "meta": {},
//                             "size": {
//                                 "width": "1413",
//                                 "height": "449"
//                             },
//                             "style": {
//                                 "fontFamily": "을지로10년후체"
//                             },
//                             "value": {
//                                 "time": 60,
//                                 "option": {},
//                                 "timerType": "end"
//                             },
//                             "display": {},
//                             "position": {
//                                 "x": "225",
//                                 "y": "250"
//                             }
//                         },
//                         "type": "Timer",
//                         "uuid": "3f30dc4dd83cbb96d05c250430668ce4",
//                         "alias": null,
//                         "chosen": false,
//                         "version": 1,
//                         "selected": false,
//                         "animation": {
//                             "mount": null,
//                             "mountEnd": null,
//                             "mountStart": null
//                         }
//                     },
//                     {
//                         "data": {
//                             "meta": {},
//                             "size": {},
//                             "style": {},
//                             "value": {
//                                 "option": {}
//                             },
//                             "display": {},
//                             "position": {
//                                 "x": "419",
//                                 "y": "29"
//                             }
//                         },
//                         "type": "Text",
//                         "uuid": "708b13799a8e641ea61add3dc6640ca4",
//                         "alias": null,
//                         "chosen": false,
//                         "version": 1,
//                         "selected": false,
//                         "animation": {
//                             "mount": null,
//                             "mountEnd": null,
//                             "mountStart": null
//                         }
//                     },
//                     {
//                         "data": {
//                             "meta": {},
//                             "size": {},
//                             "style": {},
//                             "value": {
//                                 "option": {
//                                     "positionX": 0,
//                                     "positionY": 730
//                                 },
//                                 "banPickRule": [
//                                     {
//                                         "team": "left",
//                                         "type": "ban",
//                                         "timer": "30",
//                                         "chosen": false,
//                                         "selected": false
//                                     },
//                                     {
//                                         "team": "right",
//                                         "type": "ban",
//                                         "timer": "30",
//                                         "chosen": false,
//                                         "selected": false
//                                     },
//                                     {
//                                         "team": "left",
//                                         "type": "ban",
//                                         "timer": "30",
//                                         "chosen": false,
//                                         "selected": false
//                                     },
//                                     {
//                                         "team": "right",
//                                         "type": "ban",
//                                         "timer": "30",
//                                         "chosen": false,
//                                         "selected": false
//                                     },
//                                     {
//                                         "team": "left",
//                                         "type": "ban",
//                                         "timer": "30",
//                                         "chosen": false,
//                                         "selected": false
//                                     },
//                                     {
//                                         "team": "right",
//                                         "type": "ban",
//                                         "timer": "30",
//                                         "chosen": false,
//                                         "selected": false
//                                     },
//                                     {
//                                         "team": "left",
//                                         "type": "pick",
//                                         "timer": "30",
//                                         "chosen": false,
//                                         "selected": false
//                                     },
//                                     {
//                                         "team": "right",
//                                         "type": "pick",
//                                         "timer": "30",
//                                         "chosen": false,
//                                         "selected": false
//                                     },
//                                     {
//                                         "team": "right",
//                                         "type": "pick",
//                                         "timer": "30",
//                                         "chosen": false,
//                                         "selected": false
//                                     },
//                                     {
//                                         "team": "left",
//                                         "type": "pick",
//                                         "timer": "30",
//                                         "chosen": false,
//                                         "selected": false
//                                     },
//                                     {
//                                         "team": "left",
//                                         "type": "pick",
//                                         "timer": "30",
//                                         "chosen": false,
//                                         "selected": false
//                                     },
//                                     {
//                                         "team": "right",
//                                         "type": "pick",
//                                         "timer": "30",
//                                         "chosen": false,
//                                         "selected": false
//                                     },
//                                     {
//                                         "team": "left",
//                                         "type": "ban",
//                                         "timer": "30",
//                                         "chosen": false,
//                                         "selected": false
//                                     },
//                                     {
//                                         "team": "right",
//                                         "type": "ban",
//                                         "timer": "30",
//                                         "chosen": false,
//                                         "selected": false
//                                     },
//                                     {
//                                         "team": "left",
//                                         "type": "ban",
//                                         "timer": "30",
//                                         "chosen": false,
//                                         "selected": false
//                                     },
//                                     {
//                                         "team": "right",
//                                         "type": "ban",
//                                         "timer": "30",
//                                         "chosen": false,
//                                         "selected": false
//                                     },
//                                     {
//                                         "team": "right",
//                                         "type": "pick",
//                                         "timer": "30",
//                                         "chosen": false,
//                                         "selected": false
//                                     },
//                                     {
//                                         "team": "left",
//                                         "type": "pick",
//                                         "timer": "30",
//                                         "chosen": false,
//                                         "selected": false
//                                     },
//                                     {
//                                         "team": "left",
//                                         "type": "pick",
//                                         "timer": "30",
//                                         "chosen": false,
//                                         "selected": false
//                                     },
//                                     {
//                                         "team": "right",
//                                         "type": "pick",
//                                         "timer": "30",
//                                         "chosen": false,
//                                         "selected": false
//                                     }
//                                 ]
//                             },
//                             "display": {},
//                             "position": {
//                                 "x": "-2",
//                                 "y": "734"
//                             }
//                         },
//                         "type": "BanPick",
//                         "uuid": "161686b8bdf10c95c54b1c812256bdf4",
//                         "alias": null,
//                         "chosen": false,
//                         "version": 1,
//                         "selected": false,
//                         "animation": {
//                             "mount": null,
//                             "mountEnd": null,
//                             "mountStart": null
//                         }
//                     }
//                 ],
//                 "page_option": [],
//                 "uuid": "2080c9dd0c00f16a5748656fcc5fd26b",
//                 "deleted_at": null,
//                 "deleted": 0
//             },
//             {
//                 "id": 531,
//                 "created_at": "2023-12-18T07:23:09.000Z",
//                 "updated_at": "2024-03-23T16:48:46.000Z",
//                 "overlap_id": 1,
//                 "page_title": "",
//                 "page_summary": "",
//                 "page_widget": [
//                     {
//                         "data": {
//                             "meta": {},
//                             "size": {
//                                 "width": "700",
//                                 "height": "387"
//                             },
//                             "style": {
//                                 "textAlign": "center",
//                                 "fontFamily": "한나체"
//                             },
//                             "value": {
//                                 "text": "addada"
//                             },
//                             "preset": null,
//                             "display": {},
//                             "position": {
//                                 "x": "295",
//                                 "y": "337",
//                                 "show": null
//                             }
//                         },
//                         "type": "Text",
//                         "uuid": "c2123a59f7cf83d4ca171cc301d47bce",
//                         "alias": "ㄴㄴㄴ",
//                         "chosen": false,
//                         "version": 1,
//                         "selected": false,
//                         "animation": {
//                             "mount": null,
//                             "mountEnd": null,
//                             "mountStart": null
//                         }
//                     },
//                     {
//                         "data": {
//                             "meta": {},
//                             "size": {},
//                             "style": {
//                                 "fontSize": "51",
//                                 "textAlign": "left",
//                                 "fontFamily": "프리텐다드SemiBold"
//                             },
//                             "value": {},
//                             "preset": null,
//                             "display": {},
//                             "position": {
//                                 "x": "364",
//                                 "y": "589",
//                                 "show": null
//                             }
//                         },
//                         "type": "Text",
//                         "uuid": "4e894d308c40cf4c1f3b3e5fab8e9044",
//                         "alias": null,
//                         "chosen": false,
//                         "version": 1,
//                         "selected": false,
//                         "animation": {
//                             "mount": null,
//                             "mountEnd": null,
//                             "mountStart": null
//                         }
//                     }
//                 ],
//                 "page_option": [],
//                 "uuid": "787ba698f428afbf35a5fbf060fdd356",
//                 "deleted_at": null,
//                 "deleted": 0
//             },
//             {
//                 "id": 533,
//                 "created_at": "2023-12-19T05:01:19.000Z",
//                 "updated_at": "2023-12-19T05:01:19.000Z",
//                 "overlap_id": 1,
//                 "page_title": "",
//                 "page_summary": "",
//                 "page_widget": [],
//                 "page_option": [],
//                 "uuid": "5a5e2db23f067dd68bb4d20f185638a4",
//                 "deleted_at": null,
//                 "deleted": 0
//             },
//             {
//                 "id": 534,
//                 "created_at": "2023-12-19T05:01:20.000Z",
//                 "updated_at": "2023-12-19T05:01:20.000Z",
//                 "overlap_id": 1,
//                 "page_title": "",
//                 "page_summary": "",
//                 "page_widget": [],
//                 "page_option": [],
//                 "uuid": "6c8b7c1b75b247959b2010b8a0fa32ee",
//                 "deleted_at": null,
//                 "deleted": 0
//             },
//             {
//                 "id": 535,
//                 "created_at": "2023-12-19T05:01:20.000Z",
//                 "updated_at": "2024-03-31T04:17:30.000Z",
//                 "overlap_id": 1,
//                 "page_title": "",
//                 "page_summary": "",
//                 "page_widget": [
//                     {
//                         "data": {
//                             "meta": {},
//                             "size": {},
//                             "style": {},
//                             "value": {
//                                 "option": {},
//                                 "startTime": 0,
//                                 "mediaSource": "ED.getValue(target,mediaSource)"
//                             },
//                             "preset": null,
//                             "display": {},
//                             "position": {
//                                 "show": false
//                             }
//                         },
//                         "type": "Video",
//                         "uuid": "6fd8fd0de8db674be0c0f7b5a31b8306",
//                         "alias": null,
//                         "chosen": false,
//                         "version": 1,
//                         "selected": false,
//                         "animation": {
//                             "mount": null,
//                             "mountEnd": null,
//                             "mountStart": null
//                         }
//                     }
//                 ],
//                 "page_option": [],
//                 "uuid": "0773d68ae6d73ce51ab9b0d5fbacf1e5",
//                 "deleted_at": null,
//                 "deleted": 0
//             },
//             {
//                 "id": 536,
//                 "created_at": "2023-12-19T05:01:21.000Z",
//                 "updated_at": "2024-02-11T13:06:07.000Z",
//                 "overlap_id": 1,
//                 "page_title": "",
//                 "page_summary": "",
//                 "page_widget": [
//                     {
//                         "data": {
//                             "meta": {},
//                             "size": {},
//                             "style": {},
//                             "value": {
//                                 "option": {
//                                     "preset": "quadrangle"
//                                 }
//                             },
//                             "preset": "quadrangle",
//                             "display": {},
//                             "position": {
//                                 "x": "515",
//                                 "y": "88",
//                                 "show": false
//                             }
//                         },
//                         "type": "Shape",
//                         "uuid": "ad956842ff4155e887c58b97e9faebe5",
//                         "alias": null,
//                         "chosen": false,
//                         "version": 1,
//                         "selected": false,
//                         "animation": {
//                             "mount": null,
//                             "mountEnd": null,
//                             "mountStart": null
//                         }
//                     },
//                     {
//                         "data": {
//                             "meta": {},
//                             "size": {},
//                             "style": {},
//                             "value": {
//                                 "option": {
//                                     "preset": "circle"
//                                 }
//                             },
//                             "preset": "circle",
//                             "display": {},
//                             "position": {
//                                 "x": "20",
//                                 "y": "535",
//                                 "show": false
//                             }
//                         },
//                         "type": "Shape",
//                         "uuid": "cac856dc8a8fcde28920fb12b68abf3a",
//                         "alias": null,
//                         "chosen": false,
//                         "version": 1,
//                         "selected": false,
//                         "animation": {
//                             "mount": null,
//                             "mountEnd": null,
//                             "mountStart": null
//                         }
//                     },
//                     {
//                         "data": {
//                             "meta": {},
//                             "size": {},
//                             "style": {},
//                             "value": {
//                                 "option": {
//                                     "preset": "circle"
//                                 }
//                             },
//                             "preset": "circle",
//                             "display": {},
//                             "position": {
//                                 "x": "410",
//                                 "y": "474",
//                                 "show": false
//                             }
//                         },
//                         "type": "Shape",
//                         "uuid": "51336ce259c3d822cd8f02e5d0143d97",
//                         "alias": null,
//                         "chosen": false,
//                         "version": 1,
//                         "selected": false,
//                         "animation": {
//                             "mount": null,
//                             "mountEnd": null,
//                             "mountStart": null
//                         }
//                     },
//                     {
//                         "data": {
//                             "meta": {},
//                             "size": {
//                                 "width": "200",
//                                 "height": "200"
//                             },
//                             "style": {},
//                             "value": {
//                                 "option": {
//                                     "preset": "hexagon"
//                                 }
//                             },
//                             "preset": "hexagon",
//                             "display": {},
//                             "position": {
//                                 "x": "90",
//                                 "y": "276",
//                                 "show": false
//                             }
//                         },
//                         "type": "Shape",
//                         "uuid": "c767b111ab52f5b0edbbc76995dcfdf2",
//                         "alias": null,
//                         "chosen": false,
//                         "version": 1,
//                         "selected": false,
//                         "animation": {
//                             "mount": null,
//                             "mountEnd": null,
//                             "mountStart": null
//                         }
//                     },
//                     {
//                         "data": {
//                             "meta": {},
//                             "size": {},
//                             "style": {},
//                             "value": {
//                                 "option": {
//                                     "preset": "quadrangle"
//                                 }
//                             },
//                             "preset": "quadrangle",
//                             "display": {},
//                             "position": {
//                                 "x": "1533",
//                                 "y": "807",
//                                 "show": false
//                             }
//                         },
//                         "type": "Shape",
//                         "uuid": "6ec0f5ce0b7d5076515750195f0e5c66",
//                         "alias": null,
//                         "chosen": false,
//                         "version": 1,
//                         "selected": false,
//                         "animation": {
//                             "mount": null,
//                             "mountEnd": null,
//                             "mountStart": null
//                         }
//                     },
//                     {
//                         "data": {
//                             "meta": {},
//                             "size": {},
//                             "style": {},
//                             "value": {
//                                 "option": {
//                                     "preset": "triangle"
//                                 }
//                             },
//                             "preset": "triangle",
//                             "display": {},
//                             "position": {
//                                 "x": "1705",
//                                 "y": "856",
//                                 "show": false
//                             }
//                         },
//                         "type": "Shape",
//                         "uuid": "377bae7618cc0f68580ddcbba92893ac",
//                         "alias": null,
//                         "chosen": false,
//                         "version": 1,
//                         "selected": false,
//                         "animation": {
//                             "mount": null,
//                             "mountEnd": null,
//                             "mountStart": null
//                         }
//                     }
//                 ],
//                 "page_option": [],
//                 "uuid": "c742575abf0535c61358494245a8db22",
//                 "deleted_at": null,
//                 "deleted": 0
//             },
//             {
//                 "id": 537,
//                 "created_at": "2023-12-19T05:01:21.000Z",
//                 "updated_at": "2023-12-19T05:01:21.000Z",
//                 "overlap_id": 1,
//                 "page_title": "",
//                 "page_summary": "",
//                 "page_widget": [],
//                 "page_option": [],
//                 "uuid": "69ac875d06fb3e424e497da5096a78fb",
//                 "deleted_at": null,
//                 "deleted": 0
//             },
//             {
//                 "id": 538,
//                 "created_at": "2023-12-24T07:48:52.000Z",
//                 "updated_at": "2024-02-28T16:53:00.000Z",
//                 "overlap_id": 1,
//                 "page_title": "",
//                 "page_summary": "",
//                 "page_widget": [
//                     {
//                         "data": {
//                             "meta": {},
//                             "size": {
//                                 "width": "751",
//                                 "height": "318"
//                             },
//                             "style": {},
//                             "value": {},
//                             "preset": null,
//                             "display": {},
//                             "position": {
//                                 "x": "1135",
//                                 "y": "30",
//                                 "show": false
//                             }
//                         },
//                         "type": "Notice",
//                         "uuid": "d69a9769cc8221c0274f6225d78e8dbc",
//                         "alias": null,
//                         "chosen": false,
//                         "version": 1,
//                         "selected": false,
//                         "animation": {
//                             "mount": null,
//                             "mountEnd": null,
//                             "mountStart": null
//                         }
//                     },
//                     {
//                         "data": {
//                             "meta": {},
//                             "size": {},
//                             "style": {},
//                             "value": {},
//                             "preset": null,
//                             "display": {},
//                             "position": {
//                                 "x": "649",
//                                 "y": "-19",
//                                 "show": false
//                             }
//                         },
//                         "type": "Clock",
//                         "uuid": "25b2351ebe8674e65a7bee16a3766bd9",
//                         "alias": null,
//                         "chosen": false,
//                         "version": 1,
//                         "selected": false,
//                         "animation": {
//                             "mount": null,
//                             "mountEnd": null,
//                             "mountStart": null
//                         }
//                     },
//                     {
//                         "data": {
//                             "meta": {},
//                             "size": {},
//                             "style": {},
//                             "value": {
//                                 "text": "addada"
//                             },
//                             "preset": null,
//                             "display": {},
//                             "position": {
//                                 "x": "56",
//                                 "y": "501",
//                                 "show": null
//                             }
//                         },
//                         "type": "Text",
//                         "uuid": "c2123a59f7cf83d4ca171cc301d47bce",
//                         "alias": null,
//                         "chosen": false,
//                         "version": 1,
//                         "selected": false,
//                         "animation": {
//                             "mount": null,
//                             "mountEnd": null,
//                             "mountStart": null
//                         }
//                     }
//                 ],
//                 "page_option": [],
//                 "uuid": "fbd9e9c651cb1a701c6d91a5b6e05928",
//                 "deleted_at": null,
//                 "deleted": 0
//             },
//             {
//                 "id": 539,
//                 "created_at": "2023-12-26T07:50:32.000Z",
//                 "updated_at": "2024-03-27T00:36:10.000Z",
//                 "overlap_id": 1,
//                 "page_title": "",
//                 "page_summary": "",
//                 "page_widget": [
//                     {
//                         "data": {
//                             "meta": {},
//                             "size": {
//                                 "width": "751",
//                                 "height": "318"
//                             },
//                             "style": {},
//                             "value": {},
//                             "preset": null,
//                             "display": {},
//                             "position": {
//                                 "x": "373",
//                                 "y": "559",
//                                 "show": false
//                             }
//                         },
//                         "type": "Notice",
//                         "uuid": "d69a9769cc8221c0274f6225d78e8dbc",
//                         "alias": null,
//                         "chosen": false,
//                         "version": 1,
//                         "selected": false,
//                         "animation": {
//                             "mount": null,
//                             "mountEnd": null,
//                             "mountStart": null
//                         }
//                     },
//                     {
//                         "data": {
//                             "meta": {},
//                             "size": {},
//                             "style": {},
//                             "value": {},
//                             "preset": null,
//                             "display": {},
//                             "position": {
//                                 "x": "481",
//                                 "y": "346",
//                                 "show": false
//                             }
//                         },
//                         "type": "Clock",
//                         "uuid": "25b2351ebe8674e65a7bee16a3766bd9",
//                         "alias": null,
//                         "chosen": false,
//                         "version": 1,
//                         "selected": false,
//                         "animation": {
//                             "mount": null,
//                             "mountEnd": null,
//                             "mountStart": null
//                         }
//                     },
//                     {
//                         "data": {
//                             "meta": {},
//                             "size": {},
//                             "style": {},
//                             "value": {
//                                 "text": "addada"
//                             },
//                             "preset": null,
//                             "display": {},
//                             "position": {
//                                 "x": "56",
//                                 "y": "501",
//                                 "show": null
//                             }
//                         },
//                         "type": "Text",
//                         "uuid": "c2123a59f7cf83d4ca171cc301d47bce",
//                         "alias": null,
//                         "chosen": false,
//                         "version": 1,
//                         "selected": false,
//                         "animation": {
//                             "mount": null,
//                             "mountEnd": null,
//                             "mountStart": null
//                         }
//                     },
//                     {
//                         "data": {
//                             "meta": {},
//                             "size": {},
//                             "style": {},
//                             "value": {
//                                 "option": {},
//                                 "teamData": [
//                                     {
//                                         "id": "5da722e7c7ccba67495b99768bf21567"
//                                     }
//                                 ]
//                             },
//                             "preset": "overwatch_normal",
//                             "display": {},
//                             "position": {
//                                 "x": "0",
//                                 "y": "12"
//                             }
//                         },
//                         "type": "Scoreboard",
//                         "uuid": "59fbb3bb66ee03e1ae2f857a10aea419",
//                         "alias": null,
//                         "version": 1,
//                         "animation": {
//                             "mount": null,
//                             "mountEnd": null,
//                             "mountStart": null
//                         }
//                     }
//                 ],
//                 "page_option": [],
//                 "uuid": "d9a250e33c71bf829a19b8f369a8a5a9",
//                 "deleted_at": null,
//                 "deleted": 0
//             },
//             {
//                 "id": 540,
//                 "created_at": "2023-12-26T07:50:36.000Z",
//                 "updated_at": "2024-03-14T01:24:34.000Z",
//                 "overlap_id": 1,
//                 "page_title": "",
//                 "page_summary": "",
//                 "page_widget": [],
//                 "page_option": [],
//                 "uuid": "619e5eb9db4f1585ead3670537636b13",
//                 "deleted_at": null,
//                 "deleted": 0
//             },
//             {
//                 "id": 541,
//                 "created_at": "2023-12-26T07:50:37.000Z",
//                 "updated_at": "2024-04-01T12:25:20.000Z",
//                 "overlap_id": 1,
//                 "page_title": "",
//                 "page_summary": "",
//                 "page_widget": [
//                     {
//                         "data": {
//                             "meta": {},
//                             "size": {
//                                 "width": "751",
//                                 "height": "318"
//                             },
//                             "style": {},
//                             "value": {},
//                             "preset": null,
//                             "display": {},
//                             "position": {
//                                 "x": "1135",
//                                 "y": "30",
//                                 "show": false
//                             }
//                         },
//                         "type": "Notice",
//                         "uuid": "d69a9769cc8221c0274f6225d78e8dbc",
//                         "alias": null,
//                         "chosen": false,
//                         "version": 1,
//                         "selected": false,
//                         "animation": {
//                             "mount": null,
//                             "mountEnd": null,
//                             "mountStart": null
//                         }
//                     },
//                     {
//                         "data": {
//                             "meta": {},
//                             "size": {},
//                             "style": {},
//                             "value": {},
//                             "preset": null,
//                             "display": {},
//                             "position": {
//                                 "x": "649",
//                                 "y": "-19",
//                                 "show": false
//                             }
//                         },
//                         "type": "Clock",
//                         "uuid": "25b2351ebe8674e65a7bee16a3766bd9",
//                         "alias": null,
//                         "chosen": false,
//                         "version": 1,
//                         "selected": false,
//                         "animation": {
//                             "mount": null,
//                             "mountEnd": null,
//                             "mountStart": null
//                         }
//                     },
//                     {
//                         "data": {
//                             "meta": {},
//                             "size": {},
//                             "style": {},
//                             "value": {
//                                 "text": "addada"
//                             },
//                             "preset": null,
//                             "display": {},
//                             "position": {
//                                 "x": "56",
//                                 "y": "501",
//                                 "show": null
//                             }
//                         },
//                         "type": "Text",
//                         "uuid": "c2123a59f7cf83d4ca171cc301d47bce",
//                         "alias": null,
//                         "chosen": false,
//                         "version": 1,
//                         "selected": false,
//                         "animation": {
//                             "mount": null,
//                             "mountEnd": null,
//                             "mountStart": null
//                         }
//                     },
//                     {
//                         "data": {
//                             "meta": {},
//                             "size": {
//                                 "width": "953",
//                                 "height": "677"
//                             },
//                             "style": {},
//                             "value": {},
//                             "preset": null,
//                             "display": {},
//                             "position": {
//                                 "x": "77",
//                                 "y": "349",
//                                 "show": false
//                             }
//                         },
//                         "type": "Image",
//                         "uuid": "e4475c29a6c9e70f474a08863d553061",
//                         "alias": null,
//                         "chosen": false,
//                         "version": 1,
//                         "selected": false,
//                         "animation": {
//                             "mount": null,
//                             "mountEnd": null,
//                             "mountStart": null
//                         }
//                     },
//                     {
//                         "data": {
//                             "meta": {},
//                             "size": {},
//                             "style": {},
//                             "value": {
//                                 "option": {},
//                                 "teamData": [
//                                     {
//                                         "chosen": false,
//                                         "selected": false
//                                     },
//                                     {
//                                         "id": "af9866049e0fdac3050626844fb75492"
//                                     },
//                                     {
//                                         "id": "af5a018a74656205e22b71b4c8ac1245",
//                                         "chosen": false
//                                     }
//                                 ]
//                             },
//                             "display": {},
//                             "position": {
//                                 "x": "20",
//                                 "y": "20"
//                             }
//                         },
//                         "type": "Scoreboard",
//                         "uuid": "17430040fc6bc8fdef617a3481849084",
//                         "alias": null,
//                         "chosen": false,
//                         "version": 1,
//                         "selected": false,
//                         "animation": {
//                             "mount": null,
//                             "mountEnd": null,
//                             "mountStart": null
//                         }
//                     }
//                 ],
//                 "page_option": [],
//                 "uuid": "3bfc77aa6dd9fedd813a0714eb566d34",
//                 "deleted_at": null,
//                 "deleted": 0
//             },
//             {
//                 "id": 553,
//                 "created_at": "2024-02-12T07:56:53.000Z",
//                 "updated_at": "2024-02-12T07:56:53.000Z",
//                 "overlap_id": 1,
//                 "page_title": "",
//                 "page_summary": "",
//                 "page_widget": [],
//                 "page_option": [],
//                 "uuid": "b16c7cadfc12c9842008045e8d51571c",
//                 "deleted_at": null,
//                 "deleted": 0
//             },
//             {
//                 "id": 554,
//                 "created_at": "2024-02-25T14:21:48.000Z",
//                 "updated_at": "2024-03-12T16:05:34.000Z",
//                 "overlap_id": 1,
//                 "page_title": "",
//                 "page_summary": "",
//                 "page_widget": [
//                     {
//                         "data": {
//                             "meta": {},
//                             "size": {
//                                 "width": "1332",
//                                 "height": "546"
//                             },
//                             "style": {},
//                             "value": {},
//                             "preset": null,
//                             "display": {},
//                             "position": {
//                                 "x": "578",
//                                 "y": "460",
//                                 "show": false
//                             }
//                         },
//                         "type": "Notice",
//                         "uuid": "d69a9769cc8221c0274f6225d78e8dbc",
//                         "alias": null,
//                         "chosen": false,
//                         "version": 1,
//                         "selected": false,
//                         "animation": {
//                             "mount": null,
//                             "mountEnd": null,
//                             "mountStart": null
//                         }
//                     },
//                     {
//                         "data": {
//                             "meta": {},
//                             "size": {},
//                             "style": {},
//                             "value": {},
//                             "preset": null,
//                             "display": {},
//                             "position": {
//                                 "x": "649",
//                                 "y": "-19",
//                                 "show": false
//                             }
//                         },
//                         "type": "Clock",
//                         "uuid": "25b2351ebe8674e65a7bee16a3766bd9",
//                         "alias": null,
//                         "chosen": false,
//                         "version": 1,
//                         "selected": false,
//                         "animation": {
//                             "mount": null,
//                             "mountEnd": null,
//                             "mountStart": null
//                         }
//                     },
//                     {
//                         "data": {
//                             "meta": {},
//                             "size": {},
//                             "style": {},
//                             "value": {
//                                 "text": "addada"
//                             },
//                             "preset": null,
//                             "display": {},
//                             "position": {
//                                 "x": "56",
//                                 "y": "501",
//                                 "show": null
//                             }
//                         },
//                         "type": "Text",
//                         "uuid": "c2123a59f7cf83d4ca171cc301d47bce",
//                         "alias": null,
//                         "chosen": false,
//                         "version": 1,
//                         "selected": false,
//                         "animation": {
//                             "mount": null,
//                             "mountEnd": null,
//                             "mountStart": null
//                         }
//                     },
//                     {
//                         "data": {
//                             "meta": {},
//                             "size": {},
//                             "style": {},
//                             "value": {},
//                             "preset": null,
//                             "display": {},
//                             "position": {
//                                 "show": false
//                             }
//                         },
//                         "type": "Image",
//                         "uuid": "e4475c29a6c9e70f474a08863d553061",
//                         "alias": null,
//                         "version": 1,
//                         "animation": {
//                             "mount": null,
//                             "mountEnd": null,
//                             "mountStart": null
//                         }
//                     }
//                 ],
//                 "page_option": [],
//                 "uuid": "765a9196d35eec38326266ac9f00bb32",
//                 "deleted_at": null,
//                 "deleted": 0
//             },
//             {
//                 "id": 555,
//                 "created_at": "2024-02-25T14:35:37.000Z",
//                 "updated_at": "2024-03-22T07:18:34.000Z",
//                 "overlap_id": 1,
//                 "page_title": "",
//                 "page_summary": "",
//                 "page_widget": [
//                     {
//                         "data": {
//                             "meta": {},
//                             "size": {
//                                 "width": "483",
//                                 "height": "114"
//                             },
//                             "style": {
//                                 "blur": 0,
//                                 "color": "#000000",
//                                 "scale": 100,
//                                 "sepia": 0,
//                                 "invert": 0,
//                                 "opacity": 100,
//                                 "rotateX": 0,
//                                 "rotateY": 0,
//                                 "rotateZ": 0,
//                                 "contrast": 100,
//                                 "fontSize": 50,
//                                 "morphing": false,
//                                 "saturate": 100,
//                                 "grayscale": 0,
//                                 "hueRotate": 0,
//                                 "brightness": 100,
//                                 "fontFamily": "프리텐다드Black",
//                                 "shadowColor": "#000000",
//                                 "shadowValue": "0",
//                                 "shadowDirection": "RightBottom"
//                             },
//                             "value": {
//                                 "time": 60,
//                                 "option": {},
//                                 "endTime": 37214,
//                                 "timerType": "end"
//                             },
//                             "preset": "static",
//                             "display": {
//                                 "show": true
//                             },
//                             "position": {
//                                 "x": "1419",
//                                 "y": "894",
//                                 "show": true
//                             }
//                         },
//                         "type": "Timer",
//                         "uuid": "d2897980c6b5172569da6faf7b18203a",
//                         "alias": "테스트 폰트",
//                         "chosen": false,
//                         "version": 1,
//                         "selected": false,
//                         "animation": {
//                             "mount": null,
//                             "mountEnd": null,
//                             "mountStart": null
//                         }
//                     },
//                     {
//                         "data": {
//                             "meta": {},
//                             "size": {},
//                             "style": {},
//                             "value": {
//                                 "option": {},
//                                 "teamData": []
//                             },
//                             "display": {},
//                             "position": {
//                                 "x": "0",
//                                 "y": "0"
//                             }
//                         },
//                         "type": "Scoreboard",
//                         "uuid": "1f78155dab7f8309360f5d673438ac47",
//                         "alias": null,
//                         "chosen": false,
//                         "version": 1,
//                         "selected": false,
//                         "animation": {
//                             "mount": null,
//                             "mountEnd": null,
//                             "mountStart": null
//                         }
//                     },
//                     {
//                         "data": {
//                             "meta": {},
//                             "size": {
//                                 "width": "328",
//                                 "height": "283"
//                             },
//                             "style": {},
//                             "value": {
//                                 "option": {}
//                             },
//                             "display": {},
//                             "position": {
//                                 "x": "481",
//                                 "y": "495"
//                             }
//                         },
//                         "type": "Clock",
//                         "uuid": "77aaa373e27b5e14bce83b7e66b1747b",
//                         "alias": null,
//                         "chosen": false,
//                         "version": 1,
//                         "selected": false,
//                         "animation": {
//                             "mount": null,
//                             "mountEnd": null,
//                             "mountStart": null
//                         }
//                     }
//                 ],
//                 "page_option": [],
//                 "uuid": "571a68d68df79ecf9fb1add6f4771955",
//                 "deleted_at": null,
//                 "deleted": 0
//             },
//             {
//                 "id": 560,
//                 "created_at": "2024-03-02T03:23:45.000Z",
//                 "updated_at": "2024-04-02T07:39:39.000Z",
//                 "overlap_id": 1,
//                 "page_title": "안녕",
//                 "page_summary": "",
//                 "page_widget": [
//                     {
//                         "data": {
//                             "meta": {},
//                             "size": {
//                                 "width": "916",
//                                 "height": "335"
//                             },
//                             "style": {
//                                 "blur": 0,
//                                 "color": "#000000",
//                                 "scale": 100,
//                                 "sepia": 0,
//                                 "invert": 0,
//                                 "opacity": 100,
//                                 "rotateX": 0,
//                                 "rotateY": 0,
//                                 "rotateZ": 0,
//                                 "contrast": 100,
//                                 "fontSize": 50,
//                                 "morphing": false,
//                                 "saturate": 100,
//                                 "grayscale": 0,
//                                 "hueRotate": 0,
//                                 "brightness": 100,
//                                 "fontFamily": "나눔고딕",
//                                 "shadowColor": "#000000",
//                                 "shadowValue": "0",
//                                 "shadowDirection": "RightBottom"
//                             },
//                             "value": {
//                                 "time": 60,
//                                 "option": {},
//                                 "endTime": 37214,
//                                 "timerType": "start"
//                             },
//                             "preset": "static",
//                             "display": {
//                                 "show": true
//                             },
//                             "position": {
//                                 "x": "812",
//                                 "y": "239",
//                                 "show": true
//                             }
//                         },
//                         "type": "Timer",
//                         "uuid": "d2897980c6b5172569da6faf7b18203a",
//                         "alias": "테스트 폰트",
//                         "chosen": false,
//                         "version": 1,
//                         "selected": false,
//                         "animation": {
//                             "mount": null,
//                             "mountEnd": null,
//                             "mountStart": null
//                         }
//                     },
//                     {
//                         "data": {
//                             "meta": {},
//                             "size": {},
//                             "style": {},
//                             "value": {
//                                 "option": {},
//                                 "teamData": [
//                                     {
//                                         "id": "b3c1c9793e26027934ba8fe6a4cf5079",
//                                         "chosen": true
//                                     }
//                                 ]
//                             },
//                             "display": {},
//                             "position": {
//                                 "x": "20",
//                                 "y": "20"
//                             }
//                         },
//                         "type": "Scoreboard",
//                         "uuid": "ccd9beb7a2fef9348a041d44b6814ac1",
//                         "alias": null,
//                         "version": 1,
//                         "animation": {
//                             "mount": null,
//                             "mountEnd": null,
//                             "mountStart": null
//                         }
//                     },
//                     {
//                         "data": {
//                             "meta": {},
//                             "size": {},
//                             "style": {},
//                             "value": {
//                                 "option": {
//                                     "positionX": 0,
//                                     "positionY": 730
//                                 },
//                                 "teamData": [
//                                     {
//                                         "id": "c798c09421cc8763270bb54c8d968cd7"
//                                     },
//                                     {
//                                         "id": "b4e7c4a92f5c779c05afd22877864d14"
//                                     }
//                                 ],
//                                 "banPickRule": [
//                                     {
//                                         "team": "left",
//                                         "type": "ban",
//                                         "timer": "30",
//                                         "chosen": false,
//                                         "selected": false
//                                     },
//                                     {
//                                         "team": "right",
//                                         "type": "ban",
//                                         "timer": "30",
//                                         "chosen": false,
//                                         "selected": false
//                                     },
//                                     {
//                                         "team": "left",
//                                         "type": "ban",
//                                         "timer": "30",
//                                         "chosen": false,
//                                         "selected": false
//                                     },
//                                     {
//                                         "team": "right",
//                                         "type": "ban",
//                                         "timer": "30",
//                                         "chosen": false,
//                                         "selected": false
//                                     },
//                                     {
//                                         "team": "left",
//                                         "type": "ban",
//                                         "timer": "30",
//                                         "chosen": false,
//                                         "selected": false
//                                     },
//                                     {
//                                         "team": "right",
//                                         "type": "ban",
//                                         "timer": "30",
//                                         "chosen": false,
//                                         "selected": false
//                                     },
//                                     {
//                                         "team": "left",
//                                         "type": "pick",
//                                         "timer": "30",
//                                         "chosen": false,
//                                         "selected": false
//                                     },
//                                     {
//                                         "team": "right",
//                                         "type": "pick",
//                                         "timer": "30",
//                                         "chosen": false,
//                                         "selected": false
//                                     },
//                                     {
//                                         "team": "right",
//                                         "type": "pick",
//                                         "timer": "30",
//                                         "chosen": false,
//                                         "selected": false
//                                     },
//                                     {
//                                         "team": "left",
//                                         "type": "pick",
//                                         "timer": "30",
//                                         "chosen": false,
//                                         "selected": false
//                                     },
//                                     {
//                                         "team": "left",
//                                         "type": "pick",
//                                         "timer": "30",
//                                         "chosen": false,
//                                         "selected": false
//                                     },
//                                     {
//                                         "team": "right",
//                                         "type": "pick",
//                                         "timer": "30",
//                                         "chosen": false,
//                                         "selected": false
//                                     },
//                                     {
//                                         "team": "left",
//                                         "type": "ban",
//                                         "timer": "30",
//                                         "chosen": false,
//                                         "selected": false
//                                     },
//                                     {
//                                         "team": "right",
//                                         "type": "ban",
//                                         "timer": "30",
//                                         "chosen": false,
//                                         "selected": false
//                                     },
//                                     {
//                                         "team": "left",
//                                         "type": "ban",
//                                         "timer": "30",
//                                         "chosen": false,
//                                         "selected": false
//                                     },
//                                     {
//                                         "team": "right",
//                                         "type": "ban",
//                                         "timer": "30",
//                                         "chosen": false,
//                                         "selected": false
//                                     },
//                                     {
//                                         "team": "right",
//                                         "type": "pick",
//                                         "timer": "30",
//                                         "chosen": false,
//                                         "selected": false
//                                     },
//                                     {
//                                         "team": "left",
//                                         "type": "pick",
//                                         "timer": "30",
//                                         "chosen": false,
//                                         "selected": false
//                                     },
//                                     {
//                                         "team": "left",
//                                         "type": "pick",
//                                         "timer": "30",
//                                         "chosen": false,
//                                         "selected": false
//                                     },
//                                     {
//                                         "team": "right",
//                                         "type": "pick",
//                                         "timer": "30",
//                                         "chosen": false,
//                                         "selected": false
//                                     }
//                                 ]
//                             },
//                             "display": {},
//                             "position": {
//                                 "x": 0,
//                                 "y": 729
//                             }
//                         },
//                         "type": "BanPick",
//                         "uuid": "1845792bd19f46eebab0029f40ae4447",
//                         "alias": null,
//                         "version": 1,
//                         "animation": {
//                             "mount": null,
//                             "mountEnd": null,
//                             "mountStart": null
//                         }
//                     },
//                     {
//                         "data": {
//                             "meta": {},
//                             "size": {},
//                             "style": {},
//                             "value": {
//                                 "option": {
//                                     "preset": "quadrangle"
//                                 }
//                             },
//                             "preset": "quadrangle",
//                             "display": {},
//                             "position": {
//                                 "x": "20",
//                                 "y": "20"
//                             }
//                         },
//                         "type": "Shape",
//                         "uuid": "0776c3462ef6c3cc72a3b7a16434a771",
//                         "alias": null,
//                         "version": 1,
//                         "animation": {
//                             "mount": null,
//                             "mountEnd": null,
//                             "mountStart": null
//                         }
//                     }
//                 ],
//                 "page_option": [],
//                 "uuid": "2595db61b8db21439d4aa4289bb7c572",
//                 "deleted_at": null,
//                 "deleted": 0
//             },
//             {
//                 "id": 567,
//                 "created_at": "2024-03-14T01:26:04.000Z",
//                 "updated_at": "2024-03-26T10:23:17.000Z",
//                 "overlap_id": 1,
//                 "page_title": "",
//                 "page_summary": "",
//                 "page_widget": [
//                     {
//                         "data": {
//                             "meta": {},
//                             "size": {},
//                             "style": {},
//                             "value": {
//                                 "text": "[{\"uuid\":\"b5a38a27d846b94c5ea061fa2f4e9173\",\"type\":\"Text\",\"alias\":null,\"animation\":{\"mountStart\":null,\"mount\":null,\"mountEnd\":null},\"data\":{\"position\":{\"x\":\"20\",\"y\":\"20\"},\"size\":{},\"style\":{},\"value\":{\"option\":{}},\"display\":{},\"meta\":{}},\"version\":1}]",
//                                 "option": {}
//                             },
//                             "display": {},
//                             "position": {
//                                 "x": "20",
//                                 "y": "20"
//                             }
//                         },
//                         "type": "Text",
//                         "uuid": "b5a38a27d846b94c5ea061fa2f4e9173",
//                         "alias": null,
//                         "version": 1,
//                         "animation": {
//                             "mount": null,
//                             "mountEnd": null,
//                             "mountStart": null
//                         }
//                     }
//                 ],
//                 "page_option": [],
//                 "uuid": "d204ff30101c11072a46bc9e018b1027",
//                 "deleted_at": null,
//                 "deleted": 0
//             },
//             {
//                 "id": 568,
//                 "created_at": "2024-03-14T01:26:04.000Z",
//                 "updated_at": "2024-03-14T01:26:04.000Z",
//                 "overlap_id": 1,
//                 "page_title": "",
//                 "page_summary": "",
//                 "page_widget": [],
//                 "page_option": [],
//                 "uuid": "c1b7d759a742fb6e038244406eca2f62",
//                 "deleted_at": null,
//                 "deleted": 0
//             },
//             {
//                 "id": 569,
//                 "created_at": "2024-03-14T01:26:04.000Z",
//                 "updated_at": "2024-03-14T01:26:04.000Z",
//                 "overlap_id": 1,
//                 "page_title": "",
//                 "page_summary": "",
//                 "page_widget": [],
//                 "page_option": [],
//                 "uuid": "80baf619b81c01838fa7edb647d29269",
//                 "deleted_at": null,
//                 "deleted": 0
//             },
//             {
//                 "id": 571,
//                 "created_at": "2024-03-27T01:39:49.000Z",
//                 "updated_at": "2024-03-27T01:39:49.000Z",
//                 "overlap_id": 1,
//                 "page_title": "",
//                 "page_summary": "",
//                 "page_widget": [
//                     {
//                         "data": {
//                             "meta": {},
//                             "size": {
//                                 "width": "700",
//                                 "height": "387"
//                             },
//                             "style": {
//                                 "textAlign": "center",
//                                 "fontFamily": "한나체"
//                             },
//                             "value": {
//                                 "text": "addada"
//                             },
//                             "preset": null,
//                             "display": {},
//                             "position": {
//                                 "x": "295",
//                                 "y": "337",
//                                 "show": null
//                             }
//                         },
//                         "type": "Text",
//                         "uuid": "c2123a59f7cf83d4ca171cc301d47bce",
//                         "alias": "ㄴㄴㄴ",
//                         "chosen": false,
//                         "version": 1,
//                         "selected": false,
//                         "animation": {
//                             "mount": null,
//                             "mountEnd": null,
//                             "mountStart": null
//                         }
//                     },
//                     {
//                         "data": {
//                             "meta": {},
//                             "size": {},
//                             "style": {
//                                 "fontSize": "51",
//                                 "textAlign": "left",
//                                 "fontFamily": "프리텐다드SemiBold"
//                             },
//                             "value": {},
//                             "preset": null,
//                             "display": {},
//                             "position": {
//                                 "x": "364",
//                                 "y": "589",
//                                 "show": null
//                             }
//                         },
//                         "type": "Text",
//                         "uuid": "4e894d308c40cf4c1f3b3e5fab8e9044",
//                         "alias": null,
//                         "chosen": false,
//                         "version": 1,
//                         "selected": false,
//                         "animation": {
//                             "mount": null,
//                             "mountEnd": null,
//                             "mountStart": null
//                         }
//                     }
//                 ],
//                 "page_option": [],
//                 "uuid": "31204cab661ff18f94ce2afe53a492cf",
//                 "deleted_at": null,
//                 "deleted": 0
//             },
//             {
//                 "id": 572,
//                 "created_at": "2024-04-01T09:22:41.000Z",
//                 "updated_at": "2024-04-01T09:22:41.000Z",
//                 "overlap_id": 1,
//                 "page_title": "",
//                 "page_summary": "",
//                 "page_widget": [],
//                 "page_option": [],
//                 "uuid": "9ae1c714cc1de59f3046bcb44e43740c",
//                 "deleted_at": null,
//                 "deleted": 0
//             },
//             {
//                 "id": 573,
//                 "created_at": "2024-04-01T12:48:06.000Z",
//                 "updated_at": "2024-04-01T12:48:06.000Z",
//                 "overlap_id": 1,
//                 "page_title": "",
//                 "page_summary": "",
//                 "page_widget": [],
//                 "page_option": [],
//                 "uuid": "5e8a9290e92cc766c70c85bdda9a8129",
//                 "deleted_at": null,
//                 "deleted": 0
//             },
//             {
//                 "id": 576,
//                 "created_at": "2024-04-02T07:35:13.000Z",
//                 "updated_at": "2024-04-02T07:35:13.000Z",
//                 "overlap_id": 1,
//                 "page_title": "",
//                 "page_summary": "",
//                 "page_widget": [],
//                 "page_option": [],
//                 "uuid": "3fcd59a2d6304205056145a8f6986cb6",
//                 "deleted_at": null,
//                 "deleted": 0
//             }
//         ],
//         "asset": []
//     },
//     "golbal": {
//         "variable": [],
//         "widget": []
//     }
// }