////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결 
import "sass/widgetEdit.scss"

import EditContext from 'service/context/EditContext';
import GlobalContext from 'service/context/GlobalContext';

// 컴포넌트 연결
import VideoStream from 'components/widget/stream/VideoStream'; // 이미지 버튼
import ImageButtonModule from 'components/Edit/editModule/ImageButtonModule'; // 이미지 버튼
import SwitchModule from 'components/Edit/editModule/SwitchModule'; // 이미지 버튼

// 서비스 연결
import * as Editor from 'service/edit/EditService'; 

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class CaptureStreamEdit extends Component {
    
    state = {
        cameraList : ['1','2'],
        editorToggle : true
    }

    constructor(props) {
        super(props);
        this.videoRef = React.createRef();
      }

    change = (name,value) =>{
        this.props.callBack('style',name,value)
    }
    valueChange = (name,value) =>{
        this.props.callBack('value',name,value)

        // streamID : null,
    }


    componentDidMount(){
        let cameraList = ['자동설정'] 
    }

    startCapture = async(displayMediaOptions) => {
        let captureStream;
        try {
          captureStream =
            await navigator.mediaDevices.getDisplayMedia(displayMediaOptions);
        } catch (err) {
          console.error(`Error: ${err}`);
        }
        // this.videoRef.current.srcObject = captureStream;
        return captureStream;
      }


    
    ScreenStreamInfo = (globalState) =>{

        return (
            <Fragment>
                   {Editor.WidgetEditSubhead({text:'화면 정보',icon:"video_settings"})}
                        <article className="EditPart MediaSourceModule" > 

                        </article>
            </Fragment>   
        )

    }

    ScreenStreamUndefined = (globalState) =>{
        return (
            <Fragment>
                        {Editor.WidgetEditSubhead({text:'화면 정보',icon:"devices"})}
                        <article className="EditPart MediaSourceModule" > 
                            <p className="notice">
                                아래에서 화면을 선택해주세요
                            </p>
                            <SwitchModule value={true} title="화면 자동전환" type ='auto' change={this.valueChange}/>
                        </article>
            </Fragment>   
        )

    }

    ScreenStream = (id) =>{
        this.valueChange('streamID',id)
    }

    ScreenStreamList = (globalState) =>{

        const StreamList = globalState.captureStream.map((stream)=>(
            <div className={ this.props.data.value.streamID==stream.id ? 'streamList selectStream': 'streamList' } onClick={()=>this.ScreenStream(stream.id)}>
                <div className="streamBox">
                <VideoStream streamSource={stream} streamID={stream.id}/>
                </div>

                <div className="streamInfo">
                    <p>
                       디바이스 의 화면
                    </p>
                    <p className="streamNoti">
                        이  디바이스의 화면
                    </p>
            
                    
                </div>
            </div>

        ));

        return (
            <Fragment>
                   {Editor.WidgetEditSubhead({text:'화면 리스트',icon:"subscriptions"})}
                        <article className="EditPart MediaSourceModule" > 
                            <button className="appendImageButton" onClick={() => globalState.getScreenCapture()}> 
                                <h5>내 컴퓨터에서 공유</h5>
                                <div className="appendImageIcon">
                                    <span style={{fontSize:'15px',lineHeight:'26px'}} className="material-symbols-outlined iconfont">screen_share</span>
                                </div>
                            </button>
                            <button className="appendImageButton" onClick={() => this.setState({imageAppend : !this.state.imageAppend})}> 
                                <h5>타 디바이스에서 공유</h5>
                                <div className="appendImageIcon">
                                    <span style={{fontSize:'15px',lineHeight:'26px'}} className="material-symbols-outlined iconfont">cast</span>
                                </div>
                            </button>
                            {StreamList}
                        </article>
            </Fragment>   
        )
    }

    render() { 
        const Style = {
            label: {
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
            },
            textarea : {
                width: '100%',
                resize: 'none',
                boxSizing:'border-box',
            },
            mediaView : {
                width: '100%',
                MaxHeight : '500px',
            }

        }




            // TODO 비디오 객체는 새롭게 만들어야합니다. 
        return (
            <Fragment>
            <section className='CaptureStreamEdit'>
                {Editor.WidgetEditTitle(this,{title:'화면 공유',toggle:false,icon:'screen_share'})}
                <GlobalContext.Consumer>
                {globalState => (
                    <div className={this.state.editorToggle?'editContainer containerOpen':'editContainer containerClose'}>
                        {this.ScreenStreamUndefined(globalState)}
                        {this.ScreenStreamList(globalState)}
                    </div>
                )}
                </GlobalContext.Consumer>
            </section>
            </Fragment>
        );
    }
}

////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    // screenData : state.screenData,
})

function mapDispatchToProps(dispatch){
    return {
    //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(CaptureStreamEdit);
  