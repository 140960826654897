////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, {useState, useEffect, useRef } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { Route, Link } from 'react-router-dom';
import { useHistory } from "react-router";
// 라이브러리 

// 설정 파일
import * as config from 'config/OverlapConfig'

// [컴포넌트] 위젯 
// import MiniView from "components/viewer/MiniView"
// [리덕스]스토어 연결
// import store from "store";

// SASS&CSS 연결
// import 'sass/login.scss'

// 이미지 연결
// import logoW from 'assets/logoW.svg';


// 서비스 연결
import * as DeCoder from '../../service/engine/DeCoder' ; 
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

/** [디스플레이] 
 * 프리뷰용도로 제작된 디스플레이 입니다.
 * @param {*} page 오버랩의 페이지 객체 (1개)
 * @param {number|null} previewWidth 컴포넌트의 가로 사이즈
 * @param {number|null} previewHeight 컴포넌트의 세로 사이즈
 * @param {number} referenceWidth 디스플레이의 해상도
 * @param {number} referenceHeight 디스플레이의 해상도
 * @param {*} option 디스플레이에 적용할 옵션
 * @returns 
 */


const StaticDisplay =  React.memo(function StaticDisplay({ page = null, previewWidth = null, previewHeight = null, referenceWidth, referenceHeight, option={} }) {
    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////
    // state
    // const [overlapList, setList] = useState(null);
   
    // ref

    // const displayRef = useRef(null);

    ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////

      useEffect(() => {
        // console.log('Component did mount or update');
        // getOverlapList() 
        // Cleanup function (optional)
        return () => {
          // console.log('Component will unmount');
        };
      },[]);

      const width = previewWidth;
      const height = previewHeight;
      let zoom = (height / referenceHeight)??0;

    ////////////////////////////////////////////////////////
    // Fuction
    ////////////////////////////////////////////////////////
    const DisplayCore = () => {

        if(zoom){
            return DeCoder.run(page,'view')
        }
    }

    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////


    const Ratio = referenceHeight/referenceWidth;
    
    const ContainerSizeControl ={
        height: previewHeight,
        width : previewWidth,
        alignItems: option.align??'center',
    }

    const displaySizeControl = {

        aspectRatio:  `${referenceWidth} / ${referenceHeight}`,
      };

      if(previewHeight>=previewWidth*Ratio){
        displaySizeControl['width'] = previewWidth;
        zoom = (width / referenceWidth)??0;
      }else{
        displaySizeControl['height'] = previewHeight;
        zoom = (height / referenceHeight)??0;
      }


    const zoomControl = {
        transform:  `scale(${zoom})`,
    };
      
    return (
        <div className='StaticDisplayContainer' style={ContainerSizeControl}  >
        {/* <style jsx>{`${DisplayStyle}${SizeStyle}`}</style> */}
        <style jsx>{`${DisplayStyle}`}</style>
            {/* <div style={displaySizeControl} className='PageDisplay' ref={ref}> */}
            <div style={displaySizeControl} className='StaticDisplay transparent-background'>
            {/* Width: {width}, Height: {height} */}
                <div  style={zoomControl} className='StaticDisplayCore' >
                    {DisplayCore()}
                </div>
            </div>
        </div>
    );
  });

  export default StaticDisplay;
////////////////////////////////////////////////////////
// css 부분
////////////////////////////////////////////////////////
const DisplayStyle = `
    .StaticDisplayContainer {
        width: 100%;
        height: 100%;
        display:flex;
        justify-content: center;

    }
    .StaticDisplayContainer .StaticDisplay{

        border-radius: 5px;
        box-sizing: border-box;
        box-shadow: 0 6px 16px rgba(0,0,0,.15);
        overflow: hidden;        
    }
    .StaticDisplayContainer .StaticDisplayCore{
        width: 100%;
        height: 100%;
        transform-origin: 0% 0%; 
     
    }

    .StaticDisplayContainer .transparent-background {
      background-image: linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff),
      linear-gradient(45deg, #fff 25%, #ccc 25%, #ccc 75%, #fff 75%, #fff);
      background-size: 8px 8px;
      background-position: 0 0, 4px 4px;
    }
 
`;


