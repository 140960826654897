
////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결

// 라이브러리 

// 설정 파일
import * as config from 'config/OverlapConfig'
// [리덕스]스토어 연결
import store from "store";



import axios from 'axios';




// SASS&CSS 연결

// 이미지 연결

// 서비스 연결
import * as Rest from 'service/api/rest' ;
import * as Utility from 'service/other/Utility' ; 
import { token } from 'service/event/Account' ; 


const SERVERURL = config.SERVERS[0].serverEndPoint+":"+config.SERVERS[0].serverPort;

////////////////////////////////////////////////////////
// store 연결
////////////////////////////////////////////////////////




////////////////////////////////////////////////////////
/** upload
 * @param spaceId 오버랩을 생성할 스페이스의 ID를 적습니다.
 * @param data 오버랩 생성에 필요한 정보를 적습니다. {title,resolution,private,private,summary}
 * @returns 오버랩 IO 객체
 */
////////////////////////////////////////////////////////

export async function upload(space_id,file,description) {



    const formData = new FormData();

    const FileOption = {}

    const Resolution = await getImageResolution(file);

    if(Resolution?.width&&Resolution?.height){
      FileOption['resolution'] =  {
        width :Resolution?.width,
        height :Resolution?.height
      }
    }

    console.log('file',FileOption);

    const BlobImage = await Utility.compressImage({file,quality:0.3,maxWidth:300,maxHeight:400})

    const PreviewImage  = new File([BlobImage], BlobImage.name, {
        type: BlobImage.type,
        size: BlobImage.size,
        lastModified: BlobImage.lastModified
      });

    if(file){
        formData.append('file', file);
        formData.append('previewfile', PreviewImage);
    }
    if(description){
        formData.append('description', description);

    }

    console.log('file',file,PreviewImage);
    formData.append('space', space_id);
    formData.append('fileOption', JSON.stringify([{resolution:Resolution}]) );
    let response =  await Rest.request('/file/upload','Upload',formData,token('account'));
    console.log('response',response);
    if(response?.status == 200){
        
        return response?.data?.data;
    }

    return false;
}




async function getImageResolution(file) {
    if (!file.type.startsWith('image/')) {
      throw new Error('The file is not an image.');
    }
  
    const img = new Image();
    const objectUrl = URL.createObjectURL(file);
  
    return new Promise((resolve, reject) => {
      img.onload = () => {
        URL.revokeObjectURL(objectUrl); // 메모리 누수를 방지하기 위해 객체 URL을 해제
        resolve({ width: img.width, height: img.height });
      };
      img.onerror = (error) => {
        URL.revokeObjectURL(objectUrl);
        reject(error);
      };
      img.src = objectUrl;
    });
  }

////////////////////////////////////////////////////////
/** templateGetList
 * @param spaceId 오버랩을 생성할 스페이스의 ID를 적습니다.
 * @param data 오버랩 생성에 필요한 정보를 적습니다. {title,resolution,private,private,summary}
 * @returns 오버랩 IO 객체
 */
////////////////////////////////////////////////////////

export async function getList(space_id) {

    let response =  await Rest.request('/file/list','get',{space_id},token('account'));

    if(response?.status == 200){
        return response.data.data;
    }
    return false;
}


export async function del(space,uuid) {

    let response =  await Rest.request('/file/delete','post',{space,uuid},token('account'));

    if(response?.status == 200){
        return response.data.data;
    }
    return false;
}


export async function image(uuid) {

    let response =  await Rest.request('/file/getimage','get',{file_uuid:uuid},token('account'));

    if(response?.status == 200){
        return response.data.data;
    }
    return false;
}


