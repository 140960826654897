////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, {useState, useEffect, useRef } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { Route, Link } from 'react-router-dom';
import { useHistory } from "react-router";
// 라이브러리 
import { useGoogleLogin } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google'// 구글 로그인
// 설정 파일
import * as config from 'config/OverlapConfig'

import { withRouter } from 'react-router-dom';

import * as apiAccount from 'service/api/apiAccount'

// 위젯 
import PreviewDisplay from "components/display/PreviewDisplay"
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import 'sass/login.scss'

// 이미지 연결
import logoW from 'assets/logoW.svg';
import youtube from 'assets/main/lobby/social/youtube.png';
import twitch from 'assets/main/lobby/social/twitch.png';
import afreeca from 'assets/main/lobby/social/afreeca.png';
import facebook from 'assets/main/lobby/social/facebook.png';
import naver from 'assets/main/lobby/social/naver.png';
import { get } from 'lodash';

// 서비스 연결
import * as view from 'service/api/view';
import * as page from 'service/api/page';
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

function ChannelInfo(props) {

    const [getPreview, setPreview] = useState(null);

    function setSelect(){
        props.setSelect(props.data.id,props.data.uuid,props.data);
      }


    useEffect(() => {
        getPagePreview() 
        // Cleanup function (optional)
        return () => {
            console.log('Component will unmount');
        };
    },[]);

    async function getPagePreview(){
        const NewPreview = [];

        
        if(props.data?.view_pointer?.[0]?.uuid){
            const overLapPreview = await page.getPageForUUID(props.data?.view_pointer?.[0]?.uuid);
            NewPreview.push(overLapPreview[0])
            setPreview(NewPreview)
            console.log('doen',overLapPreview[0],props.data)
        }
    }



    const uuid = view.uuidConverter('url',props.data.uuid);

    console.log('viewData',props.data);
    return (
        // <Link to={{
        //     pathname: `/view/${uuid}`,
        //     state: {id: props.data.id , uuid: props.data.uuid},
        //   }}>
        <div className='ChannelFile'>
            <style jsx>{`${SpaceModuleStyle}${ContainerStyle}`}</style>
            <div className = {"ChannelInfoInfo "+props.data.uuid} onClick={()=>setSelect()}>
                <div className='upperInfo'>
                <div className = "channelState">
                </div> 
                <div className = "preView">
                    <PreviewDisplay pageList={getPreview} referenceWidth={props.data.resolution_width??0} referenceHeight={props.data?.resolution_height??0}/>  
                    {/* <MiniView zoom={0.1} data={getPreview?.[0]?.page_widget?getPreview?.[0]?.page_widget:[]} />   */}
                </div> 
                </div>
                <div className='lowerInfo'>
                <div className = "info">
                    <div className = "title">
                        {props.data?.view_name?props.data?.view_name:'이름없는 채널'}
                    </div>
                </div>
                </div>
                </div>
            </div>
    );
  }

  export default ChannelInfo;
  ////////////////////////////////////////////////////////
// css 부분
////////////////////////////////////////////////////////
const SpaceModuleStyle = `
.ViewListContainer {

}

`;

const ContainerStyle = `

@keyframes start {
    0% {
      opacity: 0;

    }
    100% {
      opacity: 1;

    }
  }

.ChannelFile {
    animation: start 0.6s forwards;
    display: flex;
    justify-content: center;
    width: 100%;
}
.ChannelFile .ChannelInfoInfo{
    justify-self: center;
    box-sizing: content-box;
    width: 192px;
    height: 150px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    transition: all 0.3s;
}
.ChannelFile .upperInfo{
    position: relative;
    width: 192px;
    height: 108px;
}
.ChannelFile .channelState{
    position: absolute;
    display: flex;
    justify-content: end;
    width: 192px;
    padding: 5px 5px;
    height: 40px;
    z-index: 1;
}
.ChannelFile .preView{
    position: absolute;
    width: 192px;
    height: 108px;
    overflow: hidden;
    border-radius: 5px ;
    transition: all 0.5s;
}
.ChannelFile .MiniViewloding{
    box-sizing: border-box;
    border: 3px solid white;       
    background-image: url('../assets/logoW.svg');
    background-repeat: no-repeat;
    background-size: 80% 80%;
    background-position: center center;    
}
.ChannelFile .lowerInfo{

}
.ChannelFile .info{

}
.ChannelFile .title{
    display: flex;
    overflow: hidden;
    text-align : center;
    height: 20px;
    font-size: 15px;
    font-family: '프리텐다드Regular';
    line-height: 15px;
    margin: 10px 0px;
    justify-content: center;
}
`;