import * as config from 'config/OverlapConfig'

import axios from 'axios';

const SERVERURL = config.SERVERS[0].serverEndPoint+":"+config.SERVERS[0].serverPort;

// var serveURL =  'https://jongho.shop/';

var server = {};


export function linkURL() {

    
    console.log(config.SERVERS);

}


export function requestPOST(url,parameter='',token=false) {
    console.log(`[requestPOST] url : ${url} parameter : ${parameter} token : ${token}`);
    return new Promise(function(resolve,reject){
        // 임시 토큰처리 
        if (token){
            let tokenParameter = {'token':token}
            parameter = {...parameter,...tokenParameter};
        }
        console.log(url,parameter)
        axios.post(url,parameter,{ headers: {"Authorization" : 'Bearer'+token}})
        .then( response => {
            console.log("[Axios 응답]",response)
            resolve(response);
        }, { credentials: true })
        .catch( error => {
            console.log("[Axios 에러]",error)
            reject(error);
        })
        });  
    
}

export function requestPUT(url,parameter='',token=false) {
    console.log(`[requestPUT] url : ${url} parameter : ${parameter} token : ${token}`);
    return new Promise(function(resolve,reject){
        // 임시 토큰처리 
        if (token){
            let tokenParameter = {'token':token}
            parameter = {...parameter,...tokenParameter};
        }
        axios.put(url,parameter,{ headers: {"Authorization" : 'Bearer'+token}})
        .then( response => {
            console.log("[Axios 응답]",response)
            resolve(response);
        }, { credentials: true })
        .catch( error => {
            console.log("[Axios 에러]",error)
            reject(error);
        })
        // .then(function () {
        //     // always executed
        //   }); 
        });  
    
}



export function requestDELETE(url,parameter='',token=false) {
    console.log(`[requestDELETE] url : ${url} parameter : ${parameter} token : ${token}`);
    return new Promise(function(resolve,reject){
        // 임시 토큰처리 
        if (token){
            let tokenParameter = {'token':token}
            parameter = {...parameter,...tokenParameter};
        }
        console.log(url,parameter)
        axios.delete(url,parameter,{ headers: {"Authorization" : 'Bearer'+token}})
        .then( response => {
            console.log("[Axios 응답]",response)
            resolve(response);
        }, { credentials: true })
        .catch( error => {
            console.log("[Axios 에러]",error)
            reject(error);
        })
        // .then(function () {
        //     // always executed
        //   }); 
        });  
    
}

export function requestGET(url,parameter='',token=false) {
    return new Promise(function(resolve,reject){
    if (token){
        let tokenParameter = {'token':token}
        parameter = {...parameter,...tokenParameter};
    }
    axios.get(url,{params:parameter, headers: {"Authorization" : 'Bearer'+token}})
      .then(function (response) {
        console.log("[Axios 응답]",response)
        resolve(response);
      })
      .catch(function (error) {
        console.log("[Axios 에러]",error)
        reject(error);
      })
    //   .then(function () {
    //     // always executed
    //   });  
});  
    
}



export function requestUpload(url,fileData=null,token=false) {


    function isFormData(variable) {
        return variable instanceof FormData;
      }

    return new Promise(function(resolve,reject){
        // 임시 토큰처리 
        if (!isFormData(fileData)){
            console.error('[Axios 에러] : upload 요청을 하였으나 대상이 파일이 아닙니다.')
            return;
        }
        axios.post(url,fileData,{ headers: {
            // 'Content-Type': 'multipart/form-data',
            "Authorization" : 'Bearer'+token}})
        .then( response => {
            console.log("[Axios 응답]",response)
            resolve(response);
        }, { credentials: true })
        .catch( error => {
            console.log("[Axios 에러]",error)
            reject(error);
        })
    });  
    
}



export function fullUrlrequest(url,method='GET', parameter='',token=false,) {

    // 주소 설정
    let requestURL = url
    // 대문자로 method 변경
    console.log('연결테스트')
    method = method.toUpperCase()
    if (method=='POST') {
        return requestPOST(requestURL,parameter,token)
    }
    else if (method=='GET') {
        return requestGET(requestURL,token)
    }
    else if (method=='PUT') {
        return requestPUT(requestURL,parameter,token)
    }
    else if (method=='DELETE') {
        return requestDELETE(requestURL,parameter,token)
    }
}

export function request(url,method='GET', parameter='',token=false,) {


    // 주소 설정
    let requestURL = SERVERURL+url
    // 대문자로 method 변경
    console.log('연결테스트',parameter)
    method = method.toUpperCase()
    if (method=='POST') {
        return requestPOST(requestURL,parameter,token)
    }
    else if (method=='GET') {
        return requestGET(requestURL,parameter,token)
    }
    else if (method=='PUT') {
        
        return requestPUT(requestURL,parameter,token)
    }
    else if (method=='DELETE') {
        return requestDELETE(requestURL,parameter,token)
    }
    else if (method=='UPLOAD') {
        return requestUpload(requestURL,parameter,token)
    }

    
}

    

//  가상화폐용
export function CurrencyAPI(exchange,Currency) {

    let url =''
    switch (exchange) {
        case 'upbit_KRW':
            url = 'https://crix-api-endpoint.upbit.com/v1/crix/candles/days/?code=CRIX.UPBIT.KRW-'
            break;
        case 'coinone':
            url = 'https://api.coinone.co.kr/ticker?currency='
            break;   
        default:
            break;
    }
    

    return new Promise(function(resolve,reject){
    axios.get(url+Currency)
      .then(function (response) {
        // console.log("[Axios 응답]",response)
        resolve(response);
      })
      .catch(function (error) {
        console.log("[Axios 에러]",error)
        reject(error);
      })
    //   .then(function () {
    //     // always executed
    //   });  
});  
    
}
// 

//  오버워치
export function overwatchAPI(battleCode) {
    let parameter = {battleCode}
    let url = 'https://over-lap.kr/overwatchprofile/'
    
    return new Promise(function(resolve,reject){
    axios.post(url,parameter,{ headers: {'Content-Type': 'application/json'}})
      .then(function (response) {
        // console.log("[Axios 응답]",response)
        resolve(response);
      })
      .catch(function (error) {
        console.log("[Axios 에러]",error)
        reject(error);
      })
    //   .then(function () {
    //     // always executed
    //   });  
});  
    
}
// 

export function myURL(subdomain='') {

    let url = window.location.hostname
    if(url == 'localhost'){
        // 로컬호스트 인경우
        url  = 'http://'+'localhost:3000'+subdomain
    }else{
        url  = 'https://'+url+subdomain
    }
    return url
}

export function serverTime() {
    // try {
    //     let xmlHttp = new XMLHttpRequest();
    //     xmlHttp.open('HEAD',window.location.href.toString(),false);
    //     xmlHttp.setRequestHeader("Content-Type", "text/html");
    //     xmlHttp.send('');
    //     return xmlHttp.getResponseHeader("Date");
    // } catch (error) {
    //     return 0
    // }
}
