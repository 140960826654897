////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widgetEdit.scss"

// 컴포넌트 연결
import TextModule from '../editModule/TextModule'; // 텍스트 모듈
import TextSizeModule from '../editModule/TextSizeModule'; // 텍스트 크기 모듈
import FontModule from '../editModule/FontModule'; // 폰트 종류 모듈
import ColorModule from '../editModule/ColorModule'; // 색상 모듈
import RangeModule from '../editModule/RangeModule'; //범위 모듈 
import SelectModule from '../editModule/SelectModule'; // 선택리스트 모듈
import RadioButtonModule from '../editModule/RadioButtonModule';

import del from 'assets/edit/delete.png';

// 서비스 연결

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class BroadcastAlertEdit extends Component {
    
    state = {
        value : ''

    }

    change = (name,value) =>{
        this.props.callBack('style',name,value)
    }
    valueChange = (name,value) =>{

        switch (name) {
            case 'fontSize':
                // px 때문에 비교 불가 
                if(value>this.props.data.size.height){
                }
                break;
        
            default:
                break;
        }
        this.props.callBack('value',name,value)

    }

    addList = () =>{
        if( this.props.data.value.schedule.length<10){
            let schedule  = this.props.data.value.schedule
            schedule.push({title:'',text:''})
            this.valueChange('schedule',schedule)
        }
        
    }

    titleChange = (e,index) => {
        let schedule  = this.props.data.value.schedule
        schedule[index].title = e.target.value
        this.valueChange('schedule',schedule)
    }
    textChange = (e,index)  => {
        let schedule  = this.props.data.value.schedule
        schedule[index].text =  e.target.value
        this.valueChange('schedule',schedule)
    }

    deleteList = (num) =>{
        let schedule  = this.props.data.value.schedule
        schedule.splice(num, 1);
        this.valueChange('schedule',schedule)
    }

    tablelist = (listup)=>{

        let list = this.props.data.value.schedule.map(
            (info,index) => (
                <tr>
                <td><input value={info.title} onChange={(e)=>{this.titleChange(e,index)}}/></td><td><input value={info.text} onChange={(e)=>{this.textChange(e,index)}}/></td><button className="delbut" onClick={()=>this.deleteList(index)}><img src={del}></img></button>
                </tr>
            ) 
        );    


        return(
            <table  className="EditTableList" border="0">
            <tr>
            {this.props.data.value.schedule.length>0?
            <Fragment><th>제목</th><th>본문</th><span className="dumi"></span></Fragment>
            :<p className="notice" style={{'margin':0}}>여기서 알람을 추가하세요</p>
            }
            </tr>
            {list}
            <div>
                {this.props.data.value.schedule.length<10?
                <button className="addList" onClick={()=>this.addList()}>
                <p>새 알람 추가</p>
                </button>
                :''}
            </div>
            </table>
        )
    }
    
    render() { 

        const Style = {
            label: {
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
            },
            textarea : {
                width: '100%',
                resize: 'none',
                boxSizing:'border-box',
            },
            mediaView : {
                width: '100%',
                MaxHeight : '500px',
            }

        }
        return (
            <Fragment>

            <fieldset className="EditModuleField" style={Style.fieldset} >
                <legend style={Style.legend} > 전환속도 </legend>
                <RadioButtonModule value={this.props.data.value.speed} list={[{name:'빠르게',value:2},{name:'보통',value:5},{name:'느리게',value:10}]} type ="speed" title="변환속도"  change={this.valueChange} />
            </fieldset>

            <fieldset className="EditModuleField" style={Style.fieldset} >
                <legend style={Style.legend} > 본문 ({this.props.data.value.schedule.length}/10) </legend>
                {this.tablelist()}
            </fieldset>

            <fieldset className="EditModuleField" style={Style.fieldset} >
                <legend style={Style.legend} > 색상 </legend>
                <ColorModule value={this.props.data.style.main_fill} type ="main_fill" title="주배경색" change={this.change}/>
                <ColorModule value={this.props.data.style.sub_fill} type ="sub_fill" title="보조배경색" change={this.change}/>
                <ColorModule value={this.props.data.style.main_color} type ="main_color" title="주글자색" change={this.change}/>
                <ColorModule value={this.props.data.style.sub_color} type ="sub_color" title="보조글자색" change={this.change}/>
                {/* <TextSizeModule value={this.props.data.style.fontSize} type ="fontSize" change={this.change}/> */}
                <FontModule  value={this.props.data.style.fontFamily} type ="fontFamily" change={this.change}/>

                <RangeModule value={this.props.data.style.fillOpacity} type ="fillOpacity" title="배경투명도" valueView="%" min="0" max="100" change={this.change} />
            </fieldset>


            </Fragment>
        );
    }
}

////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    // screenData : state.screenData,
})

function mapDispatchToProps(dispatch){
    return {
    //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(BroadcastAlertEdit);
  