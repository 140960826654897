// 프로모션 페이지 - 헤더색션 히어로 색션

////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component, Fragment , useState, useEffect, useRef} from 'react';

import { Route, Link } from 'react-router-dom';
// [리덕스]스토어 연결

// 이미지 연결
import logoW from 'assets/logoW.svg';
import PromotionVideo from 'assets/Promotion/overlapPromotion1.mp4';
// SASS&CSS 연결
import "sass/frontPage.scss"

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

function BottomSection () {
    
    return  (
    <Fragment>
    <style jsx>{`${ComponentStyle}`}</style>
        <section id='BottomSection'>

        <div className='BottomSection'>
            <p className='OverviewText'>
                오버랩 지금 바로 시작해보세요
            </p>
        </div>
       
      

        </section>
      </Fragment>
    );

}

export default BottomSection;



const ComponentStyle = `

#BottomSection{
    display :flex;
    flex-direction: column;
    width : 100vw;
    height : 300px;
    color : #fff;
    background-color : #333;
    font-family: '프리텐다드ExtraBold';

}
.BottomSection{
    width :100%;
    height : 100%;
    display :flex;
    justify-content: space-around;
    align-items: center;
}
.BottomSection{
    width :100%;
    font-size : 30px;
    text-align: center;
}

.BottomSection p{

}
`;