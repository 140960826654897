////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widgetEdit.scss"

//
import exampleFilter from 'assets/exampleFilter.jpg';
// 컴포넌트 연결
import InfoButtonModule from '../editModule/InfoButtonModule';//위치 모듈
import RangeModule from '../editModule/RangeModule'; // 색상 모듈
// 서비스 연결
import * as Utility from 'service/other/Utility' ; 
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class ClippathEdit extends Component {
    
    state = {
        
    }

    change = (name,value) =>{
        this.props.callBack('style',name,value)
    }

    nodeValueChange = (value,index,type) =>{
        value = this.pixel2percent(value,type)
        let nodelist = this.props.data.style.clipNode 
        switch (type) {
            case 'width':
                nodelist[index][0] = value
                break;
            case 'height':
                nodelist[index][1] = value
                break;
            default:
                break;
        }
        this.change('clipNode',nodelist)
    }

    pixel2percent = (pixel,type) =>{
        
        let percent = pixel/(Utility.sizeCalculator(this.props.data.size[type],type)/10000)
        if (isNaN(percent)){
        return 0
        }
        return percent
    }
    
    percent2pixel = (percent,type) =>{
       let pixel = Utility.sizeCalculator(this.props.data.size[type]/10000,type) * percent

       return parseInt(pixel)
    }
    

    nodeList = () => {
        return this.props.data.style.clipNode.map((node,index) => 
        (<div className='node'>
            <span className='nodeCircleColor'/>
            <span className='nodeVal'>
                <span>
                X : <input value={this.percent2pixel(node[0],'width')} onChange={(e) => this.nodeValueChange(e.target.value,index,'width')} maxlength='4'/> <sub>px</sub>
                </span>
                <span>
                Y : <input value={this.percent2pixel(node[1],'height')} onChange={(e) => this.nodeValueChange(e.target.value,index,'height')} maxlength='4'/> <sub>px</sub> 
                </span>

            </span>
            <span  className='nodeDelSpan'>
                <button className='nodeDel' onClick={()=>this.delectNode(index)}>x</button>
            </span>
        </div>)
        );
    }
    
    appendNode = () => {
        let node = this.props.data.style.clipNode
        node.push([null,null])
        this.change('clipNode',node)
    }

        
    delectNode = (num) => {
        let node = this.props.data.style.clipNode
        node.splice(num, 1)
        this.change('clipNode',node)
    }

    render() { 
        const Style = {
            label: {
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
            },
            textarea : {
                width: '100%',
                resize: 'none'
            }
        }

        return (
            <fieldset className=" EditModuleField ClippathEdit" style={Style.fieldset} >
                <legend style={Style.legend} > 자르기 </legend>
                <div className='EditModule'> 
                {this.nodeList()}
                <div>
                <button onClick={()=>this.appendNode()}>새 편집선 추가</button>
                </div>
                </div>

            </fieldset>
        );
    }
}

////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////

const mapStateToProps = (state) => ({
    // exemple : state.exemple,
})

function mapDispatchToProps(dispatch){
    return {
    //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(ClippathEdit);
  