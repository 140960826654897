////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { useState, useEffect, useContext, Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { SortableContainer, SortableElement,ReactSortable } from 'react-sortablejs';
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widgetEdit.scss"

// [프로바이더]
import EditContext from 'service/context/EditContext' ; 

// 컴포넌트 연결
import PositionModule from '../editModule/PositionModule'; // 위젯의 사이즈를 조정 하는 모듈입니다.
import ErrorModule from '../editModule/ErrorModule'; // 잘못된 위젯의 값이 있을때 편집기를 대체합니다.
import GuideBalloon from 'components/common/form/GuideBalloon'// 가이드 벌룬

import VarTeam from 'components/Edit/editVariable/varTeam/VarTeam';
import ImageButtonModule  from 'components/Edit/editModule/ImageButtonModule'
import ScoreboardModule from '../editModule/ScoreboardModule'; // 스코어 보드용 팀 묘듈
import SentenceModule from '../editModule/SentenceModule'; // 텍스트 모듈

import TextSizeModule from '../editModule/TextSizeModule'; // 텍스트 크기 모듈
import FontModule from '../editModule/FontModule'; // 폰트 모듈
import ColorModule from '../editModule/ColorModule'; // 색상 모듈
import SwitchModule from '../editModule/SwitchModule'; // 체크박스 모듈
import NumberModule from '../editModule/NumberModule'; // 숫자 모듈

// 서비스 연결
import * as Utility from 'service/other/Utility'; 
import * as Editor from 'service/edit/EditService'; 
import * as ED from 'service/engine/decoder/EditorDecoder'; 
import { dragTransparent } from 'service/event/common' ; 
// 이미지 연결

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////


/** [에디터] 텍스트 편집 툴 입니다.
 * (대상위젯, 편집펑션, 편집할 객체 키, 타이틀 ,옵션)
* @param {widget} target : 편집할 대상 위젯
* @param {function} modify : 편집용 함수
* @param {{
* style: { x : number, y : number }
* }} keyName : 해당 에디터가 수정하는 값 입니다. value : {'textValue', 수정할 대상값,} 
* @param {{
* editTitle: string,textValue: string
* }} title : 편집기에 표시될 제목들 입니다.
* @param {{
* fixToggle : boolean 
* openToggle : boolean
* }} option : 추가 옵션입니다. fixToggle : 토글을 고정합니다. openToggle : 필드의 기본 상태를 열림으로 봅니다.
*/


function TeamEditForm({target,modify,keyName,title,option,global}) {

    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////

    //set
    const context = useContext(EditContext);
    const VariableTeam = global?.variable?.team??[];
    // state
    const [Toggle, setToggle] = useState(option?.openToggle==undefined?true:option?.openToggle);
    const [OpenForm, setOpenForm] = useState(option?.openForm==undefined?true:option?.openForm);

    const [ShowList, setShowList] = useState(null);

    // rel 

    ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////
    useEffect(() => {

        // 여기에 컴포넌트가 마운트되었을 때 실행할 코드를 작성합니다.

        return () => {
        // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
        };
      }, []);

    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////

       // 해당 에디터에서 편집이 가능한 값 목록
    const {maxTeam,teamData} = keyName ;

    const teamBlock = (data,index) => {
        
        const output = [];
        const filteredItems = VariableTeam.filter(item => item.id === Utility.replaceGlobal(data[index]));
        if(!data[index] || filteredItems.length==0){
            output.push(<VarTeam target={{teamName:'팀을 선택하세요'}} select={()=>setShowList(index)}/>) 
        }else{
            output.push(<VarTeam target={filteredItems[0]} select={()=>setShowList(index)}/>) 
        }
        if(ShowList==index){
            output.push(List) 
        }
        return output
    }



    const TeamList = VariableTeam.map(
        (data,index) => (<VarTeam target={data} index={index} key={data.id} select={(target)=>modifyTeam(target.id)}/> ) 
    );  


    const List = (
        <Fragment>
        <div class="tailArea">
            <div class="tail"/>
        </div>
        {TeamList.length != 0 ?
        <div className="TeamSelectList"  onClick={()=>setShowList(null)} >
        {TeamList}
        </div>
        :<div className='TeamSelectMessage '  onClick={()=>setShowList(null)}>
            <p> 생성된 팀이 없습니다.</p>
            
        </div>
        }
        </Fragment>
    )




    const TeamListData =  ED.getValue(target,teamData)

    const modifyTeam = (value) => {
        const Data =  ED.getValue(target,teamData) ;
        Data[ShowList] = 'global_team_'+value;
        setShowList(null)
        modify(teamData.category,teamData.key,Data,target.uuid)
    }

    const sortTeam = () => {
        const Data =  ED.getValue(target,teamData) ;
        Data.reverse()
        setShowList(null)
        modify(teamData.category,teamData.key,Data,target.uuid)
    }

    // 유효한팀 숫자를 넣습니다.
    let count = 0;

    VariableTeam.forEach(item => {
        if(TeamListData.includes('global_team_'+item.id)){
            count = count + 1
        }

    });

    return (
    <Fragment>
        <style jsx>{`${FormStyle}`}</style>
        <section className='TeamEditForm'>

            {Editor.editorDetail('팀 설정','group',OpenForm,setOpenForm,Toggle)}
                <article className="EditPart">
                    <nav className="TeamNav">
                        <div>팀 선택<small> &#40; {count}/2 &#41;</small></div>
                        <button className="appendTeam" onClick={()=>context.panelControl('bottom','team')}>
                        {Editor.icon({icon:'group',size:25,lineHeight:30})} &nbsp; 
                            팀 설정하기 
                        </button>
                    </nav>

                    <div className="TeamSelect">
                    {teamBlock(TeamListData,0)}
                    {teamBlock(TeamListData,1)}
                    </div>
                    <nav className="TeamBottom">
                        <button className="sortButton" onClick={()=>sortTeam()}>
                        {Editor.icon({icon:'swap_vert',size:25,lineHeight:30})} &nbsp; 
                            순서 변경
                        </button>
                    </nav>
                </article>

        </section>
    </Fragment>
    );

}


export default TeamEditForm;


const FormStyle = `

.TeamEditForm {

}

.TeamEditForm .teamEdit{

}
.TeamEditForm  .TeamNav{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 25px;
    width: calc(100% - 50px);
}

.TeamEditForm  .TeamNav .appendTeam{
    padding: 0px 10px;
    margin : 0px 0px;
    display: flex;
    justify-content:center;
    height : 30px;
    line-height : 30px;
    border-radius : 5px;
    box-shadow: 3.5px 3.5px 10px #252525, -3.5px -3.5px 10px #636363;
}

.TeamEditForm  .TeamBottom{
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 0px 25px;
    margin-top : 10px;
    width: calc(100% - 50px);
}

.TeamEditForm  .TeamBottom .sortButton{
    padding: 0px 10px;
    margin : 0px 0px;
    display: flex;
    justify-content:center;
    height : 30px;
    line-height : 30px;
    border-radius : 5px;
    box-shadow: 3.5px 3.5px 10px #252525, -3.5px -3.5px 10px #636363;
}


.TeamEditForm article.EditPart {
    width: 100%;
    padding: 20px 0px;
}


.TeamEditForm  .TeamSelectList{
    width: 100%;
    display: flex;
    max-height : 200px;
    overflow : auto;
    flex-direction: column;
    align-items: center;
    background-color: #222;
    padding-bottom : 15px;
    position: relative;
}

.TeamEditForm .tailArea{
    width: 100%;
    height : 20px;
    display: flex;
    justify-content: center;
    
}
.TeamEditForm .tailArea .tail{
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 0px 20px 20px 20px; /* 삼각형 모양 */
    border-color: transparent transparent #222 transparent; /* 아래쪽 삼각형 */
}

.TeamEditForm  .TeamSelect{
    width: 100%;
    display: flex;
    overflow: auto;
    flex-direction: column;
    align-items: center;
}
.TeamEditForm .TeamSelectMessage{
    width: 100%;
    display: flex;
    overflow: auto;
    justify-content: center;
    align-items: center;
    height : 60px;
    background-color: #222;
}
.TeamEditForm  .TeamSelect .VarTeam{
    margin-bottom : 5px;
}

`;



const TempScoreboardData = [
    {
        teamName : 'team1',
        teamColor : '#ff0000',
        teamLogo : '',
        score :  0,
        subScore : 0, 
    },    {
        teamName : 'team2',
        teamColor : '#ffff00',
        teamLogo : '',
        score :  1,
        subScore : 1, 
    }
] 