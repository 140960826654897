function preset(preset,self,data,style){
    let fullsize = data.size.height
    let width = data.size.width
    let profile = self.state.profile
    let dataList = []
    let dataField =''
    switch (preset) {
        case 'liveCamIcon1':
            return(
                <div className={"object "+self.props.widgetName+"_preset_"+preset} ref={self.widget} style={{...style.object}} >
                    <img style={{width:width,height:fullsize}} src="https://overlap.s3.ap-northeast-1.amazonaws.com/widget/LiveIcon/liveCamIcon1.png"/>
                </div>
            )
        case 'liveCircle1':
            return(
                <div className={"object "+self.props.widgetName+"_preset_"+preset} ref={self.widget} style={{...style.object}} >
                    <img src="https://overlap.s3.ap-northeast-1.amazonaws.com/widget/LiveIcon/liveCircle1.png"/>
                </div>
            )
        case 'liveSpeechIcon1':
            return(
                <div className={"object "+self.props.widgetName+"_preset_"+preset} ref={self.widget} style={{...style.object}} >
                    <img src="https://overlap.s3.ap-northeast-1.amazonaws.com/widget/LiveIcon/liveSpeechIcon1.png"/>
                </div>
            )
        case 'liveSquare1':
            return(
                <div className={"object "+self.props.widgetName+"_preset_"+preset} ref={self.widget} style={{...style.object}} >
                    <img src="https://overlap.s3.ap-northeast-1.amazonaws.com/widget/LiveIcon/liveSquare1.png"/>
                </div>
            )
        case 'liveSquare2':
            return(
                <div className={"object "+self.props.widgetName+"_preset_"+preset} ref={self.widget} style={{...style.object}} >
                    <img src="https://overlap.s3.ap-northeast-1.amazonaws.com/widget/LiveIcon/liveSquare2.png"/>
                </div>
            )
        default:
            return(
                <div className={"object unknownPreset"} ref={self.widget} style={{...style.object}} >
                    <p>알수없는 프리셋</p>
                </div>
                )
    }
}
export function data(self,style) {
    return preset(self.props.data.preset,self,self.props.data,style)
}

