// 스토어생성기, 미들웨어적용 모듈 임포트
import { createStore, applyMiddleware } from 'redux';

// 여기서 부터 미들웨어/데브툴 관련 임포트
// import { composeWithDevTools } from 'redux-devtools-extension';
// import logger from 'redux-logger';
// import thunk from 'redux-thunk';

const defaultState = {
    exemple :  false,
    passToken : null,
    selectTemplate : null,
    templateCilck : false,
    userState : {
        browser : null,
    },
    // 계정 관련 스토어
    account  : {
        nick : null,
        image : null, 
        message : null,
        mode : null, 
        socialAccount : null, 
    },
    
    // 스크린 관련
    screenNo :0,
    screenRefresh : false,
    screenListScroll:0,
    // 컨텍스트 메뉴 관련 
    contextMenu : {
        display : false,
        position : {
            x : 0,
            y : 0
        },
        traget : {
            type : null,
            id : null,
            no : null,
            self : null,
        }
    },
    // 클립보드 관련
    // clipBoard : {
    //     screen : null,
    //     object : null,
    // },
    // 되돌리기 관련
    // history : [],   
    // 저장 관련
    autoSave : true,
    lastModify : null,
    save : [],   
    // 화면 배율 관련 TODO : 미니뷰에서 사용함
    zoom : 200,
    broadCast: {
        size : {
            width : 1920,
            height : 1080,
        }, 
    },
    // 편집화면 관련 
    guideLine : null,
    mainScreen : 'Edit',
    editBackground : {
       mode : 'solid',
       source : 'white'
    },
    widgetShow : true,
    WidgetList : 
        {
            '기타' : 'TEXT'
        },
    screenData : [],
    editOverlay : [],
    //공용
    overlapData : null,
    //가이드 관련
    // editGuide : null

};

const reducer = (state = defaultState, action) => {
    switch (action.type) {
        case "showChange" :
         
            return {
                ...state,
                show : action.show,
            }
        case "selectTemplate" : 
            return {
                ...state,
                selectTemplate : action.select,
            }            
        case "templateClick" : 

            return {
                ...state,
                templateCilck : action.click,
            }     
        ////////////////////////////////////////////////////////
        // 계정정보 
        ////////////////////////////////////////////////////////
        case "setAccount" : 
        console.log(action,'확인중')
        return {
            ...state,
            account : {
                nick : action.nick??defaultState.account.nick,
                image : action.image??defaultState.account.image, 
                message : action.message??defaultState.account.message,
                mode : action.mode??defaultState.account.mode, 
                socialAccount : action.socialAccount??defaultState.account.socialAccount, 
            },
        }      
        // 스크린 변경
        case "editScreenChange" : 
            console.log(action,'확인중')
            return {
                ...state,
                screenNo : action.screenNo,
                screenData : action.screenData,
                selectTemplate : null,
            } 
        ////////////////////////////////////////////////////////
        // 스크린 관련 디스패치
        ////////////////////////////////////////////////////////
        // screenRefresh 스크린 list 를 다시 로딩합니다.
        case "screenRefresh" :
            return {
                ...state,
                screenRefresh : action.state,
            } 
        case "screenData" :
            return {
                ...state,
                screenData : action.data,
                tempHistory :{
                 data : action.data,
                },
                lastModify :new Date().getTime(),
            }
        case "screenListScroll" :
            return {
                ...state,
                screenListScroll : action.scroll,
            } 
        ////////////////////////////////////////////////////////
        // 컨텍스트 메뉴(오른쪽 클릭 메뉴) 관련 디스패치
        ////////////////////////////////////////////////////////
        case "screenContext" :
            return {
                ...state,
                contextMenu : {
                    display : action.display,
                    position : {
                        x : action.posX,
                        y : action.posY,
                    },
                    traget : {
                        type : action.traget,
                        id : action.id,
                        no : action.no,
                        self : action.self
                    }
                },
            } 
        ////////////////////////////////////////////////////////
        // 화면 배율 관련 디스패치
        ////////////////////////////////////////////////////////
        case "editScreenResize" : 
            return {
                ...state,
                editScreen: {
                    width : action.width,
                    height : action.height,
                    zoom : action.zoom,
                },
            } 
            
        ////////////////////////////////////////////////////////
        // 클립보드 관련 디스패치
        ////////////////////////////////////////////////////////
        case "clipBoardScreen" : 
            return {
                ...state,
                clipBoard : {
                    screen : action.screen,
                },
            } 
        case "clipBoardObject" : 
        return {
            ...state,
            clipBoard : {
                object : action.object,
            },
        } 
        ////////////////////////////////////////////////////////
        // 히스토리&오토세이브 관련 디스패치
        ////////////////////////////////////////////////////////
        case "history" : 
            return {
                ...state,
                history : action.history,
            } 
        case "autoSave" : 
            return {
                ...state,
                lastModify : action.value,
            } 
        ////////////////////////////////////////////////////////
        // 토큰 관련 디스패치
        ////////////////////////////////////////////////////////
        case "tokenSet" :

            return {
                ...state,
                passToken : action.token,
            } 
        case "tokenClear" :
            return {
                ...state,
                passToken : null,
            } 

        ////////////////////////////////////////////////////////
        // 편집 페이지
        ////////////////////////////////////////////////////////
        case "mainScreen" :
            return {
                ...state,
                mainScreen : action.mainScreen,
            } 
            
        ////////////////////////////////////////////////////////
        // 가이드 디스패치
        ////////////////////////////////////////////////////////
        // case "interactivityGuide" :
        //     return {
        //         ...state,
        //         editGuide : {
        //             top : action.top,
        //             left : action.left,
        //             guideType : action.guideType,
        //         }
        //     } 

        ////////////////////////////////////////////////////////
        // 가이드 디스패치
        ////////////////////////////////////////////////////////
        case "guideLine" :
            return {
                ...state,
                guideLine : action.line,
            } 
        case "editBackground" :
            return {
                ...state,
                editBackground :{
                    mode : action.mode,
                    source : action.source
                 },
            } 
        case "widgetShow" :
            return {
                ...state,
                widgetShow : action.state,
            } 
       
        ////////////////////////////////////////////////////////
        // 편집 오버랩 데이터 저장 v2
        ////////////////////////////////////////////////////////
        case "initOverlap" :
            console.log('오버랩 초기화')
            return {
                ...state,
                overlapData : action.overlap,
            } 
        ////////////////////////////////////////////////////////
        // 오버레이 디스패치
        ////////////////////////////////////////////////////////
        case "editOverlayChange" :
            return {
                ...state,
                editOverlay : action.overlay,
            } 
        ////////////////////////////////////////////////////////
        // 기타
        ////////////////////////////////////////////////////////
        case "userState" :
            return {
                ...state,
                userState : action.state,
            } 
        default:
            console.error("리듀스 에러",action);
            return state; 
        }
};

const store = createStore(reducer);
export default store;
