////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widgetEdit.scss"


// 컴포넌트 연결

import ColorModule from '../editModule/ColorModule'; // 색상 모듈
import RangeModule from '../editModule/RangeModule'; // 범위 모듈
import ImageButtonModule from '../editModule/ImageButtonModule';

// 서비스 연결
import * as Editor from 'service/edit/EditService'; 

// 이미지
import opacity_0 from 'assets/sidebar/other/opacity_0.png';
import opacity_1 from 'assets/sidebar/other/opacity_1.png';
import opacity_2 from 'assets/sidebar/other/opacity_2.png';
import opacity_3 from 'assets/sidebar/other/opacity_3.png';
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class OpacityEdit extends Component {
    
    state = {
        editorToggle : false
    }

    change = (name,value) =>{
        value = (value-100) *-1
        this.props.callBack('style',name,value)
    }
    
    render() { 

        let legend = '투명도'
        if (this.props.type){
            legend = '투명도'
        }
        let value=(this.props.data.style.opacity-100)*-1
        return (
            <section className='RadiusEdit'>
                {Editor.WidgetEditTitle(this,{title:'투명도',toggle:true,icon:'deblur'})}
                <div className={this.state.editorToggle?'editContainer containerOpen':'editContainer containerClose'}>
                    <article className="EditPart" >
                    <ImageButtonModule value={value} type ="opacity" data={[{val:100,image:opacity_3,text:'text',title:"100% 투명하게"},{val:66,image:opacity_2,title:"66% 투명하게"},{val:33,image:opacity_1,title:"33% 투명하게"},{val:0,image:opacity_0,title:"0% 투명하게"}]} title="투명도"  change={this.change}/>
                    {/* <RangeModule value={value} type ="opacity" valueView="%" min="0" max="100" title="투명도" change={this.change} /> */}
                    </article>
                </div>
            </section>
        );
    }
}

////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    // screenData : state.screenData,
})

function mapDispatchToProps(dispatch){
    return {
    //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(OpacityEdit);
  