////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component, Fragment , useState, useEffect} from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { useHistory,useLocation } from 'react-router-dom';
// 라이브러리 
import { useResizeDetector } from 'react-resize-detector';// 컴포넌트의 사이즈 감지
// [리덕스]스토어 연결
import store from "store";
// [프로바이더]
import EditProvider from 'service/provider/EditProvider';

// SASS&CSS 연결
// import "sass/edit.scss"



// 이미지 연결
import logoW from 'assets/logoW.svg';

// 컴포넌트 연결
import PeferencesArea from 'components/Edit/editPanel/preferences/editPreferences/EditPeferencesArea';//오버레이

// 사이드바 컴포넌트

// 서비스 연결

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
function PreferencesPanel({sizeDetector}) {


    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////
    //set

    // state
    // const [Ex, setEx] = useState(null);

    // rel 
    const { width, height, ref } = useResizeDetector();

   ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////
    useEffect(() => {
        // 여기에 컴포넌트가 마운트되었을 때 실행할 코드를 작성합니다.

        return () => {
        // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
        };
      }, []);

    // [useEffect] sizeDetector 엘리먼트의 사이즈를 측정합니다. 
    useEffect(() => {
      if(width!=undefined||height!=undefined){
        sizeDetector('PreferencesPanel',width,height)
      }
    }, [width,height]);

    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////
    return (
        <Fragment>
                <style jsx>{`${EditPageStyle}`}</style>
                <div id = "PreferencesPanel" className = "Neumorphism" ref={ref}>
                    <PeferencesArea/>
                </div>
        </Fragment>
    );

}

////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    // mainScreen : state.mainScreen,
})

function mapDispatchToProps(dispatch){
    return {
        // initOverlap : (overlapData) => dispatch({type:'initOverlap',overlap : overlapData}),
    }
}

  // export default EditPage;
export default connect(mapStateToProps,mapDispatchToProps)(PreferencesPanel);
  


  const EditPageStyle = `

  #PreferencesPanel {
    background-color: #3F3F3F;
    border-radius: 5px;
    flex : 1;
    overflow-y: hidden;
    transition: height 0.3s ease;
    position: relative;
    display: flex;
    flex-direction: column;
  }
  `;