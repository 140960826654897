

function preset(widgetType,data,style,option){


    const TempMessages =  []

    console.log('오버랩 채팅 프리셋',option.messages)


    const Messages =  option.messages

    const Preset =  data.preset

    let node = null;


    function stringToReadableColor(text) {
        // 텍스트를 해시 값으로 변환
        let hash = 0;
        for (let i = 0; i < text.length; i++) {
            hash = text.charCodeAt(i) + ((hash << 5) - hash);
        }
    
        // 해시를 사용해 HSL 값 생성
        const hue = hash % 360; // 색상(hue)은 0~360도
        const saturation = 50 + (hash % 50); // 채도(saturation)는 50~100% 사이로 제한
        const lightness = 50 + (hash % 20); // 밝기(lightness)는 50~70% 사이로 제한
    
        return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    }

    
    console.log('style.object',style.object);

    switch (Preset) {
        case 'simple':
            function chatNodeSimple (messages) {
                if(messages){
                    // 메시지가 있을때
                    return messages.map((chat, index) => (
                        <div className="chatNode" style={{borderRadius:style.object.borderRadius}} key={index}><b className="chatUser" style={{color:stringToReadableColor(chat.user)}}> {chat.user} : </b><span className="chatUser">{chat.message}</span></div>
                    ))
                }else{
                    // 메시지가 없을때
                }
            }
            node = chatNodeSimple(Messages)
            return(
                <div className={`object ${widgetType}_preset_${Preset}`}  style={{transition: style.object.transition ,width: style.object.width, height :style.object.height}} >
                    <div className="chatContainer">
                        {node.reverse()}
                        {/* <div className="chatNode" key={'[index]'}> <div className="user">유저</div> <div className="messages" >{'테스트 메시지'}</div></div>
                        <div className="chatNode" key={'[index1]'}>{'테스트'}: {'테스트 메시지'}</div> */}
                    </div>
                </div>
            )
        case 'color':
            function chatNodeColor (messages) {
                if(messages){
                    // 메시지가 있을때
                    return messages.map((chat, index) => (
                        <div className="chatNode" style={{borderRadius:style.object.borderRadius}} key={index}>{chat.user}: {chat.message}</div>
                    ))
                }else{
                    // 메시지가 없을때
                }
            }
            node = chatNodeColor(Messages)
            return(
                <div className={`object ${widgetType}_preset_${Preset}`}  style={{transition: style.object.transition ,width: style.object.width, height :style.object.height,}} >
                    <div className="chatContainer">
                        {node.reverse()}
                        {/* <div className="chatNode" key={'[index]'}> <div className="user">유저</div> <div className="messages" >{'테스트 메시지'}</div></div>
                        <div className="chatNode" key={'[index1]'}>{'테스트'}: {'테스트 메시지'}</div> */}
                    </div>
                </div>
            )
        case 'talk':
            function chatNodeTalk (messages) {
                if(messages){
                    // 메시지가 있을때
                    return messages.map((chat, index) => (
                        <div className="chatNode received" style={{}} key={index}>
                            
                            <p>{chat.user}: {chat.message}</p>
                            </div>
                    ))
                }else{
                    // 메시지가 없을때
                }
            }
            node = chatNodeTalk(Messages)
            return(
                <div className={`object ${widgetType}_preset_${Preset}`}  style={{transition: style.object.transition ,width: style.object.width, height :style.object.height,}} >
                    <div className="chatContainer">
                        {node.reverse()}
                        {/* <div className="chatNode" key={'[index]'}> <div className="user">유저</div> <div className="messages" >{'테스트 메시지'}</div></div>
                        <div className="chatNode" key={'[index1]'}>{'테스트'}: {'테스트 메시지'}</div> */}
                    </div>
                </div>
            )      
        default:
            return(
                <div className={"object unknownPreset"} style={{...style.object}} >
                    <p>알수없는 프리셋 {Preset} </p>
                </div >
            )
    }

}
/**
 * 
 * @param {*} widget 객체 데이터
 * @param {*} mergeStyle 합처진 스타일 {layout,object}
 * @returns 
 */
export function getPreset(widget,widgetType,mergeStyle,option={}) {
    return preset(widgetType,widget,mergeStyle,option)
}

