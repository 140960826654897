import React, { Component , Fragment } from 'react';
function preset(preset,self,data,style){
    let fullsize = data.size.height
    let width = data.size.width
    let profile = self.state.profile
    let dataList = []
    let dataField =''

    let opacity= Math.round(data.style.fillOpacity/100 * 255).toString(16)
    if (opacity.length==1){ opacity= 0+opacity }
    let main_fill = data.style.main_fill+opacity
    let sub_fill = data.style.sub_fill+opacity
    switch (preset) {
        case 'normal':
            let list = data.value.schedule.map((item,index) => (
                <Fragment>
                <div className="time" style={{'padding':'0px 10px','lineHeight':fullsize+'px','fontSize':fullsize/2.5+'px','backgroundColor':sub_fill,'color':data.style.sub_color}}>
                    {item.title}
                </div>
                <div className="name" style={{'lineHeight':fullsize+'px','fontSize':fullsize/2+'px'}}>
                    {item.text}
                </div>
                </Fragment>
            ))

            if(list.length==0){
                return(
                    <div className={"object "+self.props.widgetName+"_preset_"+preset} ref={self.widget} style={{...style.object,'backgroundColor':main_fill,'color':data.style.main_color}} >
                        <div className="time" style={{'padding':'0px 10px','lineHeight':fullsize+'px','fontSize':fullsize/2.5+'px','backgroundColor':sub_fill,'color':data.style.sub_color}}>
                            제목
                        </div>
                        <div className="name" style={{'lineHeight':fullsize+'px','fontSize':fullsize/2+'px'}}>
                            본문을 입력해주세요
                        </div>
                    </div>
                )
            }else {
                return(
                    <div className={"object "+self.props.widgetName+"_preset_"+preset} ref={self.widget} style={{...style.object,'backgroundColor':main_fill,'color':data.style.main_color}} >
                        {list[Math.round(self.state.polling/data.value.speed)%list.length]}
                    </div>
                )
            }

      
        default:
            return(
                <div className={"object unknownPreset"} ref={self.widget} style={{...style.object}} >
                    <p>알수없는 프리셋</p>
                </div>
                )
    }
}
export function data(self,style) {
    return preset(self.props.data.preset,self,self.props.data,style)
}

