////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widgetEdit.scss"


// 컴포넌트 연결
import MediaSourceModule from '../editModule/MediaSourceModule'; // 텍스트 모듈
import ColorModule from '../editModule/ColorModule'; // 색상 모듈
import SizeModule from '../editModule/SizeModule';
import EightyDirectionsModule from '../editModule/EightyDirectionsModule'; // 체크박스 모듈
import SwitchModule from '../editModule/SwitchModule'; // 체크박스 모듈

// 서비스 연결 
import * as Editor from 'service/edit/EditService'; 
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class ColorEdit extends Component {
    
    state = {
        raido : 'monochrome',
        editorToggle : true,
    }
    change = (name,value) =>{
        this.props.callBack('style',name,value)
    }
    valueChange = (name,value) =>{
        this.props.callBack('value',name,value)
    }

    guide = (e,type='test') =>{
        this.props.guideDispatch(e.target.offsetTop,null,type)
        e.stopPropagation()
    }

    radioChange = (e) => {
        this.setState({
            raido : e.target.value
        })
    }

    colorSelect = () =>{

    }

    colorChange = (num,value) =>{
        let fill ={
            type:'monochrome',
            value:"#000000"
        }
        switch (this.state.raido) {
            case 'monochrome':
                fill = value
                this.props.callBack('style','fill',fill)
                break;
            case 'gradation':
                if(typeof(this.props.data.style.fill)!='object'){fill.color = [] }
                fill.color[num] = value
                fill.type = 'gradation'
                // this.props.callBack('bgColor',colorInfo)
                break;
            case 'image':
                fill.color = value
                fill.type = 'image'
                break;
            default:
                break;
        }
        
        return 
    }

    // addColor = () =>{
    //     if (this.state.raido == 'gradation'){
    //         let fill ={
    //             type : 'gradation',
    //             // directions : this.props.value.bgColor.directions,
    //             color : []
    //         }
    //         if (typeof(this.props.data.style.fill)=='string'){
    //             console.log(this.props.data.style.fill)
    //             fill.color[0] = this.props.data.style.fill
    //             fill.color[1] = '#ffffff'
    //         }else {
    //             let temp = this.props.data.style.fill
    //             temp.push('#ffffff')
    //             fill.color = temp
    //         }
    //         this.props.callBack('style','fill',fill)
    //     }
    // }




    colorSelect = () =>{

        let list = <ColorModule value={this.props.data.style.fill} type ={0} title="색상1" change={this.colorChange}/>
        if(typeof(this.props.data.style.fill)=='object'){
            list = this.props.data.style.fill.map(
                (value,index) => (<ColorModule value={value} key={index} index={index} type={index} delete={true} title={'색상'+(index+1)} change={this.bgColorChange} deleteModule={this.colorDelete}/>)   
            );    
        }


        if(typeof(this.props.data.style.fill)=='object'){

        }
        
        switch (this.state.raido) {
            case 'monochrome':
                return (<ColorModule value={this.props.data.style.fill} type ="fill" title="배경색" change={this.colorChange} />)
            case 'gradation':
                return (
                    <Fragment>
                        <EightyDirectionsModule value={this.props.data.style.fill} type ="colorDirections" title="색 방향" change={this.colorChange} />
                        <div>
                        <button onClick={()=>this.addColor()}>색상추가</button>
                        </div>
                        {list}
                    </Fragment>
                )
            case 'image':
        
                return ('')  
            default:
                break;
        }
        
        return 
    }


    render() { 

        const Style = {
            label: {
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
            },
            textarea : {
                width: '100%',
                resize: 'none',
                boxSizing:'border-box',
            },
            mediaView : {
                width: '100%',
                MaxHeight : '500px',
            }

        }
        let legend = '색상'
        if (this.props.type){
            legend = '색상'
        }
        return (
            <section className='PositionEdit'>
                {Editor.WidgetEditTitle(this,{title:legend,toggle:true,icon:'palette'})}
 
                <div className={this.state.editorToggle?'editContainer containerOpen':'editContainer containerClose'}>
                    <article className="EditPart" >

                        <div onMouseEnter={(e)=>this.guide(e)}>
                            <ColorModule value={this.props.data.style.fill} type ="fill" title="색상" change={this.change}/>
                        </div>

                    </article>
                </div>
            </section>
        );

        return (
            
            <Fragment>
            <fieldset className="EditModuleField" style={Style.fieldset} >
                <legend style={Style.legend} > {legend} </legend>
                <div className="radioSelect rectangleSelect" >
                    <input type="radio" className={(this.state.raido == "monochrome"?'select':'')} id="SelectMonochrome" name="bgType" value="monochrome" onClick ={(e)=>this.radioChange(e)} />
                    <label htmlfor="SelectMonochrome">단색</label>
                    
                    {/* <input type="radio" className={(this.state.raido == "gradation"?'select':'')} id="SelectGradation" name="bgType" value="gradation" onClick ={(e)=>this.radioChange(e)} />
                    <label htmlfor="SelectGradation">그라데이션</label>

                    <input type="radio" className={(this.state.raido == "image"?'select':'')} id="SelectImage" name="bgType" value="image" onClick ={(e)=>this.radioChange(e)} />
                    <label htmlfor="SelectImage">이미지</label> */}
                </div>
                    {this.colorSelect()}
            </fieldset>
            </Fragment>
        );
    }
}

////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    // screenData : state.screenData,
})

function mapDispatchToProps(dispatch){
    return {
        guideDispatch : (top,left,guideType) => dispatch({type:'interactivityGuide',top:top,left:left,guideType:guideType}),
    //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(ColorEdit);
  