////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/edit.scss"


// 컴포넌트 연결

// 서비스 연결
// import * as DeCoder from '../../service/engine/DeCoder' ; 
// import * as Screen from '../../service/rest/screen' ; 
// import * as Widget from '../../service/event/widget' ; 
// 이미지 

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class InteractivityGuide extends Component {

    constructor(props) {
        super(props);
        this.myRef = React.createRef();
      }

      interactivity = (type) =>{
        let res 
        switch (type) {
            case 'test':
                res = (
                <Fragment>
                    {/* <img/> */}
                    <p>
                    인터렉티브 가이드 테스트중2
                    </p>
                </Fragment>
                )
                break;
            case 'widget_show':
                res = (
                <Fragment>
                    <img src="https://overlap.s3.ap-northeast-1.amazonaws.com/guide/interactivity/widget_show.gif"/>
                    <p>
                    숨긴 위젯을 표시합니다.<br/> 송출화면에서 보입니다.
                    </p>
                </Fragment>
                )
                break;
            case 'widget_hide':
                res = (
                <Fragment>
                    <img src="https://overlap.s3.ap-northeast-1.amazonaws.com/guide/interactivity/widget_hide.gif"/>
                    <p>
                    선택한 위젯을 숨깁니다.<br/> 송출화면에서 보이지 않습니다.
                    </p>
                </Fragment>
                )
                break;
            case 'widget_copy':
                res = (
                <Fragment>
                    {/* <img/> */}
                    <p>
                    선택한 위젯을 복사합니다.
                    </p>
                </Fragment>
                )
                break;        
            case 'widget_delete':
                res = (
                <Fragment>
                    {/* <img/> */}
                    <p>
                    선택한 위젯을 삭제합니다
                    </p>
                </Fragment>
                )
                break;
            case 'widget_noTargetPaste':
                res = (
                <Fragment>
                    {/* <img/> */}
                    <p>
                    붙여넣을 위젯을 먼저 복사해야 합니다.
                    </p>
                </Fragment>
                )
                break;
            case 'widget_paste':
                res = (
                <Fragment>
                    {/* <img/> */}
                    <p>
                    복사한 위젯을 현위치에 복사합니다.
                    </p>
                </Fragment>
                )
                break;
            case 'widget_fixingpaste':
                res = (
                <Fragment>
                    {/* <img/> */}
                    <p>
                    복사한 위젯을 복사한 위젯이 <br/>원레 있던 위치로 복사합니다.
                    </p>
                </Fragment>
                )
                break;
            case 'widget_indexFront':
                res = (
                <Fragment>
                    <img src="https://overlap.s3.ap-northeast-1.amazonaws.com/guide/interactivity/widget_indexFront.gif"/>
                    <p>
                    선택한 위젯을 맨 앞으로 가저옵니다.
                    </p>
                </Fragment>
                )
                break;
            case 'widget_indexBack':
                res = (
                <Fragment>
                    <img src="https://overlap.s3.ap-northeast-1.amazonaws.com/guide/interactivity/widget_indexBack.gif"/>
                    <p>
                    선택한 위젯을 맨 뒤으로 가저옵니다.
                    </p>
                </Fragment>
                )
                break;
            ////////////////////////////////////////////////////////
            // screen
            ////////////////////////////////////////////////////////
            case 'screen_undoDisabled':
                res = (
                <Fragment>
                    {/* <img src="https://overlap.s3.ap-northeast-1.amazonaws.com/guide/interactivity/widget_indexBack.gif"/> */}
                    <p>
                    되돌릴 행동이 없습니다.
                    </p>
                </Fragment>
                )
                break;
            case 'screen_undo':
                res = (
                <Fragment>
                    {/* <img src="https://overlap.s3.ap-northeast-1.amazonaws.com/guide/interactivity/widget_indexBack.gif"/> */}
                    <p>
                    지금 화면을 이전 상태로 되돌린다.
                    </p>
                </Fragment>
                )
                break;
            case 'screen_onair':
                res = (
                <Fragment>
                    {/* <img src="https://overlap.s3.ap-northeast-1.amazonaws.com/guide/interactivity/widget_indexBack.gif"/> */}
                    <p>
                    선택한 화면을 송출합니다.
                    </p>
                </Fragment>
                )
                break;
            case 'screen_onair_disable':
                    res = (
                    <Fragment>
                        {/* <img src="https://overlap.s3.ap-northeast-1.amazonaws.com/guide/interactivity/widget_indexBack.gif"/> */}
                        <p>
                        화면을 송출하기 위해서는 <br/> 상단에서 뷰를 선택해주세요
                        </p>
                    </Fragment>
                    )
                    break;
            case 'screen_copy':
                res = (
                <Fragment>
                    {/* <img src="https://overlap.s3.ap-northeast-1.amazonaws.com/guide/interactivity/widget_indexBack.gif"/> */}
                    <p>
                    선택한 화면을 복사합니다.
                    </p>
                </Fragment>
                )
                break;
            case 'screen_delete':
                res = (
                <Fragment>
                    {/* <img src="https://overlap.s3.ap-northeast-1.amazonaws.com/guide/interactivity/widget_indexBack.gif"/> */}
                    <p>
                    선택한 화면을 삭제합니다.
                    </p>
                </Fragment>
                )
                break;
            case 'screen_paste':
                res = (
                <Fragment>
                    {/* <img src="https://overlap.s3.ap-northeast-1.amazonaws.com/guide/interactivity/widget_indexBack.gif"/> */}
                    <p>
                    복사한 화면을 붙여넣습니다.
                    </p>
                </Fragment>
                )
                break;
            case 'screen_noTargetPaste':
                res = (
                <Fragment>
                    {/* <img src="https://overlap.s3.ap-northeast-1.amazonaws.com/guide/interactivity/widget_indexBack.gif"/> */}
                    <p>
                    붙여넣을 화면을 먼저 복사해야 합니다.
                    </p>
                </Fragment>
                )
                break;            
            case 'screen_indexFront':
                res = (
                <Fragment>
                    <img src="https://overlap.s3.ap-northeast-1.amazonaws.com/guide/interactivity/screen_indexFront.gif"/>
                    <p>
                    선택한 화면을 위로 이동합니다.
                    </p>
                </Fragment>
                )
                break;
            case 'screen_indexBack':
                res = (
                <Fragment>
                    <img src="https://overlap.s3.ap-northeast-1.amazonaws.com/guide/interactivity/screen_indexBack.gif"/>
                    <p>
                    선택한 화면을 아래로 이동합니다.
                    </p>
                </Fragment>
                )
                break;
            case 'screen_indexFrontOver':
                res = (
                <Fragment>
                    {/* <img src="https://overlap.s3.ap-northeast-1.amazonaws.com/guide/interactivity/screen_indexFront.gif"/> */}
                    <p>
                    선택한 화면이 맨 앞에 있어 이동할 수 없습니다.
                    </p>
                </Fragment>
                )
                break;
            default:
                res = null
                break;
        }
        return res
      }
      
      render() { 
        const LAYOUT = {
            position:'absolute',
            left:this.props.left+'px',
            top:this.props.top+'px',
            // zIndex: 999,
        }
        if(!this.props.type){
            return null
        }
        return (
            <div id="InteractivityGuide" style={LAYOUT}>
                {this.interactivity(this.props.type)}
            </div>
        );
    }
}
////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    contextMenu : state.contextMenu,
    clipBoard : state.clipBoard,
})

function mapDispatchToProps(dispatch){
    return {
        screenRefreshDispatch : (state) => dispatch({type:'screenRefresh',state : state}),
    }
}
  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(InteractivityGuide);
  