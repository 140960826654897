import React, { Component , Fragment } from 'react';

function preset(preset,data,style,self){
    let margin = data.style.strokeWidth/2
    let maxX = data.size.width - margin
    let maxY = data.size.height - margin

    let texture = ''


    switch (style.texture) {
        case 'glass':
            texture = 'glass_'
            break;
    
        default:
            break;
    }
    switch (texture+preset) {
            case 'triangle':
                return(
                <div className='object' ref={self.widget}>
                    <svg style={style.object} xmlns='http://www.w3.org/2000/svg' >
                        <path style={style.shapes} d={'M'+margin+' '+maxY+' L '+maxX/2+' '+margin+' L '+maxX+' '+maxY+' Z'}/>
                        {/* <path style={Style.shapes} d={'M0 '+maxY+' L'+maxX/2 +'0 L '+maxX+' '+maxY+' '}/> */}
                    </svg>
                    <svg className='line' style={style.line} xmlns='http://www.w3.org/2000/svg' >
                        <path fill='none' d={'M'+margin+' '+maxY+' L '+maxX/2+' '+margin+' L '+maxX+' '+maxY+' Z'}/>
                        {/* <path style={Style.shapes} d={'M0 '+maxY+' L'+maxX/2 +'0 L '+maxX+' '+maxY+' '}/> */}
                    </svg>
                </div>
                )
            case 'rightTriangle':
                return(
                <div className='object' ref={self.widget}>
                    <svg style={style.object} xmlns='http://www.w3.org/2000/svg' >
                        <path style={style.shapes} d={'M'+margin+' '+margin+' L'+margin+' '+maxY +' L '+maxX+' '+maxY+' Z'}/>
                    </svg>
                    <svg  className='line' style={style.line} xmlns='http://www.w3.org/2000/svg' >
                        <path fill='none' d={'M'+margin+' '+margin+' L'+margin+' '+maxY +' L '+maxX+' '+maxY+' Z'}/>
                    </svg>
                </div>
                )
            case 'quadrangle':
                return(
                <div className='object' ref={self.widget}>
                    <svg style={style.object} xmlns='http://www.w3.org/2000/svg' >
                        <path style={style.shapes}  d={'M'+margin+' '+margin+' L' + maxX+' '+margin+' L'+maxX+' '+ maxY  +' L'+margin+' '+maxY+' Z'}/>
                    </svg>
                    <svg  className='line' style={style.line} xmlns='http://www.w3.org/2000/svg' >
                        <path fill='none' d={'M'+margin+' '+margin+' L' + maxX+' '+margin+' L'+maxX+' '+ maxY  +' L'+margin+' '+maxY+' Z'}/>
                    </svg>
                </div>
                )
            case 'glass_quadrangle':
                return(
                <div className='object' ref={self.widget}>
                    <svg style={style.object} xmlns='http://www.w3.org/2000/svg' >
                        <path style={{...style.shapes,backdropFilter: 'blur( 4px )' }}  d={'M'+margin+' '+margin+' L' + maxX+' '+margin+' L'+maxX+' '+ maxY  +' L'+margin+' '+maxY+' Z'}/>
                    </svg>
                    <svg  className='line' style={style.line} xmlns='http://www.w3.org/2000/svg' >
                        <path fill='none' d={'M'+margin+' '+margin+' L' + maxX+' '+margin+' L'+maxX+' '+ maxY  +' L'+margin+' '+maxY+' Z'}/>
                    </svg>
                </div>
                )
            case 'hexagon':
                return(
                <div className='object' ref={self.widget}>
                    <svg style={style.object} xmlns='http://www.w3.org/2000/svg' >
                        <path style={style.shapes} d={'M'+margin+' '+maxY/2+' L'+maxX/4+' '+margin+' L'+maxX/4*3+' '+margin+' L'+maxX+' '+maxY/2+' L'+maxX/4*3+' '+maxY+' L'+maxX/4+' '+maxY+' Z'}/>
                    </svg>
                    <svg  className='line' style={style.line}  xmlns='http://www.w3.org/2000/svg' >
                        <path fill='none' d={'M'+margin+' '+maxY/2+' L'+maxX/4+' '+margin+' L'+maxX/4*3+' '+margin+' L'+maxX+' '+maxY/2+' L'+maxX/4*3+' '+maxY+' L'+maxX/4+' '+maxY+' Z'}/>
                    </svg>
                </div>
                )
            case 'circle':
                return(
                <div className='object' ref={self.widget}>
                    <svg style={style.object} xmlns='http://www.w3.org/2000/svg' >
                        <path style={style.shapes} 
                        d={'M '+maxX/2+', '+maxY/2+' m '+maxX/2*-1+', '+margin/2+' a '+maxX/2+','+maxY/2+' 0 1,0 '+maxX+',0 a '+maxX/2+','+maxY/2+' 0 1,0 '+maxX*-1+',0 '}/>
                    </svg>
                    <svg className='line' style={style.line}  xmlns='http://www.w3.org/2000/svg' >
                        <path fill='none' 
                        d={'M '+maxX/2+', '+maxY/2+' m '+maxX/2*-1+', '+margin/2+' a '+maxX/2+','+maxY/2+' 0 1,0 '+maxX+',0 a '+maxX/2+','+maxY/2+' 0 1,0 '+maxX*-1+',0 '}/>
                    </svg>
                </div>
                )  
            case 'star':
                const starPoints = (cx, cy, spikes, outerRadius, innerRadius) => {
                    let rot = Math.PI / 2 * 3;
                    let x = cx;
                    let y = cy;
                    let step = Math.PI / spikes;
                    let path = '';
    
                    for (let i = 0; i < spikes; i++) {
                        x = cx + Math.cos(rot) * outerRadius;
                        y = cy + Math.sin(rot) * outerRadius;
                        path += i === 0 ? 'M' + x + ',' + y : 'L' + x + ',' + y;
                        rot += step;
    
                        x = cx + Math.cos(rot) * innerRadius;
                        y = cy + Math.sin(rot) * innerRadius;
                        path += 'L' + x + ',' + y;
                        rot += step;
                    }
                    path += 'Z';
                    return path;
                };
    
                return (
                    <div className='object' ref={self.widget}>
                        <svg style={style.object} xmlns='http://www.w3.org/2000/svg' >
                            <path style={style.shapes} d={starPoints(maxX / 2, maxY / 2, 5, maxX / 2 - margin, maxX / 4 - margin)} />
                        </svg>
                        <svg className='line' style={style.line} xmlns='http://www.w3.org/2000/svg' >
                            <path fill='none' d={starPoints(maxX / 2, maxY / 2, 5, maxX / 2 - margin, maxX / 4 - margin)} />
                        </svg>
                    </div>
                );       
                case 'pentagon':
                    const pentagonPoints = (cx, cy, radius) => {
                        let angle = Math.PI / 2;
                        const step = (2 * Math.PI) / 5;
                        let path = `M${cx + radius * Math.cos(angle)},${cy - radius * Math.sin(angle)}`;
                        for (let i = 1; i < 5; i++) {
                            angle += step;
                            path += `L${cx + radius * Math.cos(angle)},${cy - radius * Math.sin(angle)}`;
                        }
                        path += 'Z';
                        return path;
                    };
        
                    return (
                        <div className='object' ref={self.widget}>
                            <svg style={style.object} xmlns='http://www.w3.org/2000/svg' >
                                <path style={style.shapes} d={pentagonPoints(maxX / 2, maxY / 2, maxX / 2 - margin)} />
                            </svg>
                            <svg className='line' style={style.line} xmlns='http://www.w3.org/2000/svg' >
                                <path fill='none' d={pentagonPoints(maxX / 2, maxY / 2, maxX / 2 - margin)} />
                            </svg>
                        </div>
                    );        
            default:
                return(
                    <div className={"object unknownPreset"} ref={self.widget} style={{...style.object}} >
                        <p>알수없는 프리셋</p>
                    </div>
                )
        }
}

export function data(data,style,self) {

   return preset(data.preset,data,style,self)
}
