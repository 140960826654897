////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";
// [프로바이더] 연결
import EditContext from 'service/context/EditContext' ; 

// SASS&CSS 연결
import "sass/edit.scss"


// 컴포넌트 연결

// 서비스 연결

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
function WidgetEditWrap(WidgetComponent) {
    return class extends React.Component {
      static contextType = EditContext;
      // 공통 로직 및 상태 관리 작성
      
      render() {
        return<div className='='>
 <WidgetComponent 
        context={this.context} />;
        </div>
      }
    };
  }
////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    selectTemplate : state.selectTemplate,
    screenData : state.screenData,
    editGuide : state.editGuide,
})

function mapDispatchToProps(dispatch){
    return {
        screenDataDispatch : (screenData) => dispatch({type:'screenData',data : screenData}),
    }
}

  // export default Login;
  export default WidgetEditWrap;
  