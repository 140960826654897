////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { useState, useEffect, useContext, Fragment } from 'react';


import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widgetEdit.scss"

// 컴포넌트 연결
import ImageButtonModule from '../editModule/ImageButtonModule'; // 이미지 버튼을 눌러 여러 옵션중 값을 정합니다.
import ErrorModule from '../editModule/ErrorModule'; // 잘못된 위젯의 값이 있을때 편집기를 대체합니다.
import SwitchModule from '../editModule/SwitchModule'; // 체크박스 모듈
import NumberModule from '../editModule/NumberModule'; // 텍스트 크기 모듈
// 서비스 연결
import * as Editor from 'service/edit/EditService'; 
import * as ED from 'service/engine/decoder/EditorDecoder'; 
import * as keyframe from 'service/model/widget/keyframe'; // 자주 사용되는 스타일 메이커 가저오기
// 이미지
import logoW from 'assets/logoW.svg';
import round_0 from 'assets/sidebar/other/round_0.png';
import round_1 from 'assets/sidebar/other/round_1.png';
import round_2 from 'assets/sidebar/other/round_2.png';
import round_3 from 'assets/sidebar/other/round_3.png';
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////


/** [에디터] 텍스트 편집 툴 입니다.
 * (대상위젯, 편집펑션, 편집할 객체 키, 타이틀 ,옵션)
* @param {widget} target : 편집할 대상 위젯
* @param {function} modify : 편집용 함수
* @param {{
* size: { width : number, height : number }
* }} keyName : 해당 에디터가 수정하는 값 입니다. value : {'textValue', 수정할 대상값,} 
* @param {{
* editTitle: string,textValue: string
* }} title : 편집기에 표시될 제목들 입니다.
* @param {{
* fixToggle : boolean 
* openToggle : boolean
* }} option : 추가 옵션입니다. fixToggle : 토글을 고정합니다. openToggle : 필드의 기본 상태를 열림으로 봅니다.
*/


function AnimationStartEditForm({target,modify,keyName,title,option}) {

/**
     * 위젯을 수정합니다.
     *  @param {bool} classify : 수정할 값의 분류를 적습니다.
     *  @param {bool} key : 수정할 키의 이름을 적습니다.
     *  @param {bool} value : 해당페이지가 선택한 뷰에서 방송중인지 확인합니다.
     */

    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////

    // set
    const uuid = target.uuid

    const {mountStart} = keyName ; 

    const mountStartValue =  ED.getValue(target,mountStart);
    
    // state
    const [Toggle, setToggle] = useState(option?.openToggle==undefined?true:option?.openToggle);
    const [OpenForm, setOpenForm] = useState(option?.openForm==undefined?true:option?.openForm);


    const [AnimationKeyFrame, setAnimationKeyFrame] = useState(mountStartValue?.keyFrame);
    const [AnimationDelay, setAnimationDelay] = useState(mountStartValue?.delay);
    const [AnimationTiming, serAnimationTiming] = useState(mountStartValue?.timing);

    // rel 
    

    ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////
    useEffect(() => {

        // 토글의 사용 여부 및 필드의 열림 상태를 정의합니다.

        // 여기에 컴포넌트가 마운트되었을 때 실행할 코드를 작성합니다.

        return () => {
        // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
        };
      }, []);

    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////



    const MaxRadius = Math.min(target.data.size.width,target.data.size.height)/2
    const AnimationList = keyframe.getReferenceAnimation(target,'mountStart')

    const GetList = () => {

        if(AnimationList){
            const AnimationNames = Object.keys(AnimationList);

            const AnimationValues = Object.values(AnimationList);
    
            const List = AnimationValues.map(
                (animation,index) => (
                    <div className='animationBox' onClick={()=>modifyAnimation(mountStart,AnimationNames[index])}>
                       
                        <div className='animationIcon'>
                            {Editor.icon({icon:animation?.info?.icon??'contact_support',size:25,lineHeight:30})}
                            </div>
                        <small>
                        {animation?.info?.nameKo??'알수없음'}
                        </small>
                    </div>    
                ) 
            );  
            
            return List;
        }else{
            return <samll> 사용 가능한 애니메이션이 없습니다. </samll>;
        }

    }

    /**
     * 애니메이션을 선택합니다.
     * @param {} value 
     */
    const modifyAnimation = (key_Name,value) => {
        const Reference = AnimationList[value];
        if(Reference&&value){
            modify(key_Name?.category,key_Name?.key,{keyFrame:value,timing:AnimationTiming??1000,delay:AnimationDelay??0},uuid)
        }
        else{
            modify(key_Name?.category,key_Name?.key,{keyFrame:null,timing:null,delay:null},uuid)
        }
    }


    console.log('ED.getValue(target,mountStart)',ED.getValue(target,mountStart))

    const KeyframeType = ED.getValue(target,mountStart)?.keyFrame;
    const MountStartKeyframe = keyframe.basic('mountStart')

    let selectKeyframe = {info : {name:'none',nameKo:'없음',icon:'stop'},}
    if(KeyframeType){
        selectKeyframe = MountStartKeyframe[KeyframeType];
    }


    return (
    <Fragment>
        <style jsx>{`${FormStyle}`}</style>
        <section className='AnimationStartEditForm'>
            {Editor.editorDetail('등장 효과','start',OpenForm,setOpenForm,Toggle)}
            <div className={OpenForm?'editContainer containerOpen':'editContainer containerClose'}>
                {/* {Editor.editorSubTitle('텍스트 설정',"edit_note")} */}

                {/* <div className="info" >
                        위젯이 처음 등장했을때 효과를 정의합니다.
                </div> */}

                <article className="EditPart" >
                  
                <div className='animationSelectArea'>
                    <p className='fromText'>사용중인 효과</p>
                    <div className='animationSelect'>
                        
                        <div className='animationSelectBox'>
                            {Editor.icon({icon:selectKeyframe?.info?.icon,size:25,lineHeight:40})}
                        </div>  
                        <div className='animationSelectInfo'>
                            <h3>
                            {selectKeyframe?.info?.nameKo}
                            </h3>
                 
                        </div>
                                       
                    </div>
                    </div>
                    <div className='animationBoxList'>
                    <div class="tailArea">
                    <div class="tail"/>
                    </div>
                    <div className="animationList" > 
                    <div className='animationBox' onClick={()=>modifyAnimation(mountStart,null)}>
                        <div className='animationIcon'>
                        {Editor.icon({icon:'stop',size:25,lineHeight:30})}
                        </div>

                    <small>
                    {'없음'}
                    </small>
                    </div>   
                    {GetList()}
                    </div>
                    {/* <NumberModule value={1} type ="point" title="타이밍" change={()=>{}}/> */}
                    </div>

                {/* {morphing? <SwitchModule value={ED.getValue(target,morphing)} title={morphing?.title??"모핑"} type ={morphing.key} change={(type,value)=>modify(morphing.category,type,value,uuid)}/> : ''} */}
                </article>
            </div>
        </section>
    </Fragment>
    );

}

export default AnimationStartEditForm;

const FormStyle = `
.AnimationStartEditForm .info{
    font-family: "프리텐다드SemiBold", sans-serif;
    border-radius: 5px;
    background: #333;
    font-size: 13px;
    margin: 10px 15px;
    width: calc(100% - 60px);
    padding: 15px;
}
.AnimationStartEditForm .info ul{
    margin : 5px 0px 0px 0px;
    padding-left : 20px;
}
.AnimationStartEditForm article.EditPart{
    padding: 5px 0px;
    width: calc(100%);
}

.animationSelectArea{
    width: calc(100% - 30px);
    padding : 0px 15px;
}

.animationSelect{
    width: calc(100% - 20px);
    height : 40px;
    display: flex;
    overflow: auto;
    justify-content: space-between;
    align-items: center;
    background: #333;
    padding : 10px;
    border-radius : 5px;
}
.animationSelectBox{
    width: 40px;
    height : 40px;
    border-radius: 5px;
    background: #444;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.animationSelectInfo{
    flex : 1;
    box-sizing: border-box;
    padding : 10px;
    height : 40px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
}

.animationBoxList .tailArea{
    margin-left : auto;
    width: 100%;
    height : 20px;
    display: flex;
    justify-content: center;
}
.animationBoxList .tailArea .tail{
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 0px 20px 20px 20px; /* 삼각형 모양 */
    border-color: transparent transparent #222 transparent; /* 아래쪽 삼각형 */
}

.animationBoxList .animationList{
    width: 100%;
    padding : 10px 20px;
    box-sizing: border-box;
    max-height : 200px;
    overflow : auto;
    background-color: #222;
    padding-bottom : 15px;
    position: relative;
    display: flex;
    font-size : 18px;
}
.animationIcon{
    width: 30px;
    height : 30px;
    margin : 5px;
    border-radius: 5px;
    margin-right : 10px;
    background: #444;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.animationBox{

    width: 100%;
    height : 50px;
    border-radius: 5px;
    background: #333;
    display: flex;
    justify-content: start;
    align-items: center;
    margin-bottom : 10px;

}
`;


