import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

function BlockNavigation() {
  const history = useHistory();

  useEffect(() => {
    // 뒤로 가기 이벤트 감지
    const unblock = history.block((location, action) => {
      if (action === 'POP') {
        // 뒤로 가기 시도 시
        return window.confirm('페이지에서 나가시겠습니까?');
      }
    });

    return () => {
      unblock();
    };
  }, [history]);

  return <div>내용</div>;
}
export default BlockNavigation;