////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, {useState, useEffect, useRef } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { Route, Link } from 'react-router-dom';
import { useHistory } from "react-router";
// 라이브러리 
import { useGoogleLogin } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google'// 구글 로그인
// 설정 파일
import * as config from 'config/OverlapConfig'

import { withRouter } from 'react-router-dom';

import * as apiAccount from 'service/api/apiAccount'

// 컴포넌트 연결
import ViewInfo from "components/Lobby/space/module/ViewInfo"
import OverlapInfo from "components/Lobby/space/module/OverlapInfo"
import GuideBalloon from 'components/common/form/GuideBalloon'; // 말풍선 가이드
import SwitchModule from 'components/Edit/editModule/SwitchModule'; // 체크박스 모듈
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import 'sass/login.scss'

// 이미지 연결
import logoW from 'assets/logoW.svg';
import template from 'assets/main/lobby/project/template.svg';

// 서비스 연결
import * as Editor from 'service/edit/EditService'; 
import * as overlap from 'service/api/overlap';
import * as Utility from 'service/other/Utility' ; 
import * as view from 'service/api/view';
import { utils } from 'sortablejs';
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

function OverlapCreate({spaceId,setMode}) {
    // const todos = useSelector(state => state.todos)
    // const dispatch = useDispatch()
    // const addTodo = text => dispatch(addTodo(text))


    let defaultOverlapData = {
      title : '',
      resolution : {width : 1920, height : 1080},
      private : false,
    }

    let defaultChannelData = {
      name : '',
      resolution : {width : 1920, height : 1080},
      private : false,
    }

    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////
    // state
    const [textValue, setTextValue] = useState('');
    const [resolutionValue, setResolutionValue] = useState('HD');
    const [overlapData, setOverlapData] = useState(defaultOverlapData);

    const [Resolution, setResolution] = useState({...defaultOverlapData.resolution});


    // 2단계 채널 정보 관련
    const [ChannelData, setChannelData] = useState(defaultChannelData);
    const [ChannelOption, setChannelOption] = useState('select');
    const [ChannelList, setChannelList] = useState([]);
    const [CreateStep, setCreateStep] = useState(0);
    const [ChannelSelect, setChannelSelect] = useState(null); // 선택한 채널의 uuid


    const [Flag_AutoMake, setFlag_AutoMake] = useState(true); // 채널 자동 생성


    // 선택한 채널의 uuid


    const [Direction, setDirection] = useState('horizontal'); //horizontal,vertical

    // rel 
    // const SpaceRef = useRef(null);
    ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////
      useEffect(() => {
        console.log(overlapData);
        // getChannel();

      }, []);

      useEffect(() => {
        // resolutionControl(Resolution.width,Resolution.height);
      }, [Resolution,Direction]);
    ////////////////////////////////////////////////////////
    // Fuction
    ////////////////////////////////////////////////////////


    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////


  //   const List = TargetTemplate?.content?.page.map((page,index)=>{return(
  //     <div className = {page.uuid == SelectPage? 'Pages SelectPage':'Pages' } key={page.uuid} onClick={()=>setSelectPage(page.uuid)} >

  //     <div className = "PagesDisplay">
  //       <StaticDisplay page={page} previewWidth={192} previewHeight={108} referenceWidth={TargetTemplate.meta.resolution.width} referenceHeight={TargetTemplate.meta.resolution.height} option={{align:'center'}} />

  //     </div>
  //     <div className = "PagesInfo">
  //         <p className = "PagesTitle" >{page.page_title??'페이지'}</p>
  //         <p className = "PagesSummary" >{page.page_summary??''}</p>
  //     </div>

  //   </div>
  //  )})

   // 해당 페이지는 오버랩 
    return(
     
      <div id="OverlapCreateOption">
          <style jsx>{`${SpaceModuleStyle}${ContainerStyle}`}</style>
        <div id="TemplateCreate"  onClick={()=>setMode('store')}>
          <div className="createImage">
            <img src={template}/>
          </div>
          <div className="createInfo">
            <h1>템플릿으로 시작하기 &nbsp; {Editor.icon({icon:'arrow_forward',size:50,lineHeight:60})}</h1>
            <p>미리 준비된 오버랩 프로젝트를 선택한 후 수정하여 사용합니다. 처음 사용자에게 추천하는 방법입니다.</p>
          </div>

        </div>
        <div id="NewCreate"  onClick={()=>setMode('append')}>
          <div className="createInfo">
            <h1>빈 화면으로 시작하기 &nbsp; {Editor.icon({icon:'arrow_forward',size:30,lineHeight:40})}</h1>
            <p>처음부터 오버랩 프로젝트를 만듭니다. 숙련된 사용자에게 추천하는 방법입니다.</p>
          </div>
          </div>
        

      </div>

    )


    return (
        <div id="overlapCreateScreen" className='overlapCreate SpaceWrap'>
            <style jsx>{`${SpaceModuleStyle}${ContainerStyle}`}</style>
            {/* <div id='createHeader'>
              <p>오버랩을 생성합니다</p>
            </div> */}

            <div id='createContent'>
    
            <div className='selectArea'>
            <GuideBalloon guideText={<p> 처음 사용자 추천 </p>}direction='top' >
              <div className='selectOption' onClick={()=>setMode('store')}>
           
              <div className='selectImage'>
                {Editor.icon({icon:'shop',size:130,lineHeight:180})}
                </div>
        
                <div className='selectTitle'>
                  템플릿으로 시작하기
                </div>
  
                <div className='selectInfo'>
                  <p>
                    완성된 오버랩 프로젝트를 수정합니다.
                  </p>
                </div>
   
              </div>
              </GuideBalloon>
              <GuideBalloon guideText={<p> 숙련된 사용자 추천 </p>}direction='top' >
              <div className='selectOption' onClick={()=>setMode('append')}>

                <div className='selectImage'>
                {Editor.icon({icon:'magnify_fullscreen',size:130,lineHeight:180})}
                </div>
                <div className='selectTitle'>
                  빈 프로젝트로 시작하기
                </div>
                <div className='selectInfo'>
                  <p>
                    처음부터 오버랩 프로젝트를 만듭니다.
                  </p>
                </div>
              </div>
              </GuideBalloon>
            </div>

            </div>

        </div>
    );
  }

  export default OverlapCreate;

  
  const SpaceModuleStyle = `
  .overlapCreate {
 
  }
  
  `;
  
  const ContainerStyle = `
  #OverlapCreateOption {
    display :flex;
    margin : 40px;
    flex-direction: column;
    width: calc( 100% - 80px );
    height: calc( 100% - 80px );
  }

  #OverlapCreateOption #TemplateCreate{
    display :flex;
    width: 100%; 
    margin-bottom : 40px;
    height: calc( 80% - 40px );
    background-color : #444;
    border-radius : 5px;
    overflow: hidden;
    position: relative;
    transition: transform 1s;
    animation: OverlapCreateOptionPopOut 1s 1;
    
  }
  #OverlapCreateOption #NewCreate{
    display :flex;
    width: 100%; 
    height: 20%;
    background-color : #444;
    overflow: hidden;
    border-radius : 5px;
    transition: transform 1s;
    animation: OverlapCreateOptionPopOut 1s 1;
    animation-delay: 1s;
    animation-fill-mode:backwards;

  }
  #OverlapCreateOption #NewCreate:hover{
    transform: scale(1.03);
  }
  #OverlapCreateOption #TemplateCreate:hover{
    transform: scale(1.03);
  }
  #OverlapCreateOption .createImage{
    display :flex;
    width: 100%; 
    height: 100%;
    position: absolute;
    z-index : 1;
  }
  #OverlapCreateOption .createImage img{
    display :flex;
    width: 100%; 
    height: 100%;
    object-fit : cover;
  }
  #OverlapCreateOption #TemplateCreate .createInfo{
    position: absolute;
    z-index : 2;
    display :flex;
    flex-direction: column;
    width: 100%; 
    height: 30%;
    padding : 0px 50px;
    justify-content: center;
    box-sizing: border-box;
    bottom : 0px;
    background: rgba( 0, 0, 0, 0.5 );
    backdrop-filter: blur( 6px );
    -webkit-backdrop-filter: blur( 6px );
  }

  #OverlapCreateOption #NewCreate .createInfo{
    display :flex;
    flex-direction: column;
    justify-content: center;
    padding : 0px 50px;
    width: 100%; 
    height: 100%;
    box-sizing: border-box;
  }
  #OverlapCreateOption .createInfo h1{
    font-size : 50px;
    margin : 0;
    display :flex;
  }
  #OverlapCreateOption #NewCreate h1{
    font-size : 30px;
  }

  #OverlapCreateOption .createInfo p{
    font-size : 20px
    margin : 0;
  }


  @keyframes OverlapCreateOptionPopOut {
    0% {
      transform: scale(0.5);      /* Start at normal size */
      opacity: 0;
    }
    80% {
      transform: scale(1.05);    /* Scale up to a larger size */
      opacity: 1;
    }
    100% {
      transform: scale(1.0);    /* Scale up to a larger size */
      opacity: 1;
    }
  }

  
  `;