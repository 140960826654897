////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { Route, Link } from 'react-router-dom';
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/edit.scss"


// 컴포넌트 연결
// import Text from './template/Text'; // 메뉴 헤더



// 서비스 연결
import * as DeCoder from 'service/engine/DeCoder' ; 
import * as Rest from 'service/api/rest' ; 
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class Main extends Component {
    
    state = {

    }

    constructor(props) {
        super(props);
        this.videoRef = React.createRef();
      }

    startVideo = async () => {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        if (this.videoRef && this.videoRef.current && !this.videoRef.current.srcObject) {
            this.videoRef.current.srcObject = stream;
        }
      };
    
    render() { 
        this.startVideo()
        return (
            <div id = "TempPage" >

                <video autoPlay ref={this.videoRef} />
                {/* <button onClick={this.startVideo()}>start</button> */}
            </div>
        );
    }
}
////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    passToken : state.passToken,
})

function mapDispatchToProps(dispatch){
    return {
    //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(Main);
  