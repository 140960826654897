////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widgetEdit.scss"


// 컴포넌트 연결
import ColorModule from '../editModule/ColorModule'; // 색상 모듈
import RangeModule from '../editModule/RangeModule'; // 색상 모듈

// 서비스 연결
import * as Editor from 'service/edit/EditService'; 
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class TextShadowEdit extends Component {
    
    state = {
        editorToggle : false
    }

    change = (name,value) =>{
        this.props.callBack('style',name,value)
    }

    render() { 

        const Style = {
            label: {
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
            },
            textarea : {
                width: '100%',
                resize: 'none',
                boxSizing:'border-box',
            },
            mediaView : {
                width: '100%',
                MaxHeight : '500px',
            }

        }
        let legend = '그림자'
        if (this.props.type){
            legend = '그림자'
        }


        return (
            <section className='TextShadowEdit'>
                {Editor.WidgetEditTitle(this,{title:'그림자',toggle:true,icon:'flip_to_front'})}
                <div className={this.state.editorToggle?'editContainer containerOpen':'editContainer containerClose'}>
                <article className="EditPart" >
                <RangeModule value={this.props.data.style.textShadowValue} type ="textShadowValue" min="0" max="10" title="그림자퍼짐" change={this.change} />
                    <ColorModule value={this.props.data.style.textShadowColor} type ="textShadowColor" title="그림자색상" change={this.change}/>
                </article>
                </div>
            </section>
        );
    }
}

////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    // screenData : state.screenData,
})

function mapDispatchToProps(dispatch){
    return {
    //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(TextShadowEdit);
  