////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결

// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widget.scss"

// [프로바이더] 연결
import EditContext from 'service/context/EditContext' ; 

// 컴포넌트 연결
import WidgetOverRay from '../Edit/editController/WidgetOverRay'; // 메뉴 헤더

// 서비스 연결
import * as Event from '../../service/event/Actions' ; 
import * as Widget from '../../service/event/widget' ; 
import * as Model from '../../service/value/Model' ; 
import * as EnCoder from '../../service/engine/EnCoder' ; 
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class Video extends Component {
    
    static defaultProps = {
        widgetName: 'Video',
      }


    //[임시] 컨텍스트 연결
    static contextType = EditContext;

    state = {
        ...Widget.DefaultState(this),
        moveStartX : 0,
        moveStartY : 0,
    }

    constructor(props) {
        super(props);
        this.widget = React.createRef();
    }
    
    DefaultMethod = (type,value) =>{
        Widget.DefaultMethod(this,type,value)
    }

    componentDidMount() {
        switch(this.props.parents){
            // 방송중
            case 'broadcast' : 
            setTimeout(  this.setState({
                viedoPlay : true, 
            },console.log('테스트')), 3000);
          
            break
            // 편집페이지
            case 'edit' : 

            break
            // 미리보기 페이지
            case 'view' :           

            break
            default :

            break
        }
    }

    // 컨텍스트 메뉴와 연결됨
    viedoPlay = (play)=>{
        if (play){
            this.setState({
                viedoPlay : true,    
            })
        }else {
            this.setState({
                viedoPlay : false,    
            })       
        }
    }

    viedo = (view) =>{
        let autoplay = false
        // 보는 뷰에 따라 실행여부를 설정합니다
        switch (view){
            case 'broadcast' :
                autoplay = true
                break;
            case 'edit' :
                autoplay = this.state.viedoPlay
                break;
            case 'view' :
                autoplay = false
                break;
        }
        let viedoURL = ''

        let startTime = this.props.data.value.startTime
        let endTime = this.props.data.value.startTime
        let rowURL = this.props.data.value.mediaSource
        console.log('&start='+this.props.data.value.startTime,'this.props.startTime')
        switch (this.props.data.value.mode){
        //////////////////////////////////
        // 유튜브 영상 지원
        //////////////////////////////////
            case 'youtube' :
                //영상 링크 종류에 따라 보정해주는 구문
                if (rowURL.indexOf('https://youtu.be/')==0) {
                    // 유튜브 1 
                    viedoURL = rowURL.replace('https://youtu.be/', '')
                }
                else if (rowURL.indexOf('https://www.youtube.com/watch?v=')==0) {
                    // 유튜브 2 
                    viedoURL = rowURL.replace('https://www.youtube.com/watch?v=', '')
                }
                // 영상의 시간 제어
                let timeControl = ''
                if(startTime!=0){
                    timeControl = timeControl+'&start='+startTime
                }
                if(endTime!=0){
                    timeControl = timeControl+'&end='+endTime
                }
                console.log(timeControl,'timeControl')
                return <iframe 
                        width={this.props.data.size.width} 
                        height={this.props.data.size.height} 
                        src={'https://www.youtube.com/embed/'+viedoURL+'?autoplay='+(autoplay?1:0)+timeControl+'&rel=0&fs=0'} 
                        frameborder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                        allowfullscreen/>
            //////////////////////////////////
            // 트위치 영상 지원
            //////////////////////////////////          
            case 'twitch' :
                if (rowURL.indexOf('https://www.twitch.tv/videos/')==0  ) {
                    viedoURL = rowURL.replace('https://www.twitch.tv/videos/', '')
                }
                return <iframe 
                        width={this.props.data.size.width} 
                        height={this.props.data.size.height} 
                        src={'https://player.twitch.tv/?video='+viedoURL+'&parent=localhost&parent=spark-obs.web.app&muted=false&autoplay='+this.state.viedoPlay}
                        frameborder="0" 
                        allowfullscreen="false" 
                        scrolling="no"/>
            case 'twitchClip' :
                if (rowURL.indexOf('https://www.twitch.tv/')==0&&rowURL.indexOf('/clip/')!=-1) {
                    viedoURL = rowURL.slice(rowURL.indexOf('/clip/')+6)
                }
                return <iframe 
                        width={this.props.data.size.width} 
                        height={this.props.data.size.height} 
                        src={'https://clips.twitch.tv/embed?clip='+viedoURL+'&parent=localhost&parent=spark-obs.web.app&muted=false&autoplay='+this.state.viedoPlay}
                        autoplay="true"
                        frameborder="0" 
                        allowfullscreen="false" 
                        scrolling="no"/>
            case 'twitchLive' :
                if (rowURL.indexOf('https://www.twitch.tv/')==0 ) {
                    viedoURL = rowURL.replace('https://www.twitch.tv/', '')
                }
                return <iframe 
                        width={this.props.data.size.width} 
                        height={this.props.data.size.height} 
                        src={'https://player.twitch.tv/?channel='+viedoURL+'&parent=localhost&parent=spark-obs.web.app&muted=false&autoplay='+this.state.viedoPlay}
                        frameborder="0" 
                        allowfullscreen="false" 
                        scrolling="no"/>
            //////////////////////////////////
            // 로컬 영상 지원
            //////////////////////////////////     
            case 'local' :
                viedoURL = rowURL
                return <video
                        width={this.props.data.size.width} 
                        height={this.props.data.size.height} 
                        src={viedoURL}
                        autoplay />
            case 'link' :
                viedoURL = rowURL
                return <video
                        width={this.props.data.size.width} 
                        height={this.props.data.size.height} 
                        src={viedoURL}
                        autoplay />
            default :
                return <p> 잘못된 영상 </p>
        }
    }
    
    render() {

        let mediaSource= 'https://overlap.s3-ap-northeast-1.amazonaws.com/widget/Example/image.png'
        if (this.props.data.value.mediaSource) {
            mediaSource = this.props.data.value.mediaSource
        }

        const Style = this.props.getStyle(this.props.data)

        const Height =this.props.data?.size?.height;
        const Width = this.props.data?.size?.width;
        const MinSize = Width<Height?Width:Height;
        // 드레그를 통한 위젯 이동
        Style.layout= { ...Style.layout,transform: 'translate('+this.state.dragMoveX+'px, '+this.state.dragMoveY+'px)'}
        switch (this.props.parents) {

            case 'edit':
                // 편집시 화면이동 트렌스레이션 효과 방지
                Style.layout.transition = false
                Style.object.transition = false
                if(this.props.userState.browser== "firefox"&&"safari"){
                    return (         
                            <div ref={(ref) => this.object=ref } className = {this.props.parents + " widgetObject "+this.props.widgetName+Widget.display(this.props)} style={Style.layout} tabIndex={0} draggable="true" 
                                onKeyDown={(e)=>Widget.keyDown(e,this)}
                                onClick={(e)=> Widget.isfocus(e,this)} 
                                onMouseDown={(e)=> Widget.mouseControl(e,this)} 	
                                //드레그 이벤트	
                                onDragOver={(e)=>Widget.alt_drag(e,this)} 
                                onDragStart={(e) => Widget.alt_dragStart(e,this)} 
                                onDragEnd={(e) => Widget.alt_dragEnd(e,this)} 
                                onDrag={(e)=>e.stopPropagation()} 
                                //드랍 이벤트 
                                onDrop={(e)=> Widget.drop(e,this)} 
                                onDragLeave={(e)=> Widget.dragLeave(e,this)} 
                                onDragEnter={(e)=> Widget.dragEnter(e,this)} 
                            >
                                <div className="object" style={Style.object} ref={this.widget} > {this.viedo(this.props.parents)}</div>
                                {/* <div className='overRay'/> */}
                                { this.props.focus ? <WidgetOverRay target={this.props} object={this.widget.current} mode={'8_Direction'} dragLock={this.state.dragLock} DefaultMethod={this.DefaultMethod}/> :"" } 
                            </div>
                    );
                }else{
                    return (         
                        <div ref={(ref) => this.object=ref } className = {this.props.parents + " widgetObject "+this.props.widgetName+Widget.display(this.props)} style={Style.layout} tabIndex={0} draggable="true" 
                            onKeyDown={(e)=>Widget.keyDown(e,this)}
                            onClick={(e)=> Widget.isfocus(e,this)} 
                            onMouseDown={(e)=> Widget.mouseControl(e,this)} 	
                            //드레그 이벤트
                            onDrag={(e)=>Widget.drag(e,this)} 
                            onDragStart={(e) => Widget.dragStart(e,this)} 
                            onDragEnd={(e) => Widget.dragEnd(e,this)} 
                            //드랍 이벤트 
                            onDrop={(e)=> Widget.drop(e,this)} 
                            onDragOver={(e)=> Widget.dragOver(e,this)} 
                            onDragLeave={(e)=> Widget.dragLeave(e,this)} 
                            onDragEnter={(e)=> Widget.dragEnter(e,this)} 
                        >
                            <div className="object" style={Style.object} ref={this.widget} > {this.viedo(this.props.parents)}</div>
                            {/* <div className='overRay'/> */}
                            { this.props.focus ? <WidgetOverRay target={this.props} object={this.widget.current} mode={'8_Direction'}/> :"" } 
                        </div>
                    );
                }

            case 'broadcast':
                return (
                    <div ref={(ref) => this.object=ref } className = {this.props.parents + " widgetObject "+this.props.widgetName+Widget.display(this.props)} style={Style.layout} >
                        <div style={Style.object} > {this.viedo(this.props.parents)}</div>
                    </div>
                );
          
            case 'view':
                return (
                    <div ref={(ref) => this.object=ref } className = {this.props.parents + " widgetObject "+this.props.widgetName+Widget.display(this.props)} style={Style.layout} >
                        <div style={Style.object} > {this.viedo(this.props.parents)}</div>
                    </div>
                );
        }

    }
}
////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    userState : state.userState,
})

function mapDispatchToProps(dispatch){
    return {
        //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}


  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(Video);
  