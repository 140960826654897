////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { useState, useEffect, useContext, Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { useHistory,useLocation } from 'react-router-dom';
// 라이브러리 
import { useResizeDetector } from 'react-resize-detector';// 컴포넌트의 사이즈 감지
// [리덕스]스토어 연결
import store from "store";
// [프로바이더]
import EditContext from 'service/context/EditContext' ; 

// SASS&CSS 연결
// import "sass/edit.scss"

// 이미지 연결
import logoW from 'assets/logoW.svg';

// 컴포넌트 연결
import WidgetListPanel from 'components/Edit/editPanel/preferences/utilPreferences/WidgetListPanel';//위젯 목록 패널
import ChannelPanel from 'components/Edit/editPanel/preferences/utilPreferences/ChannelPanel';//채널 패널
import GuideBalloon from 'components/common/form/GuideBalloon'
// 서비스 연결
import * as Editor from 'service/edit/EditService'; 
import DebugPanel from './utilPreferences/DebugPanel';
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
function UtilityPanel({sizeDetector}) {


    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////
    //set
    const context = useContext(EditContext);
    // state
    const [PanelState, setPanelState] = useState('widget');
    const [InitMessage, setInitMessage] = useState(true);
    const [Channel, setChannel] = useState(true);
    
    // rel 
    const { width, height, ref } = useResizeDetector();

   ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////
    useEffect(() => {
        // 여기에 컴포넌트가 마운트되었을 때 실행할 코드를 작성합니다.

        return () => {
        // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
        };
      }, []);

    // [useEffect] sizeDetector 엘리먼트의 사이즈를 측정합니다. 
    useEffect(() => {
      if(width!=undefined||height!=undefined){
        sizeDetector('UtilityPanel',width,height)
      }
    }, [width,height]);



    useEffect(() => {
      context.hideContext();
      if(context.panel.utility.state=='channel'){
        setChannel(false)
      }
    }, [context.panel.utility.state]);


    const getChannelIcon = () => {

      let option = null

      // 연결된 채널이 없는경우
      if(context?.view == null){
        option = 'unLink';
      }
      else if(context?.view?.view_pointer.length == 0){
        option = 'stop';
      }
      else if(context?.view?.view_pointer.length != 0){
        option = 'play';
        if(context?.view?.view_status?.status=='pause'){
          option = 'pause';
        }
      }

      
      switch (option) {

        case 'play':   
        return(
          <GuideBalloon guideText={<p style={{fontSize: 14}}>연결된 송출중인 채널을 설정 합니다.<br/><small>해당 위젯이 없는 다른 페이지 송출시 초기화 됩니다.</small> </p>}  direction='topleft'>
      
            <div className='channelIcon'>
              <svg width="25" height="25" viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
              <circle cx="12.5" cy="12.5" r="8" fill="red" stroke="#fff" stroke-width="3" clip-path="url(#clip)"></circle>
              </svg>
              {Editor.icon({icon:'play_arrow',size:20,lineHeight:30})}
            </div>
 
          </GuideBalloon>
        )

        case 'pause':   
        return(
          <GuideBalloon guideText={<p style={{fontSize: 14}}>채널이 일시 정지 되었습니다.<br/><small>여기를 눌러서 채널 상태를 관리 할 수 있습니다.</small> </p>}  direction='topleft'>
            <div className='channelIcon'>
              <svg width="25" height="25" viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
              <circle cx="12.5" cy="12.5" r="8" fill="red" stroke="#fff" stroke-width="3" clip-path="url(#clip)"></circle>
              </svg>
              {Editor.icon({icon:'pause',size:20,lineHeight:30})}
            </div>
          </GuideBalloon>
        )

        case 'stop':   
        return(
          <GuideBalloon 
          width={250}
          guideText={<p style={{fontSize: 14}}>채널에 송출중인 페이지가 없습니다.<br/>


            페이지 옆에 
            <span style={{margin:'5px 5px', width: 14, height: 14,display:'inline-flex',alignItems:'center',justifyContent:'center'}}>
            <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
              <circle cx="7" cy="7" r="6" fill="red" stroke="#fff" stroke-width="2"></circle>
          </svg>
            </span>
            
       
          버튼을 눌러서 설정해보세요
               </p>}  direction='topleft'>
            <div className='channelIcon'>
              <svg width="25" height="25" viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
              <circle cx="12.5" cy="12.5" r="8" fill="gray" stroke="#fff" stroke-width="3" clip-path="url(#clip)"></circle>
              </svg>
              {Editor.icon({icon:'stop',size:20,lineHeight:30})}
            </div>
          </GuideBalloon>
        )
        
        case 'unLink':
          
        return(
          <GuideBalloon guideText={<p style={{fontSize: 14}}>채널 연결이 필요합니다.<br/><small>오버랩과 연결된 채널이 없습니다.<br/> 여기를 눌러 채널과 연결하세요 </small> </p>} active={InitMessage}  direction='topleft'>
            <div className='channelIcon'>
              <svg width="25" height="25" viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
              <circle cx="12.5" cy="12.5" r="8" fill="gray" stroke="#fff" stroke-width="3" clip-path="url(#clip)"></circle>
              </svg>
              {Editor.icon({icon:'wifi_off',size:20,lineHeight:30})}
            </div>
          </GuideBalloon>
        )
      
        default:
          return(
            <GuideBalloon guideText={<p style={{fontSize: 14}}>연결된 채널을 설정 합니다.<br/><small>해당 위젯이 없는 다른 페이지 송출시 초기화 됩니다.</small> </p>}  direction='topleft'>
            <div className='channelIcon'>
              <svg width="25" height="25" viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
              <circle cx="12.5" cy="12.5" r="8" fill="gray" stroke="#fff" stroke-width="3" clip-path="url(#clip)"></circle>
              </svg>
              {Editor.icon({icon:'warning',size:20,lineHeight:30})}
            </div>
          </GuideBalloon>
          )
      }

    }


    let panel = null

    switch (context.panel.utility.state) {
      case 'widget':
        panel = <WidgetListPanel/>;
        break;

      case 'channel':
        panel = <ChannelPanel panelSize={{width,height}}/>;
        break;

      case 'media':
        panel = <div style={{display:'flex',justifyContent:'center', alignItems:'center', width:'100%', height: '100%'}}>
          컨트롤 가능한 미디어가 없습니다.
        </div>
        break;
      case 'debug':
        panel = <DebugPanel/>;
      default:
        break;
    }

  
    const handleMouseEnter = () => {
      if(InitMessage){
        setInitMessage(false);
      }

  };

    const SelectView =  context.view?true:false;

    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////


      return (
        // 연결된 채널이 있는경우
        <Fragment>
                <style jsx>{`${EditPageStyle}`}</style>
                <div id = "UtilityPanel" ref={ref}>
                    <ul id = "UtilityNav"  onMouseEnter={handleMouseEnter}>
                     
                      <li id="widgetListPanel" className={context.panel.utility.state == 'channel' ?'selectOptions channelOptions' : 'channelOptions unSelectOptions'} onClick={()=>context.panelControl('utility','channel')}>  
                        {getChannelIcon('unLink')}
                      </li>
        
                      <li id="widgetListPanel" className={context.panel.utility.state == 'widget' ?'selectOptions utilityOptions' : 'utilityOptions unSelectOptions'} onClick={()=>context.panelControl('utility','widget')}> 
                      <GuideBalloon guideText={<p style={{fontSize: 14}}>페이지의 위젯 목록을 봅니다.<br/><small>위젯의 순서와 상태를 설정 할 수 있습니다.  </small> </p>}  direction='topleft'>
                          {Editor.icon({icon:'list',size:25,lineHeight:30})}
                          </GuideBalloon>
                      </li>
  
                      <li id="widgetListPanel" className={context.panel.utility.state == 'media' ?'selectOptions utilityOptions' : 'utilityOptions unSelectOptions'} onClick={()=>context.panelControl('utility','media')}> 
                      <GuideBalloon guideText={<p style={{fontSize: 14}}>페이지내 미디어를 컨트롤 합니다.<br/><small>  </small> </p>}  direction='topleft'>
                          {Editor.icon({icon:'stock_media',size:20,lineHeight:30})}
                          </GuideBalloon>
                      </li>

                      <li className={context.panel.utility.state == 'widget' ?'selectOptions utilityOptions' : 'utilityOptions'} onClick={()=>context.panelControl('utility','debug')}> 
                          {Editor.icon({icon:'bug_report',size:20,lineHeight:30})}
                      </li>
                      
                    </ul>
                    <div className = "contentsDivision">
                    <div className='contentsDivisionCover'/>
                        {panel}
                    </div>
                 
                </div>
        </Fragment>
    );
    


    

}

////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    // mainScreen : state.mainScreen,
})

function mapDispatchToProps(dispatch){
    return {
        // initOverlap : (overlapData) => dispatch({type:'initOverlap',overlap : overlapData}),
    }
}

  // export default EditPage;
export default connect(mapStateToProps,mapDispatchToProps)(UtilityPanel);
  


  const EditPageStyle = `

  #UtilityPanel {
    background-color: #2C2C2C;
    border-radius: 5px;
    height: 240px;
    transition: height 0.3s ease;
    display: flex;
    flex-direction: column;
  }

  #UtilityPanel #UtilityNav{
    height: 40px;
    background-color: #3F3F3F;
    border-radius: 5px 5px 0px 0px;
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    justify-content: flex-start;
  }

  #UtilityPanel .channelOptions{
    color : rgb(173, 173, 173);
    background : #222;
    border-radius : 50px;
    height: 30px;
    width: 70px;
    margin: 5px 10px;
    padding: 0;
    list-style-type: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #UtilityPanel .unSelectOptions .icon{
    opacity: 0.4;
  }
  #UtilityPanel .unSelectOptions .channelIcon{
    opacity: 0.4;
  }
    
  #UtilityPanel .channelOptions.unSelectOptions{
    background-color: #303030;
    border-radius : 50px;
  }

  #UtilityPanel .channelOptions.selectOptions{
    background-color: #222;
    border-radius : 50px;
  }
  #UtilityPanel .utilityOptions{
    color : rgb(173, 173, 173);
    width: 50px;
    margin: 0px;
    padding: 0;
    list-style-type: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #UtilityPanel .selectOptions{
    color : rgb(255, 255, 255);

    background-color: #3F3F3F;
    border-radius: 5px 5px 0px 0px;

  }
  #UtilityPanel .selectOptions .ListTitle{
    box-sizing: border-box;
    padding: 0px 10px;
  }

  #UtilityPanel .contentsDivision{
    width: 100%;
    height: 200px;
    position: relative;
  }
  #UtilityPanel .contentsDivisionCover{
    display: flex;
    width: 100%;
    height: 10px;
    position: absolute;
    background: linear-gradient(to top, rgba(44,44,44,0), rgba(44,44,44,1));

  }
  

  #UtilityPanel input {
    margin: 0;
    padding: 0;
    border: 0;
    outline: none;
    background: none;
    box-shadow: none;
    -webkit-appearance: none; /* Chrome, Safari, Opera */
    -moz-appearance: none; /* Firefox */
    appearance: none; /* Modern browsers */
  }
  #UtilityPanel .channelStateIcon{
    position: absolute;
    bottom : 0;
    right : 0;
    width: 25px;
    height: 25px;
    padding-top : 1px;
    justify-content: end;
    align-items: end;
    display:flex;

  }

  #UtilityPanel .channelIcon {
    display:flex;
    justify-content: space-between;
    align-items: center;
    width: 50px;
  }

  #UtilityPanel .channelIcon svg{
    transform: translateY(-1px);
  }
  `;

  