import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
// import * as serviceWorker from './serviceWorker';
// Redux 임포트

import { Provider } from "react-redux";
import { createStore, applyMiddleware } from 'redux';
import { GoogleOAuthProvider } from '@react-oauth/google'
// import { composeWithDevTools } from 'redux-devtools-extension';
import { BrowserRouter, Switch, Route } from "react-router-dom";
// [리듀스]스토어 연결
import DebugArea from './components/debug/DebugArea'; // 체크박스 모듈
import * as config from 'config/OverlapConfig'
import store from "./store";
import Routes from "./components/Routes";
import BackgroundWorker from "worker";
// SASS&CSS 연결
import "./sass/font.scss"
import './sass/font.css'
import "sass/animation.scss"
import "sass/overwrite.scss"
import "sass/widget.scss"
import GlobalProvider from 'service/provider/GlobalProvider';

ReactDOM.render(
  <React.StrictMode>
     <GoogleOAuthProvider clientId={`${config.GOOGLE_REST_API_KEY}`}>
    <Provider store={store}>
      {/* <GlobalProvider> */}
        <BrowserRouter >
        <Routes/>
        <DebugArea/>
        <BackgroundWorker/>
        </BrowserRouter>
      {/* </GlobalProvider> */}
    </Provider>
    </GoogleOAuthProvider>
  </React.StrictMode>
  ,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

