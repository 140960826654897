////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { useState, useEffect, useContext, Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { useHistory,useLocation } from 'react-router-dom';
// [리덕스]스토어 연결
import store from "store";
// [프로바이더] 연결
import EditContext from 'service/context/EditContext' ; 

// SASS&CSS 연결
// import "sass/edit.scss"



// 이미지 연결
import logoW from 'assets/logoW.svg';

// 컴포넌트 연결
import PreviewDisplay from 'components/display/PreviewDisplay' // 오버랩 디스플레이
import GuideBalloon from 'components/common/form/GuideBalloon'; // 말풍선 가이드

// 서비스 연결
import * as Editor from 'service/edit/EditService'; 
import * as page from 'service/api/page';
import * as view from 'service/api/view';
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
function DebugPanel({panelSize}) {

    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////
    //set
    const context = useContext(EditContext);
    // state
    const [ChannelCache, setChannelCache] = useState(context.channelCache);

    /* EditContext 가 비동기 로딩으로 인해서 로딩중일때 오류를 막기 위한 State */
    const [ContextReady, setContextReady] = useState(false);
    const [DebugTarget, setDebugTarget] = useState(null);

    // rel 

   ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////
    useEffect(() => {
        // 여기에 컴포넌트가 마운트되었을 때 실행할 코드를 작성합니다.
        setChannelCache(context.channelCache);
        
        return () => {
        // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
        };
      }, [context.channelCache]);


    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////
  
    /**
     * 편집 프로바이더에서 위젯의 목록을 가저옵니다. 
     * 이후 위젯 리스트를 만들기 위해 데이터를 가공합니다.
     */

    console.log('[DebugPanel] : ',ChannelCache)

    let target =null;

    switch (DebugTarget) {
      case 'cache':
        target = context.channelCache;
        break;

      case 'channel':
        target = context.view;
        break;

      case 'clipboard':
        target = context.clipBoard;
        break;

      case 'overlap':
        target = context.overlap;
        break;
  
      default:
        break;
    }

    return (
        <Fragment>
                <style jsx>{`${PanelStyle}`}</style>
    
                <div id = "DebugPanel">
                
                <div id = "BasicList">
                  <div className='BasicInfo' title={'선택된 UUID : '+context?.selectWidget}>
                    <span  className='BasicIcon'>
                    {Editor.icon({icon:'pageless',size:20,lineHeight:20})}
                    </span>
                    <p>{context?.selectWidget?.substr(0, 4)??'NULL'} </p>
                  </div>

                  <div className='BasicInfo' title={'액션모드 : '+context.action}>
                    <span  className='BasicIcon'>
                    {Editor.icon({icon:'drag_pan',size:20,lineHeight:20})}
                    </span>
                    <p>{context.action??'NULL'} </p>
                  </div>


                  <div className='BasicInfo' title={'추척중인 요소 : '+DebugTarget} onClick={()=>setDebugTarget(null)}>
                    <span  className='BasicIcon'>
                    {Editor.icon({icon:'track_changes',size:20,lineHeight:20})}
                    </span>
                    <small>{DebugTarget}</small>
                  </div>

                </div>

                <div id = "DebugField">
                  {DebugTarget?
                    <textarea value={JSON.stringify(target)} />
                    :
                    <div className='SelectTarget'>
                    <button onClick={()=>setDebugTarget('cache')}>
                    {Editor.icon({icon:'memory',size:25,lineHeight:30})}
                    <small>캐쉬</small>
                    </button>
                    <button onClick={()=>setDebugTarget('channel')}>
                    {Editor.icon({icon:'podcasts',size:25,lineHeight:30})}
                    <small>채널</small>
                    </button>
                    <button onClick={()=>setDebugTarget('clipboard')}>
                    {Editor.icon({icon:'content_paste_search',size:25,lineHeight:30})}
                    <small>클립보드</small>
                    </button>
                    <button onClick={()=>setDebugTarget('overlap')}>
                    {Editor.icon({image:logoW,size:25,lineHeight:30})}
                    <small>오버랩</small>
                    </button>
                    </div>
                  }
            
                </div>

                </div>
        </Fragment>
    );

}

////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    // mainScreen : state.mainScreen,
})

function mapDispatchToProps(dispatch){
    return {
        // initOverlap : (overlapData) => dispatch({type:'initOverlap',overlap : overlapData}),
    }
}

  // export default EditPage;
export default connect(mapStateToProps,mapDispatchToProps)(DebugPanel);
  


  const PanelStyle = `

  #DebugPanel{
      width: 100%;
      height: 100%;
      display : flex;
  }

  #DebugPanel .DebugInfo{
    display : flex;
  }

  #DebugPanel #BasicList{
    width:  70px;
    background-color: rgb(28, 28, 28);
    border-radius: 5px ;
    margin : 5px 0px 5px 10px;
    display : flex;
    align-items: center;
    justify-content: start;
    flex-direction: column;
  }

  #DebugPanel .BasicInfo{
    width: 50px;
    height: 50px;
    margin : 10px 10px 0px 10px;
    background-color: #444;
    flex-direction: column;
    display : flex;
    align-items: center;
    justify-content: center;
  }

  #DebugPanel #DebugField{
    flex : 1;
    display : flex;
    margin : 5px;
  }

  #DebugPanel #DebugField textarea {
    flex : 1;
    display : flex;
    background-color: rgb(28, 28, 28);
    color : #fff;
    font-family: "프리텐다드SemiBold", sans-serif;
    border : solid 0px transparent;
    border-radius: 5px ;
  }

  #DebugPanel #DebugField .SelectTarget {

    display : flex;
    color : #fff;
    font-family: "프리텐다드SemiBold", sans-serif;
  }


  #DebugPanel #DebugField .SelectTarget button {
    background-color: rgb(28, 28, 28);
    width: 50px;
    height: 50px;
    margin : 5px;
    padding : 5px;
    color : #fff;
    font-family: "프리텐다드SemiBold", sans-serif;
    border-radius: 5px ;
    font-size : 12px;
  }
  
  `;