
// 서비스 연결
import * as widgetModel from 'service/model/widgetModel'



export function css ({widgetAnimation,availableAnimation}) {

  const CSSAnimation = {}  
  const KeyFrame = widgetAnimation.keyFrame
  const delay = widgetAnimation.delay/1000
  const timing = widgetAnimation.timing/1000
  const target = availableAnimation[KeyFrame]?.target??[];
  const keysArray = Object.keys(target);
  for (let index = 0; index < keysArray.length; index++) {
    CSSAnimation[keysArray[index]] = {animation:` ${target[keysArray[index]].keyframe} ${timing}s ease-in-out ${delay}s`}
  }
  return CSSAnimation ;
}

export function basic ({state}) {

  switch (state) {
    case 'mountStart':
      return AnimationList
    default:
      return AnimationList
  }
}


export const AnimationList = {
  'fade_in' : {
    info : {name:'fade in',nameKo:'천천히 등장하기',icon:'blur_on'},
    timing : {max:5000,min:500,default:1000},
    target :{layout:{keyframe:'widget_animation_fadeIn'}},
  },
  'zoom_in' : {
    info : {name:'zoom in',nameKo:'점점 확대되기',icon:'zoom_out_map'},
    timing : {max:5000,min:500,default:1000},
    target :{layout:{keyframe:'widget_animation_zoomIn'}},
  },
  'scale_Up' : {
    info : {name:'Scale Up',nameKo:'점점 축소되기',icon:'zoom_in_map'},
    timing : {max:5000,min:500,default:1000},
    target :{layout:{keyframe:'widget_animation_scaleUp'}},
  },
  'slideIn_Left' : {
    info : {name:'slideInLeft',nameKo:'왼쪽에서 나타나기',icon:'arrow_circle_left'},
    timing : {max:5000,min:500,default:1000},
    target :{layout:{keyframe:'widget_animation_slideIn_Left'}},
  },
  'slideIn_Right' : {
    info : {name:'slideInRight',nameKo:'오른쪽에서 나타나기',icon:'arrow_circle_right'},
    timing : {max:5000,min:500,default:1000},
    target :{layout:{keyframe:'widget_animation_slideIn_Right'}},
  },
  'slideIn_Top' : {
    info : {name:'slideInTop',nameKo:'위에서 나타나기',icon:'arrow_circle_up'},
    timing : {max:5000,min:500,default:1000},
    target :{layout:{keyframe:'widget_animation_slideIn_Top'}},
  },
  'slideIn_Bottom' : {
    info : {name:'slideInBottom',nameKo:'아래에서 나타나기',icon:'arrow_circle_down'},
    timing : {max:5000,min:500,default:1000},
    target :{layout:{keyframe:'widget_animation_slideIn_Bottom'}},
  },
  'Rotate_In' : {
    info : {name:'RotateIn',nameKo:'회전하며 등장',icon:'rotate_right'},
    timing : {max:5000,min:500,default:1000},
    target :{layout:{keyframe:'widget_animation_RotateIn'}},
  },
  'Turn' : {
    info : {name:'Flip In',nameKo:'턴',icon:'blur_on'},
    timing : {max:5000,min:500,default:1000},
    target :{layout:{keyframe:'rotate_right'}},
  },
};


/**
 * 해당 위젯이 사용가능한 애니메이션 리스트를 가지고 옵니다.
 * @param {*} target 오버랩 객체, Data 아님
 * @param {*} state 애니메이션의 종류
 * @returns 
 */
export const getReferenceAnimation = (target,state) => {

  const Reference = widgetModel.getReference();

  const TargetName  = target?.type??'Unknown'

  const TargetPreset  = target?.data?.preset

  const TargetVersion  = target?.version??Reference[TargetName].default


  try {
    const ReferenceAnimation  = Reference[TargetName].version[TargetVersion]?.availableAnimation(state,TargetPreset)
    return ReferenceAnimation;
    
  } catch (error) {
    
  }
}