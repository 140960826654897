////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widgetEdit.scss"


// 컴포넌트 연결
// import Show from '../show/Show'; // 메뉴 헤더

// 서비스 연결

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class EightyDirectionsModule extends Component {

    directions = (type,value) =>{
        console.log(value)
        switch (value) {
            case '↑':
                this.props.change(type,'180deg')
                break;
            case '↗':
                this.props.change(type,'225deg')
                break;
            case '→':
                this.props.change(type,'270deg')
                break;
            case '↘':
                this.props.change(type,'315deg')
                break;
            case '↓':
                this.props.change(type,'0deg')
                break;
            case '↙':
                this.props.change(type,'45deg')
                break;
            case '←':
                this.props.change(type,'90deg')
                break;
            case '↖':
                this.props.change(type,'135deg')
                break;
            case '⟳':
                this.props.change(type,'circle')
                break;
            default:
                break;
        }
    }


    render() { 
        console.log(this.props.value)
        return (
            <label className="EightyDirectionsModule" htmlfor="fname"> 
                <p>{this.props.title?this.props.title:'방향'}</p>
                <div>
                    <input type="button" value='↖' onClick={(e) => this.directions(this.props.type,e.target.value)}/>
                    <input type="button" value='↑' onClick={(e) => this.directions(this.props.type,e.target.value)}/>
                    <input type="button" value='↗' onClick={(e) => this.directions(this.props.type,e.target.value)}/>
                    <input type="button" value='←' onClick={(e) => this.directions(this.props.type,e.target.value)}/>
                    <input type="button" value='⟳' onClick={(e) => this.directions(this.props.type,e.target.value)}/>
                    <input type="button" value='→' onClick={(e) => this.directions(this.props.type,e.target.value)}/>
                    <input type="button" value='↙' onClick={(e) => this.directions(this.props.type,e.target.value)}/>
                    <input type="button" value='↓' onClick={(e) => this.directions(this.props.type,e.target.value)}/>
                    <input type="button" value='↘' onClick={(e) => this.directions(this.props.type,e.target.value)}/>
                </div>
               
            </label>
        );
    }
}

////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    // screenData : state.screenData,
})

function mapDispatchToProps(dispatch){
    return {
    //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(EightyDirectionsModule);
  