// 리엑트 연결
import React, { Component , Fragment } from 'react';

////////////////////////////////////////////////////////
// store 연결
////////////////////////////////////////////////////////
import store from "store";

export function widget(self) {

  // console.log('데이터 체크중 ',self.data);
  
  try {
    return eval(self.widgetName+'Model(self.data)')
  } catch (err) {
    console.error('[Model]',self.widgetName,'Model 의 이름으로 된 함수가 없음')
  }
    return 
}


////////////////////////////////////////////////////////
// 세이프티
// 디코더가 해석할때 위젯의 값이 undefined 인 경우 초기값을 설정해줌
////////////////////////////////////////////////////////

export function safety(widget) {
  if (widget){
    try {
      let data = eval(widget.type+'Reference')
      return finder(data,widget.data)
    } catch (error) {
      let data = UndefinedReference
      return finder(data,widget.data)
    }
  }
  console.error('[Decoder] safety 작동오류 해석 해야 하는 widget의 값이',widget)
}

export function presetFinder(widget) {
  if (widget){
    let preset = null
    try { preset = eval(widget+'Preset')

  }
    catch(err) { preset = null }
    return preset
  }
  console.error('[Decoder] safety 작동오류 해석 해야 하는 widget의 값이',widget)
}




// 재귀형이 아니라 2단계 까지만 가능
function finder(reference,widget) {
  // console.log('reference',reference,'widget',widget);

  let keys = Object.keys(reference)
  let newData ={}
  for (let i = 0; i < keys.length; i++) {
    let props = Object.keys(reference[keys[i]])
    newData[keys[i]]={}
    if (undefined == widget?.[keys[i]]){
      newData[keys[i]]=reference[keys[i]]
      continue;
    }
    // 값이 오브젝트인 경우
    if(typeof(reference[keys[i]])=='object'||typeof(widget[keys[i]])=='object'){
      for (let j = 0; j < props.length; j++) {

        if (undefined == widget[keys[i]][props[j]]){
          newData[keys[i]][props[j]]=reference[keys[i]][props[j]]
        }else{
          newData[keys[i]][props[j]]=widget[keys[i]][props[j]]
        }
      }
    }
    //오브젝트가 아닌경우
    else{
      newData[keys[i]]=widget[keys[i]]
    }
  }
 
  return newData
}

////////////////////////////////////////////////////////
// 스텐다드 객체
////////////////////////////////////////////////////////

function standardObject () {
  return {LAYOUT : {},OBJECT : {}}
}

const filterDefault ={
  opacity: 100,
  brightness : 100,
  contrast: 100,
  blur:0,
  grayscale:0,
  hueRotate:0,
  invert:0,
  saturate:100,
  sepia:0,
}

const transformDefault ={
  rotateZ:0,
  rotateY:0,
  rotateX:0,
  scale:100,
}


////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/// 위젯 레퍼런스
// 디코더가 해석할때 위젯의 값이 undefined 인 경우 초기값을 설정해줌
////////////////////////////////////////////////////////////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// ExWidget 테스트 위젯
////////////////////////////////////////////////////////

const ExWidgetReference = {
  position: {x: "20", y: "20", show : true},
  size: {width: "200", height: "200"},
  style : {
    fontSize : 50,
    fontFamily : '나눔스퀘어',
    shadowValue: "0", 
    shadowColor: "#000000",
    morphing: false, 
    color : "#000000",
    fill : "#888888",
    textAlign: 'left',
    fontWeight : 'normal',
    textOutline : 0,
    ...filterDefault,
    ...transformDefault,
  },
  value: {
    option: null
  },
  display:{
    show : true
  },
}
// 래퍼런스 객체에 없는것은 세이프티에서 걸러짐


function ExWidgetModel(data) {
  let layout = {}
  let object = {}
  let style = standardObject()
  let property = Object.keys(data)
  if(property.includes('position')){
    layout = Object.assign(layout,position(data.position))
  }
  if(property.includes('size')){
    object = Object.assign(object,size(data.size))
  }
  if(property.includes('style')){
    //색상
    object = Object.assign(object,fill(data.style))
    // 외곽선 
    object = Object.assign(object,border(data.style))
    // 필터 + 그림자 + 투명도
    object = Object.assign(object,filter(data.style))
    // 모핑
    layout = Object.assign(layout,morphing(data.style))

  }

  return {layout,object}
}


////////////////////////////////////////////////////////
// Undefined 망가진 위젯 처리
////////////////////////////////////////////////////////

const UndefinedReference = {
  position: {x: "20", y: "20", show : false},
  size: {width: "200", height: "200"},
  style : {
  },
  value: {
    option: null
  },
  display:{
    show : true
  },
}
// 래퍼런스 객체에 없는것은 세이프티에서 걸러짐


function UndefinedModel(data) {

  let layout = {}
  let object = {}
  let style = standardObject()
  let property = Object.keys(data)
  if(property.includes('position')){
    layout = Object.assign(layout,position(data.position))
  }
  if(property.includes('size')){
    object = Object.assign(object,size(data.size))
  }

  return {layout,object}
}


////////////////////////////////////////////////////////
// Text 텍스트 위젯
////////////////////////////////////////////////////////

const TextReference = {
  position: {x: 20, y: 20, show : true},
  size: {width: 800, height: 50},
  style : {
    fontSize : 50,
    fontFamily : '나눔스퀘어',
    shadowValue: "0", 
    shadowColor: "#000000",
    textShadowValue: "0", 
    textShadowColor: "#000000",
    morphing: false, 
    fill : "#000000",
    useFill : false,
    color : "#000000",
    textAlign: 'left',
    fontWeight : 'normal',
    verticalAlign : 'flex-start',
    outlineColor : '#000000',
    textOutline : 0,
    ...filterDefault,
    ...transformDefault,
  },
  value: {
    text:'',
    option: null
  },
  display:{
    show : true
  },
}


function TextModel(data) {

  let layout = {}
  let object = {}
  let style = standardObject()
  let property = Object.keys(data)
  if(property.includes('position')){
    layout = Object.assign(layout,position(data.position))
  }
  if(property.includes('size')){
    object = Object.assign(object,minSize(data.size,200,'auto'))
  }
  if(property.includes('style')){
    // 외곽선 
    object = Object.assign(object,border(data.style))
    // 폰트
    object = Object.assign(object,font(data.style))
    // 필터 + 그림자 + 투명도
    object = Object.assign(object,filter(data.style))
    // 모핑
    layout = Object.assign(layout,morphing(data.style))
    object = Object.assign(object,morphing(data.style))
    //배경색
    if (data.style.useFill){
      object = Object.assign(object,fill(data.style))
    }
    // 변형
    object = Object.assign(object,transform(data.style))
  }
  return {layout,object}
}

////////////////////////////////////////////////////////
// Image 이미지 위젯
////////////////////////////////////////////////////////
const ImageReference = {
  position: {x: "20", y: "20", show : true},
  size: {width: "200", height: "200"},
  style : {
    fontSize : 50,
    fontFamily : '나눔스퀘어',
    shadowValue: "0", 
    shadowColor: "#000000",
    shadowDirection : "RightBottom",
    morphing: false, 
    fill : "#000000",
    textAlign: 'left',
    fontWeight : 'normal',
    textOutline : 0,
    borderRadius : 0,
    objectFit : 'fill',
    clipNode : [],
    ...filterDefault,
    ...transformDefault,
  },
  value: {
    mediaType : 'link',
    mediaSource : null,
    option: null
  },
  display:{
    show : true
  },
}

function ImageModel(data) {
  let layout = {}
  let object = {}
  let style = standardObject()
  let property = Object.keys(data)
  if(property.includes('position')){
    layout = Object.assign(layout,position(data.position))
  }
  if(property.includes('size')){
    object = Object.assign(object,size(data.size))
  }
  if(property.includes('style')){
    // 외곽선 
    object = Object.assign(object,border(data.style))
    // 필터 + 그림자 + 투명도
    object = Object.assign(object,filter(data.style))
    // 모핑
    layout = Object.assign(layout,morphing(data.style))
    object = Object.assign(object,morphing(data.style))
    // 변형
    object = Object.assign(object,transform(data.style))
    // 자르기
    object = Object.assign(object,clipPath(data.style))
    // 이미지정렬
    object = Object.assign(object,objectFit(data.style))
  }
  return {layout,object}
}


////////////////////////////////////////////////////////
// Video 텍스트 위젯
////////////////////////////////////////////////////////
const VideoReference = {
  position: {x: "20", y: "20", show : true},
  size: {width: "960", height: "540"},
  style : {
    fontSize : 50,
    fontFamily : '나눔스퀘어',
    shadowValue: "0", 
    shadowDirection : "RightBottom",
    shadowColor: "#000000",
    morphing: false, 
    color : "#000000",
    textAlign: 'left',
    fontWeight : 'normal',
    textOutline : 0,
    borderRadius : 0,
    ...filterDefault,
    ...transformDefault,
  },
  value: {
    mediaSource : '',
    mode : "youtube",
    startTime : 0,
    endTime : 0,
    option: null
  },
  display:{
    show : true
  },
}

function VideoModel(data) {
  let layout = {}
  let object = {}
  let style = standardObject()
  let property = Object.keys(data)
  if(property.includes('position')){
    layout = Object.assign(layout,position(data.position))
  }
  if(property.includes('size')){
    object = Object.assign(object,size(data.size))
  }
  if(property.includes('style')){
    // 외곽선 
    object = Object.assign(object,border(data.style))
    // 필터 + 그림자 + 투명도
    object = Object.assign(object,filter(data.style))
    // 모핑
    layout = Object.assign(layout,morphing(data.style))
    object = Object.assign(object,morphing(data.style))
    // 변형
    object = Object.assign(object,transform(data.style))

  }
  return {layout,object}
}



////////////////////////////////////////////////////////
// Image 이미지 위젯
////////////////////////////////////////////////////////
const CaptureStreamReference = {
  position: {x: "20", y: "20", show : true},
  size: {width: "200", height: "200"},
  style : {
    fontSize : 50,
    fontFamily : '나눔스퀘어',
    shadowValue: "0", 
    shadowColor: "#000000",
    shadowDirection : "RightBottom",
    morphing: false, 
    fill : "#000000",
    textAlign: 'left',
    fontWeight : 'normal',
    textOutline : 0,
    borderRadius : 5,
    objectFit : 'fill',
    clipNode : [],
    ...filterDefault,
    ...transformDefault,
  },
  value: {
    streamID : null,
  },
  display:{
    show : true
  },
}

function CaptureStreamModel(data) {
  let layout = {}
  let object = {}
  let style = standardObject()
  let property = Object.keys(data)
  if(property.includes('position')){
    layout = Object.assign(layout,position(data.position))
  }
  if(property.includes('size')){
    object = Object.assign(object,size(data.size))
  }
  if(property.includes('style')){
    // 외곽선 
    object = Object.assign(object,border(data.style))
    // 필터 + 그림자 + 투명도
    object = Object.assign(object,filter(data.style))
    // 모핑
    layout = Object.assign(layout,morphing(data.style))
    object = Object.assign(object,morphing(data.style))
    // 변형
    object = Object.assign(object,transform(data.style))
    // 자르기
    object = Object.assign(object,clipPath(data.style))
    // 이미지정렬
    object = Object.assign(object,objectFit(data.style))
  }
  return {layout,object}
}



////////////////////////////////////////////////////////
// Shape 도형 위젯
////////////////////////////////////////////////////////
const ShapeReference = {
  position: {x: "20", y: "20", show : true},
  size: {width: "200", height: "200"},
  style : {
    fontSize : 50,
    fontFamily : '나눔스퀘어',
    shadowValue: "0", 
    shadowDirection : "RightBottom",
    shadowColor: "#000000",
    morphing: false, 
    fill : '#ffa500',
    clipNode : [],
    textAlign: 'left',
    fontWeight : 'normal',
    textOutline : 0,
    borderRadius : 0,
    ...filterDefault,
    ...transformDefault,
    strokeColor :'#222222',
    strokeWidth :0,
    strokeStyle :null,
  },
  value: {
    innerText: '',
    mediaSource : null,
    option: null
  },
  display:{
    show : true
  },
  preset : 'quadrangle'
}

function ShapeModel(data) {
  let layout = {}
  let object = {}
  let shapes = {}
  let line = {}
  let style = standardObject()
  let property = Object.keys(data)
  if(property.includes('position')){
    layout = Object.assign(layout,position(data.position))
  }
  if(property.includes('size')){
    object = Object.assign(object,size(data.size))
    line = Object.assign(line,size(data.size))
  }
  if(property.includes('style')){
    //색상
    shapes = Object.assign(shapes,fill(data.style,'','fill'))
    // 외곽선 
    line = Object.assign(line,stroke(data.style))
    // 외곽선 
    object = Object.assign(object,border(data.style))
    line = Object.assign(line,border(data.style))
    // 필터 + 그림자 + 투명도
    object = Object.assign(object,filter(data.style))
    line = Object.assign(line,shotfilter(data.style))
    // 모핑
    layout = Object.assign(layout,morphing(data.style))
    object = Object.assign(object,morphing(data.style))
    line = Object.assign(line,morphing(data.style))
    shapes = Object.assign(shapes,morphing(data.style))
    // 변형
    object = Object.assign(object,transform(data.style))
    line = Object.assign(line,transform(data.style))
    // 자르기
    object = Object.assign(object,clipPath(data.style))
  }
  return {layout,object,shapes,line}
}

////////////////////////////////////////////////////////
// Line 도형 위젯
////////////////////////////////////////////////////////
const LineReference = {
  position: {x: "20", y: "20", show : true},
  size: {width: "500", height: "0"},
  style : {
    morphing: false, 
    textOutline : 0,
    ...filterDefault,
    ...transformDefault,
    strokeColor :'#000000',
    strokeWidth :5,
    strokeStyle :null,
    borderRadius : 0,
  },
  value: {
    innerText: '',
    option: null
  },
  display:{
    show : true
  },
}

function LineModel(data) {
  let layout = {}
  let object = {}
  let line = {}
  let style = standardObject()
  let property = Object.keys(data)
  if(property.includes('position')){
    layout = Object.assign(layout,position(data.position))
  }

  if(property.includes('size')){
    line = Object.assign(line,size(data.size))
  }
  if(property.includes('style')){
    let keys = Object.keys(data.style)
    if(keys.includes('strokeWidth')){
    line = Object.assign(line,{height:data.style.strokeWidth/2})
    }

    // 외곽선 
    line = Object.assign(line,stroke(data.style))

    line = Object.assign(line,shotfilter(data.style))
    // 모핑
    layout = Object.assign(layout,morphing(data.style))
    line = Object.assign(line,transform(data.style))
    // 변형
    line = Object.assign(line,transform(data.style))
  }

  return {layout,object,line}
}


////////////////////////////////////////////////////////
// NoticeReference 메모
////////////////////////////////////////////////////////
const NoticePreset = [
  {name:'yellowPostit_Horizontal', nameKo:'노란색 포스트잇 가로형', ratio:1.666},
  {name:'yellowPostit_vertical', nameKo:'노란색 포스트잇 세로형', ratio:1.2},
  {name:'pinkPostit_Horizontal', nameKo:'분홍색 포스트잇 가로형', ratio:1.666},
  {name:'pinkPostit_vertical', nameKo:'분홍색 포스트잇 세로형', ratio:1.2},
  {name:'twitchNotice', nameKo:'트위치 공지', ratio:7},
  {name:'normal', nameKo:'기본형 모든포지션', ratio:null},
]

const NoticeReference = {
  position: {x: 20, y: 20, show : true},
  size: {width: 500, height: 300},
  style : {
    fontSize : 50,
    fontFamily : '나눔바른펜',
    shadowValue: "0", 
    shadowDirection : "RightBottom",
    shadowColor: "#000000",
    morphing: false, 
    fill : "#000000",
    useFill : false,
    color : "#000000",
    textAlign: 'left',
    verticalAlign : 'flex-start',
    fontWeight : 'normal',
    outlineColor : '#000000',
    textOutline : 0,
    ...filterDefault,
    ...transformDefault,
  },
  value: {
    text:'',
    option: null
  },
  display:{
    show : true
  },
  preset : 'yellowPostit_Horizontal'
}


function NoticeModel(data) {
  let layout = {}
  let object = {}
  let style = standardObject()
  let property = Object.keys(data)
  if(property.includes('position')){
    layout = Object.assign(layout,position(data.position))
  }
  if(property.includes('size')){
    object = Object.assign(object,minSize(data.size,200,'auto'))
  }
  if(property.includes('style')){
    // 외곽선 
    object = Object.assign(object,border(data.style))
    // 폰트
    object = Object.assign(object,font(data.style))
    // 필터 + 그림자 + 투명도
    object = Object.assign(object,filter(data.style))
    // 모핑
    layout = Object.assign(layout,morphing(data.style))
    object = Object.assign(object,morphing(data.style))
    //배경색
    if (data.style.useFill){
      object = Object.assign(object,fill(data.style))
    }
    // 변형
    object = Object.assign(object,transform(data.style))
  }
  return {layout,object}
}


////////////////////////////////////////////////////////
// Timer 타이머 위젯
////////////////////////////////////////////////////////

const TimerReference = {
  position: {x: 20, y: 20, show : true},
  size: {width: 100, height: 100},
  style : {
    fontSize : 50,
    fontFamily : '나눔스퀘어',
    shadowValue: "0", 
    shadowDirection : "RightBottom",
    shadowColor: "#000000",
    morphing: false, 
    color : "#000000",
    textAlign: 'left',
    fontWeight : 'normal',
    outlineColor : '#000000',
    textOutline : 0,
    ...filterDefault,
    ...transformDefault,
  },
  value: {
  time : 300,
  endTime : null,
  option: null
  },
  display:{
    show : true
  },
}


function TimerModel(data) {
  let layout = {}
  let object = {}
  let style = standardObject()
  let property = Object.keys(data)
  if(property.includes('position')){
    layout = Object.assign(layout,position(data.position))
  }
  if(property.includes('size')){
    object = Object.assign(object,minSize(data.size,200,'auto'))
  }
  if(property.includes('style')){
    // 외곽선 
    object = Object.assign(object,border(data.style))
    // 폰트
    object = Object.assign(object,font(data.style))
    // 필터 + 그림자 + 투명도
    object = Object.assign(object,filter(data.style))
    // 모핑
    layout = Object.assign(layout,morphing(data.style))
  }
  return {layout,object}
}

////////////////////////////////////////////////////////
// Clock 시계 위젯
////////////////////////////////////////////////////////

const ClockPreset = [
  {name:'textStyle', nameKo:'텍스트시계'},
  {name:'flipStyle', nameKo:'검은플립시계'},
  {name:'flipWhiteStyle', nameKo:'하얀플립시계'},
]


const ClockReference = {
  position: {x: 20, y: 20, show : true},
  size: {width: 500, height: 300},
  style : {
    fontSize : 50,
    fontFamily : '나눔스퀘어',
    shadowValue: "0", 
    shadowDirection : "RightBottom",
    shadowColor: "#000000",
    morphing: false, 
    color : "#000000",
    clock_color : "#000000",
    textAlign: 'left',
    fontWeight : 'normal',
    outlineColor : '#000000',
    textOutline : 0,
    ...filterDefault,
    ...transformDefault,
  },
  value: {
  use24hour : true,
  meridiemText: true,
  outputText: 'krText',
  option: null
  },
  display:{
    show : true
  },
  preset : 'textStyle'
}


function ClockModel(data) {
  let layout = {}
  let object = {}
  let style = standardObject()
  let property = Object.keys(data)
  if(property.includes('position')){
    layout = Object.assign(layout,position(data.position))
  }
  if(property.includes('size')){
    object = Object.assign(object,minSize(data.size,200,'auto'))
  }
  if(property.includes('style')){
    // 외곽선 
    object = Object.assign(object,border(data.style))
    // 폰트
    object = Object.assign(object,font(data.style))
    // 필터 + 그림자 + 투명도
    object = Object.assign(object,filter(data.style))
    // 모핑
    layout = Object.assign(layout,morphing(data.style))
  }
  return {layout,object}
}

////////////////////////////////////////////////////////
// Countdown 카운트다운 위젯
////////////////////////////////////////////////////////

const CountdownReference = {
  position: {x: 20, y: 20, show : true},
  size: {width: 0, height: 0},
  style : {
    fontSize : 50,
    fontFamily : '나눔스퀘어',
    shadowValue: "0", 
    shadowDirection : "RightBottom",
    shadowColor: "#000000",
    morphing: false, 
    color : "#000000",
    textAlign: 'center',
    fontWeight : 'normal',
    outlineColor : '#000000',
    textOutline : 0,
    ...filterDefault,
    ...transformDefault,
  },
  value: {
  time : 300,
  endTime : null,
  option: null
  },
  display:{
    show : true
  },
}


function CountdownModel(data) {
  let layout = {}
  let object = {}
  let style = standardObject()
  let property = Object.keys(data)
  if(property.includes('position')){
    layout = Object.assign(layout,position(data.position))
  }
  if(property.includes('size')){
    object = Object.assign(object,minSize(data.size,200,'auto'))
  }
  if(property.includes('style')){
    // 외곽선 
    object = Object.assign(object,border(data.style))
    // 폰트
    object = Object.assign(object,font(data.style))
    // 필터 + 그림자 + 투명도
    object = Object.assign(object,filter(data.style))
    // 모핑
    layout = Object.assign(layout,morphing(data.style))
  }
  return {layout,object}
}

////////////////////////////////////////////////////////
// BackgroundReference 배경화면 위젯
////////////////////////////////////////////////////////
const BackgroundPatternDesignPreset = [
  {name:'patternDesign', nameKo:'패턴디자인 ', ratio:3,},
  // {name:'patternDesign', nameKo:'단색 ', ratio:3,},
  // {name:'live3D', nameKo:'라이브3D', ratio:3,},
]

const BackgroundPatternDesignReference = {
  position: {x: 0, y: 0, show : true},
  size: {width: '100%', height: 1080},
  style : {

    shadowValue: "0", 
    shadowDirection : "RightBottom",
    shadowColor: "#000000",
    morphing: false, 
    fontWeight : 'normal',
    verticalAlign : 'flex-start',
    outlineColor : '#000000',
    textOutline : 0,
    pattern_morphing:'',
    fill : "#ffa500",

    pattern_shadowValue: "0", 
    pattern_shadowDirection : "RightBottom",
    pattern_shadowColor: "#000000",
    pattern_textAlign: 'center',
    pattern_fontFamily: "도현체",
    pattern_fontSize: 40,
    pattern_color : "#ffffff",
    
    pattern_rotateZ:30,
    pattern_rotateY:0,
    pattern_rotateX:0,
    pattern_scale:100,

    pattern_opacity: 100,
    pattern_brightness : 100,
    pattern_contrast: 100,
    pattern_blur:0,
    pattern_grayscale:0,
    pattern_hueRotate:0,
    pattern_invert:0,
    pattern_saturate:100,
    pattern_sepia:0,

    fillOpacity : 100,

    clipNode : [],
    ...filterDefault,
    ...transformDefault,
  },
  value: {
    type:'text',
    schedule : [],
    mediaSource : null,
    patternSize : 250,
    patternDesign : 'patternDesignMove',
    animationTiming : 'linear',
    text:'',
    speed : 3,
    animation : 'normal' 
  },
  display:{
    show : true
  },
  preset : 'patternDesign'
}

function BackgroundPatternDesignModel(data) {
  let layout = {}
  let object = {}
  let pattern ={}
  let style = standardObject()
  let property = Object.keys(data)
  if(property.includes('position')){
    layout = Object.assign(layout,position(data.position))
  }
  if(property.includes('size')){
    object = Object.assign(object,size(data.size,200,'auto'))
  }
  if(property.includes('style')){
    //배경색
    object = Object.assign(object,fill(data.style))
    // 외곽선 
    object = Object.assign(object,border(data.style))
    // 폰트
    object = Object.assign(object,font(data.style))
    pattern = Object.assign(pattern,font(data.style,'pattern'))
    // 필터 + 그림자 + 투명도
    object = Object.assign(object,filter(data.style))
    pattern = Object.assign(pattern,filter(data.style,'pattern'))
    // 모핑
    layout = Object.assign(layout,morphing(data.style))
    object = Object.assign(object,morphing(data.style))
    pattern = Object.assign(pattern,morphing(data.style))
    //배경색
    // 변형
    object = Object.assign(object,transform(data.style))
    pattern = Object.assign(pattern,transform(data.style,'pattern'))
    // 자르기
    // object = Object.assign(object,transform(data.style))''
    object = Object.assign(object,clipPath(data.style))
  }
  return {layout,object,pattern}
}

////////////////////////////////////////////////////////
// ParticlesReference | 파티클 위젯
// 파티클 위젯
////////////////////////////////////////////////////////
const ParticlesPreset = [
  {name:'normal', nameKo:'기본 파티클', ratio:3,},
]

const ParticlesReference = {
  position: {x: 0, y: 0, show : true},
  size: {width: 1920, height: 1080},
  style : {
    fontSize : 50,
    fontFamily : '나눔스퀘어',
    shadowValue: "0", 
    shadowDirection : "RightBottom",
    shadowColor: "#000000",
    morphing: false, 
    textAlign: 'center',
    verticalAlign : 'flex-start',
    fontWeight : 'normal',
    outlineColor : '#000000',
    textOutline : 0,
    fillOpacity : 100,
    ...filterDefault,
    ...transformDefault,
  },
  value: {
    particle : 1,
    particleCount : 50,
    particleSpeed : 10,
    particleSize : 100,
    particleStyle : 'overwatch',
  },
  display:{
    show : true
  },
  preset : 'normal'
}

function ParticlesModel(data) {
  let layout = {}
  let object = {}
  let style = standardObject()
  let property = Object.keys(data)
  if(property.includes('position')){
    layout = Object.assign(layout,position(data.position))
  }
  if(property.includes('size')){
    object = Object.assign(object,size(data.size,200,'auto'))
  }
  if(property.includes('style')){
    // 외곽선 
    object = Object.assign(object,border(data.style))
    // 폰트
    object = Object.assign(object,font(data.style))
    // 필터 + 그림자 + 투명도
    object = Object.assign(object,filter(data.style))
    // 모핑
    layout = Object.assign(layout,morphing(data.style))
    object = Object.assign(object,morphing(data.style))
    //배경색

    // 변형
    object = Object.assign(object,transform(data.style))
  }
  return {layout,object}
}


////////////////////////////////////////////////////////
// Scoreboard | 스코어보드 
// 게임 대회에서 사용되는 점수판
////////////////////////////////////////////////////////
const ScoreboardPreset = [
  {name:'overwatch_normal', nameKo:'오버워치 기본형 스코어보드', size:{width: '100%', height: '10%'},position:{x: 0, y: 0,}},
  // {name:'overwatch2_normal', nameKo:'오버워치 심플 스코어보드', size:{width: '100%', height: '10%'}},
  {name:'overwatch_underbar', nameKo:'오버워치 언더바 스코어보드', size:{width: '100%', height: '10%'},position:{x: 0, y: 972,}},
  {name:'overwatch2_normal', nameKo:'오버워치2 기본형 스코어보드', size:{width: '100%', height: '10%'},position:{x: 0, y: 0,}},
  {name:'overwatch2_simple', nameKo:'오버워치2 심플 스코어보드', size:{width: '100%', height: '10%'},position:{x: 0, y: 0,}},
  {name:'overwatch2_header', nameKo:'오버워치2 상단바 스코어보드', size:{width: '100%', height: '10%'},position:{x: 0, y: 0,}},
  {name:'leagueofLegends_normal', nameKo:'롤 기본형 스코어보드', size:{width: '100%', height: '10%'},position:{x: 0, y: 0,}},
  {name:'leagueofLegends_GameStyle', nameKo:'롤 게임스타일', size:{width: '100%', height: '10%'},position:{x: 0, y: 0,}},
  
  {name:'valorant_normal', nameKo:'발로란트 게임스타일', size:{width: '100%', height: '10%'}},
  {name:'valorant_double', nameKo:'전투형제 스코어보드 *테스트용', size:{width: '100%', height: '10%'}},
]

const ScoreboardReference = {
  position: {x: 0, y: 0, show : true},
  size: {width: '100%', height: '10%'},
  style : {
    info_fontFamily: "프리텐다드Black",
    info_fontSize: 15,
    team_fontFamily : '프리텐다드Black',
    team_fontSize: 30,
    leftTeam_fill: "#59CBE8",
    rightTeam_fill: "#F77397",
  },
  value: {
    reverse : false,
    matchInfo: "대회정보",
    leftTeamName : "왼쪽팀 이름",
    leftTeamScore: 0,
    leftTeamSubScore: 0,
    rightTeamName : "오른쪽팀 이름",
    rightTeamScore: 0,
    rightTeamSubScore: 0,
    option: {move:false},
  },
  display:{
    show : true
  },
  preset : 'overwatch_normal'
}

function ScoreboardModel(data) {
  let left = {}
  let right = {}
  let info = {}
  let team = {}
  let layout ={}
  let object = {}
  let style = standardObject()
  let property = Object.keys(data)
  if(property.includes('position')){
    layout = Object.assign(layout,position(data.position))

  }
  if(property.includes('size')){
    object = Object.assign(object,size(data.size))
  }
  if(property.includes('style')){
    // 폰트
    info = Object.assign(info,font(data.style,'info'))
    team = Object.assign(team,font(data.style,'team'))
    left = Object.assign(left,fill(data.style,'leftTeam'))
    right = Object.assign(right,fill(data.style,'rightTeam'))
  }
  return {layout,object,info,team,left,right}
}




////////////////////////////////////////////////////////
// Camera 텍스트 위젯
////////////////////////////////////////////////////////
const CameraReference = {
  position: {x: "20", y: "20", show : true},
  size: {width: "500", height: "500"},
  style : {
    fontSize : 50,
    fontFamily : '나눔스퀘어',
    shadowValue: "0", 
    shadowDirection : "RightBottom",
    shadowColor: "#000000",
    morphing: false, 
    fill : "#000000",
    textAlign: 'left',
    fontWeight : 'normal',
    textOutline : 0,
    borderRadius : 0,
    ...filterDefault,
    ...transformDefault,
  },
  value: {
    device:'자동설정',
    mediaSource : null,
    option: null
  },
  display:{
    show : true
  },
}

function CameraModel(data) {
  let layout = {}
  let object = {}
  let style = standardObject()
  let property = Object.keys(data)
  if(property.includes('position')){
    layout = Object.assign(layout,position(data.position))
  }
  if(property.includes('size')){
    object = Object.assign(object,size(data.size))
  }
  if(property.includes('style')){
    // 외곽선 
    object = Object.assign(object,border(data.style))
    // 필터 + 그림자 + 투명도
    object = Object.assign(object,filter(data.style))
    // 모핑
    layout = Object.assign(layout,morphing(data.style))
    object = Object.assign(object,morphing(data.style))
    // 변형
    object = Object.assign(object,transform(data.style))
    // 자르기
    object = Object.assign(object,clipPath(data.style))
  }
  return {layout,object}
}

////////////////////////////////////////////////////////
// CryptoCurrency 가상화폐(코인) 위젯
////////////////////////////////////////////////////////

const CryptoCurrencyPreset = [
  {name:'normal', nameKo:'노말스타일', ratio:3},
  // {name:'orange', nameKo:'오렌지스타일', ratio:3},
]

const CryptoCurrencyReference = {
  position: {x: "20", y: "20", show : true},
  size: {width: "300", height: "100"},
  style : {
    fontFamily : '에스코어드림Bold',
    shadowValue: "0", 
    shadowDirection : "RightBottom",
    shadowColor: "#000000",
    morphing: false, 
    fill : "#222222",
    textOutline : 0,
    ...filterDefault,
    ...transformDefault,
  },
  value: {
    coin : null,
    total : false,
    speed : 2,
    displayMode : 'standard',
    option: null,
  },
  display:{
    show : true
  },
  preset : 'normal'
}

function CryptoCurrencyModel(data) {
  let layout = {}
  let object = {}
  let objectFill = {}
  let style = standardObject()
  let property = Object.keys(data)
  if(property.includes('position')){
    layout = Object.assign(layout,position(data.position))
  }
  if(property.includes('size')){
    object = Object.assign(object,size(data.size))
  }
  if(property.includes('style')){
    // 외곽선 
    object = Object.assign(object,border(data.style))
    // 필터 + 그림자 + 투명도
    object = Object.assign(object,filter(data.style))
    objectFill = Object.assign(objectFill,fill(data.style))
    // 모핑
    layout = Object.assign(layout,morphing(data.style))
    object = Object.assign(object,morphing(data.style))
    // 변형
    object = Object.assign(object,transform(data.style))
  }
  return {layout,object,objectFill}
}

////////////////////////////////////////////////////////
// ADalertReference 메모
////////////////////////////////////////////////////////
const ADalertReference = {
  position: {x: 10, y: 900, show : true},
  size: {width: 280, height: 35},
  style : {
    fontSize : 50,
    fontFamily : '나눔스퀘어',
    shadowValue: "0", 
    shadowDirection : "RightBottom",
    shadowColor: "#000000",
    morphing: false, 
    fill : "#000000",
    useFill : false,
    color : "#ffffff",
    textAlign: 'center',
    verticalAlign : 'flex-start',
    fontWeight : 'normal',
    outlineColor : '#000000',
    textOutline : 0,
    ...filterDefault,
    ...transformDefault,
  },
  value: {
    text:'',
    type:'AD',
    option: null
  },
  display:{
    show : true
  },
  preset : 'normal'
}

function ADalertModel(data) {
  let layout = {}
  let object = {}
  let style = standardObject()
  let property = Object.keys(data)
  if(property.includes('position')){
    layout = Object.assign(layout,position(data.position))
  }
  if(property.includes('size')){
    object = Object.assign(object,minSize(data.size,200,'auto'))
  }
  if(property.includes('style')){
    // 외곽선 
    object = Object.assign(object,border(data.style))
    // 폰트
    object = Object.assign(object,font(data.style))
    // 필터 + 그림자 + 투명도
    object = Object.assign(object,filter(data.style))
    // 모핑
    layout = Object.assign(layout,morphing(data.style))
    object = Object.assign(object,morphing(data.style))
    //배경색
    if (data.style.useFill){
      object = Object.assign(object,fill(data.style))
    }
    // 변형
    object = Object.assign(object,transform(data.style))
  }
  return {layout,object}
}

////////////////////////////////////////////////////////
// BroadcastAlertReference 메모
////////////////////////////////////////////////////////
const BroadcastAlertPreset = [
  {name:'normal', nameKo:'노말스타일 ', ratio:3,},
  {name:'normal_Vertical', nameKo:'노말스타일', ratio:3,},
]

const BroadcastAlertReference = {
  position: {x: 20, y: 20, show : true},
  size: {width: 280, height: 35},
  style : {
    fontSize : 50,
    fontFamily : '나눔스퀘어',
    shadowValue: "0", 
    shadowDirection : "RightBottom",
    shadowColor: "#000000",
    morphing: false, 
    textAlign: 'center',
    verticalAlign : 'flex-start',
    fontWeight : 'normal',
    outlineColor : '#000000',
    textOutline : 0,

    main_fill : "#6E6E6E",
    main_color : "#ffffff",
    sub_fill : "#FF9500",
    sub_color : "#ffffff",

    fillOpacity : 100,
    ...filterDefault,
    ...transformDefault,
  },
  value: {
    schedule : [],
    speed : 5
  },
  display:{
    show : true
  },
  preset : 'normal'
}

function BroadcastAlertModel(data) {
  let layout = {}
  let object = {}
  let style = standardObject()
  let property = Object.keys(data)
  if(property.includes('position')){
    layout = Object.assign(layout,position(data.position))
  }
  if(property.includes('size')){
    object = Object.assign(object,size(data.size,200,'auto'))
  }
  if(property.includes('style')){
    // 외곽선 
    object = Object.assign(object,border(data.style))
    // 폰트
    object = Object.assign(object,font(data.style))
    // 필터 + 그림자 + 투명도
    object = Object.assign(object,filter(data.style))
    // 모핑
    layout = Object.assign(layout,morphing(data.style))
    object = Object.assign(object,morphing(data.style))
    //배경색

    // 변형
    object = Object.assign(object,transform(data.style))
  }
  return {layout,object}
}

////////////////////////////////////////////////////////
// NextScheduleReference 메모
////////////////////////////////////////////////////////

const NextSchedulePreset = [
  {name:'normal_Horizontal', nameKo:'노말스타일가로 ', ratio:3,},
  {name:'normal_Vertical', nameKo:'노말스타일', ratio:3,},

  // {name:'orange', nameKo:'오렌지스타일', ratio:3},
]

const NextScheduleReference = {
  position: {x: 20, y: 20, show : true},
  size: {width: 200, height: 200},
  style : {
    fontSize : 50,
    fontFamily : '나눔스퀘어',
    shadowValue: "0", 
    shadowDirection : "RightBottom",
    shadowColor: "#000000",
    morphing: false, 
    fill : "#000000",
    useFill : false,
    color : "#ffffff",
    textAlign: 'center',
    verticalAlign : 'flex-start',
    fontWeight : 'normal',
    outlineColor : '#000000',
    textOutline : 0,
    ...filterDefault,
    ...transformDefault,
  },
  value: {
    option: null
  },
  display:{
    show : true
  },
  preset : 'normal'
}

function NextScheduleModel(data) {
  let layout = {}
  let object = {}
  let style = standardObject()
  let property = Object.keys(data)
  if(property.includes('position')){
    layout = Object.assign(layout,position(data.position))
  }
  if(property.includes('size')){
    object = Object.assign(object,minSize(data.size,200,'auto'))
  }
  if(property.includes('style')){
    // 외곽선 
    object = Object.assign(object,border(data.style))
    // 폰트
    object = Object.assign(object,font(data.style))
    // 필터 + 그림자 + 투명도
    object = Object.assign(object,filter(data.style))
    // 모핑
    layout = Object.assign(layout,morphing(data.style))
    object = Object.assign(object,morphing(data.style))
    //배경색
    if (data.style.useFill){
      object = Object.assign(object,fill(data.style))
    }
    // 변형
    object = Object.assign(object,transform(data.style))
  }
  return {layout,object}
}

////////////////////////////////////////////////////////
// ScrollCaptionReference 메모
////////////////////////////////////////////////////////
const ScrollCaptionReference = {
  position: {x: 0, y: 1030, show : true},
  size: {width: 1920, height: 50},
  style : {
    fontSize : 40,
    fontFamily : '한나체',
    color:"#ffffff",
    shadowValue: "0", 
    shadowDirection : "RightBottom",
    shadowColor: "#000000",
    morphing: false, 
    fill : "#000000",
    fillOpacity :70,
    textAlign: 'left',
    fontWeight : 'normal',
    textOutline : 0,
    borderRadius : 0,
    ...filterDefault,
    ...transformDefault,
  },
  value: {
    text:'',
    speed:15,
    option: null
  },
  display:{
    show : true
  },
  preset : 'normal'
}

function ScrollCaptionModel(data) {
  let layout = {}
  let object = {}
  let style = standardObject()
  let property = Object.keys(data)
  if(property.includes('position')){

    layout = Object.assign(layout,position(data.position))
  }
  if(property.includes('size')){
    object = Object.assign(object,size(data.size))
  }
  if(property.includes('style')){
    // 외곽선 
    object = Object.assign(object,border(data.style))
    // 폰트
    object = Object.assign(object,font(data.style))
    // 필터 + 그림자 + 투명도
    object = Object.assign(object,filter(data.style))
    // 모핑
    layout = Object.assign(layout,morphing(data.style))
    object = Object.assign(object,morphing(data.style))
    //배경색
    // 변형
    object = Object.assign(object,transform(data.style))
  }
  return {layout,object}
}

////////////////////////////////////////////////////////
// NewsCaptionReference 뉴스
////////////////////////////////////////////////////////
const NewsCaptionPreset = [
  {name:'normal', nameKo:'노말스타일 ', ratio:3,}
  // {name:'orange', nameKo:'오렌지스타일', ratio:3},
]


const NewsCaptionReference = {
  position: {x: 200, y: 780, show : true},
  size: {width: 1520, height: 210},
  style : {

    header_fontSize : 42,
    header_fontFamily : '에스코어드림Bold',
    header_color:"#ffffff",
    header_textAlign: 'left',
    header_fill : "#1728AB",
    header_fillOpacity :85,

    fontSize : 63,
    fontFamily : '에스코어드림Bold',
    color:"#05193D",
    textAlign: 'left',
    fill : "#ffffff",
    fillOpacity :85,

    shadowValue: "0", 
    shadowDirection : "RightBottom",
    shadowColor: "#000000",
    morphing: false, 
    fontWeight : 'normal',
    textOutline : 0,
    borderRadius : 0,
    ...filterDefault,
    ...transformDefault,
  },
  value: {
    header_text:'',
    text:'',
    option: null
  },
  display:{
    show : true
  },
  preset : 'normal'
}

function NewsCaptionModel(data) {
  let layout = {}
  let object = {}
  let header = {}
  let headerBox = {}
  let bodyBox = {}
  let body = {}
  let style = standardObject()
  let property = Object.keys(data)
  if(property.includes('position')){
    layout = Object.assign(layout,position(data.position))
  }
  if(property.includes('size')){
    object = Object.assign(object,size(data.size))
  }
  if(property.includes('style')){
    // 외곽선 
    object = Object.assign(object,border(data.style))
    // 폰트
    header = Object.assign(header,font(data.style,'header'))
    body = Object.assign(body,font(data.style))
    // 필터 + 그림자 + 투명도
    object = Object.assign(object,filter(data.style))
    // 모핑
    layout = Object.assign(layout,morphing(data.style))
    object = Object.assign(object,morphing(data.style))
    //배경색
    headerBox = Object.assign(headerBox,fill(data.style,'header'))
    bodyBox = Object.assign(bodyBox,fill(data.style))
    // 변형
    object = Object.assign(object,transform(data.style))
  }
  return {layout,object,header,body,headerBox,bodyBox}
}



////////////////////////////////////////////////////////
// LiveIconReference 메모
////////////////////////////////////////////////////////

const LiveIconPreset = [
  {name:'liveCamIcon1', nameKo:'카메라모양 아이콘', ratio:1},
  {name:'liveCircle1', nameKo:'원모양 아이콘', ratio:1},
  {name:'liveSpeechIcon1', nameKo:'말풍선 아이콘', ratio:1},
  {name:'liveSquare1', nameKo:'사각형 아이콘1', ratio:1},
  {name:'liveSquare2', nameKo:'사각형 아이콘2', ratio:1},
]



const LiveIconReference = {
  position: {x: 1830, y: 0, show : true},
  size: {width: 80, height: 80},
  style : {

    shadowValue: "0", 
    shadowDirection : "RightBottom",
    shadowColor: "#000000",
    morphing: false, 
    outlineColor : '#000000',
    textOutline : 0,
    ...filterDefault,
    ...transformDefault,
  },
  value: {
    option: null
  },
  display:{
    show : true
  },
  preset : 'liveSpeechIcon1'
}

function LiveIconModel(data) {
  let layout = {}
  let object = {}
  let style = standardObject()
  let property = Object.keys(data)
  if(property.includes('position')){
    layout = Object.assign(layout,position(data.position))
  }
  if(property.includes('size')){
    object = Object.assign(object,size(data.size,200,'auto'))
  }
  if(property.includes('style')){
    // 외곽선 
    object = Object.assign(object,border(data.style))
    // 폰트
    object = Object.assign(object,font(data.style))
    // 필터 + 그림자 + 투명도
    object = Object.assign(object,filter(data.style))
    // 모핑
    layout = Object.assign(layout,morphing(data.style))
    object = Object.assign(object,morphing(data.style))
    //배경색
    if (data.style.useFill){
      object = Object.assign(object,fill(data.style))
    }
    // 변형
    object = Object.assign(object,transform(data.style))
  }
  return {layout,object}
}

////////////////////////////////////////////////////////
// DdayReference 메모
////////////////////////////////////////////////////////
const DdayPreset = [
  {name:'calendar', nameKo:'달력스타일 ', ratio:1,style:{'main_fill':'#F7676E'},size:{'width':200,'height':200}},
  {name:'miniWidget', nameKo:'미니위젯스타일', ratio:5,size:{'minWidth':400}},
]

const DdayReference = {
  position: {x: 20, y: 20, show : true},
  size: {width: 200, height: 200},
  style : {
    fontSize : 30,
    fontFamily : '나눔스퀘어',
    shadowValue: "0", 
    shadowDirection : "RightBottom",
    shadowColor: "#000000",
    morphing: false, 
    
    color : "#ffffff",
    textAlign: 'center',
    verticalAlign : 'flex-start',
    fontWeight : 'normal',
    outlineColor : '#000000',
    textOutline : 0,
    main_fill : "#F7676E",
    main_color : "#ffffff",
    sub_fill : "#ffffff",
    sub_color : "#000000",

    fillOpacity : 100,
    ...filterDefault,
    ...transformDefault,
  },
  value: {
    info: '',
    date: null,
  },
  display:{
    show : true
  },
  preset : 'calendar'
}

function DdayModel(data) {
  let layout = {}
  let object = {}
  let style = standardObject()
  let property = Object.keys(data)
  if(property.includes('position')){
    layout = Object.assign(layout,position(data.position))
  }
  if(property.includes('size')){
    object = Object.assign(object,size(data.size,200,'auto'))
  }
  if(property.includes('style')){
    // 외곽선 
    object = Object.assign(object,border(data.style))
    // 폰트
    object = Object.assign(object,font(data.style))
    // 필터 + 그림자 + 투명도
    object = Object.assign(object,filter(data.style))
    // 모핑
    layout = Object.assign(layout,morphing(data.style))
    object = Object.assign(object,morphing(data.style))
    //배경색

    // 변형
    object = Object.assign(object,transform(data.style))
  }
  return {layout,object}
}


////////////////////////////////////////////////////////
//  DraftPick 드레프트픽 (경매) 위젯
////////////////////////////////////////////////////////
const DraftPickPreset = [
  {name:'normal', nameKo:'기본형 모든포지션', ratio:5},
  {name:'BusinessCard', nameKo:'명함형', ratio:1.8181},
  {name:'simple', nameKo:'심플스타일 모든포지션', ratio:8.5714},
  {name:'simpleShort', nameKo:'심플스타일 한포지션', ratio:2.8571},
  {name:'clear', nameKo:'투명스타일 모든포지션', ratio:8.5714},
  {name:'clearShort', nameKo:'투명스타일 한포지션', ratio:2.8571},
]

const DraftPickReference = {
  position: {x: "20", y: "20", show : true},
  size: {width: "560", height: "250"},
  style : {
    fontFamily : '에스코어드림Bold',
    shadowValue: "0", 
    shadowDirection : "RightBottom",
    shadowColor: "#000000",
    morphing: false, 
    main_fill : "#FFC000",
    main_color : "#000000",
    sub_fill : "#ffffff",
    sub_color : "#000000",
    fillOpacity : 100,
    textOutline : 0,
    borderRadius : 0,
    ...filterDefault,
    ...transformDefault,
  },
  value: {
    point : 0,
    // {name:,position:,:memo,},
    name : '',
    member : [],
    leader : 0,
    chance :[],
    option: null,
  },
  display:{
    show : true
  },
  preset : 'BusinessCard'
}

function DraftPickModel(data) {
  let layout = {}
  let object = {}
  let objectFill = {}
  let style = standardObject()
  let property = Object.keys(data)
  if(property.includes('position')){
    layout = Object.assign(layout,position(data.position))
  }
  if(property.includes('size')){
    object = Object.assign(object,size(data.size))
  }
  if(property.includes('style')){
    //폰트
    object = Object.assign(object,font(data.style))
    // 외곽선 
    object = Object.assign(object,border(data.style))
    // 필터 + 그림자 + 투명도
    object = Object.assign(object,filter(data.style))
    objectFill = Object.assign(objectFill,fill(data.style))
    objectFill = Object.assign(objectFill,fill(data.style))
    objectFill = Object.assign(objectFill,fill(data.style))
    // 모핑
    layout = Object.assign(layout,morphing(data.style))
    object = Object.assign(object,morphing(data.style))
    // 변형
    object = Object.assign(object,transform(data.style))
  }
  return {layout,object,objectFill}
}

////////////////////////////////////////////////////////
// OverwatchHeroes 영웅 목록 위젯
////////////////////////////////////////////////////////
const OverwatchHeroesReference = {
  position: {x: "20", y: "20", show : true},
  size: {width: "200", height: "200"},
  style : {
    shadowValue: "0", 
    shadowDirection : "RightBottom",
    shadowColor: "#000000",
    morphing: false, 
    fill : "#000000",
    useFill : false,
    textAlign: 'left',
    fontWeight : 'normal',
    textOutline : 0,

    ...filterDefault,
    ...transformDefault,
  },
  value: {
    heroes : null,
    option: null
  },
  display:{
    show : true
  },
  preset : 'normal'
}

function OverwatchHeroesModel(data) {
  let layout = {}
  let object = {}
  let style = standardObject()
  let property = Object.keys(data)
  if(property.includes('position')){
    layout = Object.assign(layout,position(data.position))
  }
  if(property.includes('size')){
    object = Object.assign(object,size(data.size))
  }
  if(property.includes('style')){
    //색상
    if (data.style.useFill){
      object = Object.assign(object,fill(data.style))
    }
    // 외곽선 
    object = Object.assign(object,border(data.style))
    // 필터 + 그림자 + 투명도
    object = Object.assign(object,filter(data.style))
    // 모핑
    layout = Object.assign(layout,morphing(data.style))
    object = Object.assign(object,morphing(data.style))
    // 변형
    object = Object.assign(object,transform(data.style))


  }
  return {layout,object}
}

////////////////////////////////////////////////////////
// GameCharacters 영웅 목록 위젯
////////////////////////////////////////////////////////
const GameCharactersReference = {
  position: {x: "20", y: "20", show : true},
  size: {width: "200", height: "200"},
  style : {
    shadowValue: "0", 
    shadowDirection : "RightBottom",
    shadowColor: "#000000",
    morphing: false, 
    fill : "#000000",
    useFill : false,
    textAlign: 'left',
    fontWeight : 'normal',
    textOutline : 0,
    borderRadius : 0,
    objectFit : 'cover',
    ...filterDefault,
    ...transformDefault,
  },
  value: {
    game : "overwatch",
    characters : "Ana",
    imageStyle : "기본스타일",
    option: null
  },
  display:{
    show : true
  },
  preset : 'normal'
}

function GameCharactersModel(data) {
  let layout = {}
  let object = {}
  let style = standardObject()
  let property = Object.keys(data)
  if(property.includes('position')){
    layout = Object.assign(layout,position(data.position))
  }
  if(property.includes('size')){
    object = Object.assign(object,size(data.size))
  }
  if(property.includes('style')){
    //색상
    if (data.style.useFill){
      object = Object.assign(object,fill(data.style))
    }
    // 외곽선 
    object = Object.assign(object,border(data.style))
    // 필터 + 그림자 + 투명도
    object = Object.assign(object,filter(data.style))
    // 모핑
    layout = Object.assign(layout,morphing(data.style))
    object = Object.assign(object,morphing(data.style))
    // 변형
    object = Object.assign(object,transform(data.style))
    object = Object.assign(object,objectFit(data.style))

  }
  return {layout,object}
}

////////////////////////////////////////////////////////
// GameMap 게임 맵
////////////////////////////////////////////////////////
const GameMapsReference = {
  position: {x: "20", y: "20", show : true},
  size: {width: "200", height: "200"},
  style : {
    shadowValue: "0", 
    shadowDirection : "RightBottom",
    shadowColor: "#000000",
    morphing: false, 
    fill : "#000000",
    useFill : false,
    textAlign: 'left',
    fontWeight : 'normal',
    textOutline : 0,
    borderRadius : 0,
    objectFit : 'cover',
    ...filterDefault,
    ...transformDefault,
  },
  value: {
    game : "valorant",
    maps : "Fracture",
    imageStyle : "기본스타일",
    option: null
  },
  display:{
    show : true
  },
  preset : 'normal'
}

function GameMapsModel(data) {
  let layout = {}
  let object = {}
  let style = standardObject()
  let property = Object.keys(data)
  if(property.includes('position')){
    layout = Object.assign(layout,position(data.position))
  }
  if(property.includes('size')){
    object = Object.assign(object,size(data.size))
  }
  if(property.includes('style')){
    //색상
    if (data.style.useFill){
      object = Object.assign(object,fill(data.style))
    }
    // 외곽선 
    object = Object.assign(object,border(data.style))
    // 필터 + 그림자 + 투명도
    object = Object.assign(object,filter(data.style))
    // 모핑
    layout = Object.assign(layout,morphing(data.style))
    object = Object.assign(object,morphing(data.style))
    // 변형
    object = Object.assign(object,transform(data.style))
    object = Object.assign(object,objectFit(data.style))
    
  }
  return {layout,object}
}


////////////////////////////////////////////////////////
// OverwatchProfile 오버워치 프로필 (정보) 위젯
////////////////////////////////////////////////////////
const OverwatchProfilePreset = [
  {name:'normal', nameKo:'오버워치 프로필 기본형', ratio:19.2},
  {name:'small', nameKo:'오버워치 프로필 소형', ratio:19.2},
]

const OverwatchProfileReference = {
  position: {x: "20", y: "20", show : true},
  size: {width: "800", height: "800"},
  style : {
    fontFamily : '에스코어드림Bold',
    shadowValue: "0", 
    shadowDirection : "RightBottom",
    shadowColor: "#000000",
    morphing: false, 
    profile_fill : "#f7e4a8",
    profile_color : "#000000",
    databox_fill : "#a52a2a",
    databox_color : "#ffffff",
    textOutline : 0,
    borderRadius : 0,
    ...filterDefault,
    ...transformDefault,
  },
  value: {
    userAlias :null,
    battleCode : null,
    tierShow : true,
    pointShow : true,
    heroPosition : 'all',
    heroData : null,
    speed : 2,
    displayMode : 'standard',
    option: null,
  },
  display:{
    show : true
  },
  preset : 'normal',
}

function OverwatchProfileModel(data) {
  let layout = {}
  let object = {}
  let profile = {}
  let databox ={}
  let objectFill = {}
  let style = standardObject()
  let property = Object.keys(data)
  if(property.includes('position')){
    layout = Object.assign(layout,position(data.position))
  }
  if(property.includes('size')){
    object = Object.assign(object,size(data.size))
  }
  if(property.includes('style')){

    profile = Object.assign(profile,fill(data.style,'profile'))
    databox = Object.assign(databox,fill(data.style,'databox'))
    profile = Object.assign(profile,font(data.style,'profile'))
    databox = Object.assign(databox,font(data.style,'databox'))
    // 외곽선 
    object = Object.assign(object,border(data.style))
    // 필터 + 그림자 + 투명도
    object = Object.assign(object,filter(data.style))
    objectFill = Object.assign(objectFill,fill(data.style))
    // 모핑
    layout = Object.assign(layout,morphing(data.style))
    object = Object.assign(object,morphing(data.style))
    // 변형
    object = Object.assign(object,transform(data.style))
    
  }
  return {layout,object,objectFill,profile,databox}
}



////////////////////////////////////////////////////////
// OverwatchRank 오버워치 경쟁전 점수 (티어) 위젯
////////////////////////////////////////////////////////
const OverwatchRankPreset = [
  {name:'normal', nameKo:'기본형 모든포지션', ratio:5},
  {name:'normalShort', nameKo:'기본형 한포지션', ratio:1.8181},
  {name:'simple', nameKo:'심플스타일 모든포지션', ratio:8.5714},
  {name:'simpleShort', nameKo:'심플스타일 한포지션', ratio:2.8571},
  {name:'clear', nameKo:'투명스타일 모든포지션', ratio:8.5714},
  {name:'clearShort', nameKo:'투명스타일 한포지션', ratio:2.8571},
]

const OverwatchRankReference = {
  position: {x: "20", y: "20", show : true},
  size: {width: "400", height: "80"},
  style : {
    fontFamily : '에스코어드림Bold',
    shadowValue: "0", 
    shadowDirection : "RightBottom",
    shadowColor: "#000000",
    morphing: false, 
    fill : "#222222",
    textOutline : 0,
    borderRadius : 0,
    ...filterDefault,
    ...transformDefault,
  },
  value: {
    userAlias :null,
    battleCode : null,
    nameShow : false,
    tierShow : true,
    pointShow : true,
    heroPosition : 'all',
    heroData : null,
    speed : 2,
    displayMode : 'standard',
    option: null,
  },
  display:{
    show : true
  },
  preset : 'normal'
}

function OverwatchRankModel(data) {
  let layout = {}
  let object = {}
  let objectFill = {}
  let style = standardObject()
  let property = Object.keys(data)
  if(property.includes('position')){
    layout = Object.assign(layout,position(data.position))
  }
  if(property.includes('size')){
    object = Object.assign(object,size(data.size))
  }
  if(property.includes('style')){
    // 외곽선 
    object = Object.assign(object,border(data.style))
    // 필터 + 그림자 + 투명도
    object = Object.assign(object,filter(data.style))
    objectFill = Object.assign(objectFill,fill(data.style))
    // 모핑
    layout = Object.assign(layout,morphing(data.style))
    object = Object.assign(object,morphing(data.style))
    // 변형
    object = Object.assign(object,transform(data.style))
  }
  return {layout,object,objectFill}
}



////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// 포지션 레퍼런스
////////////////////////////////////////////////////////////////////////////////////////////////////////////////

function position (property) {
  let keys = Object.keys(property)
  let result = {
    top: '192px',
    left: '108px',
  }

  if(keys.includes('x')){
    result['left'] = property.x +'px'
  }

  if(keys.includes('y')){
    result['top'] = property.y +'px'
  }
  return result
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// 사이즈 레퍼런스
////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function lineSize (property,width='200px',height='200px') {
  let keys = Object.keys(property)
  let result = {
    width,
    height,
  }
  if(keys.includes('width')){
    if (property.width=='auto'){
      result['width'] = 'auto'
    }else {
      result['width'] = property.width +'px'
    }
  }
  return result
}



function size (property,width='200px',height='200px') {
  
  let keys = Object.keys(property)
  let result = {
    width,
    height,
  }
  if(keys.includes('width')){
    if (String(property.width).indexOf('%')!=-1){
      const state = store.getState(); // 현재 상태를 가져옵니다.
      const {broadCast} = state; // 편의상 비구조화 할당
      result['width'] = (broadCast.size.width/100*Number(property.width.substring(0,(property.width).indexOf('%'))))+'px'
    } 
    else if (property.width=='auto'){
      result['width'] = 'auto'
    } else {
      result['width'] = property.width +'px'
    }
  }

  if(keys.includes('height')){
    if (String(property.height).indexOf('%')!=-1){
      const state = store.getState(); // 현재 상태를 가져옵니다.
      const {broadCast} = state; // 편의상 비구조화 할당
      result['height'] = (broadCast.size.height/100*Number(property.height.substring(0,(property.height).indexOf('%'))))+'px'
    } 
    else if (property.result=='auto'){
      result['height'] = 'auto'
    }else {
      result['height'] = property.height +'px'
    }
  }

  // console.log(result,'result')
  return result
}


function minSize (property,width='200px',height='200px') {
  let keys = Object.keys(property)
  let result = {
    width,
    height,
  }

  if(keys.includes('width')){
    if (property.width=='auto'){
      result['minWidth'] = 'auto'
    }else {
      result['minWidth'] = property.width +'px'
    }
  }

  if(keys.includes('height')){
    if (property.result=='auto'){
      result['minHeight'] = 'auto'
    }else {
      result['minHeight'] = property.height +'px'
    }
  }
  return result
}


function maxSize (property,width='200px',height='200px') {
  let keys = Object.keys(property)
  let result = {
    width,
    height,
  }

  if(keys.includes('width')){
    if (property.width=='auto'){
      result['maxWidth'] = 'auto'
    }else {
      result['maxWidth'] = property.width +'px'
    }
  }

  if(keys.includes('height')){
    if (property.result=='auto'){
      result['maxHeight'] = 'auto'
    }else {
      result['maxHeight'] = property.height +'px'
    }
  }
  return result
}


////////////////////////////////////////////////////////
// 속성 객체
////////////////////////////////////////////////////////
function shadowOption (value,color=null,direction=null) {

  if (!direction){direction="RightBottom"}
  if (!value){return}
  if (!color){color='#000000'}

  let res = ''
  let opacity = Math.round(value/10 * 255).toString(16)
  switch (direction) {
    // case 'LeftTop':
    //   res = 'drop-shadow(-5px -5px 5px '+color+opacity+') drop-shadow(-35px -35px 25px '+color+'08'+') '
    //   break;
    // case 'LeftBottom':
    //   res = 'drop-shadow(-5px 5px 5px '+color+opacity+') drop-shadow(-35px 35px 25px '+color+'08'+') '
    //   break;
    // case 'RightTop':
    //   res = 'drop-shadow(5px -5px 5px '+color+opacity+') drop-shadow(35px -35px 25px '+color+'08'+') '
    //   break;
    // case 'RightBottom':
    //   res = 'drop-shadow(5px 5px 5px '+color+opacity+') drop-shadow(35px 35px 25px '+color+'08'+') '
    //   break;
    // default:
    //   break;
  }
  return res
}



// 필터
function filter (property,subName=null) {
  console.log('확인 미사용 필터 :',property,subName)
  if (subName){
    subName =subName+'_'
  }else{
    subName = ''
  }

  let keys = Object.keys(property)
  let result =''

  // 밝기
  if(keys.includes(subName+'brightness')){
    result += 'brightness('+property[subName+'brightness']+'%) '
  }
  if(keys.includes(subName+'contrast')){
    result += 'contrast('+property[subName+'contrast']+'%) '
  }

  //  그림자 

  let shadowColor = null
  let shadowValue = null
  let shadowDirection = null
  if(keys.includes(subName+'shadowColor')){
    shadowColor = property[subName+'shadowColor']
  }
  if(keys.includes(subName+'shadowValue')){
    shadowValue = property[subName+'shadowValue']
  }
  if(keys.includes(subName+'shadowDirection')){
    shadowDirection = property[subName+'shadowDirection']
  }
  if(shadowValue!=0){
    result += shadowOption(shadowValue,shadowColor,shadowDirection)
  }

  // console.log(shadowColor)
  // if(keys.includes(subName+'shadowColor')){
  //   shadowColor = property[subName+'shadowColor']
  // }
  // if(keys.includes(subName+'shadowValue')){
  //  let shadowValue = property[subName+'shadowValue']
  //   if (shadowValue>0){
  //     result += 'drop-shadow(0px 0px '+shadowValue+'px '+shadowColor+') '
      
  //   }
  // }
  //      result['boxShadow'] = shadowColor+'0d 0px '+shadowValue*7+'px '+shadowValue*5+'px, '+shadowColor+'4d 0px '+shadowValue+'px '+shadowValue+'px' 


  //  투명도 
  if(keys.includes(subName+'opacity')){
    let opacityVlaue = property[subName+'opacity']
    if (opacityVlaue>=0){
      result += 'opacity('+opacityVlaue+'%) '
    }
  }

  // 필터
  if(keys.includes(subName+'blur')){
    result += 'blur('+property[subName+'blur']+'px) '
  }
  if(keys.includes(subName+'grayscale')){
    result += 'grayscale('+property[subName+'grayscale']+'%) '
  }
  if(keys.includes(subName+'hueRotate')){
    result += 'hue-rotate('+property[subName+'hueRotate']+'deg) '
  }
  if(keys.includes(subName+'invert')){
    result += 'invert('+property[subName+'invert']+'%) '
  }
  if(keys.includes(subName+'saturate')){
    result += 'saturate('+property[subName+'saturate']+'%) '
  }
  if(keys.includes(subName+'sepia')){
    result += 'sepia('+property[subName+'sepia']+'%) '
  }
  return {filter : result}
}

function shotfilter (property,subName=null) {
  let keys = Object.keys(property)
  let result =''
  // 필터
  if(keys.includes('blur')){
    result += 'blur('+property.blur+'px) '
  }
  if(keys.includes('grayscale')){
    result += 'grayscale('+property.grayscale+'%) '
  }
  if(keys.includes('hueRotate')){
    result += 'hue-rotate('+property.hueRotate+'deg) '
  }
  if(keys.includes('invert')){
    result += 'invert('+property.invert+'%) '
  }
  if(keys.includes('saturate')){
    result += 'saturate('+property.saturate+'%) '
  }
  if(keys.includes('sepia')){
    result += 'sepia('+property.sepia+'%) '
  }
  return {filter : result}
}


function shadow (property,subName=null) {
    // drop-shadow(rgb(0, 0, 0) 0px 0px 8px)
  //  'rgb(0 0 0 / 5%) 0px '+shadowValue*7+'px '+shadowValue*5+'px, rgb(0 0 0 / 30%) 0px '+shadowValue+'px '+shadowValue+'px'
  // 0d 4d 
  // if (subName){
  //   subName =subName+'_'
  // }else{
  //   subName = ''
  // }

  // let keys = Object.keys(property)
  // let result ={}
  // //  그림자 
  // let shadowColor = '#000000'

  // if(keys.includes(subName+'shadowColor')){
  //   shadowColor = property[subName+'shadowColor']
  // }
  // if(keys.includes(subName+'shadowValue')){
  //  let shadowValue = property[subName+'shadowValue']
  //   if (shadowValue>0){
  //     result['boxShadow'] = shadowColor+'0d 0px '+shadowValue*7+'px '+shadowValue*5+'px, '+shadowColor+'4d 0px '+shadowValue+'px '+shadowValue+'px' 
  //   }
  // }
  // return result
}
// 컬러
function fill (property,subName=null,key='background') {
  if (subName){
    subName =subName+'_'
  }else{
    subName = ''
  }
  let keys = Object.keys(property)
  let result = {}

  if(keys.includes(subName+'fill')){
    if(property[subName+'fill']){
      if (typeof(property[subName+'fill'])=='object'){

        let fillKeys = Object.keys(property[subName+'fill'])
        switch (property[subName+'fill'].type) {
          case 'monochrome':
            if(keys.includes(subName+'fillOpacity')){

              let opacity= Math.round(subName+'fillOpacity'/100 * 255).toString(16)
              if (opacity.length==1){ opacity= 0+opacity }

              result[key] = property[subName+'fill'].value+opacity

            }else{
              result[key] = property[subName+'fill'].value
            }
            break;
          case 'gradation':
            result[key] = property[subName+'fill'].value
            break;
          case 'image':
            result[key] = property[subName+'fill'].value
            break;
          default:
          console.error('존재하지 않는 fill 옵션',property[subName+'fill'].type)
            break;
        }
      } else {
        if(keys.includes(subName+'fillOpacity')){
          let opacity= Math.round(property[subName+'fillOpacity']/100 * 255).toString(16)
          if (opacity.length==1){ opacity= 0+opacity }
          result[key] = property[subName+'fill']+opacity
        }else{
          result[key] = property[subName+'fill']
        }
      }
    }
  }
  return result
}
// 모션
function transform (property,subName=null) {
  if (subName){
    subName =subName+'_'
  }else{
    subName = ''
  }
  let keys = Object.keys(property)
  let result =''

  // 필터
  if(keys.includes(subName+'rotateZ')){
    result += 'rotateZ('+property[subName+'rotateZ']+'deg) '
  }
  if(keys.includes(subName+'rotateY')){
    result += 'rotateY('+property[subName+'rotateY']+'deg) '
  }
  if(keys.includes(subName+'rotateX')){
    result += 'rotateX('+property[subName+'rotateX']+'deg) '
  }
  if(keys.includes(subName+'scale')){
    result += 'scale('+property[subName+'scale']/100+','+property[subName+'scale']/100+') '
  }
  return {transform : result}
}

// 외곽선
function border (property) {
  let keys = Object.keys(property)
  let result = {
  }
  if(keys.includes('borderColor')){
    result['borderColor'] = property.borderColor
  }
  if(keys.includes('borderStyle')){
    result['borderStyle'] = property.borderStyle
  }
  if(keys.includes('borderRadius')){
    result['borderRadius'] = property.borderRadius +'px'
  }
  if(keys.includes('borderWidth')){
    result['borderWidth'] = property.borderWidth +'px'
  }
  return result
}

// 외곽선
function stroke (property,subName=null) {
  if (subName){
    subName =subName+'_'
  }else{
    subName = ''
  }
  let keys = Object.keys(property)
  let result = {
  }
  if(keys.includes(subName+'strokeColor')){
    result['stroke'] = property[subName+'strokeColor']
  }
  if(keys.includes(subName+'strokeWidth')){
    result['strokeWidth'] = property[subName+'strokeWidth']
  }
  return result
}



// 모핑
function morphing (property) {
  let result = {}
  if(Object.keys(property).includes('morphing')&&property.morphing==true){

    if(Object.keys(property).includes('morphingSpped')){
      if (property) {
        result = {transition : 'all '+widget.morphingSpped+'s'}
        return result
      }
    }
    else {
      result = {transition : 'all 1s'}
      return result
    }
  }
  return result
}



// 폰트
function font (property,subName=null) {
  if (subName){
    subName =subName+'_'
  }else{
    subName = ''
  }
  let keys = Object.keys(property)
  let result = {
 
  }
  let outline = ''
  let shadow = ''
  let rest = ''
  let textShadowColor = '#000000'

  if(keys.includes(subName+'color')){
    result['color'] = property[subName+'color']

  }
  if(keys.includes(subName+'fontSize')){
    result['fontSize'] = property[subName+'fontSize']+'px'
  }
  if(keys.includes(subName+'fontWeight')){
    result['fontWeight'] = property[subName+'fontWeight']
  }
  if(keys.includes(subName+'fontFamily')){
    result['fontFamily'] = property[subName+'fontFamily']
  }
  if(keys.includes(subName+'textAlign')){
    result['textAlign'] = property[subName+'textAlign']
  }
  if(keys.includes(subName+'verticalAlign')){
    result['textVerticalAlign'] = property[subName+'verticalAlign']
  }
  if(keys.includes(subName+'textOutline')&&keys.includes(subName+'outlineColor')){
    if (property[subName+'textOutline']>0){
      result['-webkit-text-stroke'] = property[subName+'textOutline']/100 +'px '+property[subName+'outlineColor']
      // let textOutline = property[subName+'textOutline']/100
      // outline = '-'+property[subName+'fontSize']/25*textOutline+'px 0 '+property[subName+'outlineColor']+', 0 '+property[subName+'fontSize']/25*textOutline+'px '+property[subName+'outlineColor']+', '+property[subName+'fontSize']/25*textOutline+'px 0 '+property[subName+'outlineColor']+', 0 -'+property[subName+'fontSize']/25*textOutline+'px '+property[subName+'outlineColor'] 
    }
  }
  if(keys.includes(subName+'textShadowColor')){
    textShadowColor = property[subName+'textShadowColor']
  }
  if(keys.includes(subName+'textShadowValue')){
    if(outline!=''){rest =' , '}
   let textShadowValue = property[subName+'textShadowValue']
    if (textShadowValue>0){
      shadow = textShadowColor+'0d 0px '+textShadowValue*7+'px '+textShadowValue*5+'px, '+textShadowColor+'4d 0px '+textShadowValue+'px '+textShadowValue+'px' 
    }
  }



  result['textShadow'] = outline + rest + shadow
  return result
}


// 오브젝트 핏 
function objectFit (property,subName=null) {
  if (subName){
    subName =subName+'_'
  }else{
    subName = ''
  }
  let keys = Object.keys(property)
  let result = {
  }
  if(keys.includes(subName+'objectFit')){
    result['objectFit'] = property[subName+'objectFit']
  }
  return result
}

// 오브젝트 핏 
function clipPath (property,subName=null) {
  if (subName){
    subName =subName+'_'
  }else{
    subName = ''
  }
  let keys = Object.keys(property)
  let result = {
  }
  if(keys.includes(subName+'clipNode')){
    let nodeList = property[subName+'clipNode']
    if (nodeList.length<=2){
      return result
    }
    let clipPath = 'polygon('
    for (let index = 0; index < nodeList.length; index++) {
      if (nodeList[index][0]!=null&&nodeList[index][1]!=null){
        clipPath = clipPath + (nodeList[index][0]/100).toFixed(2)+'% '+(nodeList[index][1]/100).toFixed(2)+'%, '
      }
    }
    clipPath = clipPath.slice(0, - 2);
    clipPath = clipPath+ ')'

    result['clipPath'] = clipPath
  }
  return result
}



