////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widgetEdit.scss"


// 컴포넌트 연결
import SizeModule from '../editModule/SizeModule';//위치 모듈

// 서비스 연결
import * as Model from 'service/value/Model' ; 

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class LineEdit extends Component {
    
    state = {
    }

    change = (name,value) =>{
        this.props.callBack('size',name,value)

    }

    render() { 

        const Style = {
            label: {
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
            },
            textarea : {
                width: '100%',
                resize: 'none'
            }
        }        
        let widthValue = 200
        let heightValue = 200

        if(this.props.data.size.width){
            widthValue = this.props.data.size.width
        }

        return (
            <fieldset className="EditModuleField" style={Style.fieldset} >
                <legend style={Style.legend} > 크기 </legend>
                    {/* <SizeModule value={this.props.data.size.width} max={this.props.broadCast.size.width} title="길이" type ="width" change={this.change}/> */}
            </fieldset>
        );
    }
}

////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////

const mapStateToProps = (state) => ({
    // exemple : state.exemple,
})

function mapDispatchToProps(dispatch){
    return {
    //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(LineEdit);
  