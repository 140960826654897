////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { useState, useEffect, useContext, useRef, Fragment } from 'react';

// [프로바이더]

// 서비스 연결

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

function VideoControlBar({time}){


    ////////////////////////////////////////////////////////
    // Const
    ////////////////////////////////////////////////////////


    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////

    const [currentTime, setCurrentTime] = useState(Date.now());

    useEffect(() => {

        const intervalId = setInterval(() => {
            setCurrentTime(Date.now());
          }, 1000);
      
          // 컴포넌트 언마운트 시 인터벌 제거
          return () => clearInterval(intervalId);
      }, []);

    ////////////////////////////////////////////////////////
    // Func
    ////////////////////////////////////////////////////////
    const formatTime = (seconds) => {
        const hrs = Math.floor(seconds / 3600);
        const mins = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;
      
        // 시간 부분은 1시간 이상일 때만 포함
        if (hrs > 0) {
          return `${hrs}:${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
        } else {
          return `${mins}:${secs.toString().padStart(2, '0')}`;
        }
      }
    
    ////////////////////////////////////////////////////////
    // Widget lander
    ////////////////////////////////////////////////////////

    return (
        <Fragment>
        <style jsx>{`${EditUtilityStyle}`}</style>
        {formatTime(time)}
        </Fragment>

    );


    
}

export default VideoControlBar;

const EditUtilityStyle = `

 

`;

