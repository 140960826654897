import React, { Component , Fragment } from 'react';
function preset(preset,self,data,style){
    let fullsize = data.size.height
    let width = data.size.width
    let profile = self.state.profile
    let dataList = []
    let dataField =''

    let opacity= Math.round(data.style.fillOpacity/100 * 255).toString(16)
    if (opacity.length==1){ opacity= 0+opacity }

    let header_opacity= Math.round(data.style.header_fillOpacity/100 * 255).toString(16)
    if (opacity.length==1){ opacity= 0+opacity }

    let main_fill = data.style.main_fill+opacity
    let sub_fill = data.style.sub_fill+opacity
    console.log()
    switch (preset) {
        case 'BusinessCard':
            if(fullsize/100*20<data.style.header_fontSize){
                style.header.fontSize=fullsize/100*20+'px'
            }
            if(fullsize/100*30<data.style.fontSize){
                style.body.fontSize=fullsize/100*30+'px'
            }
            let BusinessCardMemberList = data.value.member.map(
                (info,index) => (
                    <div className="member" style={{'background':sub_fill,'color':data.style.sub_color}}>
                        <span className="memberPosition">
                            <img src={'https://overlap.s3.ap-northeast-1.amazonaws.com/widget/DraftPick/'+info.position+'_icon.png'} onError={(e)=>{e.target.src ="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"}}/>
                            {/* {info.position} */}
                        </span>
                        <div className="memberInfo">
                            <span className="memberName" style={{'fontSize':fullsize/100*8+'px','lineHeight':fullsize/100*14+'px'}} >
                                {/* {data.value.leader==index?<img src="https://overlap.s3.ap-northeast-1.amazonaws.com/widget/DraftPick/leader.png"/>:''} */}
                                {data.value.leader==index?'👑 ':''}
                                {info.name}
                            </span>
                            <span className="memberMemo" style={{'fontSize':fullsize/100*8+'px','lineHeight':fullsize/100*14+'px'}} >
                                {info.memo}
                            </span>
                        </div>
                    </div>
                ) 
            );    

            return(
                <div className={"object "+self.props.widgetName+"_preset_"+preset} ref={self.widget} style={{...style.object,}} >
                    <div className="outLine" style={{'background':sub_fill,'color':data.style.sub_color}}>
                        <div className="inLine" style={{'background':main_fill}}>
                            {BusinessCardMemberList}
                        </div>
                        <div className="bottom">
                  
                            <span className="teamName" style={{'fontSize':fullsize/100*15+'px','lineHeight':fullsize/100*20+'px','color':data.style.main_color}} >
                            {data.value.name}
                            </span>
                            <span className="teamPoint" style={{'fontSize':fullsize/100*10+'px','lineHeight':fullsize/100*16+'px','background':'#444444'+opacity }} >
                            {data.value.point} 포인트
                            </span>
                        </div>
                    </div>
                </div>
            )   

        default:
            return(
                <div className={"object unknownPreset"} ref={self.widget} style={{...style.object}} >
                    <p>알수없는 프리셋</p>
                </div>
                )
    }
}
export function data(self,style) {
    return preset(self.props.data.preset,self,self.props.data,style)
}

